import gql from "graphql-tag";

const DELETE_ORGANISATION = gql`
  mutation deleteOrganisation($organizationId: Int!) {
    organization {
      deleteOrganization(organizationId: $organizationId)
    }
  }
`;

export default DELETE_ORGANISATION;
