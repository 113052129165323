import React, { useState, Fragment } from "react";
import { Grid } from "@material-ui/core";
import gql from "graphql-tag";

import { withLocalize, LocalizeContextProps } from "react-localize-redux";

import PartnerInformation from "./PartnerInformation";
import PartnerMedia from "./PartnerMedia";
import Employees from "./Employees/index";
import BonusAccount from "./BonusAccount/index";
import ProductRegistrations from "./ProductRegistrations/index";
import { Prompt } from "react-router";
import PageHeader from "components/common/PageHeader";

import { Theme, makeStyles } from "@material-ui/core";

import { PartnerDetail_organization } from "types/apolloGenerated/PartnerDetail";

import { changeFuncType } from ".";

import Error from "components/common/Error";
import { PartnerGroups_organizationGroups } from "types/apolloGenerated/PartnerGroups";
import { AuthStore } from "redux/types/Auth";
import PageDialog from "components/common/PageDialog";
import DELETE_ORGANISATION from "commonQueries/deleteOrganisation";
import { useMutation } from "react-apollo";
import {
  deleteOrganisation as MutationData,
  deleteOrganisationVariables as MutationVars,
} from "types/apolloGenerated/deleteOrganisation";

import {
  ReactivateOrganization as ReactivateData,
  ReactivateOrganizationVariables as ReactivateVars,
} from "types/apolloGenerated/ReactivateOrganization";

import ChangePearls from "components/common/AccShortcuts/ChangePearls";
import AuditLog from "../../../Customer/InstanceDetails/AuditLog";

const REACTIVATE_ORGANIZATION = gql`
  mutation ReactivateOrganization($id: Int!) {
    organization {
      reactivateOrganization(organizationId: $id)
    }
  }
`;

interface PartnerDetailProps extends LocalizeContextProps {
  auth: AuthStore;
  isNewMode: boolean;
  newPartnerBean: PartnerDetail_organization;
  selectedGroups: PartnerGroups_organizationGroups[];
  selectableGroups: PartnerGroups_organizationGroups[];
  isSavePending: boolean;
  error?: string[] | null;
  setSelectedGroups: (selected: PartnerGroups_organizationGroups[]) => void;
  onChangeSetData: changeFuncType;
  onSave: () => void;
  canViewLogDetail: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  userInfo: {
    paddingLeft: "30px",
    paddingBottom: "30px",
  },
  pageHeader: {
    position: "sticky",
    top: "64px",
    zIndex: 100,
    background: "#fff",
  },
  errorContainer: {
    position: "sticky",
    top: "129px",
    zIndex: 1,
  },
  error: {
    position: "sticky",
  },
}));

const PartnerDetails: React.FC<PartnerDetailProps> = ({
  auth,
  translate,
  isNewMode,
  newPartnerBean,
  selectedGroups,
  setSelectedGroups,
  selectableGroups,
  onChangeSetData,
  onSave,
  isSavePending,
  error,
  canViewLogDetail,
}) => {
  const [state, setState] = React.useState("");
  const [isDirty] = useState<boolean>(false);

  const classes = useStyles();
  // const { id } = useParams();
  // const isNewOrganization = isNewMode && window.location.href.indexOf("NEW") > -1 && Number(id);

  const [deleted, setDeleted] = React.useState(newPartnerBean.isDeleted);

  const [deleteOrganisation, { loading: orgLoad }] = useMutation<
    MutationData,
    MutationVars
  >(DELETE_ORGANISATION, {
    onCompleted: (data) => {
      if (!data.organization?.deleteOrganization) {
        setDelOrgError(
          translate("components.partnerDetails.error.delete") as string
        );
      } else {
        setOpen(false);
        setDeleted(true);
        window.location.href = "/pam/partner";
      }
    },
    onError: (error) => setDelOrgError(error.message),
  });

  const [reactivateOrganisation, { loading: reactivateLoading }] = useMutation<
    ReactivateData,
    ReactivateVars
  >(REACTIVATE_ORGANIZATION, {
    onCompleted: (data) => {
      if (!data.organization?.reactivateOrganization) {
        setDelOrgError(
          translate("components.partnerDetails.error.reactivate") as string
        );
      } else {
        setOpen(false);
        setDeleted(false);
      }
    },
  });

  const message = deleted
    ? (translate("components.partnerDetails.orgDeleted") as string)
    : "";
  const [open, setOpen] = React.useState(false);
  const [delOrgError, setDelOrgError] = React.useState("");

  const change = (newPearlsState: string) => {
    setState(newPearlsState);
  };

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Grid container>
        <Grid item xs={12} className={classes.pageHeader}>
          <PageHeader
            title={
              isNewMode
                ? (translate("components.partnerDetails.createNew") as string)
                : newPartnerBean.name || ""
            }
            onSave={onSave}
            isSavePending={isSavePending}
            showBack
          />
        </Grid>
        <Grid item xs={12} className={classes.errorContainer}>
          <Error error={error} />
          <Error error={message} />
        </Grid>

        <Grid item xs={12}>
          <PartnerInformation
            isNewMode={isNewMode}
            data={newPartnerBean}
            auth={auth}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            selectableGroups={selectableGroups}
            onChangeFunc={onChangeSetData}
          />
        </Grid>

        <Grid item xs={12}>
          <PartnerMedia data={newPartnerBean} onChangeFunc={onChangeSetData} />
        </Grid>

        {isNewMode ? null : (
          <Fragment>
            <Grid item xs={12}>
              <Employees partnerId={newPartnerBean.id} />
            </Grid>
            <Grid item xs={12}>
              <BonusAccount
                id={newPartnerBean.id}
                bonusAccount={newPartnerBean.bonusaccount}
                partnerType={newPartnerBean.partnerType}
                newPearlsState={state}
                isSavePending={isSavePending}
                countryCode={newPartnerBean?.address?.countryCode}
                onSave={onSave}
              />
            </Grid>
            <Grid item xs={12}>
              <ProductRegistrations id={newPartnerBean.id} />
            </Grid>
          </Fragment>
        )}
        <Fragment>
          <Grid item xs={12}>
            <AuditLog
              partnerDetail={newPartnerBean}
              canViewLogDetail={canViewLogDetail}
              productCode={null}
              productInstance={null}
              gridSize={8}
              customerId={null}
              productId={null}
            />
          </Grid>
        </Fragment>
      </Grid>
      <PageDialog
        open={open}
        title={
          deleted
            ? "components.partnerDetails.reactivateOrganisationMessage"
            : "components.partnerDetails.deleteOrganisationMessage"
        }
        buttons={[
          {
            title: "generics.true",
            click: () => {
              setDelOrgError("");
              deleted
                ? reactivateOrganisation({
                    variables: {
                      id: newPartnerBean.id,
                    },
                  })
                : deleteOrganisation({
                    variables: {
                      organizationId: newPartnerBean.id,
                    },
                  });
            },
          },
          {
            title: "generics.cancel",
            click: () => {
              setOpen(false);
              setDelOrgError("");
            },
          },
        ]}
        loading={orgLoad || reactivateLoading}
        error={delOrgError}
      />
      <ChangePearls orgId={newPartnerBean.id} onChangeFunc={change} />
    </Fragment>
  );
};

export default withLocalize(PartnerDetails);
