import React from "react";


import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    circle: {
      fill: "none",

    },
    "@keyframes progress": {
      "0%": {
        strokeDasharray: "0 100",
        opacity: "0"
      },
      "100%": {
        opacity: "1",
       
      },

      
    }
  });
});

const CircleSegment = ({
  value = 1,
  colour = "rgb(137, 158, 181)",
  lineWidth = 1.5,
}) => {
  const classes = useStyles();
  const percentage = value;
  return (
    <svg viewBox="0 0 36 36">
      <path
        className={classes.circle}
        style={{ stroke: colour, strokeWidth: lineWidth }}
        strokeDasharray={`${percentage}, 100`}
        d="M18 2.0845
  a 15.9155 15.9155 0 0 1 0 31.831
  a 15.9155 15.9155 0 0 1 0 -31.831"
      />
    </svg>
  );
};

export default CircleSegment;
