import equal from "deep-equal";

import {
  FilterOperator,
  SortingDirection,
} from "types/apolloGenerated/globalTypes";

import { State as AgGridState } from "redux/types/AgGrid";

import { Filter, FilterModel, SingleSortModel } from "types/AgGrid";
import ApolloClient, {
  ApolloQueryResult,
  OperationVariables,
} from "apollo-client";
import {
  IServerSideGetRowsRequest,
  GridApi,
  ColumnApi,
} from "@ag-grid-enterprise/all-modules";

export const getFilterValues = (filter: Filter): string[] => {
  return filter.filterType === "set" ? filter.values : [filter.filter];
};

export const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  menuTabs: ["filterMenuTab"],
};

export const autoGroupColumnDef = {
  flex: 1,
  minWidth: 280,
};

export const sideBar = {
  defaultToolPanel: "",
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressSideButtons: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
  ],
};

type ApolloSorting = {
  filterOption?: string | null;
  sortingCriterionOrder: number;
  sortingDirection?: SortingDirection | null;
};

export type ApolloFilter = {
  filterOption?: string[] | null;
  operator?: FilterOperator | null;
  values: string[];
};

type ApolloTypes = {
  page?: number;
  pageSize?: number;
  sorting?: ApolloSorting[];
  filter?: ApolloFilter[];
};

export const apolloArgumentsCreator = () => {
  const apolloArguments: ApolloTypes = {};

  const eqFilterOption = [
    "PARTNER_TYPE",
    "STATE",
    "PRODUCT_TYPE",
    "TYPE",
    "POSITION",
    "IS_LOCKED",
  ];
  const containsFilterOption = ["COUNTRY"];

  const sortingArr: ApolloSorting[] = [];
  let sortingOrder = 1;

  let filterArr: ApolloFilter[] = [];

  const agc = {
    addAgGridFilterModel: (
      filterModel: FilterModel,
      mapping: { [key: string]: string },
      filterMapping?: {
        [key: string]: (apolloFilter: ApolloFilter[], filterModel: any) => void;
      }
    ) => {
      let filterOption: string;
      for (let field in filterModel) {
        if (filterMapping && filterMapping[field]) {
          filterMapping[field](filterArr, filterModel[field]);
        } else if ((filterOption = mapping[field])) {
          let filter = filterModel[field];
          let selectedFilterOperator = FilterOperator.STARTSWITH;

          for (let type in eqFilterOption) {
            if (filterOption === eqFilterOption[type].toString()) {
              selectedFilterOperator = FilterOperator.EQ;
            }
          }

          for (let type in containsFilterOption) {
            if (filterOption === containsFilterOption[type].toString()) {
              selectedFilterOperator = FilterOperator.CONTAINSPLURAL;
            }
          }

          filterArr.push({
            filterOption: [filterOption],
            operator: selectedFilterOperator,
            values: getFilterValues(filter),
          });
        } else {
          console.error(
            'Trying to filter for a property which is not configured to be filterable. Property: "' +
              field +
              '". Configured Fields: ',
            mapping
          );
        }
      }
      return agc;
    },

    addAgGridSortModel: (
      sortModel: SingleSortModel[],
      mapping: { [key: string]: string }
    ) => {
      let sortField: string;
      sortModel.forEach((element) => {
        if ((sortField = mapping[element.colId])) {
          sortingArr.push({
            filterOption: sortField,
            sortingCriterionOrder: sortingOrder++,
            sortingDirection:
              element.sort === "asc"
                ? SortingDirection.ASCENDING
                : SortingDirection.DESCENDING,
          });
        } else {
          console.error(
            'Trying to sort for a property which is not configured to be sortable. Property "' +
              element.colId +
              '". Configured Fields: ',
            mapping
          );
        }
      });

      return agc;
    },

    setPagingFromAgGridStartEnd: (startRow: number, endRow: number) => {
      apolloArguments.pageSize = endRow - startRow;
      apolloArguments.page = Math.floor(startRow / apolloArguments.pageSize);

      return agc;
    },

    setPaging: (page: number, pageSize: number) => {
      apolloArguments.page = page;
      apolloArguments.pageSize = pageSize;
    },

    addSearch: (
      searchTerms: string | null | undefined,
      fields: string[] | null | undefined
    ) => {
      if (searchTerms && fields) {
        filterArr.push({
          filterOption: fields,
          operator: FilterOperator.STARTSWITH,
          values: [searchTerms],
        });
      }

      return agc;
    },

    addfilters: (filters: ApolloFilter[]) => {
      filterArr = filters.concat(filterArr);
    },

    getArguments: () => {
      if (sortingArr.length) {
        apolloArguments.sorting = sortingArr;
      }
      if (filterArr.length) {
        apolloArguments.filter = filterArr;
      }

      return apolloArguments;
    },
  };

  return agc;
};

const varsQueriedByGrids: Record<string, OperationVariables[]> = {};

export function getVarSetsQueriedByGrids(query: any): OperationVariables[] {
  const queryName = query?.definitions?.[0]?.name?.value;
  if (typeof queryName === "string") {
    if (!varsQueriedByGrids[queryName]) {
      varsQueriedByGrids[queryName] = [];
    }
    return varsQueriedByGrids[queryName];
  } else {
    console.error(
      "getVarSetsQueriedByGrids: Could not find name on query document:",
      query
    );
    return [];
  }
}

function markVarsQueriedByGrids(query: any, variables: OperationVariables) {
  const varSets = getVarSetsQueriedByGrids(query);
  const ks1 = Object.keys(variables);
  for (const vars of varSets) {
    const ks2 = Object.keys(vars);
    if (ks1.length === ks2.length) {
      let found = true;
      for (const k of ks1) {
        if (variables[k] !== vars[k]) {
          found = false;
        }
      }
      if (found) {
        // Already recorded
        return;
      }
    }
  }
  varSets.push(variables);
}

export const factoryApolloConnector = <QueryType, QueryVariableType>(
  QUERY: any,
  dataHandler: (data: QueryType) => any,
  colMapping: { [key: string]: string },
  options?: {
    queryWithoutTotalResult?: any;
    search?: {
      searchCols: string[];
      searchTerm: string | null;
    };
    filter?: ApolloFilter[];
    customArguments?: any;
    defaultSort?: any;
    filterMapping?: {
      [key: string]: (apolloFilter: ApolloFilter[], filterModel: any) => void;
    };
  }
) => {
  // let lastTotalResult = -1;
  // let lastFilter: ApolloFilter[] | undefined = undefined;

  let watcher: ZenObservable.Subscription | undefined = undefined;
  // let watcherCallback: false | (() => void) = false;

  const func = (
    client: ApolloClient<any>,
    request: IServerSideGetRowsRequest
  ): Promise<void | QueryType> => {
    if (options?.defaultSort && request.sortModel.length === 0) {
      // console.log("options.defaultSort:", options.defaultSort)
      request.sortModel = options.defaultSort;
    }

    // const queryArguments = apolloArgumentsFromAgGrid(params.request, searchTerm);
    let queryArguments = apolloArgumentsCreator();

    queryArguments
      .setPagingFromAgGridStartEnd(request.startRow, request.endRow)
      .addAgGridSortModel(request.sortModel, colMapping)
      .addAgGridFilterModel(
        request.filterModel,
        colMapping,
        options?.filterMapping
      );

    if (options?.filter) {
      // console.log('options.filter', options.filter);
      queryArguments.addfilters(options.filter);
    }
    if (options?.search?.searchTerm) {
      queryArguments.addSearch(
        options.search.searchTerm,
        options.search.searchCols
      );
    }

    let variables = queryArguments.getArguments() as QueryVariableType;

    if (options && options.customArguments) {
      variables = { ...variables, ...options.customArguments };
    }

    // const skipTotalResult = options?.queryWithoutTotalResult && lastTotalResult >= 0 && equal(lastFilter, options.filter);
    // console.log('#### #### #### #### #### ####');
    // console.log('## Has QueryWithout TR: ' + (options?.queryWithoutTotalResult ? 'Yes' : 'No'));
    // console.log('## lastTotalResult: ' + lastTotalResult);
    // console.log('## filter are equal: ' + (equal(lastFilter, options?.filter) ? 'Yes' : 'No'), { lastFilter: lastFilter, newFilter: options?.filter });
    // console.log('## Query ' + (skipTotalResult ? 'WITHOUT' : 'WITH') + ' TotalResults');
    // console.log('#### #### #### #### #### ####');

    // const actualQuerry = skipTotalResult ? options?.queryWithoutTotalResult : QUERY;
    const actualQuerry = QUERY;
    // lastFilter = options?.filter;

    // if (watcher) {
    //   watcher.unsubscribe();
    // }
    // if (watcherCallback) {
    //   // console.log('## AgGrid - Add Watcher');
    //   watcher = client.watchQuery({
    //     query: actualQuerry,
    //     variables: variables
    //   }).subscribe({
    //     next: watcherCallback
    //   });
    // }
    markVarsQueriedByGrids(actualQuerry, variables);

    return client
      .query<QueryType, QueryVariableType>({
        query: actualQuerry,
        variables: variables,
      })
      .then((queryData: ApolloQueryResult<QueryType>) =>
        dataHandler(queryData.data)
      );
    // .then((data: ReturnType<typeof dataHandler>) => {
    //   if (skipTotalResult) {
    //     data.totalResults = lastTotalResult;
    //   } else {
    //     lastTotalResult = data.totalResults;
    //   }

    //   return data;
    // });
  };

  const unRegisterWatcher = () => {
    // console.log('## AgGrid - Unregister Watcher');
    if (watcher) {
      watcher.unsubscribe();
    }
    // watcherCallback = false;
  };

  const registerWatcher = (newCallback: () => void) => {
    // console.log('## AgGrid - Register Watcher');
    // watcherCallback = newCallback;
    return unRegisterWatcher;
  };

  return { doQueryData: func, onDataChangedFromOutside: registerWatcher };
};

export const updateGridFromState = (
  api: { grid: GridApi; column: ColumnApi },
  gridState: AgGridState
) => {
  //console.log('### STATE->GRID');
  if (
    gridState.columnStates &&
    !equal(gridState.columnStates, api.column.getColumnState())
  ) {
    //console.log('setColumnState', gridState.columnStates);
    api.column.setColumnState(gridState.columnStates);
  }

  if (!equal(gridState.filterModel, api.grid.getFilterModel())) {
    //console.log('setFilterModel', gridState.filterModel);
    api.grid.setFilterModel(gridState.filterModel);
  }

  if (!equal(gridState.sortModel, api.grid.getSortModel())) {
    //console.log('setSortModel', gridState.sortModel);
    api.grid.setSortModel(gridState.sortModel);
  }

  if (gridState.page !== api.grid.paginationGetCurrentPage()) {
    //console.log('paginationGoToPage', gridState.page);
    // ToDo: Fix this Workaround!!
    setTimeout(() => {
      api.grid.paginationGoToPage(gridState.page);
    }, 100);

    // if (gridState.page === api.grid.paginationGetTotalPages()) {
    //     setTimeout(() => {
    //       api.grid.paginationGoToPage(gridState.page);
    //     }, 4000);
  }
  //console.log('### END UPDATE');
};
