import gql from "graphql-tag";

const USER_ROLES = gql`
query userRoles  {
  user {
    userInformation {
        userRoles
    }
  }
}
`;

export default USER_ROLES;