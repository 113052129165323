import React, { useState, useEffect } from 'react';
import { Grid, TextField, createStyles, Theme, Box, Button, makeStyles } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Translate } from 'react-localize-redux';
import { Delete, History } from '@material-ui/icons';
import Dropzone from 'react-dropzone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: '25px',
      "& .MuiTextField-root, & .MuiFormControl-root": {
        width: "100%",
        "& .MuiInputLabel-outlined": {
          transform: "translate(14px, 15px) scale(1)",
          fontSize: "0.9rem",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
    },
    button: {
      marginRight: "10px"
    },
    formContainer: {
      transform: "translate(0, 15px)",
    },
    imgTitle: {
      marginBottom: '5px',
    },
    imgField: {
      height: '150px',
      width: '250px',
      borderRadius: '8px',

      background: 'rgb(237, 237, 237)',
      border: 'solid 2px rgb(214, 214, 214)',
      color: 'rgb(135, 135, 135)',

      fontSize: '20px',
      position: 'relative',

      padding: '5px',

      '& img, & span': {
        maxWidth: 'calc(100% - 10px)',
        maxHeight: 'calc(100% - 10px)',
        height: 'auto',

        padding: '2px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      '& span':{
        width: '100%',
        textAlign: 'center'
      },
      '& img':{
        width: 'auto',
        whiteSpace: 'nowrap',
      },
      '& div':{
        height: '100%',
        width: '100%',
        whiteSpace: 'pre-wrap'
      }
    },
    imgUploadButton: {
      display: 'none',
    },
    icon: {
      marginLeft: '5px',
    },
    errorBox: {
      color: 'red',
    },
    btnContained: {
      whiteSpace: 'nowrap',
      '& button, & label span span': {
        fontSize: '12px',
      }
    },
  }));

interface imageData {
  imageTitle: string,
  imageSize: {
    height: number,
    width: number
  },
  defaultImage?: string | null,
  defaultImageLink?: string | null,
  supportedFileTypes?: string[],
  maxFileSize?: number,
  onChangeFunc: (value: any, name: string) => void,
  imgPropName: string,
  urlPropName: string
};

type imgType = {
  type: 'UNCHANGED' | 'EMPTY' | 'CHANGED',
  src?: string,
}

type imageUploadErrorType = {
  type: 'NO_ERROR' | 'INVALID_SIZE' | 'INVALID_FILETYPE' | 'INVALID_FILESIZE',
  message: string,
};

const isSupportedFileType = (file: string, supported: string[]) => {
  let startIndex = file.indexOf(":") + 1;
  let endIndex = file.indexOf(";");
  let fileType = file.substring(startIndex, endIndex);

  return (supported.length === 0 || supported.includes(fileType));
}


class UniqueIdGenerator {
  private id = 0;
  public getUniqueId = (): number => {
    return this.id++;
  };
}

const idGen = new UniqueIdGenerator();

//export let partnerPicBase64 = { imageType: "", imageBase64: "" };

const ImageUpload: React.FC<imageData> = (
  { imageTitle, imageSize, defaultImage, defaultImageLink, onChangeFunc, supportedFileTypes = [], maxFileSize = 102400, imgPropName, urlPropName }
) => {

  const classes = useStyles();

  const [errors, setErrors] = useState<string | null>(null);

  const [currentLocalImage, setCurrentLocalIMage] = useState<string | undefined>();
  const [actualImage, setActualImage] = useState<string | null | undefined>();

  const errorBox = errors ? <Box className={classes.errorBox}><Translate id={errors} /></Box> : null;


  const handleUploadChange = (evt: any) => {
    let file = evt.target.files[0];
    validateImage(file);
    evt.target.value = "";
  };

  const validateImage = (file: File) => {
    if (file.size <= maxFileSize) {
      let reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          if (isSupportedFileType(`${reader.result}`, supportedFileTypes)) {
            let img = new Image();
            img.addEventListener('load', function () {
              setCurrentLocalIMage(`${reader.result}`);

              if (!(imageSize.height === this.naturalHeight && imageSize.width === this.naturalWidth)) {
                setErrors('components.partnerDetails.error.invalidImageSize');
              }
              else {
                onChangeFunc(`${reader.result}`, imgPropName);
                setErrors(null);
              }
            });
            img.src = `${reader.result}`;
          } else {
            setCurrentLocalIMage(undefined);
            setErrors('components.partnerDetails.error.invalidFileType');
          }
        }
        else {
          onChangeFunc("", imgPropName);
          setCurrentLocalIMage(undefined);
        }
      }
      reader.readAsDataURL(file);
    }
    else{
      setCurrentLocalIMage(undefined);
      setErrors('components.partnerDetails.error.fileIsToBig');
    }
  };

  const handleCLearImage = (evt: any) => {
    setErrors(null);
    setActualImage(null);
    onChangeFunc(null, imgPropName);
    if (!defaultImage) {
      setCurrentLocalIMage(undefined);
    }
  };

  const handleRevertImage = (evt: any) => {
    setErrors(null);
    setCurrentLocalIMage(undefined);
    setActualImage(defaultImage);
    onChangeFunc(defaultImage, imgPropName);
  };

  const id = idGen.getUniqueId();

  useEffect(() => {
    setActualImage(currentLocalImage ? currentLocalImage : defaultImage);
  }, [currentLocalImage, defaultImage]);

  let textVariant: 'standard' | 'outlined' | 'filled' = 'standard';
  let btnVariant: 'text' | 'outlined' | 'contained' = 'contained';


  return (
    <Grid container className={classes.container}>
      <Grid container item xs={7}>
        <Grid item xs={6}>
          <Box className={classes.imgTitle}>{`${imageTitle} (${imageSize.width}x${imageSize.height}px)`}</Box>
          <Box className={classes.imgField}>
            <Dropzone>
              {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    {actualImage ? <img src={actualImage} alt="" /> : <span><Translate id="components.partnerDetails.dragAndDrop" /></span>}
                  </div>
              )}
            </Dropzone>
          </Box>
          {errorBox}
        </Grid>
        <Grid container item xs={6} className={classes.formContainer}>
          <Grid item xs={12}>
            <TextField
              defaultValue={defaultImageLink}
              label={<Translate id="components.partnerDetails.partnerLinkUrl" />}
              variant={textVariant}
              name={urlPropName}
              disabled={true} />
          </Grid>
          <Grid item xs={12} className={classes.btnContained}>
            <label htmlFor={`contained-button-img-${id}`}>
              <Button className={classes.button} variant={btnVariant} disabled={true} color="primary" component="span">
                <Translate id="components.partnerDetails.uploadImage" />
                <CloudUploadIcon className={classes.icon} />
              </Button>
            </label>
            <Button className={classes.button} variant={btnVariant} onClick={handleCLearImage} disabled={true}>
              <Translate id="components.partnerDetails.removeImage" /><Delete className={classes.icon} />
            </Button>
            <Button className={classes.button} variant={btnVariant} onClick={handleRevertImage} disabled={true}>
              <Translate id="components.partnerDetails.revertChanges" /> <History className={classes.icon} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};



export default ImageUpload;