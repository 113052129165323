import React from "react";
import format from "date-fns/format";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/HighlightOff";
import WarnIcon from "@material-ui/icons/ErrorOutline";
import InfoIcon from "@material-ui/icons/CheckCircleOutline";

import colours from "themes/bwtColors";
import { PerlaNotification, Level, getLevel } from "./types";
import { Translate } from "react-localize-redux";

const iconStyle = {
  flexShrink: 0,
  margin: 10,
  fontSize: 30
};

const useStyles = makeStyles(() => ({
  root: { display: "flex", alignItems: "center" },
  errorIcon: { ...iconStyle, color: colours.errorRed },
  warnIcon: { ...iconStyle, color: colours.warnYellow },
  infoIcon: { ...iconStyle, color: colours.arctic }
}));

interface NotificationProps {
  notification: PerlaNotification;
}

const Notifcation: React.FC<NotificationProps> = ({ notification }) => {
  const { title, subTitle, date, untranslatedSubTitle, untranslatedTitle } = notification;
  // const { notificationBody, notificationTitle } =
  //   ((notificationCode || {}).notificationTexts || [])[0] || {};
  const classes = useStyles();
  const level = getLevel(notification);


  // const downloadIoTNotification = (
  //   event: React.MouseEvent<HTMLElement, MouseEvent>
  // ) => {

  //   console.log("downloadIoTNotification: ", notificationJSON);
  //   console.log("event", event);

  //   if (notificationJSON) {
  //     const notificationEvent = JSON.stringify(notificationJSON, null, 2).replace(/\\"/g, '"');
  //     console.log("notification2: ", notificationEvent);

  //     const x = window.open();
  //     if (x) {
  //       x.document.open();
  //       x.document.write('<html><body><pre>' + notificationEvent + '</pre></body></html>');
  //       x.document.close();
  //     }
  //   }

  // };


  //<Link underline="always" href="#" color="inherit" style={{ marginTop: "7px" }} onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => { downloadIoTNotification(e) }} ><Translate id="components.deviceData.downloadJSON" /></Link>

  return (
    <div className={classes.root}>
      {level === Level.E ? (
        <ErrorIcon className={classes.errorIcon} />
      ) : level === Level.W ? (
        <WarnIcon className={classes.warnIcon} />
      ) : (
            <InfoIcon className={classes.infoIcon} />
          )}
      <div>
        <div>{title === "" ? untranslatedTitle : <Translate id={title} />} </div>
        <Translate>
          {
            (translate) => {
              return <div>{format(new Date(date), translate.translate('generics.dateTimeFormatFNS') as string)}</div>;
            }
          }
        </Translate>
        <div>{subTitle === "" ? untranslatedSubTitle : <Translate id={subTitle} />}</div>
      </div>
    </div>
  );
};

export default Notifcation;
