import React, { Fragment } from "react";
import { Translate } from "react-localize-redux";
import { Box, CircularProgress } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { TranslateFunction, withLocalize, LocalizeContextProps } from "react-localize-redux";
import { DeleteForever, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";


import Notification from "./Notification";
import Expander from "components/common/Expander";
import MAIL_QUERY from "commonQueries/mailTemplate"
import { productById_product_productInformation_productUserAction_notifications as NotificationObj } from "types/apolloGenerated/productById";
import { UserActionClass, UserActionState } from "types/apolloGenerated/globalTypes";
import { removeUserActionVariables as removeVariables, removeUserAction as remove } from "types/apolloGenerated/removeUserAction";
import { mailTemplateGroup as queryData } from "types/apolloGenerated/mailTemplateGroup"
import { updateProductByIdCache } from "commonQueries/product"
import { QUERY_PRODUCTS_GRID } from "Pages/Product/ProductsGrid"

const useStyles = makeStyles(() => {
  return createStyles({
    container: {
      margin: "0 0 15px 30px",
      borderRadius: "3px",
      border: "solid #666B6E 1px"
    },
    titleOuterContainer: {
      fontSize: "20px",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      borderRadius: "3px",
      justifyContent: "space-between"
    },
    titleInnerContainer: {
      display: "flex",
      alignItems: "center"
    },
    removeButtonContainer: {
      marginRight: "20px",
      display: "flex"
    },
    hideButton: {
      width: "30px",
      height: "35px",
      paddingTop: "6px",
      cursor: "pointer"
    },
    mousePointer: {
      cursor: "pointer"
    }
  });
});

const REMOVE_USER_ACTION = gql`
  mutation removeUserAction($productUserActionId: Int!) {
    userAction {
      deleteProductUserAction(productUserActionId: $productUserActionId)
    }
  }
`;

interface NotificationGroupProps extends LocalizeContextProps {
  idx: number
  currentNotifs: (NotificationObj | null)[] | null
  title: UserActionClass | null
  userActionId: number
  translate: TranslateFunction
  productId: number
}

const NotificationGroup: React.FC<NotificationGroupProps> = ({ idx, currentNotifs, title, userActionId, translate, productId }) => {
  const dummyNotification: NotificationObj = {
    __typename: "ProductUserActionNotification",
    mailTemplateName: translate("pages.product.chooseMailTemplate") as string,
    state: UserActionState.WARNING,
    threshold: 0,
    id: -1,
    translations: []
  }
  const classes = useStyles();
  const [notifications, setNotifications] = React.useState(currentNotifs);
  const [hidden, setHidden] = React.useState(true);
  const { data: mailData } = useQuery<queryData>(MAIL_QUERY)
  const [error, setError] = React.useState("")
  const [notifError, setNotifError] = React.useState("")
  const [dummyNotif, setDummyNotif] = React.useState<NotificationObj>(dummyNotification)

  const [removeUserAction, { loading: removeActionLoading }] = useMutation<remove, removeVariables>(REMOVE_USER_ACTION, {
    update: function (cache) {
      updateProductByIdCache(cache, productId, p => {
        if (p.product?.productInformation?.productUserAction) {
          p.product.productInformation.productUserAction = p.product.productInformation.productUserAction.filter(userAction => {
            return userAction?.id !== userActionId;
          });
        }
        return p;
      });
    },
    onError: error => {
      setError("Unable to remove user action - " + error.message)
    },
    refetchQueries: [{
      query: QUERY_PRODUCTS_GRID, variables: { pageSize: 0 }
    }]
  });

  const addNotification = (newNotif: NotificationObj) => {
    const notifToAdd: NotificationObj = {
      __typename: "ProductUserActionNotification",
      mailTemplateName: newNotif.mailTemplateName,
      threshold: newNotif.threshold,
      state: newNotif.state,
      translations: newNotif.translations,
      id: newNotif.id
    };
    if (notifications) {
      setNotifications([...notifications.slice(), notifToAdd]);
      setDummyNotif(dummyNotification)
    }
  };

  const updateNotification = (index: number, notification: NotificationObj) => {
    if (notification.id === -1) {
      const latestValue = Object.assign({}, notification);
      setDummyNotif(latestValue)
      return;
    }
    if (notifications) {
      setNotifications(
        notifications.map((currentObj, idx) =>
          idx === index ? notification : currentObj
        )
      );
    }
  };

  const removeNotification = (index: number) => {
    if (notifications) {
      setNotifications([
        ...notifications.slice(0, index),
        ...notifications.slice(index + 1)
      ]);
    }
  };

  return (
    <Box>
      <Box className={classes.container}>
        <Box className={classes.titleOuterContainer}>
          <Box className={classes.titleInnerContainer}>
            <Box className={classes.removeButtonContainer}>
              {!removeActionLoading ? (
                <DeleteForever
                  onClick={() => {
                    setError("")
                    removeUserAction({
                      variables: { productUserActionId: userActionId }
                    })
                  }
                  }
                  fontSize="large"
                  className={classes.mousePointer}
                />
              ) : (
                  <CircularProgress size={32} color="secondary" />
                )}
            </Box>
            <div onClick={() => setHidden(!hidden)} className={classes.mousePointer}><Translate id={`pages.product.notifGroups.${title}`} /></div>
          </Box>
          <Box
            onClick={() => setHidden(!hidden)}
            className={classes.hideButton}
          >
            {hidden ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </Box>
        </Box>
        <Expander hidden={hidden}>
          {notifications &&
            notifications.map((notif, idx) => {
              if (!notif) {
                return <Fragment />;
              }
              return (
                <Notification
                  key={idx}
                  index={idx}
                  notificationObject={notif}
                  change={updateNotification}
                  remove={removeNotification}
                  add={addNotification}
                  userActionId={userActionId}
                  mailData={mailData}
                  addOnly={false}
                  setError={setNotifError}
                />
              );
            })}
          <Notification
            index={0}
            notificationObject={dummyNotif}
            change={updateNotification}
            remove={removeNotification}
            add={addNotification}
            userActionId={userActionId}
            mailData={mailData}
            addOnly={true}
            setError={setNotifError}
          />
          {notifError !== "" ? <Alert style={{ marginTop: "10px" }} severity="error">{notifError}</Alert> : <div />}
        </Expander>
      </Box>
      {error !== "" ? <Alert style={{ margin: "10px 0 0 30px" }} severity="error">{error}</Alert> : <Fragment />}
    </Box>
  );
};

export default withLocalize(NotificationGroup);
