import React, { useState } from 'react';
import { TextField, createStyles, Theme, Box, Button, makeStyles } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: "30px",
      marginBottom: "50px",
      '& h1': {
        marginBottom: '20px',
      }
    },
    block: {
      display: "block",
      marginBottom: "20px"
    }
  }));

interface SetRequestProps {
}

const SetRequestId: React.FC<SetRequestProps> = () => {

  const classes = useStyles();
  const history = useHistory();

  const [textFieldValue, setTextFieldValue] = useState<string | undefined>();

  const redirectToRequestId = () => {
    history.push("/pam/request/" + textFieldValue);
  }


  return (
    <Box>
      <TextField
        className={classes.block}
        defaultValue={textFieldValue}
        label={<Translate id="pages.partnerRequest.requestId" />}
        variant="standard"
        name="requestId"
        color="primary"
        autoComplete="off"
        onChange={(evt) => { setTextFieldValue(evt.target.value) }}
        onKeyPress={(evt) => {
          if (evt.key === "Enter" && textFieldValue) {
            redirectToRequestId();
          }
        }}
      />
      <Button
        className={classes.block}
        type="submit"
        variant="outlined"
        color="primary"
        disabled={!textFieldValue}
        onClick={(evt) => { redirectToRequestId() }}>
        <Translate id="pages.partnerRequest.openApproval" />
      </Button>
    </Box>
  )
};



export default SetRequestId;