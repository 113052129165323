import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';


import { TextField } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';


import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import PageHeader from "components/common/PageHeader";

import GridFilterHeader from "components/common/Grid/GridFilterHeader";

import { AppState } from 'redux/store';
import { State as PromotionOverviewState } from 'redux/types/PromotionOverview';
import { setGridState, setColumnStates, search } from 'redux/actions/PromotionOverviewActions';

import PromoGrid from './GridConfig'


import * as Types from 'types/ToolboxEntities';
import { Translate } from 'react-localize-redux';


const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    innerContent: {
      display: "flex",
      flexFlow: "column",

      "& > .gridFilterHeader": {
        flex: "0 1 auto",
        margin: "0",
      },

      "& > .innerContent": {
        flex: "1 1 auto",
        height: "100%",
      },
    },
    loadingSpinner: {
      minWidth: "220px", 
      display: "flex", 
      justifyContent: "center", 
      marginTop: "10px"
    },
  })
});


interface PromotionListPara {
  user: Types.UserProfile | null
  promotionOverviewState: PromotionOverviewState
  actions: {
    setGridState: typeof setGridState
    setColumnStates: typeof setColumnStates
    search: typeof search
  }
}


const Promotion: React.FC<PromotionListPara> = ({ user, promotionOverviewState, actions }) => {
  const classes = useStyles();

  const [search, setSearch] = React.useState<string>("");
  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

 


  return (
    <Translate>{({ translate }) => (
      <Fragment>
        <main
          className={
            "mainContentContainer fullHeight " + classes.innerContent
          }
        >
          <PageHeader title="types.common.promotions" onAdd="promotion/NEW" />
          <GridFilterHeader>
            <TextField
              className="filter"
              id="standard-basic"
              label="Type to filter"
              value={search}
              onChange={changeSearch}
            />
          </GridFilterHeader>
          <div className="innerContent">
            <PromoGrid search={search} />
          </div>
        </main>
      </Fragment>
    )}</Translate>
  );
};




const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  promotionOverviewState: appState.promotionOverview
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ setGridState, setColumnStates, search }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Promotion);
