import React, { useRef, useEffect } from "react";
import { Translate, withLocalize, LocalizeContextProps } from "react-localize-redux";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import startOfDay from "date-fns/startOfDay";
import { List } from "immutable";
import RootRef from "@material-ui/core/RootRef";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import CircularProgress from '@material-ui/core/CircularProgress';

import DayGroup from "./DayGroup";
import { usePaginatedNotifications } from "./data";

function isNearBottom(elem: HTMLDivElement | null): boolean {
  if (!elem) return false;
  return (
    elem.getBoundingClientRect().bottom - 200 <=
    (window.innerHeight || document.documentElement.clientHeight)
  );
}

interface NotificationsHistoryProps extends LocalizeContextProps {
  productCode: string;
}

const NotificationsHistory: React.FC<NotificationsHistoryProps> = ({
  productCode,
  activeLanguage
}) => {
  const { results, loading, loadMore } = usePaginatedNotifications(
    productCode,
    // activeLanguage.code
  );

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (loadMore) {
      const handleScroll = () => {
        if (isNearBottom(ref.current)) loadMore();
      };
      document.addEventListener("scroll", handleScroll);
      return () => {
        document.removeEventListener("scroll", handleScroll);
      };
    }
  }, [loadMore]);

  // const notification: PerlaNotification = {
  //   date: new Date('2017-07-26T11:41:19.693+00:00'),
  //   icon: "info",
  //   subTitle: "",
  //   title: "components.notificationshistory.notifTitles.activated",
  //   untranslatedSubTitle: "",
  //   untranslatedTitle: "",
  //   notificationJSON: null
  // }

  // const notificationTwo: PerlaNotification = {
  //   date: new Date('2017-07-25T11:41:19.693+00:00'),
  //   icon: "info",
  //   subTitle: "",
  //   title: "components.notificationshistory.notifTitles.activated",
  //   untranslatedSubTitle: "",
  //   untranslatedTitle: "",
  //   notificationJSON: null
  // }


  // results.push(notification);
  // results.push(notificationTwo);

  const byDayPairs = List(results)
    .groupBy(notif => startOfDay(new Date(notif?.date)).getTime())
    .entrySeq();

  // if (!productCode) return <div> "no Summary Information available" </div>;
  if (loading && byDayPairs.count() === 0) return <div style={{display: "flex", placeContent: "center"}}><CircularProgress color="secondary" /> </div>;
  if (results.length === 0) return <div>< Translate id="message.error.NoConfirmedNotifications" /></div>;

  return (
    <RootRef rootRef={ref}>
      <Box>
        <Box marginBottom={2}>
          <Typography component="h3" variant="h6">
            <Translate id="components.notificationshistory.title" />
          </Typography>
        </Box>
        {byDayPairs
          .zipAll(byDayPairs.skip(1))
          .map(([[day, notifs], nextPair]) => (
            <DayGroup
              key={day}
              dayMs={day}
              notifications={notifs}
              temporalHeight={
                nextPair
                  ? Math.min(3 * differenceInCalendarDays(day, nextPair[0]), 500)
                  : 0
              }
              isLast={!nextPair}
            />
          ))}
        {loading && <div style={{display: "flex", placeContent: "center"}}><CircularProgress color="secondary" /> </div>}
      </Box>
    </RootRef>
  );
};
export default withLocalize(NotificationsHistory);
