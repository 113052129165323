import * as t from "types/apolloGenerated/notificationsHistory";

export type QueryData = t.notificationsHistory;
export type QueryVars = t.notificationsHistoryVariables;
export type PerlaNotification = { icon: string, title: string, untranslatedTitle: string, subTitle: string, untranslatedSubTitle: string, date: Date, notificationJSON: (t.notificationsHistory_productInstance_instanceInformation_PerlaInstanceInformation_eventsRawData_messages | null)[] | null | undefined }
export type SilkNotification = { icon: string, message: string, date: Date }


export enum Level {
  E = "error",
  W = "warn",
  I = "info"
}

export function getLevel(notif: PerlaNotification): Level {
  switch (notif?.icon) {
    case "error":
      return Level.E;
    case "warning":
      return Level.W;
    default:
      return Level.I;
  }
}
