import React, { useState, useEffect } from 'react';
import { createStyles, Theme, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import AgGrid from 'components/common/Grid/AgGrid';
import { AppState } from 'redux/store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setGridState, setColumnStates } from 'redux/actions/ProductRegistrationsActions';
import { State as ProductRegistrationsGridState } from 'redux/types/ProductRegistrations';
import { apolloConnectorById, getColumnDefs, CellRenderer } from './GridConfig'
import { Translate } from 'react-localize-redux';


interface ProductRegistrationsPara {
  id: number
  productRegistrationsState: ProductRegistrationsGridState
  actions: {
    setGridState: typeof setGridState
    setColumnStates: typeof setColumnStates
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: "30px",
      marginBottom: "50px",
      '& h1': {
        marginBottom: '20px',
      },
      '& .innerContent': {
        height: '300px',
        position: 'relative'
      }
    },
  }));


const ProductRegistrations: React.FC<ProductRegistrationsPara> = ({ id, productRegistrationsState, actions }) => {
  let classes = useStyles();

  const [apolloConnector, setApolloConnector] = useState<ReturnType<typeof apolloConnectorById>>(apolloConnectorById(id));

  useEffect(() => {
    setApolloConnector(apolloConnectorById(id));
  }, [id]);

  return (
    <Translate>{({ translate }) => {
      return (<Grid item xs={8} className={classes.container}>
        <h1><Translate id="pages.partnerDetails.productRegistrations" /></h1>
        <div className="innerContent">
          <AgGrid
            gridState={productRegistrationsState.grid}
            gridActions={{
              setGridState: actions.setGridState,
              setColumnStates: actions.setColumnStates
            }}
            apolloConnector={apolloConnector}
            frameworkComponents={CellRenderer}
            columnDefs={getColumnDefs(translate)}
            gridOptions={{ enableBrowserTooltips: true }}
          />
        </div>
      </Grid>);
    }}
    </Translate>
  )
};

const mapStateToProps = (appState: AppState) => ({
  productRegistrationsState: appState.productRegistrations
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ setGridState, setColumnStates }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductRegistrations);