import React, { useRef, useState } from "react";
import {
  TextField,
  createStyles,
  Theme,
  Button,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import {
  OrganizationJoinRequest,
  OrganizationJoinRequestVariables,
  OrganizationJoinRequest_organizationJoinRequest,
} from "types/apolloGenerated/OrganizationJoinRequest";
import {
  OrganizationJoinApprovalMutation,
  OrganizationJoinApprovalMutationVariables,
} from "types/apolloGenerated/OrganizationJoinApprovalMutation";
import { Position } from "types/apolloGenerated/globalTypes";
import { useQuery, useMutation } from "react-apollo";
import cloneDeep from "clone-deep";
import gql from "graphql-tag";
import { CircularProgress } from "@material-ui/core";
import Error, { CustomError } from "components/common/Error";
import { useHistory } from "react-router";
import { qraphQlErrorsToString } from "util/GraphQL";
import format from "date-fns/format";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      marginBottom: "50px",
      paddingTop: "15px",
      "& .MuiTextField-root, & .MuiFormControl-root": {
        width: "100%",
        "& .MuiInputLabel-outlined": {
          transform: "translate(14px, 15px) scale(1)",
          fontSize: "0.9rem",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
      "& .MuiGrid-grid-xs-6 .MuiGrid-item:not(:first-child):not(:last-child)": {
        padding: "0px 10px",
      },
      "& .Mui-disabled": {
        cursor: "not-allowed",
      },
    },
    button: {
      width: "100%",
    },
    errorLink: {
      color: "#ffffff!important",
      textDecoration: "none",
      "&:hover": {
        color: "#cccccc!important",
        textDecoration: "underline",
      },
    },
    loadingSymbol: {
      marginTop: "40px",
      marginLeft: "50%",
    },
  })
);

interface ApprovalFormProps {
  requestId: string;
}

const QUERY_ORGANIZATION_JOIN_REQUEST = gql`
  query OrganizationJoinRequest($requestId: Guid!) {
    organizationJoinRequest(requestId: $requestId) {
      completionTimestamp
      creationTimestamp
      organization {
        id
        name
        address {
          city
          countryCode
          postalCode
          street
        }
        vatNumber
        website
      }
      status
      user {
        id
        key
        firstName
        lastName
        telephone
        email
        employee {
          organizationId
        }
      }
    }
  }
`;

const MUTATE_ORGANIZATION_JOIN_APPROVAL = gql`
  mutation OrganizationJoinApprovalMutation(
    $approved: Boolean!
    $requestId: Guid!
    $position: Position!
  ) {
    organization {
      organizationJoinRequestApproval(
        approved: $approved
        requestId: $requestId
        position: $position
      )
    }
  }
`;

const ApprovalForm: React.FC<ApprovalFormProps> = ({ requestId }) => {
  const classes = useStyles();
  const history = useHistory();

  const variant: "standard" | "outlined" | "filled" = "standard";
  const autoComplete = "off";

  const {
    data: organizationData,
    loading: queryLoading,
    error: queryError,
  } = useQuery<OrganizationJoinRequest, OrganizationJoinRequestVariables>(
    QUERY_ORGANIZATION_JOIN_REQUEST,
    {
      variables: { requestId: requestId },
    }
  );
  const [declined, setDeclined] = useState<boolean>(false);
  const [
    mutateOrganizationApproval,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation<
    OrganizationJoinApprovalMutation,
    OrganizationJoinApprovalMutationVariables
  >(MUTATE_ORGANIZATION_JOIN_APPROVAL);

  const organizationRef = useRef<
    OrganizationJoinRequest_organizationJoinRequest | undefined
  >();
  if (typeof organizationRef.current == "undefined") {
    organizationRef.current =
      cloneDeep(organizationData?.organizationJoinRequest) ?? undefined;
  }

  const onChangeFunc = (value: string, name: string) => {
    let tmpObj: any = {
      ...organizationRef.current,
      [name]: value,
    };
    organizationRef.current = tmpObj;
  };

  const approveOrganization = (approved: boolean) => {
    if (!approved) {
      setDeclined(true);
    }

    mutateOrganizationApproval({
      variables: {
        approved: approved,
        requestId: requestId,
        position: Position.OWNER,
      },
    }).catch((error) => {
      console.log(
        "Error while approving/declining organization request: ",
        error
      );
    });
  };

  if (queryLoading) {
    return <CircularProgress color="secondary" />;
  }

  if (queryError) {
    switch (queryError.graphQLErrors[0].extensions?.["code"]) {
      case "FORMAT":
        return <Translate id={"pages.partnerRequest.formatError"} />;
      default:
        return <Translate id={"pages.partnerRequest.error"} />;
    }
  }

  if (mutationData?.organization?.organizationJoinRequestApproval) {
    if (declined) {
      history.push("/pam/partner");
    } else {
      history.push(
        "/pam/partner/" +
          organizationData?.organizationJoinRequest?.organization?.id
      );
    }
  }

  const error = organizationData?.organizationJoinRequest?.user?.employee
    ?.organizationId ? (
    <CustomError>
      <Translate
        id="pages.partnerRequest.userAlreadyAssigned"
        data={{
          link: (
            <Link
              className={classes.errorLink}
              to={
                "/pam/partner/" +
                organizationData?.organizationJoinRequest?.user?.employee
                  ?.organizationId
              }
            >
              Click Here
            </Link>
          ),
        }}
      />
    </CustomError>
  ) : null;

  const mutationErrorElement = mutationError ? (
    <Translate>
      {({ translate }) => (
        <Error
          error={
            (translate("pages.partnerRequest.mutationError") as string) +
            " " +
            qraphQlErrorsToString(mutationError.graphQLErrors, translate)
          }
        />
      )}
    </Translate>
  ) : null;

  const alreadyHandled = !error &&
    organizationData?.organizationJoinRequest?.completionTimestamp && (
      <Translate>
        {({ translate }) => (
          <Error
            error={
              (translate(
                "pages.partnerRequest.alreadyHandledRequest"
              ) as string) +
              " " +
              format(
                new Date(
                  organizationData?.organizationJoinRequest?.completionTimestamp
                ),
                translate("generics.dateTimeFormatFNS") as string
              )
            }
          />
        )}
      </Translate>
    );

  const jxGrid =
    error || alreadyHandled ? null : mutationLoading ? (
      <Grid item>
        <CircularProgress className={classes.loadingSymbol} color="secondary" />
      </Grid>
    ) : (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="outlined"
            className={classes.button}
            disabled={true}
            onClick={() => {
              //approveOrganization(true);
            }}
          >
            Approve
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="outlined"
            className={classes.button}
            disabled={true}
            onClick={() => {
              //approveOrganization(false);
            }}
          >
            Decline
          </Button>
        </Grid>
      </Grid>
    );

  return (
    <Grid
      container
      className={classes.container}
      style={{ position: "relative" }}
    >
      <Grid item xs={8}>
        {mutationErrorElement}
        {error}
        {alreadyHandled}
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextField
              defaultValue={requestId}
              label={<Translate id="pages.partnerRequest.requestId" />}
              variant={variant}
              autoComplete={autoComplete}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              defaultValue={organizationRef?.current?.organization?.name ?? ""}
              label={<Translate id="pages.partnerRequest.organizationName" />}
              variant={variant}
              autoComplete={autoComplete}
              name="organizationName"
              onChange={(evt) => {
                onChangeFunc(evt.target.value, evt.target.name);
              }}
              disabled={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              defaultValue={
                organizationRef?.current?.organization?.address?.street ?? ""
              }
              label={<Translate id="types.address.street" />}
              variant={variant}
              autoComplete={autoComplete}
              name="street"
              onChange={(evt) => {
                onChangeFunc(evt.target.value, evt.target.name);
              }}
              disabled={true}
            />
          </Grid>

          <Grid item xs={1}>
            <TextField
              defaultValue={
                organizationRef?.current?.organization?.address?.postalCode ??
                ""
              }
              label={<Translate id="types.address.postalCode" />}
              variant={variant}
              autoComplete={autoComplete}
              name="postalCode"
              onChange={(evt) => {
                onChangeFunc(evt.target.value, evt.target.name);
              }}
              disabled={true}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              defaultValue={
                organizationRef?.current?.organization?.address?.city ?? ""
              }
              label={<Translate id="types.address.city" />}
              variant={variant}
              autoComplete={autoComplete}
              name="city"
              onChange={(evt) => {
                onChangeFunc(evt.target.value, evt.target.name);
              }}
              disabled={true}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              defaultValue={
                organizationRef?.current?.organization?.address?.countryCode ??
                ""
              }
              label={<Translate id="types.address.countryCode" />}
              variant={variant}
              autoComplete={autoComplete}
              name="countryCode"
              onChange={(evt) => {
                onChangeFunc(evt.target.value, evt.target.name);
              }}
              disabled={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              defaultValue={
                organizationRef?.current?.organization?.vatNumber ?? ""
              }
              label={<Translate id="types.common.vatNumber" />}
              variant={variant}
              autoComplete={autoComplete}
              name="vatNumber"
              onChange={(evt) => {
                onChangeFunc(evt.target.value, evt.target.name);
              }}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              defaultValue={
                organizationRef?.current?.organization?.website ?? ""
              }
              label={<Translate id="pages.partnerRequest.website" />}
              variant={variant}
              autoComplete={autoComplete}
              name="website"
              onChange={(evt) => {
                onChangeFunc(evt.target.value, evt.target.name);
              }}
              disabled={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              defaultValue={organizationRef?.current?.user?.firstName ?? ""}
              label={<Translate id="types.userInformation.firstName" />}
              variant={variant}
              autoComplete={autoComplete}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              defaultValue={organizationRef?.current?.user?.lastName ?? ""}
              label={<Translate id="types.userInformation.lastName" />}
              variant={variant}
              autoComplete={autoComplete}
              disabled={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              defaultValue={organizationRef?.current?.user?.email ?? ""}
              label={<Translate id="generics.email" />}
              variant={variant}
              autoComplete={autoComplete}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              defaultValue={organizationRef?.current?.user?.telephone ?? ""}
              label={<Translate id="types.userInformation.telephone" />}
              variant={variant}
              autoComplete={autoComplete}
              disabled={true}
            />
          </Grid>
          {jxGrid}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ApprovalForm;
