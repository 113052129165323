import gql from "graphql-tag";


const GET_AUDIT_LOGS = gql`
    query objectAudit($objectType: AuditLogObject!, $objectId: String!, $pageNumber: Int!, $pageSize: Int!) {
     objectAudit(objectType: $objectType, objectId: $objectId, page: $pageNumber, pageSize: $pageSize) {
       client,
       operation,
       timestamp,
       userId,
       email,
       details
     }
    }
`

export default GET_AUDIT_LOGS;