import { ActionType, Action } from 'redux/types/AccountShortcuts/RemoveFromCompany';


export const open = (userId: number, partnerId: number): Action => ({
  type: ActionType.OPEN,
  payload: {
    userId: userId,
    partnerId: partnerId
  }
});

export const close = (): Action => ({
  type: ActionType.CLOSE,
});
