import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';

import CouponCodes from 'Pages/Promotion/CouponCode'
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { bindActionCreators, Dispatch } from 'redux';
import { dirty, load, save } from 'redux/actions/CouponCodeActions'
import { Store as CouponCodeStore } from 'redux/types/CouponCode';
import { Couponcode } from 'types/ToolboxEntities';
import { useParams } from 'react-router';
import PageHeader from 'components/common/PageHeader';

import { Translate } from 'react-localize-redux';

interface couponCodeData {
  couponCode: CouponCodeStore
  actions: {
    dirty: () => void,
    load: (id: number) => void,
    save: (couponCode: Couponcode) => void
  }
};

export type changeFuncEventType = React.SyntheticEvent<any, Event> | React.ChangeEvent<{ value: unknown, name?: string }>;

const CouponCode: React.FC<couponCodeData> = ({ couponCode, actions }) => {
  let { id } = useParams();
  let idNum = Number(id);


  useEffect(() => {
    actions.load(idNum);
  }, [actions, idNum]);


  const [couponCodeData, setCouponCodeData] = useState<Couponcode | null>(couponCode.code);

  useEffect(() => {
    setCouponCodeData(couponCode.code);
  }, [couponCode.code]);

  const onChangeSetData = (value: any, name: string) => {
    // console.log("pre change: ", partnerData);
    let newCouponCodeData = changeNestedProp(couponCodeData, value, name.split("/"));
    setCouponCodeData(newCouponCodeData);
  }

  return (
    couponCode.code && couponCode.code.itemNumber === idNum ? (
      <Grid container >
        <Grid item xs={12}>
          <PageHeader title={'Coupon Code: ' + couponCode.code.name} showBack onSave={() => { if (couponCodeData) { console.log("pre save: ", couponCodeData); actions.save(couponCodeData); } }} />
        </Grid>
        <Grid item xs={12}>
          <Box>

          </Box>
        </Grid>
        <Grid item xs={12}>
          <CouponCodes data={couponCode.code} onChangeFunc={onChangeSetData} />
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid>)
      : <div><Translate id={"generics.loading"} /></div>
  );
}

export const changeNestedProp: any = (object: any, value: any, props: string[]) => {
  if (props.length > 1) {
    return {
      ...object,
      [props[0]]: changeNestedProp(object[props[0]], value, props.slice(1))
    }
  }
  else {
    return {
      ...object,
      [props[0]]: value
    };
  }
}

const mapStateToProps = (state: AppState) => ({
  couponCode: state.couponCode,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ dirty, load, save }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponCode);
