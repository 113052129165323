import React, { useMemo, useState } from "react";
import { Translate } from "react-localize-redux";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import MultiSelect, { MultiSelectOption } from "components/common/MultiSelect";

import { consumableNameOnly, consumableNameOnly_consumableProducts } from "types/apolloGenerated/consumableNameOnly"
import { productById_product_productInformation } from "types/apolloGenerated/productById";

const CONSUMABLE_LIST = gql`
  query consumableNameOnly {
    consumableProducts{
      name
      id
    }
  }
`;

const useStyles = makeStyles(() => {
  return createStyles({
    container: {
      margin: "0 30px 40px 30px"
    },
    title: {
      marginBottom: "30px",
    },
    addSpinner: {
      marginLeft: "40px",
      position: "absolute"
    }
  });
});


const mapReduceToOptionType = (reduced: MultiSelectOption[], consumable: consumableNameOnly_consumableProducts | null | undefined) => {
  if (consumable) {
    reduced.push({
      value: consumable.id + '',
      label: consumable.name
    });
  }
  return reduced;
};



interface ConsumableProps {
  productInfoObj: productById_product_productInformation
}

const Consumable: React.FC<ConsumableProps> = ({ productInfoObj }) => {

  const classes = useStyles();

  const { data: consumables } = useQuery<consumableNameOnly>(CONSUMABLE_LIST)

  const [selectedConsumables, setSelectedConsumables] = useState<MultiSelectOption[]>(
    productInfoObj.consumableProducts?.reduce(mapReduceToOptionType, []) || []
  );

  const selectableConsumables = useMemo(() => {
    if (consumables?.consumableProducts) {
      return consumables?.consumableProducts.reduce(mapReduceToOptionType, []);
    }
    return [];
  }, [consumables]);

  return (
    <Box className={classes.container}>
      <Typography variant="h6" component="h3" className={classes.title}>
        <Translate id="types.common.consumables" />
      </Typography>
      <MultiSelect
        headline="types.product.chooseConsumable"
        options={selectableConsumables}
        value={selectedConsumables}
        error={null}
      />
    </Box>
  );
};
export default Consumable;
