import React, { Fragment } from "react";
import { Translate } from "react-localize-redux";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, Select, MenuItem, CircularProgress } from "@material-ui/core";
import { Alert } from '@material-ui/lab';

import NotificationGroup from "./NotifcationGroup";
import { productById_product_productInformation_productUserAction as UserAction } from "types/apolloGenerated/productById";
import { UserActionClass } from "types/apolloGenerated/globalTypes";
import { addUserAction as add, addUserActionVariables as addVariables } from "types/apolloGenerated/addUserAction";
import { updateProductByIdCache } from "commonQueries/product";

const useStyles = makeStyles(() => {
  return createStyles({
    container: {
      paddingTop: "20px",
      width: "98%",
      maxWidth: "1645px"
    },
    title: {
      marginLeft: "30px",
      padding: "0 720px 20px 0"
    },
    addSpinner: {
      marginLeft: "40px",
      position: "absolute"
    }
  });
});

const ADD_USER_ACTION = gql`
  mutation addUserAction($productUserAction: ProductUserActionInsert!) {
    userAction {
      createProductUserAction(productUserAction: $productUserAction) {
        id
        userActionClass
      }
    }
  }
`;

const NotificationGroups: React.FC<{ userActions: UserAction[]; productId: number; }> = ({ userActions, productId }) => {
  const classes = useStyles();
  const [selectedNotifGroup, setSelectedNotifGroup] = React.useState(0);
  const [error, setError] = React.useState("")

  const listOfUserActions: string[] = [];
  for (const userActionClass in UserActionClass) {
    listOfUserActions.push(userActionClass);
  }

  const [addUserAction, { loading: addActionLoading }] = useMutation<add, addVariables>(ADD_USER_ACTION, {
    update(cache, { data }) {
      if (data?.userAction?.createProductUserAction?.id) {
        const id = data.userAction.createProductUserAction.id;
        updateProductByIdCache(cache, productId, p => {
          p.product?.productInformation?.productUserAction?.push({
            __typename: "ProductUserAction",
            userActionClass: listOfUserActions[
              selectedNotifGroup
            ] as UserActionClass,
            notifications: [],
            id: id
          });
          return p;
        });
      }
    },
    onError: error => {
      setError("Unable to add user action - " + error.message)
    }
  });

  return (
    <Box className={classes.container} >
      <Typography
        variant="h6"
        component="h3"
        className={classes.title}
      >
        <Translate id="types.common.notifications" />
      </Typography>
      {Object.values(userActions).map((value, idx) => {
        return (
          <NotificationGroup
            key={idx}
            idx={idx}
            title={value.userActionClass}
            currentNotifs={value.notifications}
            userActionId={value.id}
            productId={productId}
          />
        );
      })}
      <Box style={{ margin: "20px 0px 60px 30px" }}>
        <Box>
          <Translate id="types.product.notifGroup" />
        </Box>
        <Select
          style={{ width: "350px" }}
          variant="standard"
          value={selectedNotifGroup}
          disabled={true}
        >
          {listOfUserActions.map((name, idx) => (
            <MenuItem key={idx} value={idx}>
              {<Translate id={`pages.product.notifGroups.${name}`} />}
            </MenuItem>
          ))}
        </Select>
        {!addActionLoading ? (
          <Button
            variant="outlined"
            disabled={true}
            style={{ marginLeft: "22px" }}
          >
            <Translate id="generics.add" />
          </Button>
        ) : (
            <CircularProgress
              color="secondary"
              size={30}
              className={classes.addSpinner}
            />
          )}
        {error !== "" ? <Alert style={{ marginTop: "10px" }} severity="error">{error}</Alert> : <Fragment />}
      </Box>
    </Box>
  );
};
export default NotificationGroups;
