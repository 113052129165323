import React, { Fragment } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { TextField, Button, Checkbox, FormControlLabel, CircularProgress, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab"
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import cloneDeep from "clone-deep";
import { ApolloClient } from "apollo-client";
import { Translate, withLocalize, LocalizeContextProps } from "react-localize-redux";

import PageHeader from "components/common/PageHeader";
import ConsumableGrid from "./ConsumableGrid";
import GridFilterHeader from "components/common/Grid/GridFilterHeader";
import {
  createConsumable as create,
  createConsumableVariables,
} from "types/apolloGenerated/createConsumable";
import { CONSUMABLES } from "./ConsumableGrid";
import { consumableGrid as Consumables } from "types/apolloGenerated/consumableGrid";
import { ConsumableProductInsert as Consumable } from "types/apolloGenerated/globalTypes"

const useStyles = makeStyles(() => {
  return createStyles({
    consumableOverview: {
      display: "flex",
      flexFlow: "column",

      "& > .gridFilterHeader": {
        flex: "0 1 auto",
        margin: "0",
      },

      "& > .innerContent": {
        flex: "1 1 auto",
        height: "100%",
      },
    },
    loadingSpinner: {
      minWidth: "220px",
      display: "flex",
      justifyContent: "center",
      marginTop: "10px"
    },
    createConsumablebutton: {
      marginTop: "10px",
      minWidth: "220px",
      height: "42px"
    },
    reactiveBoxesRight: {
      minWidth: "350px",
      width: "90%",
      margin: "0 0 23px 30px",
    },
  });
});

const CREATE_CONSUMABLE_PRODUCT = gql`
  mutation createConsumable($consumableProduct: ConsumableProductInsert!) {
    consumableProduct {
      insert(consumableProduct: $consumableProduct) {
        id
        name
      }
    }
  }
`;

interface ProductDetailViewProps extends LocalizeContextProps {
  client: ApolloClient<any>
}



const ConsumableList: React.FC<ProductDetailViewProps> = ({ client, translate }) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState<string>("");
  const [error, setError] = React.useState<string>("")
  const [newConsumable, setNewConsumable] = React.useState<Consumable>({ name: "", pearlsRewarded: 0, itemNumber: "", isActive: true });
  const [nameError, setNameError] = React.useState({ error: false, text: "" })
  const [pearlsRewardedError, setPearlsRewardedError] = React.useState({ error: false, text: "" })

  const [itemNumberError, setItemNumberError] = React.useState({ error: false, text: "" })

  const [createConsumable, { loading }] = useMutation<
    create,
    createConsumableVariables
  >(CREATE_CONSUMABLE_PRODUCT, {
    onCompleted: (data) => {
      try {
        const variables = {};
        const queryCache = cloneDeep(
          client.readQuery<Consumables>({
            query: CONSUMABLES,
            variables: variables,
          })
        );

        if (queryCache?.consumableProducts) {
          queryCache.consumableProducts = [
            ...queryCache.consumableProducts,
            {
              __typename: "ConsumableProduct",
              id: data.consumableProduct?.insert?.id ?? -1,
              name: newConsumable.name,
              itemNumber: newConsumable.itemNumber,
              isActive: newConsumable.isActive,
              pearlsRewarded: newConsumable.pearlsRewarded,
              products: [],
            },
          ];
        }

        client.writeQuery({
          query: CONSUMABLES,
          variables: variables,
          data: queryCache,
        });
      } catch (e) {
        console.log(e);
      }
      setNewConsumable({
        name: "",
        itemNumber: "",
        pearlsRewarded: 0,
        isActive: true
      })
    },
    onError: (error) => { setError(error.message) },
  });

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <Fragment>
      <main
        className={
          "mainContentContainer fullHeight " + classes.consumableOverview
        }
      >
        <PageHeader title="pages.consumable.navTitle" />
        <GridFilterHeader>
          <TextField
            className="filter"
            id="standard-basic"
            label="Type to filter"
            value={search}
            onChange={changeSearch}
          />
        </GridFilterHeader>
        {error !== "" ? <Alert severity="error">{error}</Alert> : <Fragment />}
        <div className="innerContent">
          <ConsumableGrid search={search} />
        </div>
      </main>
    </Fragment>
  );
};
export default withLocalize(ConsumableList);

