import React from 'react';
import ChipInput from 'material-ui-chip-input'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Translate, withLocalize, LocalizeContextProps } from "react-localize-redux";

import { Error } from 'types/CampGuideTypes';

import { FormHelperText } from '@material-ui/core';
import { TranslateError } from './Error';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 290,
      width: '100%'
    }
  }),
);

interface InputToChipInterface extends LocalizeContextProps {
  employeeRoles: string[]
  onChange: (selected: string[]) => void
  disabled?: boolean
  error?: Error | null
}

const InputToChip: React.FC<InputToChipInterface> = ({ employeeRoles, onChange, error, disabled = false }, props) => {
  const classes = useStyles();

  const handleAddChip = (chip: any) => {
    var newEmpl = [...employeeRoles];
    newEmpl.push(chip);
    onChange(newEmpl);
  };

  const handleDeleteChip = (chip: any, index: number) => {
    var newEmpl = employeeRoles.filter((item) => {
      return item !== chip;
    })
    onChange(newEmpl);
  };

  return (
    <Translate>
      {({ translate }) => (
        <div>
          <label>
            <Translate id="components.employeeRoles.employeeRoles" />
            <ChipInput classes={{ root: classes.root }}
              value={employeeRoles}
              placeholder={translate('components.employeeRoles.enterRolesTxt') as string}
              onDelete={handleDeleteChip}
              onAdd={handleAddChip}
            />
          </label>
          {error ? <FormHelperText className={"Mui-error"}><TranslateError error={error} /></FormHelperText> : null}
        </div >
      )}
    </Translate>
  );
}

//export default InputToChip;
export default withLocalize(InputToChip);
