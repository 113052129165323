import { ActionType, SearchAction } from "redux/types/EmployeesOverview";
import generateGridReducer from "redux/reducers/AgGridReducerHelper";
import { combineReducers } from "redux";
import { State } from "redux/types/AgGrid";

const GridState: State = {
  sortModel: [],
  filterModel: {
    "userInformation.accountLocked": {
      filterType: "set",
      values: ["false"],
    },
  },
  page: 0,
  columnStates: undefined,
};

const searchReducer = (
  state: string | null = null,
  action: SearchAction
): string | null => {
  switch (action.type) {
    case ActionType.SEARCH:
      return action.payload;

    default:
      return state;
  }
};

export default combineReducers({
  grid: generateGridReducer<ActionType>(ActionType.GRID, GridState),
  search: searchReducer,
});
