import * as React from "react";

interface WifiGoodSVGProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
  colorfading?: string;
}

function SvgComponent(props: WifiGoodSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
      {...props}
    >
      <path data-name="Rectangle 1268" fill="none" d="M0 0h25v25H0z" />
      <path
        data-name="Path 2985"
        d="M12.51 22.385L24.625 7.292A20.117 20.117 0 0012.5 3.125 20.082 20.082 0 00.375 7.292L12.49 22.385l.01.01.01-.01z"
        fill={props.colorfading ? props.colorfading : "rgba(0,103,182,0.7)"}
        fillRule="evenodd"
      />
      <path
        data-name="Path 2986"
        d="M3.677 11.406l8.813 10.979.01.01.01-.01 8.814-10.979a14.56 14.56 0 00-8.823-3.073 14.56 14.56 0 00-8.824 3.073z"
        fill={props.color ? props.color : "#0067b6"}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
