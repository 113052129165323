import React, { useState, Fragment } from "react";
import { createStyles, Theme, Grid, Button, Box } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import gql from "graphql-tag";
import { ReduxAgGrid } from "components/common/Grid/AgGrid";

import { open as changePearlsOpen } from "redux/actions/AccountShortcuts/ChangePearlsActions";
import { PearlsTransaction } from "types/ToolboxEntities";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { createBonusAccountVariables } from "types/apolloGenerated/createBonusAccount";

import { disableBonusAccountVariables } from "types/apolloGenerated/disableBonusAccount";

import {
  makeStyles,
  IconButton,
  FormControl,
  Tooltip,
} from "@material-ui/core";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Translate,
  LocalizeContextProps,
  withLocalize,
} from "react-localize-redux";
import {
  setGridState,
  setColumnStates,
} from "redux/actions/BonusAccountActions";
import { getColumnDefs } from "./GridConfig";
import { AppState } from "redux/store";
import { State as BonusAccountGridState } from "redux/types/BonusAccount";
import {
  PartnerType,
  BonusAccountPermission,
  Country,
} from "types/apolloGenerated/globalTypes";
import { PartnerDetail_organization_bonusaccount as BonusAccountObj } from "types/apolloGenerated/PartnerDetail";
import PageDialog from "components/common/PageDialog";

import { CellRenderer } from "./GridConfig";

import Edit from "@material-ui/icons/Edit";
import Link from "@material-ui/core/Link";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Alert, AlertTitle } from "@material-ui/lab";

import { BonusAccountTransactions } from "types/apolloGenerated/BonusAccountTransactions";
import { QUERY_BONUS_ACCOUNT } from "Pages/PartnerManager/Companies/Detail/BonusAccount/GridConfig";

const CREATE_BONUS_ACCOUNT = gql`
  mutation createBonusAccount($bonusAccountInsert: BonusAccountInsertInput!) {
    organization {
      createBonusAccount(bonusAccountInsert: $bonusAccountInsert)
    }
  }
`;

const DISABLE_BONUS_ACCOUNT = gql`
  mutation disableBonusAccount($bonusAccountUpdate: BonusAccountUpdateInput!) {
    organization {
      updateBonusAccount(bonusAccountUpdate: $bonusAccountUpdate)
    }
  }
`;

const CHANGE_BONUS_ACCOUNT_RIGHTS = gql`
  mutation updateBonusAccount($bonusAccountUpdate: BonusAccountUpdateInput!) {
    organization {
      updateBonusAccount(bonusAccountUpdate: $bonusAccountUpdate)
    }
  }
`;

interface BonusAccountPara extends LocalizeContextProps {
  id: number;
  bonusAccount: BonusAccountObj | null;
  bonusAccountState: BonusAccountGridState;
  newPearlsState: string | undefined;
  actions: {
    setGridState: typeof setGridState;
    setColumnStates: typeof setColumnStates;
  };
  partnerType: PartnerType | null;
  isSavePending: boolean;
  countryCode: Country | null | undefined;
  onSave: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: "30px",
      marginBottom: "50px",
      "& h1": {
        marginBottom: "20px",
      },
      "& .innerContent": {
        height: "300px",
        position: "relative",
      },
    },
    link: {
      cursor: "pointer",
    },
    editPearlsBtn: {
      color: "#005D8F",
    },
    disableButton: {
      margin: "10px 10px 10px 20px",
    },
    tooltipText: {
      visibility: "hidden",
      color: "black",
    },
  })
);

interface ChangePearlsTrigger {
  actions: { changePearlsOpen: typeof changePearlsOpen };
  orgId: number;
}

const BonusAccount: React.FC<BonusAccountPara> = ({
  id,
  bonusAccountState,
  bonusAccount,
  actions,
  partnerType,
  translate,
  newPearlsState,
  isSavePending,
  countryCode,
  onSave,
}) => {
  let classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [bonusAccountRightsDialog, setBonusAccountRightsDialog] = useState(
    false
  );
  const [error, setError] = useState("");
  const [bonusAccountRightsError, setBonusAccountRightsError] = useState("");
  const { data } = useQuery<BonusAccountTransactions>(QUERY_BONUS_ACCOUNT, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  const ChangePearlsButton = connect(null, (dispatch: Dispatch) => ({
    actions: bindActionCreators({ changePearlsOpen }, dispatch),
  }))(({ actions }: ChangePearlsTrigger) =>
    bonusAccount ? (
      <IconButton
        className={classes.editPearlsBtn}
        aria-label="toggle password visibility"
        onClick={() => {
          actions.changePearlsOpen({
            id: bonusAccount.accountId,
            pearls: bonusAccount.pearls,
            note: "",
            orgId: id,
          } as PearlsTransaction);
        }}
        edge="end"
      >
        {<Edit />}
      </IconButton>
    ) : null
  );

  const [
    createBonusAccount,
    { loading: createLoading },
  ] = useMutation<createBonusAccountVariables>(CREATE_BONUS_ACCOUNT, {
    onCompleted: (data) => {
      onSave();
      setConfirmDialog(false);
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const [
    disableBonusAccount,
    { loading: disableLoading },
  ] = useMutation<disableBonusAccountVariables>(DISABLE_BONUS_ACCOUNT, {
    onCompleted: () => {
      setConfirmDialog(false);
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const [
    updateBonusAccountRights,
    { loading: updateRightsLoading },
  ] = useMutation<undefined>(CHANGE_BONUS_ACCOUNT_RIGHTS, {
    onCompleted: (data) => {
      setBonusAccountRightsDialog(false);
      setBonusAccountPermission(tempPermission);
    },
    onError: (error) => {
      setBonusAccountRightsError(error.message);
    },
  });

  const fetchBonusAccountType = () => {
    switch (data?.organization?.bonusaccount?.details?.__typename) {
      case "BwtBonusAccountDetails":
        return <Translate id="pages.partnerDetails.bonusAccountBwt" />;
      case "ConnexBonusAccountDetails":
        return <Translate id="pages.partnerDetails.bonusAccountConnex" />;
      default:
        return <Translate id="pages.partnerDetails.bonusAccountNone" />;
    }
  };

  const getPermission = (value: string) => {
    switch (value.toUpperCase()) {
      case "WRITE":
        return BonusAccountPermission.WRITE;
      case "READ":
        return BonusAccountPermission.READ;
      case "DEACTIVATED":
        return BonusAccountPermission.DEACTIVATED;
    }
    return BonusAccountPermission.WRITE;
  };

  const permission: BonusAccountPermission = getPermission(
    bonusAccount?.permission ?? ""
  );

  const [
    bonusAccountPermission,
    setBonusAccountPermission,
  ] = React.useState<BonusAccountPermission>(permission);
  const [
    tempPermission,
    setTempPermission,
  ] = React.useState<BonusAccountPermission>(permission);
  const radioGroupRef = React.useRef(null);

  const handleCancel = () => {
    setBonusAccountRightsDialog(false);
  };

  const handleOk = () => {
    if (bonusAccount?.accountId) {
      updateBonusAccountRights({
        variables: {
          bonusAccountUpdate: {
            bonusAccountId: bonusAccount?.accountId,
            permission: tempPermission,
          },
        },
      });
    } else {
      setBonusAccountRightsError(
        translate("pages.partnerDetails.noBonusAccountId") as string
      );
    }
  };

  if (!bonusAccount) {
    return (
      <Grid item xs={8} className={classes.container}>
        <h1>
          <Translate id="pages.partnerDetails.bonusAccount" />
        </h1>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setConfirmDialog(true)}
        >
          <Translate id="pages.partnerDetails.createBonusAccount" />
        </Button>
        <PageDialog
          open={confirmDialog}
          error={error}
          loading={createLoading}
          title={"pages.partnerDetails.createBAAreYouSure"}
          buttons={[
            {
              title: "generics.true",
              click: () => {
                createBonusAccount({
                  variables: { bonusAccountInsert: { organizationId: id } },
                });
              },
            },
            {
              title: "generics.cancel",
              click: () => {
                setConfirmDialog(false);
                setError("");
              },
            },
          ]}
        />
      </Grid>
    );
  }
  const gridData =
    data && data.organization?.bonusaccount?.transactions?.results
      ? data.organization?.bonusaccount?.transactions?.results
      : [];

  return (
    <Translate>
      {({ translate }) => {
        return (
          <Grid item xs={8} className={classes.container}>
            <h1>
              <Translate id="pages.partnerDetails.bonusAccount" />
            </h1>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <div style={{ fontWeight: "bold", marginRight: "5px" }}>
                  <Translate id="pages.partnerDetails.bonusAccountType" />
                </div>
                {fetchBonusAccountType()}
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ fontWeight: "bold", marginRight: "5px" }}>
                  <Translate id="pages.partnerDetails.bonusAccountRights" />
                </div>
                <Link
                  underline="always"
                  className={classes.link}
                  color="inherit"
                  onClick={() => {
                    setBonusAccountRightsDialog(true);
                  }}
                >
                  {bonusAccountPermission}
                </Link>
              </div>
            </div>

            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
              aria-labelledby="confirmation-dialog-title"
              open={bonusAccountRightsDialog}
              // {...other}
            >
              <DialogTitle id="confirmation-dialog-title">
                Bonus Account Rights
              </DialogTitle>
              <DialogContent dividers>
                {bonusAccountRightsError && (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {bonusAccountRightsError}
                  </Alert>
                )}
                <RadioGroup
                  ref={radioGroupRef}
                  aria-label="ringtone"
                  name="ringtone"
                  value={tempPermission}
                  onChange={(event) => {
                    setTempPermission(getPermission(event.target.value));
                  }}
                >
                  {Object.keys(BonusAccountPermission).map((value) => (
                    <FormControlLabel
                      value={value}
                      key={value}
                      control={<Radio disabled={updateRightsLoading} />}
                      label={value}
                    />
                  ))}
                </RadioGroup>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                  <Translate id="generics.cancel" />
                </Button>
                {updateRightsLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <Button onClick={handleOk} color="primary">
                    <Translate id="generics.save" />
                  </Button>
                )}
              </DialogActions>
            </Dialog>

            <h3>
              <Translate id="types.bonusAccount.subheadline" />
            </h3>
            <Box style={{ display: "flex" }}>
              <FormControl variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={"text"}
                  value={
                    newPearlsState
                      ? parseInt(newPearlsState) +
                        " " +
                        translate("types.common.pearls")
                      : (bonusAccount?.pearls ? bonusAccount.pearls : 0) +
                        " " +
                        translate("types.common.pearls")
                  }
                  onChange={(evt) => {}}
                  endAdornment={
                    <InputAdornment position="end">
                      {bonusAccountPermission ===
                      BonusAccountPermission.WRITE ? (
                        <ChangePearlsButton orgId={id} />
                      ) : (
                        <Tooltip
                          title={
                            translate("pages.partnerDetails.cantEditPearls") +
                            " " +
                            bonusAccountPermission
                          }
                        >
                          <IconButton>
                            <Edit htmlColor="#e6e6e6" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  }
                  labelWidth={0}
                />
              </FormControl>
              {bonusAccount?.details?.__typename ===
                "ConnexBonusAccountDetails" && (
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.disableButton}
                  href={bonusAccount?.details.shopLoginLink || ""}
                  target="_blank"
                >
                  <Translate id="pages.partnerDetails.conexShopLink" />
                </Button>
              )}
            </Box>
            <h3>
              <Translate id="types.bonusAccount.pearlsTransactions" />
            </h3>
            <div className="innerContent">
              <ReduxAgGrid
                columnDefs={getColumnDefs(translate)}
                rowData={gridData}
                gridState={bonusAccountState.grid}
                gridActions={actions}
                paginationPageSize={50}
                pagination={true}
                frameworkComponents={CellRenderer}
              />
            </div>
          </Grid>
        );
      }}
    </Translate>
  );
};

const mapStateToProps = (appState: AppState) => ({
  bonusAccountState: appState.bonusAccount,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ setGridState, setColumnStates }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(BonusAccount));
