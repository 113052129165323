import React, { Fragment } from 'react';
import { Translate } from "react-localize-redux";

import { UserInformation } from 'types/ToolboxEntities';
import format from "date-fns/format";

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    greyColor: {
      color: "#666B6E"
    }
  }));


interface IShowAccountInfo {
  account: UserInformation
  userLanguage?: string
}

const ShowAccountInfo: React.FC<IShowAccountInfo> = (props) => {
  const classes = useStyles();

  let lang = "en"

  if(!props.userLanguage){
    lang = props.account.preferredLanguage ?? "en"
  }
  else {
    lang = props.userLanguage
  }

  if (props.account != null) {
    let accountState;

    if (props.account.accountLocked) {
      accountState = <Translate id="components.showAccountInfo.closed" />;
    } else if (!props.account.allowedToLogin) {
      accountState = <Translate id="components.showAccountInfo.deactivated" />;
    } else if (!props.account.verifiedAccount) {
      accountState = <Translate id="components.showAccountInfo.notVerified" />;
    } else {
      accountState = <Translate id="types.common.active" />;
    }

    return (
      <Fragment>
        <h2 className={classes.greyColor} style={{ fontSize: "25px", fontWeight: 400 }}><Translate id="components.showAccountInfo.headline" /> </h2>
        <table className={classes.greyColor}>
          <tbody>
            <tr><td><Translate id="components.showAccountInfo.account" /> </td><td><span style={{ fontWeight: 700 }}>{props.account.id}</span></td></tr>
            <tr><td><Translate id="components.showAccountInfo.status" /> </td><td>{accountState}</td></tr>
            <tr><td></td><td></td></tr>
            <tr>
              <td><Translate id="components.showAccountInfo.registeredOn" /> </td>
              <td>
                {props.account.registeredOn ? props.account.registeredOn && (
                  <Translate>
                    {
                      (translate) => {
                        return <div>{format(new Date(props.account.registeredOn), translate.translate('generics.dateTimeFormatFNS') as string)}</div>
                      }
                    }
                  </Translate>
                ) : <div>-</div>}
              </td>
            </tr>
            <tr><td><Translate id="components.showAccountInfo.preferredLanguage" /></td><td>{lang.toUpperCase()}</td></tr>
            <tr><td><Translate id="components.showAccountInfo.lastLogin" /></td>
              <td>
                {props.account.lastLogin ? props.account.lastLogin && (
                  <Translate>
                    {
                      (translate) => {
                        return <div>{format(new Date(props.account.lastLogin), translate.translate('generics.dateTimeFormatFNS') as string)}</div>
                      }
                    }
                  </Translate>
                ) : <div>-</div>}
              </td></tr>
            <tr><td><Translate id="components.showAccountInfo.lastFailedPasswordReset" /></td>
              <td>
                {props.account.lastPasswordReset ? props.account.lastPasswordReset && (
                  <Translate>
                    {
                      (translate) => {
                        return <div>{format(new Date(props.account.lastPasswordReset), translate.translate('generics.dateTimeFormatFNS') as string)}</div>
                      }
                    }
                  </Translate>
                ) : <div>-</div>}
              </td></tr>
            <tr><td></td><td></td></tr>
            <tr><td><Translate id="components.showAccountInfo.incorrectLogins" /></td><td>{props.account.incorrectLogins}</td></tr>
            <tr><td><Translate id="components.showAccountInfo.lastIncorrectLogin" /></td>
              <td>
                {props.account.lastIncorrectLogin ? props.account.lastIncorrectLogin && (
                  <Translate>
                    {
                      (translate) => {
                        return <div>{format(new Date(props.account.lastIncorrectLogin), translate.translate('generics.dateTimeFormatFNS') as string)}</div>
                      }
                    }
                  </Translate>
                ) : <div>-</div>}
              </td></tr>
            <tr><td></td><td></td></tr>
            <tr><td><Translate id="components.showAccountInfo.dataUpdate" /></td>
              <td>
                {props.account.dataUpdate ? props.account.dataUpdate && (
                  <Translate>
                    {
                      (translate) => {
                        return <div>{format(new Date(props.account.dataUpdate), translate.translate('generics.dateTimeFormatFNS') as string)}</div>
                      }
                    }
                  </Translate>
                ) : <div>-</div>}
              </td></tr>
            <tr><td></td><td></td></tr>
            <tr><td><Translate id="types.common.verified" /></td><td>{props.account.verifiedAccount ? <Translate id="generics.true" /> : <Translate id="generics.false" />}</td></tr>
            <tr><td><Translate id="components.showAccountInfo.allowedToLogin" /></td><td>{props.account.allowedToLogin ? <Translate id="generics.true" /> : <Translate id="generics.false" />}</td></tr>
            <tr><td><Translate id="components.showAccountInfo.accountClosed" /></td><td>{props.account.accountLocked ? <Translate id="generics.true" /> : <Translate id="generics.false" />}</td></tr>
            <tr><td></td><td></td></tr>
                    <tr><td><Translate id="components.showAccountInfo.acceptedTerms" /></td><td>{props.account.acceptedTerms ? <Translate id="generics.true" /> : <Translate id="generics.false" />}</td></tr>
                    <tr><td><Translate id="components.showAccountInfo.acceptedMarketingUsage" /></td><td>{props.account.acceptedMarketingUsage ? <Translate id="generics.true" /> : <Translate id="generics.false" />}</td></tr>
            <tr><td style={{ verticalAlign: "text-top" }} ><Translate id="components.showAccountInfo.userRoles" /></td><td>{props.account.userRoles ? props.account.userRoles.map((value, idx) => <div key={idx}>{value}</div>) : <div><Translate id="generics.none" /></div>}</td></tr>
            <tr><td>&nbsp;</td><td></td></tr>
            <tr><td>&nbsp;</td><td></td></tr>
          </tbody>
        </table>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <table>
          <tbody>
            <tr>
              <td>Not loaded</td><td></td>
            </tr>
          </tbody>
        </table>
      </Fragment>
    );
  }

};



export default ShowAccountInfo;
