import * as Auth from './types/Auth';
import * as AccChangeLogin from './types/AccountShortcuts/ChangeLogin';
import * as AccChangePearls from './types/AccountShortcuts/ChangePearls';
import * as AccChangeActive from './types/AccountShortcuts/ChangeActive';

import * as RequestLog from './types/DeviceActions/RequestLog';
import * as ResetActivation from './types/DeviceActions/ResetActivation';
import * as ResetInstaller from './types/DeviceActions/ResetInstaller';
import * as SetOutOfService from './types/DeviceActions/SetOutOfService';

import * as AccSendReset from './types/AccountShortcuts/SendReset';
import * as AccDeleteAccount from './types/AccountShortcuts/DeleteAccount';
import * as sendgridTemplatesDelete from './types/SendgridTemplatesDelete';
import * as sendgridTemplatesCreate from './types/SendgridTemplatesCreate';

import * as PartnerDetails from './types/PartnerDetails';
import * as ChangeOrganizationActiveState from './types/ChangeOrganizationActiveState';
import * as CouponCode from './types/CouponCode';
import * as AccChangePosition from './types/AccountShortcuts/ChangePosition'
import * as AccRemoveFromCompany from './types/AccountShortcuts/RemoveFromCompany'

import * as CountryList from './types/MultiSelect';
import * as ChipList from './types/InputToChip';

//import { Store } from './types/MultiSelect';

export default {

  appShell: {
    drawerShown: false
  },

  auth: {
    isLoading: false,
    hasUnauthorisedLogin: false,
    user: null,
    authHasExpired: false
  } as Auth.AuthStore,

  multiSelect: {
    countryCodes: [],
    pending: false,
    isLoaded: false,
    error: null
  } as CountryList.MultiSelectStore,

  inputToChip: {
    chips: [],
    pending: false,
    isLoaded: false,
    error: null
  } as ChipList.InputToChipStore,

  accountShortcuts: {
    changeLogin: {
      isLocked: false,
      changeData: null,
      error: null
    } as AccChangeLogin.Store,

    changeActive: {
      user: null,
    } as AccChangeActive.Store,

    sendReset: {
      isLocked: false,
      changeData: null,
      error: null
    } as AccSendReset.Store,

    deleteAccount: {
        isLocked: false,
        changeData: null,
        error: null
    } as AccDeleteAccount.Store,

    changePearls: {
      isLocked: false,
      pearlsObj: null,
      error: null
    } as AccChangePearls.Store,

    changePosition: {
      isLocked: false,
      changeData: null,
      error: null
    } as AccChangePosition.Store,

    removeFromCompany: {
      isLocked: false,
      changeData: null,
      error: null
    } as AccRemoveFromCompany.Store,
  },
  sendgridTemplatesCreate: {
    isLocked: false,
    changeData: null,
    error: null
  } as sendgridTemplatesCreate.Store,

  sendgridTemplatesDelete: {
    isLocked: false,
    changeData: null,
    error: null
  } as sendgridTemplatesDelete.Store,

  deviceActions: {
    requestLog: {
      isLocked: false,
      openWithProductCode: undefined,
      error: null
    } as RequestLog.Store,

    resetActivation: {
      isLocked: false,
      openWithProductCode: undefined,
      error: null
    } as ResetActivation.Store,

    resetInstaller: {
      isLocked: false,
      openWithProductCode: undefined,
      error: null
    } as ResetInstaller.Store,

    setOutOfService: {
      isLocked: false,
      openWithProductCode: undefined,
      error: null
    } as SetOutOfService.Store,
  },
  changeOrganizationActiveState: {
    isLocked: false,
    changeData: null,
    error: null
  } as ChangeOrganizationActiveState.Store,

  partnerDetails: {
    isLocked: false,
    isDirty: false,
    partner: null,
    globalErrors: [],
    propertyErrors: null,
  } as PartnerDetails.Store,

  couponCode: {
    isLocked: false,
    isDirty: false,
    code: null,
    globalErrors: [],
    propertyErrors: null,
  } as CouponCode.Store,
};
