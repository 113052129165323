import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  CircularProgress,
  makeStyles,
  Theme,
  SnackbarContent,
} from "@material-ui/core";

import { connect } from "react-redux";
import store, { AppState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { dirty, load, save } from "redux/actions/PartnerDetailsActions";
import { PartnerGroupDiff } from "redux/types/PartnerDetails";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { AuthStore } from "redux/types/Auth";
import gql from "graphql-tag";

import cloneDeep from "clone-deep";

import { open as changeOrganizationActiveStateOpen } from "redux/actions/ChangeOrganizationActiveStateActions";
import { useQuery, useMutation, ExecutionResult } from "react-apollo";

import {
  PartnerDetail,
  PartnerDetailVariables,
  PartnerDetail_organization,
} from "types/apolloGenerated/PartnerDetail";
import {
  PartnerGroups,
  PartnerGroups_organizationGroups,
} from "types/apolloGenerated/PartnerGroups";

import PartnerDetailsView from "./PartnerDetail";
import { filterSparseArray } from "util/CampUtils";
import { OrganizationUpdateInput } from "types/apolloGenerated/globalTypes";

import { qraphQlErrorsToString } from "util/GraphQL";
import { withLocalize, LocalizeContextProps } from "react-localize-redux";

import {
  AddNewOrganization,
  AddNewOrganizationVariables,
} from "types/apolloGenerated/AddNewOrganization";
import {
  QUERY_PARTNER_DETAIL,
  QUERY_PARTNER_GROUPS,
  FRAGMENT_PARTNER_DETAIL,
  FRAGMENT_PARTNER_ADDRESS,
} from "commonQueries/partner";

import { PartnerType, OrganizationVisibility } from "types/ToolboxEntities";
import { ApolloError } from "apollo-client";
import USER_ROLES from "../../../../commonQueries/userRole";
import * as Types from "../../../../types/ToolboxEntities";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    backgroundColor: theme.palette.error.dark,
    marginBottom: theme.spacing(3),
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

interface partnerDetailProps extends LocalizeContextProps {
  auth: AuthStore;
  actions: {
    dirty: typeof dirty;
    load: typeof load;
    save: typeof save;
    changeOrganizationActiveStateOpen: typeof changeOrganizationActiveStateOpen;
  };
}

export const MUTATE_PARTNER = gql`
  mutation MutatePartner(
    $organization: OrganizationUpdateInput!
    $bonusAccountUpdate: Boolean!
  ) {
    organization {
      update(
        organization: $organization
        bonusAccountUpdate: $bonusAccountUpdate
      ) {
        ...OrganizationDetail
      }
    }
  }
  ${FRAGMENT_PARTNER_DETAIL}
`;

const MUTATE_PARTNER_LOGO = gql`
  mutation MutatePartnerLogo($updateImageInput: OrganizationUpdateLogoInput!) {
    organization {
      updateLogo(logo: $updateImageInput) {
        logoUrl
      }
    }
  }
`;

const MUTATE_PARTNER_IMAGE = gql`
  mutation MutatePartnerImage(
    $updateImageInput: OrganizationUpdatePartnerImageInput!
  ) {
    organization {
      updatePartnerImage(partnerImage: $updateImageInput) {
        partnerImageUrl
      }
    }
  }
`;

const MUTATE_ADDPARTNERGROUP = gql`
  mutation AddToPartnerGroup(
    $organizationId: Int!
    $organizationGroupId: Int!
  ) {
    organizationGroup {
      addOrganization(
        organizationId: $organizationId
        organizationGroupId: $organizationGroupId
      ) {
        groups {
          id
          name
        }
      }
    }
  }
`;

const MUTATE_REMOVEPARTNERGROUP = gql`
  mutation RemoveFromPartnerGroup(
    $organizationId: Int!
    $organizationGroupId: Int!
  ) {
    organizationGroup {
      removeOrganization(
        organizationId: $organizationId
        organizationGroupId: $organizationGroupId
      ) {
        groups {
          id
          name
        }
      }
    }
  }
`;

const MUTATE_CHANGEPARTNERADDRESS = gql`
  mutation ChangePartnerAddress(
    $partnerAddress: OrganizationAddressUpdateInput!
  ) {
    organization {
      updateOrganizationAddress(address: $partnerAddress) {
        ...OrganizationAddress
      }
    }
  }
  ${FRAGMENT_PARTNER_ADDRESS}
`;

const MUTATE_ADD_NEW_ORGANIZATION = gql`
  mutation AddNewOrganization($organization: OrganizationInsertInput!) {
    organization {
      insert(organization: $organization) {
        name
        id
      }
    }
  }
`;

const getFilteredGroups = (
  actualGroups: (PartnerGroups_organizationGroups | null)[] | null | undefined,
  filterGroups: PartnerGroups_organizationGroups[]
): PartnerGroups_organizationGroups[] => {
  if (actualGroups) {
    return actualGroups.filter((group) => {
      return (
        group &&
        filterGroups.findIndex((filterGroup) => filterGroup.id === group.id) >=
          0
      );
    }) as PartnerGroups_organizationGroups[];
  }

  return [];
};

const getPartnerGroupDiff = (
  selectedGroups: PartnerGroups_organizationGroups[],
  partnerDataGroups: (PartnerGroups_organizationGroups | null)[]
): PartnerGroupDiff => {
  const groupsToAdd = selectedGroups.filter(
    (selectedGroup) =>
      -1 ===
      partnerDataGroups.findIndex(
        (partnerGroup) => partnerGroup && partnerGroup.id === selectedGroup.id
      )
  );

  const groupsToRemove = partnerDataGroups.filter(
    (partnerGroup) =>
      -1 ===
      selectedGroups.findIndex(
        (selectedGroup) => partnerGroup && partnerGroup.id === selectedGroup.id
      )
  ) as PartnerGroups_organizationGroups[];

  return {
    groupsToAdd: groupsToAdd,
    groupsToRemove: groupsToRemove,
  };
};

const clonePartner = (
  organization: PartnerDetail_organization | null | undefined,
  isNewMode: boolean = false
): PartnerDetail_organization | undefined => {
  if (organization) {
    return cloneDeep(organization) || undefined;
  } else if (isNewMode) {
    return {
      __typename: "Organization",
      id: -1,
      externalId: "",
      name: "",
      partnerType: PartnerType.BUILDER,
      visibility: OrganizationVisibility.VISIBLE,
      address: {
        __typename: "Address",
        street: "",
        postalCode: "",
        city: "",
        countryCode: null,
        location: null,
      },
      size: 0,
      isDeleted: false,
      email: "",
      website: "",
      telephone: "",
      vatNumber: "",
      logoUrl: "",
      logoLinkUrl: "",
      partnerImageUrl: "",
      partnerImageLinkUrl: "",
      bonusaccount: null,
      groups: null,
      creationTimeStamp: null,
    };
  }
};

export type changeFuncType = (value: any, name: string) => void;

const PartnerDetailsContainer: React.FC<partnerDetailProps> = ({
  auth,
  actions,
  translate,
}) => {
  const { id } = useParams();
  const idNum = Number(id);

  const { data: roleData, loading: roleLoading, error: roleError } = useQuery(
    USER_ROLES
  );
  const roles: string[] = roleData?.user?.userInformation?.userRoles;
  let canViewLogs: boolean = false;

  store.getState().auth.user?.roles.forEach((item: Types.Role) => {
    if (item.roleName === "AuditAdmin") {
      canViewLogs = true;
    }
  });

  if (!roleLoading) {
    if (roles != null && roles.includes("rnd")) {
    }
  }
  if (roleError) {
  }

  //trigger used to update component as a hack should be rewritten
  const [hasChanged, setHasChanged] = useState(true);

  const { ownerId } = useParams();
  let ownerIdNum = Number(ownerId) || null;

  const isNewMode = typeof id === "undefined";
  const classes = useStyles();
  const [
    persistPartner,
    { loading: persistPartnerLoading, error: persistPartnerError },
  ] = useMutation(MUTATE_PARTNER, {
    onCompleted: (data) => {
      if (partnerRef.current?.bonusaccount === null) {
        partnerRef.current.bonusaccount = data.organization.update.bonusaccount;
      }
      setHasChanged(!hasChanged);
    },
    //refetch query to update cache
    refetchQueries: [
      {
        query: QUERY_PARTNER_DETAIL,
        variables: { id: idNum },
      },
    ],
  });
  const [
    persistPartnerImage,
    { loading: persistPartnerImageLoading, error: persistPartnerImageError },
  ] = useMutation(MUTATE_PARTNER_IMAGE);
  const [
    persistPartnerLogo,
    { loading: persistPartnerLogoLoading, error: persistPartnerLogoError },
  ] = useMutation(MUTATE_PARTNER_LOGO);
  const [
    addToPartnerGroups,
    { loading: addPartnerGroupsLoading, error: addPartnerGroupsError },
  ] = useMutation(MUTATE_ADDPARTNERGROUP, {
    onCompleted: (data) => {
      if (partnerRef.current?.groups) {
        partnerRef.current.groups =
          data.organizationGroup?.addOrganization?.groups;
      }
    },
    refetchQueries: [
      {
        query: QUERY_PARTNER_DETAIL,
        variables: { id: idNum },
      },
    ],
  });
  const [
    removeFromPartnerGroups,
    { loading: removePartnerGroupsLoading, error: removePartnerGroupsError },
  ] = useMutation(MUTATE_REMOVEPARTNERGROUP, {
    onCompleted: (data) => {
      if (partnerRef.current?.groups) {
        partnerRef.current.groups =
          data.organizationGroup?.removeOrganization?.groups;
      }
    },
    refetchQueries: [
      {
        query: QUERY_PARTNER_DETAIL,
        variables: { id: idNum },
      },
    ],
  });
  const [
    changePartnerAddress,
    { loading: changePartnerAddressLoading, error: changePartnerAddressError },
  ] = useMutation(MUTATE_CHANGEPARTNERADDRESS);

  const [
    addNewOrganization,
    { loading: addNewOrganizationLoading, error: addNewOrganizationError },
  ] = useMutation<AddNewOrganization, AddNewOrganizationVariables>(
    MUTATE_ADD_NEW_ORGANIZATION,
    {
      onError: (error: ApolloError) => {
        error?.graphQLErrors?.some((graphQLError: any) => {
          if (graphQLError?.message.startsWith("BonusAccount")) {
            history.replace(
              "/pam/partner/" + graphQLError?.extensions?.data?.OrganizationId
            );
          }
        });
      },
    }
  );

  // Persisted Data from Server/DEB
  const { data: persistedPartner, loading, error } = useQuery<
    PartnerDetail,
    PartnerDetailVariables
  >(QUERY_PARTNER_DETAIL, {
    variables: { id: idNum },
    skip: isNewMode,
  });
  const {
    data: persistedAvailableGroups,
    loading: pgLoaging,
    error: pgError,
    client,
  } = useQuery<PartnerGroups, undefined>(QUERY_PARTNER_GROUPS);

  // Post-Prozessed - selectable groups
  const selectableGroups = useMemo<PartnerGroups_organizationGroups[]>(() => {
    return filterSparseArray(persistedAvailableGroups?.organizationGroups);
  }, [persistedAvailableGroups]);

  // Session-Copy of Partner
  const partnerRef = useRef<PartnerDetail_organization | undefined>();
  if (typeof partnerRef.current === "undefined") {
    partnerRef.current = clonePartner(
      persistedPartner?.organization,
      isNewMode
    );
  }

  // Session-Copy of Partner-Groups
  const selectedGroupsRef = useRef<PartnerGroups_organizationGroups[]>([]);
  if (
    selectedGroupsRef.current.length === 0 &&
    selectableGroups.length &&
    partnerRef.current?.groups
  ) {
    selectedGroupsRef.current = getFilteredGroups(
      partnerRef.current?.groups,
      selectableGroups
    );
  }
  const setSelectedGroups = useCallback(
    (selected: PartnerGroups_organizationGroups[]) => {
      selectedGroupsRef.current = selected;
    },
    []
  );

  // Session-Data: Base64Images
  const partnerSessionRef = useRef<{
    logo?: string;
    image?: string;
    logoLinkUrl?: string | null;
    partnerImageLinkUrl?: string | null;
    city?: string;
    street?: string;
    postalCode?: string;
    countryCode?: string;
  }>({});

  const onChangeSetData = useCallback<changeFuncType>(
    (value: any, name: string) => {
      if (name === "logoBase64") {
        partnerSessionRef.current.logo = value;
      } else if (name === "imageBase64" || name === "partnerImageBase64") {
        partnerSessionRef.current.image = value;
      } else if (name === "logoUrl") {
        partnerSessionRef.current.logoLinkUrl = value;
      } else if (name === "partnerImageUrl") {
        partnerSessionRef.current.partnerImageLinkUrl = value;
      } else {
        partnerRef.current = changeNestedProp(
          partnerRef.current,
          value,
          name.split("/")
        );
        if (
          partnerRef.current &&
          partnerRef.current.address &&
          partnerRef.current.address.location
        ) {
          partnerRef.current.address.location.__typename = "GeoLocation";
        }
      }
    },
    []
  );

  const [isDirty] = useState<boolean>(false);

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  interface changeAddressVariables {
    id: number;
    address: {
      postalCode?: string;
      countryCode?: string;
      street?: string;
      city?: string;
      location: {
        latitude?: number;
        longitude?: number;
      };
    };
  }

  const history = useHistory();
  let newCreatedPartnerId: number | undefined = undefined;
  const onSave = () => {
    console.log("SAVE");
    if (partnerRef.current) {
      const partnerId = partnerRef.current.id;

      const selectedFilter = (group: any) => {
        if (partnerRef.current) {
          return (
            group.name !==
              "bwt_" + partnerRef.current.partnerType?.toLowerCase() &&
            group.name !==
              "bwt_" +
                persistedPartner?.organization?.partnerType?.toLowerCase()
          );
        }
        return group;
      };

      let currentGroups: any[] = [];
      if (partnerRef.current.groups) {
        currentGroups = partnerRef.current.groups.filter(selectedFilter);
      }
      const partnerGroupsDiff = getPartnerGroupDiff(
        selectedGroupsRef.current.filter(selectedFilter),
        currentGroups
      );

      const savedAll: Promise<any>[] = [];
      if (partnerSessionRef.current.logoLinkUrl?.length === 0)
        partnerSessionRef.current.logoLinkUrl = null;
      if (partnerSessionRef.current.partnerImageLinkUrl?.length === 0)
        partnerSessionRef.current.partnerImageLinkUrl = null;

      let logoLinkUrl: string | null | undefined =
        partnerSessionRef.current.logoLinkUrl !== undefined
          ? partnerSessionRef.current.logoLinkUrl
          : partnerRef.current.logoLinkUrl;
      let partnerImageLinkUrl: string | null | undefined =
        partnerSessionRef.current.partnerImageLinkUrl !== undefined
          ? partnerSessionRef.current.partnerImageLinkUrl
          : partnerRef.current.partnerImageLinkUrl;

      if (!isNewMode) {
        const variables: OrganizationUpdateInput = {
          id: partnerRef.current.id,
          name: partnerRef?.current?.name?.trim(),
          partnerType: partnerRef?.current?.partnerType,
          visibility: partnerRef?.current?.visibility,
          email: partnerRef?.current?.email?.trim(),
          website: partnerRef?.current?.website?.trim(),
          telephone: partnerRef?.current?.telephone?.trim(),
          logoLinkUrl: logoLinkUrl,
          partnerImageLinkUrl: partnerImageLinkUrl?.trim(),
          externalId: partnerRef.current.externalId,
          size: partnerRef.current.size,
          vatNumber: partnerRef.current.vatNumber,
        };

        savedAll.push(
          persistPartner({
            variables: {
              organization: variables,
              bonusAccountUpdate:
                persistedPartner?.organization?.partnerType ===
                variables.partnerType
                  ? false
                  : true,
            },
          }).catch((error) => {
            const errorString =
              (translate("message.error.error_partnerInformation") as string) +
              qraphQlErrorsToString(error, translate);
            throw new Error(errorString);
          })
        );

        savedAll.push(
          changePartnerAddress({
            variables: {
              partnerAddress: {
                organizationId: partnerRef.current.id,
                postalCode:
                  partnerRef.current.address?.postalCode?.trim() ||
                  partnerSessionRef.current.postalCode,
                countryCode:
                  partnerRef.current.address?.countryCode ||
                  partnerSessionRef.current.countryCode,
                street:
                  partnerRef.current.address?.street?.trim() ||
                  partnerSessionRef.current.street,
                city:
                  partnerRef.current.address?.city?.trim() ||
                  partnerSessionRef.current.city,
                location: {
                  latitude: partnerRef.current.address?.location?.latitude,
                  longitude: partnerRef.current.address?.location?.longitude,
                },
              },
            },
          }).catch((error) => {
            const errorString =
              (translate("message.error.error_partnerAddress") as string) +
              qraphQlErrorsToString(error, translate);
            throw new Error(errorString);
          })
        );

        if (partnerGroupsDiff.groupsToAdd.length) {
          partnerGroupsDiff.groupsToAdd.map((elementToAdd) =>
            savedAll.push(
              addToPartnerGroups({
                variables: {
                  organizationId: partnerId,
                  organizationGroupId: elementToAdd.id,
                },
              })
                .then(() => {
                  console.log("Update -> Yehaaa Group added");
                })
                .catch((error) => {
                  const errorString =
                    (translate("message.error.error_partnerGroups") as string) +
                    qraphQlErrorsToString(error, translate);
                  throw new Error(errorString);
                })
            )
          );
        }

        if (partnerGroupsDiff.groupsToRemove.length) {
          partnerGroupsDiff.groupsToRemove.map((elementToRemove) =>
            savedAll.push(
              removeFromPartnerGroups({
                variables: {
                  organizationId: partnerId,
                  organizationGroupId: elementToRemove.id,
                },
              })
                .then(() => {
                  console.log("Update -> Yehaaa Group removed");
                })
                .catch((error) => {
                  const errorString =
                    (translate("message.error.error_partnerGroups") as string) +
                    qraphQlErrorsToString(error, translate);
                  throw new Error(errorString);
                })
            )
          );
        }

        if (partnerSessionRef.current.logo !== undefined) {
          console.log("##### " + partnerSessionRef.current.logo);
          savedAll.push(
            persistPartnerLogo({
              variables: {
                updateImageInput: {
                  organizationId: partnerRef.current.id,
                  logo:
                    partnerSessionRef.current.logo !== null
                      ? partnerSessionRef.current.logo.split("base64,").pop()
                      : null,
                },
              },
            }).catch((error) => {
              const errorString =
                (translate("message.error.error_partnerLogo") as string) +
                qraphQlErrorsToString(error, translate);
              throw new Error(errorString);
            })
          );
        }

        if (partnerSessionRef.current.image !== undefined) {
          console.log("##### " + partnerSessionRef.current.image);
          savedAll.push(
            persistPartnerImage({
              variables: {
                updateImageInput: {
                  organizationId: partnerRef.current.id,
                  partnerImage:
                    partnerSessionRef.current.image !== null
                      ? partnerSessionRef.current.image.split("base64,").pop()
                      : null,
                },
              },
            }).catch((error) => {
              const errorString =
                (translate("message.error.error_partnerImage") as string) +
                qraphQlErrorsToString(error, translate);
              throw new Error(errorString);
            })
          );
        }

        Promise.all(savedAll)
          .then(() => {
            console.log("savedAll", savedAll);
            //partnerRef.current = clonePartner(persistedPartner?.organization, true);
            selectedGroupsRef.current = getFilteredGroups(
              partnerRef.current?.groups,
              selectableGroups
            );
          })
          .catch((errorData) => {
            if (errorData) {
              console.log("savedAll - ERROR", errorData);
            }
          });
      } else {
        if (isNewMode && partnerRef.current) {
          const savedAllNewPartner: Promise<any>[] = [];

          if (ownerIdNum === 0) {
            ownerIdNum = null;
          }

          addNewOrganization({
            variables: {
              organization: {
                ownerUserId: ownerIdNum || null,
                name: partnerRef.current.name || "",
                partnerType: partnerRef.current.partnerType,
                visibility: partnerRef.current.visibility,
                email: partnerRef.current.email || "",
                telephone: partnerRef.current.telephone || "",
                vatNumber: partnerRef.current.vatNumber || "",
                externalId: partnerRef.current.externalId || "",
                size: partnerRef.current.size || 0,
                address: {
                  city: partnerRef.current?.address?.city || "",
                  countryCode: partnerRef.current?.address?.countryCode,
                  postalCode: partnerRef.current?.address?.postalCode || "",
                  street: partnerRef.current?.address?.street || "",
                  location: {
                    latitude:
                      partnerRef.current?.address?.location?.latitude || 0,
                    longitude:
                      partnerRef.current?.address?.location?.longitude || 0,
                  },
                },
              },
            },
          })
            .catch((error) => {
              console.log(error);
            })
            .then((data: void | ExecutionResult<AddNewOrganization>) => {
              if (data && partnerRef.current) {
                newCreatedPartnerId = data.data?.organization?.insert?.id;

                if (newCreatedPartnerId) {
                  const variables: OrganizationUpdateInput = {
                    id: newCreatedPartnerId,
                    name: partnerRef.current.name || "",
                    email: partnerRef.current.email || "",
                    telephone: partnerRef.current.telephone || "",
                    vatNumber: partnerRef.current.vatNumber || "",
                    externalId: partnerRef.current.externalId || "",
                    partnerType: partnerRef.current.partnerType || null,
                    visibility: partnerRef.current.visibility || null,
                    size: partnerRef.current.size || 0,
                    partnerImageLinkUrl: partnerImageLinkUrl || null,
                    logoLinkUrl: logoLinkUrl || null,
                  };

                  savedAllNewPartner.push(
                    persistPartner({
                      variables: {
                        organization: variables,
                        bonusAccountUpdate: true,
                      },
                    }).catch((error) => {
                      const errorString =
                        (translate(
                          "message.error.error_partnerInformation"
                        ) as string) + qraphQlErrorsToString(error, translate);
                      throw new Error(errorString);
                    })
                  );

                  if (partnerGroupsDiff.groupsToAdd.length) {
                    partnerGroupsDiff.groupsToAdd.map((elementToAdd) =>
                      savedAllNewPartner.push(
                        addToPartnerGroups({
                          variables: {
                            organizationId: newCreatedPartnerId,
                            organizationGroupId: elementToAdd.id,
                          },
                        })
                          .then(() => {
                            console.log("Update -> Yehaaa Group added");
                          })
                          .catch((error) => {
                            const errorString =
                              (translate(
                                "message.error.error_partnerGroups"
                              ) as string) +
                              qraphQlErrorsToString(error, translate);
                            throw new Error(errorString);
                          })
                      )
                    );
                  }

                  if (partnerGroupsDiff.groupsToRemove.length) {
                    console.log(
                      "GroupsToRemove: ",
                      partnerGroupsDiff.groupsToRemove
                    );
                    partnerGroupsDiff.groupsToRemove.map((elementToRemove) =>
                      savedAllNewPartner.push(
                        removeFromPartnerGroups({
                          variables: {
                            organizationId: newCreatedPartnerId,
                            organizationGroupId: elementToRemove.id,
                          },
                        })
                          .then(() => {
                            console.log("Update -> Yehaaa Group removed");
                          })
                          .catch((error) => {
                            const errorString =
                              (translate(
                                "message.error.error_partnerGroups"
                              ) as string) +
                              qraphQlErrorsToString(error, translate);
                            throw new Error(errorString);
                          })
                      )
                    );
                  }

                  if (partnerSessionRef.current.logo) {
                    savedAllNewPartner.push(
                      persistPartnerLogo({
                        variables: {
                          updateImageInput: {
                            organizationId: newCreatedPartnerId,
                            logo: partnerSessionRef.current.logo
                              .split("base64,")
                              .pop(),
                          },
                        },
                      }).catch((error) => {
                        const errorString =
                          (translate(
                            "message.error.error_partnerLogo"
                          ) as string) +
                          qraphQlErrorsToString(error, translate);
                        throw new Error(errorString);
                      })
                    );
                  }

                  if (partnerSessionRef.current.image) {
                    savedAllNewPartner.push(
                      persistPartnerImage({
                        variables: {
                          updateImageInput: {
                            organizationId: newCreatedPartnerId,
                            partnerImage: partnerSessionRef.current.image
                              .split("base64,")
                              .pop(),
                          },
                        },
                      })
                        .catch((error) => {
                          const errorString =
                            (translate(
                              "message.error.error_partnerImage"
                            ) as string) +
                            qraphQlErrorsToString(error, translate);
                          throw new Error(errorString);
                        })
                        .then(() => {
                          console.log("partner image uploaded");
                        })
                    );
                  }

                  Promise.all(savedAllNewPartner)
                    .then(() => {
                      console.log("YEYYY SAVED ALL", savedAllNewPartner);

                      client.cache.reset().then(() => {
                        partnerRef.current = undefined;
                        history.replace("/pam/partner/" + newCreatedPartnerId);
                      });
                    })
                    .catch((errorData) => {
                      if (errorData) {
                        console.log("savedAllNewPartner - ERROR", errorData);
                      }
                    });
                }
              }
            })
            .finally(() => {});
        }
      }
    }
  };

  if (loading || pgLoaging) {
    return (
      <div style={{ padding: "30px" }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  if (error || pgError || !partnerRef.current) {
    return (
      <SnackbarContent
        className={classes.error}
        aria-describedby="client-snackbar"
        message={
          (translate("message.error.error_loadingPartnerFailed") as string) +
          ": #" +
          id
        }
      />
    );
  }

  const isSavePending =
    persistPartnerLoading ||
    persistPartnerImageLoading ||
    persistPartnerLogoLoading ||
    addPartnerGroupsLoading ||
    removePartnerGroupsLoading ||
    changePartnerAddressLoading ||
    addNewOrganizationLoading;

  const errorObj: string[] = [];
  if (persistPartnerError)
    errorObj.push(
      (translate("message.error.error_partnerInformation") as string) +
        " " +
        qraphQlErrorsToString(persistPartnerError.graphQLErrors, translate)
    );
  if (persistPartnerImageError)
    errorObj.push(
      (translate("message.error.error_partnerImage") as string) +
        " " +
        qraphQlErrorsToString(persistPartnerImageError.graphQLErrors, translate)
    );
  if (persistPartnerLogoError)
    errorObj.push(
      (translate("message.error.error_partnerLogo") as string) +
        " " +
        qraphQlErrorsToString(persistPartnerLogoError.graphQLErrors, translate)
    );
  if (addPartnerGroupsError)
    errorObj.push(
      (translate("message.error.error_partnerGroups") as string) +
        " " +
        qraphQlErrorsToString(addPartnerGroupsError.graphQLErrors, translate)
    );
  if (removePartnerGroupsError)
    errorObj.push(
      (translate("message.error.error_partnerGroups") as string) +
        " " +
        qraphQlErrorsToString(removePartnerGroupsError.graphQLErrors, translate)
    );
  if (changePartnerAddressError)
    errorObj.push(
      (translate("message.error.error_partnerAddress") as string) +
        " " +
        qraphQlErrorsToString(
          changePartnerAddressError.graphQLErrors,
          translate
        )
    );
  if (addNewOrganizationError) {
    errorObj.push(
      (translate("message.error.error_addNewOrganization") as string) +
        " " +
        qraphQlErrorsToString(addNewOrganizationError.graphQLErrors, translate)
    );
    if (newCreatedPartnerId) {
      history.replace("/pam/partner/" + newCreatedPartnerId);
    }
  }

  return (
    <Fragment>
      <PartnerDetailsView
        auth={auth}
        isNewMode={isNewMode}
        isSavePending={isSavePending}
        newPartnerBean={partnerRef.current}
        onChangeSetData={onChangeSetData}
        selectableGroups={selectableGroups}
        selectedGroups={selectedGroupsRef.current}
        setSelectedGroups={setSelectedGroups}
        onSave={onSave}
        error={errorObj}
        canViewLogDetail={canViewLogs}
      />
    </Fragment>
  );
};

export const changeNestedProp: any = (
  object: any,
  value: any,
  props: string[]
) => {
  if (props.length > 1) {
    return {
      ...object,
      [props[0]]: changeNestedProp(object[props[0]], value, props.slice(1)),
    };
  } else {
    return {
      ...object,
      [props[0]]: value,
    };
  }
};

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { dirty, load, save, changeOrganizationActiveStateOpen },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(PartnerDetailsContainer));
