import React from 'react';
import { Translate } from 'react-localize-redux';

import { CircularProgress } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import gql from "graphql-tag";
import { useQuery } from 'react-apollo';
import {
  productInstancePreview,
  productInstancePreviewVariables,
  productInstancePreview_productInstance_owner,
  productInstancePreview_productInstance_servicePartner,
  productInstancePreview_productInstance
} from 'types/apolloGenerated/productInstancePreview';
import { Link } from 'react-router-dom';

const PREVIEW_BY_CODE = gql`
  query productInstancePreview(
    $productCode: String!
  ) {
    productInstance(productCode: $productCode) {

      productCode

      product {
        productInformation {
          name
          itemNumber
        }
      }

      registrationDate
      owner {
        userInformation {
          id
          key
          firstName
          lastName
          email
        }
      }
      
      servicePartner {
        id
        name
        email
      }
      
      instanceInformation {
        ... on PerlaInstanceInformation  {
          serialNumber
        }
      }
    }
  }
`;



const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    deviceSearchPreview: {
      marginBottom: "20px",
      display: "flex",

      '& > div': {
        marginRight: '24px',
      },
    }
  })
});





interface DeviceSearchPreviewProps {
  productCode: string | null
}



const DeviceSearchPreview: React.FC<DeviceSearchPreviewProps> = ({ productCode }) => {
  const classes = useStyles();

  const isProdCodeFormat = productCode && /^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/.test(productCode)

  const { data, loading, error } = useQuery<productInstancePreview, productInstancePreviewVariables>(PREVIEW_BY_CODE, {
    variables: { productCode: productCode || '' },
    skip: !isProdCodeFormat
  });


  if (loading) {
    return <div className={classes.deviceSearchPreview}><CircularProgress color="secondary" /></div>
  }

  if (!isProdCodeFormat || !data || error) {
    return null;
  }


  return (
    <div className={classes.deviceSearchPreview}>
      {data.productInstance && showProduct(data.productInstance)}
      {data.productInstance?.owner && showOwner(data.productInstance?.owner)}
      {data.productInstance?.servicePartner && showPartner(data.productInstance?.servicePartner)}
    </div>
  );
};


const showProduct = (productInstance: productInstancePreview_productInstance) => (
  <div>
    <b>{productInstance.product?.productInformation?.name}</b><br />
    <Link to={"/cs/device/" + productInstance.productCode}>
      {productInstance.productCode}<br />
      {productInstance?.instanceInformation?.__typename === 'PerlaInstanceInformation'
        ? productInstance?.instanceInformation.serialNumber
        : null
      }
    </Link>
  </div>
)

const showOwner = (owner: productInstancePreview_productInstance_owner) => (
  <div>
    <b><Translate id="components.deviceSearchPreviev.customer" /></b><br />
    <Link to={"/cs/customer/" + owner.userInformation?.id}>{owner.userInformation?.firstName} {owner.userInformation?.lastName}</Link><br />
    <a href={"mailto:" + owner.userInformation?.email} rel="noopener noreferrer" target="_blank">{owner.userInformation?.email}</a>
  </div>
)

const showPartner = (partner: productInstancePreview_productInstance_servicePartner) => (
  <div>
    <b><Translate id="components.deviceSearchPreviev.partner" /></b><br />
    <Link to={"/cs/partner/" + partner.id}>{partner.name}</Link><br />
    <a href={"mailto:" + partner.email} rel="noopener noreferrer" target="_blank">{partner.email}</a>
  </div>
)



export default DeviceSearchPreview;
