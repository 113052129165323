import React, { HTMLAttributes } from 'react';

import Select, { ValueContainerProps, ControlProps, MultiValueProps, OptionProps, Props as SelectProps } from 'react-select';

import { MenuItem, Chip, TextField, BaseTextFieldProps, FormControl, FormHelperText } from '@material-ui/core';
import { createStyles, emphasize, makeStyles, Theme } from '@material-ui/core/styles';
import { Cancel as CancelIcon } from '@material-ui/icons';

import clsx from 'clsx';

import { Translate } from "react-localize-redux";

import { Error } from 'types/CampGuideTypes';
import { TranslateError } from './Error';


export interface MultiSelectOption {
  label: string;
  value: string;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: '100%',
      minWidth: 290,
      width: '100%',
      '&$error': {
        color: 'white'
      }
    },
    error: {},
    input: {
      display: 'flex',
      padding: 0,
      height: 'auto',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },

  }),
);

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<MultiSelectOption>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props: OptionProps<MultiSelectOption>) {

  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function ValueContainer(props: ValueContainerProps<MultiSelectOption>) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props: MultiValueProps<MultiSelectOption>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

const components = {
  Control,
  MultiValue,
  Option,
  ValueContainer
};



interface MultiSelectInterface extends SelectProps {
  headline: string
  error?: Error | null
}

const MultiSelect: React.FC<MultiSelectInterface> = ({ headline, error, ...props }) => {
  const classes = useStyles();

  //console.log('props:', props);

  return (
    <FormControl classes={{ root: classes.root }} error={!!error}>
      <label>
        <Translate id={headline} />
        <Select<MultiSelectOption>
          classes={classes}
          isClearable={true}
          placeholder={<Translate id="components.multiSelect.selectselectableTxt" />}
          components={components}
          isMulti
          {...props}
        />
        {error ? <FormHelperText><TranslateError error={error} /></FormHelperText> : null}
      </label>
    </FormControl >
  );
}

export default MultiSelect;
