import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Translate } from "react-localize-redux";
import { useParams } from "react-router-dom";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { AppState } from "redux/store";
import { State as DeviceOverviewState } from "redux/types/DeviceOverview";

import {
  setGridState,
  setColumnStates,
} from "redux/actions/DeviceOverviewActions";

import AgGrid from "components/common/Grid/AgGrid";

import {
  getColumnDefs,
  apolloConnectorById,
  CellRenderer
} from "./GridConfig";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    customerOverview: {
      display: "flex",
      flexFlow: "column",
      height: "100%",
      "& > .gridFilterHeader": {
        flex: "0 1 auto"
      },

      "& > .innerContent": {
        flex: "1 1 auto",
        margin: "0 30px"
      }
    },

    innerHeader: {
      flex: "0 1 auto",
      display: "flex",
      margin: "0 -20px 20px -20px",
      alignItems: "flex-end",
      justifyContent: "space-between",

      "& > *": {
        flex: "0 1 auto",
        margin: "0 20px",
        minWidth: "170px"
      },

      "& > .filter": {
        flex: "1 1 auto",
        maxWidth: "250px"
      }
    },

    innerHeaderRight: {
      flex: "0 1 auto",
      display: "flex",
      alignItems: "flex-end",
      margin: 0,

      "& > *": {
        flex: "0 1 auto",
        margin: "0 20px",
        minWidth: "170px"
      }
    }
  });
});

interface RegisteredDeviceListProps {
  deviceOverviewState: DeviceOverviewState;
  actions: {
    setGridState: typeof setGridState;
    setColumnStates: typeof setColumnStates;
  };
}

const RegisteredDeviceList: React.FC<RegisteredDeviceListProps> = ({
  deviceOverviewState,
  actions
}) => {
  const classes = useStyles();

  const { customerId } = useParams();

  const id = customerId ? customerId : ""

  const [apolloConnector, setApolloConnector] = useState<ReturnType<typeof apolloConnectorById>>(apolloConnectorById(id));

  useEffect(() => {
    setApolloConnector(apolloConnectorById(id));
  }, [id]);

  return (
    <Translate>
      {({ translate }) => (
        <Box marginTop="60px"  >
          <div style={{ padding: "30px" }}>
            <Typography variant="h5" >
              <Translate id="components.registeredDevices.registeredDevices"></Translate>
            </Typography>
          </div>
          <div
            className={"mainContentContainer fullHeight " + classes.customerOverview}
            style={{ position: "relative" }}
          >
            <div className="innerContent" style={{ height: "450px" }} >
              <AgGrid
                gridState={deviceOverviewState.grid}
                gridActions={{
                  setGridState: actions.setGridState,
                  setColumnStates: actions.setColumnStates
                }}
                apolloConnector={apolloConnector}
                frameworkComponents={CellRenderer}
                columnDefs={getColumnDefs(translate)}
              />
            </div>
          </div>
        </Box>
      )}
    </Translate>
  );
};

const mapStateToProps = (appState: AppState) => ({
  deviceOverviewState: appState.deviceOverview
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { setGridState, setColumnStates },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisteredDeviceList);
