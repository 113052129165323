import React from 'react';
import { TranslateFunction } from 'react-localize-redux';

import gql from "graphql-tag";
import { BonusAccountTransactions, BonusAccountTransactions_organization_bonusaccount_transactions_results, BonusAccountTransactions_organization_bonusaccount_transactions_results as BonusAccountDetails } from "types/apolloGenerated/BonusAccountTransactions";

import { ColDef } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

import format from "date-fns/format";
import { Translate } from "react-localize-redux";

import { factoryApolloConnector } from 'util/AgGridUtils';

export const QUERY_BONUS_ACCOUNT = gql`
  query BonusAccountTransactions($id: Int!) {
    organization(id:$id) {
      id
      bonusaccount {
        accountId
        details{
            ... on ConnexBonusAccountDetails {
                __typename
            }
            ... on BwtBonusAccountDetails {
                __typename
            }
        }
        transactions(page: 0, pageSize: 100) {
          results {
            id
            amount
            date
            details{
              __typename
              ... on BwtTransactionDetail {
                bwtDescription: description
                editor{
                  firstName
                  lastName
                }
              }
              ... on BonusAccountMigrationDetail {
                migrationDescription: description
              }
              ... on LegacyTransactionDetail {
                legacyDescription: description
              }
              ... on ConnexTransactionDetail {
                orderId
                entries {
                  articleNumber
                  articleTitle
                  quantity
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const apolloConnectorById = (id: number) => {
  return factoryApolloConnector(
    QUERY_BONUS_ACCOUNT,
    (data: BonusAccountTransactions) => data.organization ? data.organization.bonusaccount?.transactions : null,
    {},
    { customArguments: { id: id } }
  )
};

interface ValueGetter {
  value: string;
  data: BonusAccountDetails;
}


export const getColumnDefs = (translate: TranslateFunction): ColDef[] => {
  const removeFilterSort = {
    sortable: false,
    filter: false,
    filterParams: {},
    menuTabs: []
  }

  const columnDefs = [
    {
      headerName: translate("generics.id") as string,
      field: "id",
      width: 250,
      ...removeFilterSort
    },
    {
      headerName: translate('generics.date') as string,
      field: "date",
      width: 100,
      cellRenderer: "FormatDate",
      ...removeFilterSort
    },
    {
      headerName: translate("generics.note") as string,
      field: "details.description",
      width: 400,
      valueGetter: (params: any) => {
          return TransactionNoteGetter({ data: params.data, value: params.value })
              ?.props.children;
      },
      ...removeFilterSort
    },
    {
      headerName: translate("types.common.pearls") as string,
      field: "amount",
      width: 100,
      ...removeFilterSort
    },
    {
      headerName: translate('components.changePearls.editor') as string,
      field: "details.editor",
      width: 175,
      valueGetter: (params: any) => {
          return EditorNameGetter({ data: params.data, value: params.value })
              ?.props.children;
      },
      ...removeFilterSort
    }
  ];

  return columnDefs;
};

const EditorNameGetter = ({ data, value }: ValueGetter) => {
  if (data?.details && data.details.__typename === "BwtTransactionDetail" && data.details.editor) {
    const name = data.details.editor.firstName + " " + data.details.editor.lastName
    return <div>{name}</div>;
  }
  return <div />;
};

const TransactionNoteGetter = ({ data, value }: ValueGetter) => {
    if (!data) {
        return <div />;
    }
    if (data?.details) {
    if (data.details.__typename === "BwtTransactionDetail") {
      return <div>{data.details.bwtDescription}</div>;

    } else if (data.details.__typename === "BonusAccountMigrationDetail") {
      return <div>{data.details.migrationDescription}</div>;

    } else if (data.details.__typename === "LegacyTransactionDetail") {
      return <div>{data.details.legacyDescription}</div>;

    } else if (data.details.__typename === "ConnexTransactionDetail") {
      const note = 'Connex Order: ' + data.details.orderId + ' - ' + data.details.entries?.map((entry) => {
        if (entry) {
          return entry.quantity + 'x ' + entry.articleTitle;
        }
        return '';
      }).join(', ');

      return <div>{note}</div>;
    }
  }
  return <div />;
};

interface CellRenderer {
  data: BonusAccountTransactions_organization_bonusaccount_transactions_results;
  value: string;
}

const FormatDate: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data || !value) {
    return <div />;
  }
  //return <div>{format(new Date(value), "P")}</div>;
  return (<Translate>
    {
      (translate) => {
        return <div>{format(new Date(value), translate.translate('generics.dateTimeFormatFNSShort') as string)}</div>;
      }
    }
  </Translate>
  );
};

export const ValueGetter = {
  TransactionNoteGetter,
  EditorNameGetter
};

export const CellRenderer = {
  FormatDate,
};
