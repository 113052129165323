import React, { Fragment, useState } from "react";

import gql from "graphql-tag";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/react-hooks";
import { useLazyQuery } from "@apollo/react-hooks";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import HelpIcon from "@material-ui/icons/Help";
import { DeleteForever } from "@material-ui/icons";
import { ErrorRounded } from "@material-ui/icons";

import { open as requestLogOpen } from "redux/actions/DeviceActions/RequestLogActions";
import { open as resetActivationOpen } from "redux/actions/DeviceActions/ResetActivationActions";
import { open as resetInstallerOpen } from "redux/actions/DeviceActions/ResetInstallerActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { UserActionClass } from "types/apolloGenerated/globalTypes";

import {
  Done,
  RotateLeft,
  Clear,
  CloudDownload,
  FlashOn,
} from "@material-ui/icons";

import {
  resetState as ResetStateMutationData,
  resetStateVariables as ResetStateMutationVars,
} from "types/apolloGenerated/resetState";

import colours from "themes/bwtColors";

import { Translate } from "react-localize-redux";
import format from "date-fns/format";
import addYears from "date-fns/addYears";

import DeviceLocationModal from "./DeviceLocationModal";

import {
  productInstanceAndCustomerDetails_productInstance_instanceInformation_PerlaInstanceInformation_accessCodes as accessCodeObj,
  productInstanceAndCustomerDetails_productInstance as ProductInstance,
  productInstanceAndCustomerDetails_productInstance_instanceInformation_ProductInstanceInformation_userActions as UserActions,
} from "types/apolloGenerated/productInstanceAndCustomerDetails";
import {
  FormControl,
  IconButton,
  MenuItem,
  Popper,
  Select,
  Tooltip,
} from "@material-ui/core";

import {
  perlaTimedRawData,
  perlaTimedRawDataVariables,
} from "types/apolloGenerated/perlaTimedRawData";
import PageDialog from "../../../components/common/PageDialog";
import UPDATE_TEST_FLAG_MUTATION from "../../../commonQueries/updateTestFlag";

const DOWNLOAD_RAW_IOT = gql`
  query perlaTimedRawData($deviceId: String!, $fromDate: DateTimeOffset!) {
    perlaTimedRawData(deviceId: $deviceId, fromDate: $fromDate) {
      telemetry
      event
      summary
    }
  }
`;

const RESET_STATE = gql`
  mutation resetState($productCode: String!) {
    productInstance {
      perla {
        resetState(productCode: $productCode)
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    appSymbol: {
      verticalAlign: "middle",
      display: "inline-flex",
    },
    icon: {
      marginRight: "10px",
      verticalAlign: "top",
    },
    spacing: {
      paddingRight: "10px",
    },
    dataItem: {
      maxWidth: "80%",
    },
    dataItemInst: {
      textAlign: "right",
    },
    inactiveIcon: {
      marginRight: "10px",
      verticalAlign: "top",
      color: "#bbb",
    },
    inactiveLink: {
      color: "#bbb",
      "&:hover": {
        cursor: "default",
      },
    },
    popper: {
      border: "1px solid #ccc",
      boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#fff",
    },
    paper: {
      padding: theme.spacing(1),
      "&:hover": {
        backgroundColor: "#eee",
      },
    },
    testDeviceFormControl: {
      minWidth: 60,
    },
    warrantyContainer: {
      height: "20px",
      display: "flex",
      alignItems: "center",
    },
  });
});

function reformat(dateStr: string | null, short: boolean = false) {
  if (!dateStr) return "-";
  if (short) {
    return (
      <Translate>
        {(translate) => {
          return (
            <div>
              {format(
                new Date(dateStr),
                translate.translate("generics.dateTimeFormatFNSShort") as string
              )}
            </div>
          );
        }}
      </Translate>
    );
  } else {
    return (
      <Translate>
        {(translate) => {
          return (
            <div>
              {format(
                new Date(dateStr),
                translate.translate("generics.dateTimeFormatFNS") as string
              )}
            </div>
          );
        }}
      </Translate>
    );
  }
}

const warrantyLength = (articleNumber: string) => {
  switch (articleNumber) {
    case "RE2D-MRDB-PLS1E":
    case "1018300": //P600
      return 4;
    case "REFD-HURB-KSP97":
    case "RE2D-HURB-KPS97":
    case "RERD-HURB-KPL97":
    case "RERD-HRCB-WRP97":
    case "RE2D-HRCB-WRP97":
    case "REFO-MRD0-PPL1E":
    case "REMD-MRDB-PFR1E":
    case "RE2D-TRNB-T401E":
    case "RERD-TRNB-T501E":
      return 3;
    case "REFL-FOR0-FRC97":
    case "REFL-FOR0-F1097":
    case "RE2B-FOR0-F2097":
    case "REFL-RIOB-U1097":
    case "RE2B-RINB-U2097":
    case "RERD-RINB-U3097":
    case "1016500": //B100
    case "RE2B-SWA0-BPL1E":
    case "REFO-PEL0-PRB1E":
    case "RE2D-PELB-PWT1E":
    case "1016300": //D300
    case "REFD-TRNB-T301E":
    case "RE2D-PELB-CIL1E":
    case "RERD-PELB-CIL1E":
    default:
      return 2;
  }
};

interface DeviceDataProps {
  productInstance: ProductInstance | null;
  rndPermission?: boolean | null;
  campRnd?: boolean | null;
}

const DeviceData: React.FC<DeviceDataProps> = ({
  productInstance,
  rndPermission,
  campRnd,
}) => {
  const classes = useStyles();
  const [openState, setOpenState] = React.useState(false);

  const itemNumberObj = {
    penguin: ["815070"],
    e1: [
      "810384",
      "820382",
      "820383",
      "820435",
      "820384",
      "820385",
      "820387",
      "840382",
      "840384",
      "840385",
    ],
    nonIotPerla: ["082028", "082040"],
  };

  const [resetState, { loading }] = useMutation<
    ResetStateMutationData,
    ResetStateMutationVars
  >(RESET_STATE, {
    onCompleted: () => {
      setOpenState(false);
    },
  });

  const {
    productCode = "",
    dataSharedWithPartner = false,
    installationDate,
    registrationDate,
    instanceInformation,
    batchNumber,
    erpBatchNumber,
    customerSelectedInstaller,
    servicePartner,
    owner = null,
    address = null,
    serialNumber,
    isTestDevice = false,
  } = productInstance || {};

  const itemNumber = productInstance?.product?.productInformation?.itemNumber;

  const { id: installerId, name: installerName } = servicePartner || {};
  const { id: partnerId, name: partnerName } = customerSelectedInstaller || {};

  const { userInformation } = owner || {};

  let instanceType: string = "";
  let firmware: number | null | undefined = 0;
  let holidayModeActive: boolean | null | undefined = false;
  let wPA2Key: string = "";
  let deviceId: string = "";
  let fromDate: string = "";
  let lastTelemetryDateStr: string = "";
  let ballValveOpen: boolean | null | undefined = false;
  let nextCustomerMaintainance: string = "";
  let nextServiceDate: string = "";
  let accessCodes: (accessCodeObj | null)[] = [];
  let isIot = true;
  let userActions: (UserActions | null)[] | null = [];

  if (instanceInformation != null) {
    if (instanceInformation.__typename === "PerlaInstanceInformation") {
      instanceType = userInformation ? "iot" : "not-reg";
      ({ wPA2Key, deviceId } = instanceInformation || {});
      ({
        firmware,
        holidayModeActive,
        nextCustomerMaintainance,
        nextServiceDate,
        ballValveOpen,
      } = instanceInformation.registeredPerlaInstanceInformation || {});
      lastTelemetryDateStr = instanceInformation?.telemetry?.value?.date ?? "";
      accessCodes = instanceInformation?.accessCodes ?? [];
    } else if (instanceInformation.__typename === "SilkInstanceInformation") {
    } else {
      isIot = false;
      instanceType = "not-iot";
      userActions = instanceInformation?.userActions;
      //E1
      if (itemNumberObj.e1.includes(itemNumber?.trim() ?? "")) {
        if (userActions) {
          userActions.forEach((action) => {
            if (
              action?.userActionClass ===
              UserActionClass.FIXED_INTERVAL_HALF_YEAR
            ) {
              nextCustomerMaintainance = action?.end;
            }
          });
        }
      }
      //Penguin
      if (itemNumberObj.penguin.includes(itemNumber?.trim() ?? "")) {
        if (userActions) {
          userActions.forEach((action) => {
            if (
              action?.userActionClass === UserActionClass.FIXED_INTERVAL_MONTHLY
            ) {
              nextCustomerMaintainance = action?.end;
            }
          });
        }
      }
      //Non-Iot perla
      if (itemNumberObj.nonIotPerla.includes(itemNumber?.trim() ?? "")) {
        if (userActions) {
          userActions.forEach((action) => {
            if (
              action?.userActionClass ===
              UserActionClass.FIXED_INTERVAL_THREE_YEARS
            ) {
              nextServiceDate = action?.end;
            }
            if (
              action?.userActionClass === UserActionClass.CONSUMABLE_REFILLMENT
            ) {
              nextCustomerMaintainance = action?.end;
            }
          });
        }
      }
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement: any) => (event: any) => {
    let anchorTarget = event.currentTarget;
    if (open) {
      anchorTarget = null;
    }
    setAnchorEl(anchorTarget);
    setPlacement(newPlacement);
    setOpen((prev) => placement !== newPlacement || !prev);
  };

  const id = open ? "simple-popper" : undefined;

  const getAccessCode = (ac: (accessCodeObj | null)[], value: string) => {
    for (let i = 0; i < ac.length; i++) {
      if (ac[i]?.accessLevel === value) return ac[i]?.code;
    }
    return "";
  };

  interface RequestLogTrigger {
    actions: { requestLogOpen: typeof requestLogOpen };
  }

  const RequestLogButton = connect(null, (dispatch: Dispatch) => ({
    actions: bindActionCreators({ requestLogOpen }, dispatch),
  }))(({ actions }: RequestLogTrigger) => (
    <Link
      underline="always"
      href="#"
      color="inherit"
      onClick={() => {
        actions.requestLogOpen(productCode);
      }}
    >
      <Translate id="components.deviceData.requestLog" />
    </Link>
  ));

  interface ResetActivationTrigger {
    actions: { resetActivationOpen: typeof resetActivationOpen };
  }

  const ResetActivationButton = connect(null, (dispatch: Dispatch) => ({
    actions: bindActionCreators({ resetActivationOpen }, dispatch),
  }))(({ actions }: ResetActivationTrigger) => (
    <Link
      underline="always"
      href="#"
      color="inherit"
      onClick={() => {
        actions.resetActivationOpen(productCode);
      }}
    >
      <Translate id="types.common.deleteDevice" />
    </Link>
  ));

  interface ResetInstallerTrigger {
    actions: { resetInstallerOpen: typeof resetInstallerOpen };
  }

  const ResetInstaller = connect(null, (dispatch: Dispatch) => ({
    actions: bindActionCreators({ resetInstallerOpen }, dispatch),
  }))(({ actions }: ResetInstallerTrigger) => (
    <Link
      underline="always"
      href="#"
      color="inherit"
      onClick={() => {
        actions.resetInstallerOpen(productCode);
      }}
    >
      <Translate id="types.common.deleteInstaller" />
    </Link>
  ));

  const closePopper = () => {
    setOpen(false);
  };

  const [rawDownloadAble, setRawDownloadAble] = useState(false);

  const [
    getRaw,
    { error: rawIotError, data: rawIot, loading: rawIotLoading },
  ] = useLazyQuery<perlaTimedRawData, perlaTimedRawDataVariables>(
    DOWNLOAD_RAW_IOT,
    {
      variables: { deviceId: deviceId, fromDate: fromDate }, //"2000-01-01 00:00:00"
    }
  );

  const dateTimeLastReceivedData = lastTelemetryDateStr
    ? new Date(lastTelemetryDateStr)
    : new Date();

  dateTimeLastReceivedData.setDate(dateTimeLastReceivedData.getDate() - 30);
  const fromDate30 = dateTimeLastReceivedData
    .toISOString()
    .replace(/T/, " ")
    .replace(/\..+/, "");

  dateTimeLastReceivedData.setDate(dateTimeLastReceivedData.getDate() - 150);
  const fromDate180 = dateTimeLastReceivedData
    .toISOString()
    .replace(/T/, " ")
    .replace(/\..+/, "");

  if (rawIotError) {
    console.log("rawIotError", rawIotError);
  }

  const downloadRawIot = (JSONString: string, type: string) => {
    closePopper();
    const x = window.open();

    if (x) {
      if (JSONString.length > 2) {
        x.document.open();
        x.document.write(
          "<html><body>" +
            type +
            ":<br/><pre>" +
            JSONString +
            "</pre></body></html>"
        );
        x.document.close();
      } else if (x) {
        x.document.open();
        x.document.write(
          "<html><body>No " + type + " data available<br/></body></html>"
        );
        x.document.close();
      }
    }
    setRawDownloadAble(false);
  };

  if (rawIot && rawDownloadAble) {
    const rawEvents = JSON.stringify(rawIot?.perlaTimedRawData?.event, null, 2)
      .replace(/\\"/g, '"')
      .replace(/\\"/g, '"')
      .replace(/\\"/g, '"');
    const rawEventsWithoutQuotes = rawEvents; //.substring(1, rawEvents.length - 1);

    downloadRawIot(
      rawEventsWithoutQuotes
        .replace(/"{/g, "{")
        .replace(/\}"/g, "}")
        .replace(/\\"/g, '"'),
      "notification"
    );
    downloadRawIot(
      JSON.stringify(rawIot?.perlaTimedRawData?.telemetry, null, 2)
        .replace(/"{/g, "{")
        .replace(/\}"/g, "}")
        .replace(/\\"/g, '"'),
      "telemetry"
    );
    downloadRawIot(
      JSON.stringify(rawIot?.perlaTimedRawData?.summary, null, 2)
        .replace(/"{/g, "{")
        .replace(/\}"/g, "}")
        .replace(/\\"/g, '"'),
      "summary"
    );
  }

  const downloadRaw30 = () => {
    setRawDownloadAble(true);
    getRaw({ variables: { deviceId: deviceId, fromDate: fromDate30 } });
    //console.log("RAW30: ", rawIot);
  };

  const downloadRaw180 = () => {
    setRawDownloadAble(true);
    getRaw({ variables: { deviceId: deviceId, fromDate: fromDate180 } });
    //console.log("RAW180: ", rawIot);
  };

  const downloadRawAll = () => {
    setRawDownloadAble(true);
    getRaw({
      variables: { deviceId: deviceId, fromDate: "2000-01-01 00:00:00" },
    });
    //console.log("RAW_ALL: ", rawIot);
  };

  const commonLeft = () => {
    return (
      <Box>
        <DataItem translate="types.common.productCode" value={productCode} />
        <DataItem
          translate="types.common.serialNumber"
          value={serialNumber ? serialNumber : "-"}
        />
        <DataItem
          translate="types.common.deviceId"
          value={deviceId ? deviceId : "-"}
        />
        <DataItem
          translate="types.common.batchNumber"
          value={batchNumber ? batchNumber : "-"}
        />
        <DataItem
          translate="components.deviceData.erpBatchNumber"
          value={erpBatchNumber ? erpBatchNumber : "-"}
        />
      </Box>
    );
  };

  const perlaLeft = () => {
    return (
      <Box>
        <DataItem
          translate="types.productInstance.firmware"
          value={
            <Box style={{ color: colours.arctic }}>
              <Box component="span" paddingRight="10px" color={colours.grey}>
                {firmware}
              </Box>
            </Box>
          }
        />
        <DataItem translate="components.deviceData.wpaKey" value={wPA2Key} />
        <DataItem
          translate="components.deviceData.serviceKey"
          value={getAccessCode(accessCodes, "SERVICE")}
        />
        <DataItem
          translate="components.deviceData.plumber"
          value={getAccessCode(accessCodes, "PLUMBER")}
        />
        <DataItem
          translate="components.deviceData.customer"
          value={getAccessCode(accessCodes, "CUSTOMER")}
        />
        {instanceType === "iot" && (
          <DataItem
            translate="types.productInstance.holidayModeActive"
            value={
              holidayModeActive ? (
                <Done color="primary" />
              ) : (
                <Clear color="error" />
              )
            }
          />
        )}
      </Box>
    );
  };

  function RegisteredPerlaOrDumbDeviceRight() {
    const [isTest, setIsTest] = React.useState<string | boolean>(isTestDevice);
    const [open, setOpen] = React.useState(false);

    const [updateTestDevice] = useMutation(UPDATE_TEST_FLAG_MUTATION);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setIsTest(event.target.value as boolean);
      updateTestDevice({
        variables: {
          productCode: productCode.toString(),
          isTestFlag: event.target.value as boolean,
        },
      });
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleOpen = () => {
      setOpen(true);
    };
    return (
      <Box>
        <DataItem
          translate="types.common.installationDate"
          value={reformat(installationDate, true)}
        />
        <DataItem
          translate="components.deviceData.userRegister"
          value={reformat(registrationDate)}
        />
        {instanceType === "iot" && (
          <DataItem
            translate="components.deviceData.dateRecieved"
            value={reformat(lastTelemetryDateStr)}
          />
        )}
        {isIot ||
        itemNumberObj.nonIotPerla.includes(itemNumber?.trim() ?? "") ? (
          <DataItem
            translate="components.deviceData.nextService"
            value={reformat(nextServiceDate, true)}
          />
        ) : (
          <Fragment />
        )}
        {isIot ||
        itemNumberObj.e1.includes(itemNumber?.trim() ?? "") ||
        itemNumberObj.penguin.includes(itemNumber?.trim() ?? "") ||
        itemNumberObj.nonIotPerla.includes(itemNumber?.trim() ?? "") ? (
          <DataItem
            translate="components.deviceData.customerMaintenance"
            value={reformat(nextCustomerMaintainance, true)}
          />
        ) : (
          <Fragment />
        )}
        <DataItem
          translate="components.deviceData.dataShare"
          value={
            dataSharedWithPartner ? (
              <Translate id="generics.true" />
            ) : (
              <Translate id="generics.false" />
            )
          }
        />
        <DataItem
          translate={"types.productInstance.isTestDevice"}
          tooltipText="This indicates whether this device is used for testing."
          value={
            <FormControl className={classes.testDeviceFormControl}>
              <Select
                labelId="is-test-device-label"
                id="is-test-device"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={isTest}
                onChange={handleChange}
                disableUnderline={true}
                inputProps={{ readOnly: campRnd ? false : true }}
              >
                <MenuItem value="true">
                  <Done color="primary" />
                </MenuItem>
                <MenuItem value="false">
                  <Clear color="error" />
                </MenuItem>
              </Select>
            </FormControl>
          }
        />
      </Box>
    );
  }

  const commonRight = () => {
    return (
      <Box>
        <DataItem
          translate="components.deviceData.articleNumber"
          value={itemNumber}
        />
        <DataItem
          translate="components.deviceData.installer"
          value={
            installerName ? (
              <Link
                underline="always"
                href={`/cs/partner/${installerId}`}
                color="inherit"
              >
                <span>{installerName}</span>
              </Link>
            ) : (
              <span>
                <Translate id="components.deviceData.noInstaller" />
              </span>
            )
          }
        />
        <DataItem
          translate="components.deviceData.servicePartner"
          value={
            partnerName ? (
              <Link
                underline="always"
                href={`/cs/partner/${partnerId}`}
                color="inherit"
              >
                <span>{partnerName}</span>
              </Link>
            ) : (
              <span>
                <Translate id="components.deviceData.noPartner" />
              </span>
            )
          }
        />
        {installationDate ? (
          <DataItem
            translate="components.deviceData.warranty"
            value={
              <div className={classes.warrantyContainer}>
                {addYears(
                  new Date(installationDate),
                  warrantyLength(itemNumber ?? "")
                ) > new Date() ? (
                  <Fragment>
                    <Done color="primary" />
                    <Translate id="components.deviceData.expires" />
                    &nbsp;
                    {reformat(
                      addYears(
                        new Date(installationDate),
                        warrantyLength(itemNumber ?? "")
                      ).toDateString(),
                      true
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <Clear htmlColor={colours.errorRed} />
                    <Translate id="components.deviceData.expired" />
                    &nbsp;
                    {reformat(
                      addYears(
                        new Date(installationDate),
                        warrantyLength(itemNumber ?? "")
                      ).toDateString(),
                      true
                    )}
                  </Fragment>
                )}
              </div>
            }
          />
        ) : (
          <div>-</div>
        )}
        {rndPermission && (
          <DataItem
            translate="types.productInstance.ballValveOpen"
            value={
              ballValveOpen ? (
                <Translate id="generics.true" />
              ) : (
                <Translate id="generics.false" />
              )
            }
          />
        )}
      </Box>
    );
  };

  return (
    <Box>
      <PageDialog
        open={openState}
        title={"pages.product.resetStateConfirm"}
        buttons={[
          {
            title: "generics.true",
            click: () => {
              resetState({ variables: { productCode: productCode } });
            },
          },
          {
            title: "generics.cancel",
            click: () => {
              setOpenState(false);
            },
          },
        ]}
        loading={loading}
      />
      <Box>
        <Typography variant="h6" component="h3">
          <strong>
            <Translate id="components.deviceData.title" />
          </strong>
        </Typography>
        <Box paddingBottom={2} fontWeight="fontWeightBold"></Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6} className={classes.spacing}>
          {commonLeft()}
          {instanceType === "iot" || instanceType === "not-reg" ? (
            perlaLeft()
          ) : (
            <Fragment />
          )}
        </Grid>

        <Grid item xs={6} className={classes.dataItem}>
          {commonRight()}
          {instanceType !== "not-reg" &&
            instanceType !== "" &&
            RegisteredPerlaOrDumbDeviceRight()}
        </Grid>
      </Grid>
      {instanceType === "iot" && (
        <Box display="flex" paddingTop={4}>
          <Box display="flex" width="80%">
            <Box display="flex" paddingRight="15px">
              <FlashOn className={classes.icon} />
              <RequestLogButton />
            </Box>
            <Box display="flex" paddingRight="15px">
              <DeleteForever className={classes.icon} />
              <ResetActivationButton />
            </Box>
            <Box display="flex" paddingRight="15px">
              <ErrorRounded className={classes.icon} />
              <ResetInstaller />
            </Box>
            <Box display="flex" paddingRight="15px">
              <RotateLeft className={classes.icon} />
              <Link
                underline="always"
                href="#"
                color="inherit"
                onClick={() => {
                  setOpenState(true);
                }}
              >
                <Translate id="components.deviceData.resetState" />
              </Link>
            </Box>
            {rawIotLoading ? (
              <CircularProgress
                color="secondary"
                style={{ marginLeft: "70px" }}
              />
            ) : (
              <div>
                {lastTelemetryDateStr ? (
                  <ClickAwayListener
                    onClickAway={!open ? () => {} : closePopper}
                  >
                    <Box display="flex" paddingRight="15px">
                      <CloudDownload className={classes.icon} />
                      <Link
                        underline="always"
                        href="#"
                        color="inherit"
                        onClick={handleClick("top")}
                      >
                        <Translate id="components.deviceData.downloadIOTMessages" />
                      </Link>
                      <Popper
                        id={id}
                        className={classes.popper}
                        open={open}
                        placement={placement}
                        anchorEl={anchorEl}
                      >
                        <div className={classes.paper}>
                          <Link
                            underline="always"
                            href="#"
                            color="inherit"
                            onClick={downloadRaw30}
                          >
                            <Translate id="components.deviceData.downloadLast30" />
                          </Link>
                        </div>
                        <div className={classes.paper}>
                          <Link
                            underline="always"
                            href="#"
                            color="inherit"
                            onClick={downloadRaw180}
                          >
                            <Translate id="components.deviceData.downloadLast180" />
                          </Link>
                        </div>
                        <div className={classes.paper}>
                          <Link
                            underline="always"
                            href="#"
                            color="inherit"
                            onClick={downloadRawAll}
                          >
                            <Translate id="components.deviceData.downloadAll" />
                          </Link>
                        </div>
                      </Popper>
                    </Box>
                  </ClickAwayListener>
                ) : (
                  <Box display="flex" paddingRight="15px">
                    <CloudDownload className={classes.inactiveIcon} />
                    <Link
                      underline="none"
                      href="#"
                      color="inherit"
                      className={classes.inactiveLink}
                    >
                      <Translate id="components.deviceData.noTelemetryData" />
                    </Link>
                  </Box>
                )}
              </div>
            )}
            <Box display="flex">
              {/* <Block className={classes.icon} /> */}
              {/* <SetOutOfServiceButton /> */}
            </Box>
          </Box>
          <Box width="50%" padding="0 15px 0 10px">
            {address && (
              <DeviceLocationModal
                existingAddress={{
                  productCode: productCode,
                  city: address.city,
                  countryCode: address.countryCode,
                  postalCode: address.postalCode,
                  street: address.street,
                  location: address.location && {
                    latitude: address.location.latitude,
                    longitude: address.location.longitude,
                  },
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const DataItem: React.FC<{
  translate: string;
  tooltipText?: string;
  value: any;
  short?: boolean;
}> = ({ translate, tooltipText, value, short = false }) => {
  return short ? (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={8}>
        <Box component="span" fontWeight="fontWeightBold">
          <Translate id={translate} />
          <Tooltip title={tooltipText ? { tooltipText } : ""}>
            <IconButton aria-label="helpTooltip">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box component="span">{value}</Box>
      </Grid>
    </Grid>
  ) : (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={6}>
        <Box component="span" fontWeight="fontWeightBold">
          <Translate id={translate} />
          {tooltipText ? (
            <Tooltip title={tooltipText}>
              <IconButton aria-label="help">
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <div></div>
          )}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box component="span">{value}</Box>
      </Grid>
    </Grid>
  );
};

export default DeviceData;
