import * as drawer from 'redux/actions/appShellActions';

import initialState from 'redux/initialState';

export default function appShellReducer(state = initialState.appShell, action: drawer.DrawerAction): drawer.DrawerState {
  switch (action.type) {
    case drawer.OPEN_DRAWER:
      return { ...state, drawerShown: true };
    case drawer.CLOSE_DRAWER:
      return { ...state, drawerShown: false };
    case drawer.TOGGLE_DRAWER:
      return { ...state, drawerShown: !state.drawerShown };
    default:
      return state;
  }
}
