import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import format from "date-fns/format";
import DateFnsUtils from "@date-io/date-fns";
import gql from "graphql-tag";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { Done, Clear } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { Translate } from "react-localize-redux";

import colours from "themes/bwtColors";
import {
  productInstanceAndCustomerDetails_productInstance,
  productInstanceAndCustomerDetails_productInstance_owner_userInformation as UserInfo,
} from "types/apolloGenerated/productInstanceAndCustomerDetails";
import { open as sendResetOpen } from "redux/actions/AccountShortcuts/SendResetActions";
import { UserInformation } from "types/ToolboxEntities";
import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useMutation, useQuery } from "react-apollo";
import ClearIcon from "@material-ui/icons/Clear";
import WarningIcon from "@material-ui/icons/Warning";
import { GET_USER_COUNTRIES_QUERY } from "commonQueries/userCountryQuery";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    appSymbol: {
      verticalAlign: "middle",
      display: "inline-flex",
    },
    dataItem: {
      maxWidth: "80%",
    },
    serviceContractFormControl: {
      minWidth: 60,
    },
  });
});

// function reformat(dateStr: string | null, short: boolean = false) {
//   if (!dateStr) return "-";
//   if (short) {
//     return (
//       <Translate>
//         {(translate) => {
//           return (
//             <div>
//               {format(
//                 new Date(dateStr),
//                 translate.translate("generics.dateTimeFormatFNSShort") as string
//               )}
//             </div>
//           );
//         }}
//       </Translate>
//     );
//   } else {
//     return (
//       <Translate>
//         {(translate) => {
//           return (
//             <div>
//               {format(
//                 new Date(dateStr),
//                 translate.translate("generics.dateTimeFormatFNS") as string
//               )}
//             </div>
//           );
//         }}
//       </Translate>
//     );
//   }
// }

interface CustomerDetailsProps {
  userInfo: UserInfo | null;
  sendResetOpen: typeof sendResetOpen;
  productInstance: productInstanceAndCustomerDetails_productInstance | null;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({
  userInfo,
  sendResetOpen,
  productInstance,
}) => {
  const classes = useStyles();

  const {
    id = "",
    firstName = "",
    lastName = "",
    //lastLogin = null,
    email = "",
    telephone,
    address,
    activity,
  } = userInfo || {};

  const {
    acceptedContractDate,
    //acceptedContractTerms
  } = productInstance || {};

  let lastAppActivity: string = "";

  for (const value of activity ?? []) {
    if (value?.clientName.startsWith("app")) {
      lastAppActivity = value?.lastAction;
    }
  }

  const UPDATE_CONTRACT_DATE_MUTATION = gql`
    mutation updateContractFlag(
      $productCode: String!
      $newDate: DateTimeOffset
    ) {
      productInstance {
        updateAcceptedContractFlag(productCode: $productCode, newDate: $newDate)
      }
    }
  `;

  const convertFlagToState = (contractDate: Date | null) => {
    if (contractDate == null) {
      return 0;
    }

    const date = new Date();
    const diff: number = Math.abs(
      date.getTime() - new Date(contractDate)?.getTime()
    );
    const diffDays: number = Math.ceil(diff / (1000 * 3600 * 24));

    if (diffDays > 730.485) {
      return 2;
    }

    return 1;
  };

  const [selectedDate, handleDateChange] = useState(
    acceptedContractDate ? new Date(acceptedContractDate) : null
  );
  const [hasAcceptedTerms, setTerms] = useState(
    convertFlagToState(acceptedContractDate)
  );
  const [updateContractDate] = useMutation(UPDATE_CONTRACT_DATE_MUTATION);
  const { data, loading } = useQuery(GET_USER_COUNTRIES_QUERY);
  const countries: string[] = data?.user?.allowedCountries;
  const updateUserAcceptedContractDate = (newDate: Date) => {
    handleDateChange(newDate);
    setTerms(convertFlagToState(newDate));
    updateContractDate({
      variables: {
        productCode: productInstance?.productCode,
        newDate: newDate as Date,
      },
    });
    renderContractDateLabel(selectedDate);
  };

  const resetContractDate = () => {
    handleDateChange(null);
    setTerms(convertFlagToState(null));
    updateContractDate({
      variables: {
        productCode: productInstance?.productCode,
        newDate: null,
      },
    });
    renderContractDateLabel(null);
  };

  function renderContractDateLabel(selectedDate: Date | null): string {
    if (selectedDate?.getFullYear() === 0 || selectedDate == null) {
      return "Not currently accepted";
    }

    return "Accepted on";
  }

  function renderContractDateValue(selectedDate: Date | null): Date | null {
    if (selectedDate?.getFullYear() === 0 || selectedDate == null) {
      return null;
    }

    return selectedDate;
  }

  const { street = "", postalCode = "", city = "" } = address || {};
  let isDenmark = false;

  //Service contract date is only available to those from Denmark (AS400)
  if (!loading) {
    if (countries.includes("DK")) {
      isDenmark = true;
    }
  }

  /*
   * Return tick if hasAccepted is true
   * Return cross if hasAccepted is false
   * Return waring if difference between accepted date and todays date is > 2 years
   */

  const checkContract = () => {
    if (hasAcceptedTerms === 0) {
      return (
        <MenuItem value="false">
          <Clear color="error" />
        </MenuItem>
      );
    }

    if (hasAcceptedTerms === 2) {
      return (
        <Tooltip title="Your service contract has expired">
          <IconButton>
            <WarningIcon />
          </IconButton>
        </Tooltip>
      );
    }

    if (hasAcceptedTerms === 1) {
      return (
        <MenuItem value="true">
          <Done color="primary" />
        </MenuItem>
      );
    }
  };

  return (
    <Box>
      <Typography variant="h6" component="h3">
        {firstName} {lastName}
      </Typography>
      <Box paddingBottom={2}>
        <Translate id="components.customerDetails.app" />
        {": "}
        {lastAppActivity !== "" ? (
          <Done className={classes.appSymbol} color="primary" />
        ) : (
          <Clear className={classes.appSymbol} htmlColor={colours.errorRed} />
        )}
        {lastAppActivity !== "" ? (
          lastAppActivity && (
            <Fragment>
              {" ( "}
              <Translate id="components.customerDetails.activity" />:{" "}
              <Translate>
                {(translate) => {
                  return (
                    <span>
                      {format(
                        new Date(lastAppActivity),
                        translate.translate(
                          "generics.dateTimeFormatFNSShort"
                        ) as string
                      )}{" "}
                    </span>
                  );
                }}
              </Translate>
              {" ) "}
            </Fragment>
          )
        ) : (
          <div />
        )}
      </Box>
      <Box>{street},</Box>
      <Box paddingBottom={2}>
        {postalCode} {city}
      </Box>
      <Box>{email}</Box>
      <Box paddingBottom={4}>{telephone}</Box>

      {isDenmark ? (
        <Fragment>
          <Box paddingBottom={1}>
            <DataItem
              translate="components.customerDetails.serviceContract"
              value={checkContract()}
            />
          </Box>
          {acceptedContractDate ? (
            <Box paddingBottom={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  label={renderContractDateLabel(selectedDate)}
                  inputVariant="outlined"
                  value={renderContractDateValue(selectedDate)}
                  onChange={(selectedDate) => {
                    updateUserAcceptedContractDate(selectedDate);
                  }}
                  style={{ width: "250px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
              <IconButton
                style={{ marginLeft: "5px" }}
                onClick={resetContractDate}
              >
                <ClearIcon />
              </IconButton>
            </Box>
          ) : (
            <Box paddingBottom={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  label={renderContractDateLabel(selectedDate)}
                  inputVariant="outlined"
                  value={renderContractDateValue(selectedDate)}
                  onChange={(selectedDate) => {
                    updateUserAcceptedContractDate(selectedDate);
                  }}
                  style={{ width: "260px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
              <IconButton
                style={{ marginLeft: "5px" }}
                onClick={resetContractDate}
              >
                <ClearIcon />
              </IconButton>
            </Box>
          )}
        </Fragment>
      ) : (
        <div />
      )}

      <Box>
        <Link underline="always" href={`/cs/customer/${id}`}>
          <Translate id="components.customerDetails.edit" />
        </Link>
        {" | "}
        <Link
          style={{ cursor: "pointer" }}
          underline="always"
          onClick={() =>
            sendResetOpen({
              id: userInfo?.id ?? "",
              email: userInfo?.email ?? "",
            } as UserInformation)
          }
        >
          <Translate id="components.customerDetails.resetPassword" />
        </Link>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ sendResetOpen }, dispatch);

const DataItem: React.FC<{
  translate: string;
  tooltipText?: string;
  value: any;
  short?: boolean;
}> = ({ translate, tooltipText, value, short = false }) =>
  short ? (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={8}>
        <Box component="span" fontWeight="fontWeightBold">
          <Translate id={translate} />
          <Tooltip title={tooltipText ? { tooltipText } : ""}>
            <IconButton aria-label="helpTooltip">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box component="span">{value}</Box>
      </Grid>
    </Grid>
  ) : (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={6}>
        <Box component="span" fontWeight="fontWeightBold">
          <Translate id={translate} />
          {tooltipText ? (
            <Tooltip title={tooltipText}>
              <IconButton aria-label="help">
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <div></div>
          )}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box component="span">{value}</Box>
      </Grid>
    </Grid>
  );

export default connect(null, mapDispatchToProps)(CustomerDetails);
