import React, { Fragment } from 'react';

import { Theme, makeStyles, Icon, SnackbarContent } from '@material-ui/core';

import { Translate } from "react-localize-redux";
import { Error as ErrorType, ErrorCodes } from 'types/CampGuideTypes';

import { fallbackTo } from 'components/common/Localization';

interface TranslateErrorProps {
  error?: ErrorType | null
}

interface ErrorProps {
  error?: (ErrorType | string)[] | ErrorType | string | null
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    backgroundColor: theme.palette.error.dark,
    marginBottom: theme.spacing(3),
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const TranslateError: React.FC<TranslateErrorProps> = ({ error }) => {
  if (!error) {
    return null;
  }

  if (error.code === ErrorCodes.Unknown && error.message.length > 0) {
    return <Fragment>{error.message}</Fragment>;
  }

  const id = 'message.error.' + error.code;

  return <Translate id={id} options={{ onMissingTranslation: fallbackTo(error.message), renderToStaticMarkup: false }} />
};

const Error: React.FC<ErrorProps> = ({ error }) => {
  const classes = useStyles();
  if (!error || (Array.isArray(error) && error.length === 0)) {
    return null;
  }
  const errorArray: (ErrorType | String)[] = Array.isArray(error) ? error : [error];


  //console.log("___ ", errorArray);
  const output = errorArray.map((errorItem, idx) => (
    <span key={idx} id="client-snackbar" className={classes.message}>
      <Icon className={classes.icon} />
      {(typeof errorItem === 'string') ? errorItem : <TranslateError error={errorItem as ErrorType} />}
    </span>
  ));


  return (
    <SnackbarContent className={classes.error} aria-describedby="client-snackbar" message={output} />
  );
};

export const CustomError: React.FC = ({ children }) => {
  const classes = useStyles();

  const output = (
    <span className={classes.message}>
      <Icon className={classes.icon} />
      {children}
    </span>
  );


  return (
    <SnackbarContent className={classes.error} aria-describedby="client-snackbar" message={output} />
  );
};

export default Error;


