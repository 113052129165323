import { AbilityBuilder } from '@casl/ability'
import { createCanBoundTo } from '@casl/react'

import * as Types from 'types/ToolboxEntities';

import store from 'redux/store';

export const ability = AbilityBuilder.define((can: Function) => {
  can('read', 'NonExisten');
  can('write', 'NonExisten');
});

let userId: string | -1;
store.subscribe(() => {
  const prevUserId = userId;
  const newUser = store.getState().auth.user;
  const newUserId = newUser ? newUser.userInformation.id : -1;

  if (prevUserId !== newUserId) {
    userId = newUserId;
    //console.log('Store changed -> User Changed');
    setAbilityFromUser(newUser);
  }
});


export const setAbilityFromUser = (user: Types.UserProfile | null): void => {
  const { rules, can } = AbilityBuilder.extract()
  //console.log('Rules before Update: ', JSON.parse(JSON.stringify(ability.rules)));

  if (user) {
    user.roles.forEach((item: Types.Role) => {
      switch (item.roleName) {
        case 'CampGuideSysAdmin':
          can('read', 'Partner');
          can('write', 'Partner');

          can('read', 'Product');
          can('write', 'Product');

          can('read', 'Customer');
          can('write', 'Customer');

          can('write', 'RND');

          can('read', 'Admin');
          can('write', 'Admin');

          can('read', 'Promotion');
          can('write', 'Promotion');

          can('read', 'Consumable');
          can('write', 'Consumable');

          can('read', 'Production');
          can('write', 'Production');

          can('read', 'SendGrid');
          can('write', 'SendGrid');
          break;

        case 'PartnerManager':
          can('read', 'Partner');
          can('write', 'Partner');
          break;

        case 'ProductManager':
          can('read', 'Product');
          can('write', 'Product');

          can('read', 'Consumable');
          can('write', 'Consumable');
          break;

        case 'CustomerService':
          can('read', 'Customer');
          can('write', 'Customer');
          break;

        case 'SendGridAdmin':
          can('read', 'SendGrid');
          can('write', 'SendGrid');
          break;

        case 'rnd':
          can('read', 'RND');
          can('write', 'RND');
          break;

        case 'admin':
          can('read', 'Admin');
          can('write', 'Admin');
          break;

        case 'sendgrid':
          can('read', 'Sendgrid');
          can('write', 'Sendgrid');
          break;

        case 'promotion':
          can('read', 'Promotion');
          can('write', 'Promotion');
          break;

        case 'Production': 
          can('read', 'Production');
          can('write', 'Production');
          break;
          
        case 'AuditAdmin':
          can('read', 'AuditLog');
          break;

        case 'CampRnd':
          can('read', 'RND');
          can('write', 'RND');
          break;

        case 'SalesRep':
          can('read', 'Partner');
      }
    });
  }

  ability.update(rules);
  //console.log('Rules after Update: ', ability.rules);
};

export const Can = createCanBoundTo(ability);
export default Can;
