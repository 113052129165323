/*
 * CI/CD Colors
 */
const bwtColors = {
  pink: "#F5B5C9",

  pink80: "#F7C5D5",
  pink50: "#FADCE6",

  blue: "#005D8F",

  arctic: "#6286A4",
  arctic80: "#819BB4",
  arctic50: "#B0BECF",
  arctic15: "#E6EAF1",

  grey: "#666B6E",
  grey80: "#878C8F",
  grey50: "#B5B9BB",
  grey35: "#CFD1D1",
  grey20: "#E1E3E4",
  grey10: "#F0F1F2",
  grey5: "#F7F8F8",

  turquoise: "#5FC3E1",
  turquoise80: "#8AD1E8",
  turquoise50: "#B6E5F5",

  /* Special Colors */
  pinkButton: "#F5B5C9",
  magenta: "#F5B5C9",
  white: "#fff",
  errorRed: "#CA3336",
  warnYellow: "#DDB868",
};

export default bwtColors;

/*
Extra colours that can be reused in C.A.M.P. Guide 
*/
export const campColours = {
  backgroundWarn: "#FEF9E5",
  backgroundError: "#FFE8EC",
  backgroundDiscon: "#cff",
  bgWarnHover: "#FEF9E566",
  bgErrorHover: "#FFE8EC66",
  bgDisconHover: "#cff6",
};
