import React from 'react';
import { connect } from 'react-redux';

import { Route, RouteProps, Redirect } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import { AppState } from 'redux/store';

import { ability } from 'util/ability';

import AppShell from 'components/common/AppShell';
import { AuthStore } from 'redux/types/Auth';

import HomePage from 'Pages/Home'
import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    content: {
      padding: theme.spacing(3),
    },
  })
});


export const AppRoute: React.FC<RouteProps> = ({ component: Component, ...params }) => {
  if (!Component) throw new Error('Component can not be null or undefined!');
  return (
    <Route
      {...params}
      render={matchProps => (
        <AppShell>
          <Component {...matchProps} />
        </AppShell>
      )}
    />
  );
}



export interface AccessAppRouteProps extends RouteProps {
  can?: {
    i: string,
    a: string,
    redirect?: string
  },
  auth: AuthStore
}

const AccessAppRoute: React.FC<AccessAppRouteProps> = ({ component, can, auth, ...params }) => {
  const contentClass = useStyles().content;
  if (can && ability.cannot(can.i, can.a)) {
    if (auth.isLoading) {
      const tmpComp: React.FC = () => (
        <div className={contentClass}>
          <CircularProgress color="secondary" />
        </div>
      );
      return <AppRoute component={tmpComp} {...params} />;
    }
    if (can.redirect) {
      return <Redirect to={can.redirect} />;
    }
    return <AppRoute component={HomePage} {...params} />;
  }
  return <AppRoute component={component} {...params} />;
}



const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(AccessAppRoute);
