export enum ActionType {
  OPEN = 'SET_OUT_OF_SERVICE_OPEN',
  CLOSE = 'SET_OUT_OF_SERVICE_CLOSE',
}

export interface Store {
  openWithProductCode: string | undefined,
}

export interface OpenAction {
  type: ActionType.OPEN,
  payload: string
}

export interface CloseAction {
  type: ActionType.CLOSE,
}


export type Action = OpenAction | CloseAction;

