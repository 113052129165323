import gql from "graphql-tag";


const FRAGMENT_PARTNER_GROUPS = gql`
  fragment OrganizationGroup on OrganizationGroup {
    id
    name
  }
`;


export const FRAGMENT_PARTNER_ADDRESS = gql`
  fragment OrganizationAddress on Address {
      street
      postalCode
      city
      countryCode
      location {
        latitude
        longitude
      }
  }
`;


export const FRAGMENT_PARTNER_DETAIL = gql`
  fragment OrganizationDetail on Organization {
    id
    externalId
    isDeleted
    name
    partnerType
    visibility
    address {
      ...OrganizationAddress
    }
    email
    website
    telephone
    vatNumber
    logoUrl
    logoLinkUrl
    partnerImageUrl
    size
    partnerImageLinkUrl
    creationTimeStamp
    bonusaccount {
      accountId
      pearls
      details {
        __typename
      ... on ConnexBonusAccountDetails {
        shopLoginLink
        remoteAvailable
      }
    }
      permission
    }
    groups{
      ...OrganizationGroup
      id
      name
    }
  }
  ${FRAGMENT_PARTNER_GROUPS}
  ${FRAGMENT_PARTNER_ADDRESS}
`;




export const QUERY_PARTNER_DETAIL = gql`
query PartnerDetail($id: Int!) {
  organization (id:$id) {
    ...OrganizationDetail
  }
}
${FRAGMENT_PARTNER_DETAIL}
`;


export const QUERY_PARTNER_GROUPS = gql`
query PartnerGroups {
  organizationGroups{
    ...OrganizationGroup
  }
}
${FRAGMENT_PARTNER_GROUPS}
`;



export const QUERY_PARTNER_EMPLOYEES = gql`
query PartnerEmployees($id: Int!) {
  organization (id:$id) {
    id
    employees{
      results{
        position
        userInformation{
          id
          firstName
          lastName
          email
          key
        }
      }
    }
  }
}
`;





export const MUTATION_PARTNER_ADD_EMPLOYEE = gql`
mutation AddEmployee($userId: Int!, $partnerId: Int!, $position: Position!){
  organization{
    createEmployee(employeeInsert: {
      organizationId: $partnerId,
      userId: $userId,
      position: $position
    })
  }
}
`;


