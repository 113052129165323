import React from "react";
import {
  setGridState,
  setColumnStates,
  search,
} from "redux/actions/SendgridTemplatesOverviewActions";

import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { withLocalize, LocalizeContextProps } from "react-localize-redux";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

import { AllEnterpriseModules } from "@ag-grid-enterprise/all-modules";
import {
  ColDef,
  MenuItemDef,
  GetContextMenuItemsParams,
} from "@ag-grid-enterprise/all-modules";
import { TranslateFunction } from "react-localize-redux";

import { sendgridGroupsGrid_sendGridTemplates_results } from "types/apolloGenerated/sendgridGroupsGrid";

import {
  updateSendgridTemplate,
  updateSendgridTemplateVariables,
} from "types/apolloGenerated/updateSendgridTemplate";
import { State as gridState } from "redux/types/PartnerOverview";

import { sendgridGroups_sendGridTemplates_results } from "types/apolloGenerated/sendgridGroups";
import { Action as ResetAction } from "redux/types/SendgridTemplatesDelete";
import { UserInformation } from "types/ToolboxEntities";
import { open as deleteOpen } from "redux/actions/SendgridTemplatesDeleteActions";
import { open as createOpen } from "redux/actions/SendgridTemplatesCreateActions";
import { ReduxAgGrid } from "components/common/Grid/AgGrid";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import {
  getSendgridUsers,
  getSendgridUsers_sendGridUsers as SendGridUserIds,
} from "types/apolloGenerated/getSendgridUsers";
import { sendGridSupportedLanguages } from "types/apolloGenerated/sendGridSupportedLanguages";
import { GET_SENDGRID_USERS } from "commonQueries/sendgridIds";
import { QUERY_SENDGRID_SUPPORTED_LANGUAGES } from "commonQueries/sendgridSupportedLanguages";
import { Fragment } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

interface TemplatesGridProps extends LocalizeContextProps {
  templates: (sendgridGroupsGrid_sendGridTemplates_results | null)[];
  state: gridState;
  actions: {
    setGridState: typeof setGridState;
    setColumnStates: typeof setColumnStates;
    search: typeof search;
    deleteOpen: typeof deleteOpen;
    createOpen: typeof createOpen;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customGrid: {
      height: "100%",
      display: "flex",
      overflow: "hidden",
      position: "relative",
      flexFlow: "column",
      "& .ag-row-selected": {
        backgroundColor: "#f29083",
      },
    },
  })
);

const UPDATE_SENDGRID_TEMPLATE = gql`
  mutation updateSendgridTemplate(
    $templateId: Long!
    $templateName: String!
    $sendgridId: String!
    $sendGridUserId: Int!
    $isDefault: Boolean!
    $countryCode: Country
    $languageCode: Language
    $templateType: SendGridTemplateType
  ) {
    sendGridTemplate {
      update(
        sendGridTemplate: {
          id: $templateId
          name: $templateName
          sendGridUserId: $sendGridUserId
          sendGridId: $sendgridId
          isDefault: $isDefault
          countryCode: $countryCode
          languageCode: $languageCode
          type: $templateType
        }
      ) {
        name
        countryCode
      }
    }
  }
`;

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: "agTextColumnFilter",
  editable: true,
  suppressHorizontalScroll: false,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  menuTabs: ["filterMenuTab"],
};

export default withLocalize(
  ({ templates, state, actions, translate }: TemplatesGridProps) => {
    const classes = useStyles();
    const [updateSendgridTemplateFunc] = useMutation<
      updateSendgridTemplate,
      updateSendgridTemplateVariables
    >(UPDATE_SENDGRID_TEMPLATE);
    const { data: sendGridUserIds } = useQuery<getSendgridUsers>(
      GET_SENDGRID_USERS
    );
    const sgIds = sendGridUserIds?.sendGridUsers ?? [];
    const {
      data: supportedLanguages,
      loading: loadingLanguages,
    } = useQuery<sendGridSupportedLanguages>(
      QUERY_SENDGRID_SUPPORTED_LANGUAGES
    );
    const supLanguages = supportedLanguages?.sendGridSupportedLanguages ?? [];

    countries = supLanguages.map((language) => {
      const languageCode = language?.languageCode?.toLowerCase() ?? "";
      const countryCode = language?.countryCode?.toUpperCase() ?? "";
      if (countryCode === "") {
        return languageCode;
      } else {
        return languageCode + "-" + countryCode;
      }
    });

    const onRowEditingStoppedFunc = (params: any) => {
      if (params.newValue !== params.oldValue) {
        if (!(params.oldValue === null && params.newValue === "")) {
          //console.log("params changed", params.data);
          params.data.languageCode = params.data.languageCode.toLowerCase();
          const templateRow = params.data;
          params.node.setSelected(false);
          if (
            templateRow.sendGridId &&
            templateRow.languageCode &&
            templateRow.sendGridUserId
          ) {
            updateSendgridTemplateFunc({
              variables: {
                templateId: templateRow.id,
                templateName: templateRow.name,
                sendgridId: templateRow.sendGridId,
                isDefault: templateRow.isDefault,
                sendGridUserId: templateRow.sendGridUserId,
                countryCode: templateRow.countryCode
                  ? templateRow.countryCode.trim()
                  : null,
                languageCode: templateRow.languageCode.toLowerCase(),
                templateType: templateRow.type
                  ? templateRow.type
                  : "NOT_SPECIFIED",
              },
            }).catch(() => {});
          } else {
            params.node.setSelected(true);
          }
        }
      }
    };

    return (
      <Fragment>
        {loadingLanguages ? (
          <div>
            {" "}
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div className="innerContent ag-theme-balham customGrid">
            <ReduxAgGrid
              className={classes.customGrid}
              animateRows={true}
              defaultColDef={defaultColDef}
              pagination={true}
              modules={[...AllEnterpriseModules, ClientSideRowModelModule]}
              columnDefs={getColumnDefs(translate, sgIds)}
              aggFuncs={{
                combineVals: combineValsFunc,
              }}
              suppressAggFuncInHeader={true}
              groupUseEntireRow={true}
              onCellValueChanged={onRowEditingStoppedFunc}
              onRowEditingStopped={onRowEditingStoppedFunc}
              components={{
                countryCellRenderer: countryCellRenderer,
                languageCellRenderer: languageCellRenderer,
                sendgridUserCellRenderer: sendgridUserCellRenderer,
                sendgridUserEditCellRenderer: sendgridUserEditCellRenderer,
              }}
              getContextMenuItems={getContextMenuItems(
                actions.deleteOpen,
                translate
              )}
              groupRowInnerRenderer="countryCellRenderer"
              groupRowRendererParams={{
                suppressCount: true,
              }}
              gridState={state.grid}
              gridActions={actions}
              quickFilterText={state.search || undefined}
              autoGroupColumnDef={{
                headerName: translate("types.common.name") as string,
                width: 375,
                cellRendererParams: {
                  suppressHorizontalScroll: false,
                  suppressCount: true,
                },
              }}
              enableGroupEdit={true}
              rowData={templates}
            ></ReduxAgGrid>
          </div>
        )}
      </Fragment>
    );
  }
);

const getColumnDefs = (
  translate: TranslateFunction,
  sendGridUserIds: (SendGridUserIds | null)[]
): ColDef[] => {
  const removeFilterSort = {
    sortable: false,
    filter: false,
    filterParams: {},
    menuTabs: [],
  };

  const columnDefs = [
    {
      headerName: translate("types.common.name") as string,
      field: "name",
      rowGroup: true,
      width: 250,
      hide: true,
    },
    {
      field: "languageCode",
      aggFunc: "combineVals",
      width: 130,
      pinned: "left",
      cellRenderer: "languageCellRenderer",
    },
    {
      field: "countryCode",
      aggFunc: "combineVals",
      width: 130,
      pinned: "left",
      cellRenderer: "countryCellRenderer",
    },

    {
      headerName: translate("types.sendgrid.sendgridId") as string,
      field: "sendGridId",
      width: 250,
      ...removeFilterSort,
    },
    {
      headerName: "isDefault",
      field: "isDefault",
      width: 90,
      cellRenderer: (params: any) => {
        let checkBox = ``;
        if (params.value !== undefined) {
          checkBox = `<input type='checkbox' ${
            params.value ? "checked" : ""
          } />`;
        }
        return checkBox;
      },
      ...removeFilterSort,
    },
    {
      headerName: "User",
      field: "sendGridUserId",
      width: 150,
      cellRenderer: "sendgridUserCellRenderer",
      cellEditor: "agRichSelectCellEditor",
      cellRendererParams: {
        sgIds: sendGridUserIds,
      },
      cellEditorParams: {
        values: sendGridUserIds.map((sendgrid) => sendgrid?.id),
        cellRenderer: "sendgridUserEditCellRenderer",
        cellRendererParams: {
          sgIds: sendGridUserIds,
        },
      },
      editable: true,
      ...removeFilterSort,
    },
    {
      headerName: translate("types.sendgrid.type") as string,
      field: "type",
      width: 200,
      ...removeFilterSort,
    },
  ];

  return columnDefs;
};

const getContextMenuItems = (
  sendResetOpen: (user: UserInformation) => ResetAction,
  translate: TranslateFunction
) => {
  return (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    let menuItems: (string | MenuItemDef)[] = [
      "copy",
      "copyWithHeaders",
      "separator",
      "export",
    ];

    if (params?.node?.data) {
      // console.log(rowData);
      menuItems = [
        ...getExpandedMenuItems(
          123,
          params.node.data as sendgridGroups_sendGridTemplates_results,
          sendResetOpen,
          translate
        ),
        ...menuItems,
      ];
    }

    return menuItems;
  };
};

const getExpandedMenuItems = (
  partnerId: number,
  rowData: sendgridGroups_sendGridTemplates_results,
  sendResetOpen: (user: UserInformation) => ResetAction,
  translate: TranslateFunction
) => {
  return [
    {
      name: translate("pages.sendgrid.templatesGrid.deleteTemplate") as string,
      action: () => {
        //console.log("change email and password");
        //console.log("rowData", rowData);
        sendResetOpen({
          id: rowData.id,
        } as UserInformation);
      },
    },
    "separator",
  ];
};

function combineValsFunc(nodes: any) {
  const flagsArr: string[] = [];

  for (let userObject of nodes) {
    if (userObject) flagsArr.push(userObject);
  }

  return flagsArr.length ? flagsArr : "";
}

export var countries: string[] = [];

function countryCellRenderer(params: any) {
  const languages: string[] = [];

  const languageVal = [];
  const countryVal = [];
  const isDefault = [];
  let fontStyle = "";

  for (let language of countries) {
    if (!languages.includes(language.substring(0, 2))) {
      languages.push(language.substring(0, 2));
    }
  }

  const cellParams = params.value;
  var flags =
    "<span style='min-width: 350px; display: inline-block;'>" +
    params.value +
    " (" +
    params.node.allChildrenCount +
    ")  </span> ";

  if (params.value !== null) {
    if (Array.isArray(cellParams)) {
      for (let leafChild of params.node.allLeafChildren) {
        if (leafChild.data.countryCode) {
          countryVal.push(
            leafChild.data.languageCode + "-" + leafChild.data.countryCode
          );
        } else {
          countryVal.push(leafChild.data.languageCode);
        }
      }

      for (let userObject of countries) {
        flags += countryVal.includes(userObject)
          ? '<span style="width: 75px; display: inline-block; color: green">' +
            userObject +
            "</span>"
          : '<span style="width: 75px; display: inline-block; color: #ccc">' +
            userObject +
            "</span>";
      }
    } else {
      if (params.node.allLeafChildren) {
        for (let leafChild of params.node.allLeafChildren) {
          if (leafChild.data.countryCode) {
            countryVal.push(
              leafChild.data.languageCode + "-" + leafChild.data.countryCode
            );
            if (leafChild.data.isDefault) {
              isDefault.push(
                leafChild.data.languageCode + "-" + leafChild.data.countryCode
              );
            }
          } else {
            //only languageCodes
            languageVal.push(leafChild.data.languageCode);
            if (leafChild.data.isDefault) {
              isDefault.push(leafChild.data.languageCode);
            }

            if (leafChild.data.languageCode === "EN") {
              countryVal.push(leafChild.data.languageCode);
            }
          }
        }

        if (countryVal.length > languageVal.length) {
          for (let countryObj of countries) {
            countryObj = countryObj.toUpperCase();
            isDefault.includes(countryObj)
              ? (fontStyle = "bold")
              : (fontStyle = "normal");
            flags += countryVal.includes(countryObj)
              ? '<span style="width: 75px; display: inline-block; color: green; font-weight:' +
                fontStyle +
                '">' +
                countryObj +
                "</span>"
              : '<span style="width: 75px; display: inline-block; color: #ccc">' +
                countryObj +
                "</span>";
          }
        } else {
          if (languageVal.length) {
            const handledLanguages: String[] = [];
            if (countryVal.length) {
              for (let countryObj of countryVal) {
                const languageObj = countryObj.substring(0, 2).toUpperCase();
                if (!languageVal.includes(languageObj))
                  languageVal.push(languageObj);
              }
            }
            for (let languageObj of languages) {
              languageObj = languageObj.toUpperCase();
              isDefault.includes(languageObj)
                ? (fontStyle = "bold")
                : (fontStyle = "normal");
              flags +=
                languageVal.includes(languageObj) &&
                !handledLanguages.includes(languageObj)
                  ? '<span style="width: 75px; display: inline-block; color: green; font-weight:' +
                    fontStyle +
                    '"">' +
                    languageObj +
                    "</span>"
                  : '<span style="width: 75px; display: inline-block; color: #ccc">' +
                    languageObj +
                    "</span>";
            }
          }
        }
      } else {
        flags = params.data.countryCode + " ";
      }
    }
  } else {
    flags = "";
  }
  return flags;
}

function sendgridUserCellRenderer(params: any) {
  return (
    params.sgIds.find(
      (sendgrid: SendGridUserIds) => sendgrid?.id === params.value
    )?.name ?? ""
  );
}

function languageCellRenderer(params: any) {
  if (params.value === undefined || params.value.length === 0) {
    return "";
  } else {
    const cellParams = params.value; //.toUpperCase()

    var flags = "";

    if (Array.isArray(cellParams)) {
      //flags = params.eGridCell.nextElementSibling.innerHTML;
      //params.eGridCell.nextElementSibling.innerHTML = "";
    } else {
      flags = cellParams;
    }

    return flags;
  }
}

function sendgridUserEditCellRenderer(params: any) {
  return (
    params.sgIds.find(
      (sendgrid: SendGridUserIds) => sendgrid?.id === params.value
    )?.name ?? ""
  );
}
