import gql from "graphql-tag";

export const PRODUCT_QUERY = gql`
  query products{
    products(pageSize: 0){
      page
      pageSize
      totalResults
      results{
        productInformation{
          id
          name
          isArchived
          isProductRegistrationEnabled
        }
      }
    }
  } 
`

export const COUNTRY_CODE_QUERY = gql`
  query countryCodes{
    countryCodes(pageSize: 0){
      page
      pageSize
      totalResults
      results{
        id
        country
      }
    }
  }
`


export const DEACTIVATE_SINGLE_CODE = gql`
  mutation deactivateCode($couponCodeId: Int!){
    promotion{
      deactivateCouponCode(couponCodeId: $couponCodeId)
    }
  }
`

export const ACTIVATE_SINGLE_CODE = gql`
  mutation activateCode($couponCodeId: Int!){
    promotion{
      activateCouponCode(couponCodeId: $couponCodeId)
    }
  }
`

export const QUERY = gql`
  query promotionDetail($id: Int!) {
    promotion(id: $id) {
      id
      creationDate
      articleNumber
      creationUser
      isDeleted
      assignedProducts {
        name
      },
      countryCodes {
        id,
        country
      }
      name
      type
      usageCountLimit
    }
  }
`

export const UPDATE_PROMOTION = gql`
  mutation updatePromotion($promotion: PromotionUpdate!){
    promotion{
      updatePromotion(promotion: $promotion){
        id
        creationDate
        creationUser
        articleNumber
        isDeleted
        name
        type
      }
    }
  }
`

export const CREATE_PROMOTION = gql`
  mutation createPromotion($promotion: PromotionInsertInput!){
    promotion{
      createPromotion(promotion: $promotion){
        id
        creationDate
        creationUser
        articleNumber
        isDeleted
        name
        type
      }
    }
  }
`