import React, { useState, Fragment } from "react";
import { Translate } from "react-localize-redux";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import {
  createStyles,
  Theme,
  makeStyles,
  withStyles
} from "@material-ui/core/styles";
import colours from "themes/bwtColors";

import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

import GoogleMap, { getLocationFromAddress } from "./GoogleMap";

import {
  updateProductInstanceAddress as MutationData,
  updateProductInstanceAddressVariables as MutationVars
} from "types/apolloGenerated/updateProductInstanceAddress";

import {
  ProductInstanceAddressUpdateInput,
  Country,
  GeoLocationInput
} from "types/apolloGenerated/globalTypes";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    closeButton: {
      position: "absolute",
      top: "0px",
      right: "0px",
      margin: "6px",
      cursor: "pointer"
    },
    spacing: {
      marginRight: "10px"
    },
    button: {
      backgroundColor: colours.arctic
    },
    uppercase: {
      textTransform: "uppercase"
    },
    overlay: {
      position: "relative",
      textAlign: "center",
      verticalAlign: "middle",
      lineHeight: "400px",
      background: "rgba(0, 0, 0, 0.5)",
      color: "white",
      fontSize: "20px",
      textTransform: "uppercase"
    }
  });
});

const DialogTitle = withStyles((theme: Theme) => ({
  root: {
    padding: 10
  }
}))(MuiDialogTitle);

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: 0
  }
}))(MuiDialogContent);

const MUTATION = gql`
  mutation updateProductInstanceAddress(
    $address: ProductInstanceAddressUpdateInput!
  ) {
    productInstance {
      updateProductInstanceAddress(address: $address) {
        id
        street
        postalCode
        city
        countryCode
        location {
          latitude
          longitude
        }
      }
    }
  }
`;

const DeviceLocationModal: React.FC<{
  existingAddress: ProductInstanceAddressUpdateInput;
}> = ({ existingAddress }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [addressUpdateInput, setAddressUpdateInput] = useState<
    ProductInstanceAddressUpdateInput
  >(existingAddress);

  function locationUpdate(address: ProductInstanceAddressUpdateInput): void {
    if (
      address.countryCode &&
      (address.postalCode || (address.street && address.city))
    ) {
      const addressString = `${address.street} ${address.city} ${address.postalCode} ${address.countryCode}`;
      getLocationFromAddress(addressString, (newState, newLocation) => {
        setState(newState);
        if (newState === "OK") {
          setAddressUpdateInput({
            ...addressUpdateInput,
            location: newLocation
          });
        }
      });
    }
  }

  const [updateAddress, { loading }] = useMutation<MutationData, MutationVars>(
    MUTATION
  );

  const [state, setState] = useState<string>(loading ? "loading" : "OK");

  const addressInputUpdater = (
    applyValue: (value: string) => ProductInstanceAddressUpdateInput
  ) => (e: any) => {
    const newInput = applyValue(e.target.value);
    console.log(newInput, addressUpdateInput)
    if (deeplyEqual(newInput, addressUpdateInput)) {
      locationUpdate(newInput);
    }
    setAddressUpdateInput(newInput);
  };

  return (
    <Fragment>
      <Link
        underline="always"
        href="#"
        color="inherit"
        onClick={handleClickOpen}
      >
        {`${existingAddress.street}, ${existingAddress.postalCode} ${existingAddress.city} ${existingAddress.countryCode}\n${existingAddress.location?.latitude}, ${existingAddress.location?.longitude}`}
      </Link>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <CloseIcon
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box margin={2} height={400} position="relative">
              <GoogleMap
                location={addressUpdateInput.location}
                onChange={(value: GeoLocationInput) => {
                  setAddressUpdateInput({
                    ...addressUpdateInput,
                    location: value
                  });
                  updateAddress({
                    variables: { address: addressUpdateInput }
                  });
                }}
              />
              {state !== "OK" && (
                <Box id="stateOverlay" className={classes.overlay}>
                  {state}
                </Box>
              )}
            </Box>
            <Box display="flex" padding={2}>
              <Box>
                <Translate id="types.address.street" />
                <TextField
                  id="deviceLocationStreet"
                  variant="outlined"
                  defaultValue={existingAddress.street}
                  className={classes.spacing}
                  onBlur={addressInputUpdater(value => ({
                    ...addressUpdateInput,
                    street: value
                  }))}
                />
              </Box>
              <Box width={120}>
                <Translate id="types.address.postalCode" />
                <TextField
                  id="deviceLocationPostalCode"
                  variant="outlined"
                  defaultValue={existingAddress.postalCode}
                  className={classes.spacing}
                  onBlur={addressInputUpdater(value => ({
                    ...addressUpdateInput,
                    postalCode: value
                  }))}
                />
              </Box>
              <Box>
                <Translate id="types.address.city" />
                <TextField
                  id="deviceLocationCity"
                  variant="outlined"
                  defaultValue={existingAddress.city}
                  className={classes.spacing}
                  onBlur={addressInputUpdater(value => ({
                    ...addressUpdateInput,
                    city: value
                  }))}
                />
              </Box>
              <Box>
                <Translate id="types.address.countryCode" />
                <Box display="flex" margin={0}>
                  <TextField
                    id="deviceLocationCountryCode"
                    variant="outlined"
                    defaultValue={existingAddress.countryCode}
                    className={classes.spacing}
                    style={{ textTransform: "uppercase", width: 60 }}
                    onBlur={addressInputUpdater(value => ({
                      ...addressUpdateInput,
                      countryCode: stringToCountryCode(value)
                    }))}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={inputChanged(
                      addressUpdateInput,
                      existingAddress
                    )}
                    onClick={() => {
                      updateAddress({
                        variables: { address: addressUpdateInput }
                      });
                      locationUpdate(addressUpdateInput);
                    }}
                  >
                    <Translate id="generics.save" />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

function stringToCountryCode(str: string): Country | null {
  return Object.values(Country).find(x => x === str.toUpperCase()) || null;
}

function inputChanged(
  input: ProductInstanceAddressUpdateInput,
  existing: ProductInstanceAddressUpdateInput
): boolean {
  return deeplyEqual(input, existing);
}

function deeplyEqual(
  a: ProductInstanceAddressUpdateInput,
  b: ProductInstanceAddressUpdateInput
): boolean {
  if (a.street && a.street !== b.street) return false;
  if (a.postalCode && a.postalCode !== b.postalCode) return false;
  if (a.city && a.city !== b.city) return false;
  if (a.countryCode && a.countryCode !== b.countryCode) return false;
  return true;
}

export default DeviceLocationModal;
