import React from "react";
import { Translate, TranslateFunction } from "react-localize-redux";
import { Link } from "react-router-dom";

import { ColDef } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { factoryApolloConnector } from "util/AgGridUtils";

import {
  customerProductInstances,
  customerProductInstances_customers_results_registeredProducts,
} from "types/apolloGenerated/customerProductInstances";

import SyncIcon from "@material-ui/icons/Sync";
import CheckIcon from "@material-ui/icons/Check";
import bwtColors, { campColours } from "themes/bwtColors";
import { format } from "date-fns";
import { Done, Clear } from "@material-ui/icons";
import { MenuItem, Tooltip } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { QUERY_CUSTOMER_PRODUCT_INSTANCES } from "commonQueries/customerProductInstances";

export const apolloConnectorById = (id: String) => {
  return factoryApolloConnector(
    QUERY_CUSTOMER_PRODUCT_INSTANCES,
    (data: customerProductInstances) => {
      const result = data.customers?.results?.[0];

      // getDataSource expects results rather than "registeredProducts".
      const transformedResponse = {
        results: result?.registeredProducts,
        __typename: result?.__typename,
      };

      return transformedResponse;
    },
    {},
    { customArguments: { id } }
  );
};

export const getColumnDefs = (translate: TranslateFunction): ColDef[] => {
  const stateStyle = (params: any) => {
    if (!params || !params.data) return {};
    if (params.data.state === "ERROR") {
      return { backgroundColor: campColours.backgroundError };
    }
    if (params.data.state === "WARNING") {
      return { backgroundColor: campColours.backgroundWarn };
    }
  };
  const columnDefs = [
    {
      headerName: translate("types.productInstance.productCode") as string,
      field: "productCode",
      cellRenderer: "ProductInstanceLink",
      cellStyle: stateStyle,
      width: 120,
      sortable: false,
    },

    // {
    //   headerName: translate("types.grid.sub") as string,
    //   field: "sub", // missing from GraphQL.
    //   cellRenderer: "SubIndicator",
    //   cellStyle: stateStyle,
    //   width: 200
    // }
    {
      headerName: translate("types.common.serviceContract") as string,
      field: "acceptedContractDate",
      cellStyle: stateStyle,
      cellRenderer: "FormatDate",
      width: 140,
      sortable: false,
    },

    {
      headerName: translate("types.common.status") as string,
      field: "acceptedContractDate",
      cellStyle: { position: "relative", top: "-4px" },
      cellRenderer: "FormatStatus",
      width: 100,
      sortable: false,
    },
  ];
  return columnDefs;
};

interface CellRenderer {
  value: string;
  data: customerProductInstances_customers_results_registeredProducts;
}

const ProductInstanceLink: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  return <Link to={"/cs/device/" + data.productCode}>{value}</Link>;
};

export const FirmwareIndicator: React.FC<CellRenderer> = ({ data, value }) => {
  if (!(data && data.instanceInformation)) {
    return <div />;
  }

  if (
    data.instanceInformation?.__typename === "PerlaInstanceInformation" &&
    data.product?.productInformation?.recentFirmwareVersion
  ) {
    const isOutOfDate =
      value !== "0" &&
      data.instanceInformation?.registeredPerlaInstanceInformation?.firmware &&
      data.instanceInformation.registeredPerlaInstanceInformation.firmware <
        data.product?.productInformation?.recentFirmwareVersion;

    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{value}</div>
        {isOutOfDate && <SyncIcon style={{ color: bwtColors.grey }} />}
      </div>
    );
  }

  return <div>{value}</div>;
};

const SubIndicator: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  return !!value ? <CheckIcon style={{ color: bwtColors.blue }} /> : null;
};

const FormatDate: React.FC<CellRenderer> = ({ data, value }) => {
  if (!(data && value)) {
    return <div />;
  }
  return (
    <Translate>
      {(translate) => {
        return (
          <div>
            {format(
              new Date(value),
              translate.translate("generics.dateTimeFormatFNS") as string
            )}
          </div>
        );
      }}
    </Translate>
  );
};

const FormatStatus: React.FC<CellRenderer> = ({ data, value }) => {
  if (!(data && value)) {
    return (
      <MenuItem value="false">
        <Clear color="error" />
      </MenuItem>
    );
  }

  const date = new Date();
  const diff: number = Math.abs(date.getTime() - new Date(value)?.getTime());
  const diffDays: number = Math.ceil(diff / (1000 * 3600 * 24));
  if (diffDays > 730.485) {
    return (
      <Tooltip title="Your service contract has expired">
        <MenuItem>
          <WarningIcon />
        </MenuItem>
      </Tooltip>
    );
  }

  return (
    <MenuItem value="true">
      <Done color="primary" />
    </MenuItem>
  );
};

const StateCell: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data || data.state === "NOT_SET") {
    return <div />;
  }
  return <div>{value}</div>;
};

export const CellRenderer = {
  ProductInstanceLink,
  FirmwareIndicator,
  SubIndicator,
  StateCell,
  FormatDate,
  FormatStatus,
};
