import gql from "graphql-tag";

export const POLL_GENERATE_CODES = gql`
query pollGenerateCodes($batchNumber: String!){
  pollGenerateProductCodesJob(batchNumber: $batchNumber){
    isFinished
    result{
      erpBatchNumber
      batchNumber
      code
      batchDeactivated
    }
    total
  }
}
`