import { ActionType, SearchAction } from "redux/types/PoolOverview";
import generateGridReducer from "redux/reducers/AgGridReducerHelper";
import { combineReducers } from "redux";

const searchReducer = (
  state: string | null = null,
  action: SearchAction
): string | null => {
  switch (action.type) {
    case ActionType.SEARCH:
      return action.payload;

    default:
      return state;
  }
};

export default combineReducers({
  grid: generateGridReducer<ActionType>(ActionType.GRID),
  search: searchReducer,
});
