import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { Translate } from "react-localize-redux";
import { DateAggregationType } from "../../../types/apolloGenerated/globalTypes";
import { eachDayOfInterval, eachWeekOfInterval, startOfDay, subMonths, startOfMonth, format, subDays, subWeeks, startOfWeek, addWeeks, } from "date-fns";

import {
  productInstanceAndCustomerDetails_productInstance_instanceInformation_SilkInstanceInformation as SilkInstanceInformation,
  productInstanceAndCustomerDetails_productInstance_instanceInformation_SilkInstanceInformation_notifications as SilkNotif,
} from "../../../types/apolloGenerated/productInstanceAndCustomerDetails";

import { Box } from "@material-ui/core";

import CurveGraph from "./CurveGraph";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    header: {
      fontSize: "20px",
      fontWeight: "bold",
      height: "75px",
    },
    monthPicker: {
      position: "relative",
      left: "500px",
      top: "-22px",
    },
    selectRoot: {
      padding: "10px 40px 10px 10px",
    },
    legend: {
      width: "3%",
      height: "8px",
      borderRadius: "20px",
      margin: "40px 20px 5px 20px",
    },
  });
});

export const groupByAggregate = (
  aggregate: DateAggregationType,
  notifs: (SilkNotif | null)[],
  length: number,
  endDate: Date
) => {
  const finishedDates = notifs
    .map((notif) => {
      const messageData = JSON.parse(notif?.message ?? "{}");
      return {
        state: messageData?.Data?.state,
        date: new Date(messageData?.Timestamp),
      };
    })
    .filter((notif) => notif.state === 1);

  const buckets: Record<number, number> = {};
  for (let i = 0; i < length; i++) {
    let milli = 0;
    if (aggregate === DateAggregationType.DAY) {
      milli = startOfDay(subDays(endDate, i)).getTime();
    }
    if (aggregate === DateAggregationType.WEEK) {
      milli = startOfWeek(subWeeks(endDate, i), { weekStartsOn: 1 }).getTime();
    }
    if (aggregate === DateAggregationType.MONTH) {
      milli = startOfMonth(subMonths(endDate, i)).getTime();
    }
    buckets[milli] = 0;
  }

  for (const { date } of finishedDates) {
    let milli = 0;
    if (aggregate === DateAggregationType.DAY) {
      milli = startOfDay(date).getTime();
    }
    if (aggregate === DateAggregationType.WEEK) {
      milli = startOfWeek(date, { weekStartsOn: 1 }).getTime();
    }
    if (aggregate === DateAggregationType.MONTH) {
      milli = startOfMonth(date).getTime();
    }
    if (milli in buckets) {
      buckets[milli]++;
    }
  }

  const output = Object.values(buckets);
  return output.reverse();
};

type line = {
  paddedPoints: number[];
  colour: string;
};
export interface RegenerationProps {
  productCode: string;
  silkData: SilkInstanceInformation | null;
}

const now = new Date();

const SilkRegeneration: React.FC<RegenerationProps> = ({
  productCode,
  silkData,
}) => {
  const classes = useStyles();
  const monthsInHalfYear = 6;
  const monthsInYear = 12;
  const daysInMonth = 30;
  const weeksInThreeMonths = 13;

  const [monthsSelected, setMonth] = React.useState(
    monthsInHalfYear.toString()
  );
  const [first, setFirst] = React.useState(
    startOfMonth(subMonths(now, monthsInHalfYear))
  );
  const [points, setPoints] = React.useState(monthsInHalfYear);
  const [aggregation, setAggregation] = React.useState(
    DateAggregationType.MONTH
  );
  const [loadNewDate, setLoadNewDate] = React.useState(false);

  // each of the dates below will fetch one additional point because in order to get the curve affect we need one point that is off the SVG
  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setLoadNewDate(true);
    setMonth((event.target as HTMLTextAreaElement).value);
    switch ((event.target as HTMLTextAreaElement).value) {
      case "1":
        setAggregation(DateAggregationType.DAY);
        setPoints(daysInMonth);
        setFirst(startOfDay(subDays(now, daysInMonth - 1)));
        break;
      case "3":
        setAggregation(DateAggregationType.WEEK);
        setPoints(weeksInThreeMonths);
        setFirst(
          startOfWeek(subWeeks(now, weeksInThreeMonths), { weekStartsOn: 1 })
        );
        break;
      case "6":
        setAggregation(DateAggregationType.MONTH);
        setPoints(monthsInHalfYear);
        setFirst(startOfMonth(subMonths(now, monthsInHalfYear)));
        break;
      case "12":
        setAggregation(DateAggregationType.MONTH);
        setPoints(monthsInYear);
        setFirst(startOfMonth(subMonths(now, monthsInYear)));
        break;
    }
  };

  //get all silk regen notifications
  const summaryType = 6012;
  let silkRegenerationNotifs: (SilkNotif | null)[] = [];
  if (silkData && silkData?.notifications) {
    silkRegenerationNotifs = silkData?.notifications.filter(
      (notif) => notif?.messageType === summaryType
    );
  }

  const byAggregate = groupByAggregate(
    aggregation,
    silkRegenerationNotifs,
    points,
    new Date()
  );

  let xAxisValues: any[] = [];
  let mockLines: line[] = [];

  if (aggregation === DateAggregationType.DAY) {
    //add a day to the current first so we dont get 31 dates but rather 30 to display on the x axis
    const dateArray = eachDayOfInterval({
      start: new Date(first),
      end: new Date(now),
    });
    for (let i = 0; i < dateArray.length; i++) {
      xAxisValues.push(format(dateArray[i], "d"));
    }
  } else {
    if (aggregation === DateAggregationType.WEEK) {
      //add an extra week so we don't get one too many because the svg requires one extra point
      const dateArray = eachWeekOfInterval(
        { start: new Date(addWeeks(first, 1)), end: new Date(now) },
        { weekStartsOn: 1 }
      );
      for (let i = 0; i < dateArray.length; i++) {
        xAxisValues.push(format(dateArray[i], "dd/MM"));
      }
    } else {
      for (let i = points - 1; i > -1; i--) {
        xAxisValues.push(format(subMonths(now, i), "MMM"));
      }
    }
  }

  mockLines = [
    {
      paddedPoints: [0, ...byAggregate, 0],
      colour: "rgb(99, 134, 163)",
    },
  ];

  let total = 0;
  mockLines.forEach((line) => {
    line.paddedPoints.forEach((point) => {
      total += point;
    });
  });

  if (total === 0 && !loadNewDate)
    return (
      <div>
        <Translate id="message.error.NoRegenerationData" />
      </div>
    );

  return (
    <Box>
      <Box className={classes.header}>
        <Translate id="components.regeneration.title" />

        <Box style={{ float: "right", fontWeight: "normal" }}>
          <FormControl variant="outlined">
            <Select
              classes={{ root: classes.selectRoot }}
              value={monthsSelected}
              onChange={(event) => handleChange(event)}
            >
              <MenuItem value={"1"}>
                1 <Translate id="components.barChart.month" />
              </MenuItem>
              <MenuItem value={"3"}>
                3 <Translate id="components.barChart.months" />
              </MenuItem>
              <MenuItem value={"6"}>
                6 <Translate id="components.barChart.months" />
              </MenuItem>
              <MenuItem value={"12"}>
                12 <Translate id="components.barChart.months" />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      {
        <CurveGraph
          linesIn={mockLines}
          samplePoints={points}
          xAxisValues={xAxisValues}
        />
      }
      <Box style={{ display: "flex" }}>
        <Box
          style={{ backgroundColor: "rgb(99, 134, 163)" }}
          className={classes.legend}
        ></Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <div># Regenerations</div>
        </Box>
      </Box>
    </Box>
  );
};

export default SilkRegeneration;
