import React, { Fragment } from "react";
import { useParams } from "react-router";

import CircularProgress from '@material-ui/core/CircularProgress';

import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { useQuery, useMutation } from "@apollo/react-hooks";

import { Translate } from "react-localize-redux";

import cloneDeep from "clone-deep";

import { open as removeFromCompany } from 'redux/actions/AccountShortcuts/RemoveFromCompanyActions';

import { Position } from 'types/apolloGenerated/globalTypes';
import { OrganizationOfEmployee, OrganizationOfEmployeeVariables, OrganizationOfEmployee_employee_organization, OrganizationOfEmployee_employee_organization_address } from "types/apolloGenerated/OrganizationOfEmployee";
import { AddEmployee, AddEmployeeVariables } from 'types/apolloGenerated/AddEmployee';
import { organizationsGrid_organizations_results } from "types/apolloGenerated/organizationsGrid";

import AddPartner from 'Pages/PartnerManager/Employees/Detail/AddPartner';

import { QUERY_PARTNER_EMPLOYEES, MUTATION_PARTNER_ADD_EMPLOYEE } from 'commonQueries/partner';
import { QUERY_EMPLOYEE_ORGANIZATION, QUERY_EMPLOYEE_DETAIL } from 'commonQueries/employee';
import { PartnerEmployees } from "types/apolloGenerated/PartnerEmployees";
import { EmployeeDetail, EmployeeDetailVariables } from "types/apolloGenerated/EmployeeDetail";
import { makeStyles, createStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() =>
  createStyles({
    greyColor: {
      color: "#666B6E"
    },
    bwtBtn: {
      backgroundColor: '#005D8F',
      color: '#fff',
      fontSize: '12px'
    }
  })
);

const OrganizationWrap: React.FC = () => (
  <div>
    <h2 style={{ fontSize: "25px", fontWeight: 400, color: "#666B6E" }}><Translate id="components.employeeAssignToPartner.title" /></h2>
    <Organization />
  </div>
)

type OrganizationProps = {
  removeFromCompany: typeof removeFromCompany
}

const Organization = connect(
  null,
  (dispatch: Dispatch) => bindActionCreators({ removeFromCompany }, dispatch)
)(({ removeFromCompany }: OrganizationProps) => {
  const { id } = useParams();
  const idNUm = Number(id);

  const { data, loading, error } = useQuery<OrganizationOfEmployee, OrganizationOfEmployeeVariables>(QUERY_EMPLOYEE_ORGANIZATION, {
    variables: { id: idNUm }
  });

  const employee = data?.employee;
  const organization = employee?.organization;

  const classes = useStyles();

  const [addEmployee, { loading: addEmployeeLoading }] = useMutation<AddEmployee, AddEmployeeVariables>(MUTATION_PARTNER_ADD_EMPLOYEE);

  const addEmployeeToPartner = (organization: organizationsGrid_organizations_results, position: Position) => {

    if (employee) {
      addEmployee({
        variables: {
          userId: idNUm,
          partnerId: organization.id,
          position: position
        },
        update: (cache) => {
          try {
            const variables = { id: idNUm };

            const queryCache = cloneDeep(cache.readQuery<OrganizationOfEmployee>({
              query: QUERY_EMPLOYEE_ORGANIZATION,
              variables: variables,
            }));

            if (queryCache?.employee) {
              queryCache.employee.organization = organization;

              cache.writeQuery({
                query: QUERY_EMPLOYEE_ORGANIZATION,
                variables: variables,
                data: queryCache
              });
            } else {
              console.warn('Trying to update CACHE (Set Partner to Employee), but emplyee not found');
            }
          } catch (e) { console.warn(e); }


          try {
            const variablesPartnerEmployees = { id: organization.id };

            const queryCachePartnerEmployees = cloneDeep(cache.readQuery<PartnerEmployees>({
              query: QUERY_PARTNER_EMPLOYEES,
              variables: variablesPartnerEmployees,
            }));

            if (queryCachePartnerEmployees?.organization?.employees?.results) {

              const queryCacheEmployee = cloneDeep(cache.readQuery<EmployeeDetail, EmployeeDetailVariables>({
                query: QUERY_EMPLOYEE_DETAIL,
                variables: { id: idNUm },
              }));

              if (queryCacheEmployee?.employee?.userInformation) {
                queryCachePartnerEmployees.organization.employees.results.push({
                  __typename: 'Employee',
                  position: position,
                  userInformation: queryCacheEmployee?.employee?.userInformation
                });

                console.log('Write Employees to cache: ', queryCachePartnerEmployees);
                cache.writeQuery({
                  query: QUERY_PARTNER_EMPLOYEES,
                  variables: variablesPartnerEmployees,
                  data: queryCachePartnerEmployees
                });
              } else {
                console.warn('Trying to update CACHE, but emplyee not found');
              }

            } else {
              console.warn('Trying to update CACHE, but emplyees of partner not found');
            }
          } catch (e) { console.warn(e); }
        }
      });
    }
  };

  if (loading || addEmployeeLoading) return <CircularProgress color="secondary" />
  if (error) return <div><Translate id="components.employeeAssignToPartner.errorLoadingOrganization" /></div>

  if (organization) {
    return (
      <div className={classes.greyColor}>
        <Fragment>
          <CurrentOrganization organization={organization} />
        </Fragment>
      </div>
    );
  } else {
    return (
      <div className={classes.greyColor}>
        <Translate id="components.employeeAssignToPartner.onOrganization" />
      </div>
    );
  }
});

interface CurrentOrganizationProps {
  organization: OrganizationOfEmployee_employee_organization
}

const CurrentOrganization: React.FC<CurrentOrganizationProps> = ({ organization }) => {

  const address = organization.address ? <Address address={organization.address} /> : null;

  return (
    <div><Link style={{ textDecoration: 'none', color: '#005D8F' }} to={"/pam/partner/" + organization.id}><strong>{organization.name}</strong></Link> (<Translate id={"types.organization.partnerType." + organization.partnerType?.toLowerCase()} />)<br />
      {address}
      <br />
    </div>

  )
};

interface AddressProps {
  address: OrganizationOfEmployee_employee_organization_address
}

const Address: React.FC<AddressProps> = ({ address }) => {
  return (
    <div>
      {address.street}<br />
      {address.postalCode} {address.city}<br></br>
      <Translate id={"countries." + address.countryCode?.toLowerCase()} />
    </div>
  )
}

export default OrganizationWrap