import React, { HTMLAttributes, useEffect } from "react";
import clsx from "clsx";
import Select from "react-select";
import {
  createStyles,
  emphasize,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import TextField, { BaseTextFieldProps } from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { ValueContainerProps } from "react-select/src/components/containers";
import { ControlProps } from "react-select/src/components/Control";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { OptionProps } from "react-select/src/components/Option";
import { ValueType } from "react-select/src/types";

import {
  Translate,
  withLocalize,
  TranslateFunction,
  LocalizeContextProps,
} from "react-localize-redux";

import { Error } from "types/CampGuideTypes";
import { FormControl, FormHelperText } from "@material-ui/core";
import { TranslateError } from "./Error";

interface OptionType {
  label: string;
  value: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 100,
      minWidth: 290,
      width: "100%",
      "&$error": {
        color: "white",
      },
    },
    error: {},
    input: {
      display: "flex",
      padding: 0,
      height: "auto",
    },
    valueContainer: {
      display: "flex",
      flexWrap: "wrap",
      flex: 1,
      alignItems: "center",
      overflow: "hidden",
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      ),
    },
  })
);

type InputComponentProps = Pick<BaseTextFieldProps, "inputRef"> &
  HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props: OptionProps<OptionType>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props: MultiValueProps<OptionType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

const components = {
  Control,
  MultiValue,
  Option,
  ValueContainer,
};

const mapStringArrToOptTypeArr = (
  countryCodes: string[],
  translate: TranslateFunction,
  trConfig: TranslateConfig | undefined
): OptionType[] => {
  return countryCodes.map((countryCode: string) => {
    return {
      value: countryCode,
      label: trConfig
        ? (translate(trConfig.prefix + countryCode) as string)
        : countryCode,
    };
  });
};

const mapOptTypeToStringArr = (value: ValueType<OptionType>): string[] => {
  if (value) {
    if (Array.isArray(value)) {
      const StrArr = value.map((item: OptionType) => {
        return item.value;
      });
      return StrArr;
    }
  }
  return [];
};

interface TranslateConfig {
  prefix: string | undefined;
}

interface MultiSelectInterface extends LocalizeContextProps {
  headline: string;
  selectable: string[];
  selected: string[];
  onChange: (selected: string[]) => void;
  disabled?: boolean;
  error?: Error | null;
  translate: TranslateFunction;
  translateConfig?: TranslateConfig;
}

const MultiSelect: React.FC<MultiSelectInterface> = (
  {
    headline,
    selectable,
    selected,
    onChange,
    error,
    disabled = false,
    translate,
    translateConfig,
  },
  props
) => {
  const classes = useStyles();

  useEffect(() => {
    onChange(selected);
  }, [onChange, selected]);

  const selectableOpt: OptionType[] = mapStringArrToOptTypeArr(
    selectable,
    translate,
    translateConfig
  );
  selectableOpt.sort((a, b) => (a.label > b.label ? 1 : -1));

  var enabledselectableLowercase = [];
  for (var i = 0; i < selected.length; i++) {
    if (selected[i].length === 2) {
      selected[i] = selected[i].toLowerCase();
    }
    enabledselectableLowercase.push(selected[i]);
  }

  enabledselectableLowercase.sort((a, b) => (a > b ? 1 : -1));
  const selectedOpt: OptionType[] = mapStringArrToOptTypeArr(
    enabledselectableLowercase,
    translate,
    translateConfig
  );
  const setSelectedVals = (
    enabledselectableLowercase: ValueType<OptionType>
  ) => {
    //console.log("setSelectedVals: ", setSelectedVals);
    onChange(mapOptTypeToStringArr(enabledselectableLowercase));
  };

  return (
    <FormControl classes={{ root: classes.root }} error={!!error}>
      <label>
        <Translate id={headline} />
        <Select
          isDisabled={disabled}
          classes={classes}
          isClearable={true}
          inputId="react-select-multiple"
          placeholder={
            <Translate id="components.multiSelect.selectselectableTxt" />
          }
          options={selectableOpt}
          components={components}
          value={selectedOpt}
          onChange={setSelectedVals}
          isMulti
        />
        {error ? (
          <FormHelperText>
            <TranslateError error={error} />
          </FormHelperText>
        ) : null}
      </label>
    </FormControl>
  );
};

export default withLocalize(MultiSelect);
