import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import gql from "graphql-tag";
import { useMutation } from '@apollo/react-hooks';
import { MutationUpdaterFn } from 'apollo-client';


import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Box } from '@material-ui/core';

import { AppState } from 'redux/store';

import { close } from 'redux/actions/AccountShortcuts/ChangeActiveActions';

import { Translate } from "react-localize-redux";

import Error from 'components/common/Error';


import { closeAccountVariables, closeAccount } from 'types/apolloGenerated/closeAccount';
import { reopenAccountVariables, reopenAccount } from 'types/apolloGenerated/reopenAccount';
import { EmployeeDetail } from "types/apolloGenerated/EmployeeDetail";
import { customerInstance } from "types/apolloGenerated/customerInstance";

import { QUERY_EMPLOYEE_DETAIL } from "commonQueries/employee";
import { QUERY_CUSTOMER_DETAIL } from "commonQueries/customer";


import { UserInformation } from 'types/ToolboxEntities';

import cloneDeep from 'clone-deep';


const CLOSE_ACCOUNT = gql`
mutation closeAccount($userId: ID!){
	user{
    closeAccount(userId: $userId)
  }
}
`;

const REOPEN_ACCOUNT = gql`
mutation reopenAccount($userId: ID!){
	user{
    reopenAccount(userId: $userId)
  }
}
`;



const updateCache = (userId: string | undefined, userKey: number | undefined, isAccountLocked: boolean) => {
  const update: MutationUpdaterFn = (cache) => {
    try {
      /*
       * Update Cache of Employee Detail Page
       */
      const employeeVariables = { id: userKey };
      const employeeCache = cloneDeep(cache.readQuery<EmployeeDetail>({
        query: QUERY_EMPLOYEE_DETAIL,
        variables: employeeVariables,
      }));
      if (employeeCache?.employee?.userInformation) {
        employeeCache.employee.userInformation.accountLocked = isAccountLocked;
        cache.writeQuery({
          query: QUERY_EMPLOYEE_DETAIL,
          variables: employeeVariables,
          data: employeeCache
        });
      }
    } catch (e) { }

    try {
      /*
       * Update Cache of Customer Detail Page
       */
      const customerVariables = { id: userId };
      const customerCache = cloneDeep(cache.readQuery<customerInstance>({
        query: QUERY_CUSTOMER_DETAIL,
        variables: customerVariables,
      }));
      if (customerCache?.customers?.results?.[0]?.userInformation) {
        customerCache.customers.results[0].userInformation.accountLocked = isAccountLocked;
        cache.writeQuery({
          query: QUERY_CUSTOMER_DETAIL,
          variables: customerVariables,
          data: customerCache
        });
      }
      /* */
    } catch (e) { }
  };
  return update;
};




interface ChangeActiveProps {
  user: UserInformation | null
  actions: {
    close: typeof close
  }
}


const ChangeActive: React.FC<ChangeActiveProps> = ({ user, actions }) => {

  const [doCloseAccount, { loading: closeAccountLoading, error: closeError }] = useMutation<closeAccount, closeAccountVariables>(CLOSE_ACCOUNT, {
    onCompleted: () => actions.close(),
    onError: (error) => console.error(error),
    update: updateCache(user?.id, user?.key, true)
  });

  const [doReopenAccount, { loading: reopenAccountLoading, error: reopenError }] = useMutation<reopenAccount, reopenAccountVariables>(REOPEN_ACCOUNT, {
    onCompleted: () => actions.close(),
    onError: (error) => console.error(error),
    update: updateCache(user?.id, user?.key, false)
  });

  if (user === null) {
    return null;
  }

  const toggleAccount = () => {
    if (user?.accountLocked) {
      doReopenAccount({ variables: { userId: user.id } });
    } else {
      doCloseAccount({ variables: { userId: user.id } });
    }
  };

  const langKeyEnableDisable = user?.accountLocked ? 'components.changeActive.enable' : 'components.changeActive.disable';

  const progress = reopenAccountLoading || closeAccountLoading;

  const error = closeError || reopenError;
  const errorElement = error ? <Error error={{ code: error?.graphQLErrors?.[0].extensions?.code, message: error.message }} /> : null;

  const OkButtonOrProgress = progress ? (
    <Box width="65px"><CircularProgress color="primary" /></Box>
  ) : (
      <Button onClick={toggleAccount} color="primary">
        <Translate id="generics.ok" />
      </Button>
    );

  return (
    <Dialog open={true} onClose={actions.close} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="form-dialog-title"><Translate id="components.changeActive.title" /></DialogTitle>
      <DialogContent>
        <DialogContentText><Translate id={langKeyEnableDisable} /></DialogContentText>
        {errorElement}
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.close} color="primary" disabled={progress}>
          <Translate id="generics.cancel" />
        </Button>
        {OkButtonOrProgress}
      </DialogActions>
    </Dialog>
  );
}





const mapStateToProps = (state: AppState) => ({
  user: state.accountShortcuts.changeActive.user
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ close }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeActive);
