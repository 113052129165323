import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { localizeReducer } from "react-localize-redux";

import initialState from "./initialState";

import appShellReducer from "./reducers/appShellReducer";
import authReducer from "./reducers/authReducer";
import changeLoginReducer from "./reducers/AccountShortcuts/changeLoginReducer";
import changePearlsReducer from "./reducers/AccountShortcuts/changePearlsReducer";
import changeActiveReducer from "./reducers/AccountShortcuts/changeActiveReducer";
import sendResetReducer from "./reducers/AccountShortcuts/sendResetReducer";
import deleteAccountReducer from "./reducers/AccountShortcuts/deleteAccountReducer";

import requestLogReducer from "./reducers/DeviceActions/requestLogReducer";
import resetActivationReducer from "./reducers/DeviceActions/resetActivationReducer";
import setOutOfServiceReducer from "./reducers/DeviceActions/setOutOfServiceReducer";
import resetInstallerReducer from "./reducers/DeviceActions/resetInstallerReducer";

import productOverviewReducer from "./reducers/productOverviewReducer";
import partnerOverviewReducer from "./reducers/partnerOverviewReducer";
import deviceOverviewReducer from "./reducers/deviceOverviewReducer";
import poolOverviewReducer from "./reducers/poolOverviewReducer";
import customerOverviewReducer from "./reducers/customerOverviewReducer";
import partnerDetailsReducer from "./reducers/partnerDetailsReducer";
import promotionOverviewReducer from "./reducers/promotionOverviewReducer";
import promotionDetailsReducer from "./reducers/promotionDetailsReducer";
import couponCodeReducer from "./reducers/couponCodeReducer";
import multiSelectReducer from "./reducers/multiSelectReducer";
import inputToChipReducer from "./reducers/inputToChipReducer";
import linkedUsersReducer from "./reducers/linkedUsersReducer";
import bonusAccountReducer from "./reducers/bonusAccountReducer";
import productRegistrationsReducer from "./reducers/productRegistrationsReducer";
import changeOrganizationActiveStateReducer from "./reducers/changeOrganizationActiveStateReducer";
import changePositionReducer from "./reducers/AccountShortcuts/changePositionReducer";
import removeFromCompanyReducer from "./reducers/AccountShortcuts/removeFromCompanyReducer";
import pearlsGridReducer from "./reducers/pearlsGridReducer";
import consumableGridReducer from "./reducers/consumableGridReducer";
import employeesOverviewReducer from "./reducers/employeesOverviewReducer";
import registrationGridReducer from "./reducers/registrationGridReducer";
import createRequestOverviewReducer from "./reducers/createRequestOverviewReducer";
import joinRequestOverviewReducer from "./reducers/joinRequestOverviewReducer";

import sendgridTemplatesOverviewReducer from "./reducers/sendgridTemplatesOverviewReducer";
import sendgridTemplatesDeleteReducer from "./reducers/sendgridTemplatesDeleteReducer";
import sendgridTemplatesCreateReducer from "./reducers/sendgridTemplatesCreateReducer";

// Be sure to ONLY add "redux-immutable-state-invariant" middleware in development!
const middleware =
  process.env.NODE_ENV !== "production"
    ? require("redux-devtools-extension").composeWithDevTools(
        applyMiddleware(
          require("redux-immutable-state-invariant").default(),
          thunk
        )
      )
    : applyMiddleware(thunk);

console.log('process.env.NODE_ENV: "' + process.env.NODE_ENV + '"');

const rootReducer = combineReducers({
  localize: localizeReducer,
  auth: authReducer,
  appShell: appShellReducer,

  /* Product */
  productOverview: productOverviewReducer,

  /* Partner */
  partnerOverview: partnerOverviewReducer,
  partnerDetails: partnerDetailsReducer,
  linkedUsers: linkedUsersReducer,
  bonusAccount: bonusAccountReducer,
  productRegistrations: productRegistrationsReducer,
  changeOrganizationActiveState: changeOrganizationActiveStateReducer,
  pearlsGrid: pearlsGridReducer,
  employeesOverview: employeesOverviewReducer,

  /* Requests */
  joinRequestOverview: joinRequestOverviewReducer,
  createRequestOverview: createRequestOverviewReducer,

  /* Promotion */
  promotionOverview: promotionOverviewReducer,
  promotionDetails: promotionDetailsReducer,
  couponCode: couponCodeReducer,

  /* Admin */
  multiSelect: multiSelectReducer,
  inputToChip: inputToChipReducer,

  /* Customer */
  deviceOverview: deviceOverviewReducer,
  customerOverview: customerOverviewReducer,
  poolOverview: poolOverviewReducer,

  /*Sendgrid Templates*/
  sendgridTemplatesOverview: sendgridTemplatesOverviewReducer,
  sendgridTemplatesDelete: sendgridTemplatesDeleteReducer,
  sendgridTemplatesCreate: sendgridTemplatesCreateReducer,
  /* Consumable */
  consumableGrid: consumableGridReducer,

  /* Production */
  registrationGrid: registrationGridReducer,

  /* Global Acount-Shortcuts */
  accountShortcuts: combineReducers({
    changeLogin: changeLoginReducer,
    changePearls: changePearlsReducer,
    changeActive: changeActiveReducer,
    sendReset: sendResetReducer,
    changePosition: changePositionReducer,
    deleteAccount: deleteAccountReducer,
    removeFromCompany: removeFromCompanyReducer,
  }),

  deviceActions: combineReducers({
    requestLog: requestLogReducer,
    resetActivation: resetActivationReducer,
    resetInstaller: resetInstallerReducer,
    setOutOfService: setOutOfServiceReducer,
  }),
});

export type AppState = ReturnType<typeof rootReducer>;

export default createStore(rootReducer, initialState, middleware);
