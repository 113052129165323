import { GridActionType, Action, State } from 'redux/types/AgGrid';

const defaultInitialState: State = {
  sortModel: [],
  filterModel: {},
  page: 0,
  columnStates: undefined,
};



export default <Type>(actionType: Type, initialState: State | undefined = undefined) => {
  return (state: State = initialState || defaultInitialState, action: Action<Type>): State => {
    if (action.type === actionType) {
      switch (action.grid) {

        case GridActionType.GRID:
          return {
            ...state,
            ...action.payload,
          };

        case GridActionType.COLUMN:
          return {
            ...state,
            columnStates: action.payload,
          };

      }
    }

    return state;
  };
};

