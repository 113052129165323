export enum ErrorCodes {
  ConnectionError = 'net_connection',
  Authorization = 'gql_authorization',
  InvalideResponse = 'gql_invalide_response',
  Unknown = 'gql_unknown',
  API = 'api',
  gql_invalid_linkUrl = 'gql_invalid_linkUrl',
  gql_password_policy = 'gql_password_policy',
  gql_property_required = 'gql_property_required'
};

export interface Error {
  code: ErrorCodes,
  message: string
}
