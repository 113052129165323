const CampUtils = {
  getEnumAsArray: (enumType: object): string[] => {
    const retArray = [];
    //console.log('getEnumAsArray: ', enumType);

    for (var n in enumType) {
      //if (typeof enumType[n] === 'number') {
      retArray.push(n);
      //}
    }

    return retArray;
  },
};
export default CampUtils;


interface CancelablePromise<T> extends Promise<T> {
  doCancel: () => void
  then: <TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null | undefined, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined) => CancelablePromise<TResult1 | TResult2>
  catch: <TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null) => CancelablePromise<T | TResult>
  finally: (onfinally?: (() => void) | undefined | null) => CancelablePromise<T>
}

// https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
// export const makeCancelable = <T>(promise: Promise<T>): CancelablePromise<T> => {
//   let hasCanceled_ = false;

//   const wrappedPromise: any = new Promise<T>((resolve, reject) => {
//     promise.then(
//       val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
//       error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
//     );
//   });

//   wrappedPromise.cancel = () => {
//     hasCanceled_ = true;
//     return wrappedPromise;
//   }

//   return wrappedPromise;
// };



export const cancelablePromise = <T>([execotor]: ConstructorParameters<typeof Promise>): CancelablePromise<T> => {
  let hasCanceled_ = false;

  const innerPromise = new Promise<T>((resolve, reject) => {
    execotor(
      (val) => { !hasCanceled_ && resolve(val as T); },
      (error: any) => { !hasCanceled_ && reject(error) }
    );
  });


  const cancelablePromise = {
    doCancel: () => { hasCanceled_ = true; },
    then: (func: any) => { innerPromise.then(func); return cancelablePromise; },
    catch: (func: any) => { innerPromise.catch(func); return cancelablePromise; },
    finally: (func: any) => { innerPromise.finally(func); return cancelablePromise; },
    [Symbol.toStringTag]: Object.prototype.toString.call(innerPromise)
  }

  // console.log('cancelablePromise', cancelablePromise);

  return cancelablePromise;
};




type sparseArray<T> = (T | null | undefined)[]

export const filterSparseArray = function <T>(toFilter: sparseArray<T> | null | undefined, strict: boolean = false): T[] {
  if (!toFilter) {
    return [];
  }
  if (strict) {
    return toFilter.filter((item) => {
      return item !== null && (typeof item !== 'undefined');
    }) as T[];
  }

  return toFilter.filter((item) => {
    return item;
  }) as T[];
};



export const mapAndFilterSparseArray = function <T, U>(toFilter: sparseArray<T> | null | undefined, map: ((from: T | null | undefined) => U | false)): U[] {
  const ret: U[] = [];

  if (toFilter) {
    toFilter.forEach((item) => {
      const toItem = map(item);
      if (toItem !== false) {
        ret.push(toItem);
      }
    });
  }

  return ret;
};



export const getLatLngFromAddress = (address: string | null | undefined) => {
  return new Promise<google.maps.LatLngLiteral>((resolve, reject) => {
    if (address) {
      if (window.google) {
        let geocoder = new google.maps.Geocoder()
        let latLng: google.maps.LatLngLiteral | undefined = undefined;

        geocoder.geocode({ 'address': address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            latLng = { lat: 0, lng: 0 };
            latLng.lat = Number(results[0].geometry.location.lat().toFixed(4));
            latLng.lng = Number(results[0].geometry.location.lng().toFixed(4));
            resolve(latLng);
          } else {
            reject('locationNotFound');
          }
        });
      } else {
        reject('missingGeocoder');
      }
    } else {
      reject('locationNotFound');
      return;
    }
  });
};

