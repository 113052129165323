import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useParams } from "react-router";

import { withLocalize, LocalizeContextProps } from "react-localize-redux";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import ShowAccountInfo from "components/common/ShowAccountInfo";

import EditCustomerDetails from "./EditCustomerDetails";
import RegisteredDevices from "./RegisteredDevices";
import ServiceContract from "./ServiceContract";
import RegisteredPools from "./RegisteredPools";
import PageHeader from "components/common/PageHeader";

import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { UserInformation } from "types/ToolboxEntities";

import {
  updateCustomerDetails as MutationData,
  updateCustomerDetailsVariables as MutationVars,
} from "types/apolloGenerated/updateCustomerDetails";

import { QUERY_CUSTOMER_DETAIL } from "commonQueries/customer";
import { GET_USER_COUNTRIES_QUERY } from "commonQueries/userCountryQuery";

import { UserInformationUpdateInput } from "types/apolloGenerated/globalTypes";
import {
  customerInstance,
  customerInstance_customers_results,
} from "types/apolloGenerated/customerInstance";

import { open as changeLoginOpen } from "redux/actions/AccountShortcuts/ChangeLoginActions";
import { open as changeActiveOpen } from "redux/actions/AccountShortcuts/ChangeActiveActions";
import { open as sendResetOpen } from "redux/actions/AccountShortcuts/SendResetActions";
import { open as deleteAccountOpen } from "redux/actions/AccountShortcuts/DeleteAccountActions";

import PageDialog from "components/common/PageDialog";
import PageHeaderMessage from "components/common/PageHeaderMessage";
import AuditLog from "../InstanceDetails/AuditLog";
import USER_ROLES from "../../../commonQueries/userRole";
import store from "../../../redux/store";
import * as Types from "../../../types/ToolboxEntities";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    infoSeg: {
      color: "#000000",
      paddingLeft: "30px",
      //margin: "20px 0",
      marginTop: "100px",
    },
  });
});

const MUTATION = gql`
  mutation updateCustomerDetails(
    $userInformation: UserInformationUpdateInput!
  ) {
    user {
      updateInformation(userInformation: $userInformation) {
        acceptedTerms
        accountLocked
        address {
          city
          countryCode
          postalCode
          street
        }
        allowedToLogin
        dataUpdate
        lastLogin
        email
        failedPasswordResetCount
        firstName
        id
        incorrectLogins
        key
        lastName
        registeredOn
        telephone
        verifiedAccount
        acceptedMarketingUsage
        isTestUser
      }
    }
  }
`;

interface CustomerDetailsProps extends LocalizeContextProps {
  actions: {
    changeLoginOpen: typeof changeLoginOpen;
    changeActiveOpen: typeof changeActiveOpen;
    sendResetOpen: typeof sendResetOpen;
    deleteAccountOpen: typeof deleteAccountOpen;
  };
  queryData: customerInstance_customers_results;
}

const CustomerDetailsView: React.FC<CustomerDetailsProps> = ({
  actions,
  queryData,
  translate,
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");

  const { data: roleData, loading: roleLoading, error: roleError } = useQuery(
    USER_ROLES
  );
  const roles: string[] = roleData?.user?.userInformation?.userRoles;
  let canViewLogs: boolean = false;

  store.getState().auth.user?.roles.forEach((item: Types.Role) => {
    if (item.roleName === "AuditAdmin") {
      canViewLogs = true;
    }
  });

  if (!roleLoading) {
    if (roles != null && roles.includes("rnd")) {
    }
  }
  if (roleError) {
    canViewLogs = false;
  }

  const [updateCustomerDetails, { loading: updateLoading }] = useMutation<
    MutationData,
    MutationVars
  >(MUTATION, {
    // onCompleted: () => handleClickOpen("confirmation"),
    onError: (error) => {
      let errorMessage = error.message;
      const message = "updateInformation";
      if (error.message.includes(message)) {
        const index = error.message.indexOf(message) + message.length;
        errorMessage = error.message.slice(0, index);
      }
      setError(errorMessage);
      setOpen(true);
    },
    refetchQueries: [
      {
        query: QUERY_CUSTOMER_DETAIL,
        variables: { id: queryData?.userInformation?.id },
      },
    ],
  });

  const userInformation = {
    id: queryData?.userInformation?.id ?? "",
    firstName: queryData?.userInformation?.firstName ?? "",
    lastName: queryData?.userInformation?.lastName ?? "",
    email: queryData?.userInformation?.email ?? "",
    telephone: queryData?.userInformation?.telephone ?? "",
    address: {
      street: queryData?.userInformation?.address?.street ?? "",
      postalCode: queryData?.userInformation?.address?.postalCode ?? "",
      countryCode: queryData?.userInformation?.address?.countryCode,
      city: queryData?.userInformation?.address?.city ?? "",
    },
    canUsePerlaUpdater: queryData?.userInformation?.canUsePerlaUpdater ?? false,
    acceptedMarketingUsage:
      queryData?.userInformation?.acceptedMarketingUsage ?? false,
    isTestUser: queryData?.userInformation?.isTestUser ?? false,
  };

  console.log("userinformation -> ", queryData);

  const account = {
    id: queryData?.userInformation?.id,
    email: queryData?.userInformation?.email,
    accountLocked: queryData?.userInformation?.accountLocked,
    allowedToLogin: queryData?.userInformation?.allowedToLogin,
    preferredLanguage: queryData?.userInformation?.preferredLanguage,
    lastLogin: queryData?.userInformation?.lastLogin,
    dataUpdate: queryData?.userInformation?.dataUpdate,
    verifiedAccount: queryData?.userInformation?.verifiedAccount,
    lastIncorrectLogin: queryData?.userInformation?.lastIncorrectLogin,
    registeredOn: queryData?.userInformation?.registeredOn,
    incorrectLogins: queryData?.userInformation?.incorrectLogins,
    acceptedTerms: queryData?.userInformation?.acceptedTerms,
    lastPasswordReset: queryData?.userInformation?.lastFailedPasswordReset,
    userRoles: queryData?.userInformation?.userRoles,
    acceptedMarketingUsage: queryData?.userInformation?.acceptedMarketingUsage,
  } as UserInformation;

  const [
    customerDetailsUpdateInput,
    setCustomerDetailsUpdateInput,
  ] = useState<UserInformationUpdateInput>(userInformation);

  //const [checkBox,setCheckBox] = useState<boolean>(userInformation.canUsePerlaUpdater)

  const fullName = userInformation?.firstName + " " + userInformation?.lastName;
  const { data, loading } = useQuery(GET_USER_COUNTRIES_QUERY);
  const countries: string[] = data?.user?.allowedCountries;
  let isDenmark = false;
  if (!loading) {
    if (countries.includes("DK")) {
      isDenmark = true;
    }
  }

  const pageNav = [
    {
      title: translate(
        account.accountLocked
          ? "types.common.activate"
          : "components.account.deactivate"
      ) as string,
      on: () => {
        actions.changeActiveOpen(account);
      },
    },
    {
      title: translate("components.account.sendSetPasswordEmail") as string,
      on: () => {
        actions.sendResetOpen(account);
      },
    },
    {
      title: translate("components.account.changePassword") as string,
      on: () => {
        actions.changeLoginOpen(account);
      },
    },
    {
      title: translate("components.account.deleteAccount") as string,
      on: () => {
        actions.deleteAccountOpen(account);
      },
    },
  ];

  const [validation, setValidation] = React.useState<
    { field: string; message: string }[]
  >([]);

  const validationCheck = () => {
    let validationArr: { field: string; message: string }[] = [];
    if (customerDetailsUpdateInput.email === "") {
      validationArr.push({
        field: "email",
        message: translate("message.error.noEmail") as string,
      });
    }
    if (customerDetailsUpdateInput.firstName === "") {
      validationArr.push({
        field: "firstName",
        message: translate("message.error.noFirstName") as string,
      });
    }
    if (customerDetailsUpdateInput.lastName === "") {
      validationArr.push({
        field: "lastName",
        message: translate("message.error.noLastName") as string,
      });
    }
    setValidation(validationArr);
    if (validationArr.length !== 0) {
      return false;
    } else {
      return true;
    }
  };


  return (
    <Box>
      <PageHeader
        showBack
        title={fullName}
        nav={pageNav}
        onSave={() => {
          if (validationCheck()) {
            updateCustomerDetails({
              variables: { userInformation: customerDetailsUpdateInput },
            });
          }
        }}
        isSavePending={updateLoading}
      />
      <PageHeaderMessage
        open={account.accountLocked}
        title={translate("message.info.lockedAccount") as string}
      />
      <PageDialog
        open={open}
        title={"message.error.updateCustomerError"}
        buttons={[{ title: "generics.ok", click: () => setOpen(false) }]}
        error={error}
      />
      <div style={{ maxWidth: "1700px" }}>
        <EditCustomerDetails
          customerDetailsObj={customerDetailsUpdateInput}
          setCustomerDetailsObj={setCustomerDetailsUpdateInput}
          validation={validation}
        />
        <RegisteredDevices />
        {isDenmark ? <ServiceContract /> : <div />}
        <RegisteredPools numberOfPools={queryData?.registeredPools} />
        <Box className={classes.infoSeg}>
          <ShowAccountInfo account={account} />
        </Box>
        <AuditLog
          canViewLogDetail={canViewLogs}
          gridSize={12}
          partnerDetail={null}
          productCode={null}
          productInstance={null}
          customerId={account.id}
          productId={null}
        />
      </div>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { changeLoginOpen, changeActiveOpen, sendResetOpen, deleteAccountOpen },
    dispatch
  ),
});

const ConnectedCustomerDetailsView = connect(
  null,
  mapDispatchToProps
)(withLocalize(CustomerDetailsView));

const CustomerDetails: React.FC = () => {
  const { customerId } = useParams();

  const { data, loading, error } = useQuery<customerInstance>(
    QUERY_CUSTOMER_DETAIL,
    {
      variables: { id: customerId },
    }
  );

  if (loading) return <CircularProgress color="secondary" />;
  if (!data?.customers?.results?.[0]?.userInformation)
    return <div>no data</div>;
  if (error) return <div>something went wrong</div>;

  return (
    <ConnectedCustomerDetailsView queryData={data?.customers?.results?.[0]} />
  );
};

export default CustomerDetails;
