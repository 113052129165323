import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { Error } from 'types/CampGuideTypes';

import { ActionType, ChangeData, Action } from 'redux/types/AccountShortcuts/SendReset';

import * as Types from 'types/ToolboxEntities';

import GraphQL from 'util/GraphQL';



export const open = (user: Types.UserInformation): Action => ({
  type: ActionType.OPEN,
  payload: {
    userId: user.id
  }
});

export const close = (): Action => ({
  type: ActionType.CLOSE,
});

export const error = (error: Error): Action => ({
  type: ActionType.ERROR,
  payload: error
});

export const loading = (): Action => ({
  type: ActionType.LOADING,
});



export const change = (changeDate: ChangeData): ThunkAction<Promise<void>, {}, {}, Action> => {
  return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {

    dispatch(loading());
    GraphQL.sendPasswordResetEmail(changeDate.userId).then((data: any) => {
      //console.log('DATA', data);
      dispatch(close());
    }).catch((errorData: Error) => {
      console.log('ERROR', errorData);
      dispatch(error(errorData));
    });
  };
};
