import gql from "graphql-tag";

export const QUERY_CUSTOMER_DETAIL = gql`
  query customerInstance($id: String) {
    customers(filter: { filterOption: ID, operator: EQ, values: [$id] }) {
      results {
        userInformation {
          id
          firstName
          lastName
          email
          telephone
          preferredLanguage
          address {
            street
            postalCode
            city
            countryCode
          }
          accountLocked
          allowedToLogin
          lastLogin
          dataUpdate
          verifiedAccount
          lastIncorrectLogin
          registeredOn
          incorrectLogins
          acceptedTerms
          lastFailedPasswordReset
          canUsePerlaUpdater
          userRoles
          acceptedMarketingUsage
          isTestUser
        }
        registeredPools {
          location
        }
      }
    }
    # Precache for smoother country select load
    enabledCountries {
      countries
    }
  }
`;
