import React from "react";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { Box, TextField, Select, MenuItem } from "@material-ui/core";

import { Translate } from "react-localize-redux";

import {
  ProductUpdateInput,
  ProductType,
} from "types/apolloGenerated/globalTypes";
import { productById_product_productInformation_productGroup as ProductGroup } from "types/apolloGenerated/productById";
import { productGroupList } from "types/apolloGenerated/productGroupList";
import { deviceVersionList } from "types/apolloGenerated/deviceVersionList";
import { filterSparseArray } from "util/CampUtils";
import format from "date-fns/format";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    outerContainer: {
      margin: "20px 30px 50px 30px",
      maxWidth: "1615px",
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "15px",
    },
    innerFlexContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "48%",
    },
    textField: {
      width: "48%",
      maxWidth: "670px",
    },
    textField2: {
      width: "48%",
      maxWidth: "670px",
      justifyContent: "space-between",
      display: "flex",
    },
    selectField: {
      width: "100%",
      maxWidth: "670px",
    },
  });
});

interface ProductInfoProps {
  productInfoObj: ProductUpdateInput;
  setProductInfoObj: React.Dispatch<React.SetStateAction<ProductUpdateInput>>;
  productGroup: ProductGroup | null | undefined;
  productGroupData: productGroupList | null | undefined;
  deviceVersionData: deviceVersionList | null | undefined;
  allowEditArtNr: boolean;
  deviceType: string;
  deviceVersion: string;
  articleNumberState: { error: boolean; message: string };
  lastEdited: string;
}

const ProductInfo: React.FC<ProductInfoProps> = (
  {
    productInfoObj,
    setProductInfoObj,
    productGroup,
    productGroupData,
    allowEditArtNr,
    deviceType,
    deviceVersion,
    deviceVersionData,
    articleNumberState,
    lastEdited,
  },
  props
) => {
  //GROUPS
  const productGroupArr = filterSparseArray(
    productGroupData?.productGroups?.results
  );
  const deviceVersionArr: any[] = filterSparseArray(
    deviceVersionData?.deviceVersions?.results
  );
  const productGroupList: string[] = [];
  for (let i = 0; i < productGroupArr.length; i++) {
    productGroupList[i] =
      productGroupArr[i].displayName || productGroupArr[i].id.toString();
  }
  productGroupList.unshift("No Group");

  let deviceTypesList: (string | undefined)[] = [];
  let deviceTypesNameList: (string | undefined)[] = [];
  for (let i = 0; i < deviceVersionArr.length; i++) {
    deviceTypesList[i] = deviceVersionArr[i].type?.name;
    deviceTypesNameList[i] = deviceVersionArr[i].type?.displayName;
  }
  let x = deviceTypesList.filter(
    (v: any, i: any) => deviceTypesList.indexOf(v) === i
  );
  let y = deviceTypesNameList.filter(
    (v: any, i: any) => deviceTypesNameList.indexOf(v) === i
  );
  const deviceTypeList = x as string[];
  const deviceTypeNameList = y as string[];
  deviceTypeList.unshift("null");
  deviceTypeNameList.unshift("Not Set");

  const deviceVersionList: string[] = [];
  const deviceVersionNameList: string[] = [];
  if (
    productInfoObj.deviceVersion !== null ||
    productInfoObj.deviceVersion !== "null"
  ) {
    for (var item in deviceVersionArr) {
      if (deviceVersionArr[item].type.name === productInfoObj.deviceType) {
        deviceVersionList[item] = deviceVersionArr[item].version;
        deviceVersionNameList[item] =
          deviceVersionArr[item].displayName +
          " (" +
          deviceVersionArr[item].version +
          ")";
      }
    }
  } else {
    for (let i = 0; i < deviceVersionArr.length; i++) {
      deviceVersionList[i] = deviceVersionArr[i].version;
      deviceVersionNameList[i] =
        deviceVersionArr[i].displayName || deviceVersionArr[i].id.toString();
    }
  }
  deviceVersionList.unshift("null");
  deviceVersionNameList.unshift("Not Set");

  var prodObjDeviceT = deviceVersionArr.find(
    (x) => x.type?.name === productInfoObj.deviceType
  );
  var prodObjDeviceType = prodObjDeviceT?.type?.displayName ?? "Not Set";
  var prodObjDeviceV = deviceVersionArr.find(
    (x) =>
      x.version === productInfoObj.deviceVersion &&
      x.type.name === productInfoObj.deviceType
  );
  var prodObjDeviceVersion =
    prodObjDeviceV?.displayName + " (" + prodObjDeviceV?.version + ")" ??
    "Not Set";

  const [currentGroup, setCurrentGroup] = React.useState<string>(
    productGroup?.displayName ?? "No Group"
  );
  const [currentTypeName, setCurrentTypeName] = React.useState<string>(
    prodObjDeviceType ?? "Not Set"
  );
  const [currentVersionName, setCurrentVersionName] = React.useState<string>(
    prodObjDeviceVersion ?? "Not Set"
  );

  const dropDowns = {
    productTypes: [
      "PoE & PoU",
      "Pool",
      "Water Dispenser",
      "Perla IOT",
      "Silk IOT",
    ],
    productGroups: productGroupList,
    deviceVersions: deviceVersionList,
    deviceVersionNames: deviceVersionNameList,
    deviceTypes: deviceTypeList,
    deviceTypeNames: deviceTypeNameList,
  };

  //FUNCTIONS

  const checkDropdown = (dropDownValues: string[], valueIn: string) => {
    for (let value in dropDownValues) {
      if (
        dropDownValues[value].toLowerCase().split(" ").join("_") ===
        valueIn.toLowerCase()
      ) {
        return value;
      }
    }
    return 0;
  };

  const checkDropdownTwo = (dropDownValues: string[], valueIn: string) => {
    for (let value in dropDownValues) {
      if (dropDownValues[value] === valueIn) {
        return value;
      }
    }
    return 0;
  };

  const getProductType = (dropDownValues: string[], index: string) => {
    const value = dropDownValues[parseInt(index)]
      .toUpperCase()
      .split(" ")
      .join("_");
    switch (value) {
      case "POE_&_POU":
        return ProductType.DEFAULT;
      case "POOL":
        return ProductType.POOL;
      case "WATER_DISPENSER":
        return ProductType.WATER_DISPENSER;
      case "PERLA_IOT":
        return ProductType.PERLA_IOT;
      case "SILK_IOT":
        return ProductType.SILK_IOT;
    }
    return ProductType.DEFAULT;
  };

  const findGroupIndex = (dropDownValues: string[], index: string) => {
    let idx = 0;
    let temp = dropDownValues.indexOf(index);
    if (temp >= 0) {
      idx = temp;
      return idx;
    } else {
      return idx;
    }
  };

  const getGroupName = (dropDownValues: string[], index: any) => {
    if (typeof index === "number") {
      if (index < dropDownValues.length) {
        return dropDownValues[index];
      }
    }
    return dropDownValues[0];
  };

  const classes = useStyles();

  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.flexContainer}>
        <TextField
          label={<Translate id="types.common.name" />}
          variant="standard"
          className={classes.textField}
          value={productInfoObj?.name}
          disabled={true}
        />
        <TextField
          label={<Translate id="types.product.displayName" />}
          variant="standard"
          value={productInfoObj?.displayName}
          className={classes.textField}
          disabled={true}
        />
      </Box>
      <Box className={classes.flexContainer}>
        <TextField
          label={<Translate id="types.product.gTIN" />}
          variant="standard"
          className={classes.textField}
          value={productInfoObj?.gTIN}
          disabled={true}
        />
        <Box className={classes.textField2}>
          <TextField
            label={<Translate id="types.product.itemNumber" />}
            variant="standard"
            className={classes.textField}
            value={productInfoObj?.itemNumber}
            disabled={true}
            error={articleNumberState.error}
            helperText={articleNumberState.message}
          />
          <TextField
            label={<Translate id="types.product.businessUnit" />}
            variant="standard"
            className={classes.textField}
            value={productInfoObj?.businessUnit}
            disabled={true}
          />
        </Box>
      </Box>
      <Box className={classes.flexContainer}>
        <Box className={classes.textField}>
          <Box>
            <Translate id="types.product.productGroup" />
          </Box>
          <Select
            variant="standard"
            className={classes.selectField}
            value={checkDropdownTwo(dropDowns["productGroups"], currentGroup)}
            disabled={true}
          >
            {dropDowns["productGroups"].map((name, idx) => (
              <MenuItem key={idx} value={idx}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className={classes.textField}>
          <Box>
            <Translate id="types.product.productType" />
          </Box>
          <Select
            variant="standard"
            className={classes.selectField}
            value={checkDropdown(
              dropDowns["productTypes"],
              productInfoObj.productType ?? ""
            )}
            disabled={true}
          >
            {dropDowns["productTypes"].map((name, idx) => (
              <MenuItem key={idx} value={idx}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box className={classes.flexContainer}>
        <Box className={classes.textField}>
          <Box>
            <Translate id="types.product.deviceType" />
          </Box>
          <Select
            variant="standard"
            className={classes.selectField}
            value={checkDropdownTwo(
              dropDowns["deviceTypeNames"],
              currentTypeName
            )}
            disabled={true}
          >
            {dropDowns["deviceTypeNames"].map((name, idx) => (
              <MenuItem key={idx} value={idx}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className={classes.textField}>
          <Box>
            <Translate id="types.product.deviceVersion" />
          </Box>
          <Select
            variant="standard"
            className={classes.selectField}
            value={checkDropdownTwo(
              dropDowns["deviceVersionNames"],
              currentVersionName
            )}
            disabled={true}
          >
            {dropDowns["deviceVersionNames"].map((name, idx) => (
              <MenuItem key={idx} value={idx}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box className={classes.flexContainer}>
        <TextField
          label={<Translate id="types.product.productOwnerEmail" />}
          variant="standard"
          className={classes.textField}
          value={productInfoObj?.productOwnerEmail}
          disabled={true}
        />
        <Translate>
          {(translate) => {
            return (
              <TextField
                label={<Translate id="types.product.lastEdited" />}
                variant="standard"
                className={classes.textField}
                value={
                  lastEdited
                    ? format(
                        new Date(lastEdited),
                        translate.translate(
                          "generics.dateTimeFormatFNS"
                        ) as string
                      )
                    : ""
                }
                disabled={true}
              />
            );
          }}
        </Translate>
      </Box>
    </Box>
  );
};

export default ProductInfo;
