import React, { useState, useEffect } from "react";

import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import {
  AllEnterpriseModules,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  GridApi,
  AgGridEvent,
  ColumnApi,
  ExcelExportParams,
} from "@ag-grid-enterprise/all-modules";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import equal from "deep-equal";

import { GridActionsType, State as AgGridState } from "redux/types/AgGrid";

import {
  defaultColDef,
  sideBar,
  factoryApolloConnector,
  updateGridFromState,
} from "util/AgGridUtils";
import { SingleSortModel, FilterModel } from "types/AgGrid";
import { ApolloConsumer } from "react-apollo";
import ApolloClient from "apollo-client";
import { debounce } from "debounce";
import { ColumnState } from "@ag-grid-community/core/dist/cjs/columnController/columnController";

import { makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import PageDialog from "components/common/PageDialog";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert, AlertTitle } from "@material-ui/lab";

import { Translate } from "react-localize-redux";
import { fallbackTo } from "components/common/Localization";
import { AUDIT_LOG } from "commonQueries/recordAuditLog";
import { useMutation } from "@apollo/react-hooks";
import {
    recordAuditLog,
    recordAuditLogVariables
} from "types/apolloGenerated/recordAuditLog";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    gridDefault: {
      position: "relative",
      height: "100%",
      display: "flex",
      flexFlow: "column",
      overflow: "hidden",
      "& > *": {
        flex: "1 1 auto",
      },
      "& a": {
        color: "#005D8F",
      },
      "& > .grie-error": {
        flex: "0 1 auto",
      },
    },

    exportToExcel: {
      position: "absolute",
      left: 0,
      bottom: "5px",
      background: "none",
      border: "none",
      padding: 0,
      color: "#005D8F", //"#005D8F", //"#e6006b",
      textDecoration: "underline",
      cursor: "pointer",
    },
  });
});

interface ReduxGridPara extends AgGridReactProps {
  className?: string;
  excelExportParams?: ExcelExportParams | false;
  gridState: AgGridState;
  gridActions: GridActionsType<any>;
  cellCallbackOnExport?: any;
}

interface ApolloGridPara extends ReduxGridPara {
  apolloConnector: ReturnType<typeof factoryApolloConnector>;
}

interface ApolloGridInternalPara extends ApolloGridPara {
  client: ApolloClient<any>;
}

interface GridLoadingError {
  short: string;
  long: string;
  showDetail: boolean;
}

const getDataSource = (
  client: ApolloClient<any>,
  apolloConnector: ReturnType<typeof factoryApolloConnector>,
  setGridLoadError: React.Dispatch<
    React.SetStateAction<GridLoadingError | undefined>
  >,
  setLoading: (loading: boolean) => void
): IServerSideDatasource => {
  return {
    getRows: (params: IServerSideGetRowsParams) => {
      setLoading(true);
      const errorHandler = function (loadingError: any) {
        console.error("ERROR (queryError): ", arguments);
        setGridLoadError({
          short: "message.error.generic_loading_data",
          long: JSON.stringify(loadingError),
          showDetail: false,
        });
        params.failCallback();
      };
      setGridLoadError(undefined);
      apolloConnector
        .doQueryData(client, params.request)
        .then((resultData: any) => {
          //console.log('resultData', resultData);
          if (!resultData) resultData = { results: [] };
          if ("results" in resultData) {
            let totalResults: number;
            if (!resultData.totalResults || resultData.totalResults < 0) {
              totalResults = resultData?.results?.length ?? 0;
            } else {
              totalResults = resultData.totalResults;
            }
            // console.log('totalResults / resultData', { totalResults, resultData });
            try {
              params.successCallback(resultData.results, totalResults);
            } catch (e) {
              console.error("Error whil ReRendering Grid with new Data.", e);
            }
          } else {
            errorHandler(resultData);
          }
        })
        .catch(errorHandler)
        .finally(() => {
          setLoading(false);
        });
    },

    destroy: () => {},
  } as IServerSideDatasource;
};

const isGridStateEqual = (api: GridApi, gridState: AgGridState): boolean =>
  gridState.page === api.paginationGetCurrentPage() &&
  equal(gridState.filterModel, api.getFilterModel()) &&
  equal(gridState.sortModel, api.getSortModel());

const debouncedSetGridState = debounce(
  (
    gridActions: GridActionsType<any>,
    page: number,
    sortModel: SingleSortModel[],
    filterModel: FilterModel
  ) => {
    // console.log('Change Grid (DEBOUNCED). change STATE now! filterModel: ', filterModel);
    console.log("DEBOUNCED set grid state -> ", filterModel);
    gridActions.setGridState(page, sortModel, filterModel);
  },
  500
);

const debouncedSetColumnStates = debounce(
  (gridActions: GridActionsType<any>, columnsStates: ColumnState[]) => {
    //console.log('Change Columns (DEBOUNCED). change STATE now!');
    gridActions.setColumnStates(columnsStates);
  },
  500
);

const getGridEventListener = (
  gridActions: GridActionsType<any>,
  gridState: AgGridState
) => {
  return (eventType: string, event: AgGridEvent) => {
    // console.log('GridEventListener - ' + eventType);

    if (
      eventType === "columnMoved" ||
      eventType === "columnResized" ||
      eventType === "displayedColumnsChanged"
    ) {
      debouncedSetColumnStates(gridActions, event.columnApi.getColumnState());
    }

    if (
      eventType === "filterChanged" ||
      eventType === "sortChanged" ||
      eventType === "paginationChanged"
    ) {
      if (!isGridStateEqual(event.api, gridState)) {
        console.log(
          "Change Grid (" + eventType + "). Gonna change GRID-STATE",
          {
            folterModel: gridState.filterModel,
            newFilter: event.api.getFilterModel(),
          }
        );
        debouncedSetGridState(
          gridActions,
          event.api.paginationGetCurrentPage(),
          event.api.getSortModel() as SingleSortModel[],
          event.api.getFilterModel() as FilterModel
        );
      }
    }
  };
};

export const ReduxAgGrid: React.FC<ReduxGridPara> = ({
  gridState,
  gridActions,
  className,
  excelExportParams,
  ...props
}) => {
  const classes = useStyles();

  const [api, setApi] = useState<
    { grid: GridApi; column: ColumnApi } | undefined
  >(undefined);

  const [recordLog] = useMutation<recordAuditLog, recordAuditLogVariables>(AUDIT_LOG, {})

  useEffect(() => {
    if (api) {
      updateGridFromState(api, gridState);
      const listener = getGridEventListener(gridActions, gridState);
      api.grid.addGlobalListener(listener);
      return () => {
        api.grid.removeGlobalListener(listener);
      };
    }
  }, [api, gridActions, gridState]);


  const excelExport = (api && excelExportParams !== false) ? (
      <button className={classes.exportToExcel} onClick={event => {
          api.grid.exportDataAsExcel(excelExportParams)
          recordLog({ variables: { auditLog: { interaction: "ExcelExport", detail: "User exported to excel from grid contained at " + window.location.pathname, pIILevel: 0 } } });
      }}>Export to Excel</button>
  ) : null;

  return (
    <div className={"ag-theme-balham " + (className ?? classes.gridDefault)}>
      <AgGridReact
        defaultColDef={defaultColDef}
        groupMultiAutoColumn={true}
        animateRows={false}
        modules={[...AllEnterpriseModules, ClientSideRowModelModule]}
        pagination={true}
        paginationPageSize={50}
        sideBar={sideBar}
        onGridReady={(event) =>
          setApi({ grid: event.api, column: event.columnApi })
        }
        {...props}
      />

      {excelExport}
    </div>
  );
};

const AgGrid: React.FC<ApolloGridInternalPara> = ({
  client,
  apolloConnector,
  gridState,
  gridActions,
  className,
  cellCallbackOnExport,
  excelExportParams,
  ...props
}) => {
  const classes = useStyles();

  const [api, setApi] = useState<
    { grid: GridApi; column: ColumnApi } | undefined
  >(undefined);
  const [gridLoadError, setGridLoadError] = useState<
    GridLoadingError | undefined
  >(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [blockSize, setBlockSize] = useState(100);
  const [waitingToExport, setWaitingToExport] = useState(false);
  const [update, setUpdate] = useState(0);
  const maxResults = 4000;
  const [recordLog] = useMutation<recordAuditLog, recordAuditLogVariables>(AUDIT_LOG, {});

  useEffect(() => {
    if (api) {
      // console.log('Grid -> useEffect -- API / State Changed');
      updateGridFromState(api, gridState);
      const listener = getGridEventListener(gridActions, gridState);
      api.grid.addGlobalListener(listener);
      return () => {
        api.grid.removeGlobalListener(listener);
      };
    }
  }, [api, gridActions, gridState]);

  useEffect(() => {
    if (api) {
      // console.log('Grid -> useEffect -- SSD Changed');
      const unregister = apolloConnector.onDataChangedFromOutside(function () {
        // console.log('apolloConnector.onDataChangedFromOutside', arguments);
        if (!isLoading) {
          // api.grid.purgeServerSideCache();
          // } else {
          //   console.log('apolloConnector.onDataChangedFromOutside - Is Loading, Ignore!');
        }
      });

      return () => {
        unregister();
      };
    }
  }, [api, apolloConnector, isLoading]);

  useEffect(() => {
    if (api) {
      api.grid.setServerSideDatasource(
        getDataSource(client, apolloConnector, setGridLoadError, setLoading)
      );
    }
  }, [api, apolloConnector, client, setGridLoadError]);

  interface cacheBlockState {
    blockNumber: number;
    startRow: number;
    endRow: number;
    pageStatus: string;
  }

  const checkAllDataLoaded = () => {
    const totalRows = api?.grid.getDisplayedRowCount() ?? 0;
    const cacheBlocks: cacheBlockState[] = Object.values(
      api?.grid.getCacheBlockState()
    );
    for (const block of cacheBlocks) {
      if (block.pageStatus === "loaded" && block.endRow >= totalRows) {
        return true;
      }
    }
    return false;
  };

  const downloadAndExport = () => {
    setBlockSize(maxResults);
    setUpdate(update + 1);
    setWaitingToExport(true);
  };

  const excelExport =
    api && excelExportParams !== false ? (
      <div>
        <button
          className={classes.exportToExcel}
          onClick={() => {
            if (api.grid.getDisplayedRowCount() < maxResults) {
              if (checkAllDataLoaded()) {
                api.grid.exportDataAsExcel({
                  processCellCallback: cellCallbackOnExport,
                });
                recordLog({ variables: { auditLog: { interaction: "ExcelExport", detail: "User exported to excel from grid contained at " + window.location.pathname, pIILevel: 0 } } });
              } else {
                downloadAndExport();
              }
            } else {
              setOpen(true);
            }
          }}
        >
          <Translate id="components.aggrid.error.Export-to-Excel" />
        </button>
        <PageDialog
          open={open}
          title={"components.aggrid.error.Excel-Export"}
          buttons={[
            {
              title: "generics.true",
              click: () => {
                setOpen(false);
                downloadAndExport();
              },
            },
            { title: "generics.cancel", click: () => setOpen(false) },
          ]}
        />
        <PageDialog
          open={waitingToExport}
          title={"components.aggrid.error.exportWait"}
          buttons={[]}
          loading={waitingToExport}
        />
      </div>
    ) : null;

  const alert = gridLoadError ? (
    <Alert
      className="grie-error"
      severity="error"
      action={
        <Button
          onClick={() => {
            setGridLoadError({
              ...gridLoadError,
              showDetail: !gridLoadError.showDetail,
            });
          }}
        >
          <ExpandMoreIcon />
        </Button>
      }
    >
      <AlertTitle>
        <Translate
          id={gridLoadError.short}
          options={{
            onMissingTranslation: fallbackTo(gridLoadError.short),
            renderToStaticMarkup: false,
          }}
        />
      </AlertTitle>
      {gridLoadError.showDetail ? gridLoadError.long : ""}
    </Alert>
  ) : undefined;

  //giving it a key block size will make the grid rerender on new fetch
  return (
    <div className={"ag-theme-balham " + (className ?? classes.gridDefault)}>
      {alert}
      <AgGridReact
        defaultColDef={defaultColDef}
        modules={AllEnterpriseModules}
        key={update}
        cacheBlockSize={blockSize}
        paginationPageSize={100}
        rowModelType="serverSide"
        pagination={true}
        sideBar={sideBar}
        onGridReady={(event) =>
          setApi({ grid: event.api, column: event.columnApi })
        }
        onPaginationChanged={(event) => {
          if (event.keepRenderedRows && waitingToExport) {
            if (api) {
              api.grid.exportDataAsExcel({
                processCellCallback: cellCallbackOnExport,
              });
              recordLog({ variables: { auditLog: { interaction: "ExcelExport", detail: "User exported to excel from grid contained at " + window.location.pathname, pIILevel: 0 } } });
              setBlockSize(100);
              setWaitingToExport(false);
            }
          }
        }}
        {...props}
      />
      {excelExport}
    </div>
  );
};

const ApolloGrid: React.FC<ApolloGridPara> = (props) => (
  <ApolloConsumer>
    {(client) => <AgGrid client={client} {...props} />}
  </ApolloConsumer>
);

export default ApolloGrid;
