import React, { Fragment } from "react";
import { Translate } from "react-localize-redux";
import { Autocomplete, Alert } from '@material-ui/lab';
import { TranslateFunction, withLocalize, LocalizeContextProps } from "react-localize-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { QUERY_PRODUCTS_GRID } from "Pages/Product/ProductsGrid"
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  Dialog,
  CircularProgress
} from "@material-ui/core";
import { Set } from "immutable"

import Translation from "./Translation";
import { mailTemplateGroup as queryData } from "types/apolloGenerated/mailTemplateGroup"
import {
  productById_product_productInformation_productUserAction_notifications as NotificationObj,
  productById_product_productInformation_productUserAction_notifications_translations as TranslationObj
} from "types/apolloGenerated/productById";
import { UserActionState } from "types/apolloGenerated/globalTypes";
import {
  removeNotificationVariables as removeVariables
} from "types/apolloGenerated/removeNotification";
import {
  updateNotification as update,
  updateNotificationVariables as updateVariables
} from "types/apolloGenerated/updateNotification";
import {
  addNotificationVariables as addNotifVariables,
  addNotification as addNotif
} from "types/apolloGenerated/addNotification";

const useStyles = makeStyles(() => {
  return createStyles({
    mailContainer: {
      width: "35%",
      margin: "0px 20px 20px 20px"
    },
    thresholdContainer: {
      width: "6%",
      margin: "0px 20px 20px 0px"
    },
    stateContainer: {
      width: "12%",
      margin: "0px 20px 20px 0px"
    },
    updateSpinner: {
      margin: "12px 20px 20px 0px",
      height: "40px",
      width: "85px",
      justifyContent: "center",
      display: "flex"
    },
    editButton: {
      margin: "12px 20px 20px 0px",
      minWidth: "170px",
      height: "40px"
    },
    removeSpinner: {
      margin: "12px 40px 20px 0px",
      height: "40px",
      width: "90px",
      justifyContent: "center",
      display: "flex"
    },
    translateTitle: {
      fontWeight: "bold",
      fontSize: "20px",
      margin: "30px 0px 30px 30px"
    },
    doneButtonContainer: {
      marginLeft: "30px",
      position: "absolute",
      bottom: "0",
      marginBottom: "30px"
    }
  });
});

const REMOVE_NOTIFICATION = gql`
  mutation removeNotification($productUserActionNotificationId: Int!) {
    userAction {
      deleteProductUserActionNotification(
        productUserActionNotificationId: $productUserActionNotificationId
      )
    }
  }
`;

const UPDATE_NOTIFICATION = gql`
  mutation updateNotification(
    $productNotification: ProductUserActionNotificationUpdate!
  ) {
    userAction {
      updateProductUserActionNotification(
        productUserActionNotification: $productNotification
      ) {
        id
      }
    }
  }
`;

const ADD_NOTIFICATION = gql`
  mutation addNotification(
    $productNotification: ProductUserActionNotificationInsert!
  ) {
    userAction {
      createProductUserActionNotification(
        productUserActionNotification: $productNotification
      ) {
        id
        productUserActionId
      }
    }
  }
`;

interface NotificationProps extends LocalizeContextProps {
  remove: (index: number) => void;
  change: (index: number, notification: NotificationObj) => void;
  add: (newNotif: NotificationObj) => void,
  index: number;
  notificationObject: NotificationObj;
  userActionId: number;
  mailData: queryData | undefined,
  translate: TranslateFunction,
  addOnly: boolean,
  setError: (message: string) => void
}

const Notification: React.FC<NotificationProps> = ({
  remove,
  index,
  notificationObject,
  change,
  userActionId,
  mailData,
  translate,
  addOnly,
  add,
  setError
}) => {
  const dummyTranslation: TranslationObj = {
    __typename: "ProductUserActionNotificationTranslation",
    id: -1,
    language: translate("pages.product.chooseLanguage") as string,
    body: "",
    title: ""
  }

  const classes = useStyles();
  const [mailAlert, setMailAlert] = React.useState({ error: false, message: "" })
  const [translations, setTranslations] = React.useState(
    notificationObject?.translations
  );
  const [dummyTranslat, setDummyTranslation] = React.useState<TranslationObj>(dummyTranslation)
  const [open, setOpen] = React.useState(false);
  const [translateError, setTranslateError] = React.useState("")

  let userActionStateArray: string[] = [];
  for (let userActionState in UserActionState) {
    userActionStateArray.push(userActionState);
  }

  const mailTemplateValues = mailData?.sendGridTemplates?.results ?? []
  const uniqueMailTemplateValues =
    Set(mailTemplateValues
      .map(value => value?.name ?? ""))
      .toArray()
      .filter(str => !!str)
      .sort()

  const [
    removeNotification,
    { loading: removeNotificationMutation }
  ] = useMutation<removeVariables>(
    REMOVE_NOTIFICATION,
    {
      onCompleted: () => {

        remove(index);
      },
      onError: error => {
        setError("Unable to remove notification - " + error.message)
      }
    }
  );

  const [
    addNotificationMutation,
    { loading: addNotificationLoading }
  ] = useMutation<addNotif, addNotifVariables>(ADD_NOTIFICATION, {
    onCompleted: data => {
      if (data.userAction?.createProductUserActionNotification?.id) {
        add({
          id: data.userAction.createProductUserActionNotification.id,
          __typename: "ProductUserActionNotification",
          threshold: notificationObject.threshold,
          translations: notificationObject.translations,
          state: notificationObject.state,
          mailTemplateName: notificationObject.mailTemplateName
        })
      }
    },
    onError: error => {
      setError("Unable to add notification - " + error.message)
    },
    refetchQueries: [{
      query: QUERY_PRODUCTS_GRID, variables: { pageSize: 0 }
    }]
  });

  const [
    updateNotification,
    { loading: updateNotificationMutation }
  ] = useMutation<update, updateVariables>(
    UPDATE_NOTIFICATION,
    {
      onCompleted: () => {
        change(index, {
          __typename: "ProductUserActionNotification",
          id: notificationObject.id,
          mailTemplateName: notificationObject.mailTemplateName,
          state: notificationObject.state,
          threshold: notificationObject.threshold,
          translations: notificationObject.translations
        });
      },
      onError: error => {
        setError("Unable to update notification - " + error.message)
      }
    }
  );

  const addTranslation = (newTranslat: TranslationObj) => {
    if (translations) {

    }
    const translatToAdd: TranslationObj = {
      __typename: "ProductUserActionNotificationTranslation",
      language: newTranslat.language,
      title: newTranslat.title,
      body: newTranslat.body,
      id: newTranslat.id
    };
    if (translations) {
      setTranslations([...translations.slice(), translatToAdd]);
      setDummyTranslation(dummyTranslation)
    }
  };

  const updateTranslation = (index: number, translation: TranslationObj) => {
    if (translation.id === -1) {
      const latestValue = Object.assign({}, translation);
      setDummyTranslation(latestValue)
      return;
    }
    if (translations) {
      setTranslations(
        translations.map((currentObj, idx) =>
          idx === index ? translation : currentObj
        )
      );
    }
  };

  const removeTranslation = (index: number) => {
    if (translations) {
      setTranslations([
        ...translations.slice(0, index),
        ...translations.slice(index + 1)
      ]);
    }
  };

  const getSeverity = (value: UserActionState | null) => {
    let returnValue = 0
    userActionStateArray.forEach((state, idx) => {
      if (value === state) {
        returnValue = idx;
      }
    });
    return returnValue
  }

  return (
    <Translate>
      {({ translate }) => (
        <Box>
          <Box style={{ display: "flex" }}>
            <Box className={classes.mailContainer}>
              <Box>
                <Translate id="types.product.mail" />
              </Box>
              <Autocomplete
                value={notificationObject.mailTemplateName ?? translate("pages.product.chooseMailTemplate") as string}
                options={uniqueMailTemplateValues}
                disabled={true}
                getOptionLabel={option => option}
                style={{ minWidth: "360px" }}
                renderInput={params => (
                  <TextField {...params} variant="standard" fullWidth
                    helperText={mailAlert.message}
                    error={mailAlert.error}
                    onBlur={e => {
                      if (e.target.value === "") {
                        change(index, {
                          ...notificationObject,
                          mailTemplateName: translate("pages.product.chooseMailTemplate") as string
                        })
                      }
                    }}
                  />
                )}
              />
            </Box>
            <Box className={classes.thresholdContainer}>
              <Box>
                <Translate id="types.product.threshold" />
              </Box>
              <TextField
                variant="standard"
                style={{ minWidth: "70px" }}
                value={notificationObject.threshold}
                type="number"
                disabled={true}
                inputProps={{ min: `1` }}
              />
            </Box>
            <Box className={classes.stateContainer}>
              <Box>
                <Translate id="types.product.severity" />
              </Box>
              <Select
                variant="standard"
                style={{ width: "100%", minWidth: "120px" }}
                value={getSeverity(notificationObject.state)}
                disabled={true}
              >
                {userActionStateArray.map((name, idx) => (
                  <MenuItem key={idx} value={idx}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {!addOnly ? !updateNotificationMutation ? (
              <Button
                variant="outlined"
                disabled={true}
                style={{ margin: "12px 20px 20px 0px", height: "40px" }}
              >
                <Translate id="generics.update" />
              </Button>
            ) : (
                <div className={classes.updateSpinner}>
                  <CircularProgress size={30} color="secondary" />
                </div>
              ) : <Fragment />}
            {!addOnly && <Button
              variant="outlined"
              disabled={true}
              className={classes.editButton}
            >
              <Translate id="types.product.editTranslations" />
            </Button>}
            {!addOnly ? !removeNotificationMutation ? (
              <Button
                variant="outlined"
                disabled={true}
                style={{ margin: "12px 40px 20px 0px", height: "40px" }}
              >
                <Translate id="generics.remove" />
              </Button>
            ) : (
                <div className={classes.removeSpinner}>
                  <CircularProgress size={30} color="secondary" />
                </div>
              ) : <Fragment />}
            {addOnly ? !addNotificationLoading ? (
              <Button
                disabled={true}
                variant="outlined"
                style={{ margin: "10px 0px 20px 0px", height: "42px" }}
              >
                <Translate id="generics.add" />
              </Button>
            ) : (
                <div style={{ margin: "10px 0px 20px 15px" }}>
                  <CircularProgress color="secondary" size={30} />
                </div>
              ) : <Fragment />
            }
            <Dialog maxWidth="lg" fullWidth={true} open={open}>
              <Box style={{ height: "800px" }}>
                <Box className={classes.translateTitle}>
                  <Translate id="types.product.translations" />
                </Box>
                <Box style={{ maxHeight: "620px", overflow: "auto" }}>
                  {translations &&
                    translations.map((translation, idx) => {
                      if (!translation) {
                        return <Fragment />;
                      }
                      return (
                        <Translation
                          key={idx}
                          index={idx}
                          translationObject={translation}
                          change={updateTranslation}
                          remove={removeTranslation}
                          add={addTranslation}
                          notificationId={notificationObject.id}
                          addOnly={false}
                          setError={setTranslateError}
                          allTranslations={translations ?? []}
                        />
                      );
                    })}
                  <Translation
                    index={-1}
                    translationObject={dummyTranslat}
                    change={updateTranslation}
                    remove={removeTranslation}
                    add={addTranslation}
                    notificationId={notificationObject.id}
                    addOnly={true}
                    setError={setTranslateError}
                    allTranslations={translations ?? []}
                  />
                  {translateError !== "" ? <Alert style={{ marginTop: "10px" }} severity="error">{translateError}</Alert> : <Fragment />}
                </Box>
                <Box className={classes.doneButtonContainer}>
                  <Button
                    style={{ marginRight: "20px" }}
                    disabled={true}
                    variant="outlined"
                  >
                    <Translate id="generics.done" />
                  </Button>
                </Box>
              </Box>
            </Dialog>
          </Box>
        </Box>
      )}
    </Translate>
  );
};

export default withLocalize(Notification);
