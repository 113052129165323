import React, { Fragment } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

import { TextField } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import PageHeader from "components/common/PageHeader";
import GridFilterHeader from "components/common/Grid/GridFilterHeader";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

import { AppState } from "redux/store";
import { State as ProductOverviewState } from "redux/types/ProductOverview";
import {
  setGridState,
  setColumnStates,
  search,
} from "redux/actions/ProductOverviewActions";
import { UserProfile } from "types/ToolboxEntities";
import { getFilterValues } from "util/AgGridUtils";
import {
  Translate,
  LocalizeState,
  withLocalize,
  LocalizeContextProps,
} from "react-localize-redux";

import ProductsGrid from "./ProductsGrid";
import { useDebouncedCallback } from "use-debounce";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    productOverview: {
      display: "flex",
      flexFlow: "column",

      "& > .gridFilterHeader": {
        flex: "0 1 auto",
      },

      "& > .innerContent": {
        flex: "1 1 auto",
        height: "100%",
      },
    },
    filterWarning: {
      color: "red",
      display: "flex",
      justifyContent: "center",
    },
  });
});

interface ProductsOverviewProps extends LocalizeContextProps {
  user: UserProfile | null;
  productOverviewState: ProductOverviewState;
  localize: LocalizeState;
  actions: {
    setGridState: typeof setGridState;
    setColumnStates: typeof setColumnStates;
    search: typeof search;
  };
}

//creation of the grid and search page and its setup
const ProductsOverview: React.FC<ProductsOverviewProps> = ({
  user,
  productOverviewState,
  actions,
  translate,
}) => {
  const classes = useStyles(); // inputs stylings into set up

  //sets the grid state
  const gridState = productOverviewState.grid; //use the current product overview

  // Handle global search
  let searchTerm = productOverviewState.search || "";
  const [debouncedSearch] = useDebouncedCallback((newSearchTerm: string) => {
    if (newSearchTerm.length >= 2) {
      actions.search(newSearchTerm);
    } else if (newSearchTerm.length === 0 && searchTerm.length > 0) {
      actions.search(null);
    }
  }, 250);
  const search = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  if (!user) {
    return null;
  }

  const changeFilter = (
    filterName: string,
    newFilterValues: string[] | false
  ) => {
    const newFilter = Object.assign({}, gridState.filterModel);

    if (newFilterValues !== false) {
      newFilter[filterName] = {
        filterType: "set",
        values: newFilterValues,
      };
    } else {
      delete newFilter[filterName];
    }
    actions.setGridState(gridState.page, gridState.sortModel, newFilter);
  };

  const changeIotFilter = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => {
    let newFilterValues = value as string[];
    if (newFilterValues.includes("IoT")) {
      newFilterValues.splice(newFilterValues.indexOf("IoT"), 1);
      if (!newFilterValues.includes("PERLA_IOT")) {
        newFilterValues = [...newFilterValues, "SILK_IOT", "PERLA_IOT"];
      }
    } else {
      if (newFilterValues.includes("SILK_IOT")) {
        newFilterValues.splice(newFilterValues.indexOf("SILK_IOT"), 1);
        newFilterValues.splice(newFilterValues.indexOf("PERLA_IOT"), 1);
      }
    }
    if (newFilterValues.length === 0 || newFilterValues.length === 5) {
      newFilterValues = [];
    }
    changeFilter(
      "productInformation.productType",
      newFilterValues.length > 0 &&
        newFilterValues.length < 5 &&
        newFilterValues
    );
  };

  const changeActiveFilter = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string[]
  ) => {
    let newFilterValues = value as string[];
    if (newFilterValues.length !== 1) {
      newFilterValues = [];
    }
    changeFilter(
      "productInformation.isProductRegistrationEnabled",
      newFilterValues.length === 1 && newFilterValues
    );
  };

  const filterType = gridState.filterModel["productInformation.productType"];
  const selectedTypes =
    filterType && getFilterValues(filterType).includes("PERLA_IOT")
      ? filterType && [...getFilterValues(filterType), "IoT"]
      : filterType && getFilterValues(filterType);

  const regFilterType =
    gridState.filterModel["productInformation.isProductRegistrationEnabled"];

  const regFilter = regFilterType && getFilterValues(regFilterType);

  return (
    <Fragment>
      {/* this is the header text*/}
      <PageHeader title="types.common.products" />
      <main
        className={"mainContentContainer fullHeight " + classes.productOverview}
        // specific padding when we show the "please select a filter value message to stop jumping on header and grid below"
        style={{
          position: "relative",
        }}
      >
        <GridFilterHeader style={{ height: "80px" }}>
          {/* this is the search filter*/}
          <TextField
            className="filter"
            id="standard-basic"
            label="Type to filter"
            defaultValue={searchTerm}
            onChange={search}
          />

          {/* this is the toggle button*/}
          <div className="innerHeaderRight">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <ToggleButtonGroup
                value={regFilter}
                onChange={changeActiveFilter}
              >
                <ToggleButton value="true">
                  <Translate id="pages.product.registerable" />
                </ToggleButton>
                <ToggleButton value="false">
                  <Translate id="pages.product.nonRegisterable" />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <ToggleButtonGroup
              value={selectedTypes}
              onChange={changeIotFilter}
              aria-label={translate("types.product.iot") as string}
            >
              <ToggleButton value="IoT">
                <Translate
                  options={{
                    renderToStaticMarkup: false,
                    renderInnerHtml: true,
                  }}
                  id="types.product.iot"
                />
              </ToggleButton>
              <ToggleButton value="DEFAULT">
                <Translate
                  options={{
                    renderToStaticMarkup: false,
                    renderInnerHtml: true,
                  }}
                  id="types.product.poeAndPou"
                />
              </ToggleButton>
              <ToggleButton value="POOL">
                <Translate
                  options={{
                    renderToStaticMarkup: false,
                    renderInnerHtml: true,
                  }}
                  id="types.product.pool"
                />
              </ToggleButton>
              <ToggleButton value="WATER_DISPENSER">
                <Translate
                  options={{
                    renderToStaticMarkup: false,
                    renderInnerHtml: true,
                  }}
                  id="types.product.waterDispenser"
                />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </GridFilterHeader>

        <div className="innerContent">
          <ProductsGrid />
        </div>
      </main>
    </Fragment>
  );
};

const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  productOverviewState: appState.productOverview,
  localize: appState.localize,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { setGridState, setColumnStates, search },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(ProductsOverview));
