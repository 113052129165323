import React from "react";
import { TranslateFunction } from "react-localize-redux";
import { Link } from "react-router-dom";

import gql from "graphql-tag";
import {
  customerGrid,
  customerGrid_customers_results,
} from "types/apolloGenerated/customerGrid";
import CheckIcon from "@material-ui/icons/Check";

import { ColDef } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { UserProfile } from "types/ToolboxEntities";

import { CustomerFilterOption } from "types/apolloGenerated/globalTypes";

import { factoryApolloConnector } from "util/AgGridUtils";

import format from "date-fns/format";
import { Translate } from "react-localize-redux";

export const QUERY = gql`
  query customerGrid(
    $page: Int
    $pageSize: Int
    $filter: [CustomerFilter]
    $sorting: [SortingCriterionForCustomerFilterOption]
  ) {
    customers(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sorting: $sorting
    ) {
      page
      pageSize
      totalResults
      results {
        userInformation {
          id
          firstName
          lastName
          email
          registeredOn
          allowedToLogin
          accountLocked
          telephone
          address {
            street
            postalCode
            city
            countryCode
          }
        }
        registeredPools {
          volume
        }
      }
    }
  }
`;

//missing post code and city and reg date
const colMapping = {
  "userInformation.firstName": CustomerFilterOption.FIRST_NAME,
  "userInformation.lastName": CustomerFilterOption.LAST_NAME,
  "userInformation.email": CustomerFilterOption.EMAIL,
  "userInformation.address.countryCode": CustomerFilterOption.COUNTRY,
  "userInformation.address.city": CustomerFilterOption.CITY,
  "userInformation.address.postalCode": CustomerFilterOption.POSTAL_CODE,
  "userInformation.registeredOn": CustomerFilterOption.REGISTERED_ON,
  "userInformation.telephone": CustomerFilterOption.TELEPHONE,
};

const searchColumns = [
  CustomerFilterOption.FIRST_NAME,
  CustomerFilterOption.LAST_NAME,
  CustomerFilterOption.EMAIL,
  CustomerFilterOption.CITY,
  CustomerFilterOption.POSTAL_CODE,
  CustomerFilterOption.TELEPHONE,
];

export const apolloConnectorBySearch = (searchTerm: string | null) => {
  return factoryApolloConnector(
      QUERY,
      //TODO: Remove results filtering once solution for customer querying is identified (done to prevent oData Max Fetch Count Errors)
      (data: customerGrid) => {
          if (data.customers?.totalResults && data.customers?.totalResults > 10000) {
              data.customers.totalResults = 10000;
          }
          return data.customers;
      },
    colMapping,
    {
      // filter: [{
      //   filterOption: ["IS_LOCKED"],
      //   operator: FilterOperator.EQ,
      //   values: ["false"]
      // }],
      filter: [],
      search: {
        searchCols: searchColumns as string[],
        searchTerm,
      },
    }
  );
};

export function getRowStyleScheduled(params: any) {
  if (params.data && params.data.userInformation.accountLocked) {
    return {
      "background-color": "#f5f7f7",
      color: "#9AA3A8",
      "font-style": "italic",
    };
  }
  return null;
}

export const getColumnDefs = (
  translate: TranslateFunction,
  user: UserProfile | null
): ColDef[] => {
  const columnDefs = [
    {
      headerName: translate("types.userInformation.firstName") as string,
      field: "userInformation.firstName",
      cellRenderer: "CustomerLink",
      width: 110,
    },
    {
      headerName: translate("types.userInformation.lastName") as string,
      field: "userInformation.lastName",
      cellRenderer: "CustomerLink",
      width: 110,
    },
    {
      headerName: translate("types.address.postalCode") as string,
      field: "userInformation.address.postalCode",
      width: 100,
    },
    {
      headerName: translate("types.address.city") as string,
      field: "userInformation.address.city",
      width: 100,
    },
    {
      headerName: translate("types.address.countryCode") as string,
      field: "userInformation.address.countryCode",
      filter: user ? "agSetColumnFilter" : false,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        values: user ? user.allowedCountries : [],
      },
      width: 100,
    },
    {
      headerName: translate("generics.email") as string,
      field: "userInformation.email",
      width: 220,
    },
    {
      headerName: translate("types.organization.regData") as string,
      field: "userInformation.registeredOn",
      width: 120,
      cellRenderer: "FormatDate",
    },
    {
      headerName: translate("types.userInformation.accountLocked") as string,
      field: "userInformation.accountLocked",
      width: 110,
      hide: true,
    },
    {
      headerName: translate("types.userInformation.telephone") as string,
      field: "userInformation.telephone",
      width: 250,
    },
    {
      headerName: translate("types.userInformation.numberOfPools") as string,
      field: "registeredPools.volume",
      cellRenderer: "NumberOfPools",
      width: 140,
    },
  ];

  return columnDefs;
};

interface CellRenderer {
  value: string;
  data: customerGrid_customers_results;
}

const CustomerLink: React.FC<CellRenderer> = ({ data, value }) => {
  if (!(data && data.userInformation)) {
    return <div />;
  }
  return (
    <Link to={"/cs/customer/" + data.userInformation.id}>
      {value || "NOT SET"}
    </Link>
  );
};

const NumberOfPools: React.FC<CellRenderer> = ({ data, value }) => {
  const amount = (data?.registeredPools && data.registeredPools.length) ?? 0;
  if (amount > 0) {
    return (
      <div>
        <CheckIcon />
      </div>
    );
  } else {
    return <div></div>;
  }
};

const FormatDate: React.FC<CellRenderer> = ({ data, value }) => {
  if (!(data && data.userInformation?.registeredOn)) {
    return <div />;
  }
  //return <div>{format(new Date(value), "P")}</div>;
  return (
    <Translate>
      {(translate) => {
        return (
          <div>
            {format(
              new Date(value),
              translate.translate("generics.dateTimeFormatFNSShort") as string
            )}
          </div>
        );
      }}
    </Translate>
  );
};

export const CellRenderer = { CustomerLink, FormatDate, NumberOfPools };
