import React from "react";
import { TranslateFunction } from "react-localize-redux";
import { Link } from "react-router-dom";

import gql from "graphql-tag";
import {
  createRequestGrid,
  createRequestGrid_organizationCreationRequests_results,
} from "types/apolloGenerated/createRequestGrid";

import { ColDef } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { UserProfile } from "types/ToolboxEntities";

import {
  OrganizationCreationRequestFilterOption,
  OrganizationRequestStatus,
} from "types/apolloGenerated/globalTypes";

import { factoryApolloConnector } from "util/AgGridUtils";

import format from "date-fns/format";
import { Translate } from "react-localize-redux";
import { campColours } from "themes/bwtColors";
import CampUtils from "util/CampUtils";

export const QUERY = gql`
  query createRequestGrid(
    $page: Int
    $pageSize: Int
    $filter: [OrganizationCreationRequestFilter]
    $sorting: [SortingCriterionForOrganizationCreationRequestFilterOption]
  ) {
    organizationCreationRequests(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sorting: $sorting
    ) {
      page
      pageSize
      totalResults
      results {
        city
        completionTimestamp
        countryCode
        creationTimestamp
        id
        organizationName
        postalCode
        status
        street
        user {
          firstName
          lastName
        }
        vatNumber
        website
      }
    }
  }
`;

const colMapping = {
  id: OrganizationCreationRequestFilterOption.ID,
  countryCode: OrganizationCreationRequestFilterOption.COUNTRY_CODE,
  completionTimestamp: OrganizationCreationRequestFilterOption.COMPLETION_DATE,
  creationTimestamp: OrganizationCreationRequestFilterOption.CREATION_DATE,
  organizationName: OrganizationCreationRequestFilterOption.ORGANIZATION_NAME,
  status: OrganizationCreationRequestFilterOption.STATUS,
};

const searchColumns = [
  OrganizationCreationRequestFilterOption.ID,
  OrganizationCreationRequestFilterOption.ORGANIZATION_NAME,
  OrganizationCreationRequestFilterOption.CREATION_DATE,
  OrganizationCreationRequestFilterOption.COMPLETION_DATE,
  OrganizationCreationRequestFilterOption.COUNTRY_CODE,
];

export const apolloConnectorBySearch = (searchTerm: string | null) => {
  return factoryApolloConnector(
    QUERY,
    //TODO: Remove results filtering once solution for customer querying is identified (done to prevent oData Max Fetch Count Errors)
    (data: createRequestGrid) => {
      if (
        data.organizationCreationRequests?.totalResults &&
        data.organizationCreationRequests?.totalResults > 10000
      ) {
        data.organizationCreationRequests.totalResults = 10000;
      }
      return data.organizationCreationRequests;
    },
    colMapping,
    {
      defaultSort: [
        {
          colId: "creationTimestamp",
          sort: "desc",
        },
      ],
      filter: [],
      search: {
        searchCols: searchColumns as string[],
        searchTerm,
      },
    }
  );
};

export function getRowStyleScheduled(params: any) {
  if (params.data) {
    if (params.data.status === "ACCEPTED") {
      return { backgroundColor: campColours.backgroundDiscon };
    }
    if (params.data.status === "PENDING") {
      return { backgroundColor: campColours.backgroundWarn };
    }
    if (params.data.status === "DECLINED") {
      return { backgroundColor: campColours.backgroundError };
    }
  }
  return null;
}

export const getColumnDefs = (
  translate: TranslateFunction,
  user: UserProfile | null
): ColDef[] => {
  const columnDefs = [
    {
      headerName: translate("generics.id") as string,
      field: "id",
      cellRenderer: "RequestLink",
      width: 250,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: translate("pages.partnerRequest.organizationName") as string,
      field: "organizationName",
      width: 240,
    },
    {
      headerName: translate("types.common.status") as string,
      field: "status",
      width: 100,
      filter: "agSetColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        values: CampUtils.getEnumAsArray(OrganizationRequestStatus),
      },
      sortable: false,
    },
    {
      headerName: translate(
        "components.promotions.details.creationDate"
      ) as string,
      field: "creationTimestamp",
      cellRenderer: "FormatDate",
      width: 120,
      suppressMenu: true,
    },
    {
      headerName: translate("pages.joinRequests.completionTimestamp") as string,
      field: "completionTimestamp",
      cellRenderer: "FormatDate",
      width: 150,
      suppressMenu: true,
    },
    {
      headerName: translate("types.address.countryCode") as string,
      field: "countryCode",
      filter: user ? "agSetColumnFilter" : false,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        values: user ? user.allowedCountries : [],
      },
      width: 100,
    },
    {
      headerName: translate("types.common.name") as string,
      field: "user.firstName",
      cellRenderer: "FormatName",
      width: 180,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: translate("types.address.city") as string,
      field: "city",
      width: 150,
      sortable: false,
      suppressMenu: true,
    },
  ];

  return columnDefs;
};

interface CellRenderer {
  value: string;
  data: createRequestGrid_organizationCreationRequests_results;
}

const RequestLink: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  return <Link to={"/pam/creation/request/" + value}>{value}</Link>;
};

const FormatDate: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }

  return (
    <Translate>
      {(translate) => {
        return (
          <div>
            {value
              ? format(
                  new Date(value),
                  translate.translate("generics.dateTimeFormatFNS") as string
                )
              : "-"}
          </div>
        );
      }}
    </Translate>
  );
};

const FormatName: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  return <div>{data.user?.firstName + " " + data.user?.lastName}</div>;
};

export const CellRenderer = { FormatDate, FormatName, RequestLink };
