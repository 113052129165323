import React, { useMemo, Fragment } from "react";

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { withLocalize, LocalizeContextProps, TranslateFunction, Translate } from "react-localize-redux";

import { Link } from "react-router-dom";

import { CircularProgress, makeStyles, createStyles, Theme } from "@material-ui/core";

import { useQuery } from "react-apollo";
import gql from "graphql-tag";

import { ColDef, ValueFormatterParams } from "@ag-grid-enterprise/all-modules";

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { ReduxAgGrid } from "components/common/Grid/AgGrid";

import { AppState } from "redux/store";
import { State as PearlsGridState } from "redux/types/PearlsGrid";
import { setGridState, setColumnStates, } from "redux/actions/PearlsGridActions";

import { pearlsReportGrid_pearls, pearlsReportGridVariables, pearlsReportGrid } from "types/apolloGenerated/pearlsReportGrid";
import { Country } from "types/apolloGenerated/globalTypes";

import { pearlsReportGrid_pearls_organization } from 'types/apolloGenerated/pearlsReportGrid';


const QUERY = gql`
  query pearlsReportGrid(
    $countryCode: Country!
    $from: DateTimeOffset!
    $to: DateTimeOffset!
  ) {
    pearls(countryCode: $countryCode, from: $from, to: $to) {
      organization {
        id
        email
        name
        address {
          city
          postalCode
          countryCode
        }
      }
      transaction {
        amount
        conversionRate
        id
        date
      }
    }
  }
`;




const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    pearlsGrid: {
      position: "relative",
      height: "100%",
      display: 'flex',
      flexFlow: 'column',
      overflow: 'hidden',

      '& > *': {
        flex: '1 1 auto',
      },
      '& a': {
        color: '#005D8F'
      },
      '& > .grie-error': {
        flex: '0 1 auto',
      },
      '& .ag-paging-panel.ag-hidden': {
        display: 'block !important',
        opacity: 0,
        pointerEvents: 'none',
      },
      '& .ag-cell.number-cell': {
        textAlign: 'right',
        paddingRight: '40px',
      },
    },
  });
});




export const getColumnDefs = (translate: TranslateFunction): ColDef[] => {
  const columnDefs = [
    {
      headerName: translate("types.organization.name") as string,
      field: "organization.name",
      cellRenderer: "PartnerLink",
      sortable: true,
      filter: true,
      width: 180,
      getQuickFilterText: function (params: any) {
        return params.value;
      }
    },
    {
      headerName: translate("types.address.postalCode") as string,
      field: "organization.address.postalCode",
      sortable: true,
      filter: true,
      width: 120,
    },
    {
      headerName: translate("types.address.city") as string,
      field: "organization.address.city",
      sortable: true,
      filter: true,
      width: 120
    },
    {
      headerName: translate("types.address.countryCode") as string,
      field: "organization.address.countryCode",
      sortable: true,
      filter: true,
      width: 100
    },
    {
      headerName: translate("types.pearlsTransactions.credit.pearls") as string,
      field: "transaction.debitPearls",
      sortable: true,
      filter: true,
      width: 150,
      cellClass: "number-cell",
      valueFormatter: numberFormatter,
      headerTooltip: translate("types.pearlsTransactions.tooltip.creditPearl") as string
    },
    {
      headerName: translate("types.pearlsTransactions.credit.euros") as string,
      field: "transaction.debitEuros",
      sortable: true,
      filter: true,
      width: 150,
      cellClass: "number-cell",
      valueFormatter: currencyFormatter,
      headerTooltip: translate("types.pearlsTransactions.tooltip.creditEuro") as string
    },
    {
      headerName: translate("types.pearlsTransactions.debit.pearls") as string,
      field: "transaction.creditPearls",
      sortable: true,
      filter: true,
      width: 150,
      cellClass: "number-cell",
      valueFormatter: numberFormatter,
      headerTooltip: translate("types.pearlsTransactions.tooltip.debitPearl") as string
    },
    {
      headerName: translate("types.pearlsTransactions.debit.euros") as string,
      field: "transaction.creditEuros",
      sortable: true,
      filter: true,
      width: 150,
      cellClass: "number-cell",
      valueFormatter: currencyFormatter,
      headerTooltip: translate("types.pearlsTransactions.tooltip.debitEuro") as string
    }
  ];
  return columnDefs;
};

const numberFormatter = (params: ValueFormatterParams) => {
  if (params.value === 0) {
    return '';
  }
  return params.value;
}

const currencyFormatter = (params: ValueFormatterParams) => {
  if (params.value === 0) {
    return '';
  }
  return "€ " + params.value.toFixed(2);
}


interface CellRenderer {
  value: number;
  data: pearlsReportGrid_pearls;
}

const PartnerLink: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  return <Link to={"partner/" + data?.organization?.id}>{value}</Link>;
};

interface SummaryRecord {
  transaction: {
    debitPearls: number,
    debitEuros: number,
    creditPearls: number,
    creditEuros: number,
  }

  organization: pearlsReportGrid_pearls_organization
}

interface id2SummaryMapping {
  [key: string]: SummaryRecord
}






interface PearlsGridPara extends LocalizeContextProps {
  translate: TranslateFunction
  country: Country
  from: Date
  to: Date
  search: string

  gridState: PearlsGridState
  actions: {
    setGridState: typeof setGridState
    setColumnStates: typeof setColumnStates
  };
}

const PearlsGrid: React.FC<PearlsGridPara> = ({ translate, country, from, to, gridState, actions, search }) => {

  const classes = useStyles();

  const { data, loading } = useQuery<pearlsReportGrid, pearlsReportGridVariables>(QUERY, {
    variables: {
      countryCode: country as Country,
      from: from.toJSON(),
      to: to.toJSON()
    },
    errorPolicy: 'ignore' // ToDo: Workaround for BUG (4284). Remove after fixed.
  });

  const gridData = useMemo<SummaryRecord[]>(() => {
    if (data && data.pearls) {

      const summary: id2SummaryMapping = {};
      data.pearls.reduce((summary, pearlTrans) => {
        if (pearlTrans && pearlTrans.organization && pearlTrans.transaction && pearlTrans.transaction.amount !== 0) {

          summary[pearlTrans.organization.id] = summary[pearlTrans.organization.id] || {
            transaction: {
              creditEuros: 0,
              creditPearls: 0,
              debitEuros: 0,
              debitPearls: 0
            },
            organization: pearlTrans.organization
          };

          if (pearlTrans.transaction.amount > 0) {
            summary[pearlTrans.organization.id].transaction.debitPearls += pearlTrans.transaction.amount
            summary[pearlTrans.organization.id].transaction.debitEuros = Math.round(
              summary[pearlTrans.organization.id].transaction.debitEuros * 100
              + pearlTrans.transaction.amount * pearlTrans.transaction.conversionRate * 100
            ) / 100
          } else {
            summary[pearlTrans.organization.id].transaction.creditPearls -= pearlTrans.transaction.amount
            summary[pearlTrans.organization.id].transaction.creditEuros = Math.round(
              summary[pearlTrans.organization.id].transaction.creditEuros * 100
              - pearlTrans.transaction.amount * pearlTrans.transaction.conversionRate * 100
            ) / 100
          }
        }
        return summary;
      }, summary);

      return Object.values(summary);
    }
    return [];
  }, [data]);


  if (loading) {
    return <CircularProgress color="secondary" />
  } else if (data) {
    return (
      <Fragment>
        <ReduxAgGrid
          className={classes.pearlsGrid}
          columnDefs={getColumnDefs(translate)}
          rowData={gridData}
          frameworkComponents={{ PartnerLink }}
          gridState={gridState.grid}
          quickFilterText={search}
          gridActions={actions}
          pagination={false}
          suppressExcelExport={false}
        // onGridReady={event => {
        //   try {
        //     (event.api as any).context.beanWrappers.tooltipManager.beanInstance.MOUSEOVER_SHOW_TOOLTIP_TIMEOUT = 500;
        //   } catch (e) {
        //     console.error(e);
        //   }
        // }}
        />
      </Fragment>

    );
  }

  return <Translate id="generics.noDataAvailable" />;
};


const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  gridState: appState.pearlsGrid
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { setGridState, setColumnStates },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(PearlsGrid));
