import React from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';



import IconSettings from '@material-ui/icons/Settings';
import IconAdd from '@material-ui/icons/Add';
import IconSave from '@material-ui/icons/Save';
import IconMenu from '@material-ui/icons/Menu';
import IconBack from '@material-ui/icons/ArrowBack';

import bwtColors from 'themes/bwtColors';
import { CircularProgress } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import { Link, useHistory } from 'react-router-dom';

import { fallbackTo } from 'components/common/Localization';

type eventFunc = (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;

type navItem = {
  title: string,
  on: eventFunc | string
};

interface iPageHeader {
  title: string
  subTitle?: string
  onAdd?: eventFunc | string
  onSettings?: eventFunc | string
  onSave?: eventFunc
  showBack?: Boolean | false
  nav?: navItem[]
  isSavePending?: boolean
  colour?: string
};


const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    pageHeader: {
      padding: '0 ' + theme.spacing(3) + 'px',
      borderBottom: "solid 1px " + bwtColors.grey50,
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      lineHeight: '64px',
      margin: 0,
      fontWeight: 'normal',
      fontSize: '2.5em',
    },
    subTitle: {
      fontSize: '1.5em',
      fontWeight: 'normal',
      paddingLeft: '20px'
    },
    controllGroup: {
      fontSize: '2em',
      display: 'flex',
      position: 'relative'
    },
    controllGroupNav: {
      fontSize: '2em',
      display: 'flex',
      position: 'relative',
      "& div:first-child": {
        zIndex: 1,
        top: "13px",
        height: "60%",
        "& svg": {
          left: "15px",
        }
      },
      "& div:last-child": {
        right: 0,
      }
    },
    icon: {
      cursor: 'pointer',
      width: '40px',
      height: '100%',
      position: 'relative',
      '&:not(:hover) $subNav': {
        display: 'none',
      },
      '& > a': {
        color: "inherit",
        display: "block",
        width: "100%",
        height: "100%",
      }
    },
    iconMenu: {
      cursor: 'pointer',
      marginLeft: "-40px",
      height: '100%',
      width: "85px",
      textAlign: "right",
      "& svg": {
        right: "0",
        left: "auto"
      },
      position: 'relative',
      '&:not(:hover) $subNav': {
        display: 'none',
      },
      '& > a': {
        color: "inherit",
        display: "block",
        width: "100%",
        height: "100%",
      }
    },
    iconSvg: {
      position: 'absolute',
      left: '25%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    subNav: {
      position: 'absolute',
      fontSize: '1rem',
      top: '100%',
      right: 0,
      border: 'solid 1px',
      whiteSpace: 'nowrap',
      padding: '5px',
      zIndex: 1,
      background: '#fff'
    },
    subNavItem: {
      display: 'block',
      padding: '5px',
      color: bwtColors.grey,
      '&:hover': {
        backgroundColor: bwtColors.grey10,
      }
    },
    pendingSave: {
      width: '30px!important',
      height: '30px!important',
      position: 'absolute',
      right: "55px"
    },
    leftSeg: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    }
  })
});


const createIcon = (onClick: eventFunc | string | undefined, classes: ReturnType<typeof useStyles>, icon: JSX.Element, isPending: boolean = false, pendingClass?: string): JSX.Element | null => {
  if (onClick) {
    if (isPending) {
      return <CircularProgress color="secondary" className={pendingClass} />;
    } else if (onClick instanceof Function) {
      return <div className={classes.icon} onClick={onClick}>{icon}</div>;
    } else {
      return <div className={classes.icon}><Link to={onClick}>{icon}</Link></div>
    }
  }
  return null;
};


const createNavLink = (navItem: navItem, classes: ReturnType<typeof useStyles>, idx: number): JSX.Element => {
  const on = navItem.on;
  if (on instanceof Function) {
    return <a key={idx} href="/" className={classes.subNavItem} onClick={(event) => { on(event); event.preventDefault(); return false; }}>{navItem.title}</a>;
  } else {
    return <Link className={classes.subNavItem} to={on}>{navItem.title}</Link>;
  }
};


const PageHeader: React.FC<iPageHeader> = ({ title, subTitle, onAdd, onSettings, onSave, nav, isSavePending = false, showBack, colour }) => {
  const history = useHistory();
  const classes = useStyles();

  const getBack = !showBack ? null : (
    <div className={classes.controllGroup}>
      <div className={classes.icon} onClick={history.goBack}><IconBack className={classes.iconSvg} fontSize="inherit" /></div>
    </div>
  );

  const add = createIcon(onAdd, classes, <IconAdd className={classes.iconSvg} fontSize="inherit" />);
  const settings = createIcon(onSettings, classes, <IconSettings className={classes.iconSvg} fontSize="inherit" />);

  const save = createIcon(onSave, classes, <IconSave className={classes.iconSvg} fontSize="inherit" />, isSavePending, classes.pendingSave);

  const navigation = nav ? (
    <div className={classes.iconMenu}><IconMenu className={classes.iconSvg} fontSize="inherit" />
      <div className={classes.subNav}>{nav.map((item, idx) => createNavLink(item, classes, idx))}</div>
    </div>
  ) : null;

  return (
    <header className={'pageHeader ' + classes.pageHeader} style={{ color: colour && colour }}>
      <div className={classes.leftSeg}>
        {getBack}
        <h1 className={classes.title}>
          <Translate id={title} options={{ onMissingTranslation: fallbackTo(title), renderToStaticMarkup: false }} />&nbsp;
        </h1>
        {subTitle && (<h3 className={classes.subTitle}>{subTitle}</h3>)}
      </div>
      <div className={classes.controllGroupNav}>{add}{settings}{save}{navigation}</div>
    </header>
  );
}

export default PageHeader;
