import * as Types from 'types/ToolboxEntities';

export enum ActionType {
  LOGIN = 'AUTH_LOGIN',
  LOGOUT = 'AUTH_LOGOUT',
  UNAUTHORISED = 'AUTH_UNAUTHORISED',
  LOADING = 'AUTH_LOADING',
  EXPIRE = 'AUTH_EXPIRE'
}

export interface AuthStore {
  isLoading: boolean
  hasUnauthorisedLogin: boolean,
  user: Types.UserProfile | null,
  authHasExpired: boolean
}

export interface Action {
  type: ActionType
  payload: Types.UserProfile | null
}
