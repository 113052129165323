import React from "react";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Input,
  ListItemText,
} from "@material-ui/core";

import { UserProfile } from "types/ToolboxEntities";
import { AppState } from "redux/store";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
      maxWidth: 300,
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      //8.5 is the amount of items to show in the countries dropdown/dropdown height
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MultiSelectPara {
  selected: string[];
  onChange: (selected: string[]) => void;
  user: UserProfile | null;
  allowMultipleSelection?: boolean;
  showSelectAll?: boolean;
}

const MultiSelect: React.FC<MultiSelectPara> = ({
  selected,
  onChange,
  user,
  allowMultipleSelection = true,
  showSelectAll = true,
}) => {
  const classes = useStyles();
  if (user === null || user.allowedCountries.length < 2) {
    return null;
  }

  //const isSelectedAll = selected.length === 0 || (selected.length === 1 && selected[0] === 'all') || (selected.length >= user.allowedCountries.length);
  const isSelectedAll =
    (selected.length === 1 && selected[0] === "all") ||
    selected.length >= user.allowedCountries.length;
  //const realSelected: string[] = isSelectedAll ? user.allowedCountries : selected;
  const realSelected: string[] = selected;

  function arraysEqual(a: string[], b: string[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  const change = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode
  ) => {
    const myKey = (child as React.ReactElement).key ?? "";
    const keyIncludesAll = typeof myKey === "string" && myKey.includes("all");
    if (child && keyIncludesAll) {
      if (arraysEqual(selected, user.allowedCountries)) {
        onChange([""]);
      } else {
        onChange(user.allowedCountries);
      }
    } else {
      const eventTargetVal = event.target.value as string[];
      if (eventTargetVal[0] === "") eventTargetVal.shift();
      onChange(eventTargetVal);
    }
  };

  return (
    <Translate>
      {({ translate }) => (
        <FormControl className={classes.formControl}>
          <Select
            multiple={allowMultipleSelection}
            value={realSelected}
            input={<Input />}
            renderValue={(realSelected) =>
              isSelectedAll
                ? (translate("components.filter.multiSelect.all") as string)
                : (realSelected as string[]).join(", ")
            }
            MenuProps={MenuProps}
            onChange={change}
            style={{ width: "200px" }}
          >
            {showSelectAll && (
              <MenuItem divider key="all">
                <Checkbox checked={isSelectedAll} />
                <ListItemText
                  primary={translate("components.filter.multiSelect.all")}
                />
              </MenuItem>
            )}
            {user.allowedCountries.map((country) => (
              <MenuItem key={country} value={country}>
                <Checkbox checked={realSelected.indexOf(country) > -1} />
                <ListItemText
                  primary={translate(
                    "countries." + country.toLocaleLowerCase()
                  )}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Translate>
  );
};

const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
});

export default connect(mapStateToProps)(MultiSelect);
