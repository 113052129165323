import React, { Fragment, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { open as changeLoginOpen } from 'redux/actions/AccountShortcuts/ChangeLoginActions';
import { open as changeActiveOpen } from 'redux/actions/AccountShortcuts/ChangeActiveActions';
import { open as sendResetOpen } from 'redux/actions/AccountShortcuts/SendResetActions';

import { UserInformation } from 'types/ToolboxEntities';
import ShowAccountInfo from 'components/common/ShowAccountInfo';
import { AuthStore } from 'redux/types/Auth';
import { AppState } from 'redux/store';

import CountrySelect from 'components/common/CountrySelect';
import InputToChip from 'components/common/InputToChip';
import PageHeader from 'components/common/PageHeader';

import allCountryCodes from 'resources/country_codes.json';

import { MultiSelectStore } from 'redux/types/MultiSelect';
import { InputToChipStore } from 'redux/types/InputToChip';

import { load, pending, save, setCountries, error } from 'redux/actions/MultiSelect';
import { loadChips, pendingChips, saveChips, setChips, errorChips } from 'redux/actions/InputToChip';

import Error from 'components/common/Error';
import { ErrorCodes } from 'types/CampGuideTypes';
import { Error as BwtError } from 'types/CampGuideTypes';
import { useQuery } from '@apollo/react-hooks';
import USER_LANGUAGE from 'commonQueries/userLanguage';


const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    content: {
      padding: theme.spacing(3),
    },
  })
});



interface IGetUserInfo {
  auth: AuthStore
}

export const GetUserInfo: React.FC<IGetUserInfo> = ({ auth }) => {
  const { data } = useQuery(USER_LANGUAGE);
  const lang = data?.user?.userInformation?.preferredLanguage;
  return (
    auth && auth.user ?
      <ShowAccountInfo account={auth.user.userInformation} userLanguage={lang} />
      : <div>Error</div>
  );
};


interface ChangeLoginTrigger { actions: { changeLoginOpen: typeof changeLoginOpen } };

const ChangeLoginButton = connect(
  null,
  (dispatch: Dispatch) => ({ actions: bindActionCreators({ changeLoginOpen }, dispatch) })
)(
  ({ actions }: ChangeLoginTrigger) => (
    <Button variant="outlined" color="primary" onClick={() => { actions.changeLoginOpen({ id: "1234", email: "asasfd@asdf.com" } as UserInformation) }}>
      Change Login
    </Button>
  )
);


interface ChangeActiveTrigger { actions: { changeActiveOpen: typeof changeActiveOpen } };

const ChangeActiveButton = connect(
  null,
  (dispatch: Dispatch) => ({ actions: bindActionCreators({ changeActiveOpen }, dispatch) })
)(
  ({ actions }: ChangeActiveTrigger) => (
    <Button variant="outlined" color="primary" onClick={() => { actions.changeActiveOpen({ id: "1234", email: "asasfd@asdf.com" } as UserInformation) }}>
      Change Active State
    </Button>
  )
);


interface SendResetTrigger { actions: { sendResetOpen: typeof sendResetOpen } };

const SendResetButton = connect(
  null,
  (dispatch: Dispatch) => ({ actions: bindActionCreators({ sendResetOpen }, dispatch) })
)(
  ({ actions }: SendResetTrigger) => (
    <Button variant="outlined" color="primary" onClick={() => { actions.sendResetOpen({ id: "1234", email: "asasfd@asdf.com" } as UserInformation) }}>
      Send Password reset email
    </Button>
  )
);

interface AdminSettingsProps {
  csStore: MultiSelectStore
  csActions: {
    load: typeof load,
    pending: typeof pending,
    save: typeof save,
    error: typeof error,
    setCountries: typeof setCountries
  }
  iTcStore: InputToChipStore
  iTcActions: {
    loadChips: typeof loadChips,
    pendingChips: typeof pendingChips,
    saveChips: typeof saveChips,
    errorChips: typeof errorChips,
    setChips: typeof setChips
  }
}

const Admin: React.FC<AdminSettingsProps> = ({ csStore, csActions, iTcStore, iTcActions }, props) => {

  const [countries, setCountries] = React.useState<string[]>(csStore.countryCodes);
  const [countriesError, setCountriesError] = React.useState<BwtError | null>(null);

  const [employeeRoles, setEmployeeRoles] = React.useState<string[]>(iTcStore.chips);
  const [employeeError, setEmployeeError] = React.useState<BwtError | null>(null);


  const saveFunction = () => {
    let errorCounter = 0;

    // Countries
    if (countries.length === 0) {
      errorCounter++;
      setCountriesError({ code: ErrorCodes.gql_property_required, message: 'test' });
    }

    // Employee Roles
    if (employeeRoles.length === 0) {
      errorCounter++;
      setEmployeeError({ code: ErrorCodes.gql_property_required, message: 'test' });
    }

    if (errorCounter === 0) {
      csActions.save(countries);
      setCountriesError(null);

      iTcActions.saveChips(employeeRoles);
      setEmployeeError(null);
    }

  };

  useEffect(() => {
    if (!csStore.pending) {
      if (!csStore.isLoaded) {
        csActions.load();
      } else {
        setCountries(csStore.countryCodes);
      }
    }
  }, [csStore, csActions]);


  useEffect(() => {
    if (!iTcStore.pending) {
      if (!iTcStore.isLoaded) {
        iTcActions.loadChips();
      } else {
        setEmployeeRoles(iTcStore.chips);
      }
    }
  }, [iTcStore, iTcActions]);

  return (
    <Fragment>
      <PageHeader title="Admin Panel" onSave={saveFunction} isSavePending={csStore.pending} />
      <div className={useStyles().content}>
        <Error error={csStore.error} />
        <Error error={iTcStore.error} />
        <ChangeLoginButton />
        <ChangeActiveButton />
        <SendResetButton />
        <p><br /></p>
        <CountrySelect headline="components.multiSelect.countries" disabled={csStore.pending} selectable={allCountryCodes} selected={countries} onChange={setCountries} error={countriesError} translateConfig={{ prefix: 'countries.' }} />
        <InputToChip disabled={iTcStore.pending} employeeRoles={employeeRoles} onChange={setEmployeeRoles} error={employeeError} />
        <p><br /></p>
        <ReduxUserInfo />
      </div>
    </Fragment >
  );
};

const mapStateToProps = (state: AppState) => ({
  csStore: state.multiSelect,
  auth: state.auth,
  iTcStore: state.inputToChip
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  csActions: bindActionCreators({ load, pending, save, setCountries, error }, dispatch),
  iTcActions: bindActionCreators({ loadChips, pendingChips, saveChips, setChips, errorChips }, dispatch)
});


const ReduxUserInfo = connect(
  mapStateToProps
)(GetUserInfo);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);


