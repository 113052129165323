import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Translate } from "react-localize-redux";

import DeviceData from "./DeviceData";
import DisplayStatus from "./DisplayStatus";
import SilkDeviceData from "./SilkDeviceData";
import SilkTelemetry from "./SilkTelemetry";
import CustomerDetails from "./CustomerDetails";
import Telemetry from "./Telemetry";
import NotificationsHistory from "./NotificationsHistory";
import SilkNotifs from "./SilkNotifications";
import DeviceStatus from "./DeviceStatus";
import DeviceSummary from "./DeviceSummary";
import SilkSummary from "./SilkSummary";
import BarChart from "./BarChart/index";
import Regeneration from "./Regeneration";
import SilkRegeneration from "./SilkRegeneration";
import ParameterRnD from "./ParametersRnD";
// import Connectivity from "./Connectivity";
import PageHeader from "components/common/PageHeader";
import colours from "themes/bwtColors";

import USER_ROLES from "commonQueries/userRole";

import {
  productInstanceAndCustomerDetails_productInstance_instanceInformation_ProductInstanceInformation as ProductInstanceInfo,
  productInstanceAndCustomerDetails_productInstance_instanceInformation_PerlaInstanceInformation as PerlaInstanceInfo,
  productInstanceAndCustomerDetails_productInstance_instanceInformation_SilkInstanceInformation as SilkInstanceInformation,
  productInstanceAndCustomerDetails_productInstance_owner_userInformation as UserInfo,
  productInstanceAndCustomerDetails_productInstance as ProductInstance,
  productInstanceAndCustomerDetails as QueryData,
  productInstanceAndCustomerDetailsVariables as QueryVars,
} from "types/apolloGenerated/productInstanceAndCustomerDetails";

import Connectivity from "./Connectivity";

import AuditLog from "./AuditLog";
import store from "../../../redux/store";
import * as Types from "../../../types/ToolboxEntities";

function resolveInstanceInfo(
  data?: QueryData
): {
  prodInfo: ProductInstanceInfo | null;
  perlaInfo: PerlaInstanceInfo | null;
  silkData: SilkInstanceInformation | null;
} {
  const info = ((data || {}).productInstance || {}).instanceInformation;
  if (info) {
    switch (info.__typename) {
      case "ProductInstanceInformation":
        return {
          prodInfo: info as ProductInstanceInfo,
          perlaInfo: null,
          silkData: null,
        };

      case "PerlaInstanceInformation":
        return {
          prodInfo: null,
          perlaInfo: info as PerlaInstanceInfo,
          silkData: null,
        };

      case "SilkInstanceInformation":
        return {
          prodInfo: null,
          perlaInfo: null,
          silkData: info as SilkInstanceInformation,
        };
    }
  }
  return { prodInfo: null, perlaInfo: null, silkData: null };
}

const QUERY = gql`
  query productInstanceAndCustomerDetails(
    $productCode: String!
    $timestamp: DateTimeOffset!
    $count: Int!
  ) {
    productInstance(productCode: $productCode) {
      id
      productCode
      dataSharedWithPartner
      installationDate
      registrationDate
      batchNumber
      erpBatchNumber
      batchDeactivated
      isTestDevice
      serialNumber
      acceptedContractDate
      acceptedContractTerms
      product {
        productInformation {
          id
          name
          recentFirmwareVersion
          itemNumber
        }
      }
      address {
        id
        street
        postalCode
        city
        countryCode
        location {
          latitude
          longitude
        }
      }
      servicePartner {
        id
        name
      }
      customerSelectedInstaller {
        id
        name
      }
      owner {
        userInformation {
          id
          firstName
          lastName
          email
          telephone
          lastLogin
          activity {
            clientName
            lastAction
          }
          address {
            street
            postalCode
            city
          }
        }
      }
      instanceInformation {
        ... on SilkInstanceInformation {
          notifications {
            messageType
            message
            timestamp
            direction
          }
          deviceId
          productCode
          state
          silkDetailsInstance {
            averageWaterServedDaily
            currentSaltLevelKg
            daysServiceExpire
            deviceActivated
            lastUpdate
            maxSaltCapacityKg
            productCode
            regenerationStarted
            totalDaysInService
          }
          silkDataInstance {
            action
            actionState
            averageWaterServed
            batchNumber
            currentActivity
            dayOfWeek
            daysToService
            daysWarrantyExpires
            finished
            hardness
            hasAction
            productCode
            productionDate
            remainingCapacity
            remainingSalt
            started
            totalRecharges
            totalWaterServed
            userId
            waterConsumption
            waterFlowRate
            waterHardnessUnit
            wifiConnectionStrength
          }
        }
        ... on PerlaInstanceInformation {
          registeredPerlaInstanceInformation {
            firmware
            stateTitle
            nextCustomerMaintainance
            nextServiceDate
            holidayModeActive
            ballValveOpen
            deviceParameter
          }
          wPA2Key
          deviceId
          accessCodes {
            code
            accessLevel
          }
          telemetry(timestamp: $timestamp) {
            value {
              date
            }
          }
          unconfirmedNotifications(count: $count) {
            baseInformation {
              receivedAt
              messageId
              sentAt
            }
            stateBody
            stateTitle
            code
            codeType
          }
        }
        ... on ProductInstanceInformation {
          userActions {
            end
            userActionClass
          }
        }
      }
    }
  }
`;

const deactivatedProductCode = (isDeactivated: boolean | undefined) => {
  return isDeactivated ? (
    <Alert severity="warning">
      <Translate id="message.error.deactivatedBatch" />
    </Alert>
  ) : (
    <Fragment />
  );
};

const CustomerPage: React.FC = () => {
  const { productCode = "" } = useParams();
  const [timestamp] = useState(new Date().toJSON());
  const count = 5;

  const { data, loading, error } = useQuery<QueryData, QueryVars>(QUERY, {
    variables: { productCode, timestamp, count },
  });

  const { data: roleData, loading: roleLoading, error: roleError } = useQuery(
    USER_ROLES
  );
  const roles: string[] = roleData?.user?.userInformation?.userRoles;
  let rndPermission: boolean = false;

  let canViewLogs: boolean = false;
  let campRnd: boolean = false;

  store.getState().auth.user?.roles.forEach((item: Types.Role) => {
    if (item.roleName === "CampRnd") {
      campRnd = true;
    }
    if (item.roleName === "AuditAdmin") {
      canViewLogs = true;
    }
  });

  if (!roleLoading) {
    if (roles != null && roles.includes("rnd")) {
      rndPermission = true;
    }
  }
  if (roleError) {
    rndPermission = false;
  }

  if (loading) return <CircularProgress color="secondary" />;

  const productInstance = data?.productInstance || null;
  const userInfo = data?.productInstance?.owner?.userInformation || null;
  const { perlaInfo, silkData, prodInfo } = resolveInstanceInfo(data);

  const productInformation =
    data?.productInstance?.product?.productInformation || null;

  const serialNumber = data?.productInstance?.serialNumber ?? "unknown";

  let views;
  if (productInstance) {
    if (perlaInfo) {
      if (userInfo) {
        views = (
          <PerlaViews
            productCode={productCode}
            userInfo={userInfo}
            productInstance={productInstance}
            perlaInfo={perlaInfo}
            rndPermission={rndPermission}
            canViewLogs={canViewLogs}
            campRnd={campRnd}
          />
        );
      } else {
        views = (
          <UnregisteredPerlaViews
            productCode={productCode}
            productInstance={productInstance}
            perlaInfo={perlaInfo}
            rndPermission={rndPermission}
            campRnd={campRnd}
          />
        );
      }
    } else if (silkData && userInfo) {
      views = (
        <SilkViews
          productCode={productCode}
          userInfo={userInfo}
          productInstance={productInstance}
          silkData={silkData}
        />
      );
    } else if (prodInfo && userInfo) {
      views = (
        <ProductViews
          userInfo={userInfo}
          productInstance={productInstance}
          campRnd={campRnd}
        />
      );
    } else {
      views = (
        <NewProductCode productInstance={productInstance} campRnd={campRnd} />
      );
    }
  }

  return (
    <Box minWidth={1050}>
      <PageHeader
        title={productInformation?.name ?? ""}
        subTitle={`(${productInstance?.productCode} / ${serialNumber})`}
        showBack
        colour={colours.blue}
      />
      {deactivatedProductCode(productInstance?.batchDeactivated)}
      <Box padding={2}>{views}</Box>
      {error && (
        <Box padding={2}>
          {
            <Fragment>
              <div>
                <Translate id="message.error.deviceInformation" />
              </div>
              <div>{error.message}</div>
            </Fragment>
          }
        </Box>
      )}
      {data?.productInstance === null ? (
        <Box padding={2}>
          <Translate id="message.error.noInstanceInformation" />
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

const NewProductCode: React.FC<{
  productInstance: ProductInstance;
  campRnd: boolean;
}> = ({ productInstance, campRnd }) => (
  <Fragment>
    <div style={{ maxWidth: "1700px", position: "relative" }}>
      <Grid
        container
        spacing={2}
        style={{
          maxWidth: "1700px",
          minWidth: "1075px",
          margin: "0px",
          width: "100%",
        }}
      >
        <Grid item xs={8}>
          <Col>
            {[
              <DeviceData
                key={productInstance.id}
                productInstance={productInstance}
                campRnd={campRnd}
              />,
            ]}
          </Col>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </div>
  </Fragment>
);

const PerlaViews: React.FC<{
  productCode: string;
  userInfo: UserInfo;
  productInstance: ProductInstance;
  perlaInfo: PerlaInstanceInfo;
  rndPermission: boolean;

  canViewLogs: boolean;
  campRnd: boolean;
}> = ({
  productCode,
  userInfo,
  productInstance,
  perlaInfo,
  rndPermission,
  canViewLogs,
  campRnd,
}) => (
  <Fragment>
    <div style={{ maxWidth: "1700px", position: "relative" }}>
      <DeviceStatus
        unconfirmedNotifs={perlaInfo?.unconfirmedNotifications ?? []}
        stateTitle={
          perlaInfo.registeredPerlaInstanceInformation?.stateTitle || null
        }
      />
      <Grid
        container
        spacing={2}
        style={{
          maxWidth: "1700px",
          minWidth: "1075px",
          margin: "0px",
          width: "100%",
        }}
      >
        <Grid item xs={5}>
          <Col>
            <CustomerDetails
              userInfo={userInfo}
              productInstance={productInstance}
            />
            <Telemetry productCode={productCode} />
            <NotificationsHistory productCode={productCode} />
          </Col>
        </Grid>
        <Grid item xs={7}>
          {rndPermission ? (
            <Col>
              <DeviceData
                productInstance={productInstance}
                rndPermission={rndPermission}
                campRnd={campRnd}
              />
              <DeviceSummary
                productCode={productCode}
                articleNumber={
                  productInstance.product?.productInformation?.itemNumber ?? ""
                }
              />
              <BarChart
                isSilk={false}
                productCode={productCode}
                installationDate={productInstance?.installationDate}
              />
              <Regeneration
                productCode={productCode}
                location={productInstance?.address?.location}
              />
              <Connectivity productCode={productCode} />
              <ParameterRnD productInstance={perlaInfo} />
              <AuditLog
                productCode={productCode}
                productInstance={productInstance}
                partnerDetail={null}
                canViewLogDetail={canViewLogs}
                customerId={null}
                gridSize={12}
                productId={null}
              />
            </Col>
          ) : (
            <Col>
              <DeviceData
                productInstance={productInstance}
                rndPermission={rndPermission}
                campRnd={campRnd}
              />
              <DeviceSummary
                productCode={productCode}
                articleNumber={
                  productInstance.product?.productInformation?.itemNumber ?? ""
                }
              />
              <BarChart
                isSilk={false}
                productCode={productCode}
                installationDate={productInstance?.installationDate}
              />
              <Regeneration
                productCode={productCode}
                location={productInstance?.address?.location}
              />
              <Connectivity productCode={productCode} />
              <AuditLog
                productCode={productCode}
                productInstance={productInstance}
                partnerDetail={null}
                canViewLogDetail={canViewLogs}
                customerId={null}
                gridSize={12}
                productId={null}
              />
            </Col>
          )}
        </Grid>
      </Grid>
    </div>
  </Fragment>
);

const SilkViews: React.FC<{
  productCode: string;
  userInfo: UserInfo;
  productInstance: ProductInstance;
  silkData: SilkInstanceInformation;
}> = ({ productCode, userInfo, productInstance, silkData }) => (
  <Fragment>
    <div style={{ maxWidth: "1700px", position: "relative" }}>
      <DisplayStatus code={silkData?.state ?? null} />
      <Grid
        container
        spacing={2}
        style={{
          maxWidth: "1700px",
          minWidth: "1075px",
          margin: "0px",
          width: "100%",
        }}
      >
        <Grid item xs={4}>
          <Col>
            <CustomerDetails
              userInfo={userInfo}
              productInstance={productInstance}
            />
            <SilkTelemetry silkData={silkData} />
            <SilkNotifs silkData={silkData} productCode={productCode} />
          </Col>
        </Grid>
        <Grid item xs={8}>
          <Col>
            <SilkDeviceData productInstance={productInstance} />
            <SilkSummary silkData={silkData} />
            <BarChart
              productCode={productCode}
              isSilk={true}
              installationDate={productInstance?.installationDate}
            />
            <SilkRegeneration productCode={productCode} silkData={silkData} />
          </Col>
        </Grid>
      </Grid>
    </div>
  </Fragment>
);

const UnregisteredPerlaViews: React.FC<{
  productCode: string;
  productInstance: ProductInstance;
  perlaInfo: PerlaInstanceInfo;
  rndPermission: boolean;
  campRnd: boolean;
}> = ({ productCode, productInstance, perlaInfo, rndPermission, campRnd }) => (
  <Fragment>
    <Grid
      container
      spacing={2}
      style={{
        maxWidth: "1700px",
        minWidth: "1075px",
        margin: "0px",
        width: "100%",
      }}
    >
      <Grid item xs={4}>
        <Col>{[<NotificationsHistory productCode={productCode} />]}</Col>
      </Grid>
      <Grid item xs={8}>
        {/* RND VISIBLE ONLY */}
        {rndPermission === true ? (
          <Col>
            <DeviceData
              productInstance={productInstance}
              rndPermission={rndPermission}
              campRnd={campRnd}
            />
            <ParameterRnD productInstance={perlaInfo} />
          </Col>
        ) : (
          <Col>
            {[
              <DeviceData
                productInstance={productInstance}
                rndPermission={rndPermission}
                campRnd={campRnd}
              />,
            ]}
          </Col>
        )}
      </Grid>
    </Grid>
  </Fragment>
);

const ProductViews: React.FC<{
  userInfo: UserInfo;
  productInstance: ProductInstance;
  campRnd: boolean;
}> = ({ userInfo, productInstance, campRnd }) => (
  <Fragment>
    <Grid
      container
      spacing={2}
      style={{
        maxWidth: "1700px",
        minWidth: "1075px",
        margin: "0px",
        width: "100%",
      }}
    >
      <Grid item xs={4}>
        <Col>
          {[
            <CustomerDetails
              key={userInfo.id}
              userInfo={userInfo}
              productInstance={productInstance}
            />,
          ]}
        </Col>
      </Grid>
      <Grid item xs={8}>
        <Col>
          {[
            <DeviceData
              key={userInfo.id}
              productInstance={productInstance}
              campRnd={campRnd}
            />,
          ]}
        </Col>
      </Grid>
    </Grid>
  </Fragment>
);

const Col: React.FC<{ children: React.ReactElement[] }> = ({ children }) => (
  <Grid container spacing={3}>
    {children.map((child, idx) => (
      <Grid key={idx} item xs={12}>
        <Box border={1} borderColor={colours.grey50} padding={2}>
          {child}
        </Box>
      </Grid>
    ))}
  </Grid>
);

export default CustomerPage;
