import gql from "graphql-tag";

const USER_LANGUAGE = gql`
query userLanguage  {
  user {
    userInformation {
      preferredLanguage
    }
  }
}
`;

export default USER_LANGUAGE;