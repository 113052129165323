import { Error } from 'types/CampGuideTypes';

export enum ActionType {
  OPEN = 'CHANGE_LOGIN_OPEN',
  CLOSE = 'CHANGE_LOGIN_CLOSE',
  LOADING = 'CHANGE_LOGIN_LOADING',
  ERROR = 'CHANGE_LOGIN_ERROR',
}

export interface ChangeData {
  userId: string,
  email: string,
  password: string
}

export interface Store {
  isLocked: boolean,
  changeData: ChangeData | null,
  error: Error | null
}


export interface ErrorAction {
  type: ActionType.ERROR,
  payload: Error
}

export interface OpenAction {
  type: ActionType.OPEN,
  payload: ChangeData
}

export interface CloseAction {
  type: ActionType.CLOSE,
}

export interface LoadingAction {
  type: ActionType.LOADING,
}

export type Action = ErrorAction | OpenAction | CloseAction | LoadingAction;
