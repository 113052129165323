import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import { AppState } from "redux/store";
import campManfred from "resources/camp-manfred_v2.jpg";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    card: {
      zIndex: 100,
      margin: "250px auto",
      textAlign: "center",
      padding: 20,
    },
    content: {
      padding: theme.spacing(3),
    },
    heroImage: {
      position: "absolute",
      top: 0,
      left: 0,
      maxWidth: "100%",
    },
  });
});

const HomePage: React.FC<{
  hasUnauthorisedLogin: boolean;
  authHasExpired: boolean;
}> = ({ hasUnauthorisedLogin, authHasExpired }) => {
  const styles = useStyles();

  return (
    <Fragment>
      {hasUnauthorisedLogin && (
        <Card className={styles.card}>
          <Translate id="pages.home.unauthorisedMessage" />
        </Card>
      )}
      {authHasExpired && (
        <Card className={styles.card}>
          <Translate id="message.error.logBackIn" />
        </Card>
      )}
      <img className={styles.heroImage} src={campManfred} alt="heroImage" />
    </Fragment>
  );
};

export default connect((state: AppState) => ({
  hasUnauthorisedLogin: state.auth.hasUnauthorisedLogin,
  authHasExpired: state.auth.authHasExpired,
}))(HomePage);
