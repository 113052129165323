import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { Action } from "redux";
import { connect } from "react-redux";

import { ThunkDispatch } from "redux-thunk";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { AppState } from "redux/store";
import { loadUser } from "redux/actions/AuthActions";

import AccessAppRoute from "components/common/AccessAppRoute";

import PageNotFound from "Pages/PageNotFound";

import HomePage from "Pages/Home";

import ProductsList from "Pages/Product";
import ConsumableList from "Pages/Consumable";

import RegistrationCodes from "Pages/Production/RegistrationCodes";

import PamPartnerList from "Pages/PartnerManager/Companies";
import PamPartnerDetails from "Pages/PartnerManager/Companies/Detail";
import PamEmployeeList from "Pages/PartnerManager/Employees";
import PamEmployeeDetail from "Pages/PartnerManager/Employees/Detail";
import PamPearls from "Pages/PartnerManager/Pearls";

import CustomerOverview from "Pages/Customer/Customer";
import DevicesOverview from "Pages/Customer/Device";
import InstanceDetails from "Pages/Customer/InstanceDetails";
import ServicePartner from "Pages/Customer/ServicePartner";
import CustomerDetails from "Pages/Customer/CustomerDetails";

import Admin from "Pages/Admin";
import SendgridOverview from "Pages/Sendgrid/TemplateGroupsOverview";
import SendgridDetail from "Pages/Sendgrid/TemplateGroupDetails";

import Promotion from "Pages/Promotion";
import PromotionDetails from "Pages/Promotion/Details";
import CouponCodeDetails from "Pages/Promotion/Details/CouponCode";
import ProductDetails from "Pages/Product/Detail";
import OrgCreationApprovalPage from "Pages/PartnerManager/Companies/OrgCreationApprovalPage/ApprovalPage";
import OrgJoinApprovalPage from "Pages/PartnerManager/Companies/OrgJoinApprovalPage/ApprovalPage";

import JoinRequestGrid from "Pages/Request/Join";
import CreateRequestGrid from "Pages/Request/Create";

const useStylesTmpPages = makeStyles((theme: Theme) => {
  return createStyles({
    content: {
      padding: theme.spacing(3),
    },
  });
});

const RND: React.FC = () => (
  <div className={useStylesTmpPages().content}>R&amp;D</div>
);

const App: React.FC = () => (
  <Switch>
    <Redirect from="/pam/request/:id" to="/pam/creation/request/:id" />

    <AccessAppRoute
      exact
      path="/"
      component={
        !sessionStorage.redirectUrl
          ? HomePage
          : () => {
              const url = sessionStorage.redirectUrl;
              sessionStorage.removeItem("redirectUrl");
              return <Redirect to={url} />;
            }
      }
    />

    <AccessAppRoute
      exact
      path="/pam"
      component={() => <Redirect to="/pam/partner" />}
    />

    <AccessAppRoute
      exact
      path="/pam/partner/:id"
      component={PamPartnerDetails}
      can={{ i: "read", a: "Partner" }}
    />
    <AccessAppRoute
      exact
      path="/pam/partner/NEW/:ownerId"
      component={PamPartnerDetails}
      can={{ i: "read", a: "Partner" }}
    />
    <AccessAppRoute
      exact
      path="/pam/partner"
      component={PamPartnerList}
      can={{ i: "read", a: "Partner" }}
    />

    <AccessAppRoute
      exact
      path="/pam/employee/:id"
      component={PamEmployeeDetail}
      can={{ i: "read", a: "Partner" }}
    />
    <AccessAppRoute
      exact
      path="/pam/employee"
      component={PamEmployeeList}
      can={{ i: "read", a: "Partner" }}
    />
    <AccessAppRoute
      exact
      path="/pam/pearls"
      component={PamPearls}
      can={{ i: "read", a: "Partner" }}
    />

    <AccessAppRoute
      exact
      path="/pam/creation/request"
      component={OrgCreationApprovalPage}
      can={{ i: "read", a: "Partner" }}
    />
    <AccessAppRoute
      exact
      path="/pam/creation/request/:id"
      component={OrgCreationApprovalPage}
      can={{ i: "read", a: "Partner" }}
    />

    <AccessAppRoute
      exact
      path="/pam/join/request"
      component={OrgJoinApprovalPage}
      can={{ i: "read", a: "Partner" }}
    />
    <AccessAppRoute
      exact
      path="/pam/join/request/:id"
      component={OrgJoinApprovalPage}
      can={{ i: "read", a: "Partner" }}
    />

    <AccessAppRoute
      exact
      path="/request"
      component={() => <Redirect to="/request/join" />}
    />

    <AccessAppRoute
      exact
      path="/request/join"
      component={JoinRequestGrid}
      can={{ i: "read", a: "Partner" }}
    />
    <AccessAppRoute
      exact
      path="/request/create"
      component={CreateRequestGrid}
      can={{ i: "read", a: "Partner" }}
    />

    <AccessAppRoute
      exact
      path="/product"
      component={ProductsList}
      can={{ i: "read", a: "Product" }}
    />
    <AccessAppRoute
      exact
      path="/product/:id"
      component={ProductDetails}
      can={{ i: "read", a: "Product" }}
    />

    <AccessAppRoute
      exact
      path="/consumable"
      component={ConsumableList}
      can={{ i: "read", a: "Product" }}
    />

    <AccessAppRoute
      exact
      path="/cs"
      component={() => <Redirect to="/cs/device" />}
    />
    <AccessAppRoute
      exact
      path="/cs/device"
      component={DevicesOverview}
      can={{ i: "read", a: "Customer" }}
    />
    <AccessAppRoute
      exact
      path="/cs/partner/:partnerId"
      component={ServicePartner}
      can={{ i: "read", a: "Customer" }}
    />
    <AccessAppRoute
      exact
      path="/cs/device/:productCode"
      component={InstanceDetails}
      can={{ i: "read", a: "Customer" }}
    />
    <AccessAppRoute
      exact
      path="/cs/customer"
      component={CustomerOverview}
      can={{ i: "read", a: "Customer" }}
    />
    <AccessAppRoute
      exact
      path="/cs/customer/:customerId"
      component={CustomerDetails}
      can={{ i: "read", a: "Customer" }}
    />

    <AccessAppRoute
      exact
      path="/rnd"
      component={RND}
      can={{ i: "read", a: "RND" }}
    />

    <AccessAppRoute
      exact
      path="/admin"
      component={Admin}
      can={{ i: "read", a: "Admin" }}
    />

    <AccessAppRoute
      exact
      path="/sendgrid/templateGroupsOverview"
      component={SendgridOverview}
      can={{ i: "read", a: "SendGrid" }}
    />
    <AccessAppRoute
      exact
      path="/sendgrid/templateGroupDetails/:id"
      component={SendgridDetail}
      can={{ i: "read", a: "SendGrid" }}
    />

    <AccessAppRoute
      exact
      path="/promotion"
      component={Promotion}
      can={{ i: "read", a: "Promotion" }}
    />
    <AccessAppRoute
      exact
      path="/promotion/:id"
      component={PromotionDetails}
      can={{ i: "read", a: "Promotion" }}
    />
    <AccessAppRoute
      exact
      path="/promotion/couponcode/:id"
      component={CouponCodeDetails}
      can={{ i: "read", a: "Promotion" }}
    />

    <AccessAppRoute
      exact
      path="/production"
      component={() => <Redirect to="/production/registrationCodes" />}
    />
    <AccessAppRoute
      exact
      path="/production/registrationCodes"
      component={RegistrationCodes}
      can={{ i: "read", a: "Production" }}
    />

    <AccessAppRoute component={PageNotFound} />
  </Switch>
);

const mapReduxStateToProps = (state: AppState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  loadAccount: () => {
    dispatch<any>(loadUser());
  },
});

const WrappedApp = connect(mapReduxStateToProps, mapDispatchToProps)(App);

export default WrappedApp;
