import React from "react";
import differenceInDays from "date-fns/differenceInDays";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import isSameDay from "date-fns/isSameDay";
import endOfDay from "date-fns/endOfDay";
import subMinutes from "date-fns/subMinutes";

import WeeklyChart from "./WeeklyChart";
import MonthlyChart from "./MonthlyChart";
import DailyChart from "./DailyChart";
import { Page } from "./index";

export const fillEmptyWaterConsumptionDataWithZeros = (
  start: Date,
  until: Date,
  DailyWaterConsumptions: { loggedAt: Date; water: number }[],
  unit: string
) => {
  const daysBetween = eachDayOfInterval({
    start,
    end: until,
  });

  const consumptionsArr = Object.values(DailyWaterConsumptions || {});

  return daysBetween.map((targetDate) => {
    const day = consumptionsArr.find(({ loggedAt }) =>
      isSameDay(targetDate, loggedAt)
    );
    const dayValue = day
      ? unit === "litres"
        ? day.water > 50000
          ? 0
          : day.water
        : day.water > 50
        ? 0
        : day.water
      : 0;
    return {
      consumption: dayValue,
      loggedAt: targetDate,
    };
  });
};

export const fillEmptySaltConsumptionDataWithZeros = (
  start: Date,
  until: Date,
  data: any[]
) => {
  const daysBetween = eachDayOfInterval({
    start,
    end: until,
  });

  const consumptionsArr = Object.values(data || {});

  return daysBetween.map((targetDate) => {
    const day = consumptionsArr.find(({ loggedAt }) =>
      isSameDay(targetDate, loggedAt)
    );
    return { consumption: day ? day.salt : 0, loggedAt: targetDate };
  });
};

const ChartPager = ({
  useLitres,
  useSalt,
  start,
  until,
  setDates,
  page,
  pages,
  setPage,
  water,
  salt,
  loading,
  unit,
}: {
  useLitres: boolean;
  useSalt: boolean;
  start: Date;
  until: Date;
  setDates: React.Dispatch<
    React.SetStateAction<{
      start: Date;
      end: Date;
    }>
  >;
  page: number;
  pages: Page[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  water: { loggedAt: any; water: number }[];
  salt: { loggedAt: any; salt: number }[];
  loading: boolean;
  unit: string;
}) => {
  //use dates to get water and salt
  const getAggregation = (days: number) => {
    if (days > 57) return "month";
    if (days > 32) return "week";
    return "day";
  };

  const dayDifference = differenceInDays(until, start);
  const aggregationType = getAggregation(dayDifference);

  const goToPreviousWeek = () => {
    setPage(page - 1);
    setDates({
      start: pages[page - 1].start,
      end: subMinutes(
        endOfDay(pages[page - 1].until),
        pages[page - 1].until.getTimezoneOffset()
      ),
    });
  };

  const goToFollowingWeek = () => {
    setPage(page + 1);
    setDates({
      start: pages[page + 1].start,
      end: subMinutes(
        endOfDay(pages[page + 1].until),
        pages[page + 1].until.getTimezoneOffset()
      ),
    });
  };

  const rightButtonEnabled = page < pages.length - 1;
  const leftButtonEnabled = page > 0;

  if (!pages[page]) return <div></div>;

  return (
    <div>
      {aggregationType === "day" ? (
        <DailyChart
          start={pages[page].start}
          until={pages[page].until}
          rightButtonEnabled={!loading && rightButtonEnabled}
          leftButtonEnabled={!loading && leftButtonEnabled}
          goToPreviousWeek={goToPreviousWeek}
          goToFollowingWeek={goToFollowingWeek}
          page={page}
          setPage={setPage}
          useLitres={useLitres}
          useSalt={useSalt}
          salt={salt}
          water={water}
          unit={unit}
        />
      ) : aggregationType === "week" ? (
        <WeeklyChart
          start={pages[page].start}
          until={pages[page].until}
          rightButtonEnabled={!loading && rightButtonEnabled}
          leftButtonEnabled={!loading && leftButtonEnabled}
          goToPreviousWeek={goToPreviousWeek}
          goToFollowingWeek={goToFollowingWeek}
          page={page}
          setPage={setPage}
          useLitres={useLitres}
          useSalt={useSalt}
          salt={salt}
          water={water}
          unit={unit}
        />
      ) : (
        <MonthlyChart
          start={pages[page].start}
          until={pages[page].until}
          rightButtonEnabled={!loading && rightButtonEnabled}
          leftButtonEnabled={!loading && leftButtonEnabled}
          goToPreviousWeek={goToPreviousWeek}
          goToFollowingWeek={goToFollowingWeek}
          page={page}
          setPage={setPage}
          useLitres={useLitres}
          useSalt={useSalt}
          salt={salt}
          water={water}
          unit={unit}
        />
      )}
    </div>
  );
};

export default ChartPager;
