/*
 * CampQueries
 * Entities which can be loaded with GraphQL
 */
export interface EnabledCountries {
  countries: Country[]
}

export interface UserProfile {
  userInformation: UserInformation
  roles: Role[]
  allowedCountries: Country[]
}

// ToDo: Missing - Avaialable ProductGroups 
export interface ProductGroups {
  productGroups: ProductGroup[]
}




/*
 * Partner
 */
export interface Organization {
  id: number
  name: string
  email: string
  telephone: string
  website: string
  address: Address
  logoBase64: string
  logoUrl: string
  partnerImageBase64: string
  partnerImageUrl: string
  partnerType: PartnerType
  visibility: OrganizationVisibility
  employees: Paging<Employee>
  groups: partnerGroup[]
  bonusaccount: Bonusaccount
  pearlTransactions: Paging<PearlTransaction>
  productInstances: Paging<ProductInstance>
}


export interface partnerGroup {
  id?: number
  name?: string
}

export interface Employee {
  userInformation: UserInformation
  organization: Organization
  position: Position
}

export interface PearlTransaction {
  id: number
  date: number
  amount: number
  note: string
  organization: Organization
}

export interface Bonusaccount {
  pearls: number
}


export enum Position {
  ASSISTANT = "ASSISTANT",
  ENGINEER = "ENGINEER",
  NONE = "NONE",
  OWNER = "OWNER",
}

export enum PartnerType {
  TWP = 'TWP',
  BUILDER = 'BUILDER',
  OPERATOR = 'OPERATOR',
  PLANNER = "PLANNER",
  WHOLESALER = "WHOLESALER",
  PROFESSIONAL = "PROFESSIONAL"
}

export enum OrganizationVisibility {
  CONTACT_ONLY = "CONTACT_ONLY",
  HIDDEN = "HIDDEN",
  PROMOTED = "PROMOTED",
  VISIBLE = "VISIBLE",
}

export enum OrganizationFilterOption {
  ID = 'ID',
  NAME = 'NAME',
  PARTNER_TYPE = 'PARTNER_TYPE',
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  EMAIL = 'EMAIL',
  VAT_NUMBER = 'VAT_NUMBER'
}



/*
 * Product
 */
export interface Product {
  productInformation: ProductInformation
  consumables: Consumable[]
  notifications: Notification[]
}

export interface ProductInformation {
  itemNumber: number
  gTIN: string
  name: string
  pearls: number
  productType: string // ToDo: ENUM
  productGroup: ProductGroup
  availableCountries: Country[]
}

export interface ProductGroup {
  name: string
}

export interface Consumable {
  isFreeConsumable: boolean
  mailTemplate: MailTemplate
  maxShipments: number
  numberOfMonths: number
  productInformation: ProductInformation
  threshold: number
}

export interface Notification {
  mailTemplate: MailTemplate
  trigger: string
}


/*
 * Promotion
 */
export enum PromotionType {
  Assignable = "Assignable",
  DedicatedUsage = "DedicatedUsage"

};

export interface Promotion {
  id: number
  articleCategory: string
  name: string
  emailTemplate: string
  crDate: number
  type: PromotionType
}

export interface PromotionDetails {
  id: number
  articleCategory: string
  name: string
  emailTemplate: string
  crDate: number
  type: PromotionType
}

export interface Couponcode {
  itemNumber: number
  name: string
  crDate: number
  assignedProductCode: number
  csDate: number
  validFrom: number
  validTo: number
  active: boolean
}

export interface PearlsTransaction {
  id: string
  pearls: number
  note: string
  orgId: number
}


/*
 * Product - Instances
 */
export interface ProductInstance {
  productCode: string
  productName: string
  serialNumber: string
  owner: UserInformation
  address: Address
  instanceInformation: ProductInstanceInformation | DeviceInstanceInformation // ToDo: ProductInstInfo | PerlaSmartInfo | PerlaSilkInfo
  dataSharedWithPartner: boolean
  servicePartner: Organization
  customerSelectedInstaller: Organization
}

export interface DeviceInstanceInformation {
  firmware: string
  installationDate: number
  nextCustomerMaintainance: number
  nextServiceDate: number
  registrationDate: number
  wPA2Key: string
}

export interface ProductInstanceInformation {
  placeholder: string
}







/*
 * User / Account
 */
export interface UserInformation { //status = kombination aus locked, verified
  id: string
  firstName: string
  lastName: string
  address: Address
  email: string
  key: number
  preferredLanguage: string
  acceptedTerms: boolean
  accountLocked: boolean
  allowedToLogin: boolean
  dataUpdate: number
  generatedAccount: boolean // ToDo: Missing in GraphQL
  incorrectLogins: number
  lastIncorrectLogin: number
  lastLogin: number
  lastPasswordReset: number // ToDo: Missing in GraphQL
  registeredOn: number
  verifiedAccount: boolean
  userRoles: string[]
  acceptedMarketingUsage: false
}

export interface Role {
  permissions: string[]
  roleName: string
}



/*
 * Admin
 */



/*
 * Generics
 */
export interface Address {
  id: number
  street: string
  postalCode: string
  city: string
  countryCode: Country
  location?: GeoLocation
}

export enum Country {
  AT = 'AT',
  DE = 'DE',
  FR = 'FR',
  BE = 'BE'
}

export interface GeoLocation {
  latitude: number
  longitude: number
}

export interface CountryAccess {
  allowedCountries: Country[]
}

export interface MailTemplate {
  id: string
  name: string
}

export interface Paging<DataType> {
  page: number
  pageSize: number
  totalResults: number
  results: DataType[]
}

export interface DefaultGqlArguments<Fields> {
  page: number
  pageSize: number
  filter?: FieldFilter<Fields>[]
  sorting?: Sorting<Fields>[]
}

export interface FieldFilter<Fields> {
  field: Fields
  operator: FilterOperator
  value: string
}

export enum FilterOperator {
  LT = 'LT',
  GT = 'GT',
  EQ = 'EQ',
  NEQ = 'NEQ',
  LIKE = 'LIKE'
}

export interface Sorting<Fields> {
  field: Fields
  direction: "asc" | "desc"
}
