import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { withLocalize, LocalizeContextProps, TranslateFunction, Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { ColDef } from "@ag-grid-enterprise/all-modules";
import { useMutation } from "@apollo/react-hooks";

import { CircularProgress, makeStyles, createStyles, Theme } from "@material-ui/core";
import { Check, Close, DeleteForever, Save } from "@material-ui/icons";

import cloneDeep from 'clone-deep';

import { ReduxAgGrid } from "components/common/Grid/AgGrid";
import { AppState } from "redux/store";
import { State as ConsumableGridState } from "redux/types/ConsumableGrid";
import { setGridState, setColumnStates, } from "redux/actions/ConsumableGridActions";
import { consumableGrid, consumableGrid_consumableProducts } from "types/apolloGenerated/consumableGrid";
import { updateConsumable as update, updateConsumableVariables } from "types/apolloGenerated/updateConsumable"
import { deleteConsumable as deleteC, deleteConsumableVariables } from "types/apolloGenerated/deleteConsumable"
import PageDialog from "components/common/PageDialog"

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import { CellRenderer } from "Pages/Promotion/Details/GridConfig";
import { Product } from "types/ToolboxEntities";

export const CONSUMABLES = gql`
  query consumableGrid {
    consumableProducts {
      id
      isActive
      itemNumber
      name
      pearlsRewarded
      products {
        productInformation {
          id
          itemNumber
        }
      }
    }
  }
`;

const UPDATE_CONSUMABLE_PRODUCT = gql`
  mutation updateConsumable($consumableProduct: ConsumableProductUpdate!){
    consumableProduct {
      update(consumableProduct: $consumableProduct){
        id
        name
      }
    }
  }
`

const DELETE_CONSUMABLE_PRODUCT = gql`
  mutation deleteConsumable($id: Int!){
    consumableProduct {
      delete(id: $id)
    }
  }
`

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    // consumableGrid:{
    //   height: "100%"
    // }
    consumableGrid: {
      position: "relative",
      height: "100%",
      display: 'flex',
      flexFlow: 'column',
      overflow: 'hidden',

      '& > *': {
        flex: '1 1 auto',
      },
      '& a': {
        color: '#005D8F'
      },
      '& > .grie-error': {
        flex: '0 1 auto',
      },
      '& .ag-paging-panel.ag-hidden': {
        display: 'block !important',
        opacity: 0,
        pointerEvents: 'none',
      },
      '& .ag-cell.number-cell': {
        textAlign: 'right',
        paddingRight: '40px',
      },
      '& div[col-id="id_1"] svg': {
        marginTop: '2px',
        height: '22px'
      },
    },
  });
});

export const getColumnDefs = (translate: TranslateFunction): ColDef[] => {
  const columnDefs = [
    {
      headerName: translate("generics.id") as string,
      field: "id",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 60
    },
    {
      headerName: translate("types.common.name") as string,
      field: "name",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 350,
      editable: true
    },
    {
      headerName: translate("pages.consumable.column.itemNumber") as string,
      field: "itemNumber",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 120,
      editable: true
    },
    {
      headerName: translate("types.common.active") as string,
      field: "isActive",
      sortable: true,
      filter: true,
      width: 80,
      cellRenderer: "BoolCheck",
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: [true, false],
        cellRenderer: 'BoolCheck',
      },
    },
    {
      headerName: translate("pages.consumable.column.pearlReward") as string,
      field: "pearlsRewarded",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 135,
      editable: true,
      valueParser: numberParser
    },
    {
      headerName: translate('types.common.products') as string,
      field: "products",
      sortable: true,
      cellRenderer: "ProductsCell",
      valueGetter: (params: any) => {
        let exportProducts = "";
        params.data.products.forEach((element: Product, index: number) => {
          if (index) {
            exportProducts = exportProducts + ", " + element.productInformation.itemNumber;
          } else {
            exportProducts = exportProducts + element.productInformation.itemNumber;
          }
        });
        return exportProducts;
      },
      filter: "agTextColumnFilter",
      width: 250,
    }
  ];
  return columnDefs;
};

function numberParser(params: any) {
  const asNumber = parseInt(params.newValue);
  if (isNaN(asNumber)) {
    return Number(params.oldValue)
  }
  return asNumber;
}

interface CellRenderer {
  value: number;
  data: consumableGrid_consumableProducts;
  mutation?: () => {}
}

const ProductsCell: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  if (data.products) {
    return (
      <div>
        {data.products.map((product, idx, arr) => {
          const prodInfo = product?.productInformation
          const gridValue = prodInfo?.itemNumber && prodInfo.itemNumber !== "" ? prodInfo.itemNumber : "DB-" + prodInfo?.id
          return (
            <Fragment key={idx}>
              <Link to={"product/" + product?.productInformation?.id}>{gridValue}</Link>{idx + 1 !== arr.length ? ", " : ""}
            </Fragment>
          )
        })}
      </div>)
  }
  return <div />
};

const BoolCheck: React.FC<CellRenderer> = ({ data, value }) => {
  return <div>{value ? <Check color={"primary"} /> : <Close color={"error"} />}</div>
}

interface ConsumableGridProps extends LocalizeContextProps {
  translate: TranslateFunction
  gridState: ConsumableGridState
  actions: {
    setGridState: typeof setGridState
    setColumnStates: typeof setColumnStates
  };
  search: string;
}

const ConsumableGrid: React.FC<ConsumableGridProps> = ({ translate, gridState, actions, search }) => {
  const classes = useStyles();
  const { data, loading } = useQuery<consumableGrid>(CONSUMABLES);
  const gridData = data && data?.consumableProducts ? data?.consumableProducts : [];

  if (loading) {
    return <CircularProgress color="secondary" />
  } else if (data) {
    return (
      <ReduxAgGrid
        className={classes.consumableGrid}
        columnDefs={getColumnDefs(translate)}
        rowData={gridData}
        frameworkComponents={{ ProductsCell, BoolCheck }}
        gridState={gridState.grid}
        gridActions={actions}
        pagination={false}
        quickFilterText={search}
      />
    );
  }

  return <Translate id="generics.noDataAvailable" />;
};


const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  gridState: appState.consumableGrid
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { setGridState, setColumnStates },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(ConsumableGrid));

