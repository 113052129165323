import React from 'react';
import { createStyles, Theme, Box, makeStyles } from '@material-ui/core';
import { Translate, LocalizeContextProps } from 'react-localize-redux';
import { useParams } from 'react-router';
import SetRequestId from './SetRequestId'
import ApprovalForm from './ApprovalForm'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: "30px",
      marginBottom: "50px",
      '& h1': {
        marginBottom: '20px',
      }
    },
    block: {
      display: "block",
      marginBottom: "20px"
    }
  }));

const ApprovalPage: React.FC<LocalizeContextProps> = ({ translate }) => {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <Box className={classes.container}>
      <h1><Translate id="pages.partnerRequest.orgJoinRequest" /></h1>
      { id ?
        <ApprovalForm requestId={id} /> :
        <SetRequestId />
      }
    </Box>
  )
};



export default ApprovalPage;