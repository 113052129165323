import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { IconButton, Menu, Fade, MenuItem } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as IconsUI from '@material-ui/icons';

import { AppState } from 'redux/store';
import { AuthStore } from 'redux/types/Auth';


interface IProfileMenu {
  auth: AuthStore
}

const ProfileMenu: React.FC<IProfileMenu> = ({ auth }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  if (auth.isLoading) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <Fragment>
      <IconButton color="inherit" edge="end" aria-controls="profile-menu" aria-haspopup="true" onClick={handleClick}>
        <IconsUI.AccountCircle />
      </IconButton>
      <Menu id="profile-menu" keepMounted TransitionComponent={Fade} open={open} onClose={handleClose} anchorEl={anchorEl} getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}>
        {auth.user || auth.hasUnauthorisedLogin
          ? <MenuItem><a href='/authentication/logout'>Logout</a></MenuItem>
          : <MenuItem><a onClick={() => sessionStorage.setItem("redirectUrl", window.location.pathname)} href='/authentication'>Login</a></MenuItem>
        }
      </Menu>
    </Fragment>
  );
};


const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(ProfileMenu);
