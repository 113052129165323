import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { Action, ActionType, PendingAction, SetChipsAction, ErrorAction } from 'redux/types/InputToChip';
import GraphQL from 'util/GraphQL';

import { Error } from 'types/CampGuideTypes';

export const setChips = (chips: string[]): SetChipsAction => ({
  type: ActionType.CHIPS,
  payload: chips
});

export const pendingChips = (): PendingAction => ({
  type: ActionType.PENDING
});


export const errorChips = (error: Error): ErrorAction => ({
  type: ActionType.ERROR,
  payload: error
});

export const loadChips = (): ThunkAction<Promise<void>, {}, {}, Action> => {
  return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {

    dispatch(pendingChips());

    GraphQL.avaiableEmployeeRoles.load().then((chips: string[]) => {
      dispatch(setChips(chips));
    }).catch((errorData: any) => {
      dispatch(errorChips(errorData));
    });
  };
};

export const saveChips = (chips: string[]): ThunkAction<Promise<void>, {}, {}, Action> => {
  return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {
    dispatch(pendingChips());

    GraphQL.avaiableEmployeeRoles.save(chips).then((data: any) => {
      dispatch(setChips(chips));
    }).catch((errorData: any) => {
      dispatch(errorChips(errorData));
    });
  };
};
