import React from "react";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Translate } from "react-localize-redux";
import { eachDayOfInterval, eachWeekOfInterval, startOfDay, subMonths, startOfMonth, format, subWeeks, subDays, addDays, subMinutes, startOfWeek, addWeeks  } from "date-fns"

import CircularProgress from '@material-ui/core/CircularProgress';

import CurveGraph from "./CurveGraph";
import {
  ConnectivityDataForGraph as ConnectivityQueryData,
  ConnectivityDataForGraphVariables as ConnectivityQueryVars
} from "types/apolloGenerated/ConnectivityDataForGraph";
import { DateAggregationType } from "types/apolloGenerated/globalTypes";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    header: {
      fontSize: "20px",
      fontWeight: "bold",
      height: "75px"
    },
    monthPicker: {
      position: "relative",
      left: "calc(100% - 120px)",
      top: "-22px"
    },
    selectRoot: {
      padding: "10px 40px 10px 10px"
    },
    legend: {
      width: "3%",
      height: "8px",
      borderRadius: "20px",
      margin: "40px 20px 5px 20px"
    }
  });
});

const QUERY = gql`
  query ConnectivityDataForGraph(
    $productCode: String!
    $from: DateTimeOffset!
    $to: DateTimeOffset!
    $aggregate: DateAggregationType!
  ) {
    productInstance(productCode: $productCode) {
      id
      instanceInformation {
        __typename
        ...on PerlaInstanceInformation {
          statistics(from: $from, to: $to, aggregate: $aggregate) {
            averageConnectedToClientLan
            averageConnectedToClientWlan
            averageGsmQuality
          }
        }
      }
    }
  }
`;

type line = {
  paddedPoints: number[]
  colour: string
}
export interface ConnectivityProps {
  productCode: string;
}

const now = new Date()

const Connectivity: React.FC<ConnectivityProps> = ({ productCode }) => {
  const classes = useStyles();
  const monthsInHalfYear = 6;
  const monthsInYear = 12;
  const daysInMonth = 30;
  const weeksInThreeMonths = 13;

  const [monthsSelected, setMonth] = React.useState(monthsInHalfYear.toString());
  const [first, setFirst] = React.useState(startOfMonth(subMonths(now, monthsInHalfYear)))
  const [points, setPoints] = React.useState(monthsInHalfYear + 1)
  const [aggregation, setAggregation] = React.useState(DateAggregationType.MONTH)

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setMonth((event.target as HTMLTextAreaElement).value);
    switch ((event.target as HTMLTextAreaElement).value) {
      case "1":
        setAggregation(DateAggregationType.DAY)
        setPoints(daysInMonth + 1)
        //one additional point because first point is off the SVG to get the curve this also means we need to back on additional day 
        setFirst(startOfDay(subDays(now, daysInMonth + 1)))
        break;
      case "3":
        setAggregation(DateAggregationType.WEEK)
        setPoints(weeksInThreeMonths + 1)
        setFirst(startOfWeek(subWeeks(now, weeksInThreeMonths), {weekStartsOn: 1}))
        break;
      case "6":
        setAggregation(DateAggregationType.MONTH)
        setPoints(monthsInHalfYear + 1)
        setFirst(startOfMonth(subMonths(now, monthsInHalfYear)))
        break;
      case "12":
        setAggregation(DateAggregationType.MONTH)
        setPoints(monthsInYear + 1)
        setFirst(startOfMonth(subMonths(now, monthsInYear)))
        break;
    }
  };

  const timeZoneOffset = first.getTimezoneOffset();
  const firstTz = subMinutes(first, timeZoneOffset);
  const { data, loading } = useQuery<ConnectivityQueryData, ConnectivityQueryVars>(
    QUERY,
    {
      variables: { productCode, from: firstTz, to: startOfDay(now), aggregate: aggregation }
    }
  );
  const info = data?.productInstance?.instanceInformation;

  let xAxisValues: any[] = [];
  let mockLines: line[] = [];

  if (aggregation === DateAggregationType.DAY) {
    const dateArray = eachDayOfInterval( {start: new Date(addDays(first, 1)), end: new Date(now)})
    for(let i = 0; i < dateArray.length; i++){
      xAxisValues.push(format(dateArray[i], "d"))
    }
  } 
  if (aggregation === DateAggregationType.WEEK) {
    //add an extra week so we don't get one too many because the svg requires one extra point
    const dateArray = eachWeekOfInterval( {start: new Date(addWeeks(first, 1)), end: new Date(now)}, {weekStartsOn: 1})
    for(let i = 0; i < dateArray.length; i++){
      xAxisValues.push(format(dateArray[i], "dd/MM"))
    }
  }
  else {
    for(let i = points - 2; i > -1; i--){
      xAxisValues.push(format(subMonths(now, i), "MMM"))
    }
  }
  
  if(!loading){
    if(info?.__typename === "PerlaInstanceInformation"){
        // create array of the regen data
      const lanArray = info?.statistics?.map(d =>
        d && d.averageConnectedToClientLan ? d.averageConnectedToClientLan : 0
        ) ?? [0];
        const wlanArray = info?.statistics?.map(d =>
          d && d.averageConnectedToClientWlan ? d.averageConnectedToClientWlan : 0
          ) ?? [0];
          const gsmArray = info?.statistics?.map(d =>
            d && d.averageGsmQuality ? d.averageGsmQuality : 0
            ) ?? [0];
            
            //  if missing data then add additional points
            while(lanArray.length !== points && lanArray.length < points){
              lanArray.unshift(0)
            }
            while(wlanArray.length !== points && wlanArray.length < points){
              wlanArray.unshift(0)
            }
            while(gsmArray.length !== points && gsmArray.length < points){
              gsmArray.unshift(0)
            }
            
            
            mockLines = [
              {
                paddedPoints: [
                  ...lanArray.slice(0),
                  lanArray[lanArray.length - 1]
                ],
                colour: "rgb(99, 134, 163)"
              },
              {
                paddedPoints: [
                  ...wlanArray.slice(0),
                  wlanArray[wlanArray.length - 1]
                ],
                colour: "rgb(244, 182, 201)"
              },
              {
                paddedPoints: [
                  ...gsmArray.slice(0),
                  gsmArray[gsmArray.length - 1]
                ],
                colour: "rgb(121, 193, 222)"
              }
            ];
          }
        }
        
        let total = 0
        mockLines.forEach(line => {
          line.paddedPoints.forEach(point =>{
            total += point;
          })
        });

        if (loading) return <div style={{display: "flex", placeContent: "center"}}><CircularProgress color="secondary" /> </div>;
        if (total === 0) return <div>< Translate id="message.error.No_Connectivity_data" /></div>


  return (
    <div>
      <div className={classes.header}>
        <Translate id="components.connectivity.title" />
      
      <div style={{float: "right", fontWeight: "normal"}}>
        <FormControl variant="outlined">
          <Select
            classes={{ root: classes.selectRoot }}
            value={monthsSelected}
            onChange={event => handleChange(event)}
          >
            <MenuItem value={"1"}>
              1 <Translate id="components.barChart.month" />
            </MenuItem>
            <MenuItem value={"3"}>
              3 <Translate id="components.barChart.months" />
            </MenuItem>
            <MenuItem value={"6"}>
              6 <Translate id="components.barChart.months" />
            </MenuItem>
            <MenuItem value={"12"}>
              12 <Translate id="components.barChart.months" />
            </MenuItem>
          </Select>
        </FormControl>
        </div>
      </div>
      {data && (
        <CurveGraph
          linesIn={mockLines}
          samplePoints={points - 1}
          xAxisValues={xAxisValues}
        />
      )}
      <div style={{ display: "flex" }}>
        <div
          style={{ backgroundColor: "rgb(99, 134, 163)" }}
          className={classes.legend}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end"
          }}
        >
          <div>LAN</div>
        </div>
        <div
          style={{ backgroundColor: "rgb(244, 182, 201)" }}
          className={classes.legend}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end"
          }}
        >
          <div>W-LAN</div>
        </div>
        <div
          style={{ backgroundColor: "rgb(121, 193, 222)" }}
          className={classes.legend}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end"
          }}
        >
          <div>GSM</div>
        </div>
      </div>
    </div>
  );
};

export default Connectivity;
