import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";

import { AppState } from "redux/store";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import * as materialIcons from "@material-ui/icons";

import { Theme, makeStyles, Grid } from "@material-ui/core";

import gql from "graphql-tag";
import {
  CreateTransaction,
  CreateTransactionVariables,
} from "types/apolloGenerated/CreateTransaction";

import { Store as ChangePearlsStore } from "redux/types/AccountShortcuts/ChangePearls";
import { close } from "redux/actions/AccountShortcuts/ChangePearlsActions";

import { Translate } from "react-localize-redux";

import Error from "components/common/Error";
import { useMutation } from "react-apollo";
import { ErrorCodes } from "types/CampGuideTypes";
import { QUERY_BONUS_ACCOUNT } from "Pages/PartnerManager/Companies/Detail/BonusAccount/GridConfig";
import { BonusAccountTransactions } from "types/apolloGenerated/BonusAccountTransactions";
import { AuthStore } from "redux/types/Auth";

const MUTATION_CREATE_TRANSACTION = gql`
  mutation CreateTransaction(
    $transactionInsert: BonusAccountTransactionInput!
  ) {
    organization {
      createTransaction(transactionInsert: $transactionInsert) {
        id
        amount
        conversionRate
        description
        validTo
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  alignRight: {
    textAlign: "right",
  },
  toggleGroup: {
    "& .Mui-selected, & .Mui-selected:hover": {
      backgroundColor: "#005D8F",
      color: "white",
    },
    marginRight: "10px",
  },
  input: {
    marginBottom: theme.spacing(1),
    "& .MuiInputBase-input": {
      padding: "11.5px 14px",
    },
  },
}));

interface IChangePearls {
  changePearls: ChangePearlsStore;
  onChangeFunc: (value: any, name: BonusAccountTransactions) => void;
  orgId: number;
  auth: AuthStore;
  actions: {
    close: typeof close;
  };
}

const ChangePearls: React.FC<IChangePearls> = ({
  changePearls,
  actions,
  onChangeFunc,
  auth,
  orgId,
}) => {
  const classes = useStyles();

  const [changedPearls, setChangedPearls] = React.useState(0);
  const [pearlsNote, setPearlsNote] = React.useState("");
  const changedPearlsThen = 0;
  const [isNegative, setIsNegative] = React.useState(false);

  const pearlsNow =
    changedPearlsThen !== 0
      ? changedPearlsThen
      : changePearls.pearlsObj?.pearls || 0;
  const pearlsThen = pearlsNow + changedPearls;

  useEffect(() => {
    let pearlsChanged = 0;
    if (changedPearls !== 0) {
      pearlsChanged = changedPearls;
    }

    if (!changePearls.pearlsObj) {
      pearlsChanged = 0;
    }

    setChangedPearls(pearlsChanged);
  }, [changePearls, changedPearls]);

  const [
    createTr,
    { loading: createTrLoading, error: createTrError },
  ] = useMutation<CreateTransaction, CreateTransactionVariables>(
    MUTATION_CREATE_TRANSACTION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: QUERY_BONUS_ACCOUNT, variables: { id: orgId } },
      ],
      onCompleted: () => {
        actions.close();
      },
    }
  );

  const submit = () => {
    if (changePearls.pearlsObj?.id) {
      setIsNegative(false);
      createTr({
        variables: {
          transactionInsert: {
            accountId: changePearls.pearlsObj.id + "",
            amount: changedPearls,
            description: pearlsNote || "",
          },
        },
      }).catch((error) => {
        console.log("Error while booking PEARLS: ", error);
      });
    }
  };

  const cancel = () => {
    setIsNegative(false);
    actions.close();
  };

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    const newChanged =
      newAlignment === "positive"
        ? Math.abs(changedPearls)
        : -Math.abs(changedPearls);
    if (newChanged !== changedPearls) {
      setChangedPearls(newChanged);
    }
  };

  const handlePearlsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "-") {
      setIsNegative(true);
    } else {
      let newChanged = Number(event.target.value);
      if (!newChanged) newChanged = 0;
      setChangedPearls(isNegative ? -Math.abs(newChanged) : newChanged);
    }
  };

  const errorElement = createTrError ? (
    <Error
      error={{ code: ErrorCodes.Unknown, message: createTrError.message }}
    />
  ) : null;
  const progress = createTrLoading ? (
    <CircularProgress color="secondary" />
  ) : null;

  const children = [
    <ToggleButton key="1" value="negative">
      <materialIcons.Remove />
    </ToggleButton>,
    <ToggleButton key="2" value="positive">
      <materialIcons.Add />
    </ToggleButton>,
  ];

  return (
    <Dialog
      open={Boolean(changePearls.pearlsObj)}
      onClose={actions.close}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Grid container>
        <Grid item xs={12}>
          <DialogTitle id="form-dialog-title">
            <Translate id="components.changePearls.title" />
          </DialogTitle>
        </Grid>
        <DialogContent>
          <Grid item xs={12}>
            {errorElement}
            <Grid container>
              <Grid item xs={7}>
                <Grid container>
                  <Grid item xs={6}>
                    <DialogContentText>
                      <Translate id="components.changePearls.pearlsNow" />
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={6} className={classes.alignRight}>
                    {pearlsNow} <Translate id="types.common.pearls" />
                  </Grid>
                </Grid>
                <ToggleButtonGroup
                  className={classes.toggleGroup}
                  size="small"
                  value={changedPearls < 0 ? "negative" : "positive"}
                  exclusive
                  onChange={handleAlignment}
                >
                  {children}
                </ToggleButtonGroup>
                <TextField
                  disabled={createTrLoading}
                  value={changedPearls === 0 ? "" : changedPearls}
                  onChange={handlePearlsChange}
                  className={classes.input}
                  variant="outlined"
                  type="text"
                  label={
                    <Translate id="components.changePearls.pearlsAmount" />
                  }
                  autoFocus
                />
                <Grid container>
                  <Grid item xs={6}>
                    <DialogContentText>
                      <Translate id="components.changePearls.pearlsThen" />
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={6} className={classes.alignRight}>
                    {pearlsThen} <Translate id="types.common.pearls" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={createTrLoading}
              defaultValue=""
              onChange={(event) => {
                setPearlsNote(event.target.value);
              }}
              className={classes.input}
              type="text"
              label={<Translate id="generics.note" />}
            />
          </Grid>
        </DialogContent>
        <Grid item xs={12}>
          <DialogActions>
            {progress}
            <Button onClick={cancel} color="primary" disabled={createTrLoading}>
              <Translate id="generics.cancel" />
            </Button>
            <Button
              onClick={submit}
              color="primary"
              disabled={createTrLoading || changedPearls === 0}
            >
              <Translate id="generics.saveNclose" />
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  changePearls: state.accountShortcuts.changePearls,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ close }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePearls);
