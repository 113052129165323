import React, { useState, useEffect, useCallback } from "react";
import { Map, GoogleApiWrapper, Marker, GoogleAPI, MapProps } from "google-maps-react";
import { Box, Theme, createStyles, Button, makeStyles, CircularProgress } from "@material-ui/core";
import cursor from "resources/maps-cursor.png";
import { GeoLocation } from "types/ToolboxEntities";
import { Country } from "types/apolloGenerated/globalTypes";
import { PartnerDetail_organization_address } from "types/apolloGenerated/PartnerDetail";
import { cancelablePromise } from "util/CampUtils";
import deepEqual from "deep-equal";
import { Translate } from "react-localize-redux";
//import { ActionVerifiedUser } from "material-ui/svg-icons";

interface MapData {
  isNewMode: boolean
  google: GoogleAPI
  address: PartnerDetail_organization_address | null
  onChange: (value: any) => void
};

interface MapLocationData {
  state: "loading" | "correct" | "locationNotFound" | "unknownError",
  location: google.maps.LatLngLiteral | undefined,
}

export type LocationInfo = {
  street?: string | null;
  postalCode?: string | null;
  city?: string | null;
  countryCode?: Country | null;
  location?: GeoLocation | null;
  [key: string]: any | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "google-maps-container": {
      position: "relative",
      paddingBottom: "75%",
      height: "0",
      width: "95%",
      marginLeft: "75px",
      "&:hover $cursor-img": {
        display: "block"
      }
    },
    "cursor-img": {
      zIndex: 999,
      height: "50px",
      width: "50px",
      top: "50%",
      left: "50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      pointerEvents: "none",
      display: "none"
    },
    "lat-lng-div": {
      position: "absolute",
      bottom: "-70px",
      "& div": {
        display: "inline",
        whiteSpace: "nowrap",
        marginRight: "10px"
      },
      "& button": {
        marginTop: "5px"
      }
    },
    bwtBtn: {
      backgroundColor: '#005D8F',
      color: '#fff',
      fontSize: '12px'
    }
  })
);

const convertLatLong = (locationInfo: GeoLocation | null | undefined): google.maps.LatLngLiteral | undefined => {
  if (locationInfo && locationInfo.latitude) {
    return {
      lat: Number(locationInfo.latitude.toFixed(4)),
      lng: Number(locationInfo.longitude.toFixed(4)),
    };
  }
  return undefined;
};

const convertGoogleLatLng = (locationInfo: google.maps.LatLngLiteral | null | undefined): any | undefined => {
  if (locationInfo && locationInfo.lat) {
    return {
      latitude: Number(locationInfo.lat.toFixed(4)),
      longitude: Number(locationInfo.lng.toFixed(4))
    };
  }
  return undefined;
};

const GoogleMapsMap: React.FunctionComponent<MapData> = ({ google: GoogleAPI, onChange, address, isNewMode }) => {
  const classes = useStyles();

  const positionDB = convertLatLong(address?.location);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [positionGeoCode, setPositionGeoCode] = useState<google.maps.LatLngLiteral | undefined>();
  const [positionCurrent, setPositionCurrent] = useState<google.maps.LatLngLiteral | undefined>(positionDB);


  if (!positionCurrent && positionGeoCode) {
    console.log("!positionCurrent && positionGeoCode");
    setPositionCurrent(positionGeoCode);

  }
  const changePosition = useCallback((newPosition: google.maps.LatLngLiteral | undefined) => {
    setPositionCurrent({ lat: Number(newPosition?.lat.toFixed(4)), lng: Number(newPosition?.lng.toFixed(4)) } as google.maps.LatLngLiteral);
    onChange(convertGoogleLatLng(newPosition));

  }, [onChange]);

  const [zoom, setZoom] = useState<number>(18);

  const addressSearchString = address?.street && address.postalCode
    ? ((address.street || "") + ", " + (address.postalCode || "") + " " + (address.city || "") + ", " + (address.countryCode || ""))
    : '';

  const isMapSynchrone = deepEqual(positionGeoCode, positionCurrent);

  useEffect(() => {
    if (addressSearchString) {
      setLoading(true);

      const loadLatLonPromise = getLatLngFromAddress(addressSearchString).then((newGeoPosition) => {
        if (isMapSynchrone) {
          changePosition(newGeoPosition);
        }

        setPositionGeoCode(newGeoPosition);
      }).catch(() => {
        setPositionGeoCode(undefined);

      }).finally(() => {
        setLoading(false);
      });

      return loadLatLonPromise.doCancel;
    }

  }, [addressSearchString, isMapSynchrone, changePosition]);

  if (isLoading && !positionCurrent) {
    return (
      <Box className={classes["google-maps-container"]} >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (!positionGeoCode && !positionCurrent) {
    return (
      <Box className={classes["google-maps-container"]} >
        {isNewMode ? null : <Translate id="components.partnerDetails.error.locationNotFound" />}
      </Box>
    );
  }

  const mapChangeFunc = (evt: MapProps | undefined, map: google.maps.Map | undefined, setNewPos: boolean) => {
    let latLng = { lat: map!.getCenter().lat(), lng: map!.getCenter().lng() }

    // if zoom changed, set new zoom and positionGeoCode
    if (evt!.zoom !== map!.getZoom()) {
      setZoom(map!.getZoom());
    }

    changePosition(latLng);
    //console.log(latLng);
  };

  const isDifferent = !deepEqual(positionGeoCode, positionCurrent);

  return (
    <Box className={classes["google-maps-container"]} >
      <Map
        google={google}
        zoom={zoom}
        center={positionCurrent}
        initialCenter={positionCurrent}
        streetViewControl={false}
        gestureHandling='greedy'
        //onDragend={(evt, map) => { mapChangeFunc(evt, map, true); }}
        //onTilesloaded={(evt, map) => { mapChangeFunc(evt, map, false); }}
      >
        <Marker position={positionCurrent}></Marker>
      </Map>
      <img className={classes["cursor-img"]} src={cursor} alt="+" />
      {positionGeoCode && isDifferent ? ( // this is important
        <div className={classes["lat-lng-div"]}>
          <div>lat: {positionGeoCode.lat}</div>
          <div>lng: {positionGeoCode.lng}</div>
        </div>)
        : ""}
    </Box>);
};


const getLatLngFromAddress = (address: string | null | undefined) => {
  return cancelablePromise<google.maps.LatLngLiteral>([(resolve, reject) => {
    if (address) {
      if (window.google) {
        let geocoder = new google.maps.Geocoder()
        let latLng: google.maps.LatLngLiteral | undefined = undefined;

        geocoder.geocode({ 'address': address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            latLng = { lat: 0, lng: 0 };
            latLng.lat = Number(results[0].geometry.location.lat().toFixed(4));
            latLng.lng = Number(results[0].geometry.location.lng().toFixed(4));
            //console.log("new", latLng);
            resolve(latLng);
          } else {
            reject('locationNotFound');
            return;
          }
        });
      } else {
        reject('missingGeocoder');
      }
    } else {
      reject('locationNotFound');
      return;
    }
  }]);
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCWlZ_Cq39bF7vr_x7lhNS_Z6u5uAM9F1M"
})(GoogleMapsMap);
