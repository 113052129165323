import React, { useState, useRef, useEffect } from "react";
import { Collection } from "immutable";
import format from "date-fns/format";

import {
  productInstanceAndCustomerDetails_productInstance_instanceInformation_SilkInstanceInformation_notifications as SilkNotifArray,
} from "types/apolloGenerated/productInstanceAndCustomerDetails"

import Notification from "./Notification";


interface DayGroupProps {
  notifications: Collection<number, SilkNotifArray | null>;
  dayMs: number;
  temporalHeight: number;
  isLast: boolean;
}

const style = require("../NotificationsHistory/style.module.css");

const DayGroup: React.FC<DayGroupProps> = ({
  notifications,
  dayMs,
  temporalHeight,
  isLast
}) => {
  const notifsRef = useRef<HTMLDivElement>(null);
  const [notifsHeight, setNotifsHeight] = useState(0);
  useEffect(() => {
    if (notifsRef && notifsRef.current)
      setNotifsHeight(notifsRef.current.offsetHeight);
  }, []);
  const [userExpanded, setUserExpanded] = useState(true);
  const defaultExpanded = (notifsRef &&
    notifsRef.current &&
    notifsHeight < temporalHeight) as boolean;
  const expanded = defaultExpanded || userExpanded;

  return (
    <div className={style.dayGroup}>
      <div className={style.leftCol}>
        <div className={style.timePoint} />
        {!isLast && <div className={style.timeLine} />}
      </div>
      <div className={style.rightCol}>
        <div className={style.dateHeader}>{format(dayMs, "yyy.MM.dd")}</div>
        <div onClick={() => setUserExpanded(!userExpanded)}>
        </div>
        <div
          className={style.expandableFrame}
          style={{
            height: expanded
              ? Math.max(notifsHeight, temporalHeight)
              : temporalHeight
          }}
        >
          <div
            ref={notifsRef}
            className={style.expandableContent}
            style={{
              opacity: expanded ? 1 : 0
            }}
          >
            {notifications.map((notif, idx) => (
              <Notification key={idx} notification={notif} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayGroup;
