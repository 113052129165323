import React, { useState, ChangeEvent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import gql from "graphql-tag";
import { useMutation } from '@apollo/react-hooks';


import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';

import { AppState } from 'redux/store';

import { Theme, makeStyles } from '@material-ui/core';

import { Store as ChangeLoginStore } from 'redux/types/AccountShortcuts/ChangeLogin';
import { close, change } from 'redux/actions/AccountShortcuts/ChangeLoginActions';

import { Translate, LocalizeContextProps, TranslateFunction, withLocalize, initialize } from "react-localize-redux";

import Error from 'components/common/Error';
import { Error as ErrorObj } from 'types/CampGuideTypes';

import { mapQlError } from 'util/GraphQL';


const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginBottom: theme.spacing(1),
  },
}));

// const validateEmail = (email: string): boolean => {
//   var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(String(email).toLowerCase());
// };

interface IChangeLogin extends LocalizeContextProps {
  changeLogin: ChangeLoginStore
  translate: TranslateFunction
  actions: {
    close: typeof close
    change: typeof change
  }
}

const ChangeLogin: React.FC<IChangeLogin> = ({ changeLogin, actions, translate }) => {

  const [email, setEmail] = useState(changeLogin.changeData ? changeLogin.changeData.email : '');
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [emailError, setEmailError] = useState('');
  const [pass1Error, setPass1Error] = useState('');
  const [pass2Error, setPass2Error] = useState('');
  const [mutationError, setMutationError] = useState("");



  const classes = useStyles();

  useEffect(() => {
    setEmail(changeLogin.changeData ? changeLogin.changeData.email : '');
    setPass1('');
    setPass2('');
    setEmailError('');
    setPass1Error('');
    setPass2Error('');
  }, [changeLogin]);

  const change = (event: ChangeEvent<HTMLInputElement>) => {

    if (event.currentTarget.id === 'email') {
      setEmail(event.currentTarget.value);
      setEmailError('');

    } else if (event.currentTarget.id === 'pass1') {
      setPass1(event.currentTarget.value);
      setPass1Error('');

    } else if (event.currentTarget.id === 'pass2') {
      setPass2(event.currentTarget.value);
      setPass2Error('');
    }
  };

  const validate = () => {
    let isValide = true;
    // if (!validateEmail(email)) {
    //   isValide = false;
    //   setEmailError('components.changeLogin.error.validEmail');
    // } else {
    //   setEmailError('');
    // }

    // isValide = isValide && !!pass1 && !!pass2 && (pass1 === pass2);

    if (!pass1) {
      setPass1Error('components.changeLogin.error.validPass');
    } else {
      setPass1Error('');
    }

    if (!pass2) {
      setPass2Error('components.changeLogin.error.validPass');
    } else if (pass1 !== pass2) {
      setPass2Error('components.changeLogin.error.passRepeat');
    } else {
      setPass2Error('');
    }

    return isValide
  };


  const CHANGE_LOGIN = gql`
mutation ChangeLogin($userId: ID!, $password: String!, $email: String!){
  user{
    changePassword(changePasswordInput:
        {
          password: $password,
          id: $userId
        }
    )
    changeEmail(changeEmail:
        {
          email: $email,
          userId: $userId
        }
    )
  }
}
`;

  const [mutate, { loading, error }] = useMutation(CHANGE_LOGIN, {
    update(/*cache, { data: { variables } }*/) {

      //   const queryCache:any = cache.readQuery({
      //     query: QUERY,
      //     variables: {
      //       id: 172359
      //     },
      //   });

      //   queryCache.organization.employees.results = queryCache.organization.employees.results.map((item:any) => {
      //     if (item.userInformation.key === changeLogin.changeData?.userId) {
      //       item.email = changeLogin.changeData?.email;
      //     }
      //     return item;
      //   });



      //   //console.log('#########################', JSON.parse(JSON.stringify(queryCache)));

      //   cache.writeQuery({
      //     query: QUERY,
      //     variables: {
      //       id: 172359
      //     },
      //     data: queryCache
      //   });


    },
    onCompleted: (data) => {
      if (!data.user.changePassword) {
        if (/^[a-zA-Z0-9- ]*$/.test(pass1) === true) {
          setMutationError(translate("components.changeLogin.error.validPassNoSpecialCharacter") as string);
        } else {
          setMutationError(translate("components.changeLogin.error.validPass") as string);
        }

      }
      if (!data.user.changeEmail) {
        setMutationError(translate("components.changeLogin.error.validEmail") as string);
      }

      if (data.user.changePassword && data.user.changeEmail) {
        actions.close();
      }
    },
    onError: (error) => console.error(error)
  });

  React.useEffect(() => {

  }, []);

  const submit = () => {
    if (validate() && changeLogin.changeData) {
      mutate({
        variables: {
          userId: changeLogin.changeData.userId,
          email: email,
          password: pass1
        }
      }).catch(() => { });
    }
  };

  const eroorObj: ErrorObj | null = error ? mapQlError(error.graphQLErrors) : null;

  const progress = loading ? <CircularProgress color="secondary" /> : null;

  return (
    <Dialog open={Boolean(changeLogin.changeData)} onClose={actions.close} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="form-dialog-title"><Translate id="components.changeLogin.title" /></DialogTitle>
      <DialogContent>
        <DialogContentText><Translate id="components.changeLogin.body" /></DialogContentText>
        <Error error={eroorObj || mutationError} />
        <TextField
          fullWidth
          disabled={loading}
          id="email"
          value={email}
          onChange={change}
          className={classes.input}
          error={!!emailError}
          helperText={emailError ? <Translate id={emailError} /> : null}
          type="email"
          label={<Translate id="generics.email" />}
          autoFocus
        />
        <TextField
          fullWidth
          disabled={loading}
          id="pass1"
          value={pass1}
          onChange={change}
          className={classes.input}
          error={!!pass1Error}
          helperText={pass1Error ? <Translate id={pass1Error} /> : null}
          type="password"
          label={<Translate id="types.account.password" />}
        />
        <TextField
          fullWidth
          disabled={loading}
          id="pass2"
          value={pass2}
          onChange={change}
          className={classes.input}
          error={!!pass2Error}
          helperText={pass2Error ? <Translate id={pass2Error} /> : null}
          type="password"
          label={<Translate id="components.changeLogin.repeatPasswort" />}
        />
      </DialogContent>
      <DialogActions>
        {progress}
        <Button onClick={actions.close} color="primary" disabled={loading}>
          <Translate id="generics.cancel" />
        </Button>
        <Button onClick={submit} color="primary" disabled={loading}>
          <Translate id="generics.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}




const ChangeLoginWrapper: React.FC<IChangeLogin> = ({ changeLogin, actions, translate, addTranslation, renderToStaticMarkup, languages, defaultLanguage, activeLanguage, addTranslationForLanguage, setActiveLanguage }) => {
  if (changeLogin.changeData) {
    return <ChangeLogin
      changeLogin={changeLogin}
      actions={actions}
      addTranslation={addTranslation}
      renderToStaticMarkup={renderToStaticMarkup}
      addTranslationForLanguage={addTranslationForLanguage}
      languages={languages}
      translate={translate}
      initialize={initialize}
      activeLanguage={activeLanguage}
      setActiveLanguage={setActiveLanguage}
      defaultLanguage={defaultLanguage} />;
  }
  return null;
}



const mapStateToProps = (state: AppState) => ({
  changeLogin: state.accountShortcuts.changeLogin,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ close, change }, dispatch)
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(ChangeLoginWrapper));
