import React, { Fragment } from "react";

import { productInstanceAndCustomerDetails_productInstance_instanceInformation_PerlaInstanceInformation as PerlaInstance } from "types/apolloGenerated/productInstanceAndCustomerDetails";

// import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from "react-localize-redux";

// const useStyles = makeStyles((theme: Theme) => {
//   return createStyles({
//     arrow: { fontSize: 16, margin: 7 },
//     textField: {
//       [`& input::-webkit-clear-button,
//       & input::-webkit-outer-spin-button,
//       & input::-webkit-inner-spin-button,
//       & input::-webkit-toolbarbutton-dropdown`]: {
//         display: "none",
//       },
//       width: 150,
//     },
//     customTooltip: {
//       width: "120px",
//     },
//   });
// });

interface ParameterProps extends LocalizeContextProps {
  productInstance: PerlaInstance | null | undefined;
}

interface Params {
  code: string;
  value: string;
}

const ParameterRnD: React.FC<ParameterProps> = ({ productInstance }) => {
  let paramInfo = "{}";
  if (
    productInstance?.registeredPerlaInstanceInformation?.deviceParameter !== ""
  ) {
    paramInfo =
      productInstance?.registeredPerlaInstanceInformation?.deviceParameter ??
      "{}";
  }
  const paramConv = JSON.parse(paramInfo);
  const paramNames: string[] = Object.getOwnPropertyNames(paramConv) ?? [];
  const paramValues: string[] = Object.values(paramConv) ?? [];
  const paramArray: Params[] = [];

  for (var i = 0; i < paramNames.length; i++) {
    paramArray[i] = {
      code: paramNames[i],
      value: paramValues[i],
    };
  }

  return (
    <Fragment>
      <Box>
        <Typography variant="h6" component="h3">
          <strong>
            <Translate id="components.deviceParameters.title" />
          </strong>
        </Typography>
        <Box paddingBottom={2} fontWeight="fontWeightBold"></Box>
        {paramArray.length > 0 ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "30%" }}>
                    <b>
                      <Translate id="components.deviceParameters.code" />
                    </b>
                  </TableCell>
                  <TableCell style={{ width: "70%" }}>
                    <b>
                      <Translate id="components.deviceParameters.value" />
                    </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paramArray.map((item) => (
                  <TableRow>
                    <TableCell>{item.code ?? ""}</TableCell>
                    <TableCell>{item.value ?? ""}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div>
            <Translate id="components.deviceParameters.noParameters" />
          </div>
        )}
      </Box>
    </Fragment>
  );
};

export default withLocalize(ParameterRnD);
