import { ActionType, Store, Action } from 'redux/types/SendgridTemplatesDelete';

import initialState from 'redux/initialState';

export default function sendgridTemplatesDeleteReducer(store: Store = initialState.sendgridTemplatesDelete, action: Action): Store {
  switch (action.type) {

    case ActionType.OPEN:
      return {
        isLocked: false,
        changeData: action.payload,
        error: null
      };

    case ActionType.CLOSE:
      return {
        isLocked: false,
        changeData: null,
        error: null
      };

    case ActionType.LOADING:
      return {
        isLocked: true,
        changeData: store.changeData,
        error: null
      };

    case ActionType.ERROR:
      return {
        isLocked: false,
        changeData: store.changeData,
        error: action.payload
      };

    default:
      return store;
  }
}
