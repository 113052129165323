import { ActionType, Store, Action } from 'redux/types/AccountShortcuts/RemoveFromCompany';

import initialState from 'redux/initialState';

export default function changeLoginReducer(store: Store = initialState.accountShortcuts.removeFromCompany, action: Action): Store {
  switch (action.type) {

    case ActionType.OPEN:
      return {
        changeData: action.payload,
      };

    case ActionType.CLOSE:
      return {
        changeData: null,
      };

    default:
      return store;
  }
}
