import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "react-apollo";
import { QUERY_SENDGRID_SUPPORTED_LANGUAGES } from "commonQueries/sendgridSupportedLanguages";
import CircularProgress from "@material-ui/core/CircularProgress";
import bwtColors from "themes/bwtColors";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  sendGridSupportedLanguages,
  sendGridSupportedLanguages_sendGridSupportedLanguages as SupportedLanguages,
} from "types/apolloGenerated/sendGridSupportedLanguages";
import {
  createSendgridSupportedLanguage as CreateSendgridSupportedLang,
  createSendgridSupportedLanguageVariables,
} from "types/apolloGenerated/createSendgridSupportedLanguage";
import { SendGridSupportedLanguageInsert } from "types/apolloGenerated/globalTypes";
import {
  deleteSendgridSupportedLanguage as DeleteSendgridSupportedLang,
  deleteSendgridSupportedLanguageVariables,
} from "types/apolloGenerated/deleteSendgridSupportedLanguage";

const DELETE_SENDGRID_SUPPORTED_LANGUAGE = gql`
  mutation deleteSendgridSupportedLanguage($id: Int!) {
    sendGridSupportedLanguage {
      delete(sendGridSupportedLanguageId: $id)
    }
  }
`;

const CREATE_SENDGRID_SUPPORTED_LANGUAGE = gql`
  mutation createSendgridSupportedLanguage(
    $insert: SendGridSupportedLanguageInsert!
  ) {
    sendGridSupportedLanguage {
      create(sendGridSupportedLanguage: $insert) {
        id
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    tableHeader: {
      border: `1px solid ${bwtColors.grey}`,
      backgroundColor: bwtColors.blue,
      color: "white",
      padding: "10px",
    },
    tableRow: {
      textAlign: "center",
      padding: "2px",

      "&:hover": {
        backgroundColor: `${bwtColors.grey10}!important`,
      },
    },
    tableCell: {
      border: `1px solid ${bwtColors.grey}`,
    },
    spinner: {
      width: "320px",
      height: "600px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });
});

const EditLanguageDialog: React.FC<{ open: boolean; close: any }> = ({
  open,
  close,
}) => {
  const {
    data: supportedLanguages,
    loading: loadingLanguages,
  } = useQuery<sendGridSupportedLanguages>(QUERY_SENDGRID_SUPPORTED_LANGUAGES);
  const [
    deleteSendgridSupportedLanguage,
    { loading: deleteLoading },
  ] = useMutation<
    DeleteSendgridSupportedLang,
    deleteSendgridSupportedLanguageVariables
  >(DELETE_SENDGRID_SUPPORTED_LANGUAGE, {
    onCompleted: () => setSelectedRow(-1),
    refetchQueries: [
      {
        query: QUERY_SENDGRID_SUPPORTED_LANGUAGES,
      },
    ],
    awaitRefetchQueries: true,
  });
  const [
    createSendgridSupportedLanguage,
    { loading: createLoading },
  ] = useMutation<
    CreateSendgridSupportedLang,
    createSendgridSupportedLanguageVariables
  >(CREATE_SENDGRID_SUPPORTED_LANGUAGE, {
    onCompleted: () => setCreateObject({ languageCode: "", countryCode: "" }),
    refetchQueries: [
      {
        query: QUERY_SENDGRID_SUPPORTED_LANGUAGES,
      },
    ],
    awaitRefetchQueries: true,
  });

  const classes = useStyles();

  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [
    createObject,
    setCreateObject,
  ] = useState<SendGridSupportedLanguageInsert>({
    languageCode: "",
    countryCode: "",
  });

  if (!supportedLanguages?.sendGridSupportedLanguages && !loadingLanguages) {
    return <div>Failed to get languages</div>;
  }

  const countryLanguagePair =
    supportedLanguages?.sendGridSupportedLanguages ?? [];

  return (
    <Dialog open={open}>
      <DialogContent>
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <div style={{ margin: "0 10px 0 0", width: "120px" }}>
            <TextField
              value={createObject.languageCode}
              label={<Translate id="pages.sendgrid.editDialog.languageCode" />}
              inputProps={{ maxLength: 2 }}
              onChange={(e) => {
                setCreateObject({
                  languageCode: e.target.value.toLowerCase(),
                  countryCode: createObject.countryCode,
                });
              }}
            />
          </div>
          <div style={{ margin: "0 10px 0 10px", width: "120px" }}>
            <TextField
              value={createObject.countryCode}
              label={<Translate id="pages.sendgrid.editDialog.countryCode" />}
              inputProps={{ maxLength: 2 }}
              onChange={(e) => {
                setCreateObject({
                  languageCode: createObject.languageCode,
                  countryCode: e.target.value.toUpperCase(),
                });
              }}
            />
          </div>
          {!createLoading ? (
            <Button
              variant="outlined"
              disabled={
                createObject.languageCode.length !== 2 ||
                createObject.countryCode.length !== 2
              }
              onClick={() =>
                createSendgridSupportedLanguage({
                  variables: { insert: createObject },
                })
              }
            >
              <Translate id="generics.add" />
            </Button>
          ) : (
            <div style={{ width: "64px" }}>
              <CircularProgress color="secondary" />
            </div>
          )}
        </div>
        {!loadingLanguages ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <table style={{ borderCollapse: "collapse", cursor: "pointer" }}>
              <thead>
                <tr>
                  <th className={classes.tableHeader}>
                    <Translate id="pages.sendgrid.editDialog.languageCode" />
                  </th>
                  <th className={classes.tableHeader}>
                    <Translate id="pages.sendgrid.editDialog.countryCode" />
                  </th>
                </tr>
              </thead>
              {countryLanguagePair.map(
                (row: SupportedLanguages | null, idx: number) => (
                  <tbody key={idx}>
                    <tr
                      className={classes.tableRow}
                      style={{
                        backgroundColor:
                          selectedRow === idx
                            ? bwtColors.grey35
                            : "transparent",
                      }}
                      onClick={() => setSelectedRow(idx)}
                    >
                      <td className={classes.tableCell}>{row?.languageCode}</td>
                      <td className={classes.tableCell}>{row?.countryCode}</td>
                    </tr>
                  </tbody>
                )
              )}
            </table>
          </div>
        ) : (
          <div className={classes.spinner}>
            <CircularProgress color="secondary" />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!deleteLoading ? (
          <Button
            variant="outlined"
            disabled={selectedRow === -1}
            onClick={() => {
              if (selectedRow !== -1) {
                deleteSendgridSupportedLanguage({
                  variables: {
                    id: countryLanguagePair[selectedRow]?.id ?? -1,
                  },
                });
              }
            }}
          >
            <Translate id="generics.remove" />
          </Button>
        ) : (
          <CircularProgress color="secondary" />
        )}
        <Button variant="outlined" onClick={close}>
          <Translate id="generics.done" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLanguageDialog;
