import React, { useState, useRef, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classnames from "classnames/bind";



const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      position: "relative",
      width: "100%",
      transition: "height 0.2s",
      height: "0",
    },
    content: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      transition: "opacity 0.2s",
      opacity: "1"
    },
    hidden: {
      pointerEvents: "none",
      opacity: "0"
    }
  })
})


const Expander: React.FC<{ hidden: boolean }> = ({ children, hidden }) => {
  const classes = useStyles();
  const cx = classnames.bind(classes);
  const ref = useRef<HTMLInputElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const height = hidden ? 0 : contentHeight
  const marginBottom = hidden ? 0 : "10px"
  const currentHeight = ref.current?.height

  useEffect(() => {
    setContentHeight(ref.current?.offsetHeight ?? 0);
  }, [currentHeight, children]);

  return (
    <div className={classes.root} style={{ height, marginBottom }}>
      <div ref={ref} className={cx("content", { hidden } )}>
        {children}
      </div>
    </div>
  );
};
export default Expander;