export enum ActionType {
  OPEN = 'REQUEST_LOG_OPEN',
  CLOSE = 'REQUEST_LOG_CLOSE',
}

export interface Store {
  openWithProductCode: string | undefined,
}


export interface OpenAction {
  type: ActionType.OPEN,
  payload: string
}

export interface CloseAction {
  type: ActionType.CLOSE,
}


export type Action = OpenAction | CloseAction;
