import {
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  banner: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  okButton: {
    margin: "17px 0 36px 0",
    width: "205px",
    height: "43px",
    backgroundColor: "#6386A4",
    color: "white",
    "&:hover": {
      backgroundColor: "#6386A4",
      filter: "brightness(0.95)",
    },
  },
}));

export default function PhoenixWarningDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const styles = useStyles();

  return (
    <Dialog open={open}>
      <DialogContent>
        <div className={styles.banner}>
          <DialogTitle id="form-dialog-title">
            <b>CAMP UPDATE</b>
          </DialogTitle>
          <DialogContentText>
          We are currently replacing CAMP with globestage. Features that are still in CAMP: 
          <ul>
            <li>
              Non IOT product user actions
            </li>
            <li>
              Manage Access to Perla Updater for Users
            </li>
          </ul>
          The rest of the features are already available in globestage. To request accesses, further training & for further questions, please contact Sandra Messer,
          {" "}
          <a href="mailto:sandra.messer@aidu.solutions">
            sandra.messer@aidu.solutions
          </a>
          </DialogContentText>
          <Button className={styles.okButton} onClick={onClose}>
            Ok
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
