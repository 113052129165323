import React from "react";

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { withLocalize,TranslateFunction,Translate, LocalizeContextProps,  } from "react-localize-redux";
import { Link } from 'react-router-dom';

import { CircularProgress, makeStyles, createStyles, Theme } from "@material-ui/core";

import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import {promo, promo_promotions_results} from "types/apolloGenerated/promo";

import { ColDef } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';


import { ReduxAgGrid } from "components/common/Grid/AgGrid";
import { AppState } from "redux/store";
import { State as PromoGridState } from "redux/types/PromotionOverview";
import { setGridState, setColumnStates, } from "redux/actions/PromotionOverviewActions";
// import { promotionInformation } from "pages/Promotion/PromotionInformation";



export const PROMOTION_GRID = gql`
query promo {
  promotions(pageSize: 0){
    results{
      id
      assignedProducts {
        id
        name
      }
      articleNumber
      availableCount
      overallCount
      name
    }
  }
}
`;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    promoGrid: {
      position: "relative",
      height: "100%",
      display: 'flex',
      flexFlow: 'column',
      overflow: 'hidden',

      '& > *': {
        flex: '1 1 auto',
      },
      '& a': {
        color: '#005D8F'
      },
      '& > .grie-error': {
        flex: '0 1 auto',
      },
      '& .ag-paging-panel.ag-hidden': {
        display: 'block !important',
        opacity: 0,
        pointerEvents: 'none',
      },
      '& .ag-cell.number-cell': {
        textAlign: 'right',
        paddingRight: '40px',
      },
    },
  });
});



export const getColumnDefs = (translate: TranslateFunction): ColDef[] => {
  const columnDefs = [
    {
      headerName: translate("types.common.name") as string,
      field: "name",
      cellRenderer: "ProductLink",
      width: 200,
    },
    {
      headerName: translate('components.showAccountInfo.status') as string,
      field: "assignedProducts",
      cellRenderer: "Count",
      width: 150,
    },
    // {
    //   headerName: translate('types.organization.partnerType') as string,
    //   field: "type",
    //   width: 300,
    // },
    {
      headerName: "Products",
      width: 300,
      cellRenderer: "ProductCount"
    }
  ];
  return columnDefs;
};

interface CellRenderer {
  value: string,
  data: promo_promotions_results
}

const ProductLink: React.FC<CellRenderer> = ({ data, value }) => {
  if (!(data && data.id)) {
    return <div />;
  }
  return (
    <Link to={"/promotion/" + data.id}>{value}</Link>
  );
};

const Count: React.FC<CellRenderer> =({ data, value }) => {
  const color = (data?.availableCount && data.availableCount < 100) || (data.availableCount === 0) ? "#c95d55" : "#74b567"
  return (
    <div style={{ backgroundColor: color, borderRadius: "5px", justifyContent: "center", display: "inline-block", padding: "0 8px" }}>
      <div>{data.availableCount + " / " + data.overallCount}</div>
    </div>
  
  ) 
};

const ProductCount: React.FC<CellRenderer> =({ data, value }) => {
  const productLength = data?.assignedProducts?.length ?? 0
  const firstProduct = (data.assignedProducts && data?.assignedProducts[0] && data?.assignedProducts[0]?.name) ?? ""
  return (
  <div>
    <div>{productLength > 1 ? firstProduct + " and " + (productLength - 1) + " more " : firstProduct }</div>
  </div>
  
  ) 
};

export const CellRenderer = { 
  Count,
  ProductLink,
  ProductCount
};

interface PromoGridPara extends LocalizeContextProps {
  translate: TranslateFunction
  gridState: PromoGridState
  search: string
  actions: {
    setGridState: typeof setGridState
    setColumnStates: typeof setColumnStates
  };
}

const PromoGrid: React.FC<PromoGridPara> = ({translate,search,gridState,actions}) => {

  const classes = useStyles();

  const {data, loading} = useQuery<promo>(PROMOTION_GRID, {
    errorPolicy: 'ignore'
  });
  const gridData = data && data?.promotions?.results ? data?.promotions?.results : [];

  if (loading) {
    return <CircularProgress color="secondary" />
  } else if (data) {
      return (
        <ReduxAgGrid
        className={classes.promoGrid}
        columnDefs={getColumnDefs(translate)}
        rowData={gridData}
        frameworkComponents={{ 
          ProductLink,
          Count,
          ProductCount 
        }}
        gridState={gridState.grid}
        gridActions={actions}
        pagination={false}
        quickFilterText={search}
        />
      );
    }        
        
    return <Translate id="generics.noDataAvailable" />;
};

const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  gridState: appState.promotionOverview
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { setGridState, setColumnStates },
    dispatch
  )
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(PromoGrid));
