import { Action, ActionType, InputToChipStore } from 'redux/types/InputToChip';

import initialState from 'redux/initialState';

export default function inputToChipReducer(state: InputToChipStore = initialState.inputToChip, action: Action): InputToChipStore {
  switch (action.type) {

    case ActionType.CHIPS:
      return {
        chips: action.payload,
        error: null,
        isLoaded: true,
        pending: false
      };

    case ActionType.PENDING:
      return {
        ...state,
        pending: true
      };

    case ActionType.SAVE:
      return {
        ...state,
        pending: true
      };

    case ActionType.ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
