import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { withApollo, OperationVariables } from "react-apollo";
import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from "react-localize-redux";
import { fromJS, List, Range } from "immutable";

import { TextField, Box } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import {
  MenuItemDef,
  GetContextMenuItemsParams,
} from "@ag-grid-enterprise/all-modules";

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import debounce from "debounce";
import ApolloClient from "apollo-client";

import store, { AppState } from "redux/store";
import { State as OrgOverviewState } from "redux/types/PartnerOverview";
import {
  setGridState,
  setColumnStates,
  search,
} from "redux/actions/PartnerOverviewActions";

import {
  setGridState as setGridWithPearlsState,
  setColumnStates as setColumnWithPearlsStates,
  searchWithPearls,
} from "redux/actions/PartnerOverviewWithPearlsActions";

import {
  apolloConnectorBySearchWithPearls,
  getColumnDefsWithPearls,
  gridOptions,
  QUERY_PARTNER_LIST,
  QUERY_PARTNER_LIST_WITH_PEARLS,
} from "./GridConfig";
import { organizationsGrid as OrgGrid } from "types/apolloGenerated/organizationsGrid";
import PageDialog from "components/common/PageDialog";
import DELETE_ORGANISATION from "commonQueries/deleteOrganisation";
import { useMutation } from "react-apollo";
import {
  deleteOrganisation as MutationData,
  deleteOrganisationVariables as MutationVars,
} from "types/apolloGenerated/deleteOrganisation";

import { UserProfile } from "types/ToolboxEntities";

import { getFilterValues, getVarSetsQueriedByGrids } from "util/AgGridUtils";

import AgGrid from "components/common/Grid/AgGrid";
import CountryFilter from "components/common/CountryFilter";
import PageHeader from "components/common/PageHeader";
import GridFilterHeader from "components/common/Grid/GridFilterHeader";
import * as Types from "../../../types/ToolboxEntities";

import {
  getColumnDefs,
  apolloConnectorBySearch,
  CellRenderer,
} from "./GridConfig";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    partnerOverview: {
      display: "flex",
      flexFlow: "column",

      "& > .gridFilterHeader": {
        flex: "0 1 auto",
      },

      "& > .innerContent": {
        flex: "1 1 100%",
        height: "100%",
      },
    },

    innerHeader: {
      flex: "0 1 auto",
      display: "flex",
      margin: "0 -20px 20px -20px",
      alignItems: "flex-end",
      justifyContent: "space-between",

      "& > *": {
        flex: "0 1 auto",
        margin: "0 20px",
        minWidth: "170px",
      },

      "& > .filter": {
        flex: "1 1 auto",
        maxWidth: "250px",
      },
    },

    backgroundError: {
      minWidth: "115px",
      "&.Mui-selected": {
        backgroundColor: "##FFE8EC",
        "&:hover": {
          backgroundColor: "rgba(102, 107, 110, 0.05)",
        },
      },
      "&:hover": {
        backgroundColor: "rgba(102, 107, 110, 0.05)",
      },
    },

    innerHeaderRight: {
      flex: "0 1 auto",
      display: "flex",
      alignItems: "flex-end",
      margin: 0,
      border: "solid 2px grey20",
      width: "350px",
      borderRadius: "5px",
      height: "48px",

      "& > *": {
        flex: "0 1 auto",
        margin: "0 20px",
        minWidth: "170px",
      },
    },
  });
});

const debouncedSearch = debounce(
  (
    searchTerm: string,
    oldSearchTerm: string | null,
    searchAction: typeof search
  ) => {
    if (searchTerm.length >= 3) {
      searchAction(searchTerm);
    } else if (
      searchTerm.length === 0 &&
      (oldSearchTerm === null || oldSearchTerm.length > 0)
    ) {
      searchAction(null);
    }
  },
  500
);

interface PartnerListPara extends LocalizeContextProps {
  user: UserProfile | null;
  partnerOverviewState: OrgOverviewState;
  partnerOverviewWithPearlsState: OrgOverviewState;
  actions: {
    setGridState: typeof setGridState;
    setColumnStates: typeof setColumnStates;
    search: typeof search;
    setGridWithPearlsState: typeof setGridState;
    setColumnWithPearlsStates: typeof setColumnWithPearlsStates;
    searchWithPearls: typeof searchWithPearls;
  };
  client: ApolloClient<any>;
}

const PartnerList: React.FC<PartnerListPara> = ({
  user,
  partnerOverviewState,
  partnerOverviewWithPearlsState,
  actions,
  translate,
  client,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [orgId, setOrgId] = React.useState(-1);
  const [delOrgError, setDelOrgError] = React.useState("");
  const [update, setUpdate] = React.useState(true);
  const [gridWithPearls, setGridWithPearls] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const refetchQueries = () => {
    //get all the cached variables
    const allQueryVariables = getVarSetsQueriedByGrids(QUERY_PARTNER_LIST);
    const allQueryVariablesWithPearls = getVarSetsQueriedByGrids(
      QUERY_PARTNER_LIST_WITH_PEARLS
    );
    //group them by query filter and sort
    const cacheGroups = fromJS(
      allQueryVariables
    ).groupBy((vars: OperationVariables) => vars.remove("page"));

    const cacheGroupsWithPearls = fromJS(
      allQueryVariablesWithPearls
    ).groupBy((vars: OperationVariables) => vars.remove("page"));

    const groupByRange = (input: any) =>
      input.sort().reduce((acc: any, value: any) => {
        if (acc.last()?.last() + 1 === value) {
          return acc.updateIn([acc.size - 1], (last: any) => last.push(value));
        } else {
          return acc.push(List([value]));
        }
      }, List());

    const groupedRanges = cacheGroups.map((group: any) =>
      groupByRange(group.map((value: any) => value.get("page")))
    );

    const groupedRangesWithPearls = cacheGroupsWithPearls.map((group: any) =>
      groupByRange(group.map((value: any) => value.get("page")))
    );

    return (
      groupedRanges
        .entrySeq()
        .flatMap((pair: any) =>
          pair[1].map((pageGroup: any) => ({
            query: QUERY_PARTNER_LIST,
            variables: { page: pageGroup.last(), ...pair[0].toJS() },
          }))
        )
        .toArray(),
      groupedRangesWithPearls
        .entrySeq()
        .flatMap((pair: any) =>
          pair[1].map((pageGroup: any) => ({
            query: QUERY_PARTNER_LIST_WITH_PEARLS,
            variables: { page: pageGroup.last(), ...pair[0].toJS() },
          }))
        )
        .toArray()
    );
  };

  const [deleteOrganisation, { loading: orgLoad }] = useMutation<
    MutationData,
    MutationVars
  >(DELETE_ORGANISATION, {
    onCompleted: (data) => {
      if (!data.organization?.deleteOrganization) {
        setDelOrgError("Unable to delete organization. Please try again later");
      } else {
        setUpdate(!update);
        setOrgId(-1);
        setOpen(false);
      }
    },
    onError: (error) => setDelOrgError(error.message),
    refetchQueries,
    awaitRefetchQueries: true,
    update: (cache) => {
      //get all the cached variables
      const allQueryVariables = getVarSetsQueriedByGrids(QUERY_PARTNER_LIST);
      //group them by query filter and sort
      const cacheGroups = fromJS(
        allQueryVariables
      ).groupBy((vars: OperationVariables) => vars.remove("page"));

      const groupByRange = (input: any) =>
        input.sort().reduce((acc: any, value: any) => {
          if (acc.last()?.last() + 1 === value) {
            return acc.updateIn([acc.size - 1], (last: any) =>
              last.push(value)
            );
          } else {
            return acc.push(List([value]));
          }
        }, List());

      const splitIntoChunks = (list: any, chunkSize = 1) => {
        return Range(0, list.count(), chunkSize).map((chunkStart) =>
          list.slice(chunkStart, chunkStart + chunkSize)
        );
      };

      const groupedRanges = cacheGroups.map((group: any) =>
        groupByRange(group.map((value: any) => value.get("page")))
      );

      groupedRanges.forEach((pageGroups: any, otherVars: any) => {
        pageGroups.forEach((pageGroup: any) => {
          const allCaches = pageGroup.map((page: any) =>
            cache.readQuery<OrgGrid>({
              query: QUERY_PARTNER_LIST,
              variables: { page, ...otherVars.toJS() },
            })
          );
          const allRows = allCaches.flatMap(
            (pageCache: any) => pageCache?.organizations?.results ?? []
          );

          const updatedRows = allRows.filter((data: any) => data?.id !== orgId);
          const pagesToUpdate = splitIntoChunks(
            updatedRows,
            otherVars.get("pageSize")
          )
            .slice(0, -1)
            .toArray();

          for (let i = 0; i < pagesToUpdate.length; i++) {
            const pageCache = allCaches.get(i);
            const nextData = {
              ...pageCache,
              organizations: {
                ...pageCache.organizations,
                results: pagesToUpdate[i].toArray(),
              },
            };

            cache.writeQuery<OrgGrid>({
              query: QUERY_PARTNER_LIST,
              variables: { page: pageGroup.get(i), ...otherVars.toJS() },
              data: nextData,
            });
          }
        });
      });
    },
  });

  const [search, setSearch] = useState<string>(
    partnerOverviewState.search || ""
  );

  const [apolloConnector, setApolloConnector] = useState<
    ReturnType<typeof apolloConnectorBySearch>
  >(apolloConnectorBySearch(partnerOverviewState.search));

  const [apolloConnectorWithPearls, setApolloConnectorWithPearls] = useState<
    ReturnType<typeof apolloConnectorBySearchWithPearls>
  >(apolloConnectorBySearchWithPearls(partnerOverviewWithPearlsState.search));

  useEffect(() => {
    setApolloConnector(apolloConnectorBySearch(partnerOverviewState.search));
    setApolloConnectorWithPearls(
      apolloConnectorBySearchWithPearls(partnerOverviewWithPearlsState.search)
    );
  }, [partnerOverviewState.search, partnerOverviewWithPearlsState.search]);

  if (!user) {
    return null;
  }

  const gridState = partnerOverviewState.grid;
  const gridStateWithPearls = partnerOverviewWithPearlsState.grid;

  const changeFilter = (
    filterName: string,
    newFilterValues: string[] | false
  ) => {
    const newFilter = Object.assign({}, gridState.filterModel);
    const newFilterWithPearls = Object.assign(
      {},
      gridStateWithPearls.filterModel
    );
    if (newFilterValues !== false) {
      newFilter[filterName] = {
        filterType: "set",
        values: newFilterValues,
      };
      newFilterWithPearls[filterName] = {
        filterType: "set",
        values: newFilterValues,
      };
    } else {
      delete newFilter[filterName];
      delete newFilterWithPearls[filterName];
    }
    actions.setGridState(gridState.page, gridState.sortModel, newFilter);
    actions.setGridWithPearlsState(
      gridStateWithPearls.page,
      gridStateWithPearls.sortModel,
      newFilterWithPearls
    );

    return;
  };

  const changeTypeFilter = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => {
    let newFilterValues = value as string[];
    changeFilter(
      "partnerType",
      newFilterValues.length > 0 &&
        newFilterValues.length < 4 &&
        newFilterValues
    );
  };

  const selectedTypes = gridState.filterModel.partnerType
    ? getFilterValues(gridState.filterModel.partnerType)
    : [];

  const changeCountryFilter = (newFilterValues: string[]) => {
    changeFilter(
      "address.countryCode",
      newFilterValues.length > 0 &&
        newFilterValues.length < user.allowedCountries.length &&
        newFilterValues
    );
  };

  const selectedCountries = gridState.filterModel["address.countryCode"]
    ? getFilterValues(gridState.filterModel["address.countryCode"])
    : user.allowedCountries;

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    // setSearchWithPearls(event.target.value);
    debouncedSearch(
      event.target.value,
      partnerOverviewState.search || partnerOverviewWithPearlsState.search,
      actions.search
    );
  };

  const getContextMenuItems = () => {
    const result: (string | MenuItemDef)[] = [
      "copy",
      "copyWithHeaders",
      "paste",
      "export",
    ];

    return (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      if (params && params.node) {
        setOrgId(params.node.data.id);
      }
      return result;
    };
  };

  let salesRep = false;
  store.getState().auth.user?.roles.forEach((item: Types.Role) => {
    if (item.roleName === "SalesRep") {
      salesRep = true;
    }
  });

  function setDefaultSort(newFilterValues: string[]) {
    changeFilter(
      "partnerType",
      newFilterValues.length > 0 &&
        newFilterValues.length < 4 &&
        newFilterValues
    );
  }

  return (
    <Translate>
      {({ translate }) => (
        <Fragment>
          <PageDialog
            open={confirmDialog && !gridWithPearls}
            title={"pages.partnerDetails.RetrievingPearlsAreYouSure"}
            buttons={[
              {
                title: "generics.true",
                click: () => {
                  setGridWithPearls(true);
                  setConfirmDialog(false);
                },
              },
              {
                title: "generics.cancel",
                click: () => {
                  setConfirmDialog(false);
                },
              },
            ]}
          />
          <PageHeader title="pages.pam.partner.title" />
          <div
            className={
              "mainContentContainer fullHeight " + classes.partnerOverview
            }
          >
            <GridFilterHeader className="gridFilterHeader">
              <Box>
                <TextField
                  className="filter"
                  id="standard-basic"
                  label="Type to Filter"
                  value={search}
                  onChange={changeSearch}
                />
              </Box>

              <div className="innerHeaderRight">
                <Box>
                  <CountryFilter
                    selected={selectedCountries}
                    onChange={changeCountryFilter}
                  />
                </Box>

                {!salesRep ? (
                  <ToggleButtonGroup
                    value={selectedTypes}
                    onChange={changeTypeFilter}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="TWP">
                      <Translate
                        options={{
                          renderToStaticMarkup: false,
                          renderInnerHtml: true,
                        }}
                        id="types.organization.partnerType.twp"
                      />
                    </ToggleButton>
                    <ToggleButton value="BUILDER">
                      <Translate
                        options={{
                          renderToStaticMarkup: false,
                          renderInnerHtml: true,
                        }}
                        id="types.organization.partnerType.builder"
                      />
                    </ToggleButton>
                    <ToggleButton value="OPERATOR">
                      <Translate
                        options={{
                          renderToStaticMarkup: false,
                          renderInnerHtml: true,
                        }}
                        id="types.organization.partnerType.operator"
                      />
                    </ToggleButton>
                    <ToggleButton value="PLANNER">
                      <Translate
                        options={{
                          renderToStaticMarkup: false,
                          renderInnerHtml: true,
                        }}
                        id="types.organization.partnerType.planner"
                      />
                    </ToggleButton>
                    <ToggleButton value="WHOLESALER">
                      <Translate
                        options={{
                          renderToStaticMarkup: false,
                          renderInnerHtml: true,
                        }}
                        id="types.organization.partnerType.wholesaler"
                      />
                    </ToggleButton>
                    <ToggleButton value="PROFESSIONAL">
                      <Translate
                        options={{
                          renderToStaticMarkup: false,
                          renderInnerHtml: true,
                        }}
                        id="types.organization.partnerType.professional"
                      />
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : (
                  <div />
                )}
              </div>
            </GridFilterHeader>

            <div className="innerContent">
              {gridWithPearls ? (
                <AgGrid
                  key={update + ""}
                  gridState={partnerOverviewWithPearlsState.grid}
                  gridActions={{
                    setGridState: actions.setGridState,
                    setColumnStates: actions.setColumnStates,
                  }}
                  apolloConnector={apolloConnectorWithPearls}
                  frameworkComponents={CellRenderer}
                  columnDefs={getColumnDefsWithPearls(translate, user)}
                  getContextMenuItems={getContextMenuItems()}
                  gridOptions={gridOptions}
                  onGridReady={gridOptions.getRowStyle}
                />
              ) : (
                <AgGrid
                  key={update + "1"}
                  gridState={partnerOverviewState.grid}
                  gridActions={{
                    setGridState: actions.setGridWithPearlsState,
                    setColumnStates: actions.setColumnWithPearlsStates,
                  }}
                  apolloConnector={apolloConnector}
                  frameworkComponents={CellRenderer}
                  columnDefs={getColumnDefs(translate, user)}
                  getContextMenuItems={getContextMenuItems()}
                  gridOptions={gridOptions}
                  onFirstDataRendered={() => {
                    if (salesRep) setDefaultSort(["TWP"]);
                  }}
                />
              )}
            </div>
          </div>
          <PageDialog
            open={open}
            title={"components.partnerDetails.deleteOrganisationMessage"}
            buttons={[
              {
                title: "generics.true",
                click: () => {
                  setDelOrgError("");
                  deleteOrganisation({
                    variables: {
                      organizationId: orgId,
                    },
                  });
                },
              },
              {
                title: "generics.cancel",
                click: () => {
                  setOpen(false);
                  setOrgId(-1);
                  setDelOrgError("");
                },
              },
            ]}
            loading={orgLoad}
            error={delOrgError}
          />
        </Fragment>
      )}
    </Translate>
  );
};

const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  partnerOverviewState: appState.partnerOverview,
  partnerOverviewWithPearlsState: appState.partnerOverview,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      setGridState,
      setColumnStates,
      search,
      setGridWithPearlsState,
      setColumnWithPearlsStates,
      searchWithPearls,
    },
    dispatch
  ),
});

export default withLocalize(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(PartnerList))
);
