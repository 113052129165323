import React, { Component, Fragment, useState, useEffect } from 'react';
import Select, { ValueType } from 'react-select'
import gql from "graphql-tag";
import { useQuery } from 'react-apollo';

import { Button, Input } from '@material-ui/core';

import { Translate } from 'react-localize-redux';
import { deviceNotificationCodeDefinitions } from 'types/apolloGenerated/deviceNotificationCodeDefinitions';
import { CircularProgress } from '@material-ui/core';

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    marginBottom: "10px"
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999
  })
};


const QUERY_DEVICE_NOTIFICATIONS = gql`
  query deviceNotificationCodeDefinitions{
    deviceNotificationCodeDefinitions (
      page: 0
      pageSize: 0
      sorting: {filterOption: CODE_TYPE, sortingCriterionOrder: 1, sortingDirection: DESCENDING}
    ) {

      results{
    code
    codeType
    mappedCodeType
    deviceVersion{
      id
      displayName
      type{
        displayName
        name
      }
      version
    }
    notificationTexts{
      title
      languageCode
    }

  }
    }
  }
`;


interface ErrorStatesFilterListQueryProp {
  state: ErrorStatesFilterState | undefined,
  onChangeFilter: (selectedDeviceVersion: number, selectedCodeType: number, selectedMessage: string) => void
}

const TodoPrivateListQuery: React.FC<ErrorStatesFilterListQueryProp> = ({ state, onChangeFilter }) => {
  const { loading, error, data } = useQuery<deviceNotificationCodeDefinitions>(QUERY_DEVICE_NOTIFICATIONS);
  const [selectedDeviceVersion, setSelectedDeviceVersion] = useState<number>(-1);
  const [selectedCodeType, setSelectedCodeType] = useState<number>(-1);
  const [selectedMessage, setSelectedMessage] = useState<string>("");

  useEffect(() => {
    setSelectedCodeType(-1);
    setSelectedMessage("");
  }, [selectedDeviceVersion]);

  useEffect(() => {
    setSelectedMessage("");
  }, [selectedCodeType]);

  if (loading) {
    return <div style={{ height: "100%", textAlign: "center" }}><CircularProgress style={{ position: "absolute", top: "50%", left: "50%", margin: "-15px 0 0 -30px" }} color="secondary" /></div>;
  }
  if (error) {
    console.error(error);
    return <div>Error!</div>;
  }

  interface DeviceVersion {
    label: string,
    value: number,
    childs: CodeType[]
  }

  interface CodeType {
    label: string,
    value: number,
    childs: Message[]
  }

  interface Message {
    label: string,
    value: string
  }


  interface filterObj {
    label: string,
    value: number
  }

  interface filterObjMessage {
    label: string,
    value: string
  }

  const nestedFilterLists: DeviceVersion[] = [];

  if (data?.deviceNotificationCodeDefinitions?.results) {
    data.deviceNotificationCodeDefinitions.results.forEach((plainRecord) => {

      if (plainRecord && plainRecord?.deviceVersion?.id) {

        let newDevice: DeviceVersion | undefined;

        if (!(newDevice = nestedFilterLists.find(device => device.value === plainRecord?.deviceVersion?.id))) {
          newDevice = {
            label: plainRecord.deviceVersion.displayName + " / " + plainRecord.deviceVersion.version,
            value: plainRecord.deviceVersion.id,
            childs: []
          };
          nestedFilterLists.push(newDevice);
        }


        let newCodeType: CodeType | undefined;

        if (!(newCodeType = newDevice.childs.find(codeType => codeType.value === plainRecord?.codeType))) {
          newCodeType = {
            label: plainRecord.mappedCodeType || '',
            value: plainRecord.codeType,
            childs: []
          };
          newDevice.childs.push(newCodeType);
        }

        let newMessage: Message | undefined;

        const messageTitle = plainRecord?.notificationTexts?.find(msgLang => msgLang?.languageCode === 'de')?.title || '';
        if (!(newMessage = newCodeType.childs.find(message => message.label === messageTitle))) {
          newMessage = {
            label: messageTitle,
            value: plainRecord.code
          };
          newCodeType.childs.push(newMessage);
        }
      }
    })
  }



  let btnVariant: 'text' | 'outlined' | 'contained' = 'contained';

  const setDeviceVersion = (
    deviceVersionFilter: ValueType<filterObj>
  ) => {
    const deviceVersionFilterObj = deviceVersionFilter as filterObj;
    setSelectedDeviceVersion(deviceVersionFilterObj.value);
  };

  const setCodeType = (
    codeTypeFilter: ValueType<filterObj>
  ) => {
    const codeTypeFilterObj = codeTypeFilter as filterObj;
    setSelectedCodeType(codeTypeFilterObj.value);
  };

  const setMessage = (
    messageFilter: ValueType<filterObjMessage>
  ) => {
    const messageFilterObj = messageFilter as filterObjMessage;
    setSelectedMessage(messageFilterObj.value);
  };

  const hideErrorStatesFilter = () => {
    if (document.querySelector('.ag-popup')) document.querySelector('.ag-popup')?.classList.add("hidden");
  }

  const submit = () => {
    onChangeFilter(selectedDeviceVersion, selectedCodeType, selectedMessage);
    hideErrorStatesFilter();
  }

  const reset = () => {
    setSelectedDeviceVersion(-1);
    setSelectedCodeType(-1);
    setSelectedMessage("");
    onChangeFilter(-1, -1, "");
    hideErrorStatesFilter();
  }

  const selDevice = nestedFilterLists.find(deviceVersion => deviceVersion.value === selectedDeviceVersion);
  const selCode = selDevice && selDevice.childs.find(codeType => codeType.value === selectedCodeType);

  return (
    <Translate>{({ translate }) => (
      <Fragment>
        <div style={{ position: "relative" }} >
          <Select
            value={selectedDeviceVersion > -1 ? nestedFilterLists.find(child => child.value === selectedDeviceVersion) : null}
            input={<Input />}
            name="deviceVersion"
            styles={customStyles}
            menuContainerStyle={{ 'zIndex': 999 }}
            onChange={setDeviceVersion}
            renderValue={() => (translate("types.product.deviceVersion") as string)}
            options={nestedFilterLists}
          />
          <Select
            value={selectedCodeType > -1 ? selDevice?.childs.find(child => child.value === selectedCodeType) : null}
            input={<Input />}
            name="codeType"
            styles={customStyles}
            menuContainerStyle={{ 'zIndex': 999 }}
            isDisabled={!selectedDeviceVersion}
            onChange={setCodeType}
            renderValue={() => (translate('components.filter.errorStates.codeType') as string)}
            options={selDevice?.childs}
          />
          <Select
            value={selectedMessage ? selCode?.childs.find(child => child.value === selectedMessage) : null}
            input={<Input />}
            name="message"
            styles={customStyles}
            menuContainerStyle={{ 'zIndex': 999 }}
            isDisabled={!selectedCodeType}
            onChange={setMessage}
            renderValue={() => (translate('components.filter.errorStates.message') as string)}
            options={selCode?.childs}
          />
          <Button variant={btnVariant} onClick={submit} color="primary" disabled={selectedDeviceVersion === -1}>
            <Translate id="generics.ok" />
          </Button>
          <Button variant="outlined" onClick={reset} color="primary" style={{ position: "absolute", right: 0 }} disabled={selectedDeviceVersion === -1}>
            <Translate id="generics.reset" />
          </Button>
        </div>
      </Fragment>
    )}
    </Translate>

  )
};


type ErrorStatesFilterProps = {
  valueGetter: (row: any) => string
  filterChangedCallback: () => void
}

type ErrorStatesFilterState = {
  selectedDeviceVersion: number
  selectedCodeType: number
  selectedMessage: string
}

type filterModel = {
  value: ErrorStatesFilterState | undefined
}


export default class PartialMatchFilter extends Component<ErrorStatesFilterProps, ErrorStatesFilterState | undefined> {
  valueGetter: (row: any) => string;


  constructor(props: ErrorStatesFilterProps) {
    super(props);

    this.state = undefined;

    this.valueGetter = this.props.valueGetter;
    this.onChange = this.onChange.bind(this);
  }

  isFilterActive() {
    return !!this.state;
  }

  getModel(): filterModel {
    return { value: this.state };
  }

  setModel(model: filterModel) {
    this.setState({
      text: model ? model.value : ''
    });
  }


  componentMethod(message: string) {
    alert(`Alert from ErrorStatesFilterComponent: ${message}`);
  }

  onChange(selectedDeviceVersion: number, selectedCodeType: number, selectedMessage: string) {
    if (!this.state
      || this.state.selectedDeviceVersion !== selectedDeviceVersion
      || this.state.selectedCodeType !== selectedCodeType
      || this.state.selectedMessage !== selectedMessage) {
      this.setState(
        { selectedDeviceVersion, selectedCodeType, selectedMessage },
        () => {
          this.props.filterChangedCallback();
        }
      );
    }

  }

  render() {
    const style = {
      width: '300px',
      height: '200px',
      padding: '10px'
    };

    return (
      <div style={style}>
        <TodoPrivateListQuery state={this.state} onChangeFilter={this.onChange} />
      </div>
    );
  }
}