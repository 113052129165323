import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { Error } from 'types/CampGuideTypes';

import { ActionType, ChangeData, Action } from 'redux/types/AccountShortcuts/ChangeLogin';

import * as Types from 'types/ToolboxEntities';


export const open = (user: Types.UserInformation): Action => ({
  type: ActionType.OPEN,
  payload: {
    userId: user.id,
    email: user.email,
    password: ''
  }
});

export const close = (): Action => ({
  type: ActionType.CLOSE,
});

export const error = (error: Error): Action => ({
  type: ActionType.ERROR,
  payload: error
});

export const loading = (): Action => ({
  type: ActionType.LOADING,
});



export const change = (changeDate: ChangeData): ThunkAction<Promise<void>, {}, {}, Action> => {
  return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {

    //  dispatch(loading());
    // GraphQL.changeLoginData(changeDate.userId, changeDate.password, changeDate.email).then((data: any) => {
    //   console.log("change login succeeded");
    //   console.log('DATA', data);
    //   dispatch(close());
    // }).catch((errorData: Error) => {
    //   console.log('ERROR', errorData);
    //   dispatch(error(errorData));
    // });

  };
};
