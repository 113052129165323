export enum ActionType {
  OPEN = 'REMOVE_FROM_COMPANY_OPEN',
  CLOSE = 'REMOVE_FROM_COMPANY_CLOSE',
}

export interface ChangeData {
  partnerId: number
  userId: number
}

export interface Store {
  changeData: ChangeData | null,
}


export interface OpenAction {
  type: ActionType.OPEN,
  payload: ChangeData
}

export interface CloseAction {
  type: ActionType.CLOSE,
}

export type Action = OpenAction | CloseAction;
