import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  CheckCircleOutlineRounded,
  HighlightOff,
  ErrorOutline,
  //Height
} from "@material-ui/icons";
import bwtColors from "themes/bwtColors";
import { NotificationType } from "../../../types/apolloGenerated/globalTypes"

import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({

    notificationPanelInnerContainer: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: 15,
      overflow: "hidden",
      minWidth: "1050px",
      maxWidth: "1700px"
    },


    notifContainer: {
      display: "flex",
      border: "1px solid #B5B9BB",
      height: "70px",
      alignItems: "center",
      padding: "20px",
      fontSize: "15px",
      margin: "10px",
      width: "calc(20% - 20px)",

      '@media (max-width: 1799px)': {
        width: "calc(25% - 20px)",
      },

      '@media (max-width: 1299px)': {
        width: "calc(33.3333% - 20px)",
      },
    }
  });
});

export interface DisplayStatusProps {
  code: NotificationType | null
}

const Status: React.FC<{code: NotificationType }> = ({code}) => {
  const classes = useStyles();
  return (
    <div className={classes.notifContainer}>
      {code === NotificationType.NOT_SYNCHED ? getIcon(NotificationType.ERROR) : getIcon(code)}
      <div style={{ paddingLeft: 20 }}>
        <div>
          {code === NotificationType.NOT_SYNCHED ? "DISCONNECTED" : code }
        </div>
      </div>
    </div>
  )
}

const getIcon = (type: NotificationType) => {
  switch (type) {
    case NotificationType.ERROR:
    case NotificationType.INTERNAL_ERROR:
      return <HighlightOff style={{ fontSize: "50px" }} color="error" />;
    case NotificationType.WARNING:
    case NotificationType.SERVICE:
      return (
        <ErrorOutline
          style={{ fontSize: "50px", color: bwtColors.warnYellow }}
        />
      );
    default:
      return <CheckCircleOutlineRounded style={{ fontSize: "50px" }} color="primary" />
  }
};

const DeviceStatus: React.FC<DisplayStatusProps> = ({ code }) => {
  const classes = useStyles();
  // let deviceDisconnected = false;
  // let disconnectCodeType = NotificationType.ERROR;

  if (code === NotificationType.NOT_SYNCHED) {
    // deviceDisconnected = true;
    // if (code?.includes("Warning")) {
    //   disconnectCodeType = NotificationType.WARNING
    // }
  }

  return (
    <Box className={classes.notificationPanelInnerContainer}>
        <Status code={ code ?? NotificationType.ERROR} />
    </Box>
  );
};

export default DeviceStatus;
