import { State } from 'redux/types/AgGrid';
import { ActionType, SearchAction } from 'redux/types/ProductOverview';
import generateGridReducer from 'redux/reducers/AgGridReducerHelper';
import { combineReducers } from 'redux';

const GridState: State = {
  sortModel: [],
  filterModel: {
    "productInformation.isProductRegistrationEnabled":{
      filterType:"set",
      values:["true"]
    }
  },
  page: 0,
  columnStates: undefined,
};


const searchReducer = (state: string | null = null, action: SearchAction): string | null => {
  switch (action.type) {

    case ActionType.SEARCH:
      return action.payload;

    default:
      return state;
  }
}

export default combineReducers({
  grid: generateGridReducer<ActionType>(ActionType.GRID, GridState),
  search: searchReducer,
});