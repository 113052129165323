import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';


import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';

import { AppState } from 'redux/store';

import { Store } from 'redux/types/DeviceActions/ResetActivation';
import { close } from 'redux/actions/DeviceActions/ResetActivationActions';

import gql from "graphql-tag";

import { Translate } from "react-localize-redux";
import { useMutation } from "@apollo/react-hooks";

import Error from 'components/common/Error';
import { ErrorCodes } from 'types/CampGuideTypes';

import {
  resetActivation as MutationData,
  resetActivationVariables as MutationVars
} from "types/apolloGenerated/resetActivation";





interface IChangeOrganizationActiveState {
  store: Store
  actions: {
    close: typeof close
  }
}


const RESETACTIVATION = gql`
mutation resetActivation($productCode: String!){
  productInstance{
    resetActivation(productCode: $productCode)
  }
}
`;



const ResetActivation: React.FC<IChangeOrganizationActiveState> = ({ store, actions }) => {

  const [resetActivation, { loading, error }] = useMutation<MutationData, MutationVars>(
    RESETACTIVATION, {
    update: () => {
      if (!error) {
        // ToDo: Apollo-Cache-Update
        actions.close();
      }
    }
  }
  );


  const submit = () => {
    if (store.openWithProductCode) {
      //console.log("reset activation", store.openWithProductCode);
      resetActivation({
        variables: { productCode: store.openWithProductCode }
      })
    }
  };

  const errorElement = error ? <Error error={{ code: ErrorCodes.Unknown, message: error.message }} /> : null;
  const progress = loading ? <CircularProgress color="secondary" /> : null;

  return (
    <Dialog open={Boolean(store.openWithProductCode)} onClose={actions.close} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="form-dialog-title"><Translate id="types.common.resetActivation" /></DialogTitle>
      <DialogContent>
        <DialogContentText><Translate id="components.resetActivation.subtitle" /></DialogContentText>
        {errorElement}
      </DialogContent>
      <DialogActions>
        {progress}
        <Button onClick={actions.close} color="primary" disabled={loading}>
          <Translate id="generics.cancel" />
        </Button>
        <Button onClick={submit} color="primary" disabled={loading}>
          <Translate id="generics.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: AppState) => ({
  store: state.deviceActions.resetActivation,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ close }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetActivation);
