import * as React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} {...props}>
    <path fill="none" d="M0 0h25v25H0z" />
    <path
      data-name="Path 3185"
      d="M13.546 14.579h-2.084v-4.166h2.084v4.166Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      data-name="Path 3186"
      d="M11.462 18.746h2.084v-2.083h-2.084Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      data-name="Path 3187"
      d="M1.046 21.871h22.916L12.504 2.079ZM12.504 5.198l8.748 15.111H3.755Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgComponent;
