import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import cloneDeep from 'clone-deep';

import gql from "graphql-tag";
import { useMutation } from '@apollo/react-hooks';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';

import { AppState } from 'redux/store';

import { Store as RemoveFromCompanyStore } from 'redux/types/AccountShortcuts/RemoveFromCompany';
import { close } from 'redux/actions/AccountShortcuts/RemoveFromCompanyActions';

import { Translate } from "react-localize-redux";

import Error from 'components/common/Error';

import { QUERY_EMPLOYEE_ORGANIZATION } from 'commonQueries/employee';
import { QUERY_PARTNER_EMPLOYEES } from 'commonQueries/partner';

import { ErrorCodes } from 'types/CampGuideTypes';
import { OrganizationOfEmployee } from 'types/apolloGenerated/OrganizationOfEmployee';
import { PartnerEmployees } from 'types/apolloGenerated/PartnerEmployees';



const REMOVE_FROM_COMPANY = gql`
mutation RemoveFromCompany($partnerId: Int!, $userId: Int!){
  organization{
    deleteEmployee(
          organizationId: $partnerId,
          userId: $userId
    )
  }
}
`;


interface RemoveFromCompanyProps {
  remFrmCmpny: RemoveFromCompanyStore
  actions: {
    close: typeof close
  }
}

const RemoveFromCompany: React.FC<RemoveFromCompanyProps> = ({ remFrmCmpny, actions }) => {

  const [mutate, { loading, error }] = useMutation(REMOVE_FROM_COMPANY, {
    update(cache) {
      //      
      // Update CACHE: Employees-Array within Organization
      //
      try {
        const variables = {
          id: remFrmCmpny.changeData?.partnerId
        };

        const queryCache = cloneDeep(cache.readQuery<PartnerEmployees>({
          query: QUERY_PARTNER_EMPLOYEES,
          variables: variables,
        }));

        if (queryCache?.organization?.employees?.results) {

          queryCache.organization.employees.results = queryCache.organization.employees.results.filter((item, idx) => {
            return (item && item?.userInformation?.key !== remFrmCmpny.changeData?.userId);
          });

          cache.writeQuery({
            query: QUERY_PARTNER_EMPLOYEES,
            variables: variables,
            data: queryCache
          });
        } else {
          console.log('Trying to update CACHE, but emplyees not found');
        }
      } catch (e) { }


      //
      // Update CACHE: Organization within Employee-Detail-Data
      //
      try {
        const variablesOrgOfEmployee = {
          id: remFrmCmpny.changeData?.userId
        };

        const cachedOrgOfEmployee = cloneDeep(cache.readQuery<OrganizationOfEmployee>({
          query: QUERY_EMPLOYEE_ORGANIZATION,
          variables: variablesOrgOfEmployee,
        }));

        if (cachedOrgOfEmployee?.employee) {
          cachedOrgOfEmployee.employee.organization = null;

          cache.writeQuery({
            query: QUERY_EMPLOYEE_ORGANIZATION,
            variables: variablesOrgOfEmployee,
            data: cachedOrgOfEmployee
          });
        } else {
          console.warn('Trying to update CACHE (Set Partner to Employee), but emplyee not found');
        }
      } catch (e) { }


      if (!error) {
        actions.close();
      } else {
        console.log('Error', error);
      }
    }
  });

  const submit = () => {
    if (remFrmCmpny.changeData) {
      mutate({
        variables: {
          userId: remFrmCmpny.changeData.userId,
          partnerId: remFrmCmpny.changeData.partnerId,
        }
      }).catch((errorData) => {
        console.log('errorData', errorData);
      });
    }
  };


  const errorElement = error ? <Error error={{ code: ErrorCodes.Unknown, message: error.message }} /> : null;
  const progress = loading ? <CircularProgress color="secondary" /> : null;

  return (
    <Dialog open={Boolean(remFrmCmpny.changeData)} onClose={actions.close} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="form-dialog-title"><Translate id="components.removeFromCompany.title" /></DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Translate id="components.removeFromCompany.body" />
        </DialogContentText>
        {errorElement}
      </DialogContent>
      <DialogActions>
        {progress}
        <Button onClick={actions.close} color="primary" disabled={loading}>
          <Translate id="generics.cancel" />
        </Button>
        <Button onClick={submit} color="primary" disabled={loading}>
          <Translate id="generics.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: AppState) => ({
  remFrmCmpny: state.accountShortcuts.removeFromCompany,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ close }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveFromCompany);
