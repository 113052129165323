import React from "react";
import format from "date-fns/format";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/HighlightOff";
import WarnIcon from "@material-ui/icons/ErrorOutline";
import InfoIcon from "@material-ui/icons/CheckCircleOutline";

import colours from "../../../../themes/bwtColors";
import { Level } from "./types";
import { Translate } from "react-localize-redux";

import {
  productInstanceAndCustomerDetails_productInstance_instanceInformation_SilkInstanceInformation_notifications as SilkNotifArray,
} from "types/apolloGenerated/productInstanceAndCustomerDetails"

const iconStyle = {
  flexShrink: 0,
  margin: 10,
  fontSize: 30
};

const useStyles = makeStyles(() => ({
  root: { display: "flex", alignItems: "center" },
  errorIcon: { ...iconStyle, color: colours.errorRed },
  warnIcon: { ...iconStyle, color: colours.warnYellow },
  infoIcon: { ...iconStyle, color: colours.arctic }
}));

interface NotificationProps {
  notification: SilkNotifArray | null;
}

const getMessage = (messageType: number) => {
  switch (messageType) {
    case 3001:
      return <Translate id="components.silk.messages.emailActivation" />
    case 3002:
      return <Translate id="components.silk.messages.activationResponse" />
    case 6001:
      return <Translate id="components.silk.messages.resetState" />
    case 6002:
      return <Translate id="components.silk.messages.confirmNotification" />
    case 5003:
      return <Translate id="components.silk.messages.firmwareUpdateRequest" />
    case 6003:
      return <Translate id="components.silk.messages.firmwareUpdateResponse" />
    case 5004:
      return <Translate id="components.silk.messages.pingRequest" />
    case 6004:
      return <Translate id="components.silk.messages.pingResponse" />
    case 5005:
      return <Translate id="components.silk.messages.connectivityTestRequest" />
    case 6005:
      return <Translate id="components.silk.messages.connectivityTestResponse" />
    case 5006:
      return <Translate id="components.silk.messages.setParameterRequest" />
    case 6006:
      return <Translate id="components.silk.messages.setParameterResponse" />
    case 2000:
      return <Translate id="components.silk.messages.information" />
    case 7000:
      return <Translate id="generics.warning" />
    case 8000:
      return <Translate id="generics.error" />
    case 2010:
      return <Translate id="components.silk.messages.serviceDue" />
    case 2011:
      return <Translate id="components.silk.messages.warrantyDue" />
    case 7012:
      return <Translate id="components.silk.messages.saltReminder" />
    case 7070:
      return <Translate id="components.silk.messages.controlBoardToESPError" />
    case 8013:
      return <Translate id="components.silk.messages.error1" />
    case 8014:
      return <Translate id="components.silk.messages.internalCommunicationError" />
    case 5010:
      return <Translate id="components.silk.messages.renewService" />
    case 5011:
      return <Translate id="components.silk.messages.renewWarranty" />
    case 5012:
      return <Translate id="components.silk.messages.startRegeneration" />
    case 60121:
      return <Translate id="components.silk.messages.regenerationResponseStart" />
    case 60122:
      return <Translate id="components.silk.messages.regenerationResponseEnd" />
    case 5013:
      return <Translate id="components.silk.messages.startFastCycle" />
    case 60131:
      return <Translate id="components.silk.messages.fastCycleStart" />
    case 60132:
      return <Translate id="components.silk.messages.fastCycleEnd" />
    default:
      return <div></div>
  }

}

const Notifcation: React.FC<NotificationProps> = ({ notification }) => {
  const classes = useStyles();
  const notifData = JSON.parse(notification?.message ?? "{}")
  const typeAsString = notification?.messageType?.toString()
  const level = typeAsString?.startsWith("7") ? Level.W : typeAsString?.startsWith("8") ? Level.E : Level.I
  let headerAsString = typeAsString;
  let header = Number(headerAsString);
  if (headerAsString === "6012" || headerAsString === "6013") {
    if (notifData?.Data?.state.toString() === "1") {
      headerAsString += 1
      header = Number(headerAsString);
    }
    else if (notifData?.Data?.state.toString() === "2") {
      headerAsString += 2
      header = Number(headerAsString);
    }
  }

  return (
    <div className={classes.root}>
      {level === Level.E ? (
        <ErrorIcon className={classes.errorIcon} />
      ) : level === Level.W ? (
        <WarnIcon className={classes.warnIcon} />
      ) : (
            <InfoIcon className={classes.infoIcon} />
          )}
      <div>
        <div>{getMessage(header ?? 0)}</div>
        <Translate>
          {
            (translate) => {
              return <div>{format(new Date(notifData?.Timestamp), translate.translate('generics.dateTimeFormatFNS') as string)}</div>;
            }
          }
        </Translate>
        {notifData.Data && notifData.Data.email ? <div> {notifData.Data.email} </div> : <div />}
      </div>
    </div>
  );
};

export default Notifcation;
