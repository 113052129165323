import React, { useState, useEffect, Fragment } from "react";

import { connect } from "react-redux";
import { Dispatch } from "redux";

import { withLocalize, LocalizeContextProps, Translate, TranslateFunction } from "react-localize-redux";

import { Box } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { endOfMonth, getMonth, getYear } from "date-fns";

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { AppState } from "redux/store";

import CountryFilter from "components/common/CountryFilter";
import PageHeader from "components/common/PageHeader";
import GridFilterHeader from "components/common/Grid/GridFilterHeader";
import TextField from "@material-ui/core/TextField";

import MonthPicker, { YearMonth } from "./MonthPicker";

import { UserProfile } from "types/ToolboxEntities";
import { Country } from "types/apolloGenerated/globalTypes";

import PearlsGrid from './PearlsGrid';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    partnerOverview: {
      position: "relative",
      display: "flex",
      flexFlow: "column",

      "& > .gridFilterHeader": {
        flex: "0 1 auto",
        justifyContent: "flex-end"
      },

      "& > .innerContent": {
        flex: "1 1 auto"
      }
    },
    fromToString: {
      display: "inline-block",
      margin: "5px 20px 0"
    },
    textField: {
      [`& input::-webkit-clear-button,
      & input::-webkit-outer-spin-button,
      & input::-webkit-inner-spin-button,
      & input::-webkit-toolbarbutton-dropdown`]: {
        display: "none"
      },
      width: 140
    },
    innerHeaderRight: {
      flex: "0 1 auto",
      display: "flex",
      alignItems: "flex-end",
      margin: 0,
      border: "solid 2px grey20",
      width: "350px",
      borderRadius: "5px",
      height: "48px",

      "& > *": {
        flex: "0 1 auto",
        margin: "0 20px",
        minWidth: "170px"
      }
    },
  });
});



interface PearlsPara extends LocalizeContextProps {
  translate: TranslateFunction
  user: UserProfile | null
}

const Pearls: React.FC<PearlsPara> = ({ user }) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState<string>("");
  const [monthYearMaster, setMonthYearMaster] = React.useState<boolean>(true);


  const [yearMonth, setYearMonth] = useState<YearMonth>(() => {
    const now = new Date();
    return {
      year: getYear(now),
      month: getMonth(now) + 1 //+1 as the month starts with january being 0
    };
  });

  const [country, setCountry] = useState<string | undefined>(user?.allowedCountries[0])
  const startDate = new Date(`${yearMonth.year}-${yearMonth.month}`);
  startDate.setDate(startDate.getDate());

  const endDate = endOfMonth(new Date(`${yearMonth.year}-${yearMonth.month}`));
  const [selectedStartDateInputValue, setSelectedStartDateInputValue] = React.useState(startDate);
  const [selectedEndDateInputValue, setSelectedEndDateInputValue] = React.useState(endDate);
  const [startQueryDate, setStartQueryDate] = React.useState(startDate);
  const [endQueryDate, setEndQueryDate] = React.useState(endDate);

  const startingDay = monthYearMaster ? startDate : selectedStartDateInputValue;
  const endingDay = monthYearMaster ? endDate : selectedEndDateInputValue;

  const selectedStartingDayString = startingDay.getUTCFullYear() + "-" + ("0" + (startingDay.getMonth() + 1)).slice(-2) + "-" + ("0" + startingDay.getDate()).slice(-2);
  const selectedEndingDayString = endingDay.getUTCFullYear() + "-" + ("0" + (endingDay.getMonth() + 1)).slice(-2) + "-" + ("0" + endingDay.getDate()).slice(-2);


  if (new Date(selectedEndDateInputValue) > new Date()) {
    setSelectedEndDateInputValue(new Date())
  }

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonthYearMaster(true);
    setSearch(event.target.value);
  };

  const setSelectedStartDateInput = (e: any) => {
    setMonthYearMaster(false);
    setSelectedStartDateInputValue(new Date(e));
  }
  const setSelectedEndDateInput = (e: any) => {
    setMonthYearMaster(false);
    setSelectedEndDateInputValue(new Date(e));
  }

  const setYearMonthPicker = (e: any) => {
    setMonthYearMaster(true);
    setYearMonth(e);
  }

  if (monthYearMaster) {
    setSelectedStartDateInputValue(startDate);
    setSelectedEndDateInputValue(endDate);
    setStartQueryDate(startDate);
    setEndQueryDate(endDate);
    setMonthYearMaster(false);
  }

  useEffect(() => {

    const troubles = document.querySelectorAll("#datePicker input");
    troubles.forEach((item) => {

      const addFunction = (e: any) => {
        const eventTarget = (e.target as HTMLInputElement);
        if (eventTarget.name === "startingDay") {
          setSelectedStartDateInputValue(new Date(eventTarget.value));
          setStartQueryDate(new Date(eventTarget.value));

        }
        if (eventTarget.name === "endingDay") {
          setSelectedEndDateInputValue(new Date(eventTarget.value));
          setEndQueryDate(new Date(eventTarget.value));
        }
      }

      item.removeEventListener("change", addFunction)
      item.addEventListener("change", addFunction);
    });
  }, []);



  return (
    <Fragment>
      <PageHeader title="types.common.pearls" />
      <main
        className={"mainContentContainer fullHeight " + classes.partnerOverview}
      >
        <GridFilterHeader className="gridFilterHeader">
          <TextField
            className="filter"
            id="standard-basic"
            label="Type to filter"
            value={search}
            onChange={changeSearch}
          />
          <div className="innerHeaderRight" style={{ marginLeft: "auto" }}>
            {user?.userInformation.address.countryCode !== "BE" && (
              <div>
                <MonthPicker
                  defaultValue={yearMonth}
                  onChange={setYearMonthPicker}
                />
              </div>
            )}
            <div id="datePicker" style={{ margin: "0 30px" }}>
              <TextField
                className={classes.textField}
                name={"startingDay"}
                type="date"
                value={selectedStartingDayString}
                onChange={(e) => setSelectedStartDateInput(e.target.value)}
              />
              <span className={classes.fromToString}>
                {" "}
                <Translate id="pages.pam.partner.to" />
              </span>
              <TextField
                className={classes.textField}
                name={"endingDay"}
                type="date"
                value={selectedEndingDayString}
                onChange={(e) => setSelectedEndDateInput(e.target.value)}
              />
            </div>
            <Box>
              <CountryFilter
                selected={country ? [country] : []}
                onChange={(country) => setCountry(country.toString())}
                allowMultipleSelection={false}
                showSelectAll={false}
              />
            </Box>
          </div>
        </GridFilterHeader>

        <div className="innerContent">
          <PearlsGrid
            country={country as Country}
            from={startQueryDate}
            to={endQueryDate}
            search={search}
          />
        </div>
      </main>
    </Fragment>
  );
};

const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Pearls));
