import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Translate } from "react-localize-redux";
import gql from "graphql-tag";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import colours from "themes/bwtColors";
import PageHeader from "components/common/PageHeader";
import {
  PartnerInfo as QueryData,
  PartnerInfoVariables as QueryVars
} from "types/apolloGenerated/PartnerInfo";

const QUERY = gql`
  query PartnerInfo($id: Int!) {
    organization(id: $id) {
      id
      name
      email
      telephone
      partnerType
      externalId
      address {
        street
        postalCode
        city
        countryCode
      }
    }
  }
`;

const ServicePartner: React.FC = () => {
  const { partnerId = "0" } = useParams();
  const id = Number(partnerId);
  const { data } = useQuery<QueryData, QueryVars>(QUERY, {
    variables: { id }
  });

  const organization = (data || {}).organization || null;

  const {
    name = "",
    address = "",
    email = "",
    telephone = "",
    partnerType = "",
    externalId = ""
  } = organization || {};

  const { street, postalCode, city, countryCode } = address || {};

  return (
    <Box>
      <PageHeader showBack title={name || "Unknown"} />
      <Box
        display="flex"
        flexWrap="wrap"
        paddingTop="40px"
        maxWidth="800px"
        flexDirection="column"
        marginLeft="25px"
      >
        <Box paddingBottom={5}>
          <TextBox value={name} translateId="components.partnerDetails.name" />
        </Box>

        <Box display="flex" flexDirection="row" paddingBottom={5}>
          <Box>
            <TextBox value={street} translateId="types.address.street" />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <TextBox
              value={postalCode}
              translateId="types.address.postalCode"
              width="95px"
            />
            <TextBox
              value={city}
              translateId="types.address.city"
              width="145px"
            />
            <TextBox
              value={countryCode}
              translateId="types.address.countryCode"
              width="60px"
            />
          </Box>
        </Box>

        <Box paddingBottom={5}>
          <TextBox
            value={email}
            translateId="generics.email"
          />
          <TextBox
            value={telephone}
            translateId="components.partnerDetails.telephone"
          />
        </Box>
        <Box>
          <TextBox
            value={partnerType}
            translateId="components.partnerDetails.partnerType"
          />
          <TextBox
            value={externalId}
            translateId="components.partnerDetails.customerNumber"
          />
        </Box>
      </Box>
    </Box>
  );
};

const TextBox: React.FC<{
  value: string | undefined | null;
  translateId: string;
  width?: string;
}> = ({ value, translateId, width = "350px" }) => {
  return (
    <TextField
      id={`service-partner-${translateId.split(".").pop()}`}
      value={value || ""}
      label={<Translate id={translateId} />}
      variant="filled"
      InputLabelProps={{
        shrink: true
      }}
      style={{
        marginRight: "25px",
        border: "solid 1px",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        backgroundColor: colours.grey5,
        width: width
      }}
    />
  );
};

export default ServicePartner;
