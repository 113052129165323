import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    gridFilterHeader: {
      display: 'flex',
      margin: '10px -20px 20px -20px',
      alignItems: 'flex-end',
      justifyContent: 'space-between',

      '& > *': {
        flex: '0 1 auto',
        margin: '0 20px',
        minWidth: '170px',
      },

      '& > .filter': {
        flex: '1 1 auto',
        maxWidth: "250px",
      },

      '& > .innerHeaderRight': {
        flex: '0 1 auto',
        display: 'flex',
        alignItems: 'flex-end',
        margin: 0,

        '& > *': {
          flex: '0 1 auto',
          margin: '0 20px',
        },
      }
    },
  })
});


const GridFilterHeader: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, className }, props) => {
  const classes = useStyles();

  return <div className={className + ' ' + classes.gridFilterHeader} {...props}>{children}</div>;
}

export default GridFilterHeader;
