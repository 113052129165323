import React from "react";
import { Translate, TranslateFunction } from "react-localize-redux";
import format from "date-fns/format";

import gql from "graphql-tag";
import { couponCodes } from "types/apolloGenerated/couponCodes";

import { ColDef } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { OrganizationFilterOption } from "types/ToolboxEntities";

import { factoryApolloConnector, ApolloFilter } from "util/AgGridUtils";
import { Check, Close } from "@material-ui/icons";

import { CouponCodeFilterOption, FilterOperator } from "types/apolloGenerated/globalTypes";

export const COUPON_CODE_GRID = gql`
  query couponCodes(
    $page: Int
    $pageSize: Int
    $filter: [CouponCodeFilter]
    $sorting: [SortingCriterionForCouponCodeFilterOption]
  ) {
    couponCodes(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sorting: $sorting
    ) {
      page
      pageSize
      totalResults
      results {
        id
        code
        creationDate
        validFrom
        validTo
        isActive
        consumptionDate
        productCode
      }
    }
  }
`;

const colMapping = {
  id: OrganizationFilterOption.ID,
  name: OrganizationFilterOption.NAME,
  partnerType: OrganizationFilterOption.PARTNER_TYPE,
  email: OrganizationFilterOption.EMAIL,
  "address.cite": OrganizationFilterOption.CITY,
  "address.countryCode": OrganizationFilterOption.COUNTRY,
  code: CouponCodeFilterOption.CODE,
};

const searchColumns = [CouponCodeFilterOption.CODE];

export const apolloConnectorByPromotion = (id: string | undefined) => {
  let options = {};
  if (id) {
    options = {
      filter: [
        {
          filterOption: [CouponCodeFilterOption.COUPON_PRODUCT_ID as String],
          operator: FilterOperator.EQ,
          values: [id],
        } as ApolloFilter,
      ],
    };
  }
  return factoryApolloConnector(
    COUPON_CODE_GRID,
    (data: couponCodes) => data.couponCodes,
    colMapping,
    options
  );
};

export const apolloConnectorBySearch = (searchTerm: string | null) => {
  return factoryApolloConnector(
    COUPON_CODE_GRID,
    (data: couponCodes) => data.couponCodes,
    colMapping,
    {
      search: {
        searchCols: searchColumns as string[],
        searchTerm,
      },
      defaultSort: [
        {
          colId: "isPendingOrganizationAssignment",
          sort: "desc",
        },
      ],
    },
  );
};

export const getColumnDefs = (translate: TranslateFunction): ColDef[] => {
  const columnDefs = [
    {
      headerName: "ID",
      field: "id",
      width: 60,
      filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      headerName: "Coupon Code",
      field: "code",
      width: 150,
      sortable: true,
    },
    {
      headerName: "Creation Date",
      field: "creationDate",
      filter: "agDateColumnFilter",
      cellRenderer: "FormatDate",
      width: 150,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: "Valid From",
      field: "validFrom",
      cellRenderer: "FormatDate",
      width: 150,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: "Valid To",
      field: "validTo",
      width: 150,
      cellRenderer: "FormatDate",
      filter: "agSetColumnFilter",
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: "Active",
      field: "isActive",
      width: 90,
      cellRenderer: "BoolCheck"
    },
    {
      headerName: "Issue For",
      field: "productCode",
      width: 170,
    },
    {
      headerName: "Issue At",
      field: "consumptionDate",
      width: 170,
      sortable: true,
      suppressMenu: true,
    },
  ];

  return columnDefs;
};

interface CellRenderer {
  value: string;
  data: couponCodes;
}

const FormatDate: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  //return <div>{format(new Date(value), "P")}</div>;
  if (!value) {
    return <div></div>;
  }
  return (
    <Translate>
      {(translate) => {
        return (
          <div>
            {format(
              new Date(value),
              translate.translate("generics.dateTimeFormatFNSShort") as string
            )}
          </div>
        );
      }}
    </Translate>
  );
};

const BoolCheck: React.FC<CellRenderer> = ({ data, value }) => {
  return <div>{value ? <Check color={"primary"} /> : <Close color={"error"} />}</div>
}

export const CellRenderer = {
  FormatDate,
  BoolCheck
};
