import React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setGridState, setColumnStates } from 'redux/actions/LinkedUsersAction';
import { open as changeLoginOpen } from 'redux/actions/AccountShortcuts/ChangeLoginActions';
import { open as sendResetOpen } from 'redux/actions/AccountShortcuts/SendResetActions';
import { open as changePositionOpen } from 'redux/actions/AccountShortcuts/ChangePositionActions';
import { open as removeFromCompanyOpen } from 'redux/actions/AccountShortcuts/RemoveFromCompanyActions';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { AgGridReact } from '@ag-grid-community/react';
import { defaultColDef } from 'util/AgGridUtils';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

import { AllEnterpriseModules } from '@ag-grid-enterprise/all-modules';
import { ColDef, MenuItemDef, GetContextMenuItemsParams } from '@ag-grid-enterprise/all-modules';
import { TranslateFunction } from 'react-localize-redux';

import { PartnerEmployees_organization_employees_results } from 'types/apolloGenerated/PartnerEmployees';

import MenuIcon from '@material-ui/icons/Menu'
import { Link } from "react-router-dom";

import { open as changePositionOpenAction } from 'redux/actions/AccountShortcuts/ChangePositionActions';
import { UserInformation } from 'types/ToolboxEntities';
import { Action as ChangeLoginAction } from 'redux/types/AccountShortcuts/ChangeLogin';
import { Action as ResetAction } from 'redux/types/AccountShortcuts/SendReset';
import { Action as RemoveFromCompanyAction } from 'redux/types/AccountShortcuts/RemoveFromCompany'

import { Position } from 'types/ToolboxEntities'

interface EmployeesGridProps extends LocalizeContextProps {
  partnerId: number
  employees: (PartnerEmployees_organization_employees_results | null)[]
  actions: {
    setGridState: typeof setGridState
    setColumnStates: typeof setColumnStates
    changeLoginOpen: typeof changeLoginOpen
    sendResetOpen: typeof sendResetOpen
    changePositionOpen: typeof changePositionOpen
    removeFromCompanyOpen: typeof removeFromCompanyOpen
  }
}

const EmployeesGrid = withLocalize(({ partnerId, employees, actions, translate }: EmployeesGridProps) => {
  return (
    <div className="innerContent ag-theme-balham">
      <AgGridReact
        defaultColDef={defaultColDef}
        frameworkComponents={CellRenderer}
        modules={[...AllEnterpriseModules, ClientSideRowModelModule]}
        columnDefs={getColumnDefs(translate)}
        getContextMenuItems={getContextMenuItems(
          partnerId,
          actions.changePositionOpen,
          actions.changeLoginOpen,
          actions.sendResetOpen,
          actions.removeFromCompanyOpen,
          translate
        )}
        rowData={employees}>
      </AgGridReact>
    </div>
  );
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ setGridState, setColumnStates, changeLoginOpen, sendResetOpen, changePositionOpen, removeFromCompanyOpen }, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(EmployeesGrid);

const getColumnDefs = (translate: TranslateFunction): ColDef[] => {
  const removeFilterSort = {
    sortable: false,
    filter: false,
    filterParams: {},
    menuTabs: []
  }

  const columnDefs = [
    {
      headerName: translate("types.common.name") as string,
      field: "userInformation.firstname",
      cellRenderer: "FullNameCell",
      width: 160,
      ...removeFilterSort
    },
    {
      headerName: translate('types.userInformation.position') as string,
      field: "position",
      width: 100,
      ...removeFilterSort
    },
    {
      headerName: translate("generics.email") as string,
      field: "userInformation.email",
      width: 200,
      ...removeFilterSort
    },
    {
      headerName: "",
      cellRenderer: "MenuCell",
      width: 50,
      resizable: false,
      onCellClicked: (params: any) => {
        const { rowIndex, node, column, event, value } = params;

        const cell = params.api.rowRenderer.rowCompsByIndex[rowIndex].getRenderedCellForColumn(column);

        cell.beans.contextMenuFactory.showMenu(node, column, value, event);
      },
      ...removeFilterSort

    }
  ];

  return columnDefs;
};

const getContextMenuItems = (
  partnerId: number,
  changePositionOpen: typeof changePositionOpenAction,
  changeLoginOpen: (user: UserInformation) => ChangeLoginAction,
  sendResetOpen: (user: UserInformation) => ResetAction,
  removeFromCompanyOpen: (userId: number, partnerId: number) => RemoveFromCompanyAction,
  translate: TranslateFunction
) => {
  return (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {

    let menuItems: (string | MenuItemDef)[] = [
      "copy",
      "copyWithHeaders",
      "separator",
      "export"
    ];

    if (params?.node?.data?.userInformation) {
      menuItems = [
        ...getExpandedMenuItems(
          partnerId,
          params.node.data as PartnerEmployees_organization_employees_results,
          changePositionOpen,
          changeLoginOpen,
          sendResetOpen,
          removeFromCompanyOpen,
          translate
        ),
        ...menuItems
      ];
    }

    return menuItems;
  }
};



const getExpandedMenuItems = (
  partnerId: number,
  rowData: PartnerEmployees_organization_employees_results,
  changePositionOpen: typeof changePositionOpenAction,
  changeLoginOpen: (user: UserInformation, partnerId: number) => ChangeLoginAction,
  sendResetOpen: (user: UserInformation) => ResetAction,
  removeFromCompanyOpen: (userId: number, partnerId: number) => RemoveFromCompanyAction,
  translate: TranslateFunction
) => {



 return [
  //{
  //  name: translate('components.employeesGrid.changePositionTo') as string,
  //  subMenu: getPositionSubMenu(partnerId, rowData, changePositionOpen),
  //},
  //{
  //  name: translate('components.employeesGrid.changeEmailAndPassword') as string,
  //  action: () => {
  //    console.log("change email and password");
  //    changeLoginOpen({
  //      id: rowData.userInformation!.id,
  //      email: rowData.userInformation!.email
  //    } as UserInformation, partnerId);
  //  },
  //},
  {
    name: translate('components.employeesGrid.sendPasswordEmail') as string,
    action: () => {
      console.log("send 'set password' email", rowData.userInformation!.id, " ", rowData.userInformation!.email);
      sendResetOpen({
        id: rowData.userInformation!.id,
        email: rowData.userInformation!.email
      } as UserInformation);
    },
  },
  //{
  //  name: translate('components.employeesGrid.removeFromCompany') as string,
  //  action: () => {
  //    console.log("remove from company");
  //    removeFromCompanyOpen(rowData.userInformation!.key, partnerId);
  //  },
  //},
    "separator"]
}

const getPositionSubMenu = (
  partnerId: number,
  rowData: PartnerEmployees_organization_employees_results,
  changePositionOpen: typeof changePositionOpenAction,
): (string | MenuItemDef)[] => {
  const subMenu: (string | MenuItemDef)[] = [];
  const fullName = `${rowData.userInformation?.firstName} ${rowData.userInformation?.lastName}`;

  for (const pos in Position) {
    if ('none' !== pos.toLowerCase()) {
      let disabled = rowData.position?.toLowerCase() === pos.toLowerCase();
      subMenu.push({
        name: pos,
        action: () => {
          console.log("rowData: ", rowData);
          console.log(`position changed to ${pos.toLocaleLowerCase()}`);
          changePositionOpen(
            rowData.userInformation!.key,
            Position[pos as keyof typeof Position],
            partnerId
          );
        },
        disabled: disabled,
        tooltip: disabled ?
          `Employee ${fullName} already has the position ${pos}` :
          `Change position of ${fullName} from ${rowData.position} to ${pos}`
      });
    }
  }
  return subMenu;
}

interface CellRenderer {
  value: string,
  data: PartnerEmployees_organization_employees_results | null,
};

const FullNameCell: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return null;
  }
  return <Link style={{ color: '#005D8F' }} to={"/pam/employee/" + data.userInformation?.key}>{`${data.userInformation?.firstName} ${data.userInformation?.lastName}`}</Link>;
};

const MenuCell: React.FC<CellRenderer> = ({ data, value }) => {
  return <MenuIcon />;
};

const LinkedUserLink: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return null;
  }
  return <Link to={"/cs/device/" + value}>{value}</Link>;
};

const CellRenderer = { FullNameCell, MenuCell, LinkedUserLink };