import React from "react";
import addMonths from "date-fns/addMonths";
import eachMonthOfInterval from "date-fns/eachMonthOfInterval";
import format from "date-fns/format";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AnimatedBarChart from "./AnimatedBarChart";

import LeftArrow from "../../../../../src/resources/graph_arrow_left_dark.svg";
import RightArrow from "../../../../../src/resources/graph_arrow_right_dark.svg";

import { Translate } from "react-localize-redux";
import Stats from "./Stats";

import {
  fillEmptyWaterConsumptionDataWithZeros,
  fillEmptySaltConsumptionDataWithZeros,
} from "./Chart";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    weekSwitcher: {
      display: "flex",
      alignItems: "center",
    },

    weekStatsSeg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      height: "80px",
      width: "100%",
    },

    animationFrame: {
      width: "100%",
      position: "absolute",
      transition: "opacity 200ms",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
    },

    selectedWeek: {
      display: "flex",
      justifyContent: "center",
      color: "#666b6e",
      fontSize: "20px",
      fontWeight: 700,
    },

    dash: {
      padding: "0 5px",
    },

    arrow: {
      cursor: "pointer",
      height: "20px",
      width: "20px",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },

    disabled: {
      opacity: "0.5",
    },

    unit: {
      paddingTop: "10px",
      fontSize: "14px",
    },
    enter: {
      opacity: "0",
    },

    enterActive: {
      opacity: "1",
    },

    enterDone: {
      opacity: "1",
    },

    exit: {
      opacity: "0",
    },

    exitActive: {
      opacity: "0",
    },
  });
});

const getLabels = (start: Date, until: Date) => {
  const numberOfMonths = eachMonthOfInterval({
    start: start,
    end: until,
  }).reduce((acc) => (acc += 1), 0);
  if (numberOfMonths < 7) {
    return [...Array(numberOfMonths).keys()].map((n) =>
      format(addMonths(start, n), "MMM")
    );
  } else {
    return [...Array(numberOfMonths).keys()].map((n) =>
      format(addMonths(start, n), "L")
    );
  }
};

const getConsumptionsAsMonth = (
  consumptions: {
    consumption: number;
    loggedAt: Date;
  }[]
) => {
  const removedConsumptions = [...consumptions];
  let monthBuckets = [];
  while (removedConsumptions.length > 0) {
    const month = removedConsumptions[0].loggedAt.getMonth();
    const year = removedConsumptions[0].loggedAt.getFullYear();
    const monthConsumptionValue = removedConsumptions
      .filter(
        (value) =>
          value.loggedAt.getMonth() === month &&
          value.loggedAt.getFullYear() === year
      )
      .map((dayValue: { consumption: number; loggedAt: Date }) => {
        const index = removedConsumptions.findIndex(
          (value) => value === dayValue
        );
        removedConsumptions.splice(index, 1);
        return dayValue;
      })
      .reduce((acc, value) => (acc += value.consumption), 0);
    monthBuckets.push({ consumption: monthConsumptionValue, month: month });
  }
  return monthBuckets;
};

const MonthlyChart = ({
  start,
  until,
  rightButtonEnabled,
  leftButtonEnabled,
  goToPreviousWeek,
  goToFollowingWeek,
  page,
  setPage,
  useLitres,
  useSalt,
  salt,
  water,
  unit,
}: {
  start: Date;
  until: Date;
  rightButtonEnabled: boolean;
  leftButtonEnabled: boolean;
  goToPreviousWeek: () => void;
  goToFollowingWeek: () => void;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  useLitres: boolean;
  useSalt: boolean;
  water: { loggedAt: any; water: number }[];
  salt: { loggedAt: any; salt: number }[];
  unit: string;
}) => {
  const labels = getLabels(start, until);
  const style = useStyles();

  //if month we need to get all results per month and add them up and put into new array of values
  //find out how many days in months

  const consumptions = useSalt
    ? fillEmptySaltConsumptionDataWithZeros(start, until, salt)
    : fillEmptyWaterConsumptionDataWithZeros(start, until, water, unit);

  const aggregatedConsumptions = getConsumptionsAsMonth(consumptions);
  const monthCrossover =
    start.getFullYear() < until.getFullYear() ||
    start.getMonth() < until.getMonth();

  const yearCrossover = start.getFullYear() < until.getFullYear();
  const now = new Date();
  const differentYear = start.getFullYear() < now.getFullYear();

  const max =
    aggregatedConsumptions?.reduce(
      (acc, s) => (s.consumption > acc ? s.consumption : acc),
      0
    ) ?? 0;

  const total =
    aggregatedConsumptions?.reduce((acc, s) => (acc += s.consumption), 0) ?? 0;

  const average = total / aggregatedConsumptions.length;

  console.log(max);

  return (
    <div>
      <div className={style.weekSwitcher}>
        <div
          className={style.arrow}
          onClick={() => leftButtonEnabled && goToPreviousWeek()}
          style={{
            opacity: leftButtonEnabled ? "1" : "0.5",
            backgroundImage: `url(${LeftArrow})`,
          }}
        />
        <div className={style.weekStatsSeg}>
          <TransitionGroup>
            <CSSTransition
              key={until.toDateString()}
              appear={true}
              timeout={0}
              // classNames={crossFade}
            >
              <div className={style.animationFrame}>
                <div className={style.selectedWeek}>
                  {yearCrossover || differentYear ? (
                    <div>{format(start, "d MMMM yyyy")}</div>
                  ) : (
                    <div>{format(start, "d MMMM")}</div>
                    // <FormattedDate value={start} day="numeric" month="long" />
                  )}
                  <div className={style.dash}> - </div>
                  {/* if previous month show month name */}
                  {(monthCrossover && yearCrossover) || differentYear ? (
                    <div>{format(until, "d MMMM yyyy")}</div>
                  ) : monthCrossover ? (
                    <div>{format(until, "d MMMM")}</div>
                  ) : (
                    // <FormattedDate value={until} day="numeric" month="long" />
                    <div>{format(until, "d")}</div>
                    // <FormattedDate value={until} day="numeric" />
                  )}
                </div>
                <div className={style.unit}>
                  {useSalt ? (
                    <Translate id="generics.kg" />
                  ) : useLitres ? (
                    <Translate id="generics.litres" />
                  ) : (
                    <Translate id="generics.cubicMetres" />
                  )}
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
        <div
          className={style.arrow}
          onClick={() => rightButtonEnabled && goToFollowingWeek()}
          style={{
            opacity: rightButtonEnabled ? "1" : "0.5",
            backgroundImage: `url(${RightArrow})`,
          }}
        />
      </div>
      <AnimatedBarChart
        labels={labels}
        values={aggregatedConsumptions.map((value) => value.consumption)}
        page={page}
        setPage={setPage}
        rightButtonEnabled={rightButtonEnabled}
        leftButtonEnabled={leftButtonEnabled}
        useLitres={useLitres}
        useSalt={useSalt}
      />
      <Stats
        average={average}
        max={max}
        total={total}
        useSalt={useSalt}
        unit={unit}
      />
    </div>
  );
};

export default MonthlyChart;
