import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { Action, ActionType, PendingAction, SetCountryAction, ErrorAction } from 'redux/types/MultiSelect';
import GraphQL from 'util/GraphQL';

import { Error } from 'types/CampGuideTypes';

export const setCountries = (countries: string[]): SetCountryAction => ({
  type: ActionType.CODES,
  payload: countries
});


export const pending = (): PendingAction => ({
  type: ActionType.PENDING
});


export const error = (error: Error): ErrorAction => ({
  type: ActionType.ERROR,
  payload: error
});

export const load = (): ThunkAction<Promise<void>, {}, {}, Action> => {
  return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {

    dispatch(pending());

    GraphQL.avaiableCountries.load().then((countries: string[]) => {
      dispatch(setCountries(countries));
    }).catch((errorData: any) => {
      dispatch(error(errorData));
    });
  };
};

export const save = (countries: string[]): ThunkAction<Promise<void>, {}, {}, Action> => {
  return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {

    dispatch(pending());

    GraphQL.avaiableCountries.save(countries).then((data: any) => {
      dispatch(setCountries(countries));
    }).catch((errorData: any) => {
      dispatch(error(errorData));
    });
  };
};
