// import { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import {
  notificationsHistory_productInstance_instanceInformation_PerlaInstanceInformation_events_events as PerlaMessage,
  notificationsHistory_productInstance_instanceInformation_PerlaInstanceInformation as PerlaInstanceInformation,
  notificationsHistory_productInstance_instanceInformation_PerlaInstanceInformation_eventsRawData_messages,
} from "types/apolloGenerated/notificationsHistory";
import { QueryData, QueryVars, PerlaNotification } from "./types";
import {
  NotificationType,
  DeviceActionType,
  DeviceActionState,
} from "types/apolloGenerated/globalTypes";

const QUERY = gql`
  query notificationsHistory(
    $productCode: String!
    $from: DateTimeOffset!
    $count: Int!
  ) {
    productInstance(productCode: $productCode) {
      id
      instanceInformation {
        __typename
        ... on PerlaInstanceInformation {
          events(from: $from, count: $count) {
            previous
            events {
              baseInformation {
                messageId
                receivedAt
                sentAt
              }
              message {
                __typename
                ... on PerlaActionMessage {
                  information {
                    message
                    actionType
                    state
                  }
                }
                ... on PerlaNotificationMessage {
                  stateTitle
                  information {
                    codeType
                  }
                }
                ... on PerlaLogMessage {
                  information {
                    deviceIPAddress
                    hostname
                  }
                }
                ... on PerlaActivationMessage {
                  information {
                    email
                  }
                }
                ... on PerlaDeviceParameterMessage {
                  placeholder
                }
              }
            }
          }
          eventsRawData(from: $from, count: $count) {
            messages {
              message
            }
          }
        }
      }
    }
  }
`;

const getIcon = (code: NotificationType) => {
  switch (code) {
    case NotificationType.ERROR:
    case NotificationType.INTERNAL_ERROR:
      return "error";
    case NotificationType.WARNING:
      return "warning";
    default:
      return "info";
  }
};

const getTitle = (actionType: DeviceActionType, state: DeviceActionState) => {
  const start = "components.notificationshistory.notifTitles.";
  switch (actionType) {
    case DeviceActionType.NOT_SET:
      return start + "unknown";
    case DeviceActionType.HOLIDAY_MODE:
      if (state === DeviceActionState.DISABELING) {
        return start + "holidayModeDisabled";
      } else {
        return start + "holidayModeEnabled";
      }
    case DeviceActionType.GET_LATEST_LOG:
      return start + "logRequested";
    case DeviceActionType.MANUAL_RINSE:
      return start + "triggeredRinse";
    case DeviceActionType.MANUAL_REGENERATION:
      return start + "triggeredRinse";
    case DeviceActionType.FIRMWARE_UPDATE:
      if (state === DeviceActionState.START) {
        return start + "firmwareStarted";
      } else {
        if (state === DeviceActionState.FINISHED) {
          return start + "firmwareFinished";
        } else {
          return start + "firmwareError";
        }
      }
    case DeviceActionType.PARAMETER_UPDATE:
      if (state === DeviceActionState.FINISHED) {
        return start + "parameterFinished";
      } else {
        return start + "parameterError";
      }
    case DeviceActionType.PING:
      if (state === DeviceActionState.START) {
        return start + "pingRequested";
      } else {
        return start + "pingResponded";
      }
    case DeviceActionType.SHUTDOWN:
      if (state === DeviceActionState.ENABLED) {
        return start + "deviceShutdown";
      } else {
        return start + "deviceStartup";
      }
    case DeviceActionType.LID_CHANGE:
      return start + "deviceLid";
  }
};

const from = new Date();

export function usePaginatedNotifications(
  productCode: string
): {
  results: PerlaNotification[];
  loading: boolean;
  loadMore: (() => void) | null;
} {
  const count = 15;
  const { data, loading, fetchMore } = useQuery<QueryData, QueryVars>(QUERY, {
    variables: { productCode, from, count },
    fetchPolicy: "cache-and-network",
  });
  let results: (PerlaMessage | null)[] = [];
  let previous: String | null;
  let notificationJSON:
    | (notificationsHistory_productInstance_instanceInformation_PerlaInstanceInformation_eventsRawData_messages | null)[]
    | null
    | undefined;

  const info = data?.productInstance?.instanceInformation;
  if (info?.__typename !== "PerlaInstanceInformation") {
    previous = null;
  } else {
    results = info?.events?.events ?? [];
    notificationJSON = info.eventsRawData?.messages;
    previous = info?.events?.previous;
  }

  const loadMore =
    !previous || loading
      ? null
      : () => {
          fetchMore({
            variables: { from: previous },
            updateQuery: (existing, { fetchMoreResult }) => {
              if (!fetchMoreResult || !existing.productInstance)
                return existing;
              const instanceInfo =
                fetchMoreResult?.productInstance?.instanceInformation;
              if (instanceInfo?.__typename !== "PerlaInstanceInformation")
                return existing;
              const perlaInfo = instanceInfo as PerlaInstanceInformation;
              const moreEvents = perlaInfo?.events?.events ?? [];
              const newPrev = perlaInfo?.events?.previous;
              const existingInfo =
                existing?.productInstance?.instanceInformation;
              if (existingInfo?.__typename !== "PerlaInstanceInformation")
                return existing;
              const existingPerlaInfo = existingInfo as PerlaInstanceInformation;
              if (!existingPerlaInfo.events) return existing;
              const existingEvents = existingPerlaInfo?.events?.events ?? [];
              const newEvents = existingEvents.concat(moreEvents);

              return {
                ...existing,
                productInstance: {
                  ...existing.productInstance,
                  instanceInformation: {
                    ...existingPerlaInfo,
                    events: {
                      ...existingPerlaInfo.events,
                      events: newEvents,
                      previous: newPrev,
                    },
                  },
                },
              };
            },
          });
        };

  const perlaNotificationsArr: PerlaNotification[] = [];

  results.forEach((r) => {
    let show = true;

    let actionType;
    let notifTitle = "";
    let notifSubTitle = "";
    let notifIcon = "";
    let untranslatedTitle = "";
    let untranslatedSubTitle = "";
    switch (r?.message?.__typename) {
      case "PerlaActionMessage":
        actionType = r?.message?.information?.actionType;
        notifTitle = getTitle(
          actionType ?? DeviceActionType.NOT_SET,
          r?.message?.information?.state ?? DeviceActionState.TIMEOUT
        );
        if (
          actionType === DeviceActionType.PARAMETER_UPDATE &&
          r?.message?.information?.state === DeviceActionState.ERROR
        ) {
          untranslatedSubTitle = r?.message?.information?.message ?? "";
        }
        if (
          actionType === DeviceActionType.FIRMWARE_UPDATE &&
          r?.message?.information?.state === DeviceActionState.ERROR
        ) {
          untranslatedSubTitle = r?.message?.information?.message ?? "";
        }
        break;
      case "PerlaNotificationMessage":
        show = r?.message?.stateTitle ? true : false;
        untranslatedTitle = r?.message?.stateTitle ?? "";
        notifIcon = getIcon(
          r?.message?.information?.codeType ?? NotificationType.NOT_SET
        );
        break;
      case "PerlaLogMessage":
        notifTitle = "components.notificationshistory.notifTitles.logRecieved";
        notifIcon = "info";
        break;
      case "PerlaActivationMessage":
        notifTitle = "components.notificationshistory.notifTitles.activated";
        untranslatedSubTitle = r.message.information?.email || "";
        notifIcon = "info";
        break;
      case "PerlaDeviceParameterMessage":
        show = false;
        notifTitle = "components.notificationshistory.notifTitles.parameterMessage";
        notifIcon = "info";
        break;
    }
    const notifDate = r?.baseInformation?.receivedAt;
    const perlaNotification: PerlaNotification = {
      icon: notifIcon,
      title: notifTitle,
      untranslatedTitle: untranslatedTitle,
      subTitle: notifSubTitle,
      untranslatedSubTitle: untranslatedSubTitle,
      date: notifDate ?? new Date(),
      notificationJSON: notificationJSON,
    };
    show && perlaNotificationsArr.push(perlaNotification);
  });

  return { results: perlaNotificationsArr, loading, loadMore };
}
