import React, { Fragment, SetStateAction } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "redux/store";
import { UserProfile } from "types/ToolboxEntities";
import { FormControl } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { Clear } from "@material-ui/icons";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import {
  Box,
  Grid,
  TextField,
  Checkbox,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Translate } from "react-localize-redux";
import {
  UserInformationUpdateInput,
  Country,
} from "types/apolloGenerated/globalTypes";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

interface EditCustomerDetailsProps {
  user: UserProfile | null;
  customerDetailsObj: UserInformationUpdateInput;
  setCustomerDetailsObj: React.Dispatch<
    React.SetStateAction<UserInformationUpdateInput>
  >;
  validation: { field: string; message: string }[];
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    contentDisplay: {
      display: "flex",
      position: "relative",
      flexWrap: "wrap",
      width: "100%",
      paddingTop: "40px",
    },
    reactiveBoxes: {
      minWidth: "350px",
      width: "92%",
      margin: "0 0 23px 30px",
    },
    reactiveBoxesRight: {
      minWidth: "350px",
      width: "90%",
      margin: "0 0 23px 30px",
    },
    reactiveBoxesSmall: {
      minWidth: "100px",
      width: "70%",
      margin: "0 0 23px 30px",
    },
  });
});

const UPDATE_TEST_FLAG_MUTATION = gql`
  mutation updateUserTestFlag($userId: ID!, $isTestUser: Boolean!) {
    user {
      updateCustomerTestFlag(userId: $userId, isTestUser: $isTestUser)
    }
  }
`;

const EditCustomerDetails: React.FC<EditCustomerDetailsProps> = ({
  user,
  customerDetailsObj,
  setCustomerDetailsObj,
  validation,
}) => {
  //const { data } = useQuery(COUNTRY_QUERY);
  const currentCC = customerDetailsObj?.address?.countryCode ?? null;
  const allowedCountries: Country[] = user?.allowedCountries || [];
  const [country, setCountry] = React.useState<Country | null>(currentCC);
  const [open, setOpen] = React.useState(false);
  const [updateTestFlag] = useMutation(UPDATE_TEST_FLAG_MUTATION);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleTestFlagChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    updateTestFlag({
      variables: {
        userId: customerDetailsObj?.id,
        isTestUser: event.target.value as boolean,
      },
    });
    customerDetailsObj.isTestUser = event.target.value as boolean;
    const latestValue = Object.assign({}, customerDetailsObj);
    setCustomerDetailsObj(latestValue);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCountry(event.target.value as Country);
    if (customerDetailsObj.address?.countryCode) {
      customerDetailsObj.address.countryCode = event.target.value as Country;
    }
    const latestValue = Object.assign({}, customerDetailsObj);
    setCustomerDetailsObj(latestValue);
  };

  const validationFields = validation.map((value) => value.field);

  const specialRights = [
    {
      message:
        'User is allowed to use "BWT-Perla Updater" (Only for BWT Employees)',
    },
  ];

  const classes = useStyles();

  console.log("test: " + user);

  return (
    <Grid container>
      <Box className={classes.contentDisplay}>
        <Grid item xs={6}>
          <TextField
            id="firstNameField"
            label={<Translate id="types.userInformation.firstName" />}
            defaultValue={customerDetailsObj?.firstName}
            variant="standard"
            error={validationFields.includes("firstName")}
            helperText={
              validationFields.includes("firstName")
                ? validation[validationFields.indexOf("firstName")].message
                : ""
            }
            className={classes.reactiveBoxes}
            InputProps={{
              style: { padding: 0 },
            }}
            onChange={(value) =>
              setCustomerDetailsObj({
                ...customerDetailsObj,
                firstName: value.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="lastNameField"
            label={<Translate id="types.userInformation.lastName" />}
            defaultValue={customerDetailsObj?.lastName}
            variant="standard"
            className={classes.reactiveBoxesRight}
            error={validationFields.includes("lastName")}
            helperText={
              validationFields.includes("lastName")
                ? validation[validationFields.indexOf("lastName")].message
                : ""
            }
            onChange={(value) =>
              setCustomerDetailsObj({
                ...customerDetailsObj,
                lastName: value.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="emailField"
            label={<Translate id="generics.email" />}
            defaultValue={customerDetailsObj?.email}
            variant="standard"
            error={validationFields.includes("email")}
            helperText={
              validationFields.includes("email")
                ? validation[validationFields.indexOf("email")].message
                : ""
            }
            className={classes.reactiveBoxes}
            onChange={(value) =>
              setCustomerDetailsObj({
                ...customerDetailsObj,
                email: value.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="phoneField"
            label={<Translate id="types.account.phone" />}
            defaultValue={customerDetailsObj?.telephone}
            variant="standard"
            type="number"
            className={classes.reactiveBoxesRight}
            onChange={(value) =>
              setCustomerDetailsObj({
                ...customerDetailsObj,
                telephone: value.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="streetField"
            label={<Translate id="types.address.address" />}
            defaultValue={customerDetailsObj?.address?.street}
            variant="standard"
            className={classes.reactiveBoxes}
            onChange={(value) =>
              setCustomerDetailsObj({
                ...customerDetailsObj,
                address: {
                  ...customerDetailsObj.address,
                  street: value.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="postalCodeField"
            label={<Translate id="types.address.postalCode" />}
            defaultValue={customerDetailsObj?.address?.postalCode}
            variant="standard"
            className={classes.reactiveBoxesSmall}
            style={{ width: "85%" }}
            onChange={(value) =>
              setCustomerDetailsObj({
                ...customerDetailsObj,
                address: {
                  ...customerDetailsObj.address,
                  postalCode: value.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="cityField"
            label={<Translate id="types.address.city" />}
            defaultValue={customerDetailsObj?.address?.city}
            variant="standard"
            className={classes.reactiveBoxesSmall}
            style={{ width: "85%" }}
            onChange={(value) =>
              setCustomerDetailsObj({
                ...customerDetailsObj,
                address: {
                  ...customerDetailsObj.address,
                  city: value.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid style={{ fontSize: "10px", lineHeight: "12px" }} item xs={2}>
          <Box style={{ marginLeft: "30px", marginBottom: "3px" }}>
            <Translate id="types.address.countryCode" />
          </Box>
          <Translate>
            {({ translate }) => {
              if (!allowedCountries.length) return Fragment;
              return (
                <Select
                  value={country}
                  className={classes.reactiveBoxesSmall}
                  onChange={handleChange}
                >
                  {allowedCountries.map((country: any) => (
                    <MenuItem key={country} value={country}>
                      {
                        translate(
                          "countries." + country.toLocaleLowerCase()
                        ) as string
                      }
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          </Translate>
        </Grid>
        <Grid item xs={6}>
          <Box style={{ marginLeft: "30px" }}>
            <Translate id="components.editCustomerDetails.specialRights" />
          </Box>
          <Box width={690} marginLeft={"30px"}>
            {specialRights.map((rights, idx) => (
              <Box display="flex" key={idx}>
                <Checkbox
                  checked={customerDetailsObj.canUsePerlaUpdater}
                  onChange={(value) => {
                    customerDetailsObj.canUsePerlaUpdater =
                      value.target.checked;
                    const latestValue = Object.assign({}, customerDetailsObj);
                    setCustomerDetailsObj(latestValue);
                  }}
                />
                <Box display="flex" alignItems="center">
                  {rights.message}
                </Box>
              </Box>
            ))}
          </Box>
          <Box style={{ marginLeft: "30px" }}>
            <Translate id="components.editCustomerDetails.testFlag" />
          </Box>
          <Box width={690} marginLeft={"30px"}>
            <FormControl>
              <Select
                labelId="is-test-device-label"
                id="is-test-device"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={customerDetailsObj?.isTestUser}
                onChange={handleTestFlagChange}
                disableUnderline={true}
              >
                <MenuItem value="true">
                  <Done color="primary" />
                </MenuItem>
                <MenuItem value="false">
                  <Clear color="error" />
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box style={{ marginLeft: "30px" }}>
            <Translate id="components.editCustomerDetails.marketingUsage" />
          </Box>
          <Box width={690} marginLeft={"30px"}>
            <Box display="flex">
              <Checkbox
                checked={customerDetailsObj.acceptedMarketingUsage}
                onChange={(value) => {
                  customerDetailsObj.acceptedMarketingUsage =
                    value.target.checked;
                  const latestValue = Object.assign({}, customerDetailsObj);
                  setCustomerDetailsObj(latestValue);
                }}
              />
              <Box display="flex" alignItems="center">
                <Translate id="components.editCustomerDetails.acceptedMarketingUsage" />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
});

export default connect(mapStateToProps)(EditCustomerDetails);
