import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  CheckCircleOutlineRounded,
  HighlightOff,
  ErrorOutline,
  //Height
} from "@material-ui/icons";
import format from "date-fns/format";

import { Translate } from "react-localize-redux";
import bwtColors from "themes/bwtColors";
import { NotificationType } from "../../../types/apolloGenerated/globalTypes"


import {
  productInstanceAndCustomerDetails_productInstance_instanceInformation_PerlaInstanceInformation_unconfirmedNotifications as unconfirmedNotifs,
} from "types/apolloGenerated/productInstanceAndCustomerDetails"
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({

    notificationPanelInnerContainer: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: 15,
      overflow: "hidden",
      minWidth: "1050px",
      maxWidth: "1700px"
    },


    notifContainer: {
      display: "flex",
      border: "1px solid #B5B9BB",
      height: "70px",
      alignItems: "center",
      padding: "20px",
      fontSize: "15px",
      margin: "10px",
      width: "calc(20% - 20px)",

      '@media (max-width: 1799px)': {
        width: "calc(25% - 20px)",
      },

      '@media (max-width: 1450px)': {
        width: "calc(33.3333% - 20px)",
      },
    }
  });
});

export interface DeviceStatusProps {
  unconfirmedNotifs: (unconfirmedNotifs | null)[]
  stateTitle: String | null
}

const Notification: React.FC<{ code: NotificationType, title: String, sentAt: Date | null }> = ({ code, title, sentAt }) => {
  const classes = useStyles();
  return (
    <div className={classes.notifContainer}>
      {getIcon(code)}
      <div style={{ paddingLeft: 20 }}>
        <div>
          {title}
        </div>
        <div>
          <Translate>
            {
              (translate) => {
                return <span> {sentAt ? format(new Date(sentAt), translate.translate('generics.dateTimeFormatFNS') as string) : ""} </span>
              }
            }
          </Translate>
        </div>
      </div>
    </div>
  )
}

const getIcon = (type: NotificationType) => {
  switch (type) {
    case NotificationType.ERROR:
    case NotificationType.INTERNAL_ERROR:
      return <HighlightOff style={{ fontSize: "50px" }} color="error" />;
    case NotificationType.WARNING:
    case NotificationType.SERVICE:
      return (
        <ErrorOutline
          style={{ fontSize: "50px", color: bwtColors.warnYellow }}
        />
      );
    default:
      return <CheckCircleOutlineRounded style={{ fontSize: "50px" }} color="primary" />
  }
};

const DeviceStatus: React.FC<DeviceStatusProps> = ({ unconfirmedNotifs, stateTitle }) => {
  const classes = useStyles();
  let deviceDisconnected = false;
  let disconnectCodeType = NotificationType.ERROR;

  if (stateTitle?.includes("disconnected")) {
    deviceDisconnected = true;
    if (stateTitle?.includes("Warning")) {
      disconnectCodeType = NotificationType.WARNING
    }
  }

  return (
    <Box className={classes.notificationPanelInnerContainer}>
      {unconfirmedNotifs.length > 0 ? (
        unconfirmedNotifs.map((notif, idx) => (
          <Notification key={idx} code={notif?.codeType ?? NotificationType.ERROR} title={notif?.stateTitle ?? ""} sentAt={notif?.baseInformation?.receivedAt} />
        )
        )) : (deviceDisconnected === true ? <Notification code={disconnectCodeType} title={stateTitle ?? ""} sentAt={null}></Notification> :
          <div className={classes.notifContainer}>
            <CheckCircleOutlineRounded
              style={{ fontSize: "50px" }}
              color="primary"
            />
            <div style={{ paddingLeft: 20 }}>
              <Translate id="components.deviceStatus.everythingOk" />
            </div>
          </div>
        )}
    </Box>
  );
};

export default DeviceStatus;
