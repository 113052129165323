import { ActionType, Action } from 'redux/types/AccountShortcuts/ChangePosition';

import * as Types from 'types/ToolboxEntities';


export const open = (userKey: number, position: Types.Position, partnerId: number): Action => ({
  type: ActionType.OPEN,
  payload: {
    userId: userKey,
    position: position,
    partnerId: partnerId
  }
});

export const close = (): Action => ({
  type: ActionType.CLOSE,
});

