import { ActionType, Store, Action } from 'redux/types/AccountShortcuts/ChangePosition';

import initialState from 'redux/initialState';

export default function changeLoginReducer(store: Store = initialState.accountShortcuts.changePosition, action: Action): Store {
  switch (action.type) {

    case ActionType.OPEN:
      return {
        changeData: action.payload,
      };

    case ActionType.CLOSE:
     return {
        changeData: null,
      };

    default:
      return store;
  }
}
