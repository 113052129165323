import { ActionType, OpenAction, Action } from 'redux/types/AccountShortcuts/ChangePearls';

import * as Types from 'types/ToolboxEntities';



export const open = (pearls: Types.PearlsTransaction): OpenAction => ({
  type: ActionType.OPEN,
  payload: pearls
});


export const close = (): Action => ({
  type: ActionType.CLOSE,
});
