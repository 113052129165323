import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useParams } from "react-router";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import EmployeeInformation from "./EmployeeInformation";
import ShowAccountInfo from "components/common/ShowAccountInfo";
import PageHeader from "components/common/PageHeader";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { UserInformation } from "types/ToolboxEntities";

import { withLocalize, LocalizeContextProps } from "react-localize-redux";

import PageDialog from "components/common/PageDialog";

import {
  updateEmployeeDetails as MutationData,
  updateEmployeeDetailsVariables as MutationVars,
} from "types/apolloGenerated/updateEmployeeDetails";
import { UserInformationUpdateInput } from "types/apolloGenerated/globalTypes";

import {
  EmployeeDetail as EmployeeQueryData,
  EmployeeDetailVariables as EmployeeQueryVars,
} from "types/apolloGenerated/EmployeeDetail";
import { Translate } from "react-localize-redux";
import { open as changeLoginOpen } from "redux/actions/AccountShortcuts/ChangeLoginActions";
import { open as changeActiveOpen } from "redux/actions/AccountShortcuts/ChangeActiveActions";
import { open as sendResetOpen } from "redux/actions/AccountShortcuts/SendResetActions";

import Organization from "Pages/PartnerManager/Employees/Detail/Organization";
import { QUERY_EMPLOYEE_DETAIL } from "commonQueries/employee";
import PageHeaderMessage from "components/common/PageHeaderMessage";
import AuditLog from "../../../Customer/InstanceDetails/AuditLog";
import store from "../../../../redux/store";
import * as Types from "../../../../types/ToolboxEntities";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    infoSeg: {
      color: "#000000",
      paddingLeft: "30px",
      margin: "40px 0 0 0",
      width: "100%",
    },
  });
});

const MUTATION = gql`
  mutation updateEmployeeDetails($details: UserInformationUpdateInput!) {
    user {
      updateInformation(userInformation: $details) {
        firstName
        lastName
        id
        email
        telephone
        acceptedMarketingUsage
        address {
          city
          countryCode
          postalCode
          street
        }
      }
    }
  }
`;

interface EmployeeDetailsProps extends LocalizeContextProps {
  actions: {
    changeLoginOpen: typeof changeLoginOpen;
    changeActiveOpen: typeof changeActiveOpen;
    sendResetOpen: typeof sendResetOpen;
  };
  queryData: any;
}

const EmployeeDetailsView: React.FC<EmployeeDetailsProps> = ({
  actions,
  queryData,
  translate,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  let canViewLogs: boolean = false;

  store.getState().auth.user?.roles.forEach((item: Types.Role) => {
    if (item.roleName === "AuditAdmin") {
      canViewLogs = true;
    }
  });

  const [updateEmployeeDetails, { loading: updateLoading }] = useMutation<
    MutationData,
    MutationVars
  >(MUTATION, {
    onError: (error) => {
      let errorMessage = error.message;
      const message = "updateInformation";
      if (error.message.includes(message)) {
        const index = error.message.indexOf(message) + message.length;
        errorMessage = error.message.slice(0, index);
      }
      setError(errorMessage);
      setOpen(true);
    },
  });

  const userInformation = {
    id: queryData?.id,
    firstName: queryData?.firstName ?? "",
    lastName: queryData?.lastName ?? "",
    email: queryData?.email ?? "",
    telephone: queryData?.telephone ?? "",
    acceptedMarketingUsage: queryData?.acceptedMarketingUsage ?? false,
    address: {
      street: queryData?.address?.street ?? "",
      postalCode: queryData?.address?.postalCode ?? "",
      countryCode: queryData?.address?.countryCode,
      city: queryData?.address?.city ?? "",
    },
    canUsePerlaUpdater: false,
    isTestUser: queryData?.isTestUser ?? false,
  };

  const account = {
    id: queryData?.id,
    key: queryData.key,
    email: queryData?.email,
    accountLocked: queryData?.accountLocked,
    allowedToLogin: queryData?.allowedToLogin,
    lastLogin: queryData?.lastLogin,
    dataUpdate: queryData?.dataUpdate,
    verifiedAccount: queryData?.verifiedAccount,
    lastIncorrectLogin: queryData?.lastIncorrectLogin,
    registeredOn: queryData?.registeredOn,
    incorrectLogins: queryData?.incorrectLogins,
    acceptedTerms: queryData?.acceptedTerms,
    lastPasswordReset: queryData?.lastFailedPasswordReset,
    preferredLanguage: queryData?.preferredLanguage,
    acceptedMarketingUsage: queryData?.acceptedMarketingUsage,
  } as UserInformation;

  const [
    employeeInformation,
    setEmployeeInformation,
  ] = useState<UserInformationUpdateInput>(userInformation);
  const { firstName, lastName } = userInformation;

  const pageNav = [
    {
      title: translate("components.account.sendSetPasswordEmail") as string,
      on: () => {
        actions.sendResetOpen(account);
      },
    },
    {
      title: translate("components.account.changePassword") as string,
      on: () => {
        actions.changeLoginOpen(account);
      },
    },
  ];

  const [validation, setValidation] = React.useState<
    { field: string; message: string }[]
  >([]);

  const validationCheck = () => {
    let validationArr: { field: string; message: string }[] = [];
    if (employeeInformation.email === "") {
      validationArr.push({
        field: "email",
        message: translate("message.error.noEmail") as string,
      });
    }
    if (employeeInformation.firstName === "") {
      validationArr.push({
        field: "firstName",
        message: translate("message.error.noFirstName") as string,
      });
    }
    if (employeeInformation.lastName === "") {
      validationArr.push({
        field: "lastName",
        message: translate("message.error.noLastName") as string,
      });
    }
    setValidation(validationArr);
    if (validationArr.length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Box>
        <PageHeader
          showBack
          title={
            firstName && lastName
              ? firstName + " " + lastName
              : (translate("message.error.nameError") as string)
          }
          nav={pageNav}
        />
        <PageHeaderMessage
          open={account.accountLocked}
          title={translate("message.info.lockedAccount") as string}
        />
        <PageDialog
          open={open}
          error={error}
          title={"message.error.updateEmployeeError"}
          buttons={[{ title: "generics.ok", click: () => setOpen(false) }]}
        />
      </Box>
      <div style={{ maxWidth: "1700px" }}>
        <br></br>
        <Grid container>
          <Box>
            <EmployeeInformation
              employeeInfoObj={employeeInformation}
              setEmployeeInfoObj={setEmployeeInformation}
              validation={validation}
            />
          </Box>
          <Box className={classes.infoSeg}>
            <Organization />
          </Box>
          <Box className={classes.infoSeg}>
            <ShowAccountInfo account={account} />
          </Box>
          <AuditLog
            canViewLogDetail={canViewLogs}
            gridSize={8}
            partnerDetail={null}
            productCode={null}
            productInstance={null}
            customerId={account.id}
            productId={null}
          />
        </Grid>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { changeLoginOpen, changeActiveOpen, sendResetOpen },
    dispatch
  ),
});

const ConnectedEmployeeDetailsView = connect(
  null,
  mapDispatchToProps
)(withLocalize(EmployeeDetailsView));

const EmployeeDetails: React.FC = () => {
  const { id = "" } = useParams();
  const { data, loading, error } = useQuery<
    EmployeeQueryData,
    EmployeeQueryVars
  >(QUERY_EMPLOYEE_DETAIL, {
    variables: { id: parseInt(id) },
  });

  if (loading) return <CircularProgress color="secondary" />;
  if (!data?.employee?.userInformation)
    return (
      <div>
        <div>
          <Translate id="components.employeeInformation.noData" />
        </div>
        <div>{error?.message}</div>
      </div>
    );

  return (
    <ConnectedEmployeeDetailsView queryData={data?.employee?.userInformation} />
  );
};

export default EmployeeDetails;
