import React, { Fragment } from "react";
import { Translate } from "react-localize-redux";
import { Autocomplete } from '@material-ui/lab';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { TranslateFunction, withLocalize, LocalizeContextProps } from "react-localize-redux";
import {
  Box,
  TextField,
  CircularProgress,
  Button
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { productById_product_productInformation_productUserAction_notifications_translations as TranslationObj } from "types/apolloGenerated/productById";
import {
  removeTranslation,
  removeTranslationVariables
} from "types/apolloGenerated/removeTranslation";
import {
  updateTranslation,
  updateTranslationVariables
} from "types/apolloGenerated/updateTranslation"
import {
  addTranslationVariables as addVariables,
  addTranslation as addTranslat
} from "types/apolloGenerated/addTranslation";

const useStyles = makeStyles(() => {
  return createStyles({
    languageContainer: {
      width: "250px",
      margin: "0px 40px 20px 30px"
    },
    titleContainer: {
      width: "200px",
      margin: "0px 40px 20px 0px"
    },
    bodyContainer: {
      width: "450px",
      margin: "0px 40px 20px 0px"
    },
    updateSpinner: {
      margin: "20px 0 0 20px",
      width: "63px"
    },
    removeButton: {
      marginTop: "17px",
      height: "35px",
      marginLeft: "20px"
    },
    addButton: {
      marginTop: "15px",
      height: "36px"
    }
  });
});

const REMOVE_TRANSLATION = gql`
  mutation removeTranslation($productTranslationId: Int!) {
    userAction {
      deleteProductUserActionNotificationTranslation(
        productUserActionNotificationTranslationId: $productTranslationId
      )
    }
  }
`;

const ADD_TRANSLATION = gql`
  mutation addTranslation(
    $productTranslation: ProductUserActionNotificationTranslationInsert!
  ) {
    userAction {
      createProductUserActionNotificationTranslation(
        productUserActionNotificationTranslation: $productTranslation
      ) {
        id
      }
    }
  }
`;

const UPDATE_TRANSLATION = gql`
mutation updateTranslation(
  $productTranslation: ProductUserActionNotificationTranslationUpdate!
) {
  userAction{
    updateProductUserActionNotificationTranslation(productUserActionNotificationTranslation: $productTranslation){
      id
    }
  }
}
`
interface TranslationProps extends LocalizeContextProps {
  remove: (index: number) => void;
  index: number;
  translationObject: TranslationObj;
  change: (index: number, notification: TranslationObj) => void,
  add: (newTranslat: TranslationObj) => void
  notificationId: number,
  addOnly: boolean,
  setError: (error: string) => void,
  allTranslations: (TranslationObj | null)[],
  translate: TranslateFunction,
}

const Translation: React.FC<TranslationProps> = ({
  translationObject,
  change,
  index,
  remove,
  notificationId,
  addOnly,
  add,
  setError,
  allTranslations,
  translate,
}) => {
  const classes = useStyles();
  const [languageAlert, setLanguageAlert] = React.useState({ error: false, message: "" })
  const [titleAlert, setTitleAlert] = React.useState({ error: false, message: "" })
  const [bodyAlert, setBodyAlert] = React.useState({ error: false, message: "" })

  const langs: string[] = ["EN", "DE", "CS", "DA", "ES", "FI", "FR", "HU", "IT", "NL", "NO", "PL", "RU", "SE", "UA", "ZH"] //["EN", "DE", "FR"]
  const langsLowercase: string[] = []
  const langTranslations: string[] = []

  langs.forEach(language => {
    langsLowercase.push(language.toLowerCase());
    langTranslations.push(translate(`languages.${language}`) as string)
  });

  const [
    removeTranslationMutation,
    { loading: removeTranslationLoading }
  ] = useMutation<removeTranslation, removeTranslationVariables>(
    REMOVE_TRANSLATION,
    {
      onCompleted: () => {
        setError("")
        remove(index);
      },
      onError: error => {
        setError("Unable to remove translation - " + error.message)
      }
    }
  );

  const [
    addTranslationMutation,
    { loading: addTranslationLoading }
  ] = useMutation<addTranslat, addVariables>(ADD_TRANSLATION, {
    onCompleted: data => {
      setError("")
      if (data.userAction?.createProductUserActionNotificationTranslation?.id) {
        add({
          __typename: "ProductUserActionNotificationTranslation",
          id: data.userAction.createProductUserActionNotificationTranslation.id,
          language: translationObject.language,
          title: translationObject.title,
          body: translationObject.body
        }

        );
      }
    },
    onError: error => {
      setError("Unable to add translation - " + error.message)
    }
  });

  const [
    updateTranslationMutation,
    { loading: updateTranslationLoading }
  ] = useMutation<updateTranslation, updateTranslationVariables>(
    UPDATE_TRANSLATION,
    {
      onCompleted: () => {
        setError("")
        change(index, {
          id: translationObject.id,
          language: translationObject.language,
          title: translationObject.title,
          body: translationObject.body,
          __typename: "ProductUserActionNotificationTranslation"
        });
      },
      onError: error => {
        setError("Unable to update translation - " + error.message)
      }

    }
  );



  const validationCheck = () => {
    let errorFound = false
    const lang = translationObject.language
    for (let i = 0; i < allTranslations.length; i++) {
      if (i !== index) {
        if (lang === allTranslations[i]?.language) {
          setLanguageAlert({ error: true, message: translate("pages.product.helper.language") as string })
          errorFound = true
        }
      }
    }
    if (lang === translate("pages.product.chooseLanguage") as string || lang === "") {
      setLanguageAlert({ error: true, message: translate("pages.product.helper.language") as string })
      errorFound = true
    }
    if (!errorFound) {
      setLanguageAlert({ error: false, message: "" })
    }
    if (translationObject.title === "") {
      setTitleAlert({ error: true, message: translate("pages.product.helper.title") as string })
      errorFound = true
    } else {
      setTitleAlert({ error: false, message: "" })
    }
    if (translationObject.body === "") {
      setBodyAlert({ error: true, message: translate("pages.product.helper.body") as string })
      errorFound = true
    } else {
      setBodyAlert({ error: false, message: "" })
    }
    return errorFound
  }


  return (
    <Translate>
      {({ translate }) => (
        <Box>
          <Box style={{ display: "flex" }}>
            <Box className={classes.languageContainer}>
              <Box>
                <Translate id="types.product.language" />
              </Box>
              <Autocomplete
                value={translationObject.language}
                options={langsLowercase.sort()}
                onChange={(e: any, value: string | null) => {
                  if (value !== null) {
                    change(
                      index, {
                      ...translationObject,
                      language: value
                    }
                    )
                  }
                }}
                getOptionLabel={(option: string) => option}
                style={{ minWidth: "250px" }}
                renderInput={params => (
                  <TextField {...params} variant="standard" fullWidth
                    helperText={languageAlert.message}
                    error={languageAlert.error}
                    onBlur={e => {
                      if (e.target.value === "") {
                        change(index, {
                          ...translationObject,
                          language: translate("pages.product.chooseLanguage") as string
                        })
                      }
                    }}
                  />
                )}
              />
            </Box>
            <Box className={classes.titleContainer}>
              <Box>
                <Translate id="types.product.title" />
              </Box>
              <TextField
                variant="standard"
                style={{ width: "200px" }}
                value={translationObject.title}
                helperText={titleAlert.message}
                error={titleAlert.error}
                onChange={e => {
                  change(index, {
                    ...translationObject,
                    title: e.target.value
                  })
                }}
              />
            </Box>
            <Box className={classes.bodyContainer}>
              <Box>
                <Translate id="types.product.body" />
              </Box>
              <TextField
                variant="standard"
                style={{ width: "450px" }}
                value={translationObject.body}
                helperText={bodyAlert.message}
                error={bodyAlert.error}
                onChange={e => {
                  change(index, {
                    ...translationObject,
                    body: e.target.value
                  })
                }}
              />
            </Box>
            {!addOnly ? !updateTranslationLoading ? (
              <Box style={{ marginTop: "17px", height: "35px" }}>
                <Button variant="outlined"
                  onClick={() => {
                    if (validationCheck()) {
                      return
                    }
                    updateTranslationMutation({
                      variables: {
                        productTranslation: {
                          body: translationObject.body,
                          title: translationObject.title ?? "",
                          id: translationObject.id,
                          productUserActionNotificationId: notificationId,
                          language: translationObject.language
                        }
                      }
                    })
                  }
                  }>
                  <Translate id="generics.update" />
                </Button>
              </Box>
            ) : (
                <Box className={classes.updateSpinner}>
                  <CircularProgress size={30} color="secondary" />
                </Box>
              ) : <Fragment />}
            {!addOnly ? !removeTranslationLoading ? (
              <Box className={classes.removeButton}>
                <Button variant="outlined" onClick={() =>
                  removeTranslationMutation({
                    variables: { productTranslationId: translationObject.id }
                  })
                }>
                  <Translate id="generics.remove" />
                </Button>
              </Box>
            ) : (
                <Box style={{ margin: "20px 0 0 45px" }}>
                  <CircularProgress size={30} color="secondary" />
                </Box>
              ) : <Fragment />}
            {addOnly ? !addTranslationLoading ? (
              <Button
                className={classes.addButton}
                onClick={() => {
                  if (validationCheck()) {
                    return
                  }
                  addTranslationMutation({
                    variables: {
                      productTranslation: {
                        language: translationObject.language,
                        title: translationObject.title ?? "",
                        body: translationObject.body,
                        productUserActionNotificationId: notificationId
                      }
                    }
                  })
                }}
                variant="outlined"
              >
                <Translate id="generics.add" />
              </Button>
            ) : (
                <Box style={{ margin: "15px 0 0 15px" }}>
                  <CircularProgress size={30} color="secondary" />
                </Box>
              ) : <Fragment />}
          </Box>
        </Box>
      )}
    </Translate>
  );
};

export default withLocalize(Translation)