import {UserInformation} from 'types/ToolboxEntities';

export enum ActionType {
  OPEN = 'CHANGE_ACTIVE_OPEN',
  CLOSE = 'CHANGE_ACTIVE_CLOSE',
}

export interface Store {
  user: UserInformation | null,
}


export interface OpenAction {
  type: ActionType.OPEN,
  payload: UserInformation
}

export interface CloseAction {
  type: ActionType.CLOSE,
}


export type Action = OpenAction | CloseAction;
