import React, { Fragment } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  Close,
  Done,
  ErrorOutline,
} from "@material-ui/icons";
import colours from "themes/bwtColors";

import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from "react-localize-redux";
import format from "date-fns/format";
import SignalStrength from "../../../components/common/SignalStrength";

import {
  telemetryInformation as QueryData,
  telemetryInformationVariables as QueryVars,
} from "types/apolloGenerated/telemetryInformation";
import { isWithinInterval, subHours } from "date-fns";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    arrow: { fontSize: 16, margin: 7 },
    textField: {
      [`& input::-webkit-clear-button,
      & input::-webkit-outer-spin-button,
      & input::-webkit-inner-spin-button,
      & input::-webkit-toolbarbutton-dropdown`]: {
        display: "none",
      },
      width: 150,
    },
    customTooltip: {
      width: "120px",
    },
  });
});

const QUERY = gql`
  query telemetryInformation(
    $productCode: String!
    $timestamp: DateTimeOffset!
  ) {
    productInstance(productCode: $productCode) {
      id
      instanceInformation {
        __typename
        ... on PerlaInstanceInformation {
          registeredPerlaInstanceInformation {
            stateBody
          }
          telemetry(timestamp: $timestamp) {
            next
            previous
            value {
              date
              motor1Position
              motor2Position
              wlanQuality
              gsmQuality
              lastRegenerationS1
              lastRegenerationS2
              capacityRemainingS1
              capacityRemainingS2
            }
          }
          telemetryRawData(timestamp: $timestamp) {
            value
          }
          summary(timestamp: $timestamp) {
            value {
              connectedToClientLan
              logDate
            }
          }
        }
      }
    }
  }
`;

interface TelemetryProps extends LocalizeContextProps {
  productCode: string;
}

function protectDateSetter(
  setter: (date: Date) => void
): (dateStr: string | null) => void {
  return (dateStr: string | null) => {
    if (dateStr) {
      const date = new Date(dateStr);
      if (!isNaN(date.getTime())) {
        setter(date);
      }
    }
  };
}

const Telemetry: React.FC<TelemetryProps> = ({ productCode, translate }) => {
  const classes = useStyles();
  const initialDate = new Date();
  const [selectedDateTelem, setSelectedDateTelem] = React.useState(initialDate);
  const [loadNewDate, setLoadNewDate] = React.useState(false);

  const { data, loading } = useQuery<QueryData, QueryVars>(QUERY, {
    variables: { productCode, timestamp: selectedDateTelem },
  });

  const info = data?.productInstance?.instanceInformation;

  let sample;
  let previous: string = "";
  let next: string = "";
  let summary;
  let isDisconnected = false;

  switch (info?.__typename) {
    case "PerlaInstanceInformation":
      previous = info?.telemetry?.previous;
      next = info?.telemetry?.next;
      sample = info?.telemetry?.value;
      summary = info?.summary?.value;
      isDisconnected = info?.registeredPerlaInstanceInformation?.stateBody
        ? info?.registeredPerlaInstanceInformation?.stateBody.includes(
            "disconnected"
          )
        : false;
      break;
    case "ProductInstanceInformation":
      break;
  }

  const {
    date,
    wlanQuality,
    gsmQuality,
    lastRegenerationS1,
    lastRegenerationS2,
    capacityRemainingS1,
    capacityRemainingS2,
    motor1Position,
    motor2Position,
  } = sample || {};

  const sampleDate =
    date && date !== "<DateTimeOffset>" ? new Date(date) : null;

  const pickerDate = sampleDate && !loading ? sampleDate : selectedDateTelem;

  const setSelectedDateStr = protectDateSetter(setSelectedDateTelem);

  const downloadIoTTelemetry = () => {
    if (
      data?.productInstance?.instanceInformation?.__typename ===
      "PerlaInstanceInformation"
    ) {
      if (data?.productInstance?.instanceInformation.telemetryRawData?.value) {
        const notificationTelemetry = JSON.stringify(
          data?.productInstance?.instanceInformation.telemetryRawData?.value,
          null,
          2
        ).replace(/\\"/g, '"'); /// beautifier fehlt noch

        console.log("TELEMETRY RAW: ", notificationTelemetry);

        const x = window.open();
        if (x) {
          x.document.open();
          x.document.write(
            "<html><body><pre>" + notificationTelemetry + "</pre></body></html>"
          );
          x.document.close();
        }
      }
    }
  };

  function reformat(dateStr: string | null, short: boolean = false) {
    if (!dateStr) return "-";
    if (short) {
      return (
        <Translate>
          {(translate) => {
            return format(
              new Date(dateStr),
              translate.translate("generics.dateTimeFormatFNSShort") as string
            );
          }}
        </Translate>
      );
    } else {
      return (
        <Translate>
          {(translate) => {
            return format(
              new Date(dateStr),
              translate.translate("generics.dateTimeFormatFNS") as string
            );
          }}
        </Translate>
      );
    }
  }

  const usingGsmOnly =
    (wlanQuality === 0 || wlanQuality === 255) &&
    summary?.connectedToClientLan === false;

  const showError = () => {
    return isDisconnected ? (
      <div
        style={{
          color: "rgb(201,51,54)",
          margin: "10px 0px",
          display: "flex",
        }}
      >
        <ErrorOutline style={{ height: "20px " }} />
        <div style={{ marginLeft: "5px" }}>
          <Translate id="components.telemetry.disconnected" />
        </div>
      </div>
    ) : !loading &&
      next === null &&
      !isWithinInterval(pickerDate, {
        start: subHours(new Date(), 48),
        end: new Date(),
      }) ? (
      <div
        style={{
          color: "rgb(201,51,54)",
          margin: "10px 0px",
          display: "flex",
        }}
      >
        <ErrorOutline style={{ height: "20px " }} />
        <div style={{ marginLeft: "5px" }}>
          <Translate id="components.telemetry.outOfDate" />
        </div>
      </div>
    ) : (
      <Fragment />
    );
  };

  if (loading && !loadNewDate)
    return (
      <div style={{ display: "flex", placeContent: "center" }}>
        <CircularProgress color="secondary" />{" "}
      </div>
    );
  if (!sample)
    return (
      <div>
        <Translate id="message.error.NoTelemetryData"></Translate>
      </div>
    );

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        marginBottom={2}
        style={{ flexFlow: "row wrap" }}
      >
        <Typography component="h3" variant="h6" style={{ marginRight: "10px" }}>
          <Translate id="components.telemetry.title" />
        </Typography>
        <Link
          underline="always"
          href="#"
          color="inherit"
          style={{ marginTop: "7px", marginRight: "10px" }}
          onClick={downloadIoTTelemetry}
        >
          {" "}
          <Translate id="components.deviceData.downloadJSON" />
        </Link>
        <Box display="flex" alignItems="center">
          {loading ? (
            <div style={{ width: "30px" }} />
          ) : (
            <ArrowBackIosRounded
              className={classes.arrow}
              color={previous ? "action" : "disabled"}
              onClick={() => {
                setSelectedDateStr(previous);
                setLoadNewDate(true);
              }}
            />
          )}
          <TextField
            className={classes.textField}
            type="datetime-local"
            value={pickerDate.toJSON().substr(0, 16)} //reformat(pickerDate.toString())
            onChange={(e) => setSelectedDateStr(e.target.value)}
          />
          {loading ? (
            <div style={{ width: "30px" }} />
          ) : (
            <ArrowForwardIosRounded
              className={classes.arrow}
              color={next ? "action" : "disabled"}
              onClick={() => {
                setSelectedDateStr(next);
                setLoadNewDate(true);
              }}
            />
          )}
        </Box>
      </Box>
      {showError()}
      {!loading ? (
        <Fragment>
          <Box paddingBottom={1}>
            <Grid
              container
              style={{
                width: "100%",
              }}
            >
              <Tooltip
                title={
                  summary?.logDate
                    ? (translate("components.telemetry.lan") as string) +
                      format(new Date(summary?.logDate), "d/M/y")
                    : (translate(
                        "components.telemetry.summaryNotAvailable"
                      ) as string)
                }
                placement="top-start"
                classes={{ tooltip: classes.customTooltip }}
              >
                <div style={{ width: "100%", display: "flex", height: "30px" }}>
                  <div style={{ fontWeight: "bold", width: "25%" }}>LAN</div>
                  <Box>
                    {summary?.connectedToClientLan ? (
                      <Done color="primary" />
                    ) : (
                      <Close htmlColor={colours.errorRed} />
                    )}
                  </Box>
                </div>
              </Tooltip>
              <div style={{ width: "100%", display: "flex", height: "30px" }}>
                <div style={{ fontWeight: "bold", width: "25%" }}>WLAN</div>
                <SignalStrength type={"wifi"} rssi={wlanQuality} />
              </div>
              <Tooltip
                title={translate("components.telemetry.onlyGsm")}
                placement="top-start"
                classes={{ tooltip: classes.customTooltip }}
              >
                <div style={{ width: "100%", display: "flex", height: "30px" }}>
                  <div style={{ fontWeight: "bold", width: "25%" }}>GSM</div>
                  <SignalStrength
                    type={"gsm"}
                    rssi={gsmQuality}
                    usingGsmOnly={usingGsmOnly}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Box>

          <Box paddingBottom={1}>
            <Grid
              container
              style={{
                width: "100%",
              }}
            >
              <Grid item xs={3} />
              <Grid item xs={5}>
                <Box borderBottom={1} borderRight={1} padding={0.5}>
                  <Translate id="components.telemetry.lastRegen" />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box borderBottom={1} padding={0.5} style={{}} minWidth={115}>
                  <Translate id="components.telemetry.capacity" /> (l * dH)
                </Box>
              </Grid>
              <Grid item xs={3}>
                <b>S1</b>
              </Grid>
              <Grid item xs={5}>
                <Box
                  borderBottom={1}
                  borderRight={1}
                  padding={0.5}
                  style={{
                    height: "100%",
                  }}
                >
                  <div>{reformat(lastRegenerationS1)}</div>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  borderBottom={1}
                  padding={0.5}
                  style={{
                    height: "100%",
                  }}
                  minWidth={115}
                >
                  {capacityRemainingS1}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <b>S2</b>
              </Grid>
              <Grid item xs={5}>
                <Box borderRight={1} padding={0.5}>
                  <div>{reformat(lastRegenerationS2)}</div>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box padding={0.5} minWidth={115}>
                  {capacityRemainingS2}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Grid container>
            <Grid item xs={3}>
              <b>
                <Translate id="components.telemetry.motor" /> 1
              </b>
            </Grid>
            <Grid
              item
              xs={9}
              style={{
                width: "300px",
                border: "solid transparent 1px",
              }}
            >
              Operation ({motor1Position})
            </Grid>
            <Grid item xs={3}>
              <b>
                <Translate id="components.telemetry.motor" /> 2
              </b>
            </Grid>
            <Grid item xs={9}>
              Operation ({motor2Position})
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Box
          height="184px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      )}
    </Box>
  );
};

export default withLocalize(Telemetry);
