import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Translate } from "react-localize-redux";

import { TextField, Box, CircularProgress, Button } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import EditLanguageDialog from "./EditLanguageDialog";

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import debounce from "debounce";

import { AppState } from "redux/store";
import { State as OrgOverviewState } from "redux/types/SendgridTemplatesOverview";
import {
  setGridState,
  setColumnStates,
  search,
} from "redux/actions/SendgridTemplatesOverviewActions";

import { UserProfile } from "types/ToolboxEntities";
import PageHeader from "components/common/PageHeader";
import GridFilterHeader from "components/common/Grid/GridFilterHeader";
import TemplatesGrid from "./TemplatesGrid";
import gql from "graphql-tag";
import {
  sendgridGroups,
  sendgridGroupsVariables,
} from "types/apolloGenerated/sendgridGroups";
import { open as createOpen } from "redux/actions/SendgridTemplatesCreateActions";
import { open as deleteOpen } from "redux/actions/SendgridTemplatesDeleteActions";
import { useLazyQuery } from "react-apollo";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    partnerOverview: {
      display: "flex",
      flexFlow: "column",

      "& > .gridFilterHeader": {
        flex: "0 1 auto",
      },

      "& > .innerContent": {
        flex: "1 1 100%",
        height: "100%",
      },

      "& .ag-body-viewport": {
        overflowX: "scroll",
      },
      "& .ag-full-width-row": {
        width: "auto!important",
      },
    },

    innerHeader: {
      flex: "0 1 auto",
      display: "flex",
      margin: "0 -20px 20px -20px",
      alignItems: "flex-end",
      justifyContent: "space-between",

      "& > *": {
        flex: "0 1 auto",
        margin: "0 20px",
        minWidth: "170px",
      },

      "& > .filter": {
        flex: "1 1 auto",
        maxWidth: "250px",
      },
    },
    innerHeaderRight: {
      flex: "0 1 auto",
      display: "flex",
      alignItems: "flex-end",
      margin: 0,
      border: "solid 2px grey20",
      width: "350px",
      borderRadius: "5px",
      height: "48px",

      "& > *": {
        flex: "0 1 auto",
        margin: "0 20px",
        minWidth: "170px",
      },
    },
  });
});

const filterList = [
  "Activation_Product_BWTPerla",
  "Activation_Product_BWTPerla_Silk_Success",
  "Activation_Product_BWTPerla_Success",
  "Activation_Product_Cillit_Duo",
  "Activation_Product_Cillit_Duo_Success",
  "Activation_Product_PerlaSilk",
  "Notification_BWT_Perla_Error",
  "Notification_BWT_Perla_low_Tabs_Level",
  "Notification_BWT_Perla_AQA_Liter_triggered",
  "Notification_BWT_Perla_AQA_Stop_triggered",
  "Notification_BWT_Perla_AQA_Watch_triggered",
  "Notification_BWT_Perla_AQA_Stop_Wireless_triggered",
  "Notification_BWT_Perla_Routine_Maintenance",
  "Notification_BWT_Perla_Silk_Professional_Maintenance",
  "Notification_BWT_Perla_Silk_Warranty_Renewal",
  "Notification_BWT_Perla_Silk_Sanitation_Reminder",
  "Notification_BWT_Perla_Professional_Maintenance",
  "Notification_Cillit_Duo_AQA_Liter_triggered",
  "Notification_Cillit_Duo_AQA_Stop_triggered",
  "Notification_Cillit_Duo_AQA_Stop_Wireless_triggered",
  "Notification_Cillit_Duo_AQA_Watch_triggered",
  "Notification_Cillit_Duo_Error",
  "Notification_Cillit_Duo_low_Tabs_Level",
  "Notification_Cillit_Duo_Professional_Maintenance",
  "Notification_Cillit_Duo_Routine_Maintenance",
];

export const QUERY_SENDGRID_TEMPLATE_LIST = gql`
  query sendgridGroups(
    $page: Int
    $filter: [SendGridTemplateFilter]
    $sorting: [SortingCriterionForSendGridTemplateFilterOption]
  ) {
    sendGridTemplates(
      page: $page
      pageSize: 0
      filter: $filter
      sorting: $sorting
    ) {
      page
      pageSize
      totalResults
      results {
        id
        name
        languageCode
        countryCode
        sendGridId
        sendGridUserId
        type
        isDefault
      }
    }
  }
`;

const debouncedSearch = debounce(
  (
    searchTerm: string,
    oldSearchTerm: string | null,
    searchAction: typeof search
  ) => {
    // console.log(searchTerm);
    if (searchTerm.length >= 3) {
      searchAction(searchTerm);
    } else if (
      searchTerm.length === 0 &&
      (oldSearchTerm === null || oldSearchTerm.length > 0)
    ) {
      searchAction(null);
    }
  },
  500
);

interface SendgridTemplatesListPara {
  user: UserProfile | null;
  templatesOverviewState: OrgOverviewState;
  actions: {
    setGridState: typeof setGridState;
    setColumnStates: typeof setColumnStates;
    createOpen: typeof createOpen;
    deleteOpen: typeof deleteOpen;
    search: typeof search;
  };
}

const SendgridTemplateGroupList: React.FC<SendgridTemplatesListPara> = ({
  user,
  templatesOverviewState,
  actions,
}) => {
  const classes = useStyles();

  const [search, setSearch] = useState<string>(
    templatesOverviewState.search || ""
  );

  const [openLanguage, setOpenLanguage] = useState<boolean>(false);

  const [getSendgridTemplates, { data: templatesData }] = useLazyQuery<
    sendgridGroups,
    sendgridGroupsVariables
  >(QUERY_SENDGRID_TEMPLATE_LIST, { fetchPolicy: "network-only" });

  useEffect(() => {
    console.log("USE EFFECTING!");
    getSendgridTemplates();
  }, [getSendgridTemplates]);

  if (!user) {
    return null;
  }

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    setSearch(event.target.value);
    debouncedSearch(
      event.target.value,
      templatesOverviewState.search,
      actions.search
    );
  };

  let sendgridTemplatesGrid = (
    <div>
      <CircularProgress size={30} color="secondary" />
    </div>
  );

  //Remove templates that are now handles by Phoenix
  const filteredTemplateData =
    templatesData?.sendGridTemplates?.results?.filter(
      (x) => !filterList.includes(x?.name ?? "")
    ) ?? [];

  if (filteredTemplateData.length) {
    sendgridTemplatesGrid = (
      <TemplatesGrid
        templates={filteredTemplateData}
        state={templatesOverviewState}
        actions={actions}
      />
    );
  }

  return (
    <Translate>
      {({ translate }) => (
        <Fragment>
          <PageHeader
            title="pages.sendgrid.title"
            onAdd={() => {
              actions.createOpen("opens");
            }} /* onSettings={() => { history.push("TemplateGroupDetails/index") }}*/
          />
          <div
            className={
              "mainContentContainer customGrid fullHeight " +
              classes.partnerOverview
            }
          >
            <GridFilterHeader className="gridFilterHeader">
              <Box>
                <TextField
                  className="filter"
                  id="standard-basic"
                  label="Type to Filter"
                  value={search}
                  onChange={changeSearch}
                />
              </Box>
              <Button variant="outlined" onClick={() => setOpenLanguage(true)}>
                <Translate id="pages.sendgrid.edit" />
              </Button>
            </GridFilterHeader>
            <EditLanguageDialog
              open={openLanguage}
              close={() => {
                setOpenLanguage(false);
                getSendgridTemplates();
              }}
            />
            {sendgridTemplatesGrid}
          </div>
        </Fragment>
      )}
    </Translate>
  );
};

const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  templatesOverviewState: appState.sendgridTemplatesOverview,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { setGridState, setColumnStates, search, createOpen, deleteOpen },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendgridTemplateGroupList);
