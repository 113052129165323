import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";

import { AppState } from "redux/store";

import { Store } from "redux/types/DeviceActions/ResetInstaller";
import { close } from "redux/actions/DeviceActions/ResetInstallerActions";

import gql from "graphql-tag";

import { Translate } from "react-localize-redux";
import { useMutation } from "@apollo/react-hooks";

import Error from "components/common/Error";
import { ErrorCodes } from "types/CampGuideTypes";

import {
  resetActivation as MutationData,
  resetActivationVariables as MutationVars,
} from "types/apolloGenerated/resetActivation";

interface IChangeOrganizationActiveState {
  store: Store;
  actions: {
    close: typeof close;
  };
}

const RESETINSTALLER = gql`
  mutation resetInstaller($productCode: String!) {
    productInstance {
      resetInstaller(productCode: $productCode)
    }
  }
`;

const ResetInstaller: React.FC<IChangeOrganizationActiveState> = ({
  store,
  actions,
}) => {
  const [resetActivation, { loading, error }] = useMutation<
    MutationData,
    MutationVars
  >(RESETINSTALLER, {
    update: () => {
      if (!error) {
        // ToDo: Apollo-Cache-Update
        actions.close();
      }
    },
  });

  const submit = () => {
    if (store.openWithProductCode) {
      resetActivation({
        variables: { productCode: store.openWithProductCode },
      });
    }
  };

  const errorElement = error ? (
    <Error error={{ code: ErrorCodes.Unknown, message: error.message }} />
  ) : null;
  const progress = loading ? <CircularProgress color="secondary" /> : null;

  return (
    <Dialog
      open={Boolean(store.openWithProductCode)}
      onClose={actions.close}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="form-dialog-title">
        <Translate id="types.common.deleteInstaller" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Translate id="components.resetInstaller.subtitle" />
        </DialogContentText>
        {errorElement}
      </DialogContent>
      <DialogActions>
        {progress}
        <Button onClick={actions.close} color="primary" disabled={loading}>
          <Translate id="generics.cancel" />
        </Button>
        <Button onClick={submit} color="primary" disabled={loading}>
          <Translate id="generics.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  store: state.deviceActions.resetInstaller,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ close }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetInstaller);
