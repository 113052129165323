import React, { ReactElement, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";

import { useQuery } from "@apollo/react-hooks";
import USER_LANGUAGE from "commonQueries/userLanguage";

import {
  LocalizeProvider,
  withLocalize,
  LocalizeContextProps,
  onMissingTranslationFunction,
  MissingTranslationOptions,
} from "react-localize-redux";

import langEN from "resources/i18n/en.translations.json";
import langDE from "resources/i18n/de.translations.json";
import langFR from "resources/i18n/fr.translations.json";

import langTranslationsEN from "resources/i18n/en.languages.json";
import langTranslationsDE from "resources/i18n/de.languages.json";
import langTranslationsFR from "resources/i18n/fr.languages.json";

import langCountryEN from "resources/i18n/en.country_translations.json";
import langCountryDE from "resources/i18n/de.country_translations.json";
import langCountryFR from "resources/i18n/fr.country_translations.json";

export const fallbackTo = (fallback: string): onMissingTranslationFunction => {
  return (options: MissingTranslationOptions) => {
    if (
      options.defaultTranslation ===
      "No default translation found! Ensure you've added translations for your default langauge."
    ) {
      return fallback;
    }
    return options.defaultTranslation;
  };
};

interface I18nConfigProps extends LocalizeContextProps {
  children: ReactElement;
  language: string;
}

const I18nConfig = withLocalize(
  ({
    children,
    language,
    initialize,
    addTranslationForLanguage,
    setActiveLanguage,
  }: I18nConfigProps) => {
    useState(() => {
      initialize({
        languages: [
          { name: "English", code: "en" },
          { name: "German", code: "de" },
          { name: "French", code: "fr" },
        ],
        options: {
          renderToStaticMarkup,
          onMissingTranslation: ({ defaultTranslation }) => {
            if (typeof defaultTranslation == "string") {
              return defaultTranslation;
            } else {
              return renderToStaticMarkup(defaultTranslation);
            }
          },
        },
      });

      setActiveLanguage(language);

      addTranslationForLanguage(langEN, "en");
      addTranslationForLanguage(langDE, "de");
      addTranslationForLanguage(langFR, "fr");
      addTranslationForLanguage(langCountryEN, "en");
      addTranslationForLanguage(langCountryDE, "de");
      addTranslationForLanguage(langCountryFR, "fr");
      addTranslationForLanguage(langTranslationsEN, "en");
      addTranslationForLanguage(langTranslationsDE, "de");
      addTranslationForLanguage(langTranslationsFR, "fr");

      return true;
    });
    return children;
  }
);

function countryCheck(language: string) {
  let setLanguage = "en";
  switch (language) {
    case "en":
      setLanguage = "en";
      break;
    case "fr":
      setLanguage = "fr";
      break;
    case "de":
      setLanguage = "de";
      break;
    default:
      setLanguage = "en";
      break;
  }
  return setLanguage;
}

interface LocalizationProps {
  children: ReactElement;
  store: any;
}

const Localization: React.FC<LocalizationProps> = ({ children, store }) => {
  const { data, loading } = useQuery(USER_LANGUAGE);
  const lang = data?.user?.userInformation?.preferredLanguage;
  const userLang = countryCheck(lang);
  return (
    <LocalizeProvider store={store}>
      {!loading ? (
        <I18nConfig language={userLang}>{children}</I18nConfig>
      ) : (
        <div></div>
      )}
    </LocalizeProvider>
  );
};

export default Localization;
