import React, { useState } from "react";
import {
  Map,
  GoogleApiWrapper,
  GoogleAPI,
  Marker,
  MapProps
} from "google-maps-react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { GeoLocationInput } from "types/apolloGenerated/globalTypes";
import cursor from "resources/maps-cursor.png";

const useStyles = makeStyles(() => {
  return createStyles({
    latlng: {
      position: "absolute",
      bottom: "-25px",
      "& span:first-child": {
        paddingRight: 15
      }
    },
    cursor: {
      zIndex: 999,
      height: "50px",
      width: "50px",
      top: "50%",
      left: "50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      pointerEvents: "none"
    }
  });
});

interface MapData {
  google: GoogleAPI;
  location?: GeoLocationInput | null;
  onChange: (value: any) => void;
}

const GoogleMap: React.FC<MapData> = ({ google, location, onChange }) => {
  const classes = useStyles();

  const center = location
    ? { lat: location.latitude, lng: location.longitude }
    : { lat: 0, lng: 0 };

  const [position, setPosition] = useState<{ state: string; location: any }>({
    state: "loading",
    location: center
  });

  const [positionText, setPositionText] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: center.lat,
    lng: center.lng
  });

  const [zoom, setZoom] = useState<number>(location ? 18 : 1);

  const [showCursor, setShowCursor] = useState<boolean>(false);

  const mapChangeFunc = (
    evt: MapProps | undefined,
    map: google.maps.Map | undefined
  ) => {
    const newLocation = {
      lat: map ? map.getCenter().lat() : 0,
      lng: map ? map.getCenter().lng() : 0
    };
    setPosition({
      state: map ? "correct" : "notFound",
      location: newLocation
    });
    setPositionText(newLocation);

    if (map && evt) {
      if (evt.zoom !== map.getZoom()) {
        setZoom(map.getZoom());
      }
    }
    onChange({ latitude: newLocation.lat, longitude: newLocation.lng });
  };

  return (
    <Box id="deviceLocationMap">
      <Map
        google={google}
        center={center}
        initialCenter={position.location}
        streetViewControl={false}
        fullscreenControl={false}
        zoom={zoom}
        onDragend={(evt, map) => {
          mapChangeFunc(evt, map);
        }}
        onMouseover={() => setShowCursor(true)}
        onMouseout={() => setShowCursor(false)}
      >
        <Marker position={center}></Marker>
      </Map>
      <img
        className={classes.cursor}
        src={cursor}
        alt="+"
        style={{ display: showCursor ? "block" : "none" }}
      />
      <Box id="mapsLatLongDisplay" className={classes.latlng}>
        <Box component="span">lat: {positionText.lat.toPrecision(12)}</Box>
        <Box component="span">lng: {positionText.lng.toPrecision(12)}</Box>
      </Box>
    </Box>
  );
};

export const getLocationFromAddress = (
  address: string,
  callback: (newState: string, newLocation?: GeoLocationInput) => void
) => {
  if (window.google) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        callback(status, {
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng()
        });
      } else {
        let state = `Error with Geocode: ${status}`;
        if (status === google.maps.GeocoderStatus.ZERO_RESULTS)
          state = "address not found";
        callback(state);
      }
    });
  }
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCWlZ_Cq39bF7vr_x7lhNS_Z6u5uAM9F1M"
})(GoogleMap);
