import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Grid, TextField, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import DropDown from 'components/common/DropDown'
import GoogleMapsMap from './GoogleMapsMap'
import { PartnerDetail_organization, PartnerDetail_organization_address, PartnerDetail_organization_groups } from 'types/apolloGenerated/PartnerDetail';
import { Translate } from 'react-localize-redux';

import cloneDeep from 'clone-deep';
import { connect } from 'react-redux';

import MultiSelect, { MultiSelectOption } from 'components/common/MultiSelect';
import { PartnerGroups_organizationGroups } from 'types/apolloGenerated/PartnerGroups';
import { AuthStore } from 'redux/types/Auth';
import { AppState } from 'redux/store';
import format from "date-fns/format";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      marginBottom: "50px",
      paddingTop: "15px",
      "& .MuiTextField-root, & .MuiFormControl-root": {
        width: "100%",
        "& .MuiInputLabel-outlined": {
          transform: "translate(14px, 15px) scale(1)",
          fontSize: "0.9rem",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
      "& .MuiGrid-grid-xs-6, & .MuiGrid-grid-xs-12": {
        paddingLeft: "30px",
        paddingTop: "25px",
        display: "inherit",
      },
      "& .MuiGrid-grid-xs-6 .MuiGrid-item:not(:first-child):not(:last-child)": {
        padding: '0px 10px',
      },
    },
    TextField:{top:"-10px"}
  }));

const mapReduceToOptionType = (reduced: MultiSelectOption[], group: PartnerDetail_organization_groups | PartnerGroups_organizationGroups | null | undefined) => {
  if (group && !['bwt_twp', 'bwt_builder', 'bwt_operator', 'bwt_partner', 'bwt_planner', 'bwt_wholesaler', 'bwt_professional'].includes(group.name)) {
    reduced.push({
      value: group.id + '',
      label: group.name
    });
  }
  return reduced;
};

const mapReduceToGroup = (reduced: PartnerGroups_organizationGroups[], optionType: MultiSelectOption | null | undefined) => {
  if (optionType && !['bwt_twp', 'bwt_builder', 'bwt_operator', 'bwt_partner', 'bwt_planner', 'bwt_wholesaler', 'bwt_professional',].includes(optionType.label)) {
    reduced.push({
      id: Number(optionType.value),
      name: optionType.label,
      __typename: 'OrganizationGroup'
    });
  }
  return reduced;
};

const nulllAddress: PartnerDetail_organization_address = {
  __typename: 'Address',
  street: null,
  postalCode: null,
  city: null,
  countryCode: null,
  location: null
};

interface PartnerInformationProps {
  auth: AuthStore
  isNewMode: boolean
  data: PartnerDetail_organization
  selectedGroups?: PartnerGroups_organizationGroups[]
  setSelectedGroups: (selected: PartnerGroups_organizationGroups[]) => void
  selectableGroups: PartnerGroups_organizationGroups[]
  onChangeFunc?: (value: any, name: string) => void
};

const PartnerInformation: React.FC<PartnerInformationProps> = ({ auth, data, isNewMode, selectedGroups = [], setSelectedGroups, selectableGroups = [], onChangeFunc = (value, name) => { } }) => {
  let classes = useStyles();

  const addressRef = useRef<PartnerDetail_organization_address>(data.address || nulllAddress);
  const [address, setAddress] = useState<PartnerDetail_organization_address>(cloneDeep(addressRef.current));
  const [isError, setIsError] = useState<boolean>((data.partnerType === "TWP" && !data.vatNumber) ? true : false)
  const [helperText, setHelperText] = useState<string>((data.partnerType === "TWP" && !data.vatNumber) ? "Field Required" : "")

  let variant: 'standard' | 'outlined' | 'filled' = 'standard';
  let autoComplete = 'off';
  

  const onTAXChange = (value:any, name: string) => {
    let text = ""
    let error = false
    if(data.partnerType === "TWP" && !value)
    { 
      error = true
      text = "Field Required" 
    }
    else 
    { 
      error = false
      text = ""
    }
    setHelperText(text)
    setIsError(error)
    onChangeFunc(value,name)
  }

  const onPartnerTypeChangeFunc = (evt:any) => {
    let temp = false
    let text = ""
    if(evt === "TWP" && !data.vatNumber)
    { 
      temp = true 
      text = "Field Required"
    }
    else 
    { 
      temp = false 
      text = ""
    }
    setIsError(temp)
    setHelperText(text)
    onChangeFunc(evt,"partnerType")
  }

  const onBlurFunc = (evt: React.SyntheticEvent<any, Event>) => {
    setAddress(cloneDeep(addressRef.current));
  };

  const onChangeAddress = useCallback((value: any, name: string) => {
    if (name === "address/street") {
      addressRef.current.street = value;
    } else if (name === "address/postalCode") {
      addressRef.current.postalCode = value;
    } else if (name === "address/city") {
      addressRef.current.city = value;
    } else if (name === "address/countryCode") {
      addressRef.current.countryCode = value;
    }

    onChangeFunc(value, name);
  }, [onChangeFunc]);

  const onChangePosition = useCallback((value: Geolocation) => {
    onChangeFunc(value, "address/location");
  }, [onChangeFunc]);

  useEffect(() => {
    addressRef.current = data.address || nulllAddress;
    setAddress(cloneDeep(addressRef.current));
  }, [data.address]);

  const selectableGroupsOT = useMemo(() => {
    return selectableGroups.reduce(mapReduceToOptionType, []);
  }, [selectableGroups]);

  const selectedGroupsOT = useMemo(() => {
    return selectedGroups.reduce(mapReduceToOptionType, []);
  }, [selectedGroups]);

  const [countryCode, setCountryCode] = React.useState("");
  const changeCountryCode = useCallback((selectedVal: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode) => {
    setCountryCode("" + selectedVal);
    console.log("changeCountryCode", selectedVal);
    onChangeFunc(selectedVal, "address/countryCode");
  }, [onChangeFunc]);

  return (
    <Grid container className={classes.container} style={{ position: "relative" }}>
      <Grid item xs={7}>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              defaultValue={data.name}
              label={<Translate id="components.partnerDetails.name" />}
              variant={variant}
              autoComplete={autoComplete}
              name="name"
              disabled={true} />
          </Grid>
          <Grid item xs={6}>
            <Grid item xs>
              <TextField
                defaultValue={data.telephone}
                label={<Translate id="components.partnerDetails.telephone" />}
                variant={variant}
                autoComplete={autoComplete}
                name="telephone"
                disabled={true} />
            </Grid>
            <Grid style={{ paddingLeft: "10px" }} item xs>
                <TextField
                defaultValue={data.vatNumber}
                error={isError}
                label={<Translate id="types.common.vatNumber" />}
                helperText={helperText}
                variant={variant}
                autoComplete={autoComplete}
                name="vatNumber"
                disabled={true} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              defaultValue={data.address?.street}
              label={<Translate id="types.address.street" />}
              variant={variant}
              autoComplete={autoComplete}
              name="address/street"
              disabled={true} />
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={3}>
              <TextField
                defaultValue={data.address?.postalCode}
                label={<Translate id="types.address.postalCode" />}
                variant={variant}
                autoComplete={autoComplete}
                name="address/postalCode"
                disabled={true} />
            </Grid>
            <Grid item xs={5}>
              <TextField
                defaultValue={data.address?.city}
                label={<Translate id="types.address.city" />}
                variant={variant}
                autoComplete={autoComplete}
                name="address/city"
                disabled={true} />
            </Grid>
            <Grid item xs={4}>
              <DropDown
                fieldName={<Translate id="types.address.countryCode" />}
                valueList={auth.user?.allowedCountries}
                selectedVal={data.address?.countryCode || countryCode}
                name="address/countryCode"
                variant={variant}
                disabledMenuItem={true}
                isDisabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              defaultValue={data.email}
              label={<Translate id="generics.generalEmail" />}
              variant={variant}
              autoComplete={autoComplete}
              name="email"
              disabled={true} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              defaultValue={data.externalId} //data.externalId
              label={<Translate id="components.partnerDetails.customerNumber" />}
              variant={variant}
              autoComplete={autoComplete}
              name="externalId"
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <DropDown
              fieldName={<Translate id="components.partnerDetails.partnerType" />}
              valueList={['TWP', 'BUILDER', 'OPERATOR', 'PLANNER', 'WHOLESALER', 'PROFESSIONAL']}
              selectedVal={data.partnerType || 'BUILDER'}
              name="partnerType"
              variant={variant}
              noneValue={false}
              isDisabled={true}
              />
          </Grid>
          <Grid item xs={6}>
            <TextField
              defaultValue={data.size}
              label={<Translate id="components.partnerDetails.organizationSize" />}
              variant={variant}
              autoComplete={autoComplete}
              name="size"
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12}>
                <DropDown
                    fieldName={<Translate id="components.partnerDetails.visibility" />}
                    valueList={['VISIBLE', 'CONTACT_ONLY', 'PROMOTED', 'HIDDEN']}
                    selectedVal={data.visibility || 'VISIBLE'}
                    name="visibility"
                    variant={variant}
                    noneValue={false}
                    isDisabled={true} />
            </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <MultiSelect
              headline="components.partnerDetails.partnerGroups"
              options={selectableGroupsOT}
              defaultValue={selectedGroupsOT}
              onChange={(selected) => { setSelectedGroups(selected ? ((selected as MultiSelectOption[]).reduce(mapReduceToGroup, [])) : []); }}
              isDisabled={false}
              error={null}
            />
          </Grid>
        </Grid>{
              !isNewMode && (
                  <Grid container>
                      <Grid item xs={6}>
                          <Translate>{
                              (translate) => {
                                  return <TextField
                                      label={<Translate id="components.partnerDetails.joinDate" />}
                                      variant='standard'
                                      defaultValue={data.creationTimeStamp ? format(new Date(data.creationTimeStamp),
                                           translate.translate('generics.dateTimeFormatFNS') as string) : ""}
                                      disabled={true}
                                  />
                              }
                          }
                          </Translate>
                      </Grid>
                  </Grid> 
                )
             }    
      </Grid>
      <Grid item xs={3}>
        <GoogleMapsMap
          isNewMode={isNewMode}
          address={address}
          onChange={onChangePosition}
        />
      </Grid>
    </Grid>
  )
};


const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user
});

export default connect(
  mapStateToProps
)(PartnerInformation);


