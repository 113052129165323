import { Error } from 'types/CampGuideTypes';

export enum ActionType {
  OPEN = 'DELETE_ACCOUNT_OPEN',
  CLOSE = 'DELETE_ACCOUNT_CLOSE',
  LOADING = 'DELETE_ACCOUNT_LOADING',
  ERROR = 'DELETE_ACCOUNT_ERROR',
}

export interface ChangeData {
  userId: string,
}

export interface Store {
  isLocked: boolean,
  changeData: ChangeData | null,
  error: Error | null
}


export interface ErrorAction {
  type: ActionType.ERROR,
  payload: Error
}

export interface OpenAction {
  type: ActionType.OPEN,
  payload: ChangeData
}

export interface CloseAction {
  type: ActionType.CLOSE,
}

export interface LoadingAction {
  type: ActionType.LOADING,
}

export type Action = ErrorAction | OpenAction | CloseAction | LoadingAction;
