import React from "react";
import { TranslateFunction } from "react-localize-redux";
import gql from "graphql-tag";

import { ColDef } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { factoryApolloConnector } from "util/AgGridUtils";

import {
  customerPoolInstances,
  customerPoolInstances_customers_results_registeredPools,
} from "types/apolloGenerated/customerPoolInstances";

import format from "date-fns/format";
import { Translate } from "react-localize-redux";

const QUERY = gql`
  query customerPoolInstances($id: String) {
    customers(filter: { filterOption: ID, operator: EQ, values: [$id] }) {
      results {
        registeredPools {
          location
          material
          shape
          volume
          poolType
          disinfectionType
          lastModification
          address {
            countryCode
            street
            city
            postalCode
          }
        }
      }
    }
  }
`;

export const apolloConnectorById = (id: String) => {
  return factoryApolloConnector(
    QUERY,
    (data: customerPoolInstances) => {
      const result = data.customers?.results?.[0];

      // getDataSource expects results rather than "registeredProducts".
      const transformedResponse = {
        results: result?.registeredPools,
        __typename: result?.__typename,
      };

      return transformedResponse;
    },
    {},
    { customArguments: { id } }
  );
};

export const getColumnDefs = (translate: TranslateFunction): ColDef[] => {
  const columnDefs = [
    {
      headerName: translate("components.registeredPools.location") as string,
      field: "location",
      width: 120,
    },
    {
      headerName: translate("components.registeredPools.material") as string,
      field: "material",
      width: 120,
    },
    {
      headerName: translate("components.registeredPools.shape") as string,
      field: "shape",
      width: 120,
    },
    {
      headerName: translate("components.registeredPools.volume") as string,
      field: "volume",
      cellRenderer: "Volume",
      width: 120,
    },
    {
      headerName: translate(
        "components.registeredPools.disinfectionType"
      ) as string,
      field: "disinfectionType",
      width: 160,
    },
    {
      headerName: translate("components.registeredPools.poolType") as string,
      field: "poolType",
      width: 160,
    },
    {
      headerName: translate(
        "components.registeredPools.lastModification"
      ) as string,
      cellRenderer: "DateCell",
      field: "lastModification",
      width: 200,
    },
    {
      headerName: translate("types.address.postalCode") as string,
      field: "address.postalCode",
      width: 140,
    },
    {
      headerName: translate("types.address.street") as string,
      field: "address.street",
      width: 140,
    },
    {
      headerName: translate("types.address.city") as string,
      field: "address.city",
      width: 140,
    },
    {
      headerName: translate("types.address.countryCode") as string,
      field: "address.countryCode",
      width: 140,
    },
  ];
  return columnDefs;
};

interface CellRenderer {
  value: string;
  data: customerPoolInstances_customers_results_registeredPools;
}

const Volume: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  return <div>{`${data.volume}m³`}</div>;
};

const DateCell: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data || !value) {
    return <div />;
  }
  return (
    <Translate>
      {(translate) => {
        return (
          <div>
            {format(
              new Date(value),
              translate.translate("generics.dateTimeFormatFNS") as string
            )}
          </div>
        );
      }}
    </Translate>
  );
};

export const CellRenderer = {
  Volume,
  DateCell,
};
