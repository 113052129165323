import React, { useState, createRef } from 'react'

import { withLocalize, LocalizeContextProps } from 'react-localize-redux';

import { format, getYear, getMonth } from 'date-fns';
import { enGB, de, fr } from 'date-fns/locale';

import { InputAdornment, Input } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


// @ts-ignore
import Picker from 'react-month-picker'
import "react-month-picker/css/month-picker.css"


const langLocalMapping: { [key: string]: any } = {
  en: enGB,
  de: de,
  fr: fr
};

let langMonthArray: { [key: string]: any } = {};


interface MonthBoxPara {
  value: string
  onClick: () => void
}

const MonthBox: React.FC<MonthBoxPara> = ({ value, onClick }) => {
  return (
    <Input
      style={{ cursor: 'pointer' }}
      id="month-selector-input"
      value={value}
      onClick={onClick}
      endAdornment={
        <InputAdornment position="end">
          <ArrowDropDownIcon />
        </InputAdornment>
      }
    />
  );
}



export interface YearMonth {
  /** The Year as 4-digit number */
  year: number
  /** The month as number starting with 0 for jan to 11 for dec */
  month: number
}


interface MonthPickerPara extends LocalizeContextProps {
  years?: number[]
  defaultValue?: YearMonth
  value?: YearMonth
  onChange?: (value: YearMonth) => void
}


const MonthPicker: React.FC<MonthPickerPara> = ({ value, defaultValue, translate, activeLanguage, onChange, years }) => {

  const [mvalue, setMvalue] = useState<YearMonth | undefined>(defaultValue);

  const pickerRef = createRef();


  if (!langMonthArray[activeLanguage.code]) {
    const locale = langLocalMapping[activeLanguage.code] || enGB;
    langMonthArray[activeLanguage.code] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((idxMonth) => format(new Date(2020, idxMonth, 1), "MMM", { locale: locale }));
  }

  const show = () => {
    // @ts-ignore
    pickerRef.current?.show();
  };

  const pickerChange = function (year: number, month: number) {
    onChange && onChange({ year, month });
    if (!value) {
      setMvalue({ year, month });
    }
  };

  const current = value || mvalue;
  const currentStr = current ? format(new Date(`${current.year}-${current.month}`), 'MMMM yyyy') : '';
  const pickerPreSet = current || { year: getYear(new Date()), month: getMonth(new Date()) };

  return (
    <Picker
      ref={pickerRef}
      value={pickerPreSet}
      lang={langMonthArray[activeLanguage.code]}
      onChange={pickerChange}
      years={years}
    >
      <MonthBox value={currentStr} onClick={show} />
    </Picker>
  )

}



export default withLocalize(MonthPicker);
