import React, { Fragment, useState } from "react";
import { useMutation, useQuery, useLazyQuery } from "react-apollo";

import { Translate, LocalizeContextProps } from "react-localize-redux";
import { useParams } from "react-router";
import ApprovalForm from "./ApprovalForm";
import { QUERY_PARTNER_LIST } from "Pages/PartnerManager/Companies/GridConfig";

import gql from "graphql-tag";

import {
  makeStyles,
  createStyles,
  Theme,
  TextField,
  Box,
  CircularProgress,
  Link,
  Grid,
} from "@material-ui/core";
import {
  organizationsGrid,
  organizationsGridVariables,
  organizationsGrid_organizations_results,
} from "types/apolloGenerated/organizationsGrid";
import {
  OrganizationFilterOption,
  FilterOperator,
  Position,
} from "types/apolloGenerated/globalTypes";
import {
  OrganizationCreationRequest,
  OrganizationCreationRequestVariables,
} from "types/apolloGenerated/OrganizationCreationRequest";
import {
  organizationCreationRequestApprovalReassignment,
  organizationCreationRequestApprovalReassignmentVariables,
} from "types/apolloGenerated/organizationCreationRequestApprovalReassignment";
import { QUERY_ORGANIZATION_CREATION_REQUEST } from "./ApprovalForm";
import { Link as Hyperlink } from "react-router-dom";
import { useHistory } from "react-router";
import { useDebouncedCallback } from "use-debounce";
import { QUERY_PARTNER_DETAIL } from "commonQueries/partner";
import { MUTATE_PARTNER } from "Pages/PartnerManager/Companies/Detail/index";
import { OrganizationUpdateInput } from "types/apolloGenerated/globalTypes";

import { qraphQlErrorsToString } from "util/GraphQL";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: "30px",
      marginBottom: "50px",
      "& h1": {
        marginBottom: "20px",
      },
    },
    block: {
      display: "block",
      marginBottom: "20px",
    },
    actionsBox: {
      margin: "0 5px",
      "& a": {
        margin: "0 5px",
        cursor: "pointer",
        color: "rgb(0, 0, 238)",
      },
    },

    resultBox: {
      width: "100%",
      border: "solid 1px #BDC3C7",
      marginTop: "12px",
    },
    listLink: {
      color: "#005D8F!important",
      textDecoration: "none",
      "&:visited": {
        color: "#005D8F!important",
      },
      "&:hover": {
        textDecoration: "underline",
      },
    },
    nobreak: {
      whiteSpace: "nowrap",
    },

    resultTable: {
      borderSpacing: 0,

      "& tr": {
        height: "28px",
        lineHeight: "28px",

        "& td": {
          margin: 0,
          padding: "0 10px",
        },
      },

      "& tr:nth-child(even)": {
        backgroundColor: "#fcfdfe",
      },

      "& tr + tr td": {
        borderTop: "solid 1px #d9dcde",
      },
    },
  })
);

const MUTATE_ORGANIZATION_CREATION_REASSIGNMENT = gql`
  mutation organizationCreationRequestApprovalReassignment(
    $requestId: Guid!
    $position: Position!
    $organizationId: Int!
  ) {
    organization {
      organizationCreationRequestApprovalReassignment(
        requestId: $requestId
        position: $position
        organizationId: $organizationId
      )
    }
  }
`;

type onSelect = (
  organization: organizationsGrid_organizations_results,
  organizationId: number,
  position: Position
) => void;

interface SearchPartnerProps {
  searchterm: string;
  maxSearchResult?: number;
  countries: string[];
  onSelect: onSelect;
}

const SearchPartner: React.FC<SearchPartnerProps> = ({
  searchterm,
  maxSearchResult,
  countries,
  onSelect,
}) => {
  const classes = useStyles();
  const loadingVariables: organizationsGridVariables = {
    pageSize: maxSearchResult || 10,
    filter: [
      {
        filterOption: [
          OrganizationFilterOption.NAME,
          OrganizationFilterOption.CITY,
          OrganizationFilterOption.EMAIL,
        ],
        operator: FilterOperator.STARTSWITH,
        values: [searchterm],
      },
      {
        filterOption: [OrganizationFilterOption.COUNTRY],
        operator: FilterOperator.EQ,
        values: countries,
      },
    ],
  };

  const { data, loading, error } = useQuery<
    organizationsGrid,
    organizationsGridVariables
  >(QUERY_PARTNER_LIST, { variables: loadingVariables });

  if (loading)
    return (
      <div style={{ marginTop: "20px" }}>
        <CircularProgress color="secondary" />
      </div>
    );
  if (!data)
    return (
      <div>
        <Translate id="types.common.noSearchResult" />
      </div>
    );
  if (error)
    return (
      <div>
        <Translate id="message.error.generic_loading_data" />
      </div>
    );

  const organizations: JSX.Element[] = [];

  data.organizations?.results?.forEach((organization) => {
    if (organization) {
      organizations.push(
        <tr key={organization?.id}>
          <td>
            <Hyperlink
              className={classes.listLink}
              /* target={"_blank"} */ to={"/pam/partner/" + organization?.id}
            >
              {organization?.name}
            </Hyperlink>
          </td>
          <td>{organization?.address?.street}</td>
          <td>{organization?.address?.city}</td>
        </tr>
      );
    }
  });

  if (organizations.length === 0)
    return (
      <Grid container style={{ position: "relative" }}>
        <Grid item xs={8}>
          <div className={classes.resultBox}>
            <Translate id="pages.partnerRequest.noSearchResult" />
          </div>
        </Grid>
      </Grid>
    );

  return (
    <Grid container style={{ position: "relative" }}>
      <Grid item xs={8}>
        <div className={classes.resultBox}>
          <table className={classes.resultTable}>
            <tbody>{organizations}</tbody>
          </table>
        </div>
      </Grid>
    </Grid>
  );
};

interface LinkedUsersPara extends LocalizeContextProps {}

const ApprovalPage: React.FC<LinkedUsersPara> = ({ translate }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [searchTerm, setSearchTrem] = useState<string>("");
  const history = useHistory();
  const [error, setError] = useState("");
  const [organizationToAddVat, setOrganizationToAddVat] = useState<number>();

  const { data: organizationData } = useQuery<
    OrganizationCreationRequest,
    OrganizationCreationRequestVariables
  >(QUERY_ORGANIZATION_CREATION_REQUEST, {
    variables: { requestId: id },
  });

  const [getPersistedPartner] = useLazyQuery(QUERY_PARTNER_DETAIL, {
    onCompleted: (data) => {
      if (data && data?.organization?.vatNumber) {
        //Redirect without vat mutation
        history.push("/pam/partner/" + data.organization.id);
      } else {
        const tempOrganizationData = data?.organization;
        tempOrganizationData.vatNumber =
          organizationData?.organizationCreationRequest?.vatNumber;

        const variables: OrganizationUpdateInput = {
          id: tempOrganizationData.id,
          name: tempOrganizationData.name,
          partnerType: tempOrganizationData.partnerType,
          email: tempOrganizationData.email,
          website: tempOrganizationData.website,
          telephone: tempOrganizationData.telephone,
          logoLinkUrl: tempOrganizationData.logoLinkUrl,
          partnerImageLinkUrl: tempOrganizationData.partnerImageLinkUrl,
          externalId: tempOrganizationData.externalId,
          size: tempOrganizationData.size,
          vatNumber: tempOrganizationData.vatNumber,
        };

        if (tempOrganizationData.id) {
          updatePartnerVat({
            variables: {
              organization: variables,
            },
          }).catch((error) => {
            const errorString =
              (translate("message.error.error_partnerInformation") as string) +
              qraphQlErrorsToString(error, translate);
            throw new Error(errorString);
          });
        }
      }
    },
  });

  const [updatePartnerVat] = useMutation(MUTATE_PARTNER, {
    onCompleted: (data) => {
      //Redirect after vat mutation
      history.push("/pam/partner/" + data.organization.update.id);
    },
    refetchQueries: [
      {
        query: QUERY_PARTNER_DETAIL,
        variables: { id: organizationToAddVat },
      },
    ],
    onError: (error) => {
      setError(error.message);
    },
  });

  const [
    mutateOrganizationReassignment,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<
    organizationCreationRequestApprovalReassignment,
    organizationCreationRequestApprovalReassignmentVariables
  >(MUTATE_ORGANIZATION_CREATION_REASSIGNMENT, {
    onCompleted: (data) => {
      getPersistedPartner({
        variables: {
          id:
            data.organization?.organizationCreationRequestApprovalReassignment,
        },
      });
    },
    refetchQueries: [
      {
        query: QUERY_ORGANIZATION_CREATION_REQUEST,
        variables: { requestId: id },
      },
    ],
    onError: (error) => {
      setError(error.message);
    },
  });

  const [debouncedCallback] = useDebouncedCallback((value) => {
    setSearchTrem(value);
  }, 500);

  if (mutationError)
    return (
      <div>
        <Translate id="message.error.organizationReassignmentFailed" />
      </div>
    );
  if (error) return <div>{error}</div>;
  const onAdd = (
    organization: organizationsGrid_organizations_results,
    organizationId: number,
    position: Position
  ) => {
    setOrganizationToAddVat(organizationId);
    mutateOrganizationReassignment({
      variables: {
        requestId: organizationData?.organizationCreationRequest?.id,
        organizationId: organizationId,
        position: position,
      },
    });
  };

  const onSelect: onSelect = (organization, organizationId, position) => {
    setSearchTrem("");
    onAdd(organization, organizationId, position);
  };

  if (
    !searchTerm &&
    organizationData?.organizationCreationRequest?.organizationName
  ) {
    setSearchTrem(
      organizationData?.organizationCreationRequest?.organizationName
    );
    //setSearchTrem("test")
  }

  return (
    <Box className={classes.container}>
      <h1>
        <Translate id="pages.partnerRequest.orgCreationRequest" />
      </h1>
      {id && (
        <Fragment>
          <ApprovalForm requestId={id} />
          {searchTerm &&
            !organizationData?.organizationCreationRequest?.user?.employee
              ?.organizationId &&
            !mutationLoading &&
            !organizationData?.organizationCreationRequest
              ?.completionTimestamp && (
              <Box>
                <h3
                  style={{
                    margin: "10px 0",
                    paddingTop: "50px",
                    display: "block",
                  }}
                >
                  <Translate id="pages.partnerRequest.searchSimilarPartners" />
                </h3>
                <TextField
                  id="standard-basic"
                  label="Search for Partner"
                  onChange={(e) => debouncedCallback(e.target.value)}
                  autoComplete="off"
                />
                <SearchPartner
                  searchterm={searchTerm}
                  countries={[]}
                  onSelect={onSelect}
                />
              </Box>
            )}
          {mutationLoading && (
            <div style={{ marginTop: "20px" }}>
              <CircularProgress color="secondary" />
            </div>
          )}
        </Fragment>
      )}
    </Box>
  );
};

export default ApprovalPage;
