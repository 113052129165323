import React from 'react';
import { Grid, TextField, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Couponcode } from 'types/ToolboxEntities';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      marginBottom: "15px",
      "& .MuiTextField-root, & .MuiFormControl-root": {
        width: "100%",
        "& .MuiInputBase-input": {
          height: "0.6em",
          fontSize: "0.9rem",
        },
        "& .MuiInputLabel-outlined": {
          transform: "translate(14px, 15px) scale(1)",
          fontSize: "0.9rem",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
      "& .MuiFormControl-root .MuiSelect-selectMenu": {
        paddingTop: "14px",
        paddingBottom: "23px",
      },
      "& .MuiGrid-grid-xs-6, & .MuiGrid-grid-xs-12": {
        paddingLeft: "30px",
        paddingTop: "25px",
        display: "inherit",
      },
      "& .MuiGrid-grid-xs-6 .MuiGrid-item:not(:first-child):not(:last-child)": {
        padding: '0px 10px',
      },
    },
  }));

interface couponcodeData {
  data: Couponcode
  onChangeFunc?: (value: any, name: string) => void
};

const CouponCode: React.FC<couponcodeData> = ({ data, onChangeFunc = (value, name) => { } }) => {
  let classes = useStyles();



  return (
    <Grid container className={classes.container}>
      <Grid item xs={7}>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              value={data.name}
              label={<Translate id="components.promotions.couponCode.couponCode" />}
              variant="outlined"
              name="name"
              onChange={(evt) => { onChangeFunc(evt.target.value, evt.target.name) }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={data.crDate}
              label={<Translate id="components.promotions.details.creationDate" />}
              variant="outlined"
              name="Creation Date"
              onChange={(evt) => { onChangeFunc(evt.target.value, evt.target.name) }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              value={data.assignedProductCode}
              label={<Translate id="components.promotions.couponCode.assignedCode" />}
              variant="outlined"
              name="Assigned Product-Code"
              onChange={(evt) => { onChangeFunc(evt.target.value, evt.target.name) }} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={data.csDate}
              label={<Translate id="components.promotions.couponCode.consumptionDate" />}
              variant="outlined"
              name="Consumption Date"
              onChange={(evt) => { onChangeFunc(evt.target.value, evt.target.name) }} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              value={data.validFrom}
              label={<Translate id="components.promotions.couponCode.validFrom" />}
              variant="outlined"
              name="Valid from"
              onChange={(evt) => { onChangeFunc(evt.target.value, evt.target.name) }} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={data.validTo}
              label={<Translate id="components.promotions.couponCode.validTo" />}
              variant="outlined"
              name="Valid to"
              onChange={(evt) => { onChangeFunc(evt.target.value, evt.target.name) }} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              value={data.active}
              label={<Translate id="types.common.active" />}
              variant="outlined"
              name="Active"
              onChange={(evt) => { onChangeFunc(evt.target.value, evt.target.name) }} />
          </Grid>
          <Grid item xs={6}>

          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
      </Grid>
    </Grid>
  )
};

export default CouponCode;