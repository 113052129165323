import gql from "graphql-tag";



export const QUERY_EMPLOYEE_ORGANIZATION = gql`
query OrganizationOfEmployee( $id: Int! ) {
  employee( id: $id ) {
    position
    organization {
      id
      name
      partnerType
      email
      telephone
      address {
        street
        postalCode
        city
        countryCode
      }
    }
  }
}
`;




export const QUERY_EMPLOYEE_DETAIL = gql`
  query EmployeeDetail($id: Int!) {
    employee(id: $id) {
      isPendingOrganizationAssignment
      position
      organization {
        id
        name
        partnerType
      }
      userInformation {
        id
        key
        firstName
        lastName
        email
        telephone
        preferredLanguage
        registeredOn
        lastFailedPasswordReset
        incorrectLogins
        lastLogin
        lastIncorrectLogin
        accountLocked
        acceptedTerms
        acceptedMarketingUsage
        dataUpdate
        allowedToLogin
        verifiedAccount
        address {
          street
          postalCode
          city
          countryCode
        }
      }
    }
  }
`;


export const FRAGMENT_EMPLOYEE_SINGLE = gql`
  fragment EmployeeData on Employee {
        isPendingOrganizationAssignment
        position
        organization {
          name
          partnerType
        }
        userInformation {
          id
          key
          firstName
          lastName
          email
          registeredOn
          accountLocked
          allowedToLogin
          lastFailedPasswordReset
          lastLogin
          dataUpdate
          acceptedTerms
          acceptedMarketingUsage
          incorrectLogins
          lastIncorrectLogin
          verifiedAccount
          address {
            street
            postalCode
            city
            countryCode
          }
        }
  }
`;


export const QUERY_EMPLOYEE_LIST = gql`
  query employeesGrid(
    $page: Int
    $pageSize: Int
    $filter: [EmployeeFilter]
    $sorting: [SortingCriterionForEmployeeFilterOption]
  ) {
    employees(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sorting: $sorting
    ) {
      page
      pageSize
      totalResults
      results {
            ...EmployeeData
      }
    }
  }
  ${FRAGMENT_EMPLOYEE_SINGLE}
`;

