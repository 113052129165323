import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
      {...props}
    >
      <path data-name="Rectangle 1380" fill="none" d="M0 0h25v25H0z" />
      <path
        data-name="Subtraction 13"
        d="M19.392 13.812a14.423 14.423 0 00-6.891-1.8 14.434 14.434 0 00-6.892 1.8L.375 7.291l.071-.054a19.506 19.506 0 013.782-2.274 19.521 19.521 0 0116.544 0 19.552 19.552 0 013.784 2.273l.069.053-5.233 6.52z"
        fill="rgba(244,198,0,0.7)"
      />
      <path
        data-name="Intersection 1"
        d="M12.489 22.385l-7.286-9.079a14.431 14.431 0 017.3-2.032 14.433 14.433 0 017.3 2.032l-7.293 9.079-.01.01z"
        fill="#f4c600"
      />
    </svg>
  );
}

export default SvgComponent;
