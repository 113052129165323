import { Error } from 'types/CampGuideTypes';
import { PearlsTransaction } from 'types/ToolboxEntities';

export enum ActionType {
  OPEN = 'CHANGE_PEARLS_OPEN',
  CLOSE = 'CHANGE_PEARLS_CLOSE',
  LOCK = 'CHANGE_PEARLS_LOCK',
  ERROR = 'CHANGE_PEARLS_ERROR',
}

export interface Store {
  isLocked: boolean,
  pearlsObj: PearlsTransaction | null,
  error: Error | null
}

export interface OpenAction {
  type: ActionType.OPEN,
  payload: PearlsTransaction
}

export interface CloseAction {
  type: ActionType.CLOSE,
}


export type Action = OpenAction | CloseAction;
