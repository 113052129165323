import gql from "graphql-tag";

export const QUERY_CUSTOMER_PRODUCT_INSTANCES = gql`
  query customerProductInstances($id: String) {
    customers(filter: { filterOption: ID, operator: EQ, values: [$id] }) {
      results {
        registeredProducts {
          address {
            street
            city
          }
          productCode
          batchNumber
          state
          acceptedContractTerms
          acceptedContractDate

          product {

            productInformation {
              name
              recentFirmwareVersion
            }
          }
          instanceInformation {
            __typename
            ... on PerlaInstanceInformation {
              registeredPerlaInstanceInformation {
                firmware
              }
            }
          }
        }
      }
    }
  }
`;