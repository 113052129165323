import { Position } from 'types/ToolboxEntities';

export enum ActionType {
  OPEN = 'CHANGE_POSITION_OPEN',
  CLOSE = 'CHANGE_POSITION_CLOSE',
}

export interface ChangeData {
  userId: number,
  position: Position,
  partnerId: number
}

export interface Store {
  changeData: ChangeData | null,
}

export interface OpenAction {
  type: ActionType.OPEN,
  payload: ChangeData
}

export interface CloseAction {
  type: ActionType.CLOSE,
}


export type Action = OpenAction | CloseAction;
