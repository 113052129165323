import React from "react";
import { Box, Button, createStyles, makeStyles } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { CircularProgress } from "@material-ui/core";

import { deactivationVariables as deactivateCode } from "types/apolloGenerated/deactivation";

import { deletepromotionVariables as deletePromotionCode } from "types/apolloGenerated/deletepromotion";

import { deleteallVariables as deleteAllCode } from "types/apolloGenerated/deleteall";
import { COUPON_CODE_GRID } from "./GridConfig"
import { CouponCodeFilterOption, FilterOperator } from "types/apolloGenerated/globalTypes"
import { PROMOTION_GRID } from "./../GridConfig"

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      margin: "10px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
    },
    buttonInnerContainer: {
      width: "280px", 
      display: "flex", 
      justifyContent: "center"
    },
    spinnerContainer: {
      display: "flex", 
      justifyContent: "center", 
      alignItems: "center"
    }
  })
);

const DEACTIVATION = gql`
  mutation deactivation($id: Int!) {
    promotion {
      deactivateAllCodes(id: $id)
    }
  }
`;

const DELETE_ALL = gql`
  mutation deleteall($id: Int!) {
    promotion {
      deleteAllDeactivatedCodes(couponProductId: $id)
    }
  }
`;

const DELETE_PROMOTION = gql`
  mutation deletepromotion($id: Int!) {
    promotion {
      deletePromotion(id: $id)
    }
  }
`;

const PromotionToolbar: React.FC<{ promotionId: number, changeUpdate: React.Dispatch<React.SetStateAction<boolean>>, update: boolean }> = ({
  promotionId,
  changeUpdate,
  update
}) => {
  const classes = useStyles();
  const [deactivation, { loading: deactivationLoading }] = useMutation<
    deactivateCode
  >(DEACTIVATION, {
    onCompleted: () => changeUpdate(!update),
    onError: (error) => {},
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: COUPON_CODE_GRID, variables: {
        pageSize: 100,
        page: 0,
        filter: [{
          filterOption: [CouponCodeFilterOption.COUPON_PRODUCT_ID], 
          operator: FilterOperator.EQ, 
          values: [String(promotionId)]
        }]
      }
    }]
  });
  const [deleteAll, { loading: deleteAllLoading }] = useMutation<deleteAllCode>(
    DELETE_ALL,
    {
      onCompleted: () => changeUpdate(!update),
      onError: (error) => {},
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: COUPON_CODE_GRID, variables: {
          pageSize: 100,
          page: 0,
          filter: [{
            filterOption: [CouponCodeFilterOption.COUPON_PRODUCT_ID], 
            operator: FilterOperator.EQ, 
            values: [String(promotionId)]
          }]
        }
      }]
    }
  );
  const [deletePromotion, { loading: deletePromotionLoading }] = useMutation<
    deletePromotionCode
  >(DELETE_PROMOTION, {
    onCompleted: () => {
      window.location.href = "/promotion"
    },
    onError: (error) => {},
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: PROMOTION_GRID
    }]
  });

  return (
    <Box className={classes.buttonContainer}>
      <Box className={classes.buttonInnerContainer}>
        {!deactivationLoading ? (
          <Button
            onClick={() => {
              deactivation({
                variables: { id: Number(promotionId) },
              });
            }}
            variant="contained"
            className={classes.button}
            color="primary"
          >
            <Translate id="components.promotions.details.deactivateAll" />
          </Button>
        ) : (
          <Box width="65px" className={classes.spinnerContainer}>
            <CircularProgress color="secondary" size={30}/>
          </Box>
        )}
      </Box>
      <Box className={classes.buttonInnerContainer}>
        {!deleteAllLoading ? (
          <Button
            onClick={() => {
              deleteAll({
                variables: { id: Number(promotionId) },
              });
            }}
            variant="contained"
            className={classes.button}
            color="primary"
          >
            <Translate id="components.promotions.details.deleteAllDeactivated" />
          </Button>
        ) : (
          <Box width="65px" className={classes.spinnerContainer}>
            <CircularProgress color="secondary" size={30}/>
          </Box>
        )}
      </Box>
      <Box className={classes.buttonInnerContainer}>
        {!deletePromotionLoading ? (
          <Button
            onClick={() => {
              deletePromotion({
                variables: { id: Number(promotionId) },
              });
            }}
            variant="contained"
            className={classes.button}
            color="primary"
          >
            <Translate id="components.promotions.details.deletePromo" />
          </Button>
        ) : (
          <Box width="65px" className={classes.spinnerContainer}>
            <CircularProgress color="secondary" size={30}/>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PromotionToolbar;
