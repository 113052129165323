import React from "react";
import { NavLink } from "react-router-dom";

import { Translate } from "react-localize-redux";

import { ListItemIcon, ListItemText, List, ListItem } from "@material-ui/core";

import {
  Create as IconCreate,
  Add as IconJoin,
  Home as IconHome,
  PeopleAlt as IconPeopleAlt,
  LocalDrink as IconLocalDrink,
  Receipt as IconRequest,
  QuestionAnswer as IconQuestionAnswer,
  Shop as IconPromotion,
  Build as IconBuild,
  Settings as IconSettings,
  SentimentVerySatisfied as IconCustomer,
  Email as IconEmail,
  Flare as IconPearls,
  HomeWork as IconPartnerCompany,
  ImportExport as IconConsumable,
  Bookmarks as IconProduction,
  LocalOffer as IconRegistrationCodes,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import bwtColors from "themes/bwtColors";

import Can from "util/ability";
import store from "redux/store";
import { Role } from "types/ToolboxEntities";

const useStyles = makeStyles((theme: any) => ({
  drawerButton: {
    display: "block",
    color: "inherit",
    textDecoration: "inherit",
  },
  drawerButtonActive: {
    background: bwtColors.pink,
    textDecoration: "inherit",
  },
  subNav: {
    paddingTop: 0,
    paddingLeft: "16px",
  },
}));

interface NavItemProps
  extends React.DetailedHTMLProps<
    React.LiHTMLAttributes<HTMLLIElement>,
    HTMLLIElement
  > {
  to: string;
  label: string;
  children?: JSX.Element;
  access?: any;
  icon?: JSX.Element;
}

const NavItem: React.FC<NavItemProps> = ({
  to,
  label,
  children,
  access,
  className,
  icon,
  ...props
}) => {
  const classes = useStyles();
  const linkClass = className
    ? className + " " + classes.drawerButton
    : classes.drawerButton;
  const iconWrapper = icon ? <ListItemIcon>{icon}</ListItemIcon> : null;

  const NavItem = (
    <li {...props}>
      <NavLink
        to={to}
        className={linkClass}
        activeClassName={classes.drawerButtonActive}
        isActive={(match, location) => {
          let locationMatch = "";
          let matchMatch = "";

          if (match) {
            locationMatch = location.pathname;
            matchMatch = match.url;

            const lastSegmentIsNumber = !isNaN(
              parseFloat(
                locationMatch.substring(locationMatch.lastIndexOf("/") + 1)
              )
            );
            const lastSegmentIsProductCode =
              locationMatch.substring(locationMatch.lastIndexOf("/") + 1)
                .length === 9 &&
              location.pathname.substring(
                location.pathname.lastIndexOf("/") + 1
              )[4] === "-";
            const lastSegmentIsNEW =
              locationMatch.split("/").slice(-2)[0] === "NEW";

            if (lastSegmentIsNEW) {
              var arrVars = locationMatch.split("/");
              arrVars.splice(-2, 2);
              var restVar = arrVars.join("/");
              locationMatch = restVar + "/";
            }

            if (lastSegmentIsNumber || lastSegmentIsProductCode) {
              locationMatch = locationMatch.substring(
                0,
                locationMatch.lastIndexOf("/")
              );
            }
            if (
              (locationMatch.match(/\//gi) || []).length ===
              (matchMatch.match(/\//gi) || []).length
            ) {
              return true;
            }
          }

          return false;
        }}
      >
        <ListItem button>
          {iconWrapper}
          <ListItemText>
            <Translate id={label} />
          </ListItemText>
        </ListItem>
      </NavLink>
      {children}
    </li>
  );

  return access ? (
    <Can I="read" a={access}>
      {NavItem}
    </Can>
  ) : (
    NavItem
  );
};

const NavigationList: React.FC = () => {
  const classes = useStyles();
  let salesRep = false;
  store.getState().auth.user?.roles.forEach((item: Role) => {
    if (item.roleName === "SalesRep") {
      salesRep = true;
    }
  });
  return (
    <List>
      <NavItem to="/" label="pages.home.navTitle" icon={<IconHome />} />

      <NavItem
        access="Partner"
        to="/pam"
        label="pages.pam.navTitle"
        icon={<IconPeopleAlt />}
      >
        <List className={classes.subNav}>
          <NavItem
            access="Partner"
            to="/pam/partner"
            className="second"
            label="pages.pam.partner.navTitle"
            icon={<IconPartnerCompany />}
          />
          {!salesRep ? (
            <NavItem
              access="Partner"
              to="/pam/employee"
              className="second"
              label="pages.pam.employee.navTitle"
              icon={<IconPeopleAlt />}
            />
          ) : (
            <div />
          )}
          <NavItem
            access="Partner"
            to="/pam/pearls"
            className="second"
            label="types.common.pearls"
            icon={<IconPearls />}
          />
        </List>
      </NavItem>
      <NavItem
        access="Partner"
        to="/request"
        label="types.common.request"
        icon={<IconRequest />}
      >
        <List className={classes.subNav}>
          <NavItem
            access="Partner"
            to="/request/join"
            className="second"
            label="types.common.join"
            icon={<IconJoin />}
          />
          <NavItem
            access="Partner"
            to="/request/create"
            className="second"
            label="types.common.create"
            icon={<IconCreate />}
          />
        </List>
      </NavItem>
      <NavItem
        access="Product"
        to="/product"
        label="types.common.product"
        icon={<IconLocalDrink />}
      />
      <NavItem
        access="Consumable"
        to="/consumable"
        label="pages.consumable.navTitle"
        icon={<IconConsumable />}
      />

      <NavItem
        access="Customer"
        to="/cs"
        label="pages.cs.navTitle"
        icon={<IconQuestionAnswer />}
      >
        <List className={classes.subNav}>
          <NavItem
            access="Customer"
            to="/cs/device"
            className="second"
            label="pages.cs.device.navTitle"
            icon={<IconLocalDrink />}
          />
          <NavItem
            access="Customer"
            to="/cs/customer"
            className="second"
            label="pages.cs.customer.navTitle"
            icon={<IconCustomer />}
          />
        </List>
      </NavItem>

      <NavItem
        access="Promotion"
        to="/promotion"
        label="types.common.promotions"
        icon={<IconPromotion />}
      />

      <NavItem
        access="RND"
        to="/rnd"
        label="pages.rnd.navTitle"
        icon={<IconBuild />}
      />
      <NavItem
        access="SendGrid"
        to="/sendgrid/templategroupsOverview"
        label="pages.sendgrid.navTitle"
        icon={<IconEmail />}
      />
      <NavItem
        access="Admin"
        to="/admin"
        label="pages.admin.navTitle"
        icon={<IconSettings />}
      />

      <NavItem
        access="Production"
        to="/production"
        label="pages.production.navTitle"
        icon={<IconProduction />}
      >
        <List className={classes.subNav}>
          <NavItem
            access="Production"
            to="/production/registrationCodes"
            label="pages.production.regCodes"
            icon={<IconRegistrationCodes />}
          />
        </List>
      </NavItem>

      {/* <div>
        <ApolloConsumer>
          {client => <Link onClick={() => { console.log('Apollo Cache: ', client.store.getCache()) }}>Debug Apollo Cache</Link>}
        </ApolloConsumer>
      </div> */}
    </List>
  );
};

export default NavigationList;
