import { GridActionType, GridActionsType, SetGridStateAction, SetCulumnStatesAction } from 'redux/types/AgGrid';
import { SingleSortModel, FilterModel } from 'types/AgGrid';
import { ColumnState } from '@ag-grid-community/core/dist/cjs/columnController/columnController';


export default <Type>(actionType: Type): GridActionsType<Type> => {
  const actions = {
    setGridState: (page: number, sortModel: SingleSortModel[], filterModel: FilterModel): SetGridStateAction<Type> => ({
      type: actionType,
      grid: GridActionType.GRID,
      payload: { page, sortModel, filterModel }
    }),

    setColumnStates: (ColumnStates: ColumnState[]): SetCulumnStatesAction<Type> => ({
      type: actionType,
      grid: GridActionType.COLUMN,
      payload: ColumnStates
    }),

  };

  return actions;
};
