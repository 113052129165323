export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

export interface DrawerState {
  drawerShown: boolean;
}

export interface DrawerAction {
  type: typeof OPEN_DRAWER | typeof CLOSE_DRAWER | typeof TOGGLE_DRAWER,
}

export const openDrawer = (): DrawerAction => ({
  type: OPEN_DRAWER,
});

export const closeDrawer = (): DrawerAction => ({
  type: CLOSE_DRAWER,
});

export const toggleDrawer = (): DrawerAction => ({
  type: TOGGLE_DRAWER,
});
