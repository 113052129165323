import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
      {...props}
    >
      <path data-name="Rectangle 1268" fill="none" d="M0 0h25v25H0z" />
      <path
        data-name="Path 2985"
        d="M12.51 22.385L24.625 7.292A20.117 20.117 0 0012.5 3.125 20.082 20.082 0 00.375 7.292L12.49 22.385l.01.01.01-.01z"
        fill="#31b600"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
