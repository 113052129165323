import * as Types from 'types/ToolboxEntities';

import { Error, ErrorCodes } from 'types/CampGuideTypes';
import { TranslateFunction } from 'react-localize-redux';
import { GraphQLError } from 'graphql';

// Simulate slow Responstime/internet - Delay in ms
const DELAY_RESPONSE = 500;


export const mapQlError = (gqlError: GraphQLError | readonly GraphQLError[]): Error => {

  const mapper = (gqlError: GraphQLError): Error | undefined => {
    if (gqlError?.extensions?.code) {
      return {
        code: gqlError.extensions.code,
        message: gqlError.message
      }
    }
  };

  if (Array.isArray(gqlError)) {
    for (let idx = 0; idx < gqlError.length; idx++) {
      let error;
      if ((error = mapper(gqlError[idx]))) {
        return error;
      }
    }

    console.log(gqlError);

    return mapper(gqlError[0]) || {
      code: ErrorCodes.Unknown,
      message: gqlError[0]?.message || ''
    } as Error;
  }


  const singleQlError = gqlError as GraphQLError;
  return mapper(singleQlError) || {
    code: ErrorCodes.Unknown,
    message: singleQlError?.message || ''
  } as Error;

}

export const qraphQlErrorsToString = (gqlError: GraphQLError | readonly GraphQLError[], translate: TranslateFunction): string | undefined => {

  const mapper = (gqlError: GraphQLError): string | undefined => {
    return (translate('message.error.' + gqlError?.extensions?.code, undefined, { onMissingTranslation: () => gqlError.message }) as string);
  };

  if (Array.isArray(gqlError)) {
    for (let idx = 0; idx < gqlError.length; idx++) {
      let error;
      if ((error = mapper(gqlError[idx]))) {
        return error;
      }
    }

    return mapper(gqlError[0]);
  }

  const singleQlError = gqlError as GraphQLError;
  return mapper(singleQlError);
}










interface IGraphQL {
  query: (query: string) => Promise<any>
  queryUser: () => Promise<Types.UserProfile>

  sendPasswordResetEmail: (userId: string) => Promise<void>

  deleteAccount: (userId: string) => Promise<boolean>

  queryOrganization: (id: number) => Promise<Types.Organization>

  queryPromotions: (id: number) => Promise<Types.Promotion>
  savePromotions: (couponcode: Types.Promotion) => Promise<Types.Promotion>

  queryPromotionDetails: (id: number) => Promise<Types.PromotionDetails>
  savePromotionDetails: (couponcode: Types.PromotionDetails) => Promise<Types.PromotionDetails>

  queryCouponcode: (id: number) => Promise<Types.Couponcode>
  saveCouponcode: (couponcode: Types.Couponcode) => Promise<Types.Couponcode>

  savePearlsTransaction: (PearlsTransaction: Types.PearlsTransaction) => Promise<Types.PearlsTransaction>

  changeOrganizationActiveState: (organizationId: number, active: boolean) => Promise<void>



  avaiableEmployeeRoles: {
    save: (chips: string[]) => Promise<string[]>
    load: () => Promise<string[]>
  }
  avaiableCountries: {
    save: (countries: string[]) => Promise<string[]>
    load: () => Promise<string[]>
  }
}


const GraphQL: IGraphQL = {

  query: (query: string): Promise<any> => {
    return new Promise((resolve, reject: (error: Error) => void) => {
      fetch('/graphql', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({ query: query }),
        credentials: 'include',
      }).then((res: Response) => {
        res.json().then((data) => {
          if (data.hasOwnProperty('errors')) {
            console.error('ERROR gQL', data);
            reject(mapQlError(data.errors));
          } else if (DELAY_RESPONSE) {
            setTimeout(() => {
              resolve(data);
            }, DELAY_RESPONSE);
          } else {
            resolve(data);
          }
        }).catch((error) => { console.error('ERROR gQL', error); reject({ code: ErrorCodes.InvalideResponse, message: error }) });
      }).catch((error) => { console.error('ERROR gQL', error); reject({ code: ErrorCodes.ConnectionError, message: error }) });
    });
  },


  queryUser: () => {
    return GraphQL.query(`
      {
        user {
          allowedCountries
          roles {
            permissions
            roleName
          }
          userInformation {
            id
            firstName
            lastName
            email
            acceptedTerms
            accountLocked
            allowedToLogin
            dataUpdate
            incorrectLogins
            lastIncorrectLogin
            key
            lastLogin
            registeredOn
            verifiedAccount
            address {
              street
              postalCode
              city
              countryCode
            }
          }
        }
      }
    `).then((data) => {
      return data.data.user;
    });
  },

  queryOrganization: (id: number): Promise<Types.Organization> => {
    return GraphQL.query(`
      {
        organization(id: ${id}){
          name
          address {
            street
            postalCode
            city
            countryCode
          }
          email
    			employees{
            results{
              position
              userInformation{
                id
                firstName
                lastName
                email
                key
              }
            }
          }
          telephone
          partnerType
          id
          logoUrl
          logoLinkUrl
          partnerImageUrl
          partnerImageLinkUrl
          bonusaccount {
            pearls
          }
          groups{
            id
            name
          }
        }
      }
    `)
      .then((data: any) => data.data.organization)
      .then((fakeData: Types.Organization) => {
        // ToDo: Remove when Mockup-API fully implemented
        fakeData.logoBase64 = "";
        fakeData.partnerImageBase64 = "";
        return fakeData;
      });
  },

  queryPromotions: (id: number): Promise<Types.Promotion> => {
    return GraphQL.query(`
      {
        organization(id: ${id}){
          name
          address{
            street
            postalCode
            city
            countryCode
          }
          email
          telephone
          partnerType
          id
        }
      }
    `).then((data: any) => data.data.organization);
  },

  queryPromotionDetails: (id: number): Promise<Types.PromotionDetails> => {
    return GraphQL.query(`
      {
        organization(id: ${id}){
          name
          address{
            street
            postalCode
            city
            countryCode
          }
          email
          telephone
          partnerType
          id
        }
      }
    `).then((data: any) => data.data.organization)
      .then((org: Types.Organization) => {
        const promo: Types.PromotionDetails = {
          id: org.id,
          articleCategory: org.partnerType,
          name: org.name,
          emailTemplate: org.email,

          crDate: (Math.random() * 10000) + 1575898311,
          type: Math.round(Math.random()) ? Types.PromotionType.Assignable : Types.PromotionType.DedicatedUsage,
        };
        return promo;
      });
  },


  queryCouponcode: (id: number): Promise<Types.Couponcode> => {
    return GraphQL.query(`
      {
        products(filter: {
          filterOption: ITEM_NUMBER
          operator: EQ
          values: ["${id}"]
        }){
          results{
            productInformation{
              name
              itemNumber
              gTIN
            }
          }
        }
      }
    `).then((data) => {
      // ToDo: Remove when Mockup-API fixed

      data.data.products.results[0].productInformation.crDate = "2019-01-07T11:11:22+00:00";
      data.data.products.results[0].productInformation.csDate = "2019-05-22T21:10:22+00:00";
      data.data.products.results[0].productInformation.validFrom = "2019-11-18T19:33:22+00:00";
      data.data.products.results[0].productInformation.validTo = "2020-11-18T19:11:22+00:00";
      data.data.products.results[0].productInformation.assignedProductCode = "50188934";
      data.data.products.results[0].productInformation.active = true;
      return data;
    }).then((data) => {
      return data.data.products.results[0].productInformation;
    });
  },


  changeOrganizationActiveState: (organizationId, active) => {
    return new Promise((resolve, reject: (error: Error) => void) => {
      setTimeout(() => {
        const rnd = Math.random();
        if (rnd < 0.5) {
          resolve();
        } else if (rnd < 0.8) {
          reject({ code: ErrorCodes.Authorization, message: "You do not have permission to change the Login for this User." });
        }
        reject({ code: ErrorCodes.ConnectionError, message: "404" });
      }, DELAY_RESPONSE);
    });
  },


  savePromotions: (promotion: Types.Promotion) => {
    console.log('save promotions', promotion);
    return new Promise((resolve, reject: (error: Error) => void) => {
      setTimeout(() => {
        const rnd = Math.random();

        if (rnd < 0.8) {
          resolve(promotion);
        }

        reject({ code: ErrorCodes.ConnectionError, message: "404" });
      }, DELAY_RESPONSE);
    });
  },

  savePromotionDetails: (promotionDetails: Types.PromotionDetails) => {
    console.log('save promotion details', promotionDetails);
    return new Promise((resolve, reject: (error: Error) => void) => {
      setTimeout(() => {
        const rnd = Math.random();

        if (rnd < 0.8) {
          resolve(promotionDetails);
        }

        reject({ code: ErrorCodes.ConnectionError, message: "404" });
      }, DELAY_RESPONSE);
    });
  },

  saveCouponcode: (couponcode: Types.Couponcode) => {
    console.log('save coupon code', couponcode);
    return new Promise((resolve, reject: (error: Error) => void) => {
      setTimeout(() => {
        const rnd = Math.random();

        if (rnd < 0.8) {
          resolve(couponcode);
        }

        reject({ code: ErrorCodes.ConnectionError, message: "404" });
      }, DELAY_RESPONSE);
    });
  },

  savePearlsTransaction: (PearlsTransaction: Types.PearlsTransaction) => {
    return new Promise((resolve, reject: (error: Error) => void) => {
      setTimeout(() => {
        const rnd = Math.random();
        if (rnd < 0.1) {
          resolve(PearlsTransaction);
        }

        reject({ code: ErrorCodes.ConnectionError, message: "404" });
      }, DELAY_RESPONSE);
    });
  },


  sendPasswordResetEmail: (userId: string) => {
    return GraphQL.query(`
    mutation{
      user{
        sendPasswordResetEmail(userId: "`+ userId + `")
      }
    }
    `).then((data) => {
      return data.data.user;
    });
  },

  deleteAccount: (userId: string): Promise<boolean> => {
    return GraphQL.query(`
    mutation{
      user{
        deleteAccount(userId: "`+ userId + `")
      }
    }
    `).then((data) => {
        return data.data.user.deleteAccount;
    });
  },


  avaiableCountries: {
    load: () => {
      return new Promise((resolve, reject) => {
        return GraphQL.query(
          `
            {
              enabledCountries{
                countries
              }
             }
          `
        ).then((data) => {
          resolve(data.data.enabledCountries.countries);
        });
      });
    },
    save: (tmp) => {
      return new Promise((resolve, reject: (error: Error) => void) => {
        setTimeout(() => {
          const rnd = Math.random();

          if (rnd < 0.7) {
            resolve(tmp);

          } else if (rnd < 0.8) {
            reject({ code: ErrorCodes.InvalideResponse, message: "Invalid response" });

          } else if (rnd < 0.9) {
            reject({ code: ErrorCodes.Authorization, message: "You do not have permission to change the Login for this User." });
          }

          reject({ code: ErrorCodes.ConnectionError, message: "404" });
        }, DELAY_RESPONSE);
      });
    }
  },

  avaiableEmployeeRoles: {
    load: () => {
      return new Promise((resolve, reject) => {
        return GraphQL.query(
          `
            {
              enabledCountries{
                countries
              }
             }
          `
        ).then((data) => {
          resolve(data.data.enabledCountries.countries);
        });
      });
    },
    save: (tmp) => {
      return new Promise((resolve, reject: (error: Error) => void) => {
        setTimeout(() => {
          const rnd = Math.random();

          if (rnd < 0.5) {
            resolve(tmp);

          } else if (rnd < 0.7) {
            reject({ code: ErrorCodes.InvalideResponse, message: "Invalid response" });

          } else if (rnd < 0.9) {
            reject({ code: ErrorCodes.Authorization, message: "You do not have permission to change the Login for this User." });
          }

          reject({ code: ErrorCodes.ConnectionError, message: "404" });
        }, DELAY_RESPONSE);
      });
    }
  }
};

export default GraphQL;