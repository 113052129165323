import { ActionType, Store, Action } from 'redux/types/DeviceActions/ResetInstaller';

import initialState from 'redux/initialState';

export default function resetInstallerReducer(store: Store = initialState.deviceActions.resetInstaller, action: Action): Store {
  switch (action.type) {

    case ActionType.OPEN:
      return {
        openWithProductCode: action.payload,
      };

    case ActionType.CLOSE:
      return {
        openWithProductCode: undefined,
      };

    default:
      return store;
  }
}
