import React from 'react';

import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';

export type valueLabel = {
  label: string
  value: string
}



interface DropDownData {
  disabledMenuItem?: boolean
  isDisabled?: boolean
  selectedVal?: string
  className?: string
  valueList?: string[]
  valueLabelList?: valueLabel[]
  fieldName?: any
  name?: string
  onChangeFunc?: (value: any, name: string) => void
  variant?: 'standard' | 'outlined' | 'filled'
  noneValue?: boolean
};

export const DropDown: React.FunctionComponent<DropDownData> = (
  { selectedVal = '', isDisabled, disabledMenuItem, className, valueList = [], valueLabelList = [], fieldName = '', name = '', variant = 'standard', onChangeFunc = (evt) => { }, noneValue = true }
) => {
  let selectedValExists = false;
  const [state, setState] = React.useState<{ value: string | number; name: string }>({
    value: selectedVal || '',
    name: name,
  });

  if (valueLabelList) {
    valueList.forEach(data => {
      valueLabelList.push({
        value: data,
        label: data,
      });
    });
  }

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  const handleChange = (propName: keyof typeof state) => (evt: React.ChangeEvent<{ value: unknown, name?: string }>) => {
    onChangeFunc(evt.target.value, name);
    setState({
      ...state,
      [propName]: evt.target.value,
    });
  };

  const selectValues: JSX.Element[] = [];

  if (noneValue) {
    selectValues.push(<MenuItem disabled={disabledMenuItem} key="empty" value=""><em>None</em></MenuItem>);
  }

  valueLabelList.forEach(data => {
    if (data.value === selectedVal) {
      selectedValExists = true;
    }

    selectValues.push(
      <MenuItem key={data.value} value={data.value}>{data.label}</MenuItem>
    );
  });

  // if value wouldn't exist, select would be empty, so select 'none' if selected value is not found
  if (!selectedValExists && state.value !== '') {
    setState({
      ...state,
      value: '',
    });
  }

  return (
    <FormControl variant={variant}>
      <InputLabel ref={inputLabel} htmlFor="role-selector-partnerDetails">
        {fieldName}
      </InputLabel>
      <Select
        disabled={isDisabled}
        className="countryDropdown"
        value={state.value}
        onChange={handleChange('value')}
        labelWidth={labelWidth}
        inputProps={{
          id: 'role-selector-partnerDetails',
        }}>
        {selectValues}
      </Select>
    </FormControl>
  )
};

export default DropDown;