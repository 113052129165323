import React, { useState } from "react";
import cx from "classnames";
import { formatNumber } from "./index";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: "100%",
      height: "240px",
      display: "flex",
      flexDirection: "column",
    },
    upperRegion: {
      display: "flex",
    },
    xAxis: {
      height: "20px",
      flexShrink: 0,
      marginRight: "25px",
      display: "flex",
      justifyContent: "space-between",
      borderTop: "1px solid #b5b9bb",
      paddingLeft: "5px",
    },

    labelSeg: {
      width: "20px",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "11px",
    },

    tick: {
      height: "6px",
      width: "1px",
      backgroundColor: "#b5b9bb",
    },

    barRegion: {
      position: "relative",
      marginTop: "0.5em",
      flexGrow: 1,
      height: "220px",
    },

    grid: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      padding: "0 5px",
    },

    gridLine: {
      width: "100%",
      height: "calc(50% - 1px)",
      borderTop: "1px solid #b5b9bb",
    },

    bars: {
      position: "absolute",
      paddingLeft: "5px",
      paddingRight: "5px",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },

    barSeg: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      transition: "height 0.2s",
    },

    bar: {
      height: "100%",
      borderTopLeftRadius: "2px",
      borderTopRightRadius: "2px",
    },

    barBlue: {
      backgroundColor: "#6286a4",
    },

    barHighBlue: {
      backgroundColor: "#81b0d6",
    },

    barHighPink: {
      backgroundColor: "#f582a6",
    },

    barPink: {
      backgroundColor: "#f5b5c9",
    },

    yAxis: {
      width: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "11px",
      padding: "0.25em",
    },
    tooltip: {
      backgroundColor: "white",
      padding: "5px",
      position: "absolute",
      borderRadius: "3px",
      transform: "translateX(0px) translateY(-40px)",
      border: "1px solid #666B6E",
    },
  });
});

const roundToTwoDecimalPlaces = (value: number) =>
  Math.round(value * 100) / 100;

const Bars = ({
  values,
  topLabel,
  colour,
  maxIdx,
}: {
  values: number[];
  topLabel: number;
  colour: string;
  maxIdx: number;
}) => {
  const [focusedBar, setFocusedBar] = useState<number | null>(null);
  const style = useStyles();
  const getBarWidth = (numberOfValues: number) => {
    if (numberOfValues < 3) return "100px";
    if (numberOfValues < 7) return "45px";
    if (numberOfValues < 10) return "35px";
    if (numberOfValues < 15) return "25px";
    return "20px";
  };
  const barWidth = getBarWidth(values.length);
  return (
    <div className={style.bars}>
      {values.map((value, index) => (
        <div
          key={index}
          className={style.barSeg}
          style={{ height: `${(100 * value) / topLabel || 0}%` }}
        >
          <div
            onMouseEnter={() => setFocusedBar(index)}
            onMouseLeave={() => setFocusedBar(null)}
            className={cx(
              style.bar,
              index === maxIdx
                ? colour === "pink"
                  ? style.barHighPink
                  : style.barHighBlue
                : colour === "pink"
                ? style.barPink
                : style.barBlue
            )}
            style={{
              width: barWidth,
              outline: focusedBar === index ? "#005D8F solid 2px" : "none",
              position: "relative",
            }}
          />
          <div
            className={style.tooltip}
            style={{
              display: focusedBar === index ? "block" : "none",
            }}
          >
            {formatNumber(value)}
          </div>
        </div>
      ))}
    </div>
  );
};

const AnimatedBarChart = ({
  labels,
  values,
  page,
  setPage,
  leftButtonEnabled,
  rightButtonEnabled,
  useLitres,
  useSalt,
}: {
  labels: string[];
  values: number[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  leftButtonEnabled: boolean;
  rightButtonEnabled: boolean;
  useLitres: boolean;
  useSalt: boolean;
}) => {
  const style = useStyles();
  const max = values.reduce((acc, value) => (acc > value ? acc : value), 0);
  const maxIdx =
    values.filter((value) => value === max).length < 2
      ? values.findIndex((value) => value === max)
      : -1;
  const midLabel = roundToTwoDecimalPlaces(max / 2);
  const topLabel = roundToTwoDecimalPlaces(midLabel * 2);

  const bars = [];
  const colour = useSalt ? "pink" : "blue";

  if (leftButtonEnabled) {
    const idx = page - 1;
    bars[idx] = (
      <Bars
        key={idx}
        values={values}
        topLabel={topLabel}
        colour={colour}
        maxIdx={maxIdx}
      />
    );
  }

  bars[page] = (
    <Bars
      key={page}
      values={values}
      topLabel={topLabel}
      colour={colour}
      maxIdx={maxIdx}
    />
  );

  if (rightButtonEnabled) {
    const idx = page + 1;
    bars[idx] = (
      <Bars
        key={idx}
        values={values}
        topLabel={topLabel}
        colour={colour}
        maxIdx={maxIdx}
      />
    );
  }

  return (
    <div className={style.root}>
      <div className={style.upperRegion}>
        <div className={style.barRegion}>
          <div className={style.grid}>
            <div className={style.gridLine} />
            <div className={style.gridLine} />
          </div>
          {bars}
        </div>
        <div className={style.yAxis}>
          <div>{topLabel || ""}</div>
          <div>{midLabel || ""}</div>
          <div style={{ lineHeight: 0 }}>
            {useSalt ? (
              <div>kg</div>
            ) : useLitres ? (
              <div>litres</div>
            ) : (
              <div>m³</div>
            )}
          </div>
        </div>
      </div>
      <div className={style.xAxis}>
        {labels.map((label, index) => (
          <div key={index} className={style.labelSeg}>
            <div className={style.tick} />
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnimatedBarChart;
