import React from 'react';
import { makeStyles, Theme, Grid, createStyles, CircularProgress } from '@material-ui/core';

import { Translate } from 'react-localize-redux';

import { useQuery } from 'react-apollo';
import { QUERY_PARTNER_EMPLOYEES } from 'commonQueries/partner';
import { PartnerEmployees, PartnerEmployeesVariables } from 'types/apolloGenerated/PartnerEmployees';

import EmployeesGrid from './EmployeesGrid';
import AddEmployee from './AddEmployee';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: "30px",
      marginBottom: "50px",
      '& h1': {
        marginBottom: '20px',
      },
      '& .innerContent': {
        height: '300px'
      }

    },
    gridDefault: {
      position: "relative",
      height: '100%',
      width: '100%',
      display: 'flex',
      flexFlow: 'column',
      '& > *': {
        flex: '1 1 auto',
      },
      '& > .grie-error': {
        flex: '0 1 auto',
      },
    },

    exportToExcel: {
      position: "absolute",
      left: 0,
      bottom: "5px",
      background: "none",
      border: "none",
      padding: 0,
      color: "#005D8F", //"#e6006b",
      textDecoration: "underline",
      cursor: "pointer",
    },
  })
);

interface EmployeesProps {
  partnerId: number
}

const Employees: React.FC<EmployeesProps> = ({ partnerId }) => {
  let classes = useStyles();

  const { data: employeeData, loading, error } = useQuery<PartnerEmployees, PartnerEmployeesVariables>(QUERY_PARTNER_EMPLOYEES, {
    variables: { id: partnerId }
  });


  let employeeGrid = <Translate id="pages.partnerDetails.noEmployees" />;
  if (loading) {
    employeeGrid = <CircularProgress color="secondary" />;
  } else if (error) {
    console.error('Error loading employees (QUERY_PARTNER_EMPLOYEES)', error);
    employeeGrid = <Translate id="types.common.noSearchResult" />;
  } else if (employeeData?.organization?.employees?.results?.length) {
    employeeGrid = <EmployeesGrid partnerId={partnerId} employees={employeeData.organization.employees.results} />
  }

  return (
    <Grid item xs={8} className={classes.container}>
      <h1><Translate id="pages.partnerDetails.employees" /></h1>
      {employeeGrid}
    </Grid >
  )
};


export default Employees;