import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import gql from "graphql-tag";
import { Translate } from "react-localize-redux";
import { useQuery } from "@apollo/react-hooks";
import { DateAggregationType } from "types/apolloGenerated/globalTypes";
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  startOfDay,
  subMonths,
  startOfMonth,
  format,
  subDays,
  subWeeks,
  subMinutes,
  startOfWeek,
  addDays,
} from "date-fns";

import { CircularProgress, Box } from "@material-ui/core";

import CurveGraph from "./CurveGraph";
import {
  RegenerationDataForGraph as RegenerationQueryData,
  RegenerationDataForGraphVariables as RegenerationQueryVars,
} from "types/apolloGenerated/RegenerationDataForGraph";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    header: {
      fontSize: "20px",
      fontWeight: "bold",
      height: "75px",
    },
    monthPicker: {
      position: "relative",
      left: "500px",
      top: "-22px",
    },
    selectRoot: {
      padding: "10px 40px 10px 10px",
    },
    legend: {
      width: "3%",
      height: "8px",
      borderRadius: "20px",
      margin: "40px 20px 5px 20px",
    },
  });
});

const QUERY = gql`
  query RegenerationDataForGraph(
    $productCode: String!
    $from: DateTimeOffset!
    $to: DateTimeOffset!
    $aggregate: DateAggregationType!
  ) {
    productInstance(productCode: $productCode) {
      id
      instanceInformation {
        __typename
        ... on PerlaInstanceInformation {
          statistics(from: $from, to: $to, aggregate: $aggregate) {
            totalRegenerationCountColumn1
            totalRegenerationCountColumn2
            from
            to
          }
        }
      }
    }
  }
`;

type line = {
  paddedPoints: number[];
  colour: string;
};
export interface RegenerationProps {
  productCode: string;
  location: any;
}

const now = new Date();

const Regeneration: React.FC<RegenerationProps> = ({
  productCode,
  location,
}) => {
  const classes = useStyles();
  const monthsInHalfYear = 6;
  const monthsInYear = 12;
  const daysInMonth = 30;
  const weeksInThreeMonths = 13;

  const [monthsSelected, setMonth] = React.useState(
    monthsInHalfYear.toString()
  );

  const [graphParameters, setGraphParameters] = React.useState({
    first: startOfMonth(subMonths(now, monthsInHalfYear - 1)),
    points: monthsInHalfYear,
    aggregation: DateAggregationType.MONTH,
  });

  const [loadNewDate, setLoadNewDate] = React.useState(false);

  // each of the dates below will fetch one additional point because in order to get the curve affect we need one point that is off the SVG
  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setLoadNewDate(true);
    setMonth((event.target as HTMLTextAreaElement).value);
    switch ((event.target as HTMLTextAreaElement).value) {
      case "1":
        setGraphParameters({
          aggregation: DateAggregationType.DAY,
          points: daysInMonth,
          first: startOfDay(subDays(now, daysInMonth)),
        });
        break;
      case "3":
        setGraphParameters({
          aggregation: DateAggregationType.WEEK,
          points: weeksInThreeMonths,
          first: startOfWeek(subWeeks(now, weeksInThreeMonths - 1), {
            weekStartsOn: 1,
          }),
        });
        break;
      case "6":
        setGraphParameters({
          aggregation: DateAggregationType.MONTH,
          points: monthsInHalfYear,
          first: startOfMonth(subMonths(now, monthsInHalfYear - 1)),
        });
        break;
      case "12":
        //minus one because the 12th month will always be in progress
        setGraphParameters({
          aggregation: DateAggregationType.MONTH,
          points: monthsInYear,
          first: startOfMonth(subMonths(now, monthsInYear - 1)),
        });
        break;
    }
  };

  //create variables used for the query // we subtract timezone offset to normalise the time as toolbox data has no concept of timezone
  let queryFirst = subMinutes(
    graphParameters.first,
    graphParameters.first.getTimezoneOffset()
  );
  let queryTo = subMinutes(startOfDay(now), now.getTimezoneOffset());

  const { data, loading } = useQuery<
    RegenerationQueryData,
    RegenerationQueryVars
  >(QUERY, {
    variables: {
      productCode,
      from: queryFirst,
      to: queryTo,
      aggregate: graphParameters.aggregation,
    },
  });

  let xAxisValues: any[] = [];

  //incase result is missing cant rely on the data. We can get each interval of week but that only grabs one day.

  if (graphParameters.aggregation === DateAggregationType.DAY) {
    //get x axis values
    xAxisValues = eachDayOfInterval({
      start: new Date(graphParameters.first),
      //saying now but that includes an extra day of data which we will not get
      end: new Date(subDays(now, 1)),
    }).map((value) => {
      return { check: format(value, "MM-dd"), value: format(value, "d") };
    });
  }
  if (graphParameters.aggregation === DateAggregationType.WEEK) {
    xAxisValues = eachWeekOfInterval(
      { start: new Date(graphParameters.first), end: new Date(now) },
      { weekStartsOn: 1 }
    ).map((value) => {
      return {
        check: format(value, "yyyy-MM-dd"),
        value: format(value, "dd/MM"),
      };
    });
  }
  if (graphParameters.aggregation === DateAggregationType.MONTH) {
    //for month aggregation
    for (let i = graphParameters.points - 1; i > -1; i--) {
      xAxisValues.push({
        check: format(subMonths(now, i), "yyyy-MM"),
        value: format(subMonths(now, i), "MMM"),
      });
    }
  }

  const info = data?.productInstance?.instanceInformation;
  const regenArrays = xAxisValues.map((date) => {
    if (info?.__typename === "PerlaInstanceInformation") {
      //find value from data based on date needed
      const dateFound = info?.statistics?.find((value) =>
        value?.from.includes(date.check)
      );
      if (
        !dateFound &&
        graphParameters.aggregation === DateAggregationType.WEEK
      ) {
        const startOfWeekAsDate = new Date(date.check + "T00:00:00.000Z");
        for (let i = 0; i < 7; i++) {
          const hasFound = info?.statistics?.find((value) =>
            value?.from?.includes(
              format(addDays(startOfWeekAsDate, i), "yyyy-MM-dd")
            )
          );
          if (hasFound)
            return {
              regen1: hasFound?.totalRegenerationCountColumn1 ?? 0,
              regen2: hasFound?.totalRegenerationCountColumn2 ?? 0,
            };
        }
      }

      return {
        regen1: dateFound?.totalRegenerationCountColumn1 ?? 0,
        regen2: dateFound?.totalRegenerationCountColumn2 ?? 0,
      };
    }
    return { regen1: 0, regen2: 0 };
  });

  const lines: line[] = [
    {
      paddedPoints: [0, ...regenArrays.map((value) => value.regen1), 0],
      colour: "rgb(99, 134, 163)",
    },
    {
      paddedPoints: [0, ...regenArrays.map((value) => value.regen2), 0],
      colour: "rgb(244, 182, 201)",
    },
  ];

  //check to see if total of all lines is 0
  const noData = lines
    .map((line) => line.paddedPoints.reduce((acc, point) => (acc += point), 0))
    .reduce((acc, value) => (acc += value), 0);

  if (loading && !loadNewDate)
    return (
      <div style={{ display: "flex", placeContent: "center" }}>
        <CircularProgress color="secondary" />{" "}
      </div>
    );
  if (noData === 0 && !loadNewDate)
    return (
      <div>
        <Translate id="message.error.NoRegenerationData" />
      </div>
    );

  return (
    <Box>
      <Box className={classes.header}>
        {graphParameters.aggregation === DateAggregationType.WEEK ? (
          <Translate id="components.regeneration.week.title" />
        ) : (
          <Translate id="components.regeneration.title" />
        )}

        <Box style={{ float: "right", fontWeight: "normal" }}>
          <FormControl variant="outlined">
            <Select
              classes={{ root: classes.selectRoot }}
              value={monthsSelected}
              onChange={(event) => handleChange(event)}
            >
              <MenuItem value={"1"}>
                1 <Translate id="components.barChart.month" />
              </MenuItem>
              <MenuItem value={"3"}>
                3 <Translate id="components.barChart.months" />
              </MenuItem>
              <MenuItem value={"6"}>
                6 <Translate id="components.barChart.months" />
              </MenuItem>
              <MenuItem value={"12"}>
                12 <Translate id="components.barChart.months" />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      {data && !loading ? (
        <CurveGraph
          linesIn={lines}
          samplePoints={graphParameters.points}
          xAxisValues={xAxisValues.map((value) => value?.value)}
        />
      ) : (
        <Box
          height="220px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      )}
      <Box style={{ display: "flex" }}>
        <Box
          style={{ backgroundColor: "rgb(99, 134, 163)" }}
          className={classes.legend}
        ></Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <div># Regenerations S1</div>
        </Box>
        <Box
          style={{ backgroundColor: "rgb(244, 182, 201)" }}
          className={classes.legend}
        ></Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <div># Regenerations S2</div>
        </Box>
      </Box>
    </Box>
  );
};

export default Regeneration;
