import React, { useRef, useLayoutEffect, useState, Fragment } from "react";

function calcPath(scaledPaddedPoints: number[], unitX: number): string {
  const grads = [];
  for (let i = 0; i < scaledPaddedPoints.length - 2; i++) {
    grads.push((scaledPaddedPoints[i + 2] - scaledPaddedPoints[i]) / 2);
  }
  //this number affects the curve of the graph
  const handleWidth: number = 0.4;
  const scaledHandleWidth = handleWidth * unitX;
  let path = `M 0 ${scaledPaddedPoints[1]} `;
  for (let i = 0; i < scaledPaddedPoints.length - 3; i++) {
    const xStart = unitX * i;
    const yStart = scaledPaddedPoints[i + 1];
    const x1 = xStart + scaledHandleWidth;
    const y1 = yStart + handleWidth * grads[i];
    const xEnd = unitX * (i + 1);
    const yEnd = scaledPaddedPoints[i + 2];
    const x2 = xEnd - scaledHandleWidth;
    const y2 = yEnd - handleWidth * grads[i + 1];
    path += `C ${x1} ${y1}, ${x2} ${y2}, ${xEnd} ${yEnd} `;
  }
  return path;
}

export interface CurveGraphContentProps {
  lines: { paddedPoints: number[]; colour: string }[];
  size: { width: number; height: number };
  yAxisTopPoint: number;
  yAxisValues: number[];
}

function range(count: number): number[] {
  return Array.from(Array(count).keys());
}

const CurveGraphContent: React.FC<CurveGraphContentProps> = ({
  lines,
  size,
  yAxisTopPoint,
  yAxisValues,
}) => {
  const topBottomThreshold = 10;
  const xCount = lines[0] ? lines[0].paddedPoints.length - 3 : 1;
  const unitX = size.width / xCount;
  const unitY = (size.height - topBottomThreshold * 2) / yAxisTopPoint;
  const scaledLines = lines.map((line) => ({
    ...line,
    paddedPoints: line.paddedPoints.map(
      (y) => (yAxisTopPoint - y) * unitY + topBottomThreshold
    ),
  }));

  return (
    <svg height={size.height} width={size.width}>
      <defs>
        {scaledLines.map(({ colour }, idx) => (
          <linearGradient
            key={idx}
            id={`grad${idx}`}
            x1="0%"
            y1="0%"
            x2="0%"
            y2={size.height - topBottomThreshold}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" style={{ stopColor: colour, stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: colour, stopOpacity: 0 }} />
          </linearGradient>
        ))}
      </defs>
      {range(xCount + 1).map((idx) => (
        <line
          key={`V${idx}`}
          x1={idx * unitX}
          x2={idx * unitX}
          y1={topBottomThreshold}
          y2={size.height - topBottomThreshold}
          stroke="rgb(237, 242, 245)"
        />
      ))}
      {[...yAxisValues, 0].map((y, idx) => (
        <line
          key={`H${idx}`}
          x1="0"
          x2={size.width}
          y1={y * unitY + topBottomThreshold}
          y2={y * unitY + topBottomThreshold}
          stroke="rgb(237, 242, 245)"
        />
      ))}
      {scaledLines.map(({ paddedPoints, colour }, idx) => {
        const linePath = calcPath(paddedPoints, unitX);
        const fillPath = linePath + `V ${size.height} H 0 Z`;
        return (
          <Fragment key={idx}>
            <path
              d={linePath}
              stroke={colour}
              strokeWidth="2"
              fill="transparent"
            />
            <path d={fillPath} fill={`url(#grad${idx})`} />
          </Fragment>
        );
      })}
      {scaledLines.map(({ paddedPoints, colour }, idx1) => (
        <Fragment key={idx1}>
          {paddedPoints.slice(1, -1).map((y, idx2) => (
            <circle
              key={idx2}
              cx={idx2 * unitX}
              cy={y}
              r="4"
              fill="white"
              stroke={colour}
              strokeWidth="2"
            />
          ))}
        </Fragment>
      ))}
    </svg>
  );
};

interface YAxisProps {
  yAxisValues: number[];
}

const YAxis: React.FC<YAxisProps> = ({ yAxisValues }) => {
  return (
    <div>
      {yAxisValues.map((value, idx) => (
        <div
          key={idx}
          style={{
            height: "24%",
            position: "relative",
            right: "8px",
            width: "30px",
            bottom: "calc(1% * " + idx + ")",
          }}
        >
          {value}
        </div>
      ))}
      <div
        style={{
          position: "relative",
          bottom: "5%",
          right: "8px",
          width: "30px",
        }}
      >
        0
      </div>
    </div>
  );
};

interface XAxisProps {
  samplePoints: number;
  xAxisValues: string[];
}

const XAxis: React.FC<XAxisProps> = ({ samplePoints, xAxisValues }) => (
  <div style={{ marginLeft: "22px", display: "flex" }}>
    <div
      style={{
        position: "relative",
        bottom: "10px",
        right: "2px",
        textAlign: "right",
        width: "10px",
      }}
    >
      {xAxisValues[0]}
    </div>
    {range(samplePoints - 1).map((idx) => (
      <div
        key={idx}
        style={{
          width: "calc(100%/" + (samplePoints - 2).toString() + ")",
          position: "relative",
          bottom: "10px",
          textAlign: "center",
          left: "calc(38%/" + (samplePoints - 2).toString() + ")",
        }}
      >
        {xAxisValues[idx + 1]}
      </div>
    ))}
  </div>
);

function getYAxisPoints(highestPoint: number) {
  const yAxisValues: number[] = [];
  let graphTop = Math.ceil(highestPoint / 10) * 10;
  yAxisValues.push(graphTop);
  yAxisValues.push((graphTop / 4) * 3);
  yAxisValues.push((graphTop / 4) * 2);
  yAxisValues.push(graphTop / 4);
  return yAxisValues;
}

function findHighestPoint(
  linesIn: { paddedPoints: number[]; colour: string }[]
) {
  let highest = 0;
  linesIn.forEach((pointArray) => {
    const highestInArray = Math.max(...pointArray.paddedPoints);
    if (highestInArray > highest) {
      highest = highestInArray;
    }
  });
  return highest;
}

export interface CurveGraphProps {
  linesIn: { paddedPoints: number[]; colour: string }[];
  samplePoints: number;
  xAxisValues: string[];
}

const CurveGraph: React.FC<CurveGraphProps> = ({
  linesIn,
  samplePoints,
  xAxisValues,
}) => {
  const highestPoint =
    findHighestPoint(linesIn) === 0 ? 100 : findHighestPoint(linesIn);
  const yAxisValues = getYAxisPoints(highestPoint);
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    function updateSize() {
      if (ref && ref.current)
        setSize({
          width: ref.current.offsetWidth - 30,
          height: ref.current.offsetHeight,
        });
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div ref={ref} style={{ width: "100%", height: 220 }}>
      <div style={{ display: "flex" }}>
        <YAxis yAxisValues={yAxisValues} />
        {ref.current && (
          <CurveGraphContent
            size={size}
            lines={linesIn}
            yAxisTopPoint={Math.ceil(highestPoint / 10) * 10}
            yAxisValues={yAxisValues}
          />
        )}
      </div>
      <XAxis samplePoints={samplePoints} xAxisValues={xAxisValues} />
    </div>
  );
};

export default CurveGraph;
