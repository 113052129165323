import { FilterModel, SingleSortModel } from 'types/AgGrid';
import { ColumnState } from '@ag-grid-community/core/dist/cjs/columnController/columnController';


export enum GridActionType {
  GRID = 'AG_GRID_STATE',
  COLUMN = 'AG_GRID_CULUMN',
}

export interface SetGridStateAction<Type> {
  type: Type
  grid: GridActionType.GRID
  payload: {
    sortModel: SingleSortModel[]
    filterModel: FilterModel
    page: number
  }
}

export interface SetCulumnStatesAction<Type> {
  type: Type
  grid: GridActionType.COLUMN
  payload: ColumnState[]
}

export type Action<Type> = SetGridStateAction<Type> | SetCulumnStatesAction<Type>

export interface State {
  sortModel: SingleSortModel[]
  filterModel: FilterModel
  page: number
  columnStates: ColumnState[] | undefined
}


export type SetGridStateFunctionType<Type> = (page: number, sortModel: SingleSortModel[], filterModel: FilterModel) => SetGridStateAction<Type>;
export type SetColumnStateFunctionType<Type> = (ColumnStates: ColumnState[]) => SetCulumnStatesAction<Type>;

export type GridActionsType<Type> = {
  setGridState: SetGridStateFunctionType<Type>,
  setColumnStates: SetColumnStateFunctionType<Type>
};
