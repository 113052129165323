import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { countryCodes_countryCodes_results } from "types/apolloGenerated/countryCodes"
import { products_products_results } from "types/apolloGenerated/products"
import { State as PromotionOverviewState } from "redux/types/PromotionOverview";
import {
    promotionDetail_promotion as PromotionInfo
  } from "types/apolloGenerated/promotionDetail";

  import {
    setGridState,
    setColumnStates,
  } from "redux/actions/PromotionDetailsActions";


  import {
    TranslateFunction,
  } from "react-localize-redux";

export interface FieldError {
    field: string
    message: string
}

export interface PromotionDetailViewProps {
    queryData: PromotionInfo,
    promoOverviewState: PromotionOverviewState,
    id: string | undefined,
    products: products_products_results[],
    countryCodes: countryCodes_countryCodes_results[],
    actions: {
      setGridState: typeof setGridState
      setColumnStates: typeof setColumnStates
    },
    translate: TranslateFunction
  }

export const overviewStyles = makeStyles((theme: Theme) => {
    return createStyles({
        content: {
        padding: theme.spacing(3),
        height: "calc(100% - 64px)",
        display: "flex",
        flexFlow: "column",
        },

        innerContent: {
        flex: "1 1 auto",
        },

        innerHeader: {
        flex: "0 1 auto",
        display: "flex",
        margin: "0 -20px 20px -20px",
        alignItems: "flex-end",
        justifyContent: "space-between",

        "& > *": {
            flex: "0 1 auto",
            margin: "0 20px",
            minWidth: "170px",
        },

        "& > .filter": {
            flex: "1 1 auto",
            maxWidth: "250px",
        },
        },

        innerHeaderRight: {
        flex: "0 1 auto",
        display: "flex",
        alignItems: "flex-end",
        margin: 0,

        "& > *": {
            flex: "0 1 auto",
            margin: "0 20px",
            minWidth: "170px",
        },
        },
    });
});