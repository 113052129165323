import { State as AgGridState } from "redux/types/AgGrid";

export enum ActionType {
  "GRID" = "CUSTOMER_OVERVIEW_GRID",
  "SEARCH" = "CUSTOMER_OVERVIEW_SEARCH",
}

export interface SearchAction {
  type: ActionType.SEARCH;
  payload: string | null;
}

export interface State {
  grid: AgGridState;
  search: string | null;
}
