import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
      {...props}
    >
      <path data-name="Rectangle 1268" fill="none" d="M0 0h25v25H0z" />
      <path
        data-name="Path 2985"
        d="M12.562 22.477L24.726 7.322a20.2 20.2 0 00-12.175-4.184A20.164 20.164 0 00.377 7.322l12.16 15.155.01.01.01-.01z"
        fill="rgba(237,43,67,0.7)"
        fillRule="evenodd"
      />
      <path
        data-name="Path 2986"
        d="M7.457 16.141l5.087 6.338.006.006.006-.006 2.613-3.256 2.474-3.082a8.4 8.4 0 00-5.093-1.774 8.4 8.4 0 00-5.093 1.774z"
        fill="#ed2b43"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
