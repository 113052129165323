import React from "react";
import { productInstanceAndCustomerDetails_productInstance as ProductInstance } from "../../../types/apolloGenerated/productInstanceAndCustomerDetails";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  GridSize,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableFooter,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";

import { Translate } from "react-localize-redux";
import CodeIcon from "@material-ui/icons/Code";

import { useQuery } from "@apollo/react-hooks";
import {
  objectAudit,
  objectAudit_objectAudit,
  objectAuditVariables,
} from "../../../types/apolloGenerated/objectAudit";
import { AuditLogObject } from "../../../types/apolloGenerated/globalTypes";
import { Link } from "react-router-dom";
import GET_AUDIT_LOGS from "../../../commonQueries/getAuditLogs";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@material-ui/icons";
import { PartnerDetail_organization } from "../../../types/apolloGenerated/PartnerDetail";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    arrow: { fontSize: 16, margin: 7 },
    textField: {
      [`& input::-webkit-clear-button,
      & input::-webkit-outer-spin-button,
      & input::-webkit-inner-spin-button,
      & input::-webkit-toolbarbutton-dropdown`]: {
        display: "none",
      },
      width: 150,
    },
    customTooltip: {
      width: "120px",
    },
    root: {
      flexShrink: 0,
      paddingLeft: "30px",
    },
    container: {
      width: "100%",
      paddingLeft: "30px",
      marginBottom: "50px",
      "& h1": {
        marginTop: "20px",
      },
      "& .innerContent": {
        height: "300px",
        position: "relative",
      },
    },
  });
});

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

interface PaginationActionProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function PaginationActions(props: PaginationActionProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

function Row({
  audit,
  canViewLogDetail,
}: {
  audit: objectAudit_objectAudit;
  canViewLogDetail: boolean;
}) {
  const [openRawDetails, setOpenRawDetails] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>{audit?.client}</TableCell>
        <TableCell>{audit?.operation}</TableCell>
        <TableCell>{audit?.timestamp}</TableCell>
        <TableCell>
          <Link
            style={{ color: "#005D8F" }}
            to={"/cs/customer/" + audit?.userId}
          >
            View
          </Link>
        </TableCell>
        <TableCell>{audit?.email}</TableCell>
        <TableCell component="th" scope="row">
          {canViewLogDetail ? (
            <Button
              variant="text"
              color="primary"
              href="#contained-buttons"
              size="small"
              onClick={() => setOpenRawDetails(!openRawDetails)}
            >
              View RAW <CodeIcon />
            </Button>
          ) : (
            <div />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={openRawDetails} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="inherit"
                gutterBottom
                component="div"
              >
                <Translate id="components.auditLog.rawOutput" />
              </Typography>
              <TableHead>
                <TableRow>
                  {canViewLogDetail ? (
                    <TableCell component="th" scope="row" align="left">
                      {audit?.details}
                    </TableCell>
                  ) : (
                    <div />
                  )}
                </TableRow>
              </TableHead>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const AuditLog: React.FC<{
  productCode: string | null;
  productInstance: ProductInstance | null;
  partnerDetail: PartnerDetail_organization | null;
  canViewLogDetail: boolean;
  productId: string | null;
  customerId: string | null;
  gridSize: GridSize;
}> = ({
  productCode = null,
  productInstance = null,
  canViewLogDetail,
  partnerDetail = null,
  productId,
  customerId,
  gridSize,
}) => {
  const { instanceInformation } = productInstance || {};
  let devId: string = "";
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  if (instanceInformation != null) {
    if (
      instanceInformation.__typename === "SilkInstanceInformation" ||
      instanceInformation.__typename === "PerlaInstanceInformation"
    ) {
      devId = instanceInformation?.deviceId ?? "";
    }
  }

  let deviceData: objectAudit | undefined;
  let organizationData: objectAudit | undefined;
  let customerData: objectAudit | undefined;
  let customerDataLoading: boolean;
  let deviceDataLoading: boolean;
  let organizationLoading: boolean;
  let shouldSkipDeviceQuery = false;
  let shouldSkipOrganizationQuery = false;
  let shouldSkipProductInstanceQuery = false;
  let shouldSkipCustomerQuery = false;
  let shouldSkipProductQuery = false;

  if (productCode === null) {
    productCode = "";
    shouldSkipProductInstanceQuery = true;
  }
  if (devId === "") {
    shouldSkipDeviceQuery = true;
  }
  if (partnerDetail === null) {
    shouldSkipOrganizationQuery = true;
  }
  if (customerId === null) {
    customerId = "";
    shouldSkipCustomerQuery = true;
  }
  if (productId === null) {
    productId = "";
    shouldSkipProductQuery = true;
  }

  const {
    data: productInstanceData,
    loading: productInstanceLoading,
  } = useQuery<objectAudit, objectAuditVariables>(GET_AUDIT_LOGS, {
    variables: {
      objectType: AuditLogObject.PRODUCT_INSTANCE_CODE,
      objectId: productCode,
      pageNumber: 0,
      pageSize: 100000,
    },
    skip: shouldSkipProductInstanceQuery,
  });

  const organizationResult = useQuery<objectAudit, objectAuditVariables>(
    GET_AUDIT_LOGS,
    {
      variables: {
        objectType: AuditLogObject.ORGANIZATION,
        objectId: String(partnerDetail?.id),
        pageNumber: 0,
        pageSize: 100000,
      },
      skip: shouldSkipOrganizationQuery,
    }
  );

  const deviceResult = useQuery<objectAudit, objectAuditVariables>(
    GET_AUDIT_LOGS,
    {
      variables: {
        objectType: AuditLogObject.DEVICE,
        objectId: devId,
        pageNumber: 0,
        pageSize: 100000,
      },
      skip: shouldSkipDeviceQuery,
    }
  );

  const customerResult = useQuery<objectAudit, objectAuditVariables>(
    GET_AUDIT_LOGS,
    {
      variables: {
        objectType: AuditLogObject.USER_ACCOUNT,
        objectId: customerId,
        pageNumber: 0,
        pageSize: 100000,
      },
      skip: shouldSkipCustomerQuery,
    }
  );

  const { data: productData, loading: productLoading } = useQuery<
    objectAudit,
    objectAuditVariables
  >(GET_AUDIT_LOGS, {
    variables: {
      objectType: AuditLogObject.PRODUCT,
      objectId: productId,
      pageNumber: 0,
      pageSize: 100000,
    },
    skip: shouldSkipProductQuery,
  });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  deviceData = deviceResult.data;
  deviceDataLoading = deviceResult.loading;
  organizationData = organizationResult.data;
  organizationLoading = organizationResult.loading;
  customerData = customerResult.data;
  customerDataLoading = customerResult.loading;

  if (
    productInstanceLoading ||
    deviceDataLoading ||
    organizationLoading ||
    customerDataLoading ||
    productLoading
  )
    return <CircularProgress color="secondary" />;

  if (
    !productInstanceData?.objectAudit &&
    !deviceData?.objectAudit &&
    !organizationData?.objectAudit &&
    !customerData?.objectAudit &&
    !productData?.objectAudit
  )
    return <div />;

  const logs: (objectAudit_objectAudit | null)[] = [];

  productInstanceData?.objectAudit?.forEach((instance) => logs.push(instance));
  deviceData?.objectAudit?.forEach((instance) => logs.push(instance));
  organizationData?.objectAudit?.forEach((instance) => logs.push(instance));
  customerData?.objectAudit?.forEach((instance) => logs.push(instance));
  productData?.objectAudit?.forEach((instance) => logs.push(instance));
  logs.sort((logA, logB) =>
    logA?.timestamp > logB?.timestamp
      ? -1
      : logB?.timestamp > logA?.timestamp
      ? 1
      : 0
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <Grid item xs={gridSize} className={classes.container}>
      <Box>
        <Box>
          <h1>
            <Translate id="components.auditLog.title" />
          </h1>
        </Box>
        <Box paddingBottom={2} fontWeight="fontWeightBold" />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "5%" }}>
                  <b>
                    <Translate id="components.auditLog.client" />
                  </b>
                </TableCell>
                <TableCell style={{ width: "5%" }}>
                  <b>
                    <Translate id="components.auditLog.operation" />
                  </b>
                </TableCell>
                <TableCell style={{ width: "5%" }}>
                  <b>
                    <Translate id="components.auditLog.loggedAt" />
                  </b>
                </TableCell>
                <TableCell style={{ width: "5%" }}>
                  <b>
                    <Translate id="components.auditLog.userProfile" />
                  </b>
                </TableCell>
                <TableCell style={{ width: "10%" }}>
                  <b>
                    <Translate id="components.auditLog.userEmail" />
                  </b>
                </TableCell>
                <TableCell style={{ width: "5%" }}>
                  <b></b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.length !== 0 ? (
                logs
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((auditDetails: objectAudit_objectAudit | null, idx) =>
                    auditDetails !== null ? (
                      <Row
                        key={idx}
                        audit={auditDetails}
                        canViewLogDetail={canViewLogDetail}
                      />
                    ) : (
                      <TableRow />
                    )
                  )
              ) : (
                <TableRow>
                  <TableCell>
                    <Translate id="components.auditLog.noLogs" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={12}
                  count={logs.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={PaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
};

export default AuditLog;
