import { State as AgGridState } from 'redux/types/AgGrid';

export enum ActionType {
  'GRID' = 'PRODUCT_OVERVIEW_GRID',
  'SEARCH' = 'PRODUCT_OVERVIEW_SEARCH',
}

export interface SearchAction {
  type: ActionType.SEARCH
  payload: string | null
}

export interface State {
  grid: AgGridState
  search: string | null
}
