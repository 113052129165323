import { Error } from 'types/CampGuideTypes';
import * as Types from 'types/ToolboxEntities';

export enum ActionType {
  LOADING = 'COUPONCODE_LOADING',
  SAVING = 'COUPONCODE_SAVING',
  LOADED = 'COUPONCODE_LOADED',
  ERROR = 'COUPONCODE_ERROR',
  DIRTY = 'COUPONCODE_DIRTY',
}

export interface FieldErrors {
  [key: string]: Error
}

export interface Store {
  isLocked: boolean,
  isDirty: boolean,
  code: Types.Couponcode | null
  globalErrors: Error[]
  propertyErrors: FieldErrors | null
}

export interface ErrorAction {
  type: ActionType.ERROR,
  payload: {
    globalErrors: Error[]
    propertyErrors: FieldErrors | null
  }
}

export interface SetDataAction {
  type: ActionType.LOADED,
  payload: Types.Couponcode
}

export interface LoadingAction {
  type: ActionType.LOADING,
}

export interface SavingAction {
  type: ActionType.SAVING,
}

export interface SetDirtyAction {
  type: ActionType.DIRTY,
}

export type Action = ErrorAction | SetDataAction | LoadingAction | SetDirtyAction | SavingAction;