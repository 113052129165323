import { ActionType, Action } from 'redux/types/AccountShortcuts/ChangeActive';

import {UserInformation} from 'types/ToolboxEntities';


export const open = (user: UserInformation): Action => ({
  type: ActionType.OPEN,
  payload: user
});

export const close = (): Action => ({
  type: ActionType.CLOSE,
});

