import React, { useRef } from "react";
import { Translate, withLocalize, LocalizeContextProps } from "react-localize-redux";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import startOfDay from "date-fns/startOfDay";
import { List } from "immutable";
import RootRef from "@material-ui/core/RootRef";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import DayGroup from "./DayGroup";

import {
  productInstanceAndCustomerDetails_productInstance_instanceInformation_SilkInstanceInformation_notifications as SilkNotifArray,
  productInstanceAndCustomerDetails_productInstance_instanceInformation_SilkInstanceInformation as SilkInstanceInformation
} from "types/apolloGenerated/productInstanceAndCustomerDetails"

interface NotificationsHistoryProps extends LocalizeContextProps {
  productCode: string;
  silkData: SilkInstanceInformation | null
}

const SilkNotifs: React.FC<NotificationsHistoryProps> = ({
  productCode,
  silkData
}) => {

  const ref = useRef<HTMLDivElement>(null);

  const evaluate = (messageType: number) => messageType > 1999
  let silkNotifications: (SilkNotifArray | null)[] = []
  if (silkData && silkData?.notifications) {
    silkNotifications = silkData.notifications.filter(notif => evaluate(notif?.messageType ?? 0))
  }

  const byDayPairs = List(silkNotifications)
    .groupBy(notif => startOfDay(new Date(notif?.timestamp)).getTime())
    .entrySeq();
  if (silkData?.notifications?.length === 0) return <div>< Translate id="message.error.NoSilkNotifications" /></div>;

  return (
    <RootRef rootRef={ref}>
      <Box>
        <Box marginBottom={2}>
          <Typography component="h3" variant="h6">
            <Translate id="components.notificationshistory.title" />
          </Typography>
        </Box>
        {byDayPairs
          .zipAll(byDayPairs.skip(1))
          .map(([[day, notifs], nextPair]) => (
            <DayGroup
              key={day}
              dayMs={day}
              notifications={notifs}
              temporalHeight={
                nextPair
                  ? Math.min(3 * differenceInCalendarDays(day, nextPair[0]), 500)
                  : 0
              }
              isLast={!nextPair}
            />
          ))}
      </Box>
    </RootRef>
  );
};
export default withLocalize(SilkNotifs);
