import React from "react";
import { TranslateFunction } from "react-localize-redux";
import { Link } from "react-router-dom";

import gql from "graphql-tag";
import {
  productInstances,
  productInstances_productInstances_results as ProductInstanceResult,
} from "types/apolloGenerated/productInstances";
import { productInstancesWithoutTotalResult } from "types/apolloGenerated/productInstancesWithoutTotalResult";

import { ColDef } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { UserProfile } from "types/ToolboxEntities";
import { FilterOperator } from "types/apolloGenerated/globalTypes";

import { ApolloFilter, factoryApolloConnector } from "util/AgGridUtils";
import CampUtils from "util/CampUtils";

import { Translate } from "react-localize-redux";
import format from "date-fns/format";

import {
  ProductInstanceFilterOption,
  ProductInstanceState,
  ProductType,
} from "types/apolloGenerated/globalTypes";

import { FirmwareIndicator } from "Pages/Customer/CustomerDetails/GridConfig";
import { campColours } from "themes/bwtColors";
import ErrorStatesFilter from "./ErrorStatesFilter";

const prodInstanceDataFragment = gql`
  fragment ProductInstanceDataForGridOverview on ProductInstance {
    productCode
    batchNumber
    deviceId
    erpBatchNumber
    state
    serialNumber
    registrationDate
    installationDate
    product {
      productInformation {
        id
        name
        itemNumber
        productType
        recentFirmwareVersion
      }
    }
    instanceInformation {
      __typename
      ... on PerlaInstanceInformation {
        registeredPerlaInstanceInformation {
          firmware
          nextServiceDate
          stateTitle
          holidayModeActive
        }
      }
      ... on SilkInstanceInformation {
        state
      }
    }
    address {
      street
      postalCode
      city
      countryCode
    }
    owner {
      userInformation {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

const QUERY = gql`
  query productInstances(
    $page: Int
    $pageSize: Int
    $filter: [ProductInstanceFilter]
    $sorting: [SortingCriterionForProductInstanceFilterOption]
  ) {
    productInstances(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sorting: $sorting
    ) {
      page
      pageSize
      totalResults
      results {
        ...ProductInstanceDataForGridOverview
      }
    }
  }
  ${prodInstanceDataFragment}
`;

const QUERY_WITHOUT_TR = gql`
  query productInstancesWithoutTotalResult(
    $page: Int
    $pageSize: Int
    $filter: [ProductInstanceFilter]
    $sorting: [SortingCriterionForProductInstanceFilterOption]
  ) {
    productInstances(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sorting: $sorting
    ) {
      page
      pageSize
      results {
        ...ProductInstanceDataForGridOverview
      }
    }
  }
  ${prodInstanceDataFragment}
`;

const colMapping = {
  productCode: ProductInstanceFilterOption.PRODUCT_CODE,
  hiddenstate: ProductInstanceFilterOption.STATE,
  deviceVersionId: ProductInstanceFilterOption.DEVICE_VERSION_ID,
  deviceState: ProductInstanceFilterOption.DEVICE_STATE,
  deviceCode: ProductInstanceFilterOption.DEVICE_STATE_CODE,
  "owner.userInformation.firstName":
    ProductInstanceFilterOption.CUSTOMER_FIRST_NAME,
  "owner.userInformation.lastName":
    ProductInstanceFilterOption.CUSTOMER_LAST_NAME,
    "owner.userInformation.email":
    ProductInstanceFilterOption.CUSTOMER_EMAIL,
  "address.street": ProductInstanceFilterOption.STREET,
  "address.postalCode": ProductInstanceFilterOption.POSTAL_CODE,
  "address.city": ProductInstanceFilterOption.CITY,
  "address.countryCode": ProductInstanceFilterOption.COUNTRY,
  "product.productInformation.name": ProductInstanceFilterOption.PRODUCT_NAME,
  "product.productInformation.productType": ProductInstanceFilterOption.TYPE,
  "product.productInformation.itemNumber": ProductInstanceFilterOption.ITEM_NUMBER,
  serialNumber: ProductInstanceFilterOption.SERIAL_NUMBER,
  registrationDate: ProductInstanceFilterOption.REGISTRATION_DATE,
  installationDate: ProductInstanceFilterOption.INSTALLATION_DATE,
  "instanceInformation.firmware": ProductInstanceFilterOption.FIRMWARE_VERSION,
  deviceId: ProductInstanceFilterOption.DEVICE_ID,
};

const searchColumns = [
  ProductInstanceFilterOption.PRODUCT_CODE,
  ProductInstanceFilterOption.CUSTOMER_FIRST_NAME,
  ProductInstanceFilterOption.CUSTOMER_LAST_NAME,
  ProductInstanceFilterOption.CUSTOMER_EMAIL,
  ProductInstanceFilterOption.STREET,
  ProductInstanceFilterOption.POSTAL_CODE,
  ProductInstanceFilterOption.CITY,
  ProductInstanceFilterOption.PRODUCT_NAME,
  ProductInstanceFilterOption.ITEM_NUMBER,
  ProductInstanceFilterOption.SERIAL_NUMBER,
  ProductInstanceFilterOption.DEVICE_ID,
  ProductInstanceFilterOption.FIRMWARE_VERSION,
];

export const apolloConnectorBySearch = (searchTerm: string | null) => {
  return factoryApolloConnector(
    QUERY,
    (data: productInstances | productInstancesWithoutTotalResult) =>
      data.productInstances,
    colMapping,
    {
      queryWithoutTotalResult: QUERY_WITHOUT_TR,
      search: {
        searchCols: searchColumns as string[],
        searchTerm,
      },
      defaultSort: [
        {
          colId: "hiddenstate",
          sort: "desc",
        },
      ],
      filterMapping: {
        state: (apolloFilter: ApolloFilter[], filterModel: any) => {
          if (filterModel?.value) {
            const filter = filterModel.value;

            if (
              filter?.selectedDeviceVersion &&
              filter?.selectedDeviceVersion !== -1
            ) {
              apolloFilter.push({
                values: [filter.selectedDeviceVersion],
                filterOption: [ProductInstanceFilterOption.DEVICE_VERSION_ID],
                operator: FilterOperator.EQ,
              });
            }
            if (filter?.selectedCodeType && filter?.selectedCodeType !== -1) {
              apolloFilter.push({
                values: [filter.selectedCodeType],
                filterOption: [ProductInstanceFilterOption.DEVICE_STATE],
                operator: FilterOperator.EQ,
              });
            }
            if (filter?.selectedMessage && filter?.selectedMessage !== "") {
              apolloFilter.push({
                values: [filter.selectedMessage],
                filterOption: [ProductInstanceFilterOption.DEVICE_STATE_CODE],
                operator: FilterOperator.EQ,
              });
            }
          }
        },
      },
    }
  );
};

export const getColumnDefs = (
  translate: TranslateFunction,
  user: UserProfile | null
): ColDef[] => {
  const stateStyle = (params: any) => {
    if (
      params?.data?.instanceInformation?.__typename ===
      "PerlaInstanceInformation"
    ) {
      if (params?.data?.state === ProductInstanceState.ERROR) {
        return { backgroundColor: campColours.backgroundError };
      }
      if (params?.data?.state === ProductInstanceState.WARNING) {
        return { backgroundColor: campColours.backgroundWarn };
      }
      if (params?.data?.state === ProductInstanceState.SERVICE) {
        return { backgroundColor: campColours.backgroundWarn };
      }
      if (params?.data?.state === ProductInstanceState.NOT_CONNECTED) {
        return { backgroundColor: campColours.backgroundDiscon };
      }
      if (params?.data?.state === ProductInstanceState.NOT_SYNCHED) {
        return { backgroundColor: campColours.backgroundDiscon };
      }
    } else if (
      params?.data?.instanceInformation?.__typename ===
      "SilkInstanceInformation"
    ) {
      let silk = params?.data?.instanceInformation ?? null;
      if (silk?.state === ProductInstanceState.ERROR) {
        return { backgroundColor: campColours.backgroundError };
      }
      if (silk?.state === ProductInstanceState.WARNING) {
        return { backgroundColor: campColours.backgroundWarn };
      }
      if (silk?.state === ProductInstanceState.SERVICE) {
        return { backgroundColor: campColours.backgroundWarn };
      }
      if (silk?.state === ProductInstanceState.NOT_SET) {
        return { backgroundColor: campColours.backgroundDiscon };
      }
      if (silk?.state === ProductInstanceState.NOT_CONNECTED) {
        return { backgroundColor: campColours.backgroundDiscon };
      }
      if (silk?.state === ProductInstanceState.NOT_SYNCHED) {
        return { backgroundColor: campColours.backgroundDiscon };
      }
    }
  };

  const columnDefs = [
    {
      headerName: translate("types.productInstance.productCode") as string,
      field: "productCode",
      cellRenderer: "ProductInstanceLink",
      cellStyle: stateStyle,
      width: 110,
    },
    {
      headerName: translate("types.common.product") as string,
      field: "product.productInformation.name",
      cellStyle: stateStyle,
      width: 150,
    },
    {
      headerName: translate("types.product.itemNumber") as string,
      field: "product.productInformation.itemNumber",
      cellStyle: stateStyle,
      width: 150,
    },
    {
      headerName: translate("types.userInformation.firstName") as string,
      field: "owner.userInformation.firstName",
      cellRenderer: "CustomerLink",
      cellStyle: stateStyle,
      width: 100,
    },
    {
      headerName: translate("types.userInformation.lastName") as string,
      field: "owner.userInformation.lastName",
      cellRenderer: "CustomerLink",
      cellStyle: stateStyle,
      width: 100,
    },
    {
      headerName: translate("types.userInformation.email") as string,
      field: "owner.userInformation.email",
      cellStyle: stateStyle,
      width: 100,
    },
    {
      headerName: translate("types.address.street") as string,
      field: "address.street",
      cellStyle: stateStyle,
      width: 140,
    },
    {
      headerName: translate("types.address.postalCode") as string,
      field: "address.postalCode",
      cellStyle: stateStyle,
      width: 100,
    },
    {
      headerName: translate("types.address.city") as string,
      field: "address.city",
      cellStyle: stateStyle,
      width: 120,
    },
    {
      headerName: translate("types.address.countryCode") as string,
      field: "address.countryCode",
      filter: user ? "agSetColumnFilter" : false,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        values: user ? user.allowedCountries : [],
      },
      cellStyle: stateStyle,
      width: 90,
    },
    {
      headerName: translate("types.productInstance.nextService") as string,
      field: "instanceInformation.nextServiceDate",
      valueGetter: (params: any) => {
          return ServiceDateValueGetter({ data: params.data, value: params.value })
              ?.props.children;
      },
      cellRenderer: "FormatDate",
      cellStyle: stateStyle,
      width: 100,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: translate("generics.error") as string,
      field: "state",
      valueGetter: (params: any) => {
        return StateValueGetter({ data: params.data, value: params.value })
          ?.props.children;
      },
      filter: "errorStatesFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        values: CampUtils.getEnumAsArray(ProductInstanceState),
      },
      cellStyle: stateStyle,
      width: 200,
    },
    {
      headerName: translate("generics.error") as string,
      field: "hiddenstate",
      hide: true,
      valueGetter: (params: any) => {
        return StateValueGetter({ data: params.data, value: params.value })
          ?.props.children;
      },
      filter: "agSetColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        values: CampUtils.getEnumAsArray(ProductInstanceState),
      },
      cellStyle: stateStyle,
      width: 200,
    },
    {
      headerName: translate("types.productInstance.firmware") as string,
      field: "instanceInformation.firmware",
      valueGetter: (params: any) => {
          return FirmwareValueGetter({ data: params.data, value: params.value })
              ?.props.children;
      },
      cellStyle: stateStyle,
      width: 100,
      suppressMenu: true,
    },
    {
      headerName: translate(
        "types.productInstance.holidayModeActive"
      ) as string,
      field: "instanceInformation.holidayModeActive",
      valueGetter: (params: any) => {
          return HolidayModeValueGetter({ data: params.data, value: params.value })
              ?.props.children;
      },
      width: 150,
      sortable: false,
      suppressMenu: true,
      cellStyle: stateStyle,
    },
    {
      headerName: "IoT",
      field: "product.productInformation.productType",
      filter: "agSetColumnFilter",
      width: 100,
      filterParams: {
        filterOptions: ["EQ"],
        suppressAndOrCondition: true,
        values: CampUtils.getEnumAsArray(ProductType),
      },
      suppressMenu: true,
      hide: true,
      lockVisible: true,
    },
    {
      headerName: translate("types.common.serialNumber") as string,
      field: "serialNumber",
      width: 150,
      cellStyle: stateStyle,
    },
    {
      headerName: translate("types.productInstance.installationDate") as string,
      field: "installationDate",
      width: 200,
      cellRenderer: "FormatDate",
      cellStyle: stateStyle,
    },
    {
      headerName: translate("types.productInstance.registrationDate") as string,
      field: "registrationDate",
      width: 180,
      cellRenderer: "FormatDate",
      cellStyle: stateStyle,
    },
    {
      headerName: translate("types.common.batchNumber") as string,
      field: "batchNumber",
      cellStyle: stateStyle,
      width: 140,
    },
    {
      headerName: translate(
        "pages.production.registrationCodeGrid.erpBatchNumber"
      ) as string,
      field: "erpBatchNumber",
      cellStyle: stateStyle,
      width: 180,
      hide: true,
    },
    {
      headerName: translate("types.common.deviceId") as string,
      field: "deviceId",
      cellStyle: stateStyle,
      width: 150,
      hide: true,
    },
  ];

  return columnDefs;
};

interface CellRenderer {
  value: string;
  data: ProductInstanceResult;
}

interface ValueGetter {
  value: string;
  data: ProductInstanceResult;
}

const ProductInstanceLink: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  return <Link to={"/cs/device/" + data.productCode}>{value}</Link>;
};

const CustomerLink: React.FC<CellRenderer> = ({ data, value }) => {
  if (!(data && data.owner)) {
    return <div />;
  }
  return (
    <Link to={"/cs/customer/" + data?.owner?.userInformation?.id}>{value}</Link>
  );
};

const StateCell: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data || data.state === "NOT_SET") {
    return <div />;
  }
  let stateTitle = "";
  if (data.instanceInformation?.__typename === "PerlaInstanceInformation") {
    stateTitle =
      data.instanceInformation.registeredPerlaInstanceInformation?.stateTitle ??
      "";
  } else if (
    data.instanceInformation?.__typename === "SilkInstanceInformation"
  ) {
    stateTitle = data.instanceInformation.state ?? "";
  }
  return <div>{stateTitle}</div>;
};

const StateValueGetter = ({ data, value }: ValueGetter) => {
  if (!data || data.state === "NOT_SET") {
    return <div />;
  }
  let stateTitle = "";
  if (data.instanceInformation?.__typename === "PerlaInstanceInformation") {
    stateTitle =
      data.instanceInformation.registeredPerlaInstanceInformation?.stateTitle ??
      "";
  } else if (
    data.instanceInformation?.__typename === "SilkInstanceInformation"
  ) {
    stateTitle = data.instanceInformation.state ?? "";
  }
  return <div>{stateTitle}</div>;
};

const FirmwareValueGetter = ({ data, value }: ValueGetter) => {
    if (!data) {
        return <div />;
    }
    let firmwareValue = "";
    if (data.instanceInformation?.__typename === "PerlaInstanceInformation") {
        firmwareValue =
            data.instanceInformation.registeredPerlaInstanceInformation?.firmware?.toString() ??
            "";
    }
    return <div>{firmwareValue}</div>;
};

const HolidayModeValueGetter = ({ data, value }: ValueGetter) => {
    if (!data) {
        return <div />;
    }
    let firmwareValue = "";
    if (data.instanceInformation?.__typename === "PerlaInstanceInformation") {
        firmwareValue =
            data.instanceInformation.registeredPerlaInstanceInformation?.holidayModeActive?.toString() ??
            "";
    }
    return <div>{firmwareValue}</div>;
};

const ServiceDateValueGetter = ({ data, value }: ValueGetter) => {
    if (!data) {
        return <div />;
    }
    let firmwareValue = "";
    if (data.instanceInformation?.__typename === "PerlaInstanceInformation") {
        firmwareValue =
            data.instanceInformation.registeredPerlaInstanceInformation?.nextServiceDate?.toString() ??
            "";
    }
    return <div>{firmwareValue}</div>;
};

const FormatDate: React.FC<CellRenderer> = ({ data, value }) => {
  if (!(data && value)) {
    return <div />;
  }
  return (
    <Translate>
      {(translate) => {
        return (
          <div>
            {format(
              new Date(value),
              translate.translate("generics.dateTimeFormatFNSShort") as string
            )}
          </div>
        );
      }}
    </Translate>
  );
};

export const ValueGetter = {
  StateValueGetter,
  HolidayModeValueGetter,
  ServiceDateValueGetter,
  FirmwareValueGetter,
};

export const CellRenderer = {
  ProductInstanceLink,
  CustomerLink,
  StateCell,
  FormatDate,
  FirmwareIndicator,
  errorStatesFilter: ErrorStatesFilter,
};
