import { ActionType, Store, Action } from 'redux/types/CouponCode';

import initialState from 'redux/initialState';

export default function couponCodeReducer(store: Store = initialState.couponCode, action: Action): Store {
  switch (action.type) {

    case ActionType.DIRTY:
      return {
        isLocked: store.isLocked,
        isDirty: true,
        code: store.code,
        globalErrors: store.globalErrors,
        propertyErrors: store.propertyErrors,
      };

    case ActionType.ERROR:
      return {
        isLocked: store.isLocked,
        isDirty: store.isDirty,
        code: store.code,
        globalErrors: action.payload.globalErrors,
        propertyErrors: action.payload.propertyErrors,
      };

    case ActionType.LOADED:
      return {
        isLocked: false,
        isDirty: false,
        code: action.payload,
        globalErrors: [],
        propertyErrors: null,
      };

    case ActionType.LOADING:
      return {
        isLocked: true,
        isDirty: false,
        code: null,
        globalErrors: [],
        propertyErrors: null,
      };

    case ActionType.SAVING:
      return {
        isLocked: true,
        isDirty: store.isDirty,
        code: store.code,
        globalErrors: store.globalErrors,
        propertyErrors: store.propertyErrors,
      };

    default:
      return store;
  }
}
