import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { CellRenderer } from "./GridConfig";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { format } from "date-fns";
import {
  Translate,
  withLocalize,
  LocalizeContextProps
} from "react-localize-redux";
import { State as PromotionOverviewState } from "redux/types/PromotionOverview";
import { CircularProgress } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import { AppState } from "redux/store";

import {
  setGridState,
  setColumnStates,
} from "redux/actions/PromotionDetailsActions";
import PromotionInformation from "Pages/Promotion/Details/PromotionInformation";
import PromotionToolbar from "Pages/Promotion/Details/PromotionToolbar";
import AgGrid from "components/common/Grid/AgGrid";
import { getColumnDefs, apolloConnectorByPromotion } from "./GridConfig";
import PageHeader from "components/common/PageHeader";
import { useParams, useHistory } from "react-router-dom";
import { PromotionInsertInput, PromotionType, PromotionUpdate } from "types/apolloGenerated/globalTypes"
import { createPromotion as CreatePromotion, createPromotionVariables } from "types/apolloGenerated/createPromotion"
import { updatePromotion as UpdatePromotion, updatePromotionVariables } from "types/apolloGenerated/updatePromotion"
import { UserProfile } from "types/ToolboxEntities";
import {
  promotionDetail as Promotion,
  promotionDetail_promotion as PromotionInfo
} from "types/apolloGenerated/promotionDetail";
import {
  MenuItemDef,
  GetContextMenuItemsParams,
} from "@ag-grid-enterprise/all-modules";
import { PROMOTION_GRID } from "./../GridConfig"
import { deactivateCodeVariables, deactivateCode } from "types/apolloGenerated/deactivateCode"
import { activateCodeVariables, activateCode } from "types/apolloGenerated/activateCode"
import { COUPON_CODE_GRID } from "./GridConfig"
import { CouponCodeFilterOption, FilterOperator } from "types/apolloGenerated/globalTypes"
import PageDialog from "components/common/PageDialog"
import {  ACTIVATE_SINGLE_CODE, COUNTRY_CODE_QUERY, 
  CREATE_PROMOTION, DEACTIVATE_SINGLE_CODE, 
  PRODUCT_QUERY, QUERY, UPDATE_PROMOTION } from "./Queries";
  import { FieldError, overviewStyles, PromotionDetailViewProps } from "./PromotionInformationModels"


const PromotionDetailView: React.FC<PromotionDetailViewProps> = ({
  queryData,
  promoOverviewState,
  id,
  actions,
  products,
  countryCodes,
  translate
}) => {
  const classes = overviewStyles();


  const [apolloConnector, setApolloConnector] = useState<
    ReturnType<typeof apolloConnectorByPromotion>
  >(apolloConnectorByPromotion(id));

  useEffect(() => {
    setApolloConnector(apolloConnectorByPromotion(id));
  }, [id]);

  const [open, setOpen] = React.useState(false)
  const [code, setCode] = React.useState<{ id: number | null, isActive: boolean | null }>({ id: null, isActive: null });
  const [update, setUpdate] = React.useState(true)
  const [fieldError, setFieldError] = React.useState<FieldError[]>([])
  const [promotionInfo, setPromotionInfo] = React.useState<PromotionInfo>(queryData)
  const [createUpdateError, setCreateUpdateError] = React.useState("")
  const history = useHistory()
  const [createPromotion, { loading: createLoading }] = useMutation<CreatePromotion, createPromotionVariables>(CREATE_PROMOTION, {
    onCompleted: data => {
      const newPromotionId = data?.promotion?.createPromotion?.id
      history.replace(`/promotion/${newPromotionId}`);
      setCreateUpdateError("")
    },
    refetchQueries: [{
      query: PROMOTION_GRID,
    }],
    onError: error => {
      setCreateUpdateError(error.message)
    }
  })
  const [updatePromotion, { loading: updateLoading }] = useMutation<UpdatePromotion, updatePromotionVariables>(UPDATE_PROMOTION, {
    refetchQueries: [{
      query: QUERY, variables: ({ id: Number(id) })
    }],
    onError: error => {
      setCreateUpdateError(error.message)
    },
    onCompleted: data => {
      setCreateUpdateError("")
    },
  })

  const [deactivateCouponCode, { loading: deactivateLoading }] = useMutation<deactivateCode, deactivateCodeVariables>(DEACTIVATE_SINGLE_CODE, {
    onCompleted: () => {
      setUpdate(!update)
      setOpen(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: COUPON_CODE_GRID, variables: {
        pageSize: 100,
        page: 0,
        filter: [{
          filterOption: [CouponCodeFilterOption.COUPON_PRODUCT_ID],
          operator: FilterOperator.EQ,
          values: [id]
        }]
      }
    },
    {
      query: PROMOTION_GRID
    }]
  })

  const [activateCouponCode, { loading: activateLoading }] = useMutation<activateCode, activateCodeVariables>(ACTIVATE_SINGLE_CODE, {
    onCompleted: () => {
      setUpdate(!update)
      setOpen(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: COUPON_CODE_GRID, variables: {
        pageSize: 100,
        page: 0,
        filter: [{
          filterOption: [CouponCodeFilterOption.COUPON_PRODUCT_ID],
          operator: FilterOperator.EQ,
          values: [id]
        }]
      }
    },
    {
      query: PROMOTION_GRID
    }]
  })

  const isLoading = createLoading || updateLoading ? true : false

  const convertToIds = (value: any) => {
    const productIdArray: number[] = []
    value.forEach((product: any) => {
      if (product !== null) {
        const arr = products.map((value: any) => value?.productInformation?.name)
        if (arr.indexOf(product.name) !== -1) {
          const id = products[arr.indexOf(product?.name)]?.productInformation?.id
          if (id) {
            productIdArray.push(id)
          }
        }
      }
    });
    return productIdArray
  }

  const savePromotion = () => {
    const errors = getInputValidationErrors(promotionInfo);

    if (errors.length) {
      setFieldError(errors);
      return;
    }

    id === "NEW" ? createPromotion({
      variables: {
        promotion: createPromotionInsertInput(promotionInfo)
      }
    }) : updatePromotion({
      variables: {
        promotion: createPromotionUpdateInput(promotionInfo)
      }
    })
    setFieldError([])
  }

  const getInputValidationErrors = (promotionInfo: PromotionInfo): FieldError[] => {
    const errors: FieldError[] = []

    if (promotionInfo?.articleNumber === "") {
      errors.push({ field: "article", message: translate("components.promotions.details.promotionIdEmpty") as string })
    }

    if (promotionInfo?.name === "") {
      errors.push({ field: "name", message: translate("components.promotions.details.nameEmpty") as string })
    }

    return errors;
  }

  const createPromotionInsertInput = (promotionInfo: PromotionInfo): PromotionInsertInput => {
    return {
      articleNumber: promotionInfo?.articleNumber,
      name: promotionInfo?.name,
      creationUser: promotionInfo?.creationUser,
      type: promotionInfo?.type,
      productIds: convertToIds(promotionInfo?.assignedProducts),
      usageCountLimit: promotionInfo?.usageCountLimit,
      countryCodeIds: promotionInfo.countryCodes?.map(countryCode => countryCode!.id) ?? []
    };
  }

  const createPromotionUpdateInput = (promotionInfo: PromotionInfo): PromotionUpdate => {
    return {
      id: promotionInfo?.id,
      articleNumber: promotionInfo?.articleNumber,
      name: promotionInfo?.name,
      creationUser: promotionInfo?.creationUser,
      type: promotionInfo?.type,
      productIds: convertToIds(promotionInfo?.assignedProducts),
      usageCountLimit: promotionInfo?.usageCountLimit,
      countryCodeIds: promotionInfo.countryCodes?.map(countryCode => countryCode!.id) ?? []
    };
  }

  const getContextMenuItems = () => {
    const result: (string | MenuItemDef)[] = [
      {
        name: translate(
          "components.promotions.details.deactivateCode"
        ) as string,
        action: () => {
          setOpen(true)
        },
        cssClasses: ["redFont", "bold"],
      },
      "copy",
      "copyWithHeaders",
      "paste",
      "export",
    ];
    return (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      if (params && params.node) {
        setCode({
          id: Number(params.node.data.id),
          isActive: params.node.data.isActive
        })
      }
      return result;
    };
  };


  const singleCodeAction = () => {
    if (code.id) {
      if (code.isActive) {
        deactivateCouponCode({
          variables: {
            couponCodeId: code.id
          }
        })
      } else {
        activateCouponCode({
          variables: {
            couponCodeId: code.id
          }
        })
      }
    }
  }

  return (
    <Translate>
      {({ translate }) => (
        <Fragment>
          <PageHeader isSavePending={isLoading} title={id === "NEW" ? translate("components.promotions.details.addNew") as string : queryData?.name ?? ""} showBack onSave={savePromotion} />
          <PageDialog
            open={open}
            title={"components.promotions.details.areYouSureDeactivate"}
            buttons={[{ title: "generics.true", click: () => singleCodeAction() }, { title: "generics.cancel", click: () => setOpen(false) }]}
            loading={deactivateLoading || activateLoading}
          />
          {createUpdateError !== "" ? <Alert severity="error">{createUpdateError}</Alert> : <div />}
          <div className={classes.content}>
            <PromotionInformation
              promotionDetails={promotionInfo}
              change={setPromotionInfo}
              products={products}
              availableCountryCodes={countryCodes}
              errorHandler={fieldError}
            />
            {id !== "NEW" ?
              <Fragment>
                <PromotionToolbar promotionId={Number(id)} changeUpdate={setUpdate} update={update} />
                <div
                  className={classes.innerContent}
                  style={{
                    position: "relative",
                    maxHeight: "600px",
                    padding: "30px",
                  }}
                >
                  <AgGrid
                    key={update + "" + code.id}
                    gridState={promoOverviewState.grid}
                    gridActions={{
                      setGridState: actions.setGridState,
                      setColumnStates: actions.setColumnStates,
                    }}
                    apolloConnector={apolloConnector}
                    frameworkComponents={CellRenderer}
                    columnDefs={getColumnDefs(translate)}
                    getContextMenuItems={getContextMenuItems()}
                  />
                </div>
              </Fragment> : <Fragment />}
          </div>
        </Fragment>
      )}
    </Translate>
  );
};

interface PromotionDetailProps extends LocalizeContextProps {
  promotionOverviewState: PromotionOverviewState
  user: UserProfile | null
  actions: {
    setGridState: typeof setGridState
    setColumnStates: typeof setColumnStates
  }
}

const PromotionDetail: React.FC<PromotionDetailProps> = ({ promotionOverviewState, actions, user, translate }) => {
  const { id: promotionId } = useParams();

  const { loading, data: promotionData } = useQuery<Promotion>(QUERY, {
    variables: { id: Number(promotionId) },
  });

  const { data: productsData, loading: productsLoading } = useQuery(PRODUCT_QUERY);

  const { data: countryCodesData, loading: countryCodesLoading } = useQuery(COUNTRY_CODE_QUERY);

  const promotion = {
    __typename: promotionData?.promotion?.__typename ?? "Promotion",
    id: Number(promotionId),
    creationDate: promotionId === "NEW" ? format(new Date(), "d/M/y") : promotionData?.promotion?.creationDate ?? "",
    articleNumber: promotionData?.promotion?.articleNumber ?? "",
    creationUser: promotionId === "NEW" ? user?.userInformation.firstName + " " + user?.userInformation.lastName : promotionData?.promotion?.creationUser ?? "",
    isDeleted: promotionData?.promotion?.isDeleted ?? false,
    name: promotionData?.promotion?.name ?? "",
    type: promotionData?.promotion?.type ?? PromotionType.ASSIGNABLE,
    assignedProducts: promotionData?.promotion?.assignedProducts ?? [],
    countryCodes: promotionData?.promotion?.countryCodes ?? [],
    usageCountLimit: promotionData?.promotion?.usageCountLimit ?? 1
  }

  if (loading || productsLoading || countryCodesLoading) return <CircularProgress color="secondary" />;

  if (!promotionData?.promotion && promotionId !== "NEW") return <div>sorry no data for this promotion</div>;

  return <PromotionDetailView queryData={promotion} promoOverviewState={promotionOverviewState} id={promotionId} actions={actions} products={productsData?.products?.results} countryCodes={countryCodesData?.countryCodes?.results} translate={translate} />;
};

const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  promotionOverviewState: appState.promotionOverview,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ setGridState, setColumnStates }, dispatch),
});

export default withLocalize(connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionDetail));
