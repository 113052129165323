import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  Translate,
  LocalizeContextProps,
  withLocalize,
} from "react-localize-redux";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

import { TextField, Box } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
// import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import debounce from "debounce";

import { AppState } from "redux/store";
import { State as employeesOverviewState } from "redux/types/EmployeesOverview";
import {
  setGridState,
  setColumnStates,
  search,
} from "redux/actions/EmployeesOverviewActions";

import { UserProfile } from "types/ToolboxEntities";

import { getFilterValues } from "util/AgGridUtils";

import AgGrid from "components/common/Grid/AgGrid";
import CountryFilter from "components/common/CountryFilter";
import PageHeader from "components/common/PageHeader";
import GridFilterHeader from "components/common/Grid/GridFilterHeader";

import {
  getColumnDefs,
  apolloConnectorBySearch,
  CellRenderer,
} from "./GridConfig";
// import AuditLog from "../../Customer/InstanceDetails/AuditLog";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    employeesOverview: {
      display: "flex",
      flexFlow: "column",

      "& > .gridFilterHeader": {
        flex: "0 1 auto",
      },

      "& > .innerContent": {
        flex: "1 1 100%",
        height: "100%",
      },
    },

    innerHeader: {
      flex: "0 1 auto",
      display: "flex",
      margin: "0 -20px 20px -20px",
      alignItems: "flex-end",
      justifyContent: "space-between",

      "& > *": {
        flex: "0 1 auto",
        margin: "0 20px",
        minWidth: "170px",
      },

      "& > .filter": {
        flex: "1 1 auto",
        maxWidth: "250px",
      },
    },

    innerHeaderRight: {
      flex: "0 1 auto",
      display: "flex",
      alignItems: "flex-end",
      margin: 0,

      "& > *": {
        flex: "0 1 auto",
        margin: "0 20px",
        minWidth: "170px",
      },
    },
  });
});

const debouncedSearch = debounce(
  (
    searchTerm: string,
    oldSearchTerm: string | null,
    searchAction: typeof search
  ) => {
    if (searchTerm.length >= 3) {
      searchAction(searchTerm);
    } else if (
      searchTerm.length === 0 &&
      (oldSearchTerm === null || oldSearchTerm.length > 0)
    ) {
      searchAction(null);
    }
  },
  500
);

interface EmployeesListPara extends LocalizeContextProps {
  user: UserProfile | null;
  employeesOverviewState: employeesOverviewState;
  actions: {
    setGridState: typeof setGridState;
    setColumnStates: typeof setColumnStates;
    search: typeof search;
  };
}

const EmployeesList: React.FC<EmployeesListPara> = ({
  user,
  employeesOverviewState,
  actions,
  translate,
}) => {
  const classes = useStyles();

  const [search, setSearch] = useState<string>(
    employeesOverviewState.search || ""
  );

  const [apolloConnector, setApolloConnector] = useState<
    ReturnType<typeof apolloConnectorBySearch>
  >(apolloConnectorBySearch(employeesOverviewState.search));

  useEffect(() => {
    setApolloConnector(apolloConnectorBySearch(employeesOverviewState.search));
  }, [employeesOverviewState.search]);

  if (!user) {
    return null;
  }

  const gridState = employeesOverviewState.grid;

  const changeFilter = (
    filterName: string,
    newFilterValues: string[] | false
  ) => {
    const newFilter = Object.assign({}, gridState.filterModel);
    if (newFilterValues !== false) {
      newFilter[filterName] = {
        filterType: "set",
        values: newFilterValues,
      };
    } else {
      delete newFilter[filterName];
    }
    actions.setGridState(gridState.page, gridState.sortModel, newFilter);
  };

  // const changeTypeFilter = (
  //   event: React.MouseEvent<HTMLElement, MouseEvent>,
  //   value: any
  // ) => {
  //   let newFilterValues = value as string[];
  //   changeFilter(
  //     "employeesType",
  //     newFilterValues.length > 0 &&
  //       newFilterValues.length < 4 &&
  //       newFilterValues
  //   );
  // };

  // const selectedTypes = gridState.filterModel.employeesType
  //   ? getFilterValues(gridState.filterModel.employeesType)
  //   : ["TWP", "BUILDER", "OPERATOR", "PLANER"];

  const changeDeletedAccountFilter = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string[]
  ) => {
    let newFilterValues = value as string[];
    if (newFilterValues.length !== 1) {
      newFilterValues = [];
    }
    changeFilter(
      "userInformation.accountLocked",
      newFilterValues.length === 1 && newFilterValues
    );
  };

  const deletedFilterType =
    gridState.filterModel["userInformation.accountLocked"];
  const deletedFilter = deletedFilterType && getFilterValues(deletedFilterType);

  const changeCountryFilter = (newFilterValues: string[]) => {
    changeFilter(
      "userInformation.address.countryCode",
      newFilterValues.length > 0 &&
        newFilterValues.length < user.allowedCountries.length &&
        newFilterValues
    );
  };

  const selectedCountries = gridState.filterModel[
    "userInformation.address.countryCode"
  ]
    ? getFilterValues(
        gridState.filterModel["userInformation.address.countryCode"]
      )
    : user.allowedCountries;

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debouncedSearch(
      event.target.value,
      employeesOverviewState.search,
      actions.search
    );
  };

  const cellCallbackOnExport = (cell: any) => {
    const rowData = cell.node.data;
    if (cell.column.colId === "isPendingOrganizationAssignment") {
      if (rowData?.isPendingOrganizationAssignment) {
        return translate("types.employees.pending") as string;
      }
      if (rowData?.userInformation?.accountLocked) {
        return translate("types.employees.locked") as string;
      }
      if (rowData?.userInformation?.verifiedAccount) {
        return translate("types.common.verified") as string;
      }
      if (rowData?.userInformation?.allowedToLogin) {
        return translate("types.common.inactive") as string;
      }
    }
    return cell.value;
  };

  return (
    <Translate>
      {({ translate }) => (
        <Fragment>
          <PageHeader title="Registered Partner Employees" />
          <main
            className={
              "mainContentContainer fullHeight " + classes.employeesOverview
            }
          >
            <GridFilterHeader className="gridFilterHeader">
              <Box>
                <TextField
                  className="filter"
                  id="standard-basic"
                  label="Type to Filter"
                  value={search}
                  onChange={changeSearch}
                />
              </Box>

              <div className="innerHeaderRight">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ToggleButtonGroup
                    value={deletedFilter}
                    onChange={changeDeletedAccountFilter}
                  >
                    <ToggleButton value="false">
                      <Translate id="pages.pam.employee.active" />
                    </ToggleButton>
                    <ToggleButton value="true">
                      <Translate id="pages.pam.employee.deactivated" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <Box>
                  <CountryFilter
                    selected={selectedCountries}
                    onChange={changeCountryFilter}
                  />
                </Box>
              </div>
            </GridFilterHeader>

            <div className="innerContent">
              <AgGrid
                gridState={employeesOverviewState.grid}
                gridActions={{
                  setGridState: actions.setGridState,
                  setColumnStates: actions.setColumnStates,
                }}
                apolloConnector={apolloConnector}
                frameworkComponents={CellRenderer}
                columnDefs={getColumnDefs(translate, user)}
                cellCallbackOnExport={cellCallbackOnExport}
              />
            </div>
          </main>
        </Fragment>
      )}
    </Translate>
  );
};

const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  employeesOverviewState: appState.employeesOverview,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { setGridState, setColumnStates, search },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(EmployeesList));
