import React from "react";
import gql from "graphql-tag";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import ReplayIcon from "@material-ui/icons/Replay";
import RestoreIcon from "@material-ui/icons/Restore";

// import {
//   renewService as renewServiceMutationData,
//   renewServiceVariables as renewServiceMutationVars,
// } from "types/apolloGenerated/renewService";
// import {
//   renewWarranty as renewWarrantyMutationData,
//   renewWarrantyVariables as renewWarrantyMutationVars,
// } from "types/apolloGenerated/renewWarranty";
import {
  startRegeneration as startRegenerationMutationData,
  startRegenerationVariables as startRegenerationMutationVars,
} from "types/apolloGenerated/startRegeneration";
import {
  startFastCycleRegeneration as startFastCycleRegenerationMutationData,
  startFastCycleRegenerationVariables as startFastCycleRegenerationMutationVars,
} from "types/apolloGenerated/startFastCycleRegeneration";

import { Translate } from "react-localize-redux";
import format from "date-fns/format";

import DeviceLocationModal from "./DeviceLocationModal";

import { productInstanceAndCustomerDetails_productInstance as ProductInstance } from "types/apolloGenerated/productInstanceAndCustomerDetails";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";

// const RENEW_SERVICE = gql`
//   mutation renewService($deviceId: String!) {
//     productInstance {
//       silk {
//         renewService(deviceId: $deviceId)
//       }
//     }
//   }
// `;
// const RENEW_WARRANTY = gql`
//   mutation renewWarranty($deviceId: String!) {
//     productInstance {
//       silk {
//         renewWarranty(deviceId: $deviceId)
//       }
//     }
//   }
// `;

const START_REGEN = gql`
  mutation startRegeneration($deviceId: String!) {
    productInstance {
      silk {
        startRegeneration(deviceId: $deviceId)
      }
    }
  }
`;

const START_FAST_REGEN = gql`
  mutation startFastCycleRegeneration($deviceId: String!) {
    productInstance {
      silk {
        startFastCycleRegeneration(deviceId: $deviceId)
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    appSymbol: {
      verticalAlign: "middle",
      display: "inline-flex",
    },
    icon: {
      marginRight: "10px",
      verticalAlign: "top",
    },
    spacing: {
      paddingRight: "10px",
    },
    dataItem: {
      maxWidth: "80%",
    },
    dataItemInst: {
      textAlign: "right",
    },
  });
});

function reformat(dateStr: string | null, short: boolean = false) {
  if (!dateStr) return "-";
  if (short) {
    return (
      <Translate>
        {(translate) => {
          return (
            <div>
              {format(
                new Date(dateStr),
                translate.translate("generics.dateTimeFormatFNSShort") as string
              )}
            </div>
          );
        }}
      </Translate>
    );
  } else {
    return (
      <Translate>
        {(translate) => {
          return (
            <div>
              {format(
                new Date(dateStr),
                translate.translate("generics.dateTimeFormatFNSShort") as string
              )}
            </div>
          );
        }}
      </Translate>
    );
  }
}

const SilkDeviceData: React.FC<{ productInstance: ProductInstance | null }> = ({
  productInstance,
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  // const [renewService] = useMutation<
  //   renewServiceMutationData,
  //   renewServiceMutationVars
  // >(RENEW_SERVICE, {
  //   onCompleted: () => handleClickOpen(),
  //   onError: () => 0,
  // });
  // const [renewWarranty] = useMutation<
  //   renewWarrantyMutationData,
  //   renewWarrantyMutationVars
  // >(RENEW_WARRANTY, {
  //   onCompleted: () => handleClickOpen(),
  //   onError: () => 0,
  // });
  const [startFastCycleRegeneration] = useMutation<
    startFastCycleRegenerationMutationData,
    startFastCycleRegenerationMutationVars
  >(START_FAST_REGEN, {
    onCompleted: () => handleClickOpen(),
    onError: () => 0,
  });
  const [startRegeneration] = useMutation<
    startRegenerationMutationData,
    startRegenerationMutationVars
  >(START_REGEN, {
    onCompleted: () => handleClickOpen(),
    onError: () => 0,
  });

  const {
    productCode = "",
    dataSharedWithPartner = false,
    instanceInformation,
    servicePartner,
    customerSelectedInstaller,
    owner = null,
    address = null,
    batchNumber,
    erpBatchNumber,
    serialNumber,
  } = productInstance || {};

  const { id: installerId, name: installerName } = servicePartner || {};
  const { id: partnerId, name: partnerName } = customerSelectedInstaller || {};

  const { userInformation } = owner || {};

  let instanceType: string = "";
  let devId: string = "";
  let lastUpdate: any = null;
  let deviceActive: any = null;
  let nextServiceDate: any = null;
  let warrantyExpires: any = null;

  if (instanceInformation != null) {
    if (instanceInformation.__typename === "SilkInstanceInformation") {
      instanceType = userInformation ? "iot" : "not-reg";
      var tempDate = new Date(
        instanceInformation?.silkDetailsInstance?.lastUpdate ?? ""
      );
      lastUpdate = tempDate.toDateString();
      devId = instanceInformation?.deviceId ?? "";
      deviceActive = new Date(
        instanceInformation?.silkDetailsInstance?.deviceActivated ?? null
      ).toDateString();
      var ServiceCalc =
        instanceInformation?.silkDataInstance?.daysToService ?? 0;
      var WarrantyCalc =
        instanceInformation?.silkDataInstance?.daysWarrantyExpires ?? 0;
      var date = new Date();
      nextServiceDate = new Date(
        date.setTime(date.getTime() + ServiceCalc * 86400000)
      ).toDateString();
      warrantyExpires = new Date(
        date.setTime(date.getTime() + WarrantyCalc * 86400000)
      ).toDateString();
    } else {
      instanceType = "not-iot";
    }
  }

  return (
    <Box>
      <Box>
        <Typography variant="h6" component="h3">
          <strong>
            <Translate id="components.deviceData.title" />
          </strong>
        </Typography>
        <Box paddingBottom={2} fontWeight="fontWeightBold"></Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6} className={classes.spacing}>
          <Box>
            <DataItem
              translate="types.common.productCode"
              value={productCode}
            />
            <DataItem translate="components.silk.devId" value={devId} />
            <DataItem
              translate="types.common.serialNumber"
              value={serialNumber ? serialNumber : "-"}
            />
            <DataItem
              translate="types.common.batchNumber"
              value={batchNumber ? batchNumber : "-"}
            />
            <DataItem
              translate="components.deviceData.erpBatchNumber"
              value={erpBatchNumber ? erpBatchNumber : "-"}
            />
          </Box>
          <Box>
            <DataItem
              translate="types.common.installationDate"
              value={reformat(productInstance?.installationDate)}
            />
            <DataItem
              translate="components.silk.deviceActive"
              value={reformat(deviceActive)}
            />
            <DataItem
              translate="components.deviceData.userRegister"
              value={reformat(productInstance?.registrationDate)}
            />
            <DataItem
              translate="components.silk.lastUpdate"
              value={reformat(lastUpdate)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.dataItem}>
          <Box>
            <DataItem
              translate="types.productInstance.nextService"
              value={reformat(nextServiceDate)}
            />
            <DataItem
              translate="components.silk.nextWarranty"
              value={reformat(warrantyExpires)}
            />
            <DataItem
              translate="components.deviceData.installer"
              value={
                installerName ? (
                  <Link
                    underline="always"
                    href={`/cs/partner/${installerId}`}
                    color="inherit"
                  >
                    <span>{installerName}</span>
                  </Link>
                ) : (
                  <span>
                    <Translate id="components.deviceData.noInstaller" />
                  </span>
                )
              }
            />
            <DataItem
              translate="components.deviceData.servicePartner"
              value={
                partnerName ? (
                  <Link
                    underline="always"
                    href={`/cs/partner/${partnerId}`}
                    color="inherit"
                  >
                    <span>{partnerName}</span>
                  </Link>
                ) : (
                  <span>
                    <Translate id="components.deviceData.noPartner" />
                  </span>
                )
              }
            />
            <DataItem
              translate="components.deviceData.dataShare"
              value={
                dataSharedWithPartner ? (
                  <Translate id="generics.true" />
                ) : (
                  <Translate id="generics.false" />
                )
              }
            />
          </Box>
        </Grid>
      </Grid>
      {instanceType === "iot" && (
        <Box display="flex" paddingTop={4}>
          <Dialog open={open} aria-labelledby="alert-dialog-title">
            <DialogTitle id="alert-dialog-title">
              {"Action Completed Successfully"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleClickClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Box display="flex" width="80%">
            {/*
            <Box display="flex" paddingRight="15px">
              <StarIcon className={classes.icon} />
              <Link
                underline="always"
                href="#"
                color="inherit"
                onClick={() => {
                  renewService({ variables: { deviceId: devId } });
                }}
              >
                <Translate id="components.silk.renewService" />
              </Link>
            </Box>
            */}
            {/*
            <Box display="flex" paddingRight="15px">
              <AssistantIcon className={classes.icon} />
              <Link
                underline="always"
                href="#"
                color="inherit"
                onClick={() => {
                  renewWarranty({ variables: { deviceId: devId } });
                }}
              >
                <Translate id="components.silk.renewWarranty" />
              </Link>
            </Box>
            */}
            <Box display="flex" paddingRight="15px">
              <ReplayIcon className={classes.icon} />
              <Link
                underline="always"
                href="#"
                color="inherit"
                onClick={() => {
                  startRegeneration({ variables: { deviceId: devId } });
                }}
              >
                <Translate id="components.silk.startRegeneration" />
              </Link>
            </Box>
            <Box display="flex" paddingRight="15px">
              <RestoreIcon className={classes.icon} />
              <Link
                underline="always"
                href="#"
                color="inherit"
                onClick={() => {
                  startFastCycleRegeneration({
                    variables: { deviceId: devId },
                  });
                }}
              >
                <Translate id="components.silk.startFastCycle" />
              </Link>
            </Box>
            <Box display="flex">
              {/* <Block className={classes.icon} /> */}
              {/* <SetOutOfServiceButton /> */}
            </Box>
          </Box>
          <Box width="50%" padding="0 15px 0 10px">
            {address && (
              <DeviceLocationModal
                existingAddress={{
                  productCode: productCode,
                  city: address.city,
                  countryCode: address.countryCode,
                  postalCode: address.postalCode,
                  street: address.street,
                  location: address.location && {
                    latitude: address.location.latitude,
                    longitude: address.location.longitude,
                  },
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const DataItem: React.FC<{
  translate: string;
  value: any;
  short?: boolean;
}> = ({ translate, value, short = false }) => {
  return short ? (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={8}>
        <Box component="span" fontWeight="fontWeightBold">
          <Translate id={translate} />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box component="span">{value}</Box>
      </Grid>
    </Grid>
  ) : (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={6}>
        <Box component="span" fontWeight="fontWeightBold">
          <Translate id={translate} />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box component="span">{value}</Box>
      </Grid>
    </Grid>
  );
};

export default SilkDeviceData;
