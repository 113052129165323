import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";

import { AppState } from "redux/store";

import { Store as DeleteAccountStore } from "redux/types/AccountShortcuts/DeleteAccount";
import {
  close,
  change,
} from "redux/actions/AccountShortcuts/DeleteAccountActions";

import { Translate } from "react-localize-redux";

import Error from "components/common/Error";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "react-apollo";

interface IDeleteAccount {
  deleteAccount: DeleteAccountStore;
  actions: {
    close: typeof close;
    change: typeof change;
  };
}

const DeleteAccount: React.FC<IDeleteAccount> = ({
  deleteAccount,
  actions,
}) => {
  const { goBack } = useHistory();
  const client = useApolloClient();

  const onSuccess = () => {
    goBack();
    client.resetStore();
  };

  const submit = () => {
    if (deleteAccount.changeData) {
      actions.change(deleteAccount.changeData, onSuccess);
    }
  };

  const progress = deleteAccount.isLocked ? (
    <CircularProgress color="secondary" />
  ) : null;

  return (
    <Dialog
      open={Boolean(deleteAccount.changeData)}
      onClose={actions.close}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="form-dialog-title">
        <Translate id="components.deleteAccount.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Translate id="components.deleteAccount.body" />
        </DialogContentText>
        <Error error={deleteAccount.error} />
      </DialogContent>
      <DialogActions>
        {progress}
        <Button
          onClick={actions.close}
          color="primary"
          disabled={deleteAccount.isLocked}
        >
          <Translate id="generics.cancel" />
        </Button>
        <Button
          onClick={submit}
          color="primary"
          disabled={deleteAccount.isLocked}
        >
          <Translate id="generics.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  deleteAccount: state.accountShortcuts.deleteAccount,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ close, change }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
