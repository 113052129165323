import React from 'react';
import { Box, createStyles, Theme, makeStyles } from '@material-ui/core';
import ImageUpload from './ImageUpload';

import { PartnerDetail_organization } from 'types/apolloGenerated/PartnerDetail';
import { Translate } from 'react-localize-redux';
import { changeFuncType } from '.';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: "30px",
      marginBottom: "50px",
      '& h1': {
        marginBottom: '20px',
      }
    },
  }));

interface partnerData {
  data: PartnerDetail_organization
  onChangeFunc?: changeFuncType
};

const PartnerMedia: React.FC<partnerData> = ({ data, onChangeFunc = (value, name) => { console.log("CHANGEFUNC"); } }) => {
  const classes = useStyles();


  return (
    <Box className={classes.container}>
      <h1><Translate id="pages.partnerDetails.partnerMedia" /></h1>
      <ImageUpload
        imageTitle="Partner Logo"
        imageSize={{ height: 125, width: 200 }}
        defaultImage={data.logoUrl}
        defaultImageLink={data.logoLinkUrl}
        supportedFileTypes={["image/png", "image/jpeg", "image/gif"]}
        maxFileSize={1048576}
        onChangeFunc={onChangeFunc}
        imgPropName={"logoBase64"}
        urlPropName={"logoUrl"}
      />
      <ImageUpload
        imageTitle="Partner Image"
        imageSize={{ height: 759, width: 359 }}
        defaultImage={data.partnerImageUrl}
        defaultImageLink={data.partnerImageLinkUrl}
        supportedFileTypes={["image/png", "image/jpeg", "image/gif"]}
        maxFileSize={1048576}
        onChangeFunc={onChangeFunc}
        imgPropName={"partnerImageBase64"}
        urlPropName={"partnerImageUrl"}
      />
    </Box>
  )
};

export default PartnerMedia;