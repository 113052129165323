import { ActionType, Action } from 'redux/types/DeviceActions/RequestLog';


export const open = (productCode: string): Action => ({
  type: ActionType.OPEN,
  payload: productCode
});

export const close = (): Action => ({
  type: ActionType.CLOSE,
});
