import { State as AgGridState } from "redux/types/AgGrid";

export enum ActionType {
    "GRID" = "SERVICE_OVERVIEW_GRID",
    "SEARCH" = "SERVICE_OVERVIEW_SEARCH",
}

export interface SearchAction {
    type: ActionType.SEARCH;
    payload: string | null;
}

export interface State {
    grid: AgGridState;
    search: string | null;
}
