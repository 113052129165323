import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { Error } from 'types/CampGuideTypes';

import { ActionType, Action, FieldErrors } from 'redux/types/CouponCode';

import * as Types from 'types/ToolboxEntities';

import GraphQL from 'util/GraphQL';



export const dirty = (): Action => ({
  type: ActionType.DIRTY
});

export const error = (globalError: Error[] = [], propertyError: FieldErrors | null = null): Action => ({
  type: ActionType.ERROR,
  payload: {
    globalErrors: globalError,
    propertyErrors: propertyError
  }
});

export const loaded = (couponcode: Types.Couponcode): Action => ({
  type: ActionType.LOADED,
  payload: couponcode
});

export const loading = (): Action => ({
  type: ActionType.LOADING
});

export const saving = (): Action => ({
  type: ActionType.SAVING
});

export const load = (id: number): ThunkAction<Promise<void>, {}, {}, Action> => {
  return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {

    dispatch(saving());
    GraphQL.queryCouponcode(id).then((couponcode: Types.Couponcode) => {
      //console.log('DATA', couponcode);
      dispatch(loaded(couponcode));
    }).catch((errorData: Error) => {
      console.log('ERROR', errorData);
      dispatch(error([errorData]));
    });
  };
};


export const save = (couponcode: Types.Couponcode): ThunkAction<Promise<void>, {}, {}, Action> => {
  return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {
    dispatch(saving());
    GraphQL.saveCouponcode(couponcode).then((newCouponCode: Types.Couponcode) => {
      //console.log('DATA', newCouponCode);
      dispatch(loaded(newCouponCode));
    }).catch((errorData: Error) => {
      console.log('ERROR', errorData);
      dispatch(error([errorData]));
    });
  };
};
