import { ActionType, SearchAction } from 'redux/types/EmployeesOverview';

import AgGridActionHelper from 'redux/actions/AgGridActionHelper';


export const { setGridState, setColumnStates } = AgGridActionHelper<ActionType.GRID>(ActionType.GRID);

export const search = (searchTerm: string | null): SearchAction => ({
  type: ActionType.SEARCH,
  payload: searchTerm
});
