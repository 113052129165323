import React, { useEffect, useState, useCallback } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import gql from "graphql-tag";
import { useMutation, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';

import DropDown from 'components/common/DropDown'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Grid, TextField, createStyles, Theme } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { AppState } from 'redux/store';

import { Store as SendgridTemplatesCreateStore } from 'redux/types/SendgridTemplatesCreate';
import { close, change } from 'redux/actions/SendgridTemplatesCreateActions';
import { createSendgridTemplate, createSendgridTemplateVariables } from 'types/apolloGenerated/createSendgridTemplate';

import { countries as languageCountryCodes } from 'Pages/Sendgrid/TemplateGroupsOverview/TemplatesGrid';

import { QUERY_SENDGRID_TEMPLATE_LIST } from 'Pages/Sendgrid/TemplateGroupsOverview/index';

import { Translate } from "react-localize-redux";

import Error from 'components/common/Error';
import { SendGridTemplateType, Language, Country } from "types/apolloGenerated/globalTypes";
import { GET_SENDGRID_USERS } from "commonQueries/sendgridIds"
import { getSendgridUsers } from 'types/apolloGenerated/getSendgridUsers';


import cloneDeep from 'clone-deep';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignRight: {
      textAlign: "right",
      paddingTop: "40px!important"
    },
    toggleGroup: {
      "& .Mui-selected, & .Mui-selected:hover": {
        backgroundColor: "#005D8F",
        color: "white"
      },
      marginRight: "10px"
    },
    container: {
      width: "100%",
      marginBottom: "50px",
      "& .MuiTextField-root, & .MuiFormControl-root": {
        width: "100%",
        "& .MuiInputLabel-outlined": {
          transform: "translate(14px, 15px) scale(1)",
          fontSize: "0.9rem",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
      "& .MuiGrid-item:nth-child(even)": {
        paddingTop: "25px",
        paddingLeft: "10px"
      },
      "& .MuiGrid-item:nth-child(odd)": {
        paddingTop: "25px",
        paddingRight: "10px"
      },
      "& .MuiGrid-grid-xs-12": {
        paddingTop: "0!important"
      },
      "& .MuiGrid-grid-xs-6 .MuiGrid-item:not(:first-child):not(:last-child)": {
        padding: '0px 10px',
      }
    }
  }));




interface ISendgridTemplatesCreate {
  createTemplate: SendgridTemplatesCreateStore
  onChangeFunc?: (value: any, name: string) => void
  //data: sendgridGroupsGrid_sendGridTemplates_results
  actions: {
    close: typeof close
    change: typeof change
  }
}


interface tempAddress {
  name: string,
  countryCode?: Country | null,
  languageCode: Language,
  sendGridId: string,
  sendGridUserId: number,
  sendGridUserName: string,
  templateType: SendGridTemplateType,
  isDefault: boolean
}


const CREATE_SENDGRID_TEMPLATE = gql`
mutation createSendgridTemplate(
  $name: String!, 
  $sendGridId: String!, 
  $isDefault: Boolean!, 
  $countryCode: Country, 
  $languageCode: Language!, 
  $sendGridUserId: Int!,
  $templateType: SendGridTemplateType
  ){
  sendGridTemplate{
    create(sendGridTemplate:{
      name: $name,
      sendGridId:  $sendGridId,
      isDefault: $isDefault,
      countryCode: $countryCode,
      sendGridUserId: $sendGridUserId,
      languageCode: $languageCode,
      type: $templateType
    }){
      id
    }
  }
}
`;

const defaultValues: tempAddress = {
  name: "",
  languageCode: Language.EN,
  sendGridId: "",
  sendGridUserId: 0,
  sendGridUserName: "",
  templateType: SendGridTemplateType.NOT_SPECIFIED,
  isDefault: false
};



const SendgridTemplatesCreate: React.FC<ISendgridTemplatesCreate> = ({ createTemplate, actions }) => {
  let classes = useStyles();
  // const [createSendgridTemplate, { loading: createTemplateLoading, error }] = useMutation<createSendgridTemplate, createSendgridTemplateVariables>(CREATE_SENDGRID_TEMPLATE);

  const { data: sendGridUserIds } = useQuery<getSendgridUsers>(GET_SENDGRID_USERS);
  const sgIds = sendGridUserIds?.sendGridUsers ?? []
  const [templateData, setTemplateData] = useState<tempAddress>(defaultValues);
  const [isDefault, setChangedIsDefault] = React.useState("");
  const [error, setError] = useState("");


  let variant: 'standard' | 'outlined' | 'filled' = 'standard';

  let newTemplateId = "";

  const [createSendgridTemplateFunc, { loading: createTemplateLoading }] = useMutation<createSendgridTemplate, createSendgridTemplateVariables>(CREATE_SENDGRID_TEMPLATE, {

    onCompleted: data => {
      newTemplateId = data?.sendGridTemplate?.create?.id;
      console.log("define templateId: ", newTemplateId);

      actions.close();
    },
    refetchQueries: [{
      query: QUERY_SENDGRID_TEMPLATE_LIST
    }],
    onError: (error) => {
      setError(error.message);
    }
  });

  const submit = useCallback(() => {
    setError("");
    if (templateData && templateData.name && templateData.languageCode) {
      createSendgridTemplateFunc({
        variables: {
          name: templateData.name,
          sendGridId: templateData.sendGridId,
          isDefault: templateData.isDefault,
          countryCode: templateData.countryCode,
          sendGridUserId: templateData.sendGridUserId,
          languageCode: templateData.languageCode,
          templateType: templateData.templateType
        },

      }).catch((e) => {
        console.log('Error while adding Employee: ', e);
      });
    }
  }, [createSendgridTemplateFunc, templateData]);



  const close = () => {
    setTemplateData(defaultValues);
    actions.close();
  };


  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    onChangeFunc(newAlignment, "isDefault")
    setChangedIsDefault(newAlignment === "true" ? "true" : "false");
  };


  const children = [
    <ToggleButton key="1" value="false">
      False
    </ToggleButton>,
    <ToggleButton key="2" value="true">
      True
    </ToggleButton>,
  ];

  const countries: string[] = [];
  const [countryState, setCountryState] = React.useState<string[]>();

  useEffect(() => {
    setCountryState(["GB"]);
  }, []);


  const onChangeFunc = (value: any, name?: string) => {

    const tempAddress = cloneDeep(templateData);

    switch (name) {
      case "sendgridId":
        tempAddress.sendGridId = value.replace(/\s/g, '');
        break;
      case "sendgridUser":
        //tempAddress.sendgridUser = value;
         
        tempAddress.sendGridUserId = sgIds.find(sendgrid => sendgrid?.name === value)?.id ?? -1
        tempAddress.sendGridUserName = value;
        break;
      case "templateName":
        tempAddress.name = value.replace(/\s/g, '');
        break;
      case "templateType":
        tempAddress.templateType = value;
        break;
      case "languageCode":
        tempAddress.languageCode = value;
        tempAddress.countryCode = null;

        for (let language of languageCountryCodes) {
          if (language.substring(0, 2) === value) {
            countries.push(language.substring(3, 5));
          }
        }
        setCountryState(countries);
        console.log("countries", countries);
        break;
      case "countryCode":
        tempAddress.countryCode = value;
        break;
      case "isDefault":
        tempAddress.isDefault = value === "true" ? true : false;
        break;
      default:

        break;
    }

    if (tempAddress) {
      setTemplateData(tempAddress);
    }

  };

  const progress = createTemplateLoading ? <CircularProgress color="secondary" /> : null;

  const languages: string[] = [];

  for (let language of languageCountryCodes) {
    if (!languages.includes(language.substring(0, 2))) {
      languages.push(language.substring(0, 2));
    }
  }


  //const sendgridUsers = ['Bwt.Main', 'bwt.italy', 'bwt.denmark', 'bwt.norway', 'bwt.hungary', 'bwt.poland'];
  const sendGridUserNames = sgIds.map((sendgrid) => sendgrid?.name ?? "") ?? [];

  return (

    <Dialog open={Boolean(createTemplate.changeData)} onClose={actions.close} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="form-dialog-title"><Translate id="pages.sendgrid.templatesGrid.createTemplate" /></DialogTitle>
      <DialogContent>
        <Error error={error} />
        <Grid className={classes.container} style={{ position: "relative" }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid container>
                <Grid item xs={8} >
                  <TextField
                    variant={variant}
                    type="text"
                    name="templateName"
                    label="Template Name"
                    autoFocus
                    onChange={(evt) => { onChangeFunc(evt.target.value, evt.target.name) }}
                  />
                </Grid>
                <Grid item xs={4} >
                  <DropDown
                    fieldName="Sendgrid User"
                    valueList={sendGridUserNames}
                    name="sendgridUser"
                    variant={variant}
                    noneValue={false}
                    selectedVal={templateData?.sendGridUserName || ''}
                    onChangeFunc={onChangeFunc}
                  //onChangeFunc={(value, name) => { onChangeFunc(value, name) }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={8} >
                  <TextField
                    variant={variant}
                    type="text"
                    label="Sendgrid Id"
                    name="sendgridId"
                    onChange={(evt) => { onChangeFunc(evt.target.value, evt.target.name) }}
                  />
                </Grid>
                <Grid item xs={4} >
                  <DropDown
                    fieldName="Template Type"
                    valueList={['NOT_SPECIFIED', 'ACCOUNT_SERVER', 'USER_ACTION', 'REGISTRATION', 'NOTIFICATION']}
                    name="templateType"
                    variant={variant}
                    noneValue={false}
                    selectedVal={templateData?.templateType || ''}
                    onChangeFunc={onChangeFunc} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3} >
                  <DropDown
                    fieldName="Language Code"
                    valueList={languages}
                    name="languageCode"
                    variant={variant}
                    noneValue={false}
                    selectedVal={templateData?.languageCode || ''}
                    onChangeFunc={onChangeFunc} />
                </Grid>
                <Grid item xs={3} >
                  <DropDown
                    fieldName="Country Code"
                    //valueList={['DE', 'AT', 'CH', 'FR', 'IT', 'NO']}
                    valueList={countryState}
                    name="countryCode"
                    className="countryDropdown"
                    isDisabled={templateData?.languageCode.length ? false : true}
                    variant={variant}
                    noneValue={false}
                    selectedVal={templateData?.countryCode || ''}
                    onChangeFunc={onChangeFunc} />
                </Grid>
                <Grid item xs={6} className={classes.alignRight}>
                  <span style={{
                    marginRight: "20px"
                  }}>
                    Is Default:
                  </span>
                  <ToggleButtonGroup className={classes.toggleGroup}
                    size="small"
                    exclusive
                    value={isDefault === "true" ? 'true' : 'false'}
                    onChange={handleAlignment}
                  >
                    {children}
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {progress}
        <Button onClick={close} color="primary" disabled={createTemplateLoading}>
          <Translate id="generics.cancel" />
        </Button>
        <Button onClick={() => submit()} color="primary" disabled={createTemplateLoading}>
          <Translate id="generics.saveNclose" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: AppState) => ({
  createTemplate: state.sendgridTemplatesCreate,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ close, change }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendgridTemplatesCreate);
