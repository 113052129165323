import React from "react";
import { Translate } from "react-localize-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography, TextField, Checkbox } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { ProductUpdateInput } from "../../../types/apolloGenerated/globalTypes";
import Expander from "components/common/Expander";

interface PartnerRegistrationProps {
  productInfoObj: ProductUpdateInput;
  setProductInfoObj: React.Dispatch<React.SetStateAction<ProductUpdateInput>>;
  validation: { field: string; message: string }[];
}

const useStyles = makeStyles(() => {
  return createStyles({
    hideButton: {
      width: "30px",
      height: "35px",
      paddingTop: "6px",
      cursor: "pointer",
    },
    titleOuterContainer: {
      fontSize: "20px",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      borderRadius: "3px",
      justifyContent: "space-between",
    },
    titleInnerContainer: {
      display: "flex",
      alignItems: "center",
    },
    removeButtonContainer: {
      marginRight: "20px",
      display: "flex",
      cursor: "pointer",
    },
    container: {
      borderRadius: "3px",
      minWidth: "990px",
      maxWidth: "1615px",
      border: "solid #666B6E 1px",
    },
    reactiveBoxesRight: {
      minWidth: "350px",
      width: "90%",
      margin: "0 0 23px 30px",
    },
  });
});

const PartnerRegistration: React.FC<PartnerRegistrationProps> = ({
  productInfoObj,
  setProductInfoObj,
  validation,
}) => {
  const [hidden, setHidden] = React.useState(
    !productInfoObj?.serviceFeePearls ? true : false
  );
  const classes = useStyles();

  const openClose = () => {
    setProductInfoObj({
      ...productInfoObj,
      invoiceUploadAllowed: false,
      serviceFeePearls: !hidden ? null : 0,
    });
    setHidden(!hidden);
  };

  const validationFields = validation.map((value) => value.field);
  return (
    <Grid container>
      <Box style={{ margin: "0 30px 50px 30px", width: "100%" }}>
        <Typography
          variant="h6"
          component="h3"
          style={{
            paddingBottom: "20px",
          }}
        >
          <Translate id="pages.product.partnerRegistration" />
        </Typography>
        <Box>
          <Translate id="types.common.pearls" />
        </Box>
        <TextField
          value={productInfoObj?.bonus.toString()}
          variant="standard"
          type="number"
          id="bonusField"
          error={validationFields.includes("bonus")}
          className={classes.reactiveBoxesRight}
          helperText={
            validationFields.includes("bonus")
              ? validation[validationFields.indexOf("bonus")].message
              : ""
          }
          style={{
            maxWidth: "670px",
            width: "48%",
            margin: "0 0 23px 0px",
          }}
          disabled={true}
        />
        <Box>
          <Box className={classes.container}>
            <Box className={classes.titleOuterContainer}>
              <Box className={classes.titleInnerContainer}>
                <Box
                  onClick={() => openClose()}
                  className={classes.removeButtonContainer}
                >
                  <div style={{ display: "flex" }}>
                    <Checkbox checked={!hidden} />
                    <div style={{ paddingTop: "10px", fontSize: "17px" }}>
                      <Translate id="types.product.serviceFeePayout" />
                    </div>
                  </div>
                </Box>
              </Box>
              <Box onClick={() => openClose()} className={classes.hideButton}>
                {hidden ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
              </Box>
            </Box>
            <Expander hidden={hidden}>
              <Box style={{ display: "flex" }}>
                <Box>
                  <Box style={{ marginLeft: "22px" }}>
                    <Translate id="types.common.pearls" />
                  </Box>
                  <TextField
                    value={productInfoObj?.serviceFeePearls || 0}
                    variant="standard"
                    type="number"
                    error={validationFields.includes("serviceFeePearls")}
                    className={classes.reactiveBoxesRight}
                    helperText={
                      validationFields.includes("serviceFeePearls")
                        ? validation[
                            validationFields.indexOf("serviceFeePearls")
                          ].message
                        : ""
                    }
                    style={{
                      minWidth: "250px",
                      width: "30%",
                      margin: "0 20px 8px 22px",
                    }}
                    disabled={true}
                  />
                </Box>
              </Box>
            </Expander>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
export default PartnerRegistration;
