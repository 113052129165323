import React from "react";
import { Translate, TranslateFunction } from "react-localize-redux";
import { Link } from "react-router-dom";
import format from "date-fns/format";

//import gql from "graphql-tag";

import { ColDef } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { UserProfile, Position } from "types/ToolboxEntities";

import { factoryApolloConnector } from "util/AgGridUtils";
import CampUtils from "util/CampUtils";
import { QUERY_EMPLOYEE_LIST } from "commonQueries/employee";

import {
  employeesGrid,
  employeesGrid_employees_results,
} from "types/apolloGenerated/employeesGrid";

import {
  PartnerType,
  EmployeeFilterOption,
} from "types/apolloGenerated/globalTypes";

import { campColours } from "themes/bwtColors";

const colMapping = {
  "userInformation.firstName": EmployeeFilterOption.FIRST_NAME,
  "userInformation.lastName": EmployeeFilterOption.LAST_NAME,
  "userInformation.email": EmployeeFilterOption.EMAIL,
  "userInformation.address.city": EmployeeFilterOption.CITY,
  "userInformation.address.countryCode": EmployeeFilterOption.COUNTRY,
  position: EmployeeFilterOption.POSITION,
  "organization.name": EmployeeFilterOption.ORGANIZATION_NAME,
  isPendingOrganizationAssignment: EmployeeFilterOption.IS_PENDING,
  "userInformation.lastLogin": EmployeeFilterOption.LAST_LOGIN,
  "userInformation.accountLocked": EmployeeFilterOption.IS_LOCKED,
};

const searchColumns = [
  EmployeeFilterOption.EMAIL,
  EmployeeFilterOption.FIRST_NAME,
  EmployeeFilterOption.CITY,
  EmployeeFilterOption.ORGANIZATION_NAME,
  EmployeeFilterOption.LAST_NAME,
  EmployeeFilterOption.POSTAL_CODE,
];

export const apolloConnectorBySearch = (searchTerm: string | null) => {
  return factoryApolloConnector(
    QUERY_EMPLOYEE_LIST,
    (data: employeesGrid) => data.employees,
    colMapping,
    {
      search: {
        searchCols: searchColumns as string[],
        searchTerm,
      },
      defaultSort: [
        {
          colId: "isPendingOrganizationAssignment",
          sort: "desc",
        },
      ],
    }
  );
};

export const getColumnDefs = (
  translate: TranslateFunction,
  user: UserProfile | null
): ColDef[] => {
  const stateStyle = (params: any) => {
    if (params?.data?.isPendingOrganizationAssignment) {
      return { backgroundColor: campColours.backgroundWarn };
    }
  };

  const columnDefs = [
    {
      headerName: translate("types.common.name") as string,
      field: "userInformation.firstName",
      cellRenderer: "CombineFieldsWithLink",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      cellStyle: stateStyle,
      width: 150,
    },
    {
      headerName: translate("generics.email") as string,
      field: "userInformation.email",

      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      cellStyle: stateStyle,
      width: 160,
    },

    {
      headerName: translate("types.address.postalCode") as string,
      field: "userInformation.address.postalCode",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      cellStyle: stateStyle,
      width: 100,
      sortable: false,
      suppressMenu: true,
    },

    {
      headerName: translate("types.address.city") as string,
      field: "userInformation.address.city",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      cellStyle: stateStyle,
      width: 80,
    },
    {
      headerName: translate("types.address.countryCode") as string,
      field: "userInformation.address.countryCode",
      filter: user ? "agSetColumnFilter" : false,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        values: user ? user.allowedCountries : [],
      },
      cellStyle: stateStyle,
      width: 90,
    },
    {
      headerName: translate("types.organization.name") as string,
      field: "organization.name",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      cellStyle: stateStyle,
      width: 200,
    },
    {
      headerName: translate("types.organization.partnerType") as string,
      field: "organization.partnerType",
      filter: "agSetColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        values: CampUtils.getEnumAsArray(PartnerType),
      },
      cellStyle: stateStyle,
      width: 100,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: translate("types.organization.employeerole") as string,
      field: "position",
      cellRenderer: "FilterNone",
      filter: user ? "agSetColumnFilter" : false,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        values: CampUtils.getEnumAsArray(Position),
      },
      cellStyle: stateStyle,
      width: 100,
    },
    {
      headerName: translate("types.organization.lastLogin") as string,
      field: "userInformation.lastLogin",
      cellRenderer: "FormatDate",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      cellStyle: stateStyle,
      width: 100,
      suppressMenu: true,
    },

    {
      headerName: translate("types.common.state") as string,
      field: "isPendingOrganizationAssignment",
      filter: "agSetColumnFilter",
      filterParams: {
        filterOptions: ["EQ"],
        suppressAndOrCondition: true,
        values: [true, false],
      },
      cellStyle: stateStyle,
      cellRenderer: "StateCell",
      width: 100,
      suppressMenu: true,
      // hide: true,
      lockVisible: true,
    },
    {
      headerName: translate("types.common.isLocked") as string,
      field: "userInformation.accountLocked",
      filter: "agSetColumnFilter",
      filterParams: {
        filterOptions: ["EQ"],
        suppressAndOrCondition: true,
        values: [true, false],
      },
      width: 120,
      hide: true,
      cellStyle: stateStyle,
    },
  ];

  return columnDefs;
};

interface CellRenderer {
  data: employeesGrid_employees_results;
  value: string;
}

const TestVerifiedAccount: React.FC<CellRenderer> = ({ data }) => {
  if (!(data && data.userInformation)) {
    return <div />;
  }
  if (data.userInformation.accountLocked) {
    return <Translate id="components.showAccountInfo.closed" />;
  } else if (!data.userInformation.allowedToLogin) {
    return <Translate id="components.showAccountInfo.deactivated" />;
  } else if (!data.userInformation.verifiedAccount) {
    return <Translate id="components.showAccountInfo.notVerified" />;
  } else {
    return <Translate id="types.common.active" />;
  }
};

const CombineFieldsWithLink: React.FC<CellRenderer> = ({ data, value }) => {
  if (!(data && data.userInformation)) {
    return <div />;
  }
  return (
    <Link to={"/pam/employee/" + data.userInformation.key}>
      {value + " " + data.userInformation.lastName}
    </Link>
  );
};

const FilterNone: React.FC<CellRenderer> = ({ value }) => {
  if (value === "NONE") {
    return <div />;
  }
  return <div>{value}</div>;
};

const PartnerTypeCell: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  return (
    <Translate id={"types.organization.partnerType." + value.toLowerCase()}>
      types.organization.partnerType.{value.toLowerCase()}
    </Translate>
  );
};

const FormatDate: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  //return <div>{format(new Date(value), "P")}</div>;
  if (!value) {
    return <div></div>;
  }
  return (
    <Translate>
      {(translate) => {
        return (
          <div>
            {format(
              new Date(value),
              translate.translate("generics.dateTimeFormatFNSShort") as string
            )}
          </div>
        );
      }}
    </Translate>
  );
};

const StateCell: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  if (data?.isPendingOrganizationAssignment) {
    return (
      <div>
        <Translate id={"types.employees.pending"} />
      </div>
    );
  }
  if (data?.userInformation?.accountLocked) {
    return (
      <div>
        <Translate id={"types.employees.locked"} />
      </div>
    );
  }
  if (data?.userInformation?.verifiedAccount) {
    return (
      <div>
        <Translate id={"types.common.verified"} />
      </div>
    );
  }
  if (
    data?.userInformation?.allowedToLogin &&
    !data?.userInformation?.verifiedAccount
  ) {
    return (
      <div>
        <Translate id={"types.employees.notVerfified"} />
      </div>
    );
  }

  if (data?.userInformation?.allowedToLogin) {
    return (
      <div>
        <Translate id={"types.common.inactive"} />
      </div>
    );
  }
  return <div> </div>;
};

export const CellRenderer = {
  CombineFieldsWithLink,
  PartnerTypeCell,
  FormatDate,
  TestVerifiedAccount,
  StateCell,
  FilterNone,
};
