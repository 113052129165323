import * as t from "../../../../types/apolloGenerated/silkNotifications";

export type QueryData = t.silkNotifications;
export type QueryVars = t.silkNotificationsVariables;
export type SilkMessage = {icon: string, header: string, date: Date, direction: any}


export enum Level {
  E = "error",
  W = "warn",
  I = "info"
}

export function getLevel(notif: SilkMessage): Level {
  switch (notif?.icon) {
    case "error":
      return Level.E;
    case "warning":
      return Level.W;
    default:
      return Level.I;
  }
}
