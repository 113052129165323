import React from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import {campColours} from 'themes/bwtColors';
import { Translate } from 'react-localize-redux';

import { fallbackTo } from 'components/common/Localization';


interface PageHeaderMessagePara {
  title: string
  open?: boolean
};


const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    pageHeaderMessage: {
      backgroundColor: campColours.backgroundError,
      padding: theme.spacing(2) + 'px ' + theme.spacing(3) + 'px',
    }
  })
});




const PageHeaderMessage: React.FC<PageHeaderMessagePara> = ({ title, open }) => {
  const classes = useStyles();

  return open ? (
    <div className={classes.pageHeaderMessage}>
      <Translate id={title} options={{ onMissingTranslation: fallbackTo(title), renderToStaticMarkup: false }} />&nbsp;
    </div>
  ) : null;
}

export default PageHeaderMessage;
