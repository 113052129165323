import { Error } from 'types/CampGuideTypes';

export enum ActionType {
  CHIPS = 'SET_CHIPS',
  ERROR = 'SET_CHIPS_ERROR',
  PENDING = 'SET_CHIPS_PENDING',
  SAVE = 'SET_CHIPS_SAVE',
  LOAD = 'SET_CHIPS_LOAD'
}

export interface InputToChipStore {
  chips: string[],
  pending: boolean,
  isLoaded: boolean,
  error: Error | null
}

export interface SetChipsAction {
  type: ActionType.CHIPS
  payload: string[],
}

export interface ErrorAction {
  type: ActionType.ERROR,
  payload: Error
}

export interface PendingAction {
  type: ActionType.PENDING
}

export interface LoadAction {
  type: ActionType.LOAD
}

export interface SaveAction {
  type: ActionType.SAVE
}

export type Action = SetChipsAction | PendingAction | ErrorAction | LoadAction | SaveAction;