import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Translate } from 'react-localize-redux';


import { TextField } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { campColours } from 'themes/bwtColors';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

import debounce from 'debounce';

import { AppState } from 'redux/store';
import { State as OrgOverviewState } from 'redux/types/DeviceOverview';
import { setGridState, setColumnStates, search } from 'redux/actions/DeviceOverviewActions';

import { UserProfile } from 'types/ToolboxEntities';

import AgGrid from 'components/common/Grid/AgGrid';
import CountryFilter from 'components/common/CountryFilter';
import PageHeader from 'components/common/PageHeader';
import GridFilterHeader from 'components/common/Grid/GridFilterHeader';

import { getFilterValues } from 'util/AgGridUtils';

import { getColumnDefs, apolloConnectorBySearch, CellRenderer } from './GridConfig';
import DeviceSearchPreview from './DeviceSearchPreview';

import { ProductInstanceState } from 'types/apolloGenerated/globalTypes';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    deviceOverview: {
      display: 'flex',
      flexFlow: 'column',

      '& > .gridFilterHeader': {
        flex: '0 1 auto',
      },

      '& > .innerContent': {
        flex: '1 1 100%',
        height: '100%'
      },
      '& .ag-menu': {
        overflow: "inherit"
      },
      '& .hidden': {
        display: "none"
      },
    },

    innerHeaderRight: {
      flex: '0 1 auto',
      display: 'flex',
      alignItems: 'flex-end',
      margin: 0,

      '& > *': {
        flex: '0 1 auto',
        margin: '0 20px',
        minWidth: '170px',
      },
    },
    backgroundError: {
      "&.Mui-selected": {
        backgroundColor: campColours.backgroundError,
        "&:hover": {
          backgroundColor: campColours.bgErrorHover
        }
      },
      "&:hover": {
        backgroundColor: campColours.bgErrorHover
      }
    },
    backgroundWarn: {
      "&.Mui-selected": {
        backgroundColor: campColours.backgroundWarn,
        "&:hover": {
          backgroundColor: campColours.bgWarnHover
        }
      },
      "&:hover": {
        backgroundColor: campColours.bgWarnHover
      }
    },
    backgroundDiscon: {
      "&.Mui-selected": {
        backgroundColor: campColours.backgroundDiscon,
        "&:hover": {
          backgroundColor: campColours.bgDisconHover
        }
      },
      "&:hover": {
        backgroundColor: campColours.bgDisconHover
      }
    }
  })
});


const debouncedSearch = debounce((searchTerm: string, oldSearchTerm: string | null, searchAction: typeof search) => {
  if (searchTerm.length >= 3) {
    searchAction(searchTerm);
  } else if (searchTerm.length === 0 && (oldSearchTerm === null || oldSearchTerm.length > 0)) {
    searchAction(null);
  }
}, 500);


interface DeviceListPara {
  user: UserProfile | null
  deviceOverviewState: OrgOverviewState
  actions: {
    setGridState: typeof setGridState
    setColumnStates: typeof setColumnStates
    search: typeof search
  }
}

const DeviceList: React.FC<DeviceListPara> = ({ user, deviceOverviewState, actions }) => {
  const classes = useStyles();
  const [search, setSearch] = useState<string>(deviceOverviewState.search || "");
  const [apolloConnector, setApolloConnector] = useState<ReturnType<typeof apolloConnectorBySearch>>(apolloConnectorBySearch(deviceOverviewState.search));
  const [statesFilter, setStatesFilter] = useState([""]);

  useEffect(() => {
    setApolloConnector(apolloConnectorBySearch(deviceOverviewState.search));
  }, [deviceOverviewState.search]);


  if (!user) {
    return null;
  }

  const gridState = deviceOverviewState.grid;

  const changeFilter = (filterName: string, newFilterValues: string[] | false) => {
    const newFilter = Object.assign({}, gridState.filterModel);
    if (newFilterValues !== false) {
      newFilter[filterName] = {
        filterType: "set",
        values: newFilterValues
      };
    } else {
      delete newFilter[filterName];
    }
    actions.setGridState(gridState.page, gridState.sortModel, newFilter);
  }

  const changeTypeFilter = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
    let newFilterValues = value as string[];
    if (newFilterValues.length === 0 || newFilterValues.length === 2) {
      newFilterValues = []
    } else if (newFilterValues.includes("IoT")) {
      newFilterValues = ["SILK_IOT", "PERLA_IOT"]
    } else {
      newFilterValues = ["DEFAULT", "POOL", "WATER_DISPENSER"]
    }

    changeFilter(
      'product.productInformation.productType',
      newFilterValues.length > 1 && newFilterValues.length < 4 && newFilterValues
    );
  };

  const filter = gridState.filterModel["product.productInformation.productType"]
  const selectedTypes = filter && getFilterValues(filter).length === 3 ? ['NonIoT'] : filter && getFilterValues(filter).length === 2 ? ['IoT'] : [];

  const changeStateFilter = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
    let newFilterValues = value as string[];
    if (newFilterValues.toString() === statesFilter.toString()) {
      newFilterValues = [];
    }
    setStatesFilter(newFilterValues);
    let buttonCnt = event.currentTarget.parentElement!.childNodes.length;
    changeFilter(
      "hiddenstate",
      newFilterValues.length > 0 &&
      newFilterValues.length < buttonCnt &&
      newFilterValues
    );
  };

  const selectedStates = gridState.filterModel.hiddenstate
    ? getFilterValues(gridState.filterModel.hiddenstate)
    : [];

  const changeCountryFilter = (newFilterValues: string[]) => {
    changeFilter(
      "address.countryCode",
      newFilterValues.length > 0 &&
      newFilterValues.length < user.allowedCountries.length &&
      newFilterValues
    );
  };

  const selectedCountries = gridState.filterModel["address.countryCode"]
    ? getFilterValues(gridState.filterModel["address.countryCode"])
    : user.allowedCountries;

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debouncedSearch(event.target.value, deviceOverviewState.search, actions.search);
  }

  return (
    <Translate>{({ translate }) => (
      <Fragment>
        <PageHeader title="pages.cs.device.title" />

        <main className={"mainContentContainer fullHeight " + classes.deviceOverview} style={{ position: "relative" }}>
          <GridFilterHeader className="gridFilterHeader">
            <TextField className="filter" id="standard-basic" label="Type to filter" value={search} onChange={changeSearch} />

            <div className="innerHeaderRight">
              <CountryFilter selected={selectedCountries} onChange={changeCountryFilter} />

              <ToggleButtonGroup value={selectedTypes} onChange={changeTypeFilter} aria-label={translate("types.product.iot") as string}>
                <ToggleButton value="IoT"><Translate options={{ renderToStaticMarkup: false, renderInnerHtml: true }} id="types.product.iot" /></ToggleButton>
                <ToggleButton value="NonIoT"><Translate options={{ renderToStaticMarkup: false, renderInnerHtml: true }} id="types.product.iot.false" /></ToggleButton>
              </ToggleButtonGroup>

              <div hidden={window.innerWidth <= 1299 ? true : false}>
                <ToggleButtonGroup value={selectedStates} onChange={changeStateFilter} aria-label="text alignment">
                  <ToggleButton value={ProductInstanceState.ERROR} className={classes.backgroundError}>
                    <Translate options={{ renderToStaticMarkup: false, renderInnerHtml: true }} id="generics.error" />
                  </ToggleButton>
                  <ToggleButton value={ProductInstanceState.WARNING} className={classes.backgroundWarn}>
                    <Translate options={{ renderToStaticMarkup: false, renderInnerHtml: true }} id="types.productInstance.warnings" />
                  </ToggleButton>
                  <ToggleButton value={ProductInstanceState.NOT_CONNECTED} className={classes.backgroundDiscon}>
                    <Translate options={{ renderToStaticMarkup: false, renderInnerHtml: true }} id="types.productInstance.disconnected" />
                  </ToggleButton>
                  <ToggleButton value={ProductInstanceState.NOT_SET}>
                    <Translate options={{ renderToStaticMarkup: false, renderInnerHtml: true }} id="generics.ok" />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
          </GridFilterHeader>

          <DeviceSearchPreview productCode={deviceOverviewState.search} />

          <div className="innerContent">
            <AgGrid
              gridState={deviceOverviewState.grid}
              gridActions={{
                setGridState: actions.setGridState,
                setColumnStates: actions.setColumnStates,
              }}
              apolloConnector={apolloConnector}
              frameworkComponents={CellRenderer}
              columnDefs={getColumnDefs(translate, user)}
            />
          </div>
        </main>
      </Fragment>
    )}</Translate>
  );
};





const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  deviceOverviewState: appState.deviceOverview
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ setGridState, setColumnStates, search }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceList);
