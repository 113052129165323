import React, { Fragment } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Translate } from "react-localize-redux";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  ErrorOutline,
} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";

import { CircularProgress } from "@material-ui/core";

import CircleSegment from "./CircleSegment";
import {
  deviceSummary as QueryData,
  deviceSummaryVariables as QueryVars,
} from "types/apolloGenerated/deviceSummary";

import { isWithinInterval, subHours } from "date-fns";
import warningTriangle from "../../../resources/ic_warning_16px.svg";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    arrow: { fontSize: 16, margin: 10 },
    textField: {
      [`& input::-webkit-clear-button,
      & input::-webkit-outer-spin-button,
      & input::-webkit-inner-spin-button,
      & input::-webkit-toolbarbutton-dropdown`]: {
        display: "none",
      },
      width: 140,
    },
    vertAlign: {
      verticalAlign: "middle",
    },
    bigNumbers: {
      "@media (max-width: 1549px)": {
        fontSize: "35px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "45px",
      },
      marginTop: "18%",
      height: "22%",
      fontSize: "55px",
      width: "30%",
      textAlign: "center",
    },
    bigNumbersWithDoubleCircle: {
      "@media (max-width: 1549px)": {
        fontSize: "25px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "35px",
      },
      marginTop: "18%",
      height: "22%",
      fontSize: "45px",
      width: "30%",
      textAlign: "center",
    },
    measurement: {
      "@media (max-width: 1549px)": {
        fontSize: "15px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "18px",
      },
      height: "8%",
      fontSize: "20px",
      width: "30%",
      textAlign: "center",
    },
    measurementText: {
      "@media (max-width: 1549px)": {
        fontSize: "15px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "18px",
      },
      fontSize: "20px",
      width: "30%",
      textAlign: "center",
      height: "40%",
    },
    bottomInformationTitle: {
      "@media (max-width: 1549px)": {
        fontSize: "13px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "15px",
      },
      fontWeight: "bold",
      marginRight: "16px",
      fontSize: "17px",
    },
    bottomInformation: {
      "@media (max-width: 1549px)": {
        fontSize: "13px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "15px",
      },
      fontSize: "17px",
    },
    circleInformation: {
      position: "absolute",
      top: "7.5%",
      left: "7.9%",
      width: "84.4%",
      height: "82.9%",
      borderColor: "#f8f8f8",
      borderRadius: "50%",
      borderWidth: "0.6vw",
      borderStyle: "solid",
      display: "table",
    },
    perlaDivider: {
      margin: "5% 0 5% 35%",
      backgroundColor: "rgb(137, 158, 181)",
      width: "30%",
      height: "2px",
    },
    statsContainer: {
      display: "flex",
      width: "calc(100%/3)",
    },
    middleContainer: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      height: "80%",
    },
    divider: {
      width: "1px",
      backgroundColor: "#666B6E",
      margin: "5% 0 5% 0",
    },
    singleCircleContainer: {
      width: "30%",
      margin: "3%",
      position: "relative",
    },
    doubleCircleContainer: {
      width: "30%",
      margin: "1%",
      position: "relative",
    },
    circlePercentageText: {
      textAlign: "center",
      fontWeight: "bold",
      "@media (max-width: 1549px)": {
        fontSize: "24px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "34px",
      },
      fontSize: "40px",
    },
    warning: {
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "30px",
      width: "30px",
      backgroundRepeat: "no-repeat",
      margin: "auto",
      backgroundImage: `url(${warningTriangle})`,
    },
  });
});

const QUERY = gql`
  query deviceSummary($productCode: String!, $timestamp: DateTimeOffset!) {
    productInstance(productCode: $productCode) {
      id
      instanceInformation {
        __typename
        ... on PerlaInstanceInformation {
          registeredPerlaInstanceInformation {
            stateBody
            firmware
          }
          summary(timestamp: $timestamp) {
            next
            previous
            value {
              logDate
              brineCounter
              electrolysisCurrent
              waterHardnessIn
              waterHardnessOut
              waterTreatedCurrentMonth
              waterTreatedCurrentYear
              waterTreatedDaily
              consumptionOfResourceSinceRefill
              consumptionOfResourceSinceRefillMineral
              totalConsumptionOfResource
              saltAvailabilityInDay
            }
          }
          summaryRawData(timestamp: $timestamp) {
            value
          }
          telemetry(timestamp: $timestamp) {
            next
            previous
            value {
              availableAmountOfResource
            }
          }
        }
      }
    }
  }
`;

function protectDateSetter(
  setter: (date: Date) => void
): (dateStr: string | null) => void {
  return (dateStr: string | null) => {
    if (dateStr) {
      const date = new Date(dateStr);
      if (!isNaN(date.getTime())) {
        setter(date);
      }
    }
  };
}

interface DeviceSummaryProps {
  productCode: string;
  articleNumber: string;
}

const getMineralValue = (
  firmwareVersion: number,
  consumptionOfResourceSinceRefillMineral: number
) => {
  const PERLA_HYBRID_MINERAL_CAPACITY = 3000;
  if (consumptionOfResourceSinceRefillMineral != null) {
    if (firmwareVersion < 2.0) {
      //Consumption needs to be scaled in terms of ml to liters
      const consumedMineralCapacity =
        PERLA_HYBRID_MINERAL_CAPACITY -
        consumptionOfResourceSinceRefillMineral * 1000;
      const mineralRemaining =
        (consumedMineralCapacity / PERLA_HYBRID_MINERAL_CAPACITY) * 100;
      return mineralRemaining;
    } else {
      const consumedMineralCapacity =
        PERLA_HYBRID_MINERAL_CAPACITY - consumptionOfResourceSinceRefillMineral;
      const mineralRemaining =
        (consumedMineralCapacity / PERLA_HYBRID_MINERAL_CAPACITY) * 100;
      return mineralRemaining;
    }
  }
  return 0;
};

const DeviceSummary: React.FC<DeviceSummaryProps> = ({
  productCode,
  articleNumber,
}) => {
  const classes = useStyles();
  const initialDate = new Date();
  const [selectedDate, setSelectedDate] = React.useState(initialDate);
  const [loadNewDate, setLoadNewDate] = React.useState(false);

  const { data, loading, error } = useQuery<QueryData, QueryVars>(QUERY, {
    variables: { productCode, timestamp: selectedDate },
  });

  //const { results } = usePaginatedNotifications(productCode);

  if (error) console.error(error);

  const info = data?.productInstance?.instanceInformation;

  let value;
  let previous: string = "";
  let next: string = "";
  let availableResource: number = 0;
  let isDisconnected = false;
  let firmwareVersion = 0;

  if (info?.__typename === "PerlaInstanceInformation") {
    previous = info?.summary?.previous;
    next = info?.summary?.next;
    value = info?.summary?.value;
    availableResource = info?.telemetry?.value?.availableAmountOfResource ?? 0;
    isDisconnected = info?.registeredPerlaInstanceInformation?.stateBody
      ? info?.registeredPerlaInstanceInformation?.stateBody.includes(
          "disconnected"
        )
      : false;
    firmwareVersion = info?.registeredPerlaInstanceInformation?.firmware ?? 0;
  }

  const {
    logDate,
    brineCounter,
    electrolysisCurrent,
    waterHardnessIn,
    waterHardnessOut,
    waterTreatedCurrentMonth,
    waterTreatedCurrentYear,
    waterTreatedDaily,
    saltAvailabilityInDay,
    consumptionOfResourceSinceRefillMineral,
  } = value || {};

  const sampleDate = logDate ? new Date(logDate) : null;
  const pickerDate = sampleDate && !loading ? sampleDate : selectedDate;
  const maxDailyWater = 3000;
  const maxMonthlyWater = 90000;
  const maxYearlyWater = 1080000;
  const setSelectedDateStr = protectDateSetter(setSelectedDate);

  const downloadIoTSummary = () => {
    if (
      data?.productInstance?.instanceInformation?.__typename ===
      "PerlaInstanceInformation"
    ) {
      if (data?.productInstance?.instanceInformation.summaryRawData?.value) {
        const notificationSummary = JSON.stringify(
          data?.productInstance?.instanceInformation.summaryRawData?.value,
          null,
          2
        ).replace(/\\"/g, '"');

        console.log("SUMMARY RAW: ", notificationSummary);

        const x = window.open();
        if (x) {
          x.document.open();
          x.document.write(
            "<html><body><pre>" + notificationSummary + "</pre></body></html>"
          );
          x.document.close();
        }
      }
    }
  };

  if (loading && !loadNewDate)
    return (
      <div style={{ display: "flex", placeContent: "center" }}>
        <CircularProgress color="secondary" />{" "}
      </div>
    );
  if (!value)
    return (
      <div>
        <Translate id="message.error.NoSummaryInformation" />
      </div>
    );
  const perlaHybridArticleNumbers = [
    "6-501305",
    "6-500145",
    "6-501312",
    "11435",
  ];
  const isPerlaHybrid = perlaHybridArticleNumbers.includes(articleNumber);
  const mineralLeft = Math.floor(
    getMineralValue(
      firmwareVersion,
      consumptionOfResourceSinceRefillMineral ?? 0
    )
  );

  const showError = () => {
    return isDisconnected ? (
      <div
        style={{ color: "rgb(201,51,54)", margin: "10px 0px", display: "flex" }}
      >
        <ErrorOutline style={{ height: "20px " }} />
        <div style={{ marginLeft: "5px" }}>
          <Translate id="components.deviceSummary.disconnected" />
        </div>
      </div>
    ) : !loading &&
      next === null &&
      !isWithinInterval(pickerDate, {
        start: subHours(new Date(), 48),
        end: new Date(),
      }) ? (
      <div
        style={{ color: "rgb(201,51,54)", margin: "10px 0px", display: "flex" }}
      >
        <ErrorOutline style={{ height: "20px " }} />
        <div style={{ marginLeft: "5px" }}>
          <Translate id="components.deviceSummary.outOfDate" />
        </div>
      </div>
    ) : (
      <Fragment />
    );
  };

  return (
    <Box>
      <Box display="flex">
        <Box width="36%">
          <Typography
            variant="h6"
            component="h3"
            style={{ display: "inline-block", marginRight: "20px" }}
          >
            <Translate id="components.deviceSummary.title" />
          </Typography>
          <Link
            underline="always"
            href="#"
            color="inherit"
            style={{ marginTop: "7px" }}
            onClick={downloadIoTSummary}
          >
            <Translate id="components.deviceData.downloadJSON" />
          </Link>
        </Box>
        <Box width="64%">
          <Box display="flex" justifyContent="center">
            {loading ? (
              <div style={{ width: "30px" }} />
            ) : (
              <ArrowBackIosRounded
                className={classes.arrow}
                color={previous ? "action" : "disabled"}
                onClick={() => {
                  setSelectedDateStr(previous);
                  setLoadNewDate(true);
                }}
              />
            )}
            <TextField
              className={classes.textField}
              type="date"
              value={pickerDate.toJSON().substr(0, 10)}
              onChange={(e) => setSelectedDateStr(e.target.value)}
            />
            {loading ? (
              <div style={{ width: "30px" }} />
            ) : (
              <ArrowForwardIosRounded
                className={classes.arrow}
                color={next ? "action" : "disabled"}
                onClick={() => {
                  setSelectedDateStr(next);
                  setLoadNewDate(true);
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      {showError()}
      {!loading ? (
        <Fragment>
          <Box display="flex">
            <Box
              className={
                isPerlaHybrid
                  ? classes.doubleCircleContainer
                  : classes.singleCircleContainer
              }
            >
              <CircleSegment value={availableResource}></CircleSegment>

              <Box className={classes.circleInformation}>
                <Box display="table-cell" className={classes.vertAlign}>
                  <Box textAlign="center" fontSize="100%">
                    <Translate id="components.deviceSummary.perlaLevel" />
                  </Box>
                  <Box className={classes.circlePercentageText}>
                    {availableResource.toString()}%
                  </Box>
                  <Box className={classes.perlaDivider} />
                  <Box textAlign="center" fontSize="100%">
                    {saltAvailabilityInDay ? saltAvailabilityInDay : "0"}
                    <Box component="span" m={0.3} />
                    <Translate id="components.deviceSummary.days" />
                  </Box>
                </Box>
              </Box>
            </Box>
            {isPerlaHybrid ? (
              <Box className={classes.doubleCircleContainer}>
                <CircleSegment value={mineralLeft}></CircleSegment>

                <Box className={classes.circleInformation}>
                  <Box display="table-cell" className={classes.vertAlign}>
                    <Box textAlign="center" fontSize="100%">
                      <Translate id="components.deviceSummary.mineralCompound" />
                    </Box>
                    <Box className={classes.circlePercentageText}>
                      {mineralLeft.toString()}%
                    </Box>
                    <Box className={classes.perlaDivider} />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Fragment />
            )}
            <Box className={classes.divider} />
            <Box width="60%" display="flex" flexWrap="wrap">
              <Box
                className={
                  isPerlaHybrid
                    ? classes.bigNumbersWithDoubleCircle
                    : classes.bigNumbers
                }
                marginLeft="9%"
              >
                {waterTreatedDaily ? (
                  waterTreatedDaily > maxDailyWater ? (
                    <div className={classes.warning} />
                  ) : (
                    waterTreatedDaily.toFixed(0)
                  )
                ) : (
                  "0"
                )}
              </Box>
              <Box
                className={
                  isPerlaHybrid
                    ? classes.bigNumbersWithDoubleCircle
                    : classes.bigNumbers
                }
              >
                {waterTreatedCurrentMonth ? (
                  waterTreatedCurrentMonth > maxMonthlyWater ? (
                    <div className={classes.warning} />
                  ) : (
                    waterTreatedCurrentMonth.toFixed(0)
                  )
                ) : (
                  "0"
                )}
              </Box>
              <Box
                className={
                  isPerlaHybrid
                    ? classes.bigNumbersWithDoubleCircle
                    : classes.bigNumbers
                }
              >
                {waterTreatedCurrentYear ? (
                  waterTreatedCurrentYear > maxYearlyWater ? (
                    <div className={classes.warning} />
                  ) : (
                    waterTreatedCurrentYear.toFixed(0)
                  )
                ) : (
                  "0"
                )}
              </Box>
              <Box className={classes.measurement} marginLeft="9%">
                <Translate id="components.deviceSummary.litres" />
              </Box>
              <Box className={classes.measurement}>
                <Translate id="components.deviceSummary.litres" />
              </Box>
              <Box className={classes.measurement}>
                <Translate id="components.deviceSummary.litres" />
              </Box>
              <Box className={classes.measurementText} marginLeft="9%">
                <Translate id="components.deviceSummary.today" />
              </Box>
              <Box className={classes.measurementText}>
                <Translate
                  options={{
                    renderInnerHtml: true,
                    renderToStaticMarkup: false,
                  }}
                  id="components.deviceSummary.sinceMonth"
                />
              </Box>
              <Box className={classes.measurementText}>
                <Translate
                  options={{
                    renderInnerHtml: true,
                    renderToStaticMarkup: false,
                  }}
                  id="components.deviceSummary.sinceYear"
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" textAlign="center" paddingTop={1} marginLeft={1}>
            <Box display="flex" width="30%">
              <Box className={classes.bottomInformationTitle}>
                <Translate id="components.deviceSummary.electro" />
              </Box>
              <Box className={classes.bottomInformation}>
                {electrolysisCurrent ? electrolysisCurrent.toFixed(0) : "0"}
                <Box component="span" m={0.3} />
                mA
              </Box>
            </Box>
            <Box display="flex" width="30%">
              <Box className={classes.bottomInformationTitle}>
                <Translate id="components.deviceSummary.brine" />
              </Box>
              <Box className={classes.bottomInformation}>
                {brineCounter ? (brineCounter * 0.5263).toFixed(0) : "0"}
                <Box component="span" m={0.3} />
                ml
              </Box>
            </Box>
            <Box display="flex" width="40%">
              <Box className={classes.bottomInformationTitle}>
                <Translate id="components.deviceSummary.hardness" />
              </Box>
              <Box className={classes.bottomInformation}>
                {waterHardnessIn ? waterHardnessIn.toFixed(1) : "0.0"}
                {"/"}
                {waterHardnessOut ? waterHardnessOut.toFixed(1) : "0.0"}
                <Box component="span" m={0.3} />
                dH
              </Box>
            </Box>
          </Box>
        </Fragment>
      ) : (
        <Box
          height="360px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      )}
    </Box>
  );
};

export default DeviceSummary;
