import React, { Fragment } from "react";
import { DialogContent, Dialog, DialogActions, Button, CircularProgress } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { Translate } from "react-localize-redux";

interface PageDialogProps {
  open: boolean
  title: string
  buttons: { title: string, click: () => void }[]
  error?: string
  loading?: boolean
}

const PageDialog: React.FC<PageDialogProps> = ({ open, title, buttons, error, loading }) => {

  return(
    <Dialog open={open}>
      <DialogContent style={{textAlign:"center", whiteSpace: "pre-wrap"}}>
        <Translate id={title} />
        {error ? <Alert severity="error">{error}</Alert> : <Fragment/>}
      </DialogContent>
      <DialogActions style={{ alignSelf: "center", height: "60px" }}>
        {loading ? <CircularProgress color="secondary"/> : 
          buttons.map((button, idx) => 
            <Button key={idx} onClick={button.click}>
              <Translate id={button.title} />
            </Button>
          )}
      </DialogActions>
    </Dialog>
  )
}
export default PageDialog