import React, { Fragment } from 'react';
import gql from 'graphql-tag'
import { Grid, Box } from '@material-ui/core';
import { createStyles, makeStyles } from "@material-ui/core/styles";

import GenerateCodes from "./GenerateCodes"
import { productList } from "types/apolloGenerated/productList"
import PageHeader from 'components/common/PageHeader';
import { CircularProgress } from '@material-ui/core';
import { useQuery } from 'react-apollo';

const useStyles = makeStyles(() => {
  return createStyles({
    registrationOverview: {
      display: "flex",
      flexFlow: "column",

      "& > .gridFilterHeader": {
        flex: "0 1 auto",
        margin: "0",
      },

      "& > .innerContent": {
        flex: "1 1 auto",
        height: "100%",
      },
    },
  });
});

const QUERY_PRODUCT = gql`
  query productList {
    products(pageSize: 0) {
      page
      pageSize
      totalResults
      results {
        productInformation {
          itemNumber
          name
          id
          isProductRegistrationEnabled
        }
      }
    }
  }
`;

const RegistrationCodes: React.FC = () => {
  const classes = useStyles();

  const { data, loading } = useQuery<productList>(QUERY_PRODUCT)

  if (loading) return <div><CircularProgress color="secondary" /></div>
  if (!data) return <div>No Products Available</div>

  return (
    <Fragment>
      <main
        className={
          "mainContentContainer fullHeight " + classes.registrationOverview
        }
      >
        <Grid container >
          <Grid item xs={12}>
            <PageHeader title="pages.production.regCodes" />
          </Grid>
          <Box style={{ width: "100%" }}>
            <Box style={{ marginBottom: "30px" }}>
              <GenerateCodes productList={data} />
            </Box>
          </Box>
        </Grid>
      </main>
    </Fragment>
  );
};





export default RegistrationCodes
