import React, {
  useState, useRef, useEffect,
  // Fragment 
} from "react";
// import { Translate } from "react-localize-redux";
import { Collection } from "immutable";
import format from "date-fns/format";
import { Translate } from "react-localize-redux";

import {
  PerlaNotification,
  // Level,getLevel 
} from "./types";
import style from "./style.module.css";
import Notification from "./Notification";

interface DayGroupProps {
  notifications: Collection<number, PerlaNotification>;
  dayMs: number;
  temporalHeight: number;
  isLast: boolean;
}

const MAX_HEIGHT = 500;

const DayGroup: React.FC<DayGroupProps> = ({
  notifications,
  dayMs,
  temporalHeight,
  isLast
}) => {

  const notifsRef = useRef<HTMLDivElement>(null);
  const [notifsHeight, setNotifsHeight] = useState(0);
  useEffect(() => {
    if (notifsRef && notifsRef.current){
      setNotifsHeight(notifsRef.current.offsetHeight);
    }
  }, [notifications]);
  const [userExpanded, setUserExpanded] = useState(false);
  const defaultExpanded = (notifsRef &&
    notifsRef.current &&
    notifsHeight < temporalHeight) as boolean;
  const expanded = defaultExpanded || userExpanded;
  const arrowIcon = defaultExpanded ? "" : expanded ? " ▲" : " ▼ Load " +  (notifications.toList().size - 10) + " more";

  return (
    <Translate>
      {(translate) => {
        return (
          <div className={style.dayGroup}>
            <div className={style.leftCol}>
              <div className={style.timePoint} />
              {!isLast && <div className={style.timeLine} />}
            </div>
            <div className={style.rightCol}>
              <div className={style.dateHeader}>{format(dayMs, translate.translate('generics.dateTimeFormatFNSShort') as string)}</div>
              <div onClick={() => setUserExpanded(!userExpanded)}>
                {/* <span className={style.levelSummary}>{levelSummary}</span> */}
                {
                  notifsHeight > MAX_HEIGHT && arrowIcon
                }
                {/* keep comments  incase usablity focussed function is requested in the future.   */}
              </div>
              <div
                className={style.expandableFrame}
                style={{
                  height: expanded ? Math.max(notifsHeight, temporalHeight)
                    : Math.min(MAX_HEIGHT, notifsHeight)
                }}
              >
                <div
                  ref={notifsRef}
                  className={style.expandableContent}
                >
                  {notifications.map((notif, idx) => (
                    <Notification key={idx} notification={notif} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Translate>
  );
};

export default DayGroup;
