import React, { Fragment, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { Box, TextField, Typography } from "@material-ui/core";

import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  ErrorOutline,
} from "@material-ui/icons";

import { Translate } from "react-localize-redux";
import { isBefore, isWithinInterval, subHours, parseISO } from "date-fns";

import SignalStrength from "../../../components/common/SignalStrength";
import { NotificationType } from "types/apolloGenerated/globalTypes";

import { productInstanceAndCustomerDetails_productInstance_instanceInformation_SilkInstanceInformation_notifications as SilkNotifArray } from "types/apolloGenerated/productInstanceAndCustomerDetails";

import { productInstanceAndCustomerDetails_productInstance_instanceInformation_SilkInstanceInformation as SilkInstanceInfo } from "types/apolloGenerated/productInstanceAndCustomerDetails";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    appSymbol: {
      verticalAlign: "middle",
      display: "inline-flex",
    },
    dataContainer: {
      display: "flex",
    },
    titleContainer: {
      marginBottom: "10px",
      fontWeight: "bold",
      minWidth: "60%",
      marginRight: "20px",
    },
    icon: {
      marginRight: "10px",
      verticalAlign: "top",
    },
    spacing: {
      paddingRight: "10px",
    },
    dataItem: {
      maxWidth: "80%",
    },
    dataItemInst: {
      textAlign: "right",
    },
    arrow: { fontSize: 16, margin: 7 },
    textField: {
      [`& input::-webkit-clear-button,
      & input::-webkit-outer-spin-button,
      & input::-webkit-inner-spin-button,
      & input::-webkit-toolbarbutton-dropdown`]: {
        display: "none",
      },
      width: 176,
    },
  });
});

const calcActivity = (value: number | undefined) => {
  if (!value) return -1;
  if (value < 7 && value >= 0) return "Regeneration State";
  if (value < 128 && value >= 7) return "Valve Status";
  if (value < 65536 && value >= 128) return "User Interface Status";
  return -1;
};

const SilkTelemetry: React.FC<{ silkData: SilkInstanceInfo | null }> = ({
  silkData,
}) => {
  const classes = useStyles();
  const [indexToShow, setIndexToShow] = useState<number>(0);

  const telemetryType = 1002;
  let silkTelemetryNotifs: (SilkNotifArray | null)[] = [];
  if (silkData && silkData?.notifications) {
    silkTelemetryNotifs = silkData?.notifications.filter(
      (notif) => notif?.messageType === telemetryType
    );
  }

  const regenType = 6012;
  let silkRegenNotifs: (SilkNotifArray | null)[] = [];
  if (silkData && silkData?.notifications) {
    silkRegenNotifs = silkData?.notifications.filter(
      (notif) => notif?.messageType === regenType
    );
  }

  let telemData;
  let numberOfTelemetryResults = 0;
  if (silkTelemetryNotifs && silkTelemetryNotifs[indexToShow]?.message) {
    numberOfTelemetryResults = silkTelemetryNotifs.length;
    telemData = JSON.parse(silkTelemetryNotifs[indexToShow]?.message ?? "{}");
  }

  let notFound = true;
  let counter = 0;
  while (notFound) {
    if (counter < numberOfTelemetryResults) {
      if (silkRegenNotifs && silkRegenNotifs[counter]?.message) {
        const data = JSON.parse(silkRegenNotifs[counter]?.message ?? "{}");
        if (data.Data && data.Data.state === 1) {
          if (
            isBefore(
              new Date(data.Timestamp.toLocaleString()),
              new Date(telemData?.Timestamp.toLocaleString())
            )
          ) {
            notFound = false;
            break;
          }
        }
      }
    } else {
      counter = 0;
      break;
    }
    counter++;
  }

  let regenData = JSON.parse(silkRegenNotifs[counter]?.message ?? "{}");

  if (numberOfTelemetryResults === 0) {
    return (
      <div>
        <Translate id="message.error.noSilkTelemetry" />
      </div>
    );
  }

  const timeStamp = telemData?.Timestamp ?? "-";
  const wifiConnection: number = telemData?.Data?.wifiCon ?? 0;
  const remainingCapacity: number = telemData?.Data?.remCap ?? 0;
  const remainingSalt: number =
    (telemData?.Data?.remSalt === 0 ? 0 : telemData?.Data?.remSalt / 10) ?? 0;
  const currentActivity: number = telemData?.Data?.currAct ?? 0;
  const waterConsumption: number = telemData?.Data?.waterCons ?? 0;
  const waterFlow: number = telemData?.Data?.waterFlow ?? 0;
  const isDisconnected = silkData?.state;
  let lastRegen: any =
    (regenData?.Timestamp &&
      regenData?.Timestamp.substr(0, 16).replace("T", " ")) ??
    "-";

  const showError = () => {
    return isDisconnected === NotificationType.NOT_SYNCHED ? (
      <div
        style={{
          color: "rgb(201,51,54)",
          margin: "10px 0px",
          display: "flex",
        }}
      >
        <ErrorOutline style={{ height: "20px " }} />
        <div style={{ marginLeft: "5px" }}>
          <Translate id="components.telemetry.disconnected" />
        </div>
      </div>
    ) : indexToShow === 0 &&
      !isWithinInterval(parseISO(timeStamp), {
        start: subHours(new Date(), 48),
        end: new Date(),
      }) ? (
      <div
        style={{
          color: "rgb(201,51,54)",
          margin: "10px 0px",
          display: "flex",
        }}
      >
        <ErrorOutline style={{ height: "20px " }} />
        <div style={{ marginLeft: "5px" }}>
          <Translate id="components.telemetry.outOfDate" />
        </div>
      </div>
    ) : (
      <Fragment />
    );
  };

  return (
    <Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ marginBottom: "16px" }}
        >
          <Typography component="h3" variant="h6">
            <Translate id="components.telemetry.title" />
          </Typography>
          <Box display="flex" alignItems="center">
            {
              <ArrowBackIosRounded
                className={classes.arrow}
                color={
                  indexToShow < numberOfTelemetryResults - 1
                    ? "action"
                    : "disabled"
                }
                onClick={() => {
                  if (indexToShow < numberOfTelemetryResults - 1) {
                    setIndexToShow(indexToShow + 1);
                  }
                }}
              />
            }
            <TextField
              className={classes.textField}
              type="datetime-local"
              value={timeStamp.substr(0, 16)}
            />
            {
              <ArrowForwardIosRounded
                className={classes.arrow}
                color={indexToShow > 0 ? "action" : "disabled"}
                onClick={() => {
                  if (indexToShow > 0) {
                    setIndexToShow(indexToShow - 1);
                  }
                }}
              />
            }
          </Box>
        </Box>
      </Box>
      {showError()}
      <Fragment>
        <Box className={classes.dataContainer}>
          <Box className={classes.titleContainer}>WLAN</Box>
          <Box style={{ display: "flex" }}>
            <SignalStrength type={"wifi"} rssi={wifiConnection} />
            <div style={{ marginLeft: "10px" }}>{`(${wifiConnection})`}</div>
          </Box>
        </Box>
        <Box>
          <Box className={classes.dataContainer}>
            <Box className={classes.titleContainer}>
              <Translate id="components.telemetry.lastRegen" />
            </Box>
            <Box>{lastRegen}</Box>
          </Box>
          <Box className={classes.dataContainer}>
            <Box className={classes.titleContainer}>
              <Translate id="components.silk.remainingCapacity" />
            </Box>
            <Box>{remainingCapacity}</Box>
          </Box>
          <Box className={classes.dataContainer}>
            <Box className={classes.titleContainer}>
              <Translate id="components.silk.remainingSalt" />
            </Box>
            <Box>{remainingSalt}</Box>
          </Box>
          <Box className={classes.dataContainer}>
            <Box className={classes.titleContainer}>
              <Translate id="components.silk.waterConsumption" />
            </Box>
            <Box>{waterConsumption}</Box>
          </Box>
          <Box className={classes.dataContainer}>
            <Box className={classes.titleContainer}>
              <Translate id="components.silk.flowRate" />
            </Box>
            <Box>{waterFlow}</Box>
          </Box>
          <Box className={classes.dataContainer}>
            <Box className={classes.titleContainer}>
              <Translate id="components.silk.currentActivity" />
            </Box>
            <Box>{calcActivity(currentActivity)}</Box>
          </Box>
        </Box>
      </Fragment>
    </Box>
  );
};

export default SilkTelemetry;
