import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
      {...props}
    >
      <g data-name="Icon 24px">
        <path
          d="M21.594 23.958l-2.082-2.082H1.042l9.229-9.239-6.629-6.626 1.327-1.323 17.948 17.947-1.322 1.322zm.281-5.02L12.928 9.99l8.947-8.948v17.9z"
          fill="currentColor"
        />
        <path data-name="Shape" d="M0 0h25v25H0z" fill="none" />
      </g>
    </svg>
  );
}

export default SvgComponent;
