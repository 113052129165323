import React from "react";
import { Translate, TranslateFunction } from "react-localize-redux";
import { Link } from "react-router-dom";

import gql from "graphql-tag";
import { organizationsGrid } from "types/apolloGenerated/organizationsGrid";
import { organizationsGridWithPearls } from "types/apolloGenerated/organizationsGridWithPearls";

import { ColDef } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { UserProfile } from "types/ToolboxEntities";

import { factoryApolloConnector } from "util/AgGridUtils";
import CampUtils from "util/CampUtils";
import format from "date-fns/format";

import {
  PartnerType,
  OrganizationFilterOption,
} from "types/apolloGenerated/globalTypes";

export const QUERY_PARTNER_LIST = gql`
  query organizationsGrid(
    $page: Int
    $pageSize: Int
    $filter: [OrganizationFilter]
    $sorting: [SortingCriterionForOrganizationFilterOption]
  ) {
    organizations(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sorting: $sorting
    ) {
      page
      pageSize
      totalResults
      results {
        id
        name
        vatNumber
        hasEmployees
        employeeCount
        partnerType
        email
        externalId
        telephone
        creationTimeStamp
        address {
          street
          postalCode
          city
          countryCode
        }
      }
    }
  }
`;

export const QUERY_PARTNER_LIST_WITH_PEARLS = gql`
  query organizationsGridWithPearls(
    $page: Int
    $pageSize: Int
    $filter: [OrganizationFilter]
    $sorting: [SortingCriterionForOrganizationFilterOption]
  ) {
    organizations(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sorting: $sorting
    ) {
      page
      pageSize
      totalResults
      results {
        id
        name
        vatNumber
        hasEmployees
        employeeCount
        partnerType
        email
        externalId
        bonusaccount {
          pearls
        }
        telephone
        creationTimeStamp
        address {
          street
          postalCode
          city
          countryCode
        }
      }
    }
  }
`;


const colMapping = {
  id: OrganizationFilterOption.ID,
  name: OrganizationFilterOption.NAME,
  partnerType: OrganizationFilterOption.PARTNER_TYPE,
  email: OrganizationFilterOption.EMAIL,
  "address.city": OrganizationFilterOption.CITY,
  "address.countryCode": OrganizationFilterOption.COUNTRY,
  "address.postalCode": OrganizationFilterOption.POSTAL_CODE,
  creationTimeStamp: OrganizationFilterOption.CREATION_TIME_STAMP,
  vatNumber: OrganizationFilterOption.VAT_NUMBER,
  employeeCount: OrganizationFilterOption.EMPLOYEE_COUNT,
  hasEmployees: OrganizationFilterOption.HAS_EMPLOYEES,
};

const searchColumns = [
  OrganizationFilterOption.NAME,
  OrganizationFilterOption.CITY,
  OrganizationFilterOption.EMAIL,
  OrganizationFilterOption.POSTAL_CODE,
  OrganizationFilterOption.VAT_NUMBER,
  //OrganizationFilterOption.PARTNER_TYPE
];

export const apolloConnectorBySearch = (searchTerm: string | null) => {
  // console.log("apollostringstuff: ", searchTerm);
  return factoryApolloConnector(
    QUERY_PARTNER_LIST,
    (data: organizationsGrid) => data.organizations,
    colMapping,
    {
      search: {
        searchCols: searchColumns as string[],
        searchTerm,
      },
    }
  );
};

export const apolloConnectorBySearchWithPearls = (
  searchTerm: string | null
) => {
  // console.log("apollostringstuff: ", searchTerm);
  return factoryApolloConnector(
    QUERY_PARTNER_LIST_WITH_PEARLS,
    (data: organizationsGridWithPearls) => data.organizations,
    colMapping,
    {
      search: {
        searchCols: searchColumns as string[],
        searchTerm,
      },
    }
  );
};


export const getColumnDefsWithPearls = (
  translate: TranslateFunction,
  user: UserProfile | null
): ColDef[] => {
  const columnDef = getColumnDefs(translate, user);

  columnDef.push({
    headerName: translate("types.common.pearls") as string,
    field: "bonusaccount.pearls",
    filter: "agNumberColumnFilter",
    cellRenderer: "PearlsCount",
    width: 60,
    suppressMenu: true,
  });

  return columnDef;
};

const FormatDate: React.FC<CellRenderer> = ({ data, value }) => {
  if (!(data && value)) {
    return <div />;
  }
  return (
    <Translate>
      {(translate) => {
        return (
          <div>
            {format(
              new Date(value),
              translate.translate("generics.dateTimeFormatFNSShort") as string
            )}
          </div>
        );
      }}
    </Translate>
  );
};

export const getColumnDefs = (
  translate: TranslateFunction,
  user: UserProfile | null
): ColDef[] => {
  const columnDefs = [
    {
      headerName: translate("generics.id") as string,
      field: "id",
      filter: "agNumberColumnFilter",
      cellRenderer: "PartnerLink",
      width: 60,
      suppressMenu: true,
    },
    {
      headerName: translate("types.organization.name") as string,
      field: "name",
      width: 280,
    },
    {
      headerName: translate("types.organization.partnerType") as string,
      field: "partnerType",
      filter: "agSetColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        values: CampUtils.getEnumAsArray(PartnerType),
      },
      width: 100,
      sortable: false,
    },
    {
      headerName: translate("types.address.postalCode") as string,
      field: "address.postalCode",
      width: 100,
    },
    {
      headerName: translate("types.address.city") as string,
      field: "address.city",
      width: 100,
    },
    {
      headerName: translate("types.address.countryCode") as string,
      field: "address.countryCode",
      filter: user ? "agSetColumnFilter" : false,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        values: user ? user.allowedCountries : [],
      },
      width: 100,
    },
    {
      headerName: translate("types.organization.generalEmail") as string,
      field: "email",
      width: 220,
    },
    {
      headerName: translate("types.organization.externalId") as string,
      field: "externalId",
      width: 220,
    },
    {
      headerName: translate("types.organization.joinDate") as string,
      field: "creationTimeStamp",
      width: 120,
      cellRenderer: "FormatDate",
    },
    {
      headerName: translate("types.product.hasEmployees") as string,
      field: "hasEmployees",
      sortable: true,
      cellRenderer: "HasEmployees",
      filter: "agSetColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      width: 140,
    },
    {
      headerName: translate("types.product.regEmployeeCount") as string,
      field: "employeeCount",
      filter: "agNumberColumnFilter",
      width: 170,
    },
    {
      headerName: translate("types.product.vatNumber") as string,
      field: "vatNumber",
      width: 120,
    },
  ];
  return columnDefs;
};

export const gridOptions = {
  getRowStyle: checkForEmployee,
};

function checkForEmployee(params: any) {
  if (params.data?.employeeCount === 0) {
    return { "background-color": "rgba(238,64,64,0.13)" };
  }
}

interface CellRenderer {
  value: any;
  data: { id: number };
}

const PartnerLink: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  return <Link to={"partner/" + data.id}>{value}</Link>;
};

const PearlsCount: React.FC<CellRenderer> = ({ data, value }) => {
  if (value && value === "0") {
    return <div />;
  } else if (value && value !== "0") {
    return <div>{value}</div>;
  }
  return <div />;
};

const HasEmployees: React.FC<CellRenderer> = ({ data, value }) => {
  if (value) {
    return <span style={{ color: "green" }}>Yes</span>;
  } else return <span style={{ color: "red" }}>No</span>;
};

const PartnerTypeCell: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  return (
    <Translate id={"types.organization.partnerType." + value?.toLowerCase()}>
      types.organization.partnerType.{value?.toLowerCase()}
    </Translate>
  );
};

export const CellRenderer = {
  PartnerLink,
  PearlsCount,
  PartnerTypeCell,
  FormatDate,
  HasEmployees,
};
