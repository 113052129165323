import React from "react";
import { TextField, createStyles, Theme, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { promotionDetail_promotion as PromotionDetail } from "types/apolloGenerated/promotionDetail";
import { countryCodes_countryCodes_results } from "types/apolloGenerated/countryCodes"
import { Translate } from "react-localize-redux";

import { PromotionType } from "types/apolloGenerated/globalTypes"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FieldError } from "./PromotionInformationModels"

import { products_products_results } from "types/apolloGenerated/products"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    innerContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "15px"
    },
    textFields: {
      width: "46%",
    },
  });
});


interface promotionData {
  promotionDetails: PromotionDetail
  change: React.Dispatch<React.SetStateAction<PromotionDetail>>
  products: products_products_results[],
  availableCountryCodes: countryCodes_countryCodes_results[],
  errorHandler: FieldError[]
}

const PromotionInformation: React.FC<promotionData> = ({
  promotionDetails,
  change,
  products,
  availableCountryCodes,
  errorHandler
}) => {

  let classes = useStyles();

  const filteredProductsArray = products.filter((obj) => {
    return !obj.productInformation?.isArchived && obj.productInformation?.isProductRegistrationEnabled;
  });

  const productArray = filteredProductsArray.map(value => value.productInformation?.name ? value.productInformation.name : "").sort();

  let promotionType = []
  for (const type in PromotionType) {
    promotionType.push(type)
  }

  const {
    name,
    articleNumber,
    creationDate,
    creationUser,
    assignedProducts,
    usageCountLimit,
    countryCodes
  } = promotionDetails || {};

  const errorFields = errorHandler.map(value => value.field);
  const assignedProductArray = assignedProducts ? assignedProducts?.map(value => value?.name ? value.name : "") : []
  const selectedCountryCodeIds = countryCodes?.map(countryCode => countryCode!.id) ?? [];
  const assignedCountryCodes = availableCountryCodes.filter(countryCode => selectedCountryCodeIds.indexOf(countryCode.id) > -1 );

  var dateString = "" + creationDate;
  var slicedDate = dateString.slice(0, 10);

  return (
    <Box style={{ margin: "30px" }}>
      <Box className={classes.innerContainer}>
        <TextField
          className={classes.textFields}
          error={errorFields.includes("name") ? true : false}
          helperText={errorFields.includes("name") ? errorHandler[errorFields.indexOf("name")].message : ""}
          value={name || ""}
          label={<Translate id="types.common.name" />}
          variant="standard"
          name="name"
          onChange={evt => {
            change({ ...promotionDetails, name: evt.target.value });
          }}
        />
        <TextField
          className={classes.textFields}
          error={errorFields.includes("article") ? true : false}
          helperText={errorFields.includes("article") ? errorHandler[errorFields.indexOf("article")].message : ""}
          value={articleNumber || ""}
          label={
            <Translate id="components.promotions.details.promotionId" />
          }
          variant="standard"
          name="promotionId"
          onChange={evt => {
            change({ ...promotionDetails, articleNumber: evt.target.value });
          }}
        />
      </Box>
      <Box className={classes.innerContainer}>
        <TextField
          className={classes.textFields}
          value={creationUser || ""}
          label={
            <Translate id="components.promotions.details.creationUser" />
          }
          variant="standard"
          disabled={true}
        />
        <TextField
          className={classes.textFields}
          value={slicedDate || ""}
          label={
            <Translate id="components.promotions.details.creationDate" />
          }
          variant="standard"
          name="crDate"
          disabled={true}
        />
      </Box>
      <Box className={classes.innerContainer}>
        <TextField
          className={classes.textFields}
          style={{ width: "100%" }}
          value={usageCountLimit}
          label={
            <Translate id="components.promotions.details.usageCountLimit" />
          }
          variant="standard"
          type="number"
          onChange={evt => {
            change({ ...promotionDetails, usageCountLimit: Number(evt.target.value) });
          }}
          InputProps={{ inputProps: { min: 1 } }}
        />
      </Box>
      <Box className={classes.innerContainer}>
        <Box className={classes.textFields} style={{ width: "100%" }}>
          <Autocomplete
            multiple
            options={productArray}
            getOptionLabel={(option: string) => option}
            defaultValue={assignedProductArray}
            onChange={(_, value) => {
              change({ ...promotionDetails, assignedProducts: value.map(value => ({ __typename: "ProductInformation", name: value })) })
            }}
            renderInput={(params: any) => (
              <TextField
                style={{ width: "100%" }}
                {...params}
                variant="standard"
                label={
                  <Translate id='types.common.products' />
                }
              />
            )}
          />
        </Box>
      </Box>
      <Box className={classes.innerContainer}>
        <Box className={classes.textFields} style={{ width: "100%" }}>
          <Autocomplete
            multiple
            options={availableCountryCodes}
            getOptionLabel={(option: countryCodes_countryCodes_results) => option.country}
            defaultValue={assignedCountryCodes}
            onChange={(_, value) => {
              change({ ...promotionDetails, countryCodes: value })
            }}
            renderInput={(params: any) => (
              <TextField
                style={{ width: "100%" }}
                {...params}
                variant="standard"
                // TODO: Translations?
                label="Country Codes"
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PromotionInformation;
