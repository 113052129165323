import gql from "graphql-tag";
import { DataProxy } from 'apollo-cache';

import cloneDeep from "clone-deep";

import { productById, productByIdVariables } from "types/apolloGenerated/productById";

export const updateProductByIdCache = (cache: DataProxy, id: number, modify: (p: productById) => productById) => {
  try {
    const variables: productByIdVariables = {
      id: id
    };

    const cachedProduct = cloneDeep(cache.readQuery<productById, productByIdVariables>({
      query: QUERY_PRODUCT_BY_ID,
      variables: variables,
    }));

    if (cachedProduct) {
      cache.writeQuery({
        query: QUERY_PRODUCT_BY_ID,
        variables: variables,
        data: modify(cachedProduct)
      });
    } else {
      console.log('Trying to update CACHE, but product not found');
    }
  } catch (e) { }
}


export const QUERY_PRODUCT_BY_ID = gql`
query productById($id: Int!) {
  product(id: $id){
    productInformation {
			gTIN
      name
      productType
      bonus
      itemNumber
      id
      deviceType
      deviceVersion
      productOwnerEmail
      lastEdited
      consumableProducts{
        id
        name
      }
      displayName
      businessUnit
      isArchived
      isCodelessProductRegistrationEnabled
      isProductRegistrationEnabled
      registrationMailTemplateName
      invoiceUploadAllowed
      registrationBonus{
        bonusId
      }
      serviceFeePearls
      productGroup {
        description
        displayName
        id
        name
      }
      productUserAction {
        id
        userActionClass
        notifications {
          id
          mailTemplateName
          state
          threshold
          translations {
            id
            body
            title
            language
          }
        }
      }
    }
  }
  enabledCountries {
    countries
  }
}
`;
