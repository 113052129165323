import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';


import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';

import { AppState } from 'redux/store';

import { Store } from 'redux/types/ChangeOrganizationActiveState';
import { close, change } from 'redux/actions/ChangeOrganizationActiveStateActions';

import { Translate } from "react-localize-redux";

import Error from 'components/common/Error';



interface IChangeOrganizationActiveState {
  store: Store
  actions: {
    close: typeof close
    change: typeof change
  }
}


const ChangeOrganizationActiveState: React.FC<IChangeOrganizationActiveState> = ({ store, actions }) => {
  const submit = () => {
    if (store.changeData) {
      actions.change(store.changeData);
    }
  };

  const langKeyEnableDisable = store.changeData ? 'components.changeActive.enable' : 'components.changeActive.disable';

  const progress = store.isLocked ? <CircularProgress color="secondary" /> : null;

  return (
    <Dialog open={Boolean(store.changeData)} onClose={actions.close} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="form-dialog-title"><Translate id="components.changeActive.title" /></DialogTitle>
      <DialogContent>
        <DialogContentText><Translate id={langKeyEnableDisable} /></DialogContentText>
        <Error error={store.error} />
      </DialogContent>
      <DialogActions>
        {progress}
        <Button onClick={actions.close} color="primary" disabled={store.isLocked}>
          <Translate id="generics.cancel" />
        </Button>
        <Button onClick={submit} color="primary" disabled={store.isLocked}>
          <Translate id="generics.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: AppState) => ({
  store: state.changeOrganizationActiveState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ close, change }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeOrganizationActiveState);
