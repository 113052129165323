import React, { useState, useCallback, useEffect, Fragment } from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  Switch,
  Dialog,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import gql from "graphql-tag";
import { Translate } from "react-localize-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import ChartPager from "./Chart";
import CalendarIcon from "../../../../../src/resources/ic_calendar.svg";

import {
  SilkWaterDataForBarChart as SilkWaterQueryData,
  SilkWaterDataForBarChartVariables as SilkWaterQueryVars,
} from "../../../../types/apolloGenerated/SilkWaterDataForBarChart";

import {
  eachDayOfInterval,
  startOfDay,
  format,
  subDays,
  isBefore,
  subHours,
  addDays,
  isWithinInterval,
  addMinutes,
  intervalToDuration,
  endOfDay,
  subMinutes,
} from "date-fns";

import {
  WaterAndSaltDataForBarChart as SaltAndWaterQueryData,
  WaterAndSaltDataForBarChartVariables as SaltAndWaterQueryVars,
} from "types/apolloGenerated/WaterAndSaltDataForBarChart";
import { DateAggregationType } from "types/apolloGenerated/globalTypes";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    header: {
      fontSize: "20px",
      fontWeight: "bold",
      display: "flex",
      flexWrap: "wrap",
      height: "40px",
      alignItems: "center",
      marginBottom: "35px",
      justifyContent: "space-between",
    },
    headerLeft: {
      color: "rgb(137, 158, 181)",
    },
    headerRight: {
      color: "rgb(244, 182, 201)",
      marginRight: "auto",
    },
    monthPicker: {
      left: "100px",
    },
    chartWrap: {
      alignContent: "center",
      height: "400px",
      width: "100%",
    },
    selectRoot: {
      padding: "10px 40px 10px 10px",
    },
    toggleContainer: {
      fontWeight: "normal",
      alignItems: "baseline",
      display: "flex",
    },
    dateDialog: {
      padding: "30px 30px 0 30px",
      width: "300px",
      textAlign: "center",
      fontSize: "20px",
    },
  });
});

const SILK_QUERY = gql`
  query SilkWaterDataForBarChart(
    $productCode: String!
    $from: DateTimeOffset
    $to: DateTimeOffset
    $aggregate: DateAggregationType
  ) {
    productInstance(productCode: $productCode) {
      id
      instanceInformation {
        __typename
        ... on SilkInstanceInformation {
          waterStatistics(from: $from, to: $to, aggregate: $aggregate) {
            totalWaterConsumedLiter
            from
            to
          }
        }
      }
    }
  }
`;

const QUERY = gql`
  query WaterAndSaltDataForBarChart(
    $productCode: String!
    $from: DateTimeOffset!
    $to: DateTimeOffset!
    $aggregate: DateAggregationType!
  ) {
    productInstance(productCode: $productCode) {
      id
      instanceInformation {
        __typename
        ... on PerlaInstanceInformation {
          statistics(from: $from, to: $to, aggregate: $aggregate) {
            totalUsedSalt
            totalWaterTreated
            calculatedWaterConsumption
            to
            from
          }
        }
      }
    }
  }
`;

export interface Page {
  start: Date;
  until: Date;
}

export const formatNumber = (value: number) => {
  return value.toFixed(2).replace(/\.0+$/, "");
};

const now = new Date();

const removeTimezone = (value: Date) => {
  return addMinutes(value, value.getTimezoneOffset());
};

const BarChart: React.FC<{
  productCode: string;
  installationDate: Date;
  isSilk: boolean;
}> = ({ productCode, installationDate, isSilk }) => {
  const classes = useStyles();
  const [unit, setUnit] = useState("litres");
  const [showTreatedWater, setShowTreatedWater] = useState(false);
  const [once, setOnce] = useState(true);
  // const [useSalt, setUseSalt] = useState(false);
  const [pickDate, setPickDate] = useState(false);
  const [dateError, setDateError] = useState("");

  const [dates, setDates] = useState({
    start: subDays(now, 6),
    end: removeTimezone(endOfDay(now)),
  });

  const [pickedButNotSet, setPickedButNotSet] = useState({
    start: dates.start,
    end: dates.end,
  });

  const [pages, setPages] = useState<Page[]>([{ start: now, until: now }]);
  const [page, setPage] = useState(pages.length - 1);

  const getPages = useCallback(
    (start, until) => {
      let startx = start;
      let untilx = until;

      const pages: Page[] = [];
      const range = eachDayOfInterval({ start: start, end: until }).length;

      //pages pushed should not go over start and end date

      const oldestRecord = installationDate
        ? new Date(installationDate)
        : new Date(2006, 0, 1); //date could be changed to whatever is earliest plausible date
      //make startx one further than install date
      while (startx > new Date(oldestRecord)) {
        startx = subDays(startx, range);
        untilx = subDays(untilx, range);
      }

      while (subDays(untilx, range) < subHours(now, 1)) {
        pages.push({
          start: isBefore(startx, new Date(oldestRecord))
            ? new Date(oldestRecord)
            : startx,
          until: isBefore(
            isBefore(untilx, now) ? untilx : now,
            new Date(oldestRecord)
          )
            ? new Date(oldestRecord)
            : isBefore(untilx, now)
            ? untilx
            : now,
        });
        startx = addDays(startx, range);
        untilx = addDays(untilx, range);
      }

      return pages;
    },
    [installationDate]
  );

  const [getSaltAndWater, { data, loading }] = useLazyQuery<
    SaltAndWaterQueryData,
    SaltAndWaterQueryVars
  >(QUERY, {
    variables: {
      productCode,
      from: dates.start,
      to: dates.end,
      aggregate: DateAggregationType.DAY,
    },
  });

  const [getWaterForSilk, { data: silkData }] = useLazyQuery<
    SilkWaterQueryData,
    SilkWaterQueryVars
  >(SILK_QUERY, {
    variables: {
      productCode,
      from: dates.start,
      to: dates.end,
      aggregate: DateAggregationType.DAY,
    },
  });

  useEffect(() => {
    if (once) {
      const newPages = getPages(dates.start, dates.end);
      setPages(getPages(dates.start, dates.end));
      setPage(newPages.length - 1);
      setOnce(false);
    }
    isSilk
      ? getWaterForSilk({
          variables: {
            productCode,
            from: dates.start,
            to: dates.end,
            aggregate: DateAggregationType.DAY,
          },
        })
      : getSaltAndWater({
          variables: {
            productCode,
            from: dates.start,
            to: dates.end,
            aggregate: DateAggregationType.DAY,
          },
        });
  }, [
    dates.end,
    dates.start,
    getPages,
    once,
    getSaltAndWater,
    getWaterForSilk,
    isSilk,
    productCode,
  ]);

  const silkInfo = silkData?.productInstance?.instanceInformation;
  const perlaInfo = data?.productInstance?.instanceInformation;

  const perlaWaterConsumption =
    perlaInfo?.__typename === "PerlaInstanceInformation"
      ? perlaInfo?.statistics?.map((value) => {
          // add timezone offset otherwise this value can cross different days.
          return {
            loggedAt: removeTimezone(startOfDay(new Date(value?.from))),
            water:
              (unit === "litres"
                ? showTreatedWater
                  ? value?.totalWaterTreated
                  : value?.calculatedWaterConsumption
                : ((showTreatedWater
                    ? value?.totalWaterTreated
                    : value?.calculatedWaterConsumption) ?? 0) / 1000) ?? 0,
          };
        })
      : [];

  const silkWaterConsumption =
    silkInfo?.__typename === "SilkInstanceInformation"
      ? silkInfo?.waterStatistics?.map((value) => {
          return {
            loggedAt: removeTimezone(startOfDay(new Date(value?.from))),
            water:
              (unit === "litres"
                ? value?.totalWaterConsumedLiter
                : (value?.totalWaterConsumedLiter ?? 0) / 1000) ?? 0,
          };
        })
      : [];

  const saltConsumption =
    perlaInfo?.__typename === "PerlaInstanceInformation"
      ? perlaInfo?.statistics?.map((value) => {
          return {
            loggedAt: removeTimezone(startOfDay(new Date(value?.from))),
            salt: value?.totalUsedSalt ?? 0,
          };
        })
      : [];

  const BlueSwitch = withStyles({
    switchBase: {
      color: "#005D8F",
      "&$checked": {
        color: "#005D8F",
      },
      "&$checked + $track": {
        backgroundColor: "#005D8F",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const validateSelectedDate = (start: Date, end: Date) => {
    // make sure we check the end of the day
    if (isBefore(removeTimezone(endOfDay(now)), end)) {
      setDateError("To date if after todays date");
      return false;
    } else if (isBefore(end, start)) {
      setDateError("To date is before from date");
      return false;
    } else if (
      (intervalToDuration({
        start: start,
        end: end,
      }).years ?? 0) > 1
    ) {
      setDateError("Dates are futher than 2 years apart");
      return false;
    }
    if (isBefore(pickedButNotSet.start, new Date(installationDate))) {
      setDateError("From date is before installation date");
      return false;
    } else {
      setDateError("");
      return true;
    }
  };

  return (
    <div>
      <div>
        <div className={classes.header}>
          <div style={{ display: "flex", width: "48%" }}>
            <div className={classes.headerLeft}>
              {unit === "metres" ? (
                showTreatedWater ? (
                  <Translate id="components.barChart.title.treatedMetres" />
                ) : (
                  <Translate id="components.barChart.title.consumedMetres" />
                )
              ) : showTreatedWater ? (
                <Translate id="components.barChart.title.treatedLitres" />
              ) : (
                <Translate id="components.barChart.title.consumedLitres" />
              )}
            </div>
            {/* <div style={{ padding: "0 4px" }}>&</div>
            <div className={classes.headerRight}>
              <Translate id="components.barChart.usedSalt" />
            </div> */}
          </div>
          <div style={{ display: "flex", placeContent: "center" }}>
            {loading ? <CircularProgress color="secondary" /> : <div />}
          </div>
          <div
            style={{
              backgroundImage: `url(${CalendarIcon})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              setPickDate(true);
            }}
          />
          <Dialog open={pickDate}>
            <div className={classes.dateDialog}>Select date range</div>
            <div style={{ width: "52%", margin: "5% 24%" }}>
              <TextField
                label="From"
                type="date"
                value={format(pickedButNotSet.start, "yyyy-MM-dd")}
                onChange={(e) => {
                  if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(e.target.value)) {
                    setPickedButNotSet({
                      start: new Date(e.target.value),
                      end: pickedButNotSet.end,
                    });
                    //if end date is after todays date, if start date is before installation date, if dates are more than two years apart
                    validateSelectedDate(
                      new Date(e.target.value),
                      pickedButNotSet.end
                    );
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ width: "52%", margin: "5% 24%" }}>
              <TextField
                label="To"
                type="date"
                value={format(pickedButNotSet.end, "yyyy-MM-dd")}
                onChange={(e) => {
                  if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(e.target.value)) {
                    setPickedButNotSet({
                      start: pickedButNotSet.start,
                      end: removeTimezone(endOfDay(new Date(e.target.value))),
                    });
                    validateSelectedDate(
                      pickedButNotSet.start,
                      removeTimezone(endOfDay(new Date(e.target.value)))
                    );
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ color: "red", textAlign: "center" }}>{dateError}</div>
            <DialogActions style={{ alignSelf: "center", height: "60px" }}>
              <Button
                disabled={dateError !== ""}
                onClick={() => {
                  if (
                    validateSelectedDate(
                      pickedButNotSet.start,
                      pickedButNotSet.end
                    )
                  ) {
                    setDates({
                      start: pickedButNotSet.start,
                      end: subMinutes(
                        endOfDay(pickedButNotSet.end),
                        pickedButNotSet.end.getTimezoneOffset()
                      ),
                    });
                    const newPages = getPages(
                      pickedButNotSet.start,
                      pickedButNotSet.end
                    );
                    setPages(newPages);
                    //find index is failing
                    const currentPage = newPages.findIndex((dates) =>
                      isWithinInterval(pickedButNotSet.start, {
                        start: removeTimezone(startOfDay(dates.start)),
                        end: removeTimezone(endOfDay(dates.until)),
                      })
                    );
                    setPage(currentPage);
                    setPickDate(false);
                  }
                }}
              >
                <Translate id="generics.ok" />
              </Button>
              <Button
                onClick={() => {
                  setPickedButNotSet({ start: dates.start, end: dates.end });
                  setDateError("");
                  setPickDate(false);
                }}
              >
                <Translate id="generics.cancel" />
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <ChartPager
        useLitres={unit === "litres"}
        useSalt={false}
        start={dates.start}
        until={dates.end}
        setDates={setDates}
        page={page}
        pages={pages}
        setPage={setPage}
        water={
          isSilk
            ? silkWaterConsumption
              ? silkWaterConsumption
              : []
            : perlaWaterConsumption ?? []
        }
        salt={saltConsumption ? saltConsumption : []}
        loading={loading}
        unit={unit}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={classes.toggleContainer}>
          <div style={{ fontWeight: 400, fontSize: "18px" }}>L</div>
          <BlueSwitch
            checked={unit === "metres"}
            onChange={() => setUnit(unit === "metres" ? "litres" : "metres")}
          />
          <div style={{ fontWeight: 400, fontSize: "18px" }}>m³</div>
        </div>
        {!isSilk ? (
          <Fragment>
            <div className={classes.toggleContainer}>
              {/* <div style={{ fontWeight: 400, fontSize: "18px" }}>
                <Translate id="components.barChart.water" />
              </div>
              <BlueSwitch
                checked={useSalt}
                onChange={() => setUseSalt(!useSalt)}
              />
              <div style={{ fontWeight: 400, fontSize: "18px" }}>
                <Translate id="components.barChart.salt" />
              </div> */}
            </div>
            <div className={classes.toggleContainer}>
              <div style={{ fontWeight: 400, fontSize: "18px" }}>
                <Translate id="components.barChart.totalWater" />
              </div>
              <BlueSwitch
                checked={showTreatedWater}
                onChange={() => setShowTreatedWater(!showTreatedWater)}
              />
              <div style={{ fontWeight: 400, fontSize: "18px" }}>
                <Translate id="components.barChart.treatedWater" />
              </div>
            </div>
          </Fragment>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default BarChart;
