import gql from "graphql-tag";

const MAIL_QUERY = gql`
query mailTemplateGroup  {
  sendGridTemplates(pageSize: 0) {
    results {
      name
    }
  }
}
`;

export default MAIL_QUERY;
