import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import gql from "graphql-tag";
import { useMutation } from '@apollo/react-hooks';

import cloneDeep from 'clone-deep';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';

import { AppState } from 'redux/store';

import { Store as ChangePositionStore } from 'redux/types/AccountShortcuts/ChangePosition';
import { close } from 'redux/actions/AccountShortcuts/ChangePositionActions';

import { Translate } from "react-localize-redux";

import Error from 'components/common/Error';
import { ErrorCodes } from 'types/CampGuideTypes';

import { QUERY_PARTNER_EMPLOYEES } from 'commonQueries/partner';
import { OrganizationDetail } from 'types/apolloGenerated/OrganizationDetail';
import { ChangePosition, ChangePositionVariables } from 'types/apolloGenerated/ChangePosition';
import { PartnerEmployees } from 'types/apolloGenerated/PartnerEmployees';


const CHANGE_POSITION = gql`
mutation ChangePosition($userId: Int!, $partnerId: Int!, $position: Position!){
  organization{
    deleteEmployee(organizationId:  $partnerId, userId: $userId)
    createEmployee(employeeInsert:
        {
          organizationId: $partnerId,
          userId: $userId,
          position: $position
        }
    )
  }
}
`;


type OrganizationDetailWrapper = {
  organization: OrganizationDetail
}

interface ChangePositionProps {
  changePosition: ChangePositionStore
  actions: {
    close: typeof close
  }
}


const ChangePositionComponent: React.FC<ChangePositionProps> = ({ changePosition, actions }) => {

  const [mutate, { loading, error }] = useMutation<ChangePosition, ChangePositionVariables>(CHANGE_POSITION, {
    update(cache) {
      try {
        const variables = {
          id: changePosition.changeData?.partnerId
        };

        const queryCache = cloneDeep(cache.readQuery<PartnerEmployees>({
          query: QUERY_PARTNER_EMPLOYEES,
          variables: variables,
        }));

        if (queryCache?.organization?.employees?.results) {
          queryCache.organization.employees.results = queryCache.organization.employees.results.map((item) => {
            if (item && item?.userInformation?.key === changePosition.changeData?.userId) {
              item.position = changePosition?.changeData?.position || null;
            }
            return item;
          });

          cache.writeQuery({
            query: QUERY_PARTNER_EMPLOYEES,
            variables: {
              id: changePosition.changeData?.partnerId
            },
            data: queryCache
          });
        } else {
          console.log('Trying to update CACHE, but emplyees not found');
        }
      } catch (e) { }

      if (!error) {
        actions.close();
      }
    }
  });


  const submit = () => {
    if (changePosition.changeData) {

      mutate({
        variables: {
          userId: changePosition.changeData.userId,
          partnerId: changePosition.changeData.partnerId,
          position: changePosition.changeData.position
        }
      }).catch((errorData) => {
        console.log('errorData', errorData);
      });

    }
  };

  const errorElement = error ? <Error error={{ code: ErrorCodes.Unknown, message: error.message }} /> : null;
  const progress = loading ? <CircularProgress color="secondary" /> : null;

  return (
    <Dialog open={Boolean(changePosition.changeData)} onClose={actions.close} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="form-dialog-title"><Translate id="components.changePosition.title" /></DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Translate id="components.changePosition.body1" />
          <Translate id={`types.positions.${changePosition.changeData?.position.toLocaleLowerCase()}`} />
          <Translate id="components.changePosition.body2" />
        </DialogContentText>
        {errorElement}
      </DialogContent>
      <DialogActions>
        {progress}
        <Button onClick={actions.close} color="primary" disabled={loading}>
          <Translate id="generics.cancel" />
        </Button>
        <Button onClick={submit} color="primary" disabled={loading}>
          <Translate id="generics.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: AppState) => ({
  changePosition: state.accountShortcuts.changePosition,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ close }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePositionComponent);
