import React, { Fragment } from 'react';
import { TranslateFunction } from 'react-localize-redux';

import gql from "graphql-tag";
import { ProductRegistrations, ProductRegistrations_organization_productInstances_results } from "types/apolloGenerated/ProductRegistrations";


import { ColDef } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

import { ability } from 'util/ability';

import { Link } from "react-router-dom";

import { factoryApolloConnector } from 'util/AgGridUtils';

import format from "date-fns/format";
import { Translate } from "react-localize-redux";


const QUERY = gql`
query ProductRegistrations($id: Int!) {
  organization (id:$id) {
    id
    productInstances(page: 0, pageSize: 100){
      page
      pageSize
      results{
        productCode
        partnerRegistrationDate
        registrationDate
        product {
          productInformation {
            name
          }
        }
        owner{
          userInformation{
            firstName
            lastName
          }
        }
        address{
          postalCode
          city
        }
        installationDate
        state
      }
    }
  }
}
`;

interface ValueGetter {
    value: string;
    data: ProductRegistrations_organization_productInstances_results;
}

export const apolloConnectorById = (id: number) => {
  return factoryApolloConnector(
    QUERY,
    (data: ProductRegistrations) => data.organization ? data.organization.productInstances : null,
    {},
    { customArguments: { id: id } }
  )
};


export const getColumnDefs = (translate: TranslateFunction): ColDef[] => {
  const removeFilterSort = {
    sortable: false,
    filter: false,
    filterParams: {},
    menuTabs: []
  }

  const columnDefs = [
    {
      headerName: translate('types.product.productName') as string,
      field: "product.productInformation.name",
      width: 200,
      ...removeFilterSort
    },
    {
      headerName: translate('types.common.productCode') as string,
      field: "productCode",
      width: 120,
      cellRenderer: ability.can('read', 'Customer') ? "ProductLink" : "",
      ...removeFilterSort
    },
    {
      headerName: translate('types.product.partnerRegistrationDate') as string,
      field: "partnerRegistrationDate",
      cellRenderer: "DateCell",
      width: 140,
      ...removeFilterSort
    },
    {
      headerName: translate("types.common.name") as string,
      field: "owner.userInformation.firstName",
      valueGetter: (params: any) => {
          return FullNameGetter({ data: params.data, value: params.value })
              ?.props.children;
      },
      width: 160,
      ...removeFilterSort
    },
    {
      headerName: translate('types.address.postalCode') as string,
      field: "address.postalCode",
      width: 100,
      headerTooltip: translate('types.tooltips.deviceAddress') as string,
      ...removeFilterSort
    },
    {
      headerName: translate('types.address.city') as string,
      field: "address.city",
      width: 125,
      headerTooltip: translate('types.tooltips.deviceAddress') as string,
      ...removeFilterSort
    },
    {
      headerName: translate('types.common.installationDate') as string,
      field: "installationDate",
      cellRenderer: "DateCell",
      width: 140,
      ...removeFilterSort
    },
    {
      headerName: translate("types.common.state") as string,
      field: "state",
      width: 125,
      cellRenderer: "StateCell",
      ...removeFilterSort
    },
    {
      headerName: translate("types.product.customerRegistrationDate") as string,
      field: "registrationDate",
      cellRenderer: "DateCell",
      width: 140,
      ...removeFilterSort
    }

  ];

  return columnDefs;
};

const FullNameGetter = ({ data, value }: ValueGetter) => {
    if (data?.owner?.userInformation) {
        const name = data.owner?.userInformation?.firstName + " " + data.owner?.userInformation?.lastName
        return <div>{name}</div>;
    }
    return <div />;
};

const StateCell: React.FC<CellRenderer> = ({ data, value }) => {
  if (value === 'NOT_SET') {
    return <Fragment />;
  }
  return <Fragment>{value}</Fragment>;
};

const DateCell: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data || !value) {
    return <div />;
  }

  //return <div>{format(new Date(value), "P")}</div>;
  return (<Translate>
    {
      (translate) => {
        return <div>{format(new Date(value), translate.translate('generics.dateTimeFormatFNS') as string)}</div>;
      }
    }
  </Translate>
  );
};

const ProductLink: React.FC<CellRenderer> = ({ data, value }) => {
  if (!data) {
    return <div />;
  }
  return <Link to={"/cs/device/" + value}>{value}</Link>;
};

interface CellRenderer {
  value: string,
  data: ProductRegistrations_organization_productInstances_results | null,
};

export const CellRenderer = { DateCell, ProductLink, StateCell };

export const ValueGetter = {
    FullNameGetter
};
