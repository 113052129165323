import gql from "graphql-tag";

export const GET_SENDGRID_USERS = gql`
  query getSendgridUsers {
    sendGridUsers {
      id
      name
    }
  }
`;
