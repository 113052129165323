import { ActionType, Store, Action } from 'redux/types/AccountShortcuts/ChangePearls';

import initialState from 'redux/initialState';

export default function changePearlsReducer(store: Store = initialState.accountShortcuts.changePearls, action: Action): Store {
  switch (action.type) {

    case ActionType.OPEN:
      return {
        isLocked: false,
        pearlsObj: action.payload,
        error: null
      };

    case ActionType.CLOSE:
      return {
        isLocked: false,
        pearlsObj: null,
        error: null
      };

    default:
      return store;
  }
}
