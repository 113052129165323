import React, { Fragment, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Translate } from "react-localize-redux";
import Typography from "@material-ui/core/Typography";
import { Box, TextField } from "@material-ui/core";

import { isWithinInterval, subHours, parseISO } from "date-fns";

import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  ErrorOutline,
} from "@material-ui/icons";

import CircleSegment from "./CircleSegment";

import { productInstanceAndCustomerDetails_productInstance_instanceInformation_SilkInstanceInformation_notifications as SilkNotifArray } from "types/apolloGenerated/productInstanceAndCustomerDetails";

import { productInstanceAndCustomerDetails_productInstance_instanceInformation_SilkInstanceInformation as SilkInstanceInformation } from "../../../types/apolloGenerated/productInstanceAndCustomerDetails";
import { NotificationType } from "types/apolloGenerated/globalTypes";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    arrow: { fontSize: 16, margin: 10 },
    textField: {
      [`& input::-webkit-clear-button,
      & input::-webkit-outer-spin-button,
      & input::-webkit-inner-spin-button,
      & input::-webkit-toolbarbutton-dropdown`]: {
        display: "none",
      },
    },
    vertAlign: {
      verticalAlign: "middle",
    },
    icon: {
      marginRight: "10px",
      verticalAlign: "top",
    },
    bigNumbers: {
      "@media (max-width: 1549px)": {
        fontSize: "35px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "45px",
      },
      marginTop: "18%",
      height: "22%",
      fontSize: "55px",
      width: "30%",
      textAlign: "center",
      marginBottom: "5%",
    },
    measurement: {
      "@media (max-width: 1549px)": {
        fontSize: "15px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "18px",
      },
      height: "8%",
      fontSize: "20px",
      width: "30%",
      textAlign: "center",
    },
    measurementText: {
      "@media (max-width: 1549px)": {
        fontSize: "15px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "18px",
      },
      fontSize: "20px",
      width: "30%",
      textAlign: "center",
      height: "40%",
    },
    bottomInformationTitle: {
      "@media (max-width: 1549px)": {
        fontSize: "13px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "15px",
      },
      fontWeight: "bold",
      marginRight: "16px",
      fontSize: "17px",
    },
    bottomInformation: {
      "@media (max-width: 1549px)": {
        fontSize: "13px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "15px",
      },
      fontSize: "17px",
    },
    circleInformation: {
      position: "absolute",
      top: "7.5%",
      left: "7.9%",
      width: "84.4%",
      height: "82.9%",
      borderColor: "#f8f8f8",
      borderRadius: "50%",
      borderWidth: "0.6vw",
      borderStyle: "solid",
      display: "table",
    },
    perlaDivider: {
      margin: "5% 0 5% 35%",
      backgroundColor: "rgb(137, 158, 181)",
      width: "30%",
      height: "2px",
    },
    statsContainer: {
      display: "flex",
      width: "calc(100%/3)",
    },
    middleContainer: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      height: "80%",
    },
    divider: {
      width: "1px",
      backgroundColor: "#666B6E",
      margin: "5% 0 5% 0",
    },
    singleCircleContainer: {
      width: "30%",
      margin: "3%",
      position: "relative",
    },
    circlePercentageText: {
      textAlign: "center",
      fontWeight: "bold",
      "@media (max-width: 1549px)": {
        fontSize: "24px",
      },
      "@media (min-width: 1550px) and (max-width: 1850px)": {
        fontSize: "34px",
      },
      fontSize: "40px",
    },
  });
});

const GetConvertedHardness = (waterHardness: number, hardnessUnit: number) => {
  switch (hardnessUnit) {
    //1 ppm is 1 mg/l
    case 4:
      return waterHardness.toFixed(2).toString() + " mg/l";
    //1 ppm is 0.1 °F
    case 5:
      return (waterHardness * 0.1).toFixed(2).toString() + " °F";
    //1 ppm is 0.056 °D
    case 6:
      return (waterHardness * 0.056).toFixed(2).toString() + " °D";
    default:
      return "0";
  }
};

const SilkSummary: React.FC<{ silkData: SilkInstanceInformation | null }> = ({
  silkData,
}) => {
  const classes = useStyles();
  const [indexToShow, setIndexToShow] = useState<number>(0);

  //get all summary messages
  const summaryType = 1001;
  let silkSummaryNotifs: (SilkNotifArray | null)[] = [];
  if (silkData && silkData?.notifications) {
    silkSummaryNotifs = silkData?.notifications.filter(
      (notif) => notif?.messageType === summaryType
    );
  }

  //get all telemetry messages
  const telemetryType = 1002;
  let silkTelemetryNotifs: (SilkNotifArray | null)[] = [];
  if (silkData && silkData?.notifications) {
    silkTelemetryNotifs = silkData?.notifications.filter(
      (notif) => notif?.messageType === telemetryType
    );
  }

  //parse JSON blob of summary obj to show
  let summaryData;
  let numberOfSummaryResults = 0;
  if (silkSummaryNotifs && silkSummaryNotifs[indexToShow]?.message) {
    numberOfSummaryResults = silkSummaryNotifs.length;
    summaryData = JSON.parse(silkSummaryNotifs[indexToShow]?.message ?? "{}");
  }

  //find matching remaining salt value from telemetry data
  const summaryTimestamp = summaryData?.Timestamp as String
  const matchingTelemetry = silkTelemetryNotifs.find((notif) => {
      if (notif?.message) {
          const data = JSON.parse(notif.message)
          return data.Timestamp.slice(0, 10) === summaryTimestamp?.slice(0, 10)
      }
  })

  const telemetryData = JSON.parse(matchingTelemetry?.message ?? "{}");

  if (!summaryData) {
    return (
      <div>
        <Translate id="message.error.noSilkSummary" />
      </div>
    );
  }

  const timeStamp = summaryData?.Timestamp;
  const totalDays = summaryData?.Data?.daysInServ;
  const totalL = summaryData?.Data?.totalServWater;
  const totalRech = summaryData?.Data?.totalRecharg;
  const waterHardness = summaryData?.Data?.hardness;
  const hardnessUnit = summaryData?.Data?.waterHardUnit;
  const currentSalt =
    telemetryData?.Data?.remSalt === 0 ? 0 : telemetryData?.Data?.remSalt / 10;
  const maxSalt = silkData?.silkDetailsInstance?.maxSaltCapacityKg ?? 0;
  const availableResource = maxSalt === 0 ? 0 : (currentSalt / maxSalt) * 100;
  const isDisconnected = silkData?.state;

  const showError = () => {
    return isDisconnected === NotificationType.NOT_SYNCHED ? (
      <div
        style={{ color: "rgb(201,51,54)", margin: "10px 0px", display: "flex" }}
      >
        <ErrorOutline style={{ height: "20px " }} />
        <div style={{ marginLeft: "5px" }}>
          <Translate id="components.deviceSummary.disconnected" />
        </div>
      </div>
    ) : indexToShow === 0 &&
      !isWithinInterval(parseISO(timeStamp), {
        start: subHours(new Date(), 48),
        end: new Date(),
      }) ? (
      <div
        style={{
          color: "rgb(201,51,54)",
          margin: "10px 0px",
          display: "flex",
        }}
      >
        <ErrorOutline style={{ height: "20px " }} />
        <div style={{ marginLeft: "5px" }}>
          <Translate id="components.deviceSummary.outOfDate" />
        </div>
      </div>
    ) : (
      <Fragment />
    );
  };

  return (
    <Box>
      <Box display="flex">
        <Box width="36%">
          <Typography variant="h6" component="h3">
            <Translate id="components.deviceSummary.title" />
          </Typography>
        </Box>
        <Box width="64%">
          <Box display="flex" justifyContent="center">
            {
              <ArrowBackIosRounded
                className={classes.arrow}
                color={
                  indexToShow < numberOfSummaryResults - 1
                    ? "action"
                    : "disabled"
                }
                onClick={() => {
                  if (indexToShow < numberOfSummaryResults - 1) {
                    setIndexToShow(indexToShow + 1);
                  }
                }}
              />
            }
            <TextField
              className={classes.textField}
              type="datetime-local"
              value={timeStamp ? timeStamp.substr(0, 16) : "-"}
            />
            {
              <ArrowForwardIosRounded
                className={classes.arrow}
                color={indexToShow > 0 ? "action" : "disabled"}
                onClick={() => {
                  if (indexToShow > 0) {
                    setIndexToShow(indexToShow - 1);
                  }
                }}
              />
            }
          </Box>
        </Box>
      </Box>
      {showError()}
      <Fragment>
        <Box display="flex">
          <Box className={classes.singleCircleContainer}>
            <CircleSegment value={availableResource}></CircleSegment>

            <Box className={classes.circleInformation}>
              <Box display="table-cell" className={classes.vertAlign}>
                <Box textAlign="center" fontSize="100%">
                  <Translate id="components.silk.saltLevel" />
                </Box>
                <Box className={classes.circlePercentageText}>
                  {availableResource.toFixed(0).toString()}%
                </Box>
                <Box className={classes.perlaDivider} />
                <Box textAlign="center" fontSize="100%">
                  {totalDays ? totalDays : "0"}
                  <Box component="span" m={0.3} />
                  <Translate id="components.deviceSummary.days" />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.divider} />
          <Box width="60%" display="flex" flexWrap="wrap">
            <Box className={classes.bigNumbers} marginLeft="9%">
              {totalDays ? totalDays.toFixed(0) : "0"}
            </Box>
            <Box className={classes.bigNumbers}>
              {totalL ? (totalL * 100).toFixed(0) : "0"}
            </Box>
            <Box className={classes.bigNumbers}>
              {totalRech ? totalRech.toFixed(0) : "0"}
            </Box>
            <Box className={classes.measurement} marginLeft="9%">
              <Translate id="components.silk.totalDaysInService" />
            </Box>
            <Box className={classes.measurement}>
              <Translate id="components.silk.totalWaterServed" />
            </Box>
            <Box className={classes.measurement}>
              <Translate id="components.silk.totalRecharges" />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="flex" width="40%">
            <Box className={classes.bottomInformationTitle}>
              <Translate id="components.silk.hardness" />
            </Box>
            <Box className={classes.bottomInformation}>
              {GetConvertedHardness(waterHardness ?? 0, hardnessUnit)}
            </Box>
            <Box
              className={classes.bottomInformation}
              style={{ marginLeft: "20px" }}
            >
              {waterHardness.toString() + "ppm"}
            </Box>
          </Box>
        </Box>
      </Fragment>
    </Box>
  );
};

export default SilkSummary;
