import React, { useRef } from "react";
import {
  TextField,
  createStyles,
  Theme,
  Button,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import {
  OrganizationCreationRequest,
  OrganizationCreationRequestVariables,
  OrganizationCreationRequest_organizationCreationRequest,
} from "types/apolloGenerated/OrganizationCreationRequest";
import {
  OrganizationCreationApprovalMutation,
  OrganizationCreationApprovalMutationVariables,
} from "types/apolloGenerated/OrganizationCreationApprovalMutation";
import { OrganizationJoinRequestInput } from "types/apolloGenerated/globalTypes";
import { useQuery, useMutation } from "react-apollo";
import cloneDeep from "clone-deep";
import gql from "graphql-tag";
import { CircularProgress } from "@material-ui/core";
import Error, { CustomError } from "components/common/Error";
import { useHistory } from "react-router";
import { qraphQlErrorsToString } from "util/GraphQL";
import format from "date-fns/format";

import { Translate } from "react-localize-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      marginBottom: "50px",
      paddingTop: "15px",
      "& .MuiTextField-root, & .MuiFormControl-root": {
        width: "100%",
        "& .MuiInputLabel-outlined": {
          transform: "translate(14px, 15px) scale(1)",
          fontSize: "0.9rem",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
      "& .MuiGrid-grid-xs-6 .MuiGrid-item:not(:first-child):not(:last-child)": {
        padding: "0px 10px",
      },
      "& .Mui-disabled": {
        cursor: "not-allowed",
      },
    },
    button: {
      width: "100%",
    },
    loadingSymbol: {
      marginTop: "40px",
      marginLeft: "50%",
    },
    errorLink: {
      color: "#ffffff!important",
      textDecoration: "none",
      "&:hover": {
        color: "#cccccc!important",
        textDecoration: "underline",
      },
    },
    partnerOverview: {
      display: "flex",
      flexFlow: "column",

      "& > .gridFilterHeader": {
        flex: "0 1 auto",
      },

      "& > .innerContent": {
        flex: "1 1 100%",
        height: "100%",
      },
    },
  })
);

interface ApprovalFormProps {
  requestId: string;
}

export const QUERY_ORGANIZATION_CREATION_REQUEST = gql`
  query OrganizationCreationRequest($requestId: Guid!) {
    organizationCreationRequest(requestId: $requestId) {
      city
      countryCode
      id
      organizationName
      postalCode
      completionTimestamp
      street
      user {
        id
        key
        email
        firstName
        telephone
        lastName
        employee {
          position
          organizationId
        }
      }
      vatNumber
      website
    }
  }
`;

const MUTATE_ORGANIZATION_CREATION_APPROVAL = gql`
  mutation OrganizationCreationApprovalMutation(
    $approved: Boolean!
    $creationRequestUpdate: OrganizationJoinRequestInput!
  ) {
    organization {
      organizationCreationRequestApproval(
        approved: $approved
        creationRequestUpdate: $creationRequestUpdate
      )
    }
  }
`;

const ApprovalForm: React.FC<ApprovalFormProps> = ({ requestId }) => {
  const classes = useStyles();
  const history = useHistory();

  const variant: "standard" | "outlined" | "filled" = "standard";
  const autoComplete = "off";

  const {
    data: organizationData,
    loading: queryLoading,
    error: queryError,
  } = useQuery<
    OrganizationCreationRequest,
    OrganizationCreationRequestVariables
  >(QUERY_ORGANIZATION_CREATION_REQUEST, {
    variables: { requestId: requestId },
  });

  const organizationRef = useRef<
    OrganizationCreationRequest_organizationCreationRequest | undefined
  >();
  const [
    mutateOrganizationApproval,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation<
    OrganizationCreationApprovalMutation,
    OrganizationCreationApprovalMutationVariables
  >(MUTATE_ORGANIZATION_CREATION_APPROVAL, {
    onCompleted: (data) => {},
    refetchQueries: [
      {
        query: QUERY_ORGANIZATION_CREATION_REQUEST,
        variables: { requestId: requestId },
      },
    ],
    onError: (error) => {},
  });

  if (typeof organizationRef.current == "undefined") {
    organizationRef.current =
      cloneDeep(organizationData?.organizationCreationRequest) ?? undefined;
  }

  const onChangeFunc = (value: string, name: string) => {
    let tmpObj: any = {
      ...organizationRef.current,
      [name]: value,
    };
    organizationRef.current = tmpObj;
  };

  const approveOrganization = (approved: boolean) => {
    let update: OrganizationJoinRequestInput = {
      id: requestId,
    };

    if (approved) {
      update = {
        ...update,
        organizationName: organizationRef.current?.organizationName,
        street: organizationRef.current?.street,
        postalCode: organizationRef.current?.postalCode,
        city: organizationRef.current?.city,
        countryCode: organizationRef.current?.countryCode,
        vatNumber: organizationRef.current?.vatNumber,
        website: organizationRef.current?.website,
      };
    }

    mutateOrganizationApproval({
      variables: {
        approved: approved,
        creationRequestUpdate: update,
      },
    }).catch((error) => {
      console.log(
        "Error while approving/declining organization request: ",
        error
      );
    });
  };

  if (queryLoading) {
    return <CircularProgress color="secondary" />;
  }

  if (queryError) {
    switch (queryError.graphQLErrors[0].extensions?.["code"]) {
      case "FORMAT":
        return <Translate id={"pages.partnerRequest.formatError"} />;
      default:
        return <Translate id={"pages.partnerRequest.error"} />;
    }
  }

  if (mutationData?.organization?.organizationCreationRequestApproval) {
    history.push(
      "/pam/partner/" +
        mutationData.organization.organizationCreationRequestApproval
    );
  }

  const error = organizationRef.current?.user?.employee?.organizationId ? (
    <CustomError>
      <Translate
        id="pages.partnerRequest.userAlreadyAssigned"
        data={{
          link: (
            <Link
              className={classes.errorLink}
              to={
                "/pam/partner/" +
                organizationRef.current?.user?.employee?.organizationId
              }
            >
              Click Here
            </Link>
          ),
        }}
      />
    </CustomError>
  ) : null;

  const mutationErrorElement = mutationError ? (
    <Translate>
      {({ translate }) => (
        <Error
          error={
            (translate("pages.partnerRequest.mutationError") as string) +
            " " +
            qraphQlErrorsToString(mutationError.graphQLErrors, translate)
          }
        />
      )}
    </Translate>
  ) : null;

  const alreadyHandled = !error &&
    organizationData?.organizationCreationRequest?.completionTimestamp && (
      <Translate>
        {({ translate }) => (
          <Error
            error={
              (translate(
                "pages.partnerRequest.alreadyHandledRequest"
              ) as string) +
              " " +
              format(
                new Date(
                  organizationData?.organizationCreationRequest?.completionTimestamp
                ),
                translate("generics.dateTimeFormatFNS") as string
              )
            }
          />
        )}
      </Translate>
    );

  const jxGrid =
    error || alreadyHandled ? null : mutationLoading ? (
      <Grid item>
        <CircularProgress className={classes.loadingSymbol} color="secondary" />
      </Grid>
    ) : (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="outlined"
            className={classes.button}
            disabled={true}
            onClick={() => {
              //approveOrganization(true);
            }}
          >
            Approve
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="outlined"
            className={classes.button}
            disabled={true}
            onClick={() => {
              //approveOrganization(false);
            }}
          >
            Decline
          </Button>
        </Grid>
      </Grid>
    );

  return (
    <Translate>
      {({ translate }) => (
        <Grid
          container
          className={classes.container}
          style={{ position: "relative" }}
        >
          <Grid item xs={8}>
            {mutationErrorElement}
            {alreadyHandled}
            {error}
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  defaultValue={requestId}
                  label={<Translate id="pages.partnerRequest.requestId" />}
                  variant={variant}
                  autoComplete={autoComplete}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={
                    organizationRef?.current?.organizationName ?? ""
                  }
                  label={
                    <Translate id="pages.partnerRequest.organizationName" />
                  }
                  variant={variant}
                  autoComplete={autoComplete}
                  name="organizationName"
                  onChange={(evt) => {
                    onChangeFunc(evt.target.value, evt.target.name);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  defaultValue={organizationRef?.current?.street ?? ""}
                  label={<Translate id="types.address.street" />}
                  variant={variant}
                  autoComplete={autoComplete}
                  name="street"
                  onChange={(evt) => {
                    onChangeFunc(evt.target.value, evt.target.name);
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <TextField
                  defaultValue={organizationRef?.current?.postalCode ?? ""}
                  label={<Translate id="types.address.postalCode" />}
                  variant={variant}
                  autoComplete={autoComplete}
                  name="postalCode"
                  onChange={(evt) => {
                    onChangeFunc(evt.target.value, evt.target.name);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  defaultValue={organizationRef?.current?.city ?? ""}
                  label={<Translate id="types.address.city" />}
                  variant={variant}
                  autoComplete={autoComplete}
                  name="city"
                  onChange={(evt) => {
                    onChangeFunc(evt.target.value, evt.target.name);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  defaultValue={organizationRef?.current?.countryCode ?? ""}
                  label={<Translate id="types.address.countryCode" />}
                  variant={variant}
                  autoComplete={autoComplete}
                  name="countryCode"
                  onChange={(evt) => {
                    onChangeFunc(evt.target.value, evt.target.name);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  defaultValue={organizationRef?.current?.vatNumber ?? ""}
                  label={<Translate id="types.common.vatNumber" />}
                  variant={variant}
                  autoComplete={autoComplete}
                  name="vatNumber"
                  onChange={(evt) => {
                    onChangeFunc(evt.target.value, evt.target.name);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={organizationRef?.current?.website ?? ""}
                  label={<Translate id="pages.partnerRequest.website" />}
                  variant={variant}
                  autoComplete={autoComplete}
                  name="website"
                  onChange={(evt) => {
                    onChangeFunc(evt.target.value, evt.target.name);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  defaultValue={organizationRef?.current?.user?.firstName ?? ""}
                  label={<Translate id="types.userInformation.firstName" />}
                  variant={variant}
                  autoComplete={autoComplete}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={organizationRef?.current?.user?.lastName ?? ""}
                  label={<Translate id="types.userInformation.lastName" />}
                  variant={variant}
                  autoComplete={autoComplete}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  defaultValue={organizationRef?.current?.user?.email ?? ""}
                  label={<Translate id="generics.email" />}
                  variant={variant}
                  autoComplete={autoComplete}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={organizationRef?.current?.user?.telephone ?? ""}
                  label={<Translate id="types.userInformation.telephone" />}
                  variant={variant}
                  autoComplete={autoComplete}
                  disabled={true}
                />
              </Grid>
              {jxGrid}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Translate>
  );
};

export default ApprovalForm;
