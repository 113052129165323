import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import CircularProgress from "@material-ui/core/CircularProgress";

import PageHeader from "components/common/PageHeader";
import PartnerRegistration from "./PartnerRegistration";
import CustomerRegistration from "./CustomerRegistration";
import Consumable from "./Consumable";
import NotificationGroups from "./Notifications/NotificationGroups";
import ProductInfo from "./ProductInfo";
import {
  ProductUpdateInput,
  ProductType,
} from "../../../types/apolloGenerated/globalTypes";
import { Translate, withLocalize } from "react-localize-redux";
import { LocalizeContextProps } from "react-localize-redux";
import {
  productById as QueryData,
  productByIdVariables as QueryVars,
  productById_product_productInformation,
} from "types/apolloGenerated/productById";
import { getProductArticleNumbers as ProductArticleNumber } from "types/apolloGenerated/getProductArticleNumbers";
import PageHeaderMessage from "components/common/PageHeaderMessage";

import { productGroupList } from "types/apolloGenerated/productGroupList";
import { deviceVersionList } from "types/apolloGenerated/deviceVersionList";
import { filterSparseArray } from "util/CampUtils";
import { PromotionIdsAndNames_promotions_results as PromotionsData } from "types/apolloGenerated/PromotionIdsAndNames";

import { QUERY_PRODUCT_BY_ID } from "commonQueries/product";
import { Box, makeStyles, createStyles, Theme } from "@material-ui/core";
import { ApolloError } from "apollo-client";
import AuditLog from "../../Customer/InstanceDetails/AuditLog";
import USER_ROLES from "../../../commonQueries/userRole";
import store from "../../../redux/store";
import * as Types from "../../../types/ToolboxEntities";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    outerContainer: {
      margin: "20px 30px 50px 30px",
      maxWidth: "1615px",
    },
  });
});

const PRODUCT_GROUP_LIST = gql`
  query productGroupList {
    productGroups {
      results {
        id
        displayName
      }
    }
  }
`;

const DEVICE_VERSION_LIST = gql`
  query deviceVersionList {
    deviceVersions {
      results {
        id
        displayName
        version
        type {
          displayName
          name
          isExternallyManaged
        }
      }
    }
  }
`;

const PRODUCT_ARTICLE_NUMBER = gql`
  query getProductArticleNumbers($page: Int, $pageSize: Int) {
    products(page: $page, pageSize: $pageSize) {
      results {
        productInformation {
          itemNumber
        }
      }
    }
  }
`;

//ProductUpdateInput is the data that is mutating and the other information is what we get back after the mutation
const UPDATEPRODUCT = gql`
  mutation updateProductInformation($productInfo: ProductUpdateInput!) {
    product {
      update(product: $productInfo) {
        productInformation {
          gTIN
          id
          isCodelessProductRegistrationEnabled
          isProductRegistrationEnabled
          registrationMailTemplateName
          invoiceUploadAllowed
          isArchived
          itemNumber
          businessUnit
          deviceType
          deviceVersion
          bonus
          name
          displayName
          productType
          productOwnerEmail
          registrationBonus {
            bonusId
          }
          productGroup {
            id
          }
          serviceFeePearls
        }
      }
    }
  }
`;

const PROMOTIONS = gql`
  query PromotionIdsAndNames($page: Int, $pageSize: Int) {
    promotions(page: $page, pageSize: $pageSize) {
      results {
        id
        name
      }
    }
  }
`;

interface ProductDetailViewProps extends LocalizeContextProps {
  promotionsData: PromotionsData[];
  queryData: productById_product_productInformation | null;
  id: string;
  groupData: productGroupList | undefined;
  versionData: deviceVersionList | undefined;
}

const ProductDetailView: React.FC<ProductDetailViewProps> = ({ translate }) => {
  const { id = "" } = useParams<{ id: string }>();

  //get query for product
  const { data, loading, error: productError } = useQuery<QueryData, QueryVars>(
    QUERY_PRODUCT_BY_ID,
    {
      variables: { id: parseInt(id) },
      onCompleted: (data) => {
        const prodInformation = data.product?.productInformation;
        const product: ProductUpdateInput = {
          id: prodInformation?.id ?? 0,
          bonus: prodInformation?.bonus ?? 0,
          name: prodInformation?.name ?? "",
          displayName: prodInformation?.displayName ?? "",
          itemNumber: prodInformation?.itemNumber ?? "",
          groupId: prodInformation?.productGroup?.id ?? null,
          productType: prodInformation?.productType ?? ProductType.DEFAULT,
          businessUnit: prodInformation?.businessUnit ?? "",
          deviceType: prodInformation?.deviceType ?? "",
          deviceVersion: prodInformation?.deviceVersion ?? "",
          gTIN: prodInformation?.gTIN ?? "",
          isProductRegistrationEnabled:
            id === "NEW" ||
            (prodInformation?.isProductRegistrationEnabled ?? false),
          isCodelessProductRegistrationEnabled:
            prodInformation?.isCodelessProductRegistrationEnabled ?? false,
          invoiceUploadAllowed: prodInformation?.invoiceUploadAllowed ?? false,
          isArchived: prodInformation?.isArchived ?? false,
          registrationMailTemplateName:
            prodInformation?.registrationMailTemplateName ?? "",
          serviceFeePearls: prodInformation?.serviceFeePearls,
          registrationBonus: {
            bonusId: prodInformation?.registrationBonus?.bonusId,
          },
          productOwnerEmail: prodInformation?.productOwnerEmail ?? "",
        };
        setProdDetails(product);
      },
    }
  );

  //get promotions data
  const { data: promotionsData, loading: promotionsLoading } = useQuery(
    PROMOTIONS,
    {
      variables: {
        page: 0,
        pageSize: 200,
      },
    }
  );

  //get product list
  const { data: groupData } = useQuery<productGroupList>(PRODUCT_GROUP_LIST);

  //get device version list
  const { data: versionData } = useQuery<deviceVersionList>(
    DEVICE_VERSION_LIST
  );

  const classes = useStyles();

  const { data: articleNumberData, loading: articleNumberLoading } = useQuery(
    PRODUCT_ARTICLE_NUMBER,
    {
      variables: {
        page: 0,
        pageSize: 4000,
      },
    }
    );

  const [articleNumberWarning, setArticleNumberWarning] = React.useState({
    error: false,
    message: "",
  });
  const productArticleNumbers: ProductArticleNumber = articleNumberData;
  //assign product info
  const queryData = data?.product?.productInformation ?? null;

  const history = useHistory();

  const allowEditArtNr = !(queryData?.itemNumber && id !== "NEW");

  const productInformation: ProductUpdateInput = {
    id: queryData?.id ?? 0,
    bonus: queryData?.bonus ?? 0,
    name: queryData?.name ?? "",
    displayName: queryData?.displayName ?? "",
    itemNumber: queryData?.itemNumber ?? "",
    groupId: queryData?.productGroup?.id ?? null,
    productType: queryData?.productType ?? ProductType.DEFAULT,
    businessUnit: queryData?.businessUnit ?? "",
    deviceType: queryData?.deviceType ?? "",
    deviceVersion: queryData?.deviceVersion ?? "",
    gTIN: queryData?.gTIN ?? "",
    isProductRegistrationEnabled:
      id === "NEW" || (queryData?.isProductRegistrationEnabled ?? false),
    isCodelessProductRegistrationEnabled:
      queryData?.isCodelessProductRegistrationEnabled ?? false,
    invoiceUploadAllowed: queryData?.invoiceUploadAllowed ?? false,
    isArchived: queryData?.isArchived ?? false,
    registrationMailTemplateName: queryData?.registrationMailTemplateName ?? "",
    serviceFeePearls: queryData?.serviceFeePearls,
    registrationBonus: {
      bonusId: queryData?.registrationBonus?.bonusId,
    },
    productOwnerEmail: queryData?.productOwnerEmail ?? "",
  };

  const [prodDetails, setProdDetails] = useState<ProductUpdateInput>(
    productInformation
  );

  let pageLoading: boolean = false;

  const title =
    id === "NEW" ? "pages.product.addNewProductTitle" : queryData?.name ?? "";
  let jsxNotifications: any = null;

  if (
    queryData &&
    queryData?.productType !== ProductType.SILK_IOT &&
    queryData?.productType !== ProductType.PERLA_IOT &&
    id !== "NEW"
  ) {
    jsxNotifications = (
      <NotificationGroups
        userActions={filterSparseArray(queryData?.productUserAction)}
        productId={queryData.id}
      />
    );
  }

  const [validation, setValidation] = React.useState<
    { field: string; message: string }[]
  >([]);

  const jsxConsumable = queryData?.id ? (
    <Consumable productInfoObj={queryData} />
  ) : null;

  const validationCheck = () => {
    let validationArr: { field: string; message: string }[] = [];

    if (prodDetails.bonus && prodDetails.bonus.toString().search(/\./) > 0) {
      validationArr.push({
        field: "bonus",
        message: translate("message.error.invalidNumber") as string,
      });
    }

    if (
      prodDetails.serviceFeePearls &&
      prodDetails.serviceFeePearls.toString().search(/\./) > 0
    ) {
      validationArr.push({
        field: "serviceFeePearls",
        message: translate("message.error.invalidNumber") as string,
      });
    }

    setValidation(validationArr);
    if (validationArr.length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  const { data: roleData, loading: roleLoading, error: roleError } = useQuery(
    USER_ROLES
  );

  const roles: string[] = roleData?.user?.userInformation?.userRoles;
  let canViewLogs: boolean = false;

  store.getState().auth.user?.roles.forEach((item: Types.Role) => {
    if (item.roleName === "AuditAdmin") {
      canViewLogs = true;
    }
  });

  if (!roleLoading) {
    if (roles != null && roles.includes("rnd")) {
    }
  }
  if (roleError) {
    canViewLogs = false;
  }

  //if loading show circle spinner
  if (loading || promotionsLoading)
    return <CircularProgress color="secondary" />;

  //if no data and not in a new state
  if (!data && id !== "NEW")
    return (
      <div>
        <div>
          <Translate id="pages.product.noData" />
        </div>
        <div>{productError?.message}</div>
      </div>
    );

  return (
    <div style={{ minHeight: "50px", minWidth: "100px" }}>
      <PageHeader
        title={title}
        showBack
        isSavePending={pageLoading}
      />
      <PageHeaderMessage
        open={
          (!prodDetails.isProductRegistrationEnabled &&
            !prodDetails.isCodelessProductRegistrationEnabled &&
            prodDetails.id !== 0) ||
          prodDetails.isArchived
        }
        title={
          prodDetails.isArchived
            ? (translate("pages.product.detail.hasBeenArchived") as string)
            : (translate("pages.product.detail.registrationDisabled") as string)
        }
      />
      <Box className={classes.outerContainer}>
        <ProductInfo
          deviceType={queryData?.deviceType ?? ""}
          deviceVersion={queryData?.deviceVersion ?? ""}
          productInfoObj={prodDetails}
          setProductInfoObj={setProdDetails}
          productGroup={queryData?.productGroup}
          productGroupData={groupData}
          deviceVersionData={versionData}
          allowEditArtNr={allowEditArtNr}
          articleNumberState={articleNumberWarning}
          lastEdited={queryData?.lastEdited ?? ""}
        />
        <PartnerRegistration
          productInfoObj={prodDetails}
          setProductInfoObj={setProdDetails}
          validation={validation}
        />
        <CustomerRegistration
          promotionsData={promotionsData.results}
          productInfoObj={prodDetails}
          setProductInfoObj={setProdDetails}
          isNew={id === "NEW" ? true : false}
        />
        {jsxConsumable}
        {jsxNotifications}
        {id !== "NEW" ? (
          <div style={{ paddingBottom: "50px" }}>
            <AuditLog
              productCode={null}
              productInstance={null}
              partnerDetail={null}
              canViewLogDetail={canViewLogs}
              customerId={null}
              gridSize={12}
              productId={id}
            />
          </div>
        ) : (
          <div />
        )}
      </Box>
    </div>
  );
};

export default withLocalize(ProductDetailView);
