
import React, { Fragment } from "react";
import { Box, Grid, TextField, Select, MenuItem } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { Translate } from 'react-localize-redux';

import { UserInformationUpdateInput } from "../../../../types/apolloGenerated/globalTypes"
import { Country } from "types/apolloGenerated/globalTypes";
import { UserProfile } from "types/ToolboxEntities";

interface EmployeeInformationProps {
  employeeInfoObj: UserInformationUpdateInput
  setEmployeeInfoObj: React.Dispatch<React.SetStateAction<UserInformationUpdateInput>>;
  user: UserProfile | null;
  validation: { field: string, message: string }[]
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    contentDisplay: {
      display: "flex",
      position: "relative",
      flexWrap: "wrap",
      width: "100%",
      paddingTop: "40px"
    },
    reactiveBoxes: {
      minWidth: "350px",
      width: "92%",
      margin: "0 0 23px 30px",
    },
    reactiveBoxesRight: {
      minWidth: "350px",
      width: "90%",
      margin: "0 0 23px 30px",
    },
    reactiveBoxesSmall: {
      minWidth: "100px",
      width: "70%",
      margin: "0 0 23px 30px",
    },
  });
});

const EmployeeInformation: React.FC<EmployeeInformationProps> = ({ employeeInfoObj, setEmployeeInfoObj, user, validation }) => {

  const classes = useStyles();

  const updateValue = (
    applyValue: (value: string) => UserInformationUpdateInput
  ) => (e: any) => {
    const latestValue = applyValue(e.target.value);
    setEmployeeInfoObj(latestValue);
  };

  const currentCC = employeeInfoObj?.address?.countryCode;
  const allowedCountries = user?.allowedCountries ?? []
  const [country, setCountry] = React.useState<Country | null | undefined>(currentCC);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCountry(event.target.value as Country);
    if (employeeInfoObj.address?.countryCode) {
      employeeInfoObj.address.countryCode = event.target.value as Country
    }
    const latestValue = Object.assign({}, employeeInfoObj)
    setEmployeeInfoObj(latestValue)
  };

  const validationFields = validation.map(value => value.field);

  return(
    <Box className={classes.contentDisplay}>
            <Grid item xs={6}>
              <TextField
                id="firstNameField"
                label={<Translate id="types.userInformation.firstName" />}
                defaultValue={employeeInfoObj.firstName}
                variant="standard"
                className={classes.reactiveBoxes}
                error={validationFields.includes("firstName")}
                helperText={validationFields.includes("firstName") ? validation[validationFields.indexOf("firstName")].message : ""}
                InputProps={{
                  style: { padding: 0 }
                }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="lastNameField"
                label={<Translate id="types.userInformation.lastName" />}
                defaultValue={employeeInfoObj.lastName}
                variant="standard"
                className={classes.reactiveBoxesRight}
                error={validationFields.includes("lastName")}
                helperText={validationFields.includes("lastName") ? validation[validationFields.indexOf("lastName")].message : ""}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="emailField"
                label={<Translate id="generics.email" />}
                variant="standard"
                className={classes.reactiveBoxesRight}
                error={validationFields.includes("email")}
                helperText={validationFields.includes("email") ? validation[validationFields.indexOf("email")].message : ""}
                defaultValue={employeeInfoObj.email}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="phoneField"
                label={<Translate id="types.account.phone" />}
                defaultValue={employeeInfoObj.telephone}
                variant="standard"
                className={classes.reactiveBoxesRight}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="streetField"
                label={<Translate id="types.address.address" />}
                defaultValue={employeeInfoObj?.address?.street}
                variant="standard"
                className={classes.reactiveBoxes}
                disabled={true}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="postalCodeField"
                label={<Translate id="types.address.postalCode" />}
                defaultValue={employeeInfoObj?.address?.postalCode}
                variant="standard"
                className={classes.reactiveBoxesSmall}
                style={{ width: "85%" }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="cityField"
                label={<Translate id="types.address.city" />}
                defaultValue={employeeInfoObj?.address?.city}
                variant="standard"
                className={classes.reactiveBoxesSmall}
                style={{ width: "85%" }}
                disabled={true}
              />
            </Grid>
            <Grid style={{ fontSize: "10px", lineHeight: "12px" }} item xs={2}>
              <Box style={{ marginLeft: "30px", marginBottom: "3px" }}>
                <Translate id="types.address.countryCode" />
              </Box>
              <Translate>{({ translate }) => {
                if (!allowedCountries.length) return Fragment;
                return (
                  <Select
                    value={country}
                    className={classes.reactiveBoxesSmall}
                    onChange={handleChange}
                    disabled={true}
                  >
                    {allowedCountries.map((country: any) => (
                      <MenuItem
                        key={country}
                        value={country}>
                        {translate('countries.' + country.toLocaleLowerCase()) as string}
                      </MenuItem>
                    ))}
                  </Select>
                )
              }}
              </Translate>
            </Grid>
          </Box>
  );
}

const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
});

export default connect(
  mapStateToProps
)(EmployeeInformation);