import React from 'react';

import { Link } from 'react-router-dom';

import { LocalizeState, LocalizeContextProps, withLocalize, TranslateFunction, Translate } from 'react-localize-redux';


import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { AppState } from 'redux/store';
import { State as ProductOverviewState } from 'redux/types/ProductOverview';
import { setGridState, setColumnStates, search } from 'redux/actions/ProductOverviewActions';


import { ReduxAgGrid } from "components/common/Grid/AgGrid";

import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { productGrid } from "types/apolloGenerated/productGrid";
import CampUtils, { filterSparseArray } from "util/CampUtils";

import { ColDef } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { ProductType } from 'types/apolloGenerated/globalTypes';
import { productGrid_products_results as productInfo, productGridVariables } from 'types/apolloGenerated/productGrid';
import CheckIcon from '@material-ui/icons/Check';
import { Fragment } from 'react';
import { CircularProgress } from '@material-ui/core';
import format from "date-fns/format";


export const QUERY_PRODUCTS_GRID = gql`
  query productGrid(
    $page: Int
    $pageSize: Int
    $filter: [ProductFilter]
    $sorting: [SortingCriterionForProductFilterOption]
  ) {
    products(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sorting: $sorting
    ) {
      page
      pageSize
      totalResults
      results {
        productInformation {
          itemNumber
          id
          gTIN
          name
          businessUnit
          productType
          hasPromotions
          hasNotifications
          hasConsumables
          isProductRegistrationEnabled
          bonus
          serviceFeePearls
          lastEdited
        }
      }
    }
  }
`;


const getColumnDefs = (translate: TranslateFunction, products: productInfo[]): ColDef[] => {
  
  const columnDefs = [
    {
      headerName: translate("types.product.itemNumber") as string,
      field: "productInformation.itemNumber",
      filter: "agNumberColumnFilter",
      cellRenderer: "ProductLink",
      width: 100,
    },
    {
      headerName: translate("types.product.gTIN") as string,
      field: "productInformation.gTIN",
      filter: "agNumberColumnFilter",
      cellRenderer: "ProductLink",
      width: 120,
    },
    {
      headerName: translate("types.common.name") as string,
      field: "productInformation.name",
      cellRenderer: "ProductLink",
      width: 180,
    },
    {
      headerName: translate("types.product.businessUnit") as string,
      field: "productInformation.businessUnit",
      cellRenderer: "ProductLink",
      width: 110,
    },
    {
      headerName: "IoT", // - IOT -- need to edit so its hide value is controllable by the toggle button
      field: "productInformation.productType",
      filter: "agSetColumnFilter",
      cellRenderer: "IoTCheck",
      width: 60,
      filterParams: {
        filterOptions: ["EQ"],
        suppressAndOrCondition: true,
        values: CampUtils.getEnumAsArray(ProductType),
      },
      hide: false, // needs ability to be toggled
    },
    {
      headerName: translate("types.common.promotions") as string,
      field: "productInformation.hasPromotions",
      filter: "agSetColumnFilter",
      cellRenderer: "BoolCheck",
      width: 120,
    },
    {
      headerName: translate("types.common.notifications") as string,
      field: "productInformation.hasNotifications",
      filter: "agSetColumnFilter",
      cellRenderer: "BoolCheck",
      width: 120,
    },
    {
      headerName: translate("types.common.consumables") as string,
      field: "productInformation.hasConsumables",
      filter: "agSetColumnFilter",
      cellRenderer: "BoolCheck",
      width: 120,
    },
    {
      headerName: translate("types.common.active") as string,
      field: "productInformation.isProductRegistrationEnabled",
      filter: "agSetColumnFilter",
      cellRenderer: "BoolCheck",
      width: 120,
      hide: true,
    },
    {
      headerName: translate("types.common.pearls") as string,
      field: "productInformation.bonus",
      width: 120,
    },
    {
      headerName: translate("types.product.serviceFeePearls") as string,
      field: "productInformation.serviceFeePearls",
      width: 160,
      },
    {
      headerName: translate("types.product.lastEdited") as string,
      field: "productInformation.lastEdited",
      width: 200,
      cellRenderer: "FormatDate",
    }
  ];

  return columnDefs;
};

interface CellRenderer {
  value: string;
  data: productInfo;
}

const FormatDate: React.FC<CellRenderer> = ({ data, value }) => {
    if (!(data && value)) {
        return <div />;
    }
    return (
        <Translate>{
            (translate) => {
                return <div>

                   {format(new Date(value), translate.translate('generics.dateTimeFormatFNS') as string)}
           
                </div>
            }
        }
        </Translate>
    );
};

const ProductLink: React.FC<CellRenderer> = ({ data, value }) => {
  if (!(data && data.productInformation)) {
    return <div />;
  }
  return <Link to={"product/" + data.productInformation.id}>{value}</Link>;
};

const IoTCheck: React.FC<CellRenderer> = ({ data, value }) => {
  if (!(data && data.productInformation)) {
    return <div />;
  }
  return (
    <div>
      {data.productInformation.productType === "SILK_IOT" ||
      data.productInformation.productType === "PERLA_IOT" ? (
        <CheckIcon color={"primary"} />
      ) : (
        <Fragment />
      )}
    </div>
  );
};

const BoolCheck: React.FC<CellRenderer> = (value) => {
  return (
    <div>{value.value ? <CheckIcon color={"primary"} /> : null}</div>
  );
};

const CellRenderer = {
  ProductLink,
  IoTCheck,
  BoolCheck,
  FormatDate,
};

interface ProductsGridPara extends LocalizeContextProps {
  productOverviewState: ProductOverviewState
  localize: LocalizeState
  actions: {
    setGridState: typeof setGridState
    setColumnStates: typeof setColumnStates
    search: typeof search
  }
}

const ProductsGrid: React.FC<ProductsGridPara> = ({ productOverviewState, actions, translate }) => {
  const { data, loading } = useQuery<productGrid, productGridVariables>(QUERY_PRODUCTS_GRID, {variables:{pageSize: 0}}); // pageSize = 0 --> Load all
  const gridData = filterSparseArray(data?.products?.results);


  if (loading) {
    return <CircularProgress color="secondary" />
  } else if (data) {
        return (
      <ReduxAgGrid
        columnDefs={getColumnDefs(translate, gridData)}
        rowData={gridData}
        frameworkComponents={CellRenderer}
        gridState={productOverviewState.grid}
        gridActions={actions}
        pagination={true}
        paginationPageSize={500}
        quickFilterText={productOverviewState.search || undefined}
      />
    );
  } else {
    return <Translate id="generics.noDataAvailable" />;
  }
}

const mapStateToProps = (appState: AppState) => ({
  productOverviewState: appState.productOverview,
  localize: appState.localize
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ setGridState, setColumnStates, search }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(ProductsGrid));
