import gql from "graphql-tag";

export const QUERY_SENDGRID_SUPPORTED_LANGUAGES = gql`
  query sendGridSupportedLanguages {
    sendGridSupportedLanguages {
      languageCode
      countryCode
      id
    }
  }
`;
