import React, { useEffect, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  Typography,
  Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
} from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import ProfileMenu from "components/common/ProfileMenu";
// import LanguageMenu from 'components/common/LanguageMenu';

import store, { AppState } from "redux/store";

import NavigationList from "components/common/Navigation";
import {
  DrawerState,
  closeDrawer,
  openDrawer,
  toggleDrawer,
} from "redux/actions/appShellActions";

import logo from "resources/logo-inverted.svg";
import { Translate } from "react-localize-redux";
import ChangeActive from "./AccShortcuts/ChangeActive";

import RequestLog from "./DeviceActions/RequestLog";
import ResetActivation from "./DeviceActions/ResetActivation";
import SetOutOfService from "./DeviceActions/SetOutOfService";
import ResetInstaller from "./DeviceActions/ResetInstaller";

import ChangeOrganizationActiveState from "./ChangeOrganisationActiveState";
import ChangeLogin from "./AccShortcuts/ChangeLogin";

import SendPasswordResetEmail from "./AccShortcuts/SendPasswordResetEmail";
import ChangePosition from "./AccShortcuts/ChangePosition";
import RemoveFromCompany from "./AccShortcuts/RemoveFromCompany";

import SendgridTemplatesDelete from "./SendgridTemplatesDelete";
import SendgridTemplatesCreate from "./SendgridTemplatesCreate";
import PhoenixWarningBanner from "./PhoenixWarningBanner";
import PhoenixWarningDialog from "./PhoenixWarningDialog";
import DeleteAccount from "./AccShortcuts/DeleteAccount";

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    app: {
      display: "flex",
      height: "100vh",
      minWidth: "1300px",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      height: "100%",
    },
    logo: {
      width: drawerWidth - 2 * theme.spacing(3),
      marginRight: 2 * theme.spacing(3),
    },
    logoImage: {
      maxWidth: "100%",
      maxHeight: "50px",
      display: "block",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: {
      ...theme.mixins.toolbar,
      marginTop: "64px",
    },
    contentWrapper: {
      flexGrow: 1,
      height: "100vh",
      display: "flex",
      flexDirection: "column",

      "& > .mainContentContainer": {
        overflowY: "auto",
        padding: theme.spacing(3),
      },
      "& > .mainContentContainer.fullHeight": {
        height: "calc(100vh - 64px)",
      },
      "& > .pageHeader + .mainContentContainer.fullHeight": {
        height: "calc(100vh - 128px)",
        overflowY: "auto",
      },
    },
    title: {
      flexGrow: 1,
    },
    appVersion: {
      position: "fixed",
      left: 0,
      bottom: 0,
      fontSize: "0.7em",
      background: "white",
      width: drawerWidth,
    },
  });
});

interface IAppShellProps {
  actions: {
    openDrawer: () => void;
    closeDrawer: () => void;
    toggleDrawer: () => void;
  };
  appShell: DrawerState;
}

const AppShell: React.FC<IAppShellProps> = ({ children }) => {
  const classes = useStyles();

  const [showLogin, setShowLogin] = useState(true);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);


  useEffect(() => {
    if(store.getState().auth.user && showLogin) {
      setWarningDialogOpen(true);
      setShowLogin(false);

    }
  }, [store.getState().auth.user, showLogin]) 

  return (
    <div className={"App " + classes.app}>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <NavLink className={classes.logo} to="/">
            <img className={classes.logoImage} src={logo} alt="logo" />
          </NavLink>
          <Typography
            className={classes.title}
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
          >
            C.A.M.P Guide
          </Typography>
          {/* <LanguageMenu /> */}
          <ProfileMenu />
        </Toolbar>
        <PhoenixWarningBanner
          findOutMoreOnClick={() => setWarningDialogOpen(true)}
        />
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
        className={classes.drawer}
      >
        <div className={classes.toolbar} />
        <NavigationList />
        <div className={classes.appVersion}>
          <table>
            <tbody>
              <tr>
                <td>
                  <Translate id="app.version" />
                </td>
                <td>{process.env.REACT_APP_VERSION}</td>
                <td> - </td>
                <td>
                  <Translate id="app.commit" />
                </td>
                <td>{process.env.REACT_APP_COMMIT}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Drawer>

      <div className={classes.contentWrapper}>
        <div className={classes.toolbar} />
        {children}
      </div>

      <ChangeActive />
      <ChangeLogin />

      <ChangeOrganizationActiveState />
      <SendPasswordResetEmail />
      <ChangePosition />
      <RemoveFromCompany />
      <RequestLog />
      <ResetActivation />
      <ResetInstaller />
      <SetOutOfService />
      <SendgridTemplatesDelete />
      <SendgridTemplatesCreate />
      <PhoenixWarningDialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
      />
      <DeleteAccount />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  appShell: state.appShell,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { openDrawer, closeDrawer, toggleDrawer },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppShell);
