import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
      {...props}
    >
      <path d="M0 0h25v25H0z" fill="none" />
      <path
        data-name="Shape"
        d="M2.081 22.836h20.755V2.081z"
        fill="currentColor"
        opacity={0.7}
      />
      <path
        data-name="Shape"
        d="M2.081 22.836h15.6v-15.6z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
