import React, { useState, Fragment } from "react";
import gql from "graphql-tag";
import { TextField, Box, Button, CircularProgress } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Translate,
  TranslateFunction,
  withLocalize,
  LocalizeContextProps,
} from "react-localize-redux";
import { Autocomplete, Alert } from "@material-ui/lab";
import { useMutation, useLazyQuery } from "react-apollo";
import { productList } from "types/apolloGenerated/productList";
import { connect } from "react-redux";
import { State as ConsumableGridState } from "redux/types/ConsumableGrid";
import {
  setGridState,
  setColumnStates,
} from "redux/actions/ConsumableGridActions";
import { AppState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";

import {
  generateCodesVariables,
  generateCodes,
} from "types/apolloGenerated/generateCodes";
import {
  pollGenerateCodesVariables,
  pollGenerateCodes,
  pollGenerateCodes_pollGenerateProductCodesJob_result as CodeGenResults,
} from "types/apolloGenerated/pollGenerateCodes";

import { POLL_GENERATE_CODES } from "commonQueries/pollGeneratedCodes";
import { ColDef } from "@ag-grid-enterprise/all-modules";
import { ReduxAgGrid } from "components/common/Grid/AgGrid";
import {
  exportBonusLabelsVariables as ExportVariables,
  exportBonusLabels as Export,
} from "types/apolloGenerated/exportBonusLabels";
import PageDialog from "components/common/PageDialog";
import { ProductType } from "types/apolloGenerated/globalTypes";
import {
    disableCodeBatchVariables,
    disableCodeBatch,
} from "types/apolloGenerated/disableCodeBatch";
const GENERATE_CODES = gql`
  mutation generateCodes($params: ProductCodeGenerationParameter!) {
    product {
      startGenerateProductCodesJob(params: $params)
    }
  }
`;

const EXPORT_BONUS_LABELS = gql`
  mutation exportBonusLabels(
    $batchNumber: String!
    $productDescription: String!
    $useTemplate: Boolean
    $overwrite: Boolean
  ) {
    product {
      exportBonusLabels(
        batchNumber: $batchNumber
        productDescription: $productDescription
        useTemplate: $useTemplate
        overwrite: $overwrite
      )
    }
  }
`;

const DISABLE_BATCH = gql`
  mutation disableCodeBatch ($batchNumber: String!)  {
    product {
      disableCodeBatch(batchNumber: $batchNumber)
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: "flex",
      margin: "20px 20px 0px 20px",
      width: "100%",
    },
    fieldContainer: {
      marginRight: "40px",
    },
    generateCodesGrid: {
      position: "relative",
      height: "650px",
      display: "flex",
      flexFlow: "column",
      overflow: "hidden",

      "& > *": {
        flex: "1 1 auto",
      },
      "& a": {
        color: "#005D8F",
      },
      "& > .grie-error": {
        flex: "0 1 auto",
      },
      "& .ag-paging-panel.ag-hidden": {
        display: "block !important",
        opacity: 0,
        pointerEvents: "none",
      },
      "& .ag-cell.number-cell": {
        textAlign: "right",
        paddingRight: "40px",
      },
      '& div[col-id="id_1"] svg': {
        marginTop: "2px",
        height: "22px",
      },
    },
    gridContainer: {
      margin: "20px 20px 0px 20px",
    },
    pdfContainer: {
      margin: "20px 20px 0px 20px",
      display: "flex",
    },
  });
});

interface CellRenderer {
  value: string;
  data: any;
}

let tempArticle: any;
let articleName = "";
let totalResult = 0;

interface GenerateCodesProps extends LocalizeContextProps {
  gridState: ConsumableGridState;
  actions: {
    setGridState: typeof setGridState;
    setColumnStates: typeof setColumnStates;
  };
  productList: productList;
}

const GenerateCodes: React.FC<GenerateCodesProps> = ({
  translate,
  gridState,
  actions,
  productList,
}) => {
  const classes = useStyles();
  const [exportOverwrite, setExportOverwrite] = useState({
    open: false,
    message: "",
  });
  const [batchError, setBatchError] = useState({
    error: false,
    message: "",
  });
  const [showGrid, setShowGrid] = useState(true);
    const [deactivateBatchDialog, setDeactivateBatchDialog] = useState({ open: false, error: "" })
    const [generateCodesDialog, setGenerateCodesDialog] = useState(false)
  const [template, setTemplate] = useState(false);
  const [productNeeded, setProductNeeded] = useState({
    show: false,
    message: "",
  });

  const [generateData, setGenerateData] = React.useState({
    productId: -1,
    amount: 0,
    batchNumber: "",
    erpBatchNumber: ""
  });
  const [gridData, setGridData] = React.useState<(CodeGenResults | null)[]>([]);
  const [exportError, setExportError] = useState("");

  const [generateCodesFunc, { loading: genCodesLoading }] = useMutation<
    generateCodes,
    generateCodesVariables
  >(GENERATE_CODES, {
    onCompleted: () => {
      setBatchError({
        error: false,
        message: "",
      });
      startPolling();
    },
    onError: (error) => {
      error.graphQLErrors.forEach((error) => {
        if (error?.extensions?.data?.batchAlreadyExists) {
          articleName = "";
          setBatchError({
            error: true,
            message: translate(
              "pages.production.error.sameBatchNumber"
            ) as string,
          });
          setShowGrid(true);
          startPolling();
        }
      });
    },
  });

  const startPolling = () => {
    pollGenCodes({
      variables: {
        batchNumber: generateData.batchNumber,
      },
    });
  };

  function compare(a: any, b: any) {
    const codeA = a.code;
    const codeB = b.code;

    let comparison = 0;
    if (codeA > codeB) {
      comparison = 1;
    } else if (codeA < codeB) {
      comparison = -1;
    }
    return comparison;
  }

  const [pollInterval, setPollInterval] = useState(0);
  const [pollGenCodes, { loading: pollGenCodesLoading, data: pollGenerateData }] = useLazyQuery<
    pollGenerateCodes,
    pollGenerateCodesVariables
  >(POLL_GENERATE_CODES, {
    pollInterval: pollInterval,
    notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
          setGenerateCodesDialog(false)
          setShowGrid(true);
      if (
        data.pollGenerateProductCodesJob?.isFinished &&
        data.pollGenerateProductCodesJob.result !== null
      ) {
          setGridData(data.pollGenerateProductCodesJob.result.sort(compare));
        totalResult = data.pollGenerateProductCodesJob.total || 0;
        setPollInterval(0);
      } else {
        if (pollInterval !== 1000) {
          setPollInterval(1000);
        }
      }
    },
  });

  const [exportBonusLabels, { loading: exportLoading }] = useMutation<
    Export,
    ExportVariables
  >(EXPORT_BONUS_LABELS, {
    onCompleted: (data) => {
      if (data.product?.exportBonusLabels) {
        window.open(data.product?.exportBonusLabels, "_self");
      }
      setExportOverwrite({ open: false, message: "" });
      setExportError("");
    },
    onError: (error) => {
      let alreadyDownloaded = false;
      error.graphQLErrors.forEach((error) => {
        if (error?.extensions?.data?.alreadyExported) {
          setExportOverwrite({
            open: true,
            message: "pages.production.error.alreadyDownloaded",
          });
          alreadyDownloaded = true;
        }
      });
      if (!alreadyDownloaded) {
        setExportError(error.message);
      }
    },
  });

    const [deactivateBatch, { loading: deactivateLoading,  }] = useMutation <
        disableCodeBatch,
        disableCodeBatchVariables
        >(DISABLE_BATCH, {
            onCompleted: (data) => {
                setDeactivateBatchDialog({ open: false, error: ""});
                if (pollGenerateData?.pollGenerateProductCodesJob?.result) {
                    setGridData(pollGenerateData.pollGenerateProductCodesJob.result.sort(compare));
                }
            },
            onError: (error) => {
                setDeactivateBatchDialog({ open: true, error: error.message})
            },
            refetchQueries: [{
                query: POLL_GENERATE_CODES, variables: ({ batchNumber: generateData.batchNumber })
            }],
            awaitRefetchQueries: true
        }
        )

  interface ProductType {
    name: string;
    id: number;
  }

  const notRegisterable = translate(
    "pages.production.notConfiguredForRegistration"
  ) as string;

  const productArray = productList?.products?.results
    ? productList.products.results
        .map((value) => {
          let valueToDisplay = { name: "", id: 0 };
          let canGenerate = value?.productInformation
            ?.isProductRegistrationEnabled
            ? ""
            : " " + notRegisterable;

          if (value?.productInformation?.name && value.productInformation.id) {
            valueToDisplay.name = value.productInformation.name + canGenerate;
            valueToDisplay.id = value.productInformation.id;
          }

          if (
            value?.productInformation?.name &&
            value.productInformation.itemNumber &&
            value.productInformation.id
          ) {
            valueToDisplay.name =
              value.productInformation.name +
              " : " +
              value.productInformation.itemNumber +
              canGenerate;
            valueToDisplay.id = value.productInformation.id;
          }

          return valueToDisplay;
        })
        .sort()
    : [];

  const CodeGrid = () => {
    if (!gridData.length) {
      return <Fragment />;
    }

    return (
      <ReduxAgGrid
        className={classes.generateCodesGrid}
        columnDefs={getColumnDefs(translate)}
        rowData={gridData}
        gridState={gridState.grid}
        gridActions={actions}
        paginationPageSize={50}
        pagination={true}
      />
    );
  };

  const ActionButtons = () => {
    if (!gridData.length) {
      return <Fragment />;
    }

    return (
      <Fragment>
        {exportLoading && !template ? (
          <Box
            style={{
              width: "140px",
              display: "flex",
              justifyContent: "center",
              marginRight: "45px",
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Button
            className={classes.fieldContainer}
            variant="outlined"
            onClick={() => {
              setTemplate(false);
              exportBonusLabels({
                variables: {
                  batchNumber: generateData.batchNumber,
                  productDescription: articleName,
                  useTemplate: false,
                  overwrite: false,
                },
              });
            }}
          >
            <Translate id="pages.production.downloadPdf" />
          </Button>
        )}
        {exportLoading && template ? (
          <Box
            style={{
              width: "240px",
              display: "flex",
              justifyContent: "center",
              marginRight: "45px",
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Button
            className={classes.fieldContainer}
            variant="outlined"
            onClick={() => {
              setTemplate(true);
              exportBonusLabels({
                variables: {
                  batchNumber: generateData.batchNumber,
                  productDescription: articleName,
                  useTemplate: true,
                  overwrite: false,
                },
              });
            }}
          >
            <Translate id="pages.production.downloadPdfTemplate" />
          </Button>
        )}
            <div>
            {gridData.length > 0 && gridData[0]?.batchDeactivated ? <Alert severity="warning"><Translate id="pages.production.batchDeactivated" /></Alert> : 
               <Button variant="outlined" className={classes.fieldContainer} onClick={() => {
                        setDeactivateBatchDialog({ open: true, error: "" });
            }}
          >
            <Translate id="pages.production.deactivateBatch" />
          </Button>}
            </div>
            <PageDialog
                open={deactivateBatchDialog.open}
                title={"pages.production.disableBatchWarning"}
                buttons={[
                    {
                        title: "generics.true",
                        click: () =>
                            deactivateBatch({
                                variables: {
                                    batchNumber: generateData.batchNumber
                                }
                            }),
                    },
                    {
                        title: "generics.false",
                        click: () => {
                            setDeactivateBatchDialog({ open: false, error: "" });
                        },
                    },
                ]}
                loading={deactivateLoading}
                error={deactivateBatchDialog.error}
            />
      </Fragment>
    );
  };

  const submitGenerate = () => {
    setShowGrid(false);
    if (!tempArticle) {
      setProductNeeded({ show: true, message: "Please choose a product" });
    } else {
      setProductNeeded({ show: false, message: "" });
      articleName = tempArticle.name
        .substring(0, tempArticle.name.search(":"))
            .trim();
      setGenerateCodesDialog(true)
      }
      
  };

  return (
    <Box>
      <Box className={classes.container}>
        <Autocomplete
          blurOnSelect
          className={classes.fieldContainer}
          style={{ width: "40%" }}
          options={productArray as ProductType[]}
          getOptionLabel={(option) => option.name}
          getOptionDisabled={(option) =>
            option.name.includes(notRegisterable) ? true : false
          }
          onChange={(event: any, newValue: any) => {
            if (newValue) {
              tempArticle = newValue;
              console.log("new value -> ", newValue);
              setGenerateData({ ...generateData, productId: newValue.id });
            }
          }}
          renderOption={(option) => <span>{option.name}</span>}
          renderInput={(params) => (
            <TextField
              style={{ width: "100%" }}
              {...params}
              variant="standard"
              label={<Translate id="types.common.product" />}
              helperText={productNeeded.message}
              error={productNeeded.show}
            />
          )}
        />

        <TextField
          type="number"
          style={{ width: "10%" }}
          className={classes.fieldContainer}
          label={<Translate id="components.generateCodes.amount" />}
          onChange={(evt) =>
            setGenerateData({
              ...generateData,
              amount: Number(evt.target.value),
            })
          }
        />
        <TextField
          className={classes.fieldContainer}
          style={{ width: "15%" }}
          label={<Translate id="types.common.batchNumber" />}
          onChange={(evt) =>
            setGenerateData({
              ...generateData,
              batchNumber: evt.target.value,
            })
          }
        />
        <TextField
          className={classes.fieldContainer}
          style={{ width: "15%" }}
          label={<Translate id="components.generateCodes.erpNo" />}
          onChange={evt =>
              setGenerateData({
                ...generateData,
                erpBatchNumber: evt.target.value
              })
          }
        />
          <Button
            className={classes.fieldContainer}
            style={{ width: "20%", height: "48px" }}
            variant="outlined"
            onClick={submitGenerate}
          >
            <Translate id="components.generateCodes.genCodes" />
          </Button>
           
              <PageDialog
                  open={generateCodesDialog}
                  title={"pages.production.codeGenerationWarning"}
                  buttons={[
                      {
                          title: "generics.true",
                          click: () =>
                              generateCodesFunc({
                                  variables: {
                                      params: {
                                          productId: tempArticle.id,
                                          amount: generateData.amount,
                                          batchNumber: generateData.batchNumber,
                                          erpBatchNumber: generateData.erpBatchNumber === "" ? null : generateData.erpBatchNumber
                                      },
                                  },
                              }),
                      },
                      {
                          title: "generics.false",
                          click: () => {
                              setGenerateCodesDialog(false);
                          },
                      },
                  ]}
                  loading={pollGenCodesLoading || genCodesLoading || pollInterval === 1000}
              />
      </Box>
      {batchError.error ? (
        <Alert style={{ margin: "20px" }} severity="error">
          {batchError.message}
        </Alert>
      ) : (
        <Fragment />
      )}
      <Box className={classes.gridContainer}>
        {showGrid ? <CodeGrid /> : <div />}
      </Box>
      <Box className={classes.pdfContainer}>
        {showGrid ? <ActionButtons /> : <div />}
      </Box>
      <PageDialog
        open={exportOverwrite.open}
        title={exportOverwrite.message}
        buttons={[
          {
            title: "generics.true",
            click: () =>
              exportBonusLabels({
                variables: {
                  batchNumber: generateData.batchNumber,
                  productDescription: articleName,
                  useTemplate: template,
                  overwrite: true,
                },
              }),
          },
          {
            title: "generics.false",
            click: () => {
              setExportOverwrite({ open: false, message: "" });
              setExportError("");
            },
          },
        ]}
        loading={exportLoading}
        error={exportError}
      />
    </Box>
  );
};

export const getColumnDefs = (translate: TranslateFunction): ColDef[] => {
  const columnDefs = [
    {
      headerName: translate(
        "types.common.batchNumber"
      ) as string,
      field: "batchNumber",
      sortable: false,
      filter: "agTextColumnFilter",
      width: 200,
      suppressMenu: true,
    },
    {
      headerName: translate(
        "pages.production.registrationCodeGrid.erpBatchNumber"
      ) as string,
      field: "erpBatchNumber",
      sortable: false,
      filter: "agTextColumnFilter",
      width: 200,
      suppressMenu: true,
    },
    {
      headerName: translate(
        'types.common.productCode'
      ) as string,
      field: "code",
      sort: "asc",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 120,
      suppressMenu: true,
    },
    {
      headerName: translate(
        "pages.production.registrationCodeGrid.articleName"
      ) as string,
      sortable: false,
      resizable: true,
      valueGetter: (params: any) => {
        return articleName;
      },
      filter: "agTextColumnFilter",
      width: 250,
    },
    {
      headerName: translate(
        "pages.production.registrationCodeGrid.itemOfBatchSize"
      ) as string,
      sortable: false,
      valueGetter: (params: any) => {
        return (
          ((params.node.rowIndex +
            1 +
            " " +
            translate("pages.production.registrationCodeGrid.of")) as string) +
          " " +
          totalResult
        );
      },
      filter: "agTextColumnFilter",
      width: 120,
      },
      {
          headerName: translate(
              "pages.production.registrationCodeGrid.batchDeactivated"
          ) as string,
          field: "batchDeactivated",
          sortable: false,
          filter: "agTextColumnFilter",
          width: 200,
          suppressMenu: true,
      },
  ];
  return columnDefs;
};

const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  gridState: appState.registrationGrid,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ setGridState, setColumnStates }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(GenerateCodes));
