import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    content: {
      padding: theme.spacing(3),
    },
  })
});

const PageNotFound = () => <div className={useStyles().content}><h1>404 - Diese Seite exisitiert nicht</h1></div>;

export default PageNotFound;
