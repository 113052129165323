import React from "react";

import GsmFairIcon from "../../resources/signalStrength/GsmFairIcon";
import GsmGoodIcon from "../../resources/signalStrength/GsmGoodIcon";
import GsmWeakIcon from "../../resources/signalStrength/GsmWeakIcon";
import GsmExcellentIcon from "../../resources/signalStrength/GsmExcellentIcon";
import GsmOfflineIcon from "../../resources/signalStrength/GsmOfflineIcon";

import WifiFairIcon from "../../resources/signalStrength/WifiFairIcon";
import WifiGoodIcon from "../../resources/signalStrength/WifiGoodIcon";
import WifiWeakIcon from "../../resources/signalStrength/WifiWeakIcon";
import WifiExcellentIcon from "../../resources/signalStrength/WifiExcellentIcon";
import WifiOfflineIcon from "../../resources/signalStrength/WifiUnknownIcon";

export const QUALITY_THRESHOLDS = {
  gsm: { good: 65, fair: 80, weak: 90 },
  wifi: { good: 55, fair: 75, weak: 85 },
};

export type SignalType = keyof typeof QUALITY_THRESHOLDS;

function getSignalQuality(type: SignalType, rssi?: number | undefined) {
  const ranges = QUALITY_THRESHOLDS[type];
  if (!rssi || rssi === 0 || rssi === 255) {
    return "offline";
  } else if (rssi >= ranges.weak) {
    return "weak";
  } else if (rssi >= ranges.fair) {
    return "fair";
  } else if (rssi >= ranges.good) {
    return "good";
  } else {
    return "excellent";
  }
}

function getGsmSignalQualityIconComponent(
  quality: string,
  usingGsmOnly: boolean
) {
  if (usingGsmOnly) {
    switch (quality) {
      case "offline":
        return <GsmOfflineIcon color="#ed2b43" />;
      case "weak":
        return <GsmWeakIcon color="#ed2b43" />;
      case "fair":
        return <GsmFairIcon color="#f4c62d" />;
      case "good":
        return <GsmGoodIcon color="#0e67b6" />;
      case "excellent":
        return <GsmExcellentIcon color="#31b61e" />;
    }
  } else {
    switch (quality) {
      case "offline":
        return <GsmOfflineIcon color="#ed2b43" />;
      case "weak":
        return <GsmWeakIcon color="#666b6e" />;
      case "fair":
        return <GsmFairIcon color="#666b6e" />;
      case "good":
        return <GsmGoodIcon color="#666b6e" />;
      case "excellent":
        return <GsmExcellentIcon color="#666b6e" />;
    }
  }
  return <div />;
}

function getWifiSignalQualityIconComponent(quality: string) {
  switch (quality) {
    case "offline":
      return <WifiOfflineIcon />;
    case "weak":
      return <WifiWeakIcon />;
    case "fair":
      return <WifiFairIcon />;
    case "good":
      return <WifiGoodIcon />;
    case "excellent":
      return <WifiExcellentIcon />;
  }
  return <div />;
}

const SignalStrength = ({
  type,
  rssi,
  usingGsmOnly,
}: {
  type: SignalType;
  rssi: number | undefined;
  usingGsmOnly?: boolean;
}) => {
  const signalQuality = getSignalQuality(type, rssi);
  if (type === "gsm") {
    return getGsmSignalQualityIconComponent(
      signalQuality,
      usingGsmOnly ?? false
    );
  }
  return getWifiSignalQualityIconComponent(signalQuality);
};

export default SignalStrength;
