import { Error } from 'types/CampGuideTypes';

export enum ActionType {
  CODES = 'SET_COUNTRY_CODES',
  ERROR = 'SET_COUNTRY_ERROR',
  PENDING = 'SET_COUNTRY_PENDING',
  SAVE = 'SET_COUNTRY_SAVE',
  LOAD = 'SET_COUNTRY_LOAD'
}

export interface MultiSelectStore {
  countryCodes: string[],
  pending: boolean,
  isLoaded: boolean,
  error: Error | null
}

export interface SetCountryAction {
  type: ActionType.CODES
  payload: string[],
}

export interface ErrorAction {
  type: ActionType.ERROR,
  payload: Error
}

export interface PendingAction {
  type: ActionType.PENDING
}

export interface LoadAction {
  type: ActionType.LOAD
}

export interface SaveAction {
  type: ActionType.SAVE
}

export type Action = SetCountryAction | PendingAction | ErrorAction | LoadAction | SaveAction;