import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';

import { connect } from 'react-redux';
import { AppState } from 'redux/store';

import { Store } from 'redux/types/DeviceActions/RequestLog';
import { close } from 'redux/actions/DeviceActions/RequestLogActions';

import gql from "graphql-tag";

import { Translate } from "react-localize-redux";
import { useMutation } from "@apollo/react-hooks";

import Error from 'components/common/Error';


import {
  requestLog as RequestLogMutationData,
  requestLogVariables as RequestLogMutationVars
} from "types/apolloGenerated/requestLog"

import { ErrorCodes } from 'types/CampGuideTypes';



interface IChangeOrganizationActiveState {
  store: Store
  actions: {
    close: typeof close
  }
}



const REQUEST_LOG = gql`
mutation requestLog($productCode: String!){
  productInstance{
    perla{
      requestLog(productCode: $productCode)
    }
  }
}
`;

const RequestLog: React.FC<IChangeOrganizationActiveState> = ({ store, actions }) => {

  const [requestLog, { loading, error }] = useMutation<RequestLogMutationData, RequestLogMutationVars>(REQUEST_LOG, {
    update: () => {
      if (!error) {
        // ToDo: Apollo-Cache-Update
        actions.close();
      }
    }
  }
  );


  const submit = () => {
    if (store.openWithProductCode) {
      //console.log("request log", store.openWithProductCode);
      requestLog({
        variables: { productCode: store.openWithProductCode }
      })
    }
  };

  const errorElement = error ? <Error error={{ code: ErrorCodes.Unknown, message: error.message }} /> : null;
  const progress = loading ? <CircularProgress color="secondary" /> : null;

  return (
    <Dialog open={Boolean(store.openWithProductCode)} onClose={actions.close} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="form-dialog-title"><Translate id="components.deviceData.requestLog" /></DialogTitle>
      <DialogContent>
        <DialogContentText><Translate id="components.requestLog.subtitle" /></DialogContentText>
        {errorElement}
      </DialogContent>
      <DialogActions>
        {progress}
        <Button onClick={actions.close} color="primary" disabled={loading}>
          <Translate id="generics.cancel" />
        </Button>
        <Button onClick={submit} color="primary" disabled={loading}>
          <Translate id="generics.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: AppState) => ({
  store: state.deviceActions.requestLog,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ close }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestLog);
