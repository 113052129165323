import { AuthStore, Action, ActionType } from 'redux/types/Auth';

import initialState from 'redux/initialState';

export default function authReducer(state: AuthStore = initialState.auth, action: Action): AuthStore {
  switch (action.type) {

    case ActionType.LOGIN:
      return {
        isLoading: false,
        hasUnauthorisedLogin: false,
        user: action.payload,
        authHasExpired: false
      };

    case ActionType.UNAUTHORISED:
      return {
        isLoading: false,
        hasUnauthorisedLogin: true,
        user: null,
        authHasExpired: false
      };

    case ActionType.LOGOUT:
      return {
        isLoading: false,
        hasUnauthorisedLogin: false,
        user: null,
        authHasExpired: false
      };

    case ActionType.LOADING:
      return {
        isLoading: true,
        hasUnauthorisedLogin: false,
        user: null,
        authHasExpired: false
      };

    case ActionType.EXPIRE:
      return {
        isLoading: false,
        hasUnauthorisedLogin: false,
        user: null,
        authHasExpired: true
      }

    default:
      return state;
  }
}
