import { createMuiTheme } from "@material-ui/core/styles";

import bwtColors from "./bwtColors";

import "./index.css";

const theme = createMuiTheme({
  palette: {
    primary: { main: bwtColors.blue },
    secondary: { main: bwtColors.pink },
    error: { main: bwtColors.errorRed },

    common: {
      black: bwtColors.grey,
    },

    background: {
      default: "#ffffff",
    },

    text: {
      primary: bwtColors.grey,
    },
  },

  typography: {
    fontFamily: "CeraPRO, Roboto, Arial, sans-serif",
    body1: {
      fontSize: 14,
    },
  },
});

export default theme;
