import React from "react";
import { Translate } from "react-localize-redux";
import { useQuery } from "@apollo/react-hooks";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Set } from "immutable";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

import { ProductUpdateInput } from "types/apolloGenerated/globalTypes";
import { mailTemplateGroup as queryData } from "types/apolloGenerated/mailTemplateGroup";
import MAIL_QUERY from "commonQueries/mailTemplate";
import Expander from "components/common/Expander";

import { PromotionIdsAndNames_promotions_results as PromotionData } from "types/apolloGenerated/PromotionIdsAndNames";

const useStyles = makeStyles(() => {
  return createStyles({
    hideButton: {
      width: "30px",
      height: "35px",
      paddingTop: "6px",
      cursor: "pointer",
    },
    titleOuterContainer: {
      fontSize: "20px",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      borderRadius: "3px",
      justifyContent: "space-between",
    },
    titleInnerContainer: {
      display: "flex",
      alignItems: "center",
    },
    removeButtonContainer: {
      marginRight: "20px",
      display: "flex",
    },
    container: {
      margin: "10px 0 0 0",
      borderRadius: "3px",
      minWidth: "990px",
      maxWidth: "1615px",
      border: "solid #666B6E 1px",
    },
  });
});

interface CustomerRegistrationProps {
  promotionsData: PromotionData[];
  productInfoObj: ProductUpdateInput;
  setProductInfoObj: React.Dispatch<React.SetStateAction<ProductUpdateInput>>;
  isNew: boolean;
}

const CustomerRegistration: React.FC<CustomerRegistrationProps> = ({
  promotionsData,
  productInfoObj,
  setProductInfoObj,
  isNew,
}) => {
  const { data: mailData } = useQuery<queryData>(MAIL_QUERY);
  const classes = useStyles();

  const regBonus = productInfoObj?.registrationBonus;

  const mailTemplateValues = mailData?.sendGridTemplates?.results ?? [];

  const uniqueMailTemplateValues = Set(
    mailTemplateValues.map((value) => value?.name ?? "")
  )
    .toArray()
    .filter((str) => !!str)
    .filter(
      (str) => str.startsWith("Activation") || str.startsWith("Registration")
    );

  const [hidden, setHidden] = React.useState(regBonus?.bonusId ? false : true);

  const openClose = () => {
    setProductInfoObj({
      ...productInfoObj,
      registrationBonus: {
        bonusId: hidden ? 1 : null,
      },
    });
    setHidden(!hidden);
  };

  const promotionsList =
    (promotionsData && promotionsData?.map((value: any) => value.name)) ?? [];
  const bonusIncentiveValue =
    promotionsList.length > 0
      ? promotionsList[
          promotionsData
            .map((value: any) => value.id)
            .indexOf(regBonus?.bonusId ?? 1)
        ]
      : "";

  const convertToBonusId = (value: string) => {
    const index = promotionsData.map((value: any) => value.name).indexOf(value);
    return promotionsData[index]?.id ?? 1;
  };

  return (
    <Box style={{ margin: "0 30px 50px 30px", maxWidth: "1615px" }}>
      <Typography
        variant="h6"
        component="h3"
        style={{
          margin: "0 0 20px 0",
        }}
      >
        <Translate id="types.product.reg" />
      </Typography>
      <Grid container>
        <Box
          display="flex"
          flexWrap="wrap"
          width="100%"
          style={{ justifyContent: "space-between" }}
        >
          <Box style={{ width: "48%" }}>
            <Box>
              <Translate id="types.product.regBehavior" />
            </Box>
            <FormGroup>
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={productInfoObj.isCodelessProductRegistrationEnabled}
                  disabled={true}
                />
                <div style={{ paddingTop: "11px" }}>
                  <Translate id="pages.product.codelessReg" />
                </div>
              </div>
            </FormGroup>
          </Box>
          <Box style={{ width: "48%", maxWidth: "670px" }}>
            <Box>
              <Translate id="types.product.mailTemplate" />
            </Box>
            <Autocomplete
              id="combo-box-demo"
              value={productInfoObj?.registrationMailTemplateName}
              disabled={true}
              options={uniqueMailTemplateValues.sort()}
              getOptionLabel={(option) => option}
              freeSolo={false}
              style={{
                maxWidth: "670px",
                width: "100%",
                margin: "0 0 23px 0px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  fullWidth
                  onBlur={(value) => {
                    if (
                      !uniqueMailTemplateValues.includes(value.target.value)
                    ) {
                      productInfoObj.registrationMailTemplateName = "";
                    } else {
                      productInfoObj.registrationMailTemplateName =
                        value.target.value;
                    }
                    const latestValue = Object.assign({}, productInfoObj);
                    setProductInfoObj(latestValue);
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </Grid>

      <Box className={classes.container}>
        <Box className={classes.titleOuterContainer}>
          <Box className={classes.titleInnerContainer}>
            <Box className={classes.removeButtonContainer}>
              <div style={{ display: "flex" }}>
                <Box
                  style={{ display: "flex", cursor: "pointer" }}
                  onClick={() => {
                    if (!isNew) {
                      if (hidden) {
                        setProductInfoObj({
                          ...productInfoObj,
                          isProductRegistrationEnabled: !productInfoObj.isProductRegistrationEnabled,
                        });
                      }
                    }
                  }}
                >
                  <Checkbox
                    checked={productInfoObj.isProductRegistrationEnabled}
                    disabled={true}
                  />
                  <div style={{ paddingTop: "10px", fontSize: "17px" }}>
                    <Translate id="pages.product.codeReg" />
                  </div>
                </Box>
                <Box
                  style={{ display: "flex", cursor: "pointer" }}
                  onClick={() => {
                    if (productInfoObj.isProductRegistrationEnabled) {
                      openClose();
                    }
                  }}
                >
                  <Checkbox 
                    checked={!hidden}
                    disabled={true}
                  />
                  <div style={{ paddingTop: "10px", fontSize: "17px" }}>
                    <Translate id="types.product.bonusForReg" />
                  </div>
                </Box>
              </div>
            </Box>
          </Box>
          <Box
            onClick={() => {
              if (productInfoObj.isProductRegistrationEnabled) {
                openClose();
              }
            }}
            className={classes.hideButton}
          >
            {hidden ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </Box>
        </Box>
        <Expander hidden={hidden}>
          <Box style={{ display: "flex", height: "60px", marginBottom: "4px" }}>
            <Box>
              <Box style={{ margin: "0 0 4px 22px" }}>
                <Translate id="types.product.bonusInsentive" />
              </Box>
              <Autocomplete
                id="combo-box-demo"
                value={bonusIncentiveValue}
                options={promotionsList}
                getOptionLabel={(option: string) => option}
                disabled={true}
                style={{
                  minWidth: "250px",
                  width: "30%",
                  margin: "0 20px 8px 22px",
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" fullWidth />
                )}
              />
            </Box>
          </Box>
        </Expander>
      </Box>
    </Box>
  );
};

export default CustomerRegistration;
