import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
      {...props}
    >
      <path data-name="Rectangle 318" fill="none" d="M0 0h25v25H0z" />
      <path
        data-name="Subtraction 8"
        d="M12.5 22.397l-.01-.011L.374 7.292l.068-.052A20.163 20.163 0 0112.5 3.125 20.154 20.154 0 0124.557 7.24l.068.052L12.51 22.385l-.009.011zm-1.032-6.772v2.078h2.078v-2.078zm1.035-8.46a1.917 1.917 0 011.915 1.915c0 .052 0 .107-.007.17a1.925 1.925 0 01-.554 1.183l-1.188 1.2a3.769 3.769 0 00-1.187 2.554h2.043a6.551 6.551 0 011.058-2.041l.856-.908a5.361 5.361 0 00.894-2.157 3.83 3.83 0 10-7.66 0h1.915a1.918 1.918 0 011.915-1.916z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
