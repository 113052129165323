import React, { Fragment, useState, useEffect } from "react";
import { Translate } from "react-localize-redux";
import PageHeader from "components/common/PageHeader";
import AgGrid from "components/common/Grid/AgGrid";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  getColumnDefs,
  apolloConnectorBySearch,
  CellRenderer,
  getRowStyleScheduled,
} from "./GridConfig";
import { bindActionCreators, Dispatch } from "redux";
import { AppState } from "redux/store";
import { connect } from "react-redux";
import {
  setGridState,
  setColumnStates,
  search,
} from "redux/actions/CreateRequestOverviewActions";
import { State as CreateRequestOverviewState } from "redux/types/CreateRequestOverview";
import { UserProfile } from "types/ToolboxEntities";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    customerOverview: {
      display: "flex",
      flexFlow: "column",

      "& > .gridFilterHeader": {
        flex: "0 1 auto",
      },

      "& > .innerContent": {
        flex: "1 1 100%",
        height: "100%",
      },
    },

    innerHeader: {
      flex: "0 1 auto",
      display: "flex",
      margin: "0 -20px 20px -20px",
      alignItems: "flex-end",
      justifyContent: "space-between",

      "& > *": {
        flex: "0 1 auto",
        margin: "0 20px",
        minWidth: "170px",
      },

      "& > .filter": {
        flex: "1 1 auto",
        maxWidth: "250px",
      },
    },

    innerHeaderRight: {
      flex: "0 1 auto",
      display: "flex",
      alignItems: "flex-end",
      margin: 0,

      "& > *": {
        flex: "0 1 auto",
        margin: "0 20px",
        minWidth: "170px",
      },
    },
  });
});

interface CreateRequestProps {
  user: UserProfile | null;
  createRequestOverviewState: CreateRequestOverviewState;
  actions: {
    setGridState: typeof setGridState;
    setColumnStates: typeof setColumnStates;
    search: typeof search;
  };
}

const CreateRequestGrid: React.FC<CreateRequestProps> = ({
  user,
  createRequestOverviewState,
  actions,
}) => {
  const classes = useStyles();

  const [apolloConnector, setApolloConnector] = useState<
    ReturnType<typeof apolloConnectorBySearch>
  >(apolloConnectorBySearch(createRequestOverviewState.search));

  useEffect(() => {
    setApolloConnector(
      apolloConnectorBySearch(createRequestOverviewState.search)
    );
  }, [createRequestOverviewState.search]);
  return (
    <Translate>
      {({ translate }) => (
        <Fragment>
          <PageHeader title="pages.requests.create" />
          <main
            className={
              "mainContentContainer fullHeight " + classes.customerOverview
            }
            style={{ position: "relative" }}
          >
            {/* <GridFilterHeader className="gridFilterHeader">
              <TextField
                className="filter"
                id="standard-basic"
                label="Type to filter"
                value={search}
                onChange={changeSearch}
              />

              <div className="innerHeaderRight">
                <CountryFilter
                  selected={selectedCountries}
                  onChange={changeCountryFilter}
                />
              </div>
            </GridFilterHeader> */}

            <div className="innerContent">
              <AgGrid
                gridState={createRequestOverviewState.grid}
                gridActions={{
                  setGridState: actions.setGridState,
                  setColumnStates: actions.setColumnStates,
                }}
                getRowStyle={getRowStyleScheduled}
                apolloConnector={apolloConnector}
                frameworkComponents={CellRenderer}
                columnDefs={getColumnDefs(translate, user)}
              />
            </div>
          </main>
        </Fragment>
      )}
    </Translate>
  );
};

const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  createRequestOverviewState: appState.createRequestOverview,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { setGridState, setColumnStates, search },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRequestGrid);
