import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { Error } from 'types/CampGuideTypes';

import { ActionType, Action, FieldErrors, PartnerGroupDiff } from 'redux/types/PartnerDetails';

import * as Types from 'types/ToolboxEntities';

import GraphQL from 'util/GraphQL';



export const dirty = (): Action => ({
  type: ActionType.DIRTY
});

export const error = (globalError: Error[] = [], propertyError: FieldErrors | null = null): Action => ({
  type: ActionType.ERROR,
  payload: {
    globalErrors: globalError,
    propertyErrors: propertyError
  }
});

export const loaded = (organization: Types.Organization): Action => ({
  type: ActionType.LOADED,
  payload: organization
});

export const loading = (): Action => ({
  type: ActionType.LOADING
});

export const saving = (): Action => ({
  type: ActionType.SAVING
});

export const load = (id: number): ThunkAction<Promise<void>, {}, {}, Action> => {
  return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {
    dispatch(loading());

    GraphQL.queryOrganization(id).then((organization: Types.Organization) => {
      //console.log('DATA', organization);
      dispatch(loaded(organization));
    }).catch((errorData: Error) => {
      console.log('ERROR', errorData);
      dispatch(error([errorData]));
    });
  };
};


export const save = (organization: Types.Organization, groupsDiff: PartnerGroupDiff): ThunkAction<Promise<void>, {}, {}, Action> => {
  return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {
    dispatch(saving());
    // GraphQL.saveOrganization(organization, groupsDiff).then((newOrganization: Types.Organization) => {
    //   dispatch(loaded(newOrganization));
    // }).catch((errorData: Error) => {
    //   console.log('Error', organization);
    //   console.log('ERROR', errorData);
    //   dispatch(error([errorData]));
    // });
  };
};