import React from "react";
import { Translate } from "react-localize-redux";
import { formatNumber } from "./index";

const Stats = ({
  average,
  max,
  total,
  useSalt,
  unit,
}: {
  average: number;
  max: number;
  total: number;
  useSalt: boolean;
  unit: string;
}) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        margin: "25px 0 20px 0",
      }}
    >
      <div>
        <b>
          <Translate id="components.barChart.average" />
        </b>
        {" " + formatNumber(average) + " "}
        {useSalt ? (
          <Translate id="components.barChart.kgUnit" />
        ) : unit === "litres" ? (
          "L"
        ) : (
          "m³"
        )}
      </div>
      <div>
        <b>
          <Translate id="components.barChart.total" />
        </b>
        {" " + formatNumber(total) + " "}
        {useSalt ? (
          <Translate id="components.barChart.kgUnit" />
        ) : unit === "litres" ? (
          "L"
        ) : (
          "m³"
        )}
      </div>
      <div>
        <b>
          <Translate id="components.barChart.max" />
        </b>
        {" " + formatNumber(max) + " "}
        {useSalt ? (
          <Translate id="components.barChart.kgUnit" />
        ) : unit === "litres" ? (
          "L"
        ) : (
          "m³"
        )}
      </div>
    </div>
  );
};

export default Stats;
