import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import PhoenixWarningIcon from "components/PhoenixWarningIcon";
import React from "react";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    phoenixBanner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "100%",
      height: "auto",
      backgroundColor: "#f4c600",
      color: "white",
      padding: "14px 20px 17px 20px",
    },
    warningIcon: { marginRight: "30px" },
    warningText: { height: "auto", display: "flex" },
    showMoreText: {
      color: "white",
      "&:hover": { cursor: "pointer" },
      marginLeft: "5px",
    },
  });
});

export default function PhoenixWarningBanner({
  findOutMoreOnClick,
}: {
  findOutMoreOnClick: () => void;
}) {
  const styles = useStyles();

  return (
    <div className={styles.phoenixBanner}>
      <PhoenixWarningIcon className={styles.warningIcon} />
      <div className={styles.warningText}>
        We’re updating CAMP to Globestage.
        <b>
          <u>
            <div className={styles.showMoreText} onClick={findOutMoreOnClick}>
              Find out more{" "}
            </div>
          </u>
        </b>{" "}
      </div>
    </div>
  );
}
