/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AuditLogObject {
  ADDRESS = "ADDRESS",
  BONUS_ACCOUNT = "BONUS_ACCOUNT",
  BONUS_ACCOUNT_TRANSACTION = "BONUS_ACCOUNT_TRANSACTION",
  CONSUMABLE_PRODUCT = "CONSUMABLE_PRODUCT",
  COUPON_CODE = "COUPON_CODE",
  DEVICE = "DEVICE",
  EMPLOYEE = "EMPLOYEE",
  ORGANIZATION = "ORGANIZATION",
  ORGANIZATION_CREATION_REQUEST = "ORGANIZATION_CREATION_REQUEST",
  ORGANIZATION_GROUP = "ORGANIZATION_GROUP",
  ORGANIZATION_JOIN_REQUEST = "ORGANIZATION_JOIN_REQUEST",
  PRODUCT = "PRODUCT",
  PRODUCT_CODE_BATCH = "PRODUCT_CODE_BATCH",
  PRODUCT_INSTANCE_CODE = "PRODUCT_INSTANCE_CODE",
  PRODUCT_USER_ACTION = "PRODUCT_USER_ACTION",
  PRODUCT_USER_ACTION_NOTIFICATION = "PRODUCT_USER_ACTION_NOTIFICATION",
  PRODUCT_USER_ACTION_NOTIFICATION_TRANSLATION = "PRODUCT_USER_ACTION_NOTIFICATION_TRANSLATION",
  PROMOTION = "PROMOTION",
  SEND_GRID_API_KEY = "SEND_GRID_API_KEY",
  SEND_GRID_SUPPORTED_LANGUAGE = "SEND_GRID_SUPPORTED_LANGUAGE",
  SEND_GRID_TEMPLATE = "SEND_GRID_TEMPLATE",
  SEND_GRID_USER = "SEND_GRID_USER",
  USER_ACCOUNT = "USER_ACCOUNT",
}

export enum BonusAccountPermission {
  DEACTIVATED = "DEACTIVATED",
  READ = "READ",
  WRITE = "WRITE",
}

export enum Country {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export enum CouponCodeFilterOption {
  CODE = "CODE",
  CONSUMPTION_DATE = "CONSUMPTION_DATE",
  COUPON_PRODUCT_ID = "COUPON_PRODUCT_ID",
  CREATION_DATE = "CREATION_DATE",
  CREATION_USER = "CREATION_USER",
  DESCRIPTION = "DESCRIPTION",
  ID = "ID",
  IS_ACTIVE = "IS_ACTIVE",
  PRODUCT_CODE = "PRODUCT_CODE",
  VALID_FROM = "VALID_FROM",
  VALID_TO = "VALID_TO",
}

export enum CustomerFilterOption {
  CITY = "CITY",
  COUNTRY = "COUNTRY",
  EMAIL = "EMAIL",
  FIRST_NAME = "FIRST_NAME",
  ID = "ID",
  IS_LOCKED = "IS_LOCKED",
  KEY = "KEY",
  LAST_NAME = "LAST_NAME",
  POSTAL_CODE = "POSTAL_CODE",
  REGISTERED_ON = "REGISTERED_ON",
  TELEPHONE = "TELEPHONE",
}

export enum DateAggregationType {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export enum DeviceAccessLevel {
  ADMIN = "ADMIN",
  CUSTOMER = "CUSTOMER",
  PLUMBER = "PLUMBER",
  RN_D = "RN_D",
  SERVICE = "SERVICE",
}

export enum DeviceActionState {
  ACTIVATED = "ACTIVATED",
  DISABELING = "DISABELING",
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  ERROR = "ERROR",
  FINISHED = "FINISHED",
  RUNNING = "RUNNING",
  START = "START",
  TIMEOUT = "TIMEOUT",
}

export enum DeviceActionType {
  FIRMWARE_UPDATE = "FIRMWARE_UPDATE",
  GET_LATEST_LOG = "GET_LATEST_LOG",
  HOLIDAY_MODE = "HOLIDAY_MODE",
  LID_CHANGE = "LID_CHANGE",
  MANUAL_REGENERATION = "MANUAL_REGENERATION",
  MANUAL_RINSE = "MANUAL_RINSE",
  NOT_SET = "NOT_SET",
  PARAMETER_UPDATE = "PARAMETER_UPDATE",
  PING = "PING",
  SHUTDOWN = "SHUTDOWN",
}

export enum DisinfectionType {
  BROMINE = "BROMINE",
  CHLORINE = "CHLORINE",
  SALT = "SALT",
}

export enum EmployeeFilterOption {
  CITY = "CITY",
  COUNTRY = "COUNTRY",
  EMAIL = "EMAIL",
  FIRST_NAME = "FIRST_NAME",
  ID = "ID",
  IS_LOCKED = "IS_LOCKED",
  IS_PENDING = "IS_PENDING",
  LAST_LOGIN = "LAST_LOGIN",
  LAST_NAME = "LAST_NAME",
  ORGANIZATION_NAME = "ORGANIZATION_NAME",
  POSITION = "POSITION",
  POSTAL_CODE = "POSTAL_CODE",
}

export enum FilterOperator {
  CONTAINS = "CONTAINS",
  CONTAINSPLURAL = "CONTAINSPLURAL",
  EQ = "EQ",
  GT = "GT",
  ISNULL = "ISNULL",
  LT = "LT",
  NEQ = "NEQ",
  STARTSWITH = "STARTSWITH",
}

export enum Language {
  AA = "AA",
  AB = "AB",
  AE = "AE",
  AF = "AF",
  AK = "AK",
  AM = "AM",
  AN = "AN",
  AR = "AR",
  AS = "AS",
  AV = "AV",
  AY = "AY",
  AZ = "AZ",
  BA = "BA",
  BE = "BE",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BR = "BR",
  BS = "BS",
  CA = "CA",
  CE = "CE",
  CH = "CH",
  CO = "CO",
  CR = "CR",
  CS = "CS",
  CU = "CU",
  CV = "CV",
  CY = "CY",
  DA = "DA",
  DE = "DE",
  DV = "DV",
  DZ = "DZ",
  EE = "EE",
  EL = "EL",
  EN = "EN",
  EO = "EO",
  ES = "ES",
  ET = "ET",
  EU = "EU",
  FA = "FA",
  FF = "FF",
  FI = "FI",
  FJ = "FJ",
  FO = "FO",
  FR = "FR",
  FY = "FY",
  GA = "GA",
  GD = "GD",
  GL = "GL",
  GN = "GN",
  GU = "GU",
  GV = "GV",
  HA = "HA",
  HE = "HE",
  HI = "HI",
  HO = "HO",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  HY = "HY",
  HZ = "HZ",
  IA = "IA",
  ID = "ID",
  IE = "IE",
  IG = "IG",
  II = "II",
  IK = "IK",
  IO = "IO",
  IS = "IS",
  IT = "IT",
  IU = "IU",
  JA = "JA",
  JV = "JV",
  KA = "KA",
  KG = "KG",
  KI = "KI",
  KJ = "KJ",
  KK = "KK",
  KL = "KL",
  KM = "KM",
  KN = "KN",
  KO = "KO",
  KR = "KR",
  KS = "KS",
  KU = "KU",
  KV = "KV",
  KW = "KW",
  KY = "KY",
  LA = "LA",
  LB = "LB",
  LG = "LG",
  LI = "LI",
  LN = "LN",
  LO = "LO",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  MG = "MG",
  MH = "MH",
  MI = "MI",
  MK = "MK",
  ML = "ML",
  MN = "MN",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MY = "MY",
  NA = "NA",
  NB = "NB",
  ND = "ND",
  NE = "NE",
  NG = "NG",
  NL = "NL",
  NN = "NN",
  NO = "NO",
  NR = "NR",
  NV = "NV",
  NY = "NY",
  OC = "OC",
  OJ = "OJ",
  OM = "OM",
  OR = "OR",
  OS = "OS",
  PA = "PA",
  PI = "PI",
  PL = "PL",
  PS = "PS",
  PT = "PT",
  QU = "QU",
  RM = "RM",
  RN = "RN",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SI = "SI",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SQ = "SQ",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SU = "SU",
  SV = "SV",
  SW = "SW",
  TA = "TA",
  TE = "TE",
  TG = "TG",
  TH = "TH",
  TI = "TI",
  TK = "TK",
  TL = "TL",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TS = "TS",
  TT = "TT",
  TW = "TW",
  TY = "TY",
  UG = "UG",
  UK = "UK",
  UR = "UR",
  UZ = "UZ",
  VE = "VE",
  VI = "VI",
  VO = "VO",
  WA = "WA",
  WO = "WO",
  XH = "XH",
  YI = "YI",
  YO = "YO",
  ZA = "ZA",
  ZH = "ZH",
  ZU = "ZU",
}

export enum MessageTranslation {
  ACTIVATION_RESPONSE = "ACTIVATION_RESPONSE",
  CONFIRM_NOTIFICATION_RESPONSE = "CONFIRM_NOTIFICATION_RESPONSE",
  CONNECTIVITY_TEST_REQUEST = "CONNECTIVITY_TEST_REQUEST",
  CONNECTIVITY_TEST_RESPONSE = "CONNECTIVITY_TEST_RESPONSE",
  EMAIL_ACTIVATION_REQUEST = "EMAIL_ACTIVATION_REQUEST",
  ERROR = "ERROR",
  ERR_1_POSITION_FAILED = "ERR_1_POSITION_FAILED",
  FAST_CYCLE_REGENERATION_RESPONSE = "FAST_CYCLE_REGENERATION_RESPONSE",
  FIRMWARE_UPDATE_REQUEST = "FIRMWARE_UPDATE_REQUEST",
  FIRMWARE_UPDATE_RESPONSE = "FIRMWARE_UPDATE_RESPONSE",
  INFORMATION = "INFORMATION",
  PING_REQUEST = "PING_REQUEST",
  PING_RESPONSE = "PING_RESPONSE",
  REGENERATION_RESPONSE = "REGENERATION_RESPONSE",
  RENEW_SERVICE = "RENEW_SERVICE",
  RENEW_WARRANTY = "RENEW_WARRANTY",
  RESET_STATE_RESPONSE = "RESET_STATE_RESPONSE",
  SALT_REMINDER = "SALT_REMINDER",
  SERVICE_DUE = "SERVICE_DUE",
  SET_PARAMETER_REQUEST = "SET_PARAMETER_REQUEST",
  SET_PARAMETER_RESPONSE = "SET_PARAMETER_RESPONSE",
  SHUTDOWN_REQUEST = "SHUTDOWN_REQUEST",
  SHUTDOWN_RESPONSE = "SHUTDOWN_RESPONSE",
  START_FAST_CYCLE_REGENERATION = "START_FAST_CYCLE_REGENERATION",
  START_REGENERATION = "START_REGENERATION",
  SUMMARY = "SUMMARY",
  TELEMETRY = "TELEMETRY",
  WARANTY_DUE = "WARANTY_DUE",
  WARNING = "WARNING",
}

export enum NotificationType {
  CONFIRMATION = "CONFIRMATION",
  ERROR = "ERROR",
  INFORMATION = "INFORMATION",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  NOT_SET = "NOT_SET",
  NOT_SYNCHED = "NOT_SYNCHED",
  OK = "OK",
  SERVICE = "SERVICE",
  WARNING = "WARNING",
}

export enum OrganizationCreationRequestFilterOption {
  COMPLETION_DATE = "COMPLETION_DATE",
  COUNTRY_CODE = "COUNTRY_CODE",
  CREATION_DATE = "CREATION_DATE",
  ID = "ID",
  ORGANIZATION_NAME = "ORGANIZATION_NAME",
  STATUS = "STATUS",
}

export enum OrganizationFilterOption {
  CITY = "CITY",
  COUNTRY = "COUNTRY",
  CREATION_TIME_STAMP = "CREATION_TIME_STAMP",
  EMAIL = "EMAIL",
  EMPLOYEE_COUNT = "EMPLOYEE_COUNT",
  EXTERNAL_ID = "EXTERNAL_ID",
  HAS_EMPLOYEES = "HAS_EMPLOYEES",
  ID = "ID",
  IS_DELETED = "IS_DELETED",
  NAME = "NAME",
  PARTNER_TYPE = "PARTNER_TYPE",
  POSTAL_CODE = "POSTAL_CODE",
  VAT_NUMBER = "VAT_NUMBER",
}

export enum OrganizationJoinRequestFilterOption {
  COMPLETION_DATE = "COMPLETION_DATE",
  COUNTRY_CODE = "COUNTRY_CODE",
  CREATION_DATE = "CREATION_DATE",
  ID = "ID",
  STATUS = "STATUS",
}

export enum OrganizationRequestStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  PENDING = "PENDING",
}

export enum OrganizationVisibility {
  CONTACT_ONLY = "CONTACT_ONLY",
  HIDDEN = "HIDDEN",
  PROMOTED = "PROMOTED",
  VISIBLE = "VISIBLE",
}

export enum PartnerType {
  BUILDER = "BUILDER",
  OPERATOR = "OPERATOR",
  PLANNER = "PLANNER",
  PROFESSIONAL = "PROFESSIONAL",
  TWP = "TWP",
  WHOLESALER = "WHOLESALER",
}

export enum PoolLocationType {
  INDOOR = "INDOOR",
  OUTDOOR = "OUTDOOR",
}

export enum PoolMaterial {
  CONCRETE = "CONCRETE",
  FOIL = "FOIL",
  GLASS_FIBRE = "GLASS_FIBRE",
  STEEL = "STEEL",
  TILES = "TILES",
}

export enum PoolShape {
  KIDNEY = "KIDNEY",
  L_SHAPED = "L_SHAPED",
  RECTANGULAR = "RECTANGULAR",
  ROUND = "ROUND",
}

export enum PoolType {
  CHILDRENS = "CHILDRENS",
  GENERIC = "GENERIC",
  WHIRLPOOL = "WHIRLPOOL",
}

export enum Position {
  ASSISTANT = "ASSISTANT",
  ENGINEER = "ENGINEER",
  NONE = "NONE",
  OWNER = "OWNER",
}

export enum ProductFilterOption {
  COUNTRY = "COUNTRY",
  GTIN = "GTIN",
  ID = "ID",
  ITEM_NUMBER = "ITEM_NUMBER",
  NAME = "NAME",
  PRODUCT_GROUP_NAME = "PRODUCT_GROUP_NAME",
  PRODUCT_TYPE = "PRODUCT_TYPE",
}

export enum ProductInstanceFilterOption {
  BATCH_NUMBER = "BATCH_NUMBER",
  CITY = "CITY",
  COUNTRY = "COUNTRY",
  CUSTOMER_EMAIL = "CUSTOMER_EMAIL",
  CUSTOMER_FIRST_NAME = "CUSTOMER_FIRST_NAME",
  CUSTOMER_LAST_NAME = "CUSTOMER_LAST_NAME",
  DEVICE_ID = "DEVICE_ID",
  DEVICE_STATE = "DEVICE_STATE",
  DEVICE_STATE_CODE = "DEVICE_STATE_CODE",
  DEVICE_VERSION_ID = "DEVICE_VERSION_ID",
  DISPLAY_NAME = "DISPLAY_NAME",
  ERP_BATCH_NUMBER = "ERP_BATCH_NUMBER",
  FIRMWARE_VERSION = "FIRMWARE_VERSION",
  ID = "ID",
  INSTALLATION_DATE = "INSTALLATION_DATE",
  ITEM_NUMBER = "ITEM_NUMBER",
  POSTAL_CODE = "POSTAL_CODE",
  PRODUCT_CODE = "PRODUCT_CODE",
  PRODUCT_ID = "PRODUCT_ID",
  PRODUCT_NAME = "PRODUCT_NAME",
  REGISTRATION_DATE = "REGISTRATION_DATE",
  SERIAL_NUMBER = "SERIAL_NUMBER",
  STATE = "STATE",
  STREET = "STREET",
  TYPE = "TYPE",
}

export enum ProductInstanceState {
  CONFIRMATION = "CONFIRMATION",
  ERROR = "ERROR",
  INFORMATION = "INFORMATION",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  NOT_CONNECTED = "NOT_CONNECTED",
  NOT_SET = "NOT_SET",
  NOT_SYNCHED = "NOT_SYNCHED",
  OK = "OK",
  SERVICE = "SERVICE",
  WARNING = "WARNING",
}

export enum ProductType {
  DEFAULT = "DEFAULT",
  PERLA_IOT = "PERLA_IOT",
  POOL = "POOL",
  SILK_IOT = "SILK_IOT",
  WATER_DISPENSER = "WATER_DISPENSER",
}

export enum PromotionType {
  ASSIGNABLE = "ASSIGNABLE",
  DEDICATED_USAGE = "DEDICATED_USAGE",
}

export enum SendGridTemplateFilterOption {
  COUNTRY = "COUNTRY",
  IS_DEFAULT = "IS_DEFAULT",
  LANGUAGE = "LANGUAGE",
  NAME = "NAME",
  SEND_GRID_TEMPLATE_TYPE = "SEND_GRID_TEMPLATE_TYPE",
}

export enum SendGridTemplateType {
  ACCOUNT_SERVER = "ACCOUNT_SERVER",
  NOTIFICATION = "NOTIFICATION",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  REGISTRATION = "REGISTRATION",
  USER_ACTION = "USER_ACTION",
}

export enum SortingDirection {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum UserActionClass {
  CONSUMABLE_REFILLMENT = "CONSUMABLE_REFILLMENT",
  CO_2_BOTTLE_CAPACITY = "CO_2_BOTTLE_CAPACITY",
  FILTER_CARTRIDGE_CAPACITY = "FILTER_CARTRIDGE_CAPACITY",
  FIXED_INTERVAL_HALF_YEAR = "FIXED_INTERVAL_HALF_YEAR",
  FIXED_INTERVAL_MONTHLY = "FIXED_INTERVAL_MONTHLY",
  FIXED_INTERVAL_THREE_YEARS = "FIXED_INTERVAL_THREE_YEARS",
  FIXED_INTERVAL_YEAR = "FIXED_INTERVAL_YEAR",
  NO_ESTIMATION = "NO_ESTIMATION",
}

export enum UserActionState {
  ERROR = "ERROR",
  OK = "OK",
  WARNING = "WARNING",
}

export enum UserInformationFilterOption {
  EMAIL = "EMAIL",
  FIRST_NAME = "FIRST_NAME",
  ID = "ID",
  KEY = "KEY",
  LAST_NAME = "LAST_NAME",
}

export interface AddressInsertInput {
  city: string;
  countryCode?: Country | null;
  postalCode: string;
  street: string;
  location?: GeoLocationInput | null;
}

export interface AuditLogInput {
  interaction: string;
  detail: string;
  pIILevel: number;
}

export interface BonusAccountInsertInput {
  organizationId: number;
}

export interface BonusAccountTransactionInput {
  accountId: string;
  amount: any;
  description: string;
}

export interface BonusAccountUpdateInput {
  permission?: BonusAccountPermission | null;
  bonusAccountId: string;
}

export interface ConsumableProductInsert {
  itemNumber: string;
  pearlsRewarded: any;
  isActive: boolean;
  name: string;
}

export interface ConsumableProductUpdate {
  id: number;
  itemNumber: string;
  pearlsRewarded: any;
  isActive: boolean;
  name: string;
}

export interface CouponCodeFilter {
  filterOption?: (CouponCodeFilterOption | null)[] | null;
  operator?: FilterOperator | null;
  values: (string | null)[];
}

export interface CustomerFilter {
  filterOption?: (CustomerFilterOption | null)[] | null;
  operator?: FilterOperator | null;
  values: (string | null)[];
}

export interface EmployeeFilter {
  filterOption?: (EmployeeFilterOption | null)[] | null;
  operator?: FilterOperator | null;
  values: (string | null)[];
}

export interface GeoLocationInput {
  latitude: number;
  longitude: number;
}

export interface OrganizationAddressUpdateInput {
  organizationId: number;
  city?: string | null;
  countryCode?: Country | null;
  postalCode?: string | null;
  street?: string | null;
  location?: GeoLocationInput | null;
}

export interface OrganizationCreationRequestFilter {
  filterOption?: (OrganizationCreationRequestFilterOption | null)[] | null;
  operator?: FilterOperator | null;
  values: (string | null)[];
}

export interface OrganizationFilter {
  filterOption?: (OrganizationFilterOption | null)[] | null;
  operator?: FilterOperator | null;
  values: (string | null)[];
}

export interface OrganizationInsertInput {
  ownerUserId?: number | null;
  name: string;
  address?: AddressInsertInput | null;
  partnerType?: PartnerType | null;
  visibility?: OrganizationVisibility | null;
  email?: string | null;
  telephone?: string | null;
  website?: string | null;
  externalId?: string | null;
  vatNumber?: string | null;
  partnerImage?: string | null;
  partnerImageLinkUrl?: string | null;
  logo?: string | null;
  logoLinkUrl?: string | null;
  size?: number | null;
}

export interface OrganizationJoinRequestFilter {
  filterOption?: (OrganizationJoinRequestFilterOption | null)[] | null;
  operator?: FilterOperator | null;
  values: (string | null)[];
}

export interface OrganizationJoinRequestInput {
  id: string;
  organizationName?: string | null;
  postalCode?: string | null;
  street?: string | null;
  city?: string | null;
  countryCode?: string | null;
  vatNumber?: string | null;
  website?: string | null;
}

export interface OrganizationUpdateInput {
  id: number;
  name?: string | null;
  partnerType?: PartnerType | null;
  visibility?: OrganizationVisibility | null;
  email?: string | null;
  telephone?: string | null;
  website?: string | null;
  partnerImageLinkUrl?: string | null;
  logoLinkUrl?: string | null;
  externalId?: string | null;
  size?: number | null;
  vatNumber?: string | null;
}

export interface OrganizationUpdateLogoInput {
  organizationId: number;
  logo?: string | null;
}

export interface OrganizationUpdatePartnerImageInput {
  organizationId: number;
  partnerImage?: string | null;
}

export interface ProductCodeGenerationParameter {
  amount: number;
  batchNumber: string;
  productId: number;
  erpBatchNumber?: string | null;
}

export interface ProductFilter {
  filterOption?: (ProductFilterOption | null)[] | null;
  operator?: FilterOperator | null;
  values: (string | null)[];
}

export interface ProductInstanceAddressUpdateInput {
  productCode: string;
  city?: string | null;
  countryCode?: Country | null;
  postalCode?: string | null;
  street?: string | null;
  location?: GeoLocationInput | null;
}

export interface ProductInstanceFilter {
  filterOption?: (ProductInstanceFilterOption | null)[] | null;
  operator?: FilterOperator | null;
  values: (string | null)[];
}

export interface ProductUpdateInput {
  id: number;
  bonus?: any | null;
  name: string;
  displayName?: string | null;
  productOwnerEmail?: string | null;
  groupId?: number | null;
  itemNumber?: string | null;
  productType?: ProductType | null;
  businessUnit: string;
  deviceType?: string | null;
  deviceVersion?: string | null;
  gTIN?: string | null;
  isProductRegistrationEnabled: boolean;
  isCodelessProductRegistrationEnabled: boolean;
  invoiceUploadAllowed: boolean;
  isArchived: boolean;
  registrationMailTemplateName?: string | null;
  serviceFeePearls?: any | null;
  registrationBonus?: RegistrationBonusInput | null;
}

export interface ProductUserActionInsert {
  productId: number;
  userActionClass?: UserActionClass | null;
  userActionInProductIndex: number;
}

export interface ProductUserActionNotificationInsert {
  mailTemplateName?: string | null;
  productUserActionId: number;
  state?: UserActionState | null;
  threshold: number;
}

export interface ProductUserActionNotificationTranslationInsert {
  body?: string | null;
  language: string;
  productUserActionNotificationId: number;
  title: string;
}

export interface ProductUserActionNotificationTranslationUpdate {
  body?: string | null;
  id: number;
  language: string;
  productUserActionNotificationId: number;
  title: string;
}

export interface ProductUserActionNotificationUpdate {
  id: number;
  mailTemplateName?: string | null;
  productUserActionId: number;
  state?: UserActionState | null;
  threshold: number;
}

export interface PromotionInsertInput {
  articleNumber?: string | null;
  name?: string | null;
  creationUser?: string | null;
  usageCountLimit: number;
  type?: PromotionType | null;
  productIds?: (number | null)[] | null;
  countryCodeIds?: (any | null)[] | null;
}

export interface PromotionUpdate {
  id: number;
  articleNumber?: string | null;
  name?: string | null;
  creationUser?: string | null;
  usageCountLimit: number;
  type?: PromotionType | null;
  productIds?: (number | null)[] | null;
  countryCodeIds?: (any | null)[] | null;
}

export interface RegistrationBonusInput {
  bonusId?: number | null;
}

export interface SendGridSupportedLanguageInsert {
  languageCode: string;
  countryCode: string;
}

export interface SendGridTemplateFilter {
  filterOption?: (SendGridTemplateFilterOption | null)[] | null;
  operator?: FilterOperator | null;
  values: (string | null)[];
}

export interface SortingCriterionForCouponCodeFilterOption {
  filterOption?: CouponCodeFilterOption | null;
  sortingCriterionOrder: number;
  sortingDirection?: SortingDirection | null;
}

export interface SortingCriterionForCustomerFilterOption {
  filterOption?: CustomerFilterOption | null;
  sortingCriterionOrder: number;
  sortingDirection?: SortingDirection | null;
}

export interface SortingCriterionForEmployeeFilterOption {
  filterOption?: EmployeeFilterOption | null;
  sortingCriterionOrder: number;
  sortingDirection?: SortingDirection | null;
}

export interface SortingCriterionForOrganizationCreationRequestFilterOption {
  filterOption?: OrganizationCreationRequestFilterOption | null;
  sortingCriterionOrder: number;
  sortingDirection?: SortingDirection | null;
}

export interface SortingCriterionForOrganizationFilterOption {
  filterOption?: OrganizationFilterOption | null;
  sortingCriterionOrder: number;
  sortingDirection?: SortingDirection | null;
}

export interface SortingCriterionForOrganizationJoinRequestFilterOption {
  filterOption?: OrganizationJoinRequestFilterOption | null;
  sortingCriterionOrder: number;
  sortingDirection?: SortingDirection | null;
}

export interface SortingCriterionForProductFilterOption {
  filterOption?: ProductFilterOption | null;
  sortingCriterionOrder: number;
  sortingDirection?: SortingDirection | null;
}

export interface SortingCriterionForProductInstanceFilterOption {
  filterOption?: ProductInstanceFilterOption | null;
  sortingCriterionOrder: number;
  sortingDirection?: SortingDirection | null;
}

export interface SortingCriterionForSendGridTemplateFilterOption {
  filterOption?: SendGridTemplateFilterOption | null;
  sortingCriterionOrder: number;
  sortingDirection?: SortingDirection | null;
}

export interface UserAddressInput {
  city?: string | null;
  countryCode?: Country | null;
  postalCode?: string | null;
  street?: string | null;
  location?: GeoLocationInput | null;
}

export interface UserInformationFilter {
  filterOption?: (UserInformationFilterOption | null)[] | null;
  operator?: FilterOperator | null;
  values: (string | null)[];
}

export interface UserInformationUpdateInput {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  address?: UserAddressInput | null;
  acceptedMarketingUsage: boolean;
  canUsePerlaUpdater: boolean;
  isTestUser: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
