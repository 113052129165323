import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { Action, ActionType } from 'redux/types/Auth';

import * as Types from 'types/ToolboxEntities';

import { Error } from 'types/CampGuideTypes';

import GraphQL from 'util/GraphQL';

export const login = (user: Types.UserProfile): Action => ({
  type: ActionType.LOGIN,
  payload: user
});

export const unauthorised = (): Action => ({
  type: ActionType.UNAUTHORISED,
  payload: null
});

export const logout = (): Action => ({
  type: ActionType.LOGOUT,
  payload: null
});

export const loading = (): Action => ({
  type: ActionType.LOADING,
  payload: null
});

export const expire = (): Action => ({
  type: ActionType.EXPIRE,
  payload: null
})

export const loadUser = (): ThunkAction<Promise<void>, {}, {}, Action> => {
  return async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {

    dispatch(loading());

    GraphQL.queryUser().then((user) => {
      //console.log('Logedin User:', user);
      dispatch(login(user));
    }).catch((error: Error) => {
      if (error.message.includes("Permission 'UserRead' required")) {
        dispatch(unauthorised())
      } else {
        console.log('ERROR Logedin User:', error);
        window.document.cookie = '';
        dispatch(logout());
      }
    });
  };
};
