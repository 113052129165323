import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import gql from "graphql-tag";
import { useMutation } from '@apollo/react-hooks';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';

import { AppState } from 'redux/store';

import { Store as SendgridTemplatesDeleteStore } from 'redux/types/SendgridTemplatesDelete';
import { close, change } from 'redux/actions/SendgridTemplatesDeleteActions';
import { deleteSendgridTemplate, deleteSendgridTemplateVariables } from 'types/apolloGenerated/deleteSendgridTemplate';


import { sendgridGroups } from 'types/apolloGenerated/sendgridGroups';
import { QUERY_SENDGRID_TEMPLATE_LIST } from 'Pages/Sendgrid/TemplateGroupsOverview/index';

import { Translate } from "react-localize-redux";

import Error from 'components/common/Error';
import cloneDeep from 'clone-deep';


interface ISendgridTemplatesDelete {
  deleteTemplate: SendgridTemplatesDeleteStore
  actions: {
    close: typeof close
    change: typeof change
  }
}


const DELETE_SENDGRID_TEMPLATE = gql`
mutation deleteSendgridTemplate(
  $templateId: Int!
  ){
  sendGridTemplate{
    delete(sendGridTemplateId:$templateId)
  }
}
`;


const SendgridTemplatesDelete: React.FC<ISendgridTemplatesDelete> = ({ deleteTemplate, actions }) => {

  const [deleteSendgridTemplateFunc, { error }] = useMutation<deleteSendgridTemplate, deleteSendgridTemplateVariables>(DELETE_SENDGRID_TEMPLATE, {
    update(cache) {
      //      
      // Update CACHE: Employees-Array within Organization
      //
      try {
        const variables = {
          id: deleteTemplate.changeData?.templateId
        };

        console.log("templateId: ", deleteTemplate.changeData?.templateId);

        const queryCache = cloneDeep(cache.readQuery<sendgridGroups>({
          query: QUERY_SENDGRID_TEMPLATE_LIST,
          variables: variables,
        }));

        if (queryCache?.sendGridTemplates?.results) {

          queryCache.sendGridTemplates.results = queryCache.sendGridTemplates.results.filter((item, idx) => {
            return (item && item?.id !== deleteTemplate.changeData?.templateId);
          });

          cache.writeQuery({
            query: QUERY_SENDGRID_TEMPLATE_LIST,
            variables: variables,
            data: queryCache
          });
        } else {
          console.log('Trying to update CACHE, but emplyees not found');
        }
      } catch (e) { }



      if (!error) {
        actions.close();
      } else {
        console.log('Error', error);
      }
    }
  });


  const submit = () => {
    if (deleteTemplate.changeData) {
      actions.change(deleteTemplate.changeData);
      deleteSendgridTemplateFunc({
        variables: {
          templateId: parseFloat(deleteTemplate.changeData.templateId),
        }
      }).catch(() => { });

    }
  };




  const progress = deleteTemplate.isLocked ? <CircularProgress color="secondary" /> : null;

  return (
    <Dialog open={Boolean(deleteTemplate.changeData)} onClose={actions.close} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="form-dialog-title"><Translate id="pages.sendgrid.templatesGrid.deleteTemplate" /></DialogTitle>
      <DialogContent>
        <DialogContentText><Translate id="pages.sendgrid.templatesGrid.deleteTemplateAreYouSure" /></DialogContentText>
        <Error error={deleteTemplate.error} />
      </DialogContent>
      <DialogActions>
        {progress}
        <Button onClick={actions.close} color="primary" disabled={deleteTemplate.isLocked}>
          <Translate id="generics.cancel" />
        </Button>
        <Button onClick={submit} color="primary" disabled={deleteTemplate.isLocked}>
          <Translate id="generics.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: AppState) => ({
  deleteTemplate: state.sendgridTemplatesDelete,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ close, change }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendgridTemplatesDelete);
