import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Translate } from 'react-localize-redux';


import { TextField } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';


import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

import debounce from 'debounce';

import { AppState } from 'redux/store';
import { State as CustomerOverviewState } from 'redux/types/CustomerOverview';
import { setGridState, setColumnStates, search } from 'redux/actions/CustomerOverviewActions';

import { UserProfile } from 'types/ToolboxEntities';


import { getFilterValues } from 'util/AgGridUtils';


import AgGrid from 'components/common/Grid/AgGrid';
import CountryFilter from 'components/common/CountryFilter';
import PageHeader from 'components/common/PageHeader';
import GridFilterHeader from 'components/common/Grid/GridFilterHeader';


import { getColumnDefs, apolloConnectorBySearch, CellRenderer, getRowStyleScheduled } from './GridConfig';


const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    customerOverview: {
      display: 'flex',
      flexFlow: 'column',

      '& > .gridFilterHeader': {
        flex: '0 1 auto',
      },

      '& > .innerContent': {
        flex: '1 1 100%',
        height: '100%',
      },
    },

    innerHeader: {
      flex: '0 1 auto',
      display: 'flex',
      margin: '0 -20px 20px -20px',
      alignItems: 'flex-end',
      justifyContent: 'space-between',

      '& > *': {
        flex: '0 1 auto',
        margin: '0 20px',
        minWidth: '170px',
      },

      '& > .filter': {
        flex: '1 1 auto',
        maxWidth: "250px",
      }
    },

    innerHeaderRight: {
      flex: '0 1 auto',
      display: 'flex',
      alignItems: 'flex-end',
      margin: 0,

      '& > *': {
        flex: '0 1 auto',
        margin: '0 20px',
        minWidth: '170px',
      },
    },
  })
});







const debouncedSearch = debounce((searchTerm: string, oldSearchTerm: string | null, searchAction: typeof search) => {
  // console.log(searchTerm);
  if (searchTerm.length >= 3) {
    searchAction(searchTerm);
  } else if (searchTerm.length === 0 && (oldSearchTerm === null || oldSearchTerm.length > 0)) {
    searchAction(null);
  }
}, 500);




interface CustomerListPara {
  user: UserProfile | null
  customerOverviewState: CustomerOverviewState
  actions: {
    setGridState: typeof setGridState
    setColumnStates: typeof setColumnStates
    search: typeof search
  }
}



const CustomerList: React.FC<CustomerListPara> = ({ user, customerOverviewState, actions }) => {
  const classes = useStyles();

  const [search, setSearch] = useState<string>(customerOverviewState.search || "");

  const [apolloConnector, setApolloConnector] = useState<ReturnType<typeof apolloConnectorBySearch>>(apolloConnectorBySearch(customerOverviewState.search));

  useEffect(() => {
    setApolloConnector(apolloConnectorBySearch(customerOverviewState.search));
  }, [customerOverviewState.search]);


  if (!user) {
    return null;
  }

  const gridState = customerOverviewState.grid;

  const changeFilter = (filterName: string, newFilterValues: string[] | false) => {
    const newFilter = Object.assign({}, gridState.filterModel);
    if (newFilterValues !== false) {
      newFilter[filterName] = {
        filterType: "set",
        values: newFilterValues
      };
    } else {
      delete newFilter[filterName];
    }
    actions.setGridState(gridState.page, gridState.sortModel, newFilter);
  }


  const changeCountryFilter = (newFilterValues: string[]) => {
    changeFilter(
      'userInformation.address.countryCode',
      (newFilterValues.length > 0 && newFilterValues.length < user.allowedCountries.length) && newFilterValues
    );
  };

  const selectedCountries = gridState.filterModel['userInformation.address.countryCode'] ? getFilterValues(gridState.filterModel['userInformation.address.countryCode']) : user.allowedCountries;


  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debouncedSearch(event.target.value, customerOverviewState.search, actions.search);
  }


  return (
    <Translate>{({ translate }) => (
      <Fragment>
        <PageHeader title="pages.cs.customer.title" />
        <main className={"mainContentContainer fullHeight " + classes.customerOverview} style={{ position: "relative" }}>
          <GridFilterHeader className="gridFilterHeader">
            <TextField className="filter" id="standard-basic" label="Type to filter" value={search} onChange={changeSearch} />

            <div className="innerHeaderRight">
              <CountryFilter selected={selectedCountries} onChange={changeCountryFilter} />
            </div>
          </GridFilterHeader>

          <div className="innerContent">
            <AgGrid
              gridState={customerOverviewState.grid}
              gridActions={{
                setGridState: actions.setGridState,
                setColumnStates: actions.setColumnStates
              }}
              getRowStyle={getRowStyleScheduled}
              apolloConnector={apolloConnector}
              frameworkComponents={CellRenderer}
              columnDefs={getColumnDefs(translate, user)}
            />
          </div>
        </main>
      </Fragment>
    )}</Translate>
  );
};





const mapStateToProps = (appState: AppState) => ({
  user: appState.auth.user,
  customerOverviewState: appState.customerOverview
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ setGridState, setColumnStates, search }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerList);
