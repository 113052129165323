import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
// Disable query batching for the time being for graphql-faker compat
import { createHttpLink } from "apollo-link-http";
// import { BatchHttpLink } from "apollo-link-batch-http";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { onError, ErrorResponse } from 'apollo-link-error';

import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'themes/theme';

import * as serviceWorker from 'serviceWorker';

import Localization from 'components/common/Localization';

import App from 'App';

import store from 'redux/store';
import { loadUser, logout, expire } from 'redux/actions/AuthActions';

import introspectionQueryResultData from "nonheuristicGraphqlTypes.json";

import { LicenseManager } from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey("BWT_AG_CAMP_Guide_single_1_Devs__20_January_2021_[v2]_MTYxMTEwMDgwMDAwMA==15f5ebe329fba3e829f66ccac1cb5e0d");


const logoutErrorHandler = onError((errorResponse: ErrorResponse | null) => {
  let handled = false;
  errorResponse?.graphQLErrors?.some((graphQLError) => {
    // Not Loged-In and/or session expired
    console.log(graphQLError)
    if (graphQLError?.extensions?.code === 'Authentication') {
      handled = true;
      console.warn('GraphQL Auth Error');
      sessionStorage.setItem("redirectUrl", window.location.pathname);
      store.dispatch<any>(logout());
      store.dispatch<any>(expire());
      return true;
    }
  });

  if (!handled) {
    console.error('Apollo Error:', errorResponse);
  }
});

const client = new ApolloClient({
  // Disable query batching for the time being for graphql-faker compat
  link: logoutErrorHandler.concat(createHttpLink({ uri: "/graphql" })),
  // link: createHttpLink({ uri: "/graphql" }),
  // link: new BatchHttpLink({ uri: "/graphql" }),
  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData
    })
  })
});

store.dispatch<any>(loadUser());

ReactDOM.render(
  <ApolloProvider client={client}>
    <ReduxProvider store={store}>
      <Localization store={store}>
        <Router basename={window.location.pathname.startsWith("/camp") ? "/camp/" : ""}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Router>
      </Localization>
    </ReduxProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
