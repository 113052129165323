export enum ActionType {
  OPEN = 'RESET_INSTALLER_OPEN',
  CLOSE = 'RESET_INSTALLER_CLOSE',
}

export interface Store {
  openWithProductCode: string | undefined,
}


export interface OpenAction {
  type: ActionType.OPEN,
  payload: string
}

export interface CloseAction {
  type: ActionType.CLOSE,
}


export type Action = OpenAction | CloseAction;
